import { Injectable } from '@angular/core';

import { Observable, concatMap, defer, finalize, forkJoin, from, map, of, switchMap, toArray } from 'rxjs';
import { FormatNumberBrowserLocalePipe, LoaderStateService, TranslateService, UnitSymbolPipe } from '@novisto/common';

import { EmbedderUtils } from '../../utilities/embedder-utils';
import {
  CheckPublicIndicatorValuesUpdated,
  CheckPublicIndicatorValuesUpdatedPayload,
  EmbedderFontColors,
  EmbedderHighlightColors,
  EmbedderValue,
  EmbedderValueField,
  EmbedderValueId,
  IContextSettings,
  Indicator,
  MinimalDocumentMetaData,
} from '../../models';
import { PublicDocumentsService } from '../public-documents/public-documents.service';
import { PublicIndicatorsService } from '../public-indicators/public-indicators.service';
import { ValueUtils } from '../../utilities/value-utils';

type InsertOptions = {
  controlId?: string;
  highlightColor?: EmbedderHighlightColors;
};

@Injectable({ providedIn: 'root' })
export class WordEmbedderService {
  private highlightControls = false;

  constructor(
    private readonly formatNumberBrowserLocalePipe: FormatNumberBrowserLocalePipe,
    private readonly loaderStateService: LoaderStateService,
    private readonly publicDocumentsService: PublicDocumentsService,
    private readonly publicIndicatorsService: PublicIndicatorsService,
    private readonly translateService: TranslateService,
    private readonly unitSymbolPipe: UnitSymbolPipe
  ) {}

  public checkForUpdates(): Observable<CheckPublicIndicatorValuesUpdated[]> {
    return this.getEmbedderValueIds().pipe(
      map((embbederValueIds) => {
        const previousValues: CheckPublicIndicatorValuesUpdatedPayload['previous_values'] = [];
        embbederValueIds.forEach(({ embedderValue }) => {
          if (embedderValue.table) {
            embedderValue.table.forEach((vg) => {
              vg.values?.forEach((v) => {
                if (v.id) {
                  previousValues.push({ value: v.value, value_id: v.id });
                }
              });
            });
          } else if (embedderValue.value?.id) {
            previousValues.push({ value: embedderValue.value.value, value_id: embedderValue.value.id });
          }
        });
        return previousValues;
      }),
      switchMap((previousValues) =>
        previousValues.length
          ? this.publicIndicatorsService
              .checkValuesUpdated({ previous_values: previousValues })
              .pipe(map((res) => res.data))
          : of([])
      )
    );
  }

  public embbedValue(
    settings: IContextSettings,
    documents: Record<string, MinimalDocumentMetaData>,
    embedderValue: EmbedderValue,
    field: string,
    options: InsertOptions = {}
  ): Observable<void> {
    const id = EmbedderUtils.formatId(settings, embedderValue, field);

    if (field === String(EmbedderValueField.explanation) && embedderValue.value) {
      return this.insertText(id, ValueUtils.formatExplanation(embedderValue.value), options);
    } else {
      const { html, value: formattedValue } = ValueUtils.formatValue(
        embedderValue,
        field,
        this.formatNumberBrowserLocalePipe,
        this.unitSymbolPipe,
        documents
      );

      if (Array.isArray(formattedValue)) {
        return this.insertList(id, formattedValue, options);
      } else if (html) {
        return this.insertHtml(id, formattedValue, options);
      } else {
        return this.insertText(id, formattedValue, options);
      }
    }
  }

  public getSetting<T>(key: string): Observable<T | null> {
    return this.run(async (context: Word.RequestContext) => {
      const setting = context.document.settings.getItemOrNullObject(key);
      setting.load();
      await context.sync();

      return setting.isNullObject ? null : JSON.parse(String(setting.value));
    });
  }

  public initialize(settings?: IContextSettings | null): void {
    this.highlightControls = Boolean(settings?.highlightControls);
  }

  public saveSetting(key: string, newSetting: unknown): Observable<void> {
    return this.run(async (context: Word.RequestContext) => {
      const settings = context.document.settings;
      settings.add(key, JSON.stringify(newSetting));
      return context.sync();
    });
  }

  public setControlsHighlight(highlightControls?: boolean): Observable<void> {
    const isOverallUpdate = typeof highlightControls === 'undefined';

    if (!isOverallUpdate && this.highlightControls === highlightControls) {
      return of(undefined);
    }

    return this.run(async (context) => {
      const controls = await this.getControls(context);

      this.highlightControls = isOverallUpdate ? this.highlightControls : highlightControls;
      controls.items.forEach((control: Word.ContentControl) => this.setHighlightColor(control));
      await context.sync();
    });
  }

  public updateEmbedderValues(
    settings: IContextSettings,
    changes?: CheckPublicIndicatorValuesUpdated[]
  ): Observable<boolean> {
    this.loaderStateService.openloader(this.translateService.instant('Updating values'));
    return this.getEmbedderValueIds(changes).pipe(
      switchMap((embbededValueIds) => {
        if (!embbededValueIds.length) {
          return of(false);
        }

        return forkJoin([
          of(embbededValueIds),
          this.fetchIndicators(
            settings,
            embbededValueIds.map((e) => e.metricId)
          ),
        ]).pipe(
          switchMap(([embbederValueIds, [indicators, documents]]) => {
            const valuesByIndicator = EmbedderUtils.getEmbedderValueByIndicator(indicators);
            return from(
              embbederValueIds.map((embbederValueId) => ({
                embbederValueId,
                values: valuesByIndicator[embbederValueId.metricId],
                documents,
              }))
            );
          }),
          concatMap(({ embbederValueId, values, documents }) => {
            const oldId = EmbedderUtils.encodeId(embbederValueId);
            const newEmbedderValue = values[embbederValueId.embedderValue.id];

            if (newEmbedderValue) {
              return this.embbedValue(settings, documents, newEmbedderValue, embbederValueId.field, {
                controlId: oldId,
                highlightColor: changes ? EmbedderHighlightColors.updated : undefined,
              }).pipe(map(() => false));
            }

            return this.highlightControl(oldId, EmbedderHighlightColors.deleted).pipe(map(() => true));
          })
        );
      }),
      toArray(),
      map((results) => results.includes(true)),
      finalize(() => {
        this.loaderStateService.closeloader();
      })
    );
  }

  private fetchIndicators(
    settings: IContextSettings,
    metricIds: string[]
  ): Observable<[Indicator[], Record<string, MinimalDocumentMetaData>]> {
    return this.publicIndicatorsService
      .search(
        {
          business_unit_id: settings.source.id,
          fiscal_year: settings.fiscalYear.id,
          filters: { metric_ids: metricIds },
        },
        true
      )
      .pipe(switchMap((res) => forkJoin([of(res.data), this.publicDocumentsService.getIndicatorDocuments(res.data)])));
  }

  private async getControls(context: Word.RequestContext): Promise<Word.ContentControlCollection> {
    const controls = context.document.getContentControls();
    context.load(controls, 'items');
    await context.sync();

    return controls;
  }

  private getEmbedderValueIds(changes?: CheckPublicIndicatorValuesUpdated[]): Observable<EmbedderValueId[]> {
    return this.run(async (context: Word.RequestContext) => {
      const controls = await this.getControls(context);
      let embbededValueIds: EmbedderValueId[] = controls.items.map((control: Word.ContentControl) =>
        EmbedderUtils.fetchId(control.tag)
      );

      if (changes) {
        embbededValueIds = embbededValueIds.filter((embbededValueId) => {
          if (embbededValueId.embedderValue.value) {
            const change = changes.find((c) => c.value_id === embbededValueId.embedderValue.value?.id);
            return !change || change.has_changed;
          } else if (embbededValueId.embedderValue.table) {
            const tableIds = embbededValueId.embedderValue.table.flatMap((vg) => vg.values?.map((v) => v.id) || []);
            const tableChanges = changes.filter((c) => tableIds.includes(c.value_id));
            return !tableChanges.length || tableChanges.some((c) => c.has_changed);
          }

          return false;
        });
      }

      return embbededValueIds;
    });
  }

  private highlightControl(controlId: string, highlightColor: EmbedderHighlightColors | null): Observable<void> {
    return this.run(async (context: Word.RequestContext) => {
      const controls = await this.getControls(context);
      const control = controls.getByTag(controlId).getFirst();
      this.setHighlightColor(control, highlightColor);

      if (highlightColor === EmbedderHighlightColors.deleted) {
        this.setColor(control, EmbedderFontColors.deleted);
        control.delete(true);
      }

      await context.sync();
    });
  }

  private insert(
    id: string,
    execute: (control: Word.ContentControl) => void,
    options: InsertOptions
  ): Observable<void> {
    return this.run(async (context: Word.RequestContext) => {
      let wordContentControl: Word.ContentControl;

      if (options.controlId) {
        const controls = await this.getControls(context);
        wordContentControl = controls.getByTag(options.controlId).getFirst();
        wordContentControl.set({ removeWhenEdited: false });
        wordContentControl.clear();
        await context.sync();
      } else {
        let range = context.document.getSelection();
        const parentContentControl = range.parentContentControlOrNullObject;
        context.load(range);
        context.load(parentContentControl);
        await context.sync();

        if (!parentContentControl.isNullObject) {
          parentContentControl.getRange('After').select('End');
          range = context.document.getSelection();
          context.load(range);
          await context.sync();
        }

        if (!range.isEmpty) {
          range.clear();
        }

        wordContentControl = range.insertContentControl();
      }

      wordContentControl.tag = id;
      execute(wordContentControl);
      context.load(wordContentControl);
      await context.sync();

      wordContentControl.set({ removeWhenEdited: true, tag: id });
      this.setHighlightColor(wordContentControl, options.highlightColor || null);
      wordContentControl.getRange('After').select('End');
      await context.sync();
    });
  }

  private insertHtml(id: string, value: string, options: InsertOptions): Observable<void> {
    return this.insert(id, (control) => control.insertHtml(value, 'Start'), options);
  }

  private insertList(id: string, values: string[], options: InsertOptions): Observable<void> {
    return this.insert(
      id,
      (control) => {
        const paragraph = control.insertParagraph(values[0], 'Start');
        const list = paragraph.startNewList();

        values.slice(1).forEach((v) => list.insertParagraph(v, 'End'));
      },
      options
    );
  }

  private insertText(id: string, value: string, options: InsertOptions): Observable<void> {
    return this.insert(id, (c) => c.insertText(value, 'Start'), options);
  }

  private run<T>(execute: (context: Word.RequestContext) => Promise<T>): Observable<T> {
    return defer(() => from(Word.run(execute)));
  }

  private setColor(control: Word.ContentControl, color: EmbedderFontColors): void {
    control.font.set({ color });
  }

  private setHighlightColor(control: Word.ContentControl, color?: EmbedderHighlightColors | null): void {
    const highlightColor =
      color || (this.highlightControls ? EmbedderHighlightColors.standard : EmbedderHighlightColors.none);
    control.font.set({ highlightColor });
  }
}
