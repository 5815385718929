import { v4 as uuidv4 } from 'uuid';

import { CreatedUpdatedBuilder } from './created-updated-builder';
import { PlatformValueGroupSetStatus, VGSetStatusContext, ValueGroupSetStatusTransition } from '../models';

export class ValueGroupSetStatusTransitionBuilder extends CreatedUpdatedBuilder<ValueGroupSetStatusTransition> {
  constructor() {
    super({
      id: uuidv4(),
      value_group_set_id: uuidv4(),
      from_status: PlatformValueGroupSetStatus.NOT_STARTED,
      to_status: PlatformValueGroupSetStatus.IN_PROGRESS,
      from_status_context: undefined,
      to_status_context: undefined,
    });
  }

  public fromStatus(fromStatus: PlatformValueGroupSetStatus): ValueGroupSetStatusTransitionBuilder {
    this._instance.from_status = fromStatus;
    return this;
  }

  public fromStatusContext(fromStatusContext?: VGSetStatusContext): ValueGroupSetStatusTransitionBuilder {
    this._instance.from_status_context = fromStatusContext;
    return this;
  }

  public id(id: string): ValueGroupSetStatusTransitionBuilder {
    this._instance.id = id;
    return this;
  }

  public toStatus(toStatus: PlatformValueGroupSetStatus): ValueGroupSetStatusTransitionBuilder {
    this._instance.to_status = toStatus;
    return this;
  }

  public toStatusContext(toStatusContext?: VGSetStatusContext): ValueGroupSetStatusTransitionBuilder {
    this._instance.to_status_context = toStatusContext;
    return this;
  }

  public valueGroupSetId(valueGroupSetId: string): ValueGroupSetStatusTransitionBuilder {
    this._instance.value_group_set_id = valueGroupSetId;
    return this;
  }
}
