import { Validator } from '../models';

export class ValidatorBuilder {
  private readonly _validator: Validator;

  constructor() {
    this._validator = {
      id: 'validatorId',
      validator_type: 'max_length',
      value: '100',
    };
  }

  validatorType(validatorType: string): ValidatorBuilder {
    this._validator.validator_type = validatorType;
    return this;
  }

  instructions(instructions: string | string[] | object): ValidatorBuilder {
    this._validator.instructions = instructions;
    return this;
  }

  build(): Validator {
    return this._validator;
  }
}
