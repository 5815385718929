import { Component, Input } from '@angular/core';
import { Required } from '../../../decorators/required.decorator';

@Component({
  selector: 'lib-simple-list',
  templateUrl: './simple-list.component.html',
  styleUrls: ['./simple-list.component.scss'],
})
export class SimpleListComponent {
  @Input() listTitle?: string;
  @Input() @Required() listItems!: string[];
}
