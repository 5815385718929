import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';
import { CDPErrorType, JobQueueOutputCDPReportSubmissionFileError } from '../models';

export class CdpJobQueueOutputFileErrorBuilder {
  private readonly _cdpJobQueueOutputFileError: JobQueueOutputCDPReportSubmissionFileError;

  constructor() {
    this._cdpJobQueueOutputFileError = {
      error_type: CDPErrorType.FILE_UPLOAD,
      error_message: faker.random.word(),
      question_id: 1234,
      filename: faker.random.word(),
      document_id: uuidv4(),
      question: faker.random.word(),
      metric_code: faker.random.word(),
      metric_name: faker.random.word(),
    };
  }

  error_message(error_message: string): CdpJobQueueOutputFileErrorBuilder {
    this._cdpJobQueueOutputFileError.error_message = error_message;
    return this;
  }

  question_id(question_id: number): CdpJobQueueOutputFileErrorBuilder {
    this._cdpJobQueueOutputFileError.question_id = question_id;
    return this;
  }

  filename(filename: string): CdpJobQueueOutputFileErrorBuilder {
    this._cdpJobQueueOutputFileError.filename = filename;
    return this;
  }

  document_id(document_id: string): CdpJobQueueOutputFileErrorBuilder {
    this._cdpJobQueueOutputFileError.document_id = document_id;
    return this;
  }

  question(question: string): CdpJobQueueOutputFileErrorBuilder {
    this._cdpJobQueueOutputFileError.question = question;
    return this;
  }

  metric_code(metric_code: string): CdpJobQueueOutputFileErrorBuilder {
    this._cdpJobQueueOutputFileError.metric_code = metric_code;
    return this;
  }

  metric_name(metric_name: string): CdpJobQueueOutputFileErrorBuilder {
    this._cdpJobQueueOutputFileError.metric_name = metric_name;
    return this;
  }

  build(): JobQueueOutputCDPReportSubmissionFileError {
    return this._cdpJobQueueOutputFileError;
  }
}
