import { FeatureFlags, Permission } from '../models';

export interface NavigationMenu {
  label: string;
  routerLink?: string;
  featureFlag?: keyof FeatureFlags | (keyof FeatureFlags)[];
  permissions?: Permission[];
  externalLink?: string;
  iconName?: string;
}

export interface NavigationGroup {
  options: NavigationMenu[];
  groupLabel?: string;
  featureFlag?: keyof FeatureFlags;
  permissions?: Permission[];
}
