import { Observable } from 'rxjs';
import {
  ApiResponse,
  TemplateReportVersion,
  GetTemplateReportQueryParams,
  TemplateReport,
  TemplateReportSection,
  CreateTemplateReportSectionPayload,
  TemplateReportVersionPayload,
  TemplateReportVersionsFilteringOptions,
  TemplateReportCheckResponse,
  UpdateTemplateReportSectionMetricPayload,
  DisplayedValue,
  DisplayedValuePayload,
} from '../../../models';

export abstract class TemplateReportsApiService {
  public readonly template$?: Observable<TemplateReport | undefined>;

  abstract getTemplateReportSections(
    templateReportId: string,
    templateReportVersionId?: string
  ): Observable<ApiResponse<TemplateReportSection[]>>;

  abstract activateTemplateReportVersion(
    templateReportId: string,
    templateReportVersionId: string
  ): Observable<ApiResponse<TemplateReportVersion>>;

  abstract deactivateTemplateReportVersion(
    templateReportId: string,
    templateReportVersionId: string
  ): Observable<ApiResponse<TemplateReportVersion>>;

  abstract getTemplateReport(
    templateReportId: string,
    queryParams: GetTemplateReportQueryParams
  ): Observable<ApiResponse<TemplateReport>>;

  abstract createTemplateReportSection(
    templateReportId: string,
    templateReportSectionPayload: CreateTemplateReportSectionPayload,
    templateReportVersionId?: string
  ): Observable<ApiResponse<TemplateReportSection>>;

  abstract editTemplateReportSection(
    templateReportId: string,
    templateReportSectionId: string,
    templateReportSectionPayload: CreateTemplateReportSectionPayload,
    templateReportVersionId?: string
  ): Observable<ApiResponse<TemplateReportSection>>;

  abstract deleteTemplateReportSection(
    templateReportId: string,
    templateReportSectionId: string,
    templateReportVersionId?: string
  ): Observable<ApiResponse<TemplateReportSection[]>>;

  abstract addMetricToTemplateReportSection(
    templateReportId: string,
    templateReportSectionId: string,
    metricId: string,
    placeholderCode?: string | null,
    placeholderDescription?: string | null,
    templateReportVersionId?: string
  ): Observable<ApiResponse<TemplateReportSection[]>>;

  abstract updateMetricToTemplateReportSection(
    templateReportId: string,
    templateReportSectionId: string,
    metricId: string,
    updateTemplateReportSectionMetricPayload: UpdateTemplateReportSectionMetricPayload,
    templateReportVersionId?: string
  ): Observable<ApiResponse<TemplateReportSection[]>>;

  abstract removeMetricFromTemplateReportSection(
    templateReportId: string,
    templateReportSectionId: string,
    metricId: string,
    templateReportVersionId?: string
  ): Observable<ApiResponse<TemplateReportSection[]>>;

  abstract listTemplateReportVersions(
    templateReportId: string,
    payload?: TemplateReportVersionsFilteringOptions
  ): Observable<ApiResponse<TemplateReportVersion[]>>;

  abstract updateTemplateReportVersion(
    templateReportId: string,
    versionId: string,
    payload: TemplateReportVersionPayload
  ): Observable<ApiResponse<TemplateReportVersion>>;

  abstract checkTemplateReportVersionName(
    templateReportId: string,
    name: string
  ): Observable<ApiResponse<TemplateReportCheckResponse>>;

  abstract moveTemplateReportSection(
    templateReportId: string,
    templateReportVersionId: string,
    templateReportSectionId: string,
    position: number
  ): Observable<ApiResponse<TemplateReportSection[]>>;

  abstract moveTemplateReportSectionMetric(
    templateReportId: string,
    templateReportVersionId: string,
    templateReportSectionId: string,
    metricId: string,
    position: number
  ): Observable<ApiResponse<TemplateReportSection[]>>;

  abstract getDisplayedValues(
    templateId: string,
    versionId: string,
    sectionId: string,
    metricId: string
  ): Observable<ApiResponse<DisplayedValue[]>>;

  abstract postDisplayedValues(
    data: DisplayedValuePayload[],
    templateId: string,
    versionId: string,
    sectionId: string,
    metricId: string
  ): Observable<ApiResponse<DisplayedValue[]>>;
}
