import { Pipe, PipeTransform } from '@angular/core';
import { NumberMaskService } from './number-mask.service';

@Pipe({
  name: 'numberMask',
})
export class NumberMaskPipe implements PipeTransform {
  constructor(private readonly numberMaskService: NumberMaskService) {}

  transform(
    value?: number,
    maxNumberDecimal?: number,
    unit?: string,
    padding: boolean = true,
    invertSymbol: boolean = false,
    unitSpace: boolean = false
  ): string {
    return this.numberMaskService.maskNumber(value, maxNumberDecimal, unit, padding, invertSymbol, unitSpace);
  }
}
