import { StandardDocumentMetadata } from '../documents';
import { Doc, RequestDocMetadata } from './entities';
import { DocumentHostEnv } from './type_details';

export interface DocumentPayloadOptions {
  document_id: string;
  document_name?: string;
  host_env?: DocumentHostEnv;
}

export interface DataRequestDocumentPayloadOptions extends DocumentPayloadOptions {
  data_request_id: string;
  data_request_source_id: string;
  metric_id: string;
  value_definition_id: string;
}

export interface DocumentFileData {
  doc?: Doc;
  doc_name: string;
  doc_format: string;
  doc_blob?: Blob;
}

export interface FileDocumentInterface {
  id: string;
  name: string;
  format: string;
  href?: string;
  doc?: Doc | RequestDocMetadata | StandardDocumentMetadata;
}

export interface MinimalDocumentMetaData {
  id: string;
  extension: string;
  deleted?: string;
  name: string;
  original_filename?: string;
  public?: boolean;
}
