import { Injectable } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';
import { map, retryWhen, tap, take, delay, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/common/auth/auth.service';
import { ApiService } from '../services/common/api/api.service';
import { ApplicationApiDefinition } from '../models';
import { Message } from './models';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  apiName: keyof ApplicationApiDefinition = 'messaging';
  resource: string;
  servicePath: string;
  public messages$: Observable<Message<unknown>>;
  private subject!: Subject<Message<unknown>>;
  private connected$ = new Subject<any>();
  private reconnectionAttempts = 10;

  constructor(private authService: AuthService, private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.messaging.resources.websockets;

    this.messages$ = this.getMessage().pipe(
      retryWhen((errors) =>
        errors.pipe(
          tap((err) => console.error('Websocket failed to make a connect. Retrying in 5 seconds.', err.message)),
          delay(5000),
          take(this.reconnectionAttempts)
        )
      )
    );
  }

  // TODO: Make this observable multicast and implement subscription in job-queue.component instead of using the
  //  eventsService to trigger the job-queue reload.
  private getMessage(): Observable<Message<unknown>> {
    return this.getUrl().pipe(
      switchMap((url) =>
        this.connect(url).pipe(map((response) => JSON.parse(response.data as string) as Message<unknown>))
      )
    );
  }

  private getUrl(): Observable<string> {
    return this.getTicket().pipe(
      map(
        (ticket) =>
          `${this.apiService.getWebsocketBaseUrl()}${this.servicePath}${this.resource}/clients/${
            this.authService.user?.client_code
          }?ticket=${ticket}`
      )
    );
  }

  private getTicket(): Observable<string> {
    return this.apiService.get(`${this.servicePath}${this.resource}/tickets`).pipe(map((response) => response.data));
  }

  public connect(url: string): Subject<Message<unknown>> {
    if (!this.subject) {
      this.subject = this.create(url);
      this.connected$.next(true);
    }
    return this.subject;
  }

  public connected(): Observable<any> {
    return this.connected$.asObservable();
  }

  private create(url: string): Subject<Message<unknown>> {
    const ws = new WebSocket(url);

    const observable = new Observable((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });
    const observer = {
      next: (data: Object) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      },
    };
    return Subject.create(observer, observable);
  }
}
