import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class TabTitleService {
  constructor(private titleService: Title, private authService: AuthService) {}

  public updateTabTitle(): void {
    const title = this.authService.user?.org_name === 'Novisto' ? '' : `${this.authService.user?.org_name} - `;

    this.titleService.setTitle(`${title}${this.titleService.getTitle()}`);
  }
}
