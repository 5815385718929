export { AuthRefreshDialogComponent } from './auth-refresh-dialog/auth-refresh-dialog.component';
export { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
export { InfoDialogComponent } from './info-dialog/info-dialog.component';
export { DialogInfoActionsComponent } from './dialog-info-actions/dialog-info-actions.component';
export { TagCannotBeDeletedDialogComponent } from './tag-cannot-be-deleted-dialog/tag-cannot-be-deleted-dialog.component';
export { DialogTitleComponent } from './dialog-title/dialog-title.component';
export { DialogWarningComponent } from './dialog-warning/dialog-warning.component';

export { DialogsModule } from './dialogs.module';
export { DialogsService } from './services/dialogs.service';
