<a
  class="card card-primary"
  role="presentation"
  [attr.aria-label]="item?.id"
  [name]="item?.id"
  [ngClass]="{ disabled: item?.disabled }"
  [title]="item?.title"
  [routerLink]="item?.id"
  [queryParams]="item?.queryParams"
  [state]="{ item: item }"
  [viewerAction]="item?.viewerAction"
  (click)="action.emit()"
>
  <div class="card-header">
    <mat-icon class="icon-lg mb-2" [svgIcon]="item?.icon || ''"></mat-icon>
  </div>
  <div class="card-content nav">
    <div [title]="item?.title" class="text-clamp text-clamp-3">
      {{ item?.title }}
    </div>
  </div>
  <div class="card-footer color-grey-800">
    {{ item?.subtitle }}
  </div>
</a>
