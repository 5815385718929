import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { combineLatestWith, Observable, of } from 'rxjs';
import { ActionItem, ChoiceTypeDetails, SelectionSetItem, ValueDefinition } from '../../../../../../models';
import { map } from 'rxjs/operators';
import { TranslateService } from '../../../../../../services/common';
import { SearchService } from '../../../../../../search';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Required } from '../../../../../../decorators';
import { CustomSelectionListDialogComponent } from '../../../../custom-selection-list-dialog/custom-selection-list-dialog.component';
import { CustomSelectionListDialogConfig } from '../../../../custom-selection-list-dialog/models';
import { DialogsService } from '../../../../../../dialogs';

@Component({
  selector: 'lib-metric-structure-choice-field-selection',
  templateUrl: './metric-structure-choice-field-selection.component.html',
  styleUrls: ['./metric-structure-choice-field-selection.component.scss'],
})
export class MetricStructureChoiceFieldSelectionComponent implements OnInit, OnChanges {
  @Input() @Required() selectionListFormControl!: UntypedFormControl;
  @Input() @Required() valueDefinition!: ValueDefinition<ChoiceTypeDetails>;
  @Input() canCreateCustomChoices: boolean = false;

  @Output() customChoiceAnswersChange: EventEmitter<ActionItem[]> = new EventEmitter<ActionItem[]>();

  public selectionList$?: Observable<ActionItem[]>;
  public customChoiceAnswers: ActionItem[] = [];

  constructor(
    private readonly translateService: TranslateService,
    private readonly searchService: SearchService,
    private readonly dialogsService: DialogsService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.selectionList$ = of({ id: 'custom', title: this.translateService.instant('Custom') } as ActionItem).pipe(
      combineLatestWith(this.searchService.searchResources('selection_set')),
      map(([customOption, selectionSets]) =>
        this.canCreateCustomChoices ? [customOption, ...selectionSets] : selectionSets
      )
    );
  }

  ngOnChanges(): void {
    this.updateAnswers();
  }

  public updateAnswers(): void {
    if (this.selectionListFormControl.value === 'custom') {
      this.setAnswersFromCustomChoices(this.valueDefinition.type_details?.choices);
    } else if (this.selectionListFormControl.value) {
      this.setAnswersFromSelectionSetId(this.selectionListFormControl.value as string);
    } else {
      this.customChoiceAnswers = [];
    }
  }

  public choicesValidator(customChoiceAnswers: ActionItem[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === 'custom' && !customChoiceAnswers.length) {
        return { invalidChoices: true };
      }
      return null;
    };
  }

  private setAnswersFromSelectionSetId(selectionSetId: string): void {
    this.searchService.searchResources('selection_set_item', selectionSetId).subscribe((res) => {
      this.customChoiceAnswers = res.map((customChoiceAnswer: ActionItem<SelectionSetItem>) => ({
        id: customChoiceAnswer.item?.id as string,
        title: customChoiceAnswer.item?.name as string,
      }));
      this.customChoiceAnswersChange.emit(this.customChoiceAnswers);
      this.cdr.detectChanges();
    });
    this.selectionListFormControl.setValidators(Validators.required);
  }

  private setAnswersFromCustomChoices(choices: string[] | null | undefined): void {
    this.customChoiceAnswers =
      choices?.map((choice: string) => ({
        id: choice,
        title: choice,
      })) ?? [];
    this.customChoiceAnswersChange.emit(this.customChoiceAnswers);
    this.selectionListFormControl.setValidators([Validators.required, this.choicesValidator(this.customChoiceAnswers)]);
    this.selectionListFormControl.updateValueAndValidity();
  }

  public launchCustomSelectionDialog(): void {
    const dialogRef = this.dialogsService.open<
      CustomSelectionListDialogComponent,
      CustomSelectionListDialogConfig,
      CustomSelectionListDialogConfig
    >(CustomSelectionListDialogComponent, {
      data: {
        choices: this.customChoiceAnswers.map((customChoice) => customChoice.title),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.choices) {
        this.convertSelectionSetToCustomChoices();
        this.setAnswersFromCustomChoices(result.choices);
        this.selectionListFormControl.markAsDirty();
      }
    });
  }

  private convertSelectionSetToCustomChoices() {
    this.selectionListFormControl.setValue('custom');
  }
}
