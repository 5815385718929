import { Injectable } from '@angular/core';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';

@Injectable({
  providedIn: 'root',
})
export class DragAndDropService {
  private static isInSameContainer<T, O = T>(event: CdkDragDrop<T, O>): boolean {
    return event.previousContainer.id === event.container.id;
  }

  private static hasChangedPosition<T, O = T>(event: CdkDragDrop<T, O>): boolean {
    return event.previousIndex !== event.currentIndex;
  }

  public drop<T, O = T>(
    event: CdkDragDrop<T[], O[]>,
    onAdd: (newItem: O, position: number) => void,
    onMove: (movedItem: T, newPosition: number) => void,
    dummy: T
  ): void {
    const position = event.currentIndex;
    const adjustedPosition = position + 1;

    if (DragAndDropService.isInSameContainer(event)) {
      if (DragAndDropService.hasChangedPosition(event)) {
        moveItemInArray<T>(event.container.data, event.previousIndex, position);
        onMove(event.container.data[position], adjustedPosition);
      }
    } else {
      event.container.data.splice(position, 0, dummy);
      onAdd(event.previousContainer.data[event.previousIndex], adjustedPosition);
    }
  }
}
