import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';

import {
  ConsolidationRules,
  ConsolidationTriggers,
  DecimalTypeDetails,
  DefaultConsolidationRule,
  IntegerTypeDetails,
  MetricTableColumnDefinition,
  MetricTableColumnOption,
  Validator,
  ValueDefinitionType,
} from '../models';
import { MetricTableColumnOptionBuilder } from './metric-table-column-option-builder';

export class MetricTableColumnDefinitionBuilder {
  private readonly _metricTableColumnDefinition: MetricTableColumnDefinition;

  constructor() {
    const columnId = uuidv4();
    const options = faker.helpers.uniqueArray(() => faker.random.words(), faker.datatype.number({ min: 2, max: 10 }));
    this._metricTableColumnDefinition = {
      id: columnId,
      metric_table_definition_id: uuidv4(),
      position: 1,
      label: faker.commerce.productName(),
      type: ValueDefinitionType.label,
      options,
      type_details: {},
      validators: [],
      consolidation_rule: DefaultConsolidationRule,
      consolidation_trigger: null,
      context_options: options.map((o, i) =>
        new MetricTableColumnOptionBuilder()
          .columnId(columnId)
          .label(o)
          .position(i + 1)
          .build()
      ),
    };
  }

  id(id: string): MetricTableColumnDefinitionBuilder {
    this._metricTableColumnDefinition.id = id;
    return this;
  }

  label(label: string): MetricTableColumnDefinitionBuilder {
    this._metricTableColumnDefinition.label = label;
    return this;
  }

  position(position: number): MetricTableColumnDefinitionBuilder {
    this._metricTableColumnDefinition.position = position;
    return this;
  }

  type(type: ValueDefinitionType): MetricTableColumnDefinitionBuilder {
    this._metricTableColumnDefinition.type = type;
    return this;
  }

  typeDetails(typeDetails: IntegerTypeDetails | DecimalTypeDetails) {
    this._metricTableColumnDefinition.type_details = typeDetails;
    return this;
  }

  options(options: string[]): MetricTableColumnDefinitionBuilder {
    this._metricTableColumnDefinition.options = options;
    return this;
  }

  contextOptions(contextOptions: MetricTableColumnOption[]): MetricTableColumnDefinitionBuilder {
    this._metricTableColumnDefinition.context_options = contextOptions;
    return this;
  }

  validators(validators: Validator[]): MetricTableColumnDefinitionBuilder {
    this._metricTableColumnDefinition.validators = validators;
    return this;
  }

  consolidationRule(consolidationRule: ConsolidationRules): MetricTableColumnDefinitionBuilder {
    this._metricTableColumnDefinition.consolidation_rule = consolidationRule;
    return this;
  }

  consolidationTrigger(consolidationTrigger: ConsolidationTriggers | null): MetricTableColumnDefinitionBuilder {
    this._metricTableColumnDefinition.consolidation_trigger = consolidationTrigger;
    return this;
  }

  build(): MetricTableColumnDefinition {
    return this._metricTableColumnDefinition;
  }
}
