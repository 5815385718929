import { AfterContentInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import {
  ApiResponse,
  Metric,
  MetricTableColumnDefinition,
  MetricTableDefinition,
  MetricTableValueSelectionChoice,
  Presentation,
  TableColumn,
  ValueDefinition,
  ValueDefinitionFieldType,
  ValueDefinitionGroup,
  ValueDefinitionType,
} from '../../models';

import { ClientMetricsService } from '../../services/client';
import { ObservableUtils, StringUtils } from '../../classes';
import { TranslateService } from '../../services/common';

import { BehaviorSubject, forkJoin, Observable, of, switchMap } from 'rxjs';
import { combineLatestWith, map, tap } from 'rxjs/operators';
import { ValueDefinitionFieldTypeToText } from './select-metric-value-definition.translation';

interface ValueDefinitionWithFieldTypeRow {
  metricTableDefinition?: MetricTableDefinition;
  valueDefinition?: ValueDefinition;
  label: string;
  fieldType: ValueDefinitionFieldType;
  fieldTypeText?: string;
}
@Component({
  selector: 'lib-select-metric-value-definition',
  templateUrl: './select-metric-value-definition.component.html',
  styleUrls: ['./select-metric-value-definition.component.scss'],
})
export class SelectMetricValueDefinitionComponent implements AfterContentInit {
  @ViewChild('valueDefinitionCell', { static: true }) valueDefinitionCell?: TemplateRef<unknown>;

  @Input() set metricId(metricId: string) {
    this.metricId$.next(metricId);
  }
  @Input() supportedFieldTypes: ValueDefinitionFieldType[] = [];
  @Input() metricTableValueSelection: MetricTableValueSelectionChoice = MetricTableValueSelectionChoice.tableColumn;
  @Output() valueDefinitionSelected = new EventEmitter<ValueDefinition>();
  @Output() metricTableColumnDefinitionSelected = new EventEmitter<MetricTableColumnDefinition>();

  public data$: Observable<{
    metric: Metric;
    filteredValueDefinitionsWithFieldTypeRows: ValueDefinitionWithFieldTypeRow[];
  }>;
  private metricId$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  private searchQuery$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public valueDefinitionsWithFieldTypeRows: ValueDefinitionWithFieldTypeRow[] = [];
  public valueDefinitionTableColumns: TableColumn<ValueDefinitionWithFieldTypeRow>[] = [];
  public selectedTable?: MetricTableDefinition;

  public readonly eValueDefinitionFieldType = ValueDefinitionFieldType;
  public readonly eMetricTableValueSelectionChoice = MetricTableValueSelectionChoice;
  public readonly ePresentation = Presentation;

  constructor(private translateService: TranslateService, private readonly clientMetricsService: ClientMetricsService) {
    this.data$ = this.fetchMetric().pipe(
      combineLatestWith(this.searchQuery$),
      switchMap(([metric, search]) =>
        forkJoin({
          metric: of(metric),
          filteredValueDefinitionsWithFieldTypeRows: this.fetchValueDefinitionWithFieldTypeRows(metric, search),
        })
      )
    );
  }

  ngAfterContentInit(): void {
    this.setupColumns();
  }

  public filterValueDefinitions(value: string): void {
    this.searchQuery$.next(value);
  }

  public selectValueDefinition(valueDefinitionWithFieldType: ValueDefinitionWithFieldTypeRow) {
    if (valueDefinitionWithFieldType.fieldType == ValueDefinitionFieldType.table) {
      this.selectedTable = valueDefinitionWithFieldType.metricTableDefinition;
    } else {
      this.valueDefinitionSelected.emit(valueDefinitionWithFieldType.valueDefinition);
    }
  }

  public selectValueDefinitionFromTableCell(valueDefinition: ValueDefinition | undefined) {
    this.valueDefinitionSelected.emit(valueDefinition);
  }

  public selectTableInputColumn(metricTableColumnDefinition: MetricTableColumnDefinition) {
    this.metricTableColumnDefinitionSelected.emit(metricTableColumnDefinition);
  }

  private metricValueDefinitionGroupsToRows(
    metricValueDefinitionGroups?: ValueDefinitionGroup[]
  ): ValueDefinitionWithFieldTypeRow[] {
    return (
      metricValueDefinitionGroups?.reduce((acc: ValueDefinitionWithFieldTypeRow[], vdg: ValueDefinitionGroup) => {
        if (vdg.repeatable) {
          const repeateableValueDefs =
            vdg.value_definitions?.map((value_definition) => ({
              valueDefinition: value_definition,
              fieldType: ValueDefinitionFieldType.repeatable_group,
              label: value_definition.label ?? '',
              tableAction: { type: ValueDefinitionFieldType.repeatable_group },
            })) ?? [];

          acc.push(...repeateableValueDefs);
        } else if (vdg.table_id) {
          if (vdg.is_calculation) {
            const valueDef =
              vdg.value_definitions?.map((value_definition) => this.convertValueDefinition(value_definition)) || [];

            acc.push(...valueDef);
          }
        } else {
          const valueDef =
            vdg.value_definitions?.map((value_definition) => this.convertValueDefinition(value_definition)) || [];

          acc.push(...valueDef);
        }

        return acc;
      }, []) ?? []
    );
  }

  private convertValueDefinition(valueDefinition: ValueDefinition): ValueDefinitionWithFieldTypeRow {
    const textarea = ValueDefinitionFieldType.textarea;
    const rich_text = ValueDefinitionFieldType.rich_text;
    const has_table_calculation_id = valueDefinition.metric_table_calculation_field_definition_id != null;
    let fieldType = this.convert(valueDefinition.type);

    if (fieldType === ValueDefinitionFieldType.calculated && has_table_calculation_id) {
      fieldType = ValueDefinitionFieldType.table_total;
    } else if (textarea in valueDefinition.type_details && valueDefinition.type_details[textarea]) {
      fieldType = valueDefinition.type_details[rich_text] ? rich_text : textarea;
    }

    return {
      valueDefinition,
      fieldType,
      label: valueDefinition.label ?? '',
    };
  }

  private setupColumns(): void {
    this.valueDefinitionTableColumns = [
      {
        name: this.translateService.instant('Field Name'),
        dataKey: 'label',
        width: '80%',
      },
      {
        name: this.translateService.instant('Field Type'),
        dataKey: 'fieldTypeText',
        cellTemplate: this.valueDefinitionCell,
        width: '20%',
      },
    ];
  }

  private convert(valueDefinitionType: ValueDefinitionType) {
    return ValueDefinitionFieldType[ValueDefinitionType[valueDefinitionType] as keyof typeof ValueDefinitionFieldType];
  }

  private filterUnsupportedTypesOnActiveFields(
    valueDefinitionsWithFieldType: ValueDefinitionWithFieldTypeRow[]
  ): ValueDefinitionWithFieldTypeRow[] {
    return valueDefinitionsWithFieldType.filter(
      (valueDefinitionWithFieldType: ValueDefinitionWithFieldTypeRow) =>
        this.supportedFieldTypes.includes(valueDefinitionWithFieldType.fieldType) &&
        valueDefinitionWithFieldType.valueDefinition?.active
    );
  }

  private getMetricTablesRowsFromVDG$(metric: Metric): Observable<ValueDefinitionWithFieldTypeRow[]> {
    const dataTablesSet = new Set<string>();

    metric.value_definition_groups?.forEach((vdg: ValueDefinitionGroup) => {
      if (vdg.table_id) {
        dataTablesSet.add(vdg.table_id);
      }
    });

    if (dataTablesSet.size == 0) {
      return of(this.valueDefinitionsWithFieldTypeRows);
    }

    const getMetricTableRequests: Observable<MetricTableDefinition>[] = [];

    dataTablesSet.forEach((tableId) => {
      getMetricTableRequests.push(
        this.clientMetricsService
          .getMetricTable(metric.id, tableId)
          .pipe(map((response: ApiResponse<MetricTableDefinition>) => response.data))
      );
    });

    return forkJoin(getMetricTableRequests).pipe(
      map((metricTableDefinitions: MetricTableDefinition[]) => {
        const metricTableValueDefinitionWithFieldTypeRows = metricTableDefinitions.map((metricTableDefinition) => ({
          metricTableDefinition,
          fieldType: ValueDefinitionFieldType.table,
          label: metricTableDefinition.title,
          tableAction: { type: ValueDefinitionFieldType.table },
        }));

        this.valueDefinitionsWithFieldTypeRows = [
          ...this.valueDefinitionsWithFieldTypeRows,
          ...metricTableValueDefinitionWithFieldTypeRows,
        ];

        return [...this.valueDefinitionsWithFieldTypeRows];
      })
    );
  }

  private fetchMetric(): Observable<Metric> {
    return this.metricId$.pipe(
      ObservableUtils.filterNullish(),
      switchMap((metricId: string) =>
        this.clientMetricsService.getMetric(metricId).pipe(
          map((response: ApiResponse<Metric>) => response.data),
          tap(() => {
            this.selectedTable = undefined;
          })
        )
      )
    );
  }

  private fetchValueDefinitionWithFieldTypeRows(
    metric: Metric,
    searchQuery: string
  ): Observable<ValueDefinitionWithFieldTypeRow[]> {
    let obs: Observable<ValueDefinitionWithFieldTypeRow[]>;
    const valueDefinitionRows = this.metricValueDefinitionGroupsToRows(metric.value_definition_groups);

    this.valueDefinitionsWithFieldTypeRows = this.filterUnsupportedTypesOnActiveFields(valueDefinitionRows);

    if (this.supportedFieldTypes.includes(ValueDefinitionFieldType.table)) {
      obs = this.getMetricTablesRowsFromVDG$(metric);
    } else {
      obs = of(this.valueDefinitionsWithFieldTypeRows);
    }

    return obs.pipe(
      map((valueDefinitionsWithFieldType) =>
        valueDefinitionsWithFieldType.filter((valueDefinitionsWithFieldType: ValueDefinitionWithFieldTypeRow) =>
          StringUtils.includesSubstring(String(valueDefinitionsWithFieldType.label), searchQuery)
        )
      ),
      map((valueDefinitionsWithFieldTypes: ValueDefinitionWithFieldTypeRow[]) =>
        valueDefinitionsWithFieldTypes.map((row: ValueDefinitionWithFieldTypeRow) => ({
          ...row,
          fieldTypeText: ValueDefinitionFieldTypeToText[row.fieldType],
        }))
      )
    );
  }
}
