import {
  DataRequest,
  DataRequestIndicatorLink,
  DataRequestSource,
  DataRequestStatus,
  DataRequestToolPanelVisibilityType,
  DataRequestType,
} from '../models';
import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';
import { EntityBuilder } from './entity-builder';
import { formatDate } from '@angular/common';
import { BUILDER_DATE_FORMAT, BUILDER_LOCAL } from './builder-utils';

export class DataRequestBuilder extends EntityBuilder<DataRequest> {
  constructor() {
    super({
      frequency_code: faker.date.past().getFullYear().toString(),
      id: uuidv4(),
      indicator_links: [],
      name: faker.name.jobDescriptor(),
      status: DataRequestStatus.ACTIVE,
      code: faker.name.jobDescriptor(),
      active: true,
      updated_by: uuidv4(),
      sources: [],
      updated: faker.date.past(),
      introduction: faker.lorem.sentences(2),
      conclusion: faker.lorem.sentences(2),
      show_historical_data_to: DataRequestToolPanelVisibilityType.ALL,
      show_recommendations_to: DataRequestToolPanelVisibilityType.ALL,
      due_date: formatDate(faker.date.future(), BUILDER_DATE_FORMAT, BUILDER_LOCAL),
      type: DataRequestType.REGULAR,
      allow_approvers_to_edit: true,
      allow_reviewers_to_edit: true,
      hide_metric_codes: true,
      prefill_frequency_code: '2023',
    });
  }

  id(id: string): DataRequestBuilder {
    this._instance.id = id;
    return this;
  }

  status(status: DataRequestStatus): DataRequestBuilder {
    this._instance.status = status;
    return this;
  }

  indicatorLinks(indicatorLinks: DataRequestIndicatorLink[]): DataRequestBuilder {
    this._instance.indicator_links = indicatorLinks;
    return this;
  }

  sources(sources: DataRequestSource[]): DataRequestBuilder {
    this._instance.sources = sources;
    return this;
  }

  name(name: string): DataRequestBuilder {
    this._instance.name = name;
    return this;
  }

  frequencyCode(frequencyCode: string): DataRequestBuilder {
    this._instance.frequency_code = frequencyCode;
    return this;
  }

  type(type: DataRequestType): DataRequestBuilder {
    this._instance.type = type;
    return this;
  }

  dueDate(dueDate: string | undefined): DataRequestBuilder {
    this._instance.due_date = dueDate;
    return this;
  }

  reportId(reportId: string): DataRequestBuilder {
    this._instance.report_id = reportId;
    return this;
  }

  introduction(introduction: string): DataRequestBuilder {
    this._instance.introduction = introduction;
    return this;
  }

  conclusion(conclusion: string): DataRequestBuilder {
    this._instance.conclusion = conclusion;
    return this;
  }

  prefill_frequency_code(prefill_frequency_code?: string): DataRequestBuilder {
    this._instance.prefill_frequency_code = prefill_frequency_code;
    return this;
  }
}
