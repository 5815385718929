<div class="select-table-cell">
  <ng-container *ngIf="!contextColumnOption">
    <div class="table-info-container">
      <button lib-button type="cancel" class="btn return-to-fields" (click)="backToFields()">
        <mat-icon svgIcon="arrow-head-left"></mat-icon>
        {{ "Fields" | translate }}
      </button>
      <p class="table-title">{{ metricTableDefinition.title }}</p>
      <div class="table-context-wrapper">
        <p class="table-context-columns-title">{{ "Context columns: " | translate }}</p>
        <p class="table-context-columns-content">{{ tableContextColumns }}</p>
      </div>
    </div>

    <lib-data-table-remote-data
      data-testid="contextSelection"
      [tableColumns]="contextColumns"
      [tableData]="contextRows"
      [isPaginable]="false"
      (selectRow)="handleSelectContextRow($event)"
      [showRowSelection]="true"
    ></lib-data-table-remote-data>
  </ng-container>

  <ng-container *ngIf="contextColumnOption">
    <div class="table-info-container">
      <button lib-button type="cancel" class="btn return-to-fields" (click)="backToContextColumns()">
        <mat-icon svgIcon="arrow-head-left"></mat-icon>
        {{ "Table context columns" | translate }}
      </button>
      <p class="table-title">{{ metricTableDefinition.title }}</p>
      <div class="table-context-wrapper">
        <p class="table-context-columns-title">{{ "Context column option: " | translate }}</p>
        <p class="table-context-columns-content">{{ contextColumnOption.contextColumnOption }}</p>
      </div>
    </div>

    <lib-data-table-remote-data
      data-testid="inputSelection"
      [tableColumns]="inputColumns"
      [tableData]="inputRows"
      [isPaginable]="false"
      (selectRow)="handleSelectInputRow($event)"
      [showRowSelection]="true"
    ></lib-data-table-remote-data>
  </ng-container>
</div>
