<div class="field-position-relative" [ngSwitch]="valueDefinitionDisplayType">
  <div
    [class]="'field-focus ' + (valueFormControl.valueRef | valueDefinitionFieldSize)"
    [libIsActiveField]="focusedField$ | async"
    [focusFieldUniqueId]="focusFieldUniqueId$ | async"
    [value]="valueFormControl.valueRef"
    [focusId]="focusId"
    [ngClass]="{
      editable: isEditable && isFocusEnabled,
      'has-comments':
        this.valueFormControl.valueRef.unresolved_regular_comments_and_replies ||
        !!this.valueFormControl.valueRef.unresolved_rejection_comments_and_replies
    }"
    (click)="selectFieldForFocus()"
    data-testid="field-focus"
  >
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.subtitle">
      <lib-metric-editor-subtitle [valueFormControl]="valueFormControl"></lib-metric-editor-subtitle>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.tip">
      <lib-metric-editor-tip [valueFormControl]="valueFormControl"></lib-metric-editor-tip>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.text_simple">
      <lib-metric-editor-text-field [valueFormControl]="valueFormControl" #valueFieldRef></lib-metric-editor-text-field>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.text_area">
      <lib-metric-editor-text-area-field
        [valueFormControl]="valueFormControl"
        #valueFieldRef
      ></lib-metric-editor-text-area-field>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.text_rich">
      <lib-metric-editor-rich-text
        (editorBlur)="onBlur()"
        [valueFormControl]="valueFormControl"
        #valueFieldRef
      ></lib-metric-editor-rich-text>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.document">
      <lib-metric-editor-document-link
        [valueFormControl]="valueFormControl"
        [documentContext]="documentContext"
      ></lib-metric-editor-document-link>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.file">
      <lib-metric-editor-file-attachment
        [valueFormControl]="valueFormControl"
        [documentContext]="documentContext"
        #valueFieldRef
      ></lib-metric-editor-file-attachment>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.file_v2">
      <lib-metric-editor-file-attachment-v2
        [valueFormControl]="valueFormControl"
        [documentContext]="documentContext"
        #valueFieldRef
      ></lib-metric-editor-file-attachment-v2>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.boolean">
      <lib-metric-editor-boolean [valueFormControl]="valueFormControl" #valueFieldRef></lib-metric-editor-boolean>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.date">
      <lib-metric-editor-date-field [valueFormControl]="valueFormControl" #valueFieldRef></lib-metric-editor-date-field>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.metric">
      <lib-metric-editor-metric-link
        [valueFormControl]="valueFormControl"
        (metricLinkEdit)="metricLinkEdit.emit($event)"
      >
      </lib-metric-editor-metric-link>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <lib-metric-editor-choice-field
        *ngIf="valueDefinitionDisplayType && isChoiceField(valueDefinitionDisplayType)"
        [valueFormControl]="valueFormControl"
        [isPublic]="documentContext.itemType === eItemType.public_data_requests_request"
        #valueFieldRef
      ></lib-metric-editor-choice-field>
      <lib-metric-editor-numeric-field
        *ngIf="valueDefinitionDisplayType && isNumericField(valueDefinitionDisplayType)"
        [valueFormControl]="valueFormControl"
        [indicatorId]="indicatorId"
        [vgsetId]="vgsetId"
        [groupId]="groupId"
        [itemType]="documentContext.itemType"
        #valueFieldRef
      ></lib-metric-editor-numeric-field>
    </ng-container>

    <ng-container *libFeatureFlag="{ featureFlags: ['field_comments_enabled'] }">
      <div
        lib-field-comment-counter
        *ngIf="
          valueFormControl.valueRef.unresolved_regular_comments_and_replies ||
          !!valueFormControl.valueRef.unresolved_rejection_comments_and_replies
        "
        class="comments-count"
        [unresolved_regular_comments_and_replies]="valueFormControl.valueRef.unresolved_regular_comments_and_replies"
        [unresolved_rejection_comments_and_replies]="
          valueFormControl.valueRef.unresolved_rejection_comments_and_replies
        "
      ></div>
      <div
        lib-field-actions
        data-testid="fieldActions"
        *ngIf="displayFieldActions"
        [valueDefinitionType]="valueFormControl.valueRef.type"
        [unresolvedRejectionCommentsAndReplies]="valueFormControl.valueRef.unresolved_rejection_comments_and_replies"
        [valueGroupSetStatus]="valueGroupSetStatus"
        [dataRequestSourceStatus]="dataRequestSourceStatus"
        [collaboratorResponsibility]="collaboratorResponsibility"
      ></div>
    </ng-container>
  </div>
</div>
