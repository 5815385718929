import { Component, DoCheck, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { Required } from '../../../decorators';
import { ValueFormControl } from '../../models/valueFormControl';
import { ChoiceFieldWidgetType, ChoiceTypeDetails, ValueDefinitionSize } from '../../../models';
import { SearchService } from '../../../search';
import {
  MultiSelectChipInputComponent,
  SearchableSelectInputParameters,
  SearchableSelectInputComponent,
} from '../../../components';
import { MetricEditorCheckboxFieldComponent } from './metric-editor-checkbox-field/metric-editor-checkbox-field.component';
import { MetricEditorRadioFieldComponent } from './metric-editor-radio-field/metric-editor-radio-field.component';
import { ChoiceFormGroup } from './forms/metric-editor-choice-field-form';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { StringUtils } from '../../../classes';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'lib-metric-editor-choice-field',
  templateUrl: './metric-editor-choice-field.component.html',
  styleUrls: ['./metric-editor-choice-field.component.scss'],
})
export class MetricEditorChoiceFieldComponent implements OnInit, OnDestroy, DoCheck {
  @Input() @Required() valueFormControl!: ValueFormControl<ChoiceTypeDetails>;
  @Input() isPublic: boolean = false;

  @ViewChild('inputFieldRef') inputFieldRef!:
    | SearchableSelectInputComponent<string>
    | MetricEditorCheckboxFieldComponent
    | MultiSelectChipInputComponent
    | MetricEditorRadioFieldComponent;

  readonly eChoiceFieldWidgetType = ChoiceFieldWidgetType;

  choiceFormGroup?: ChoiceFormGroup;

  hint: string = '';
  label: string = '';
  widgetType: ChoiceFieldWidgetType = ChoiceFieldWidgetType.single_select;
  multiChoice: boolean = false;
  choicesList$: Observable<string[]> = of([]);
  filteredChoiceList: string[] = [];
  displayExplanation: boolean = false;
  explanationLabel: string = '';
  explanationRequired: boolean = false;
  allowAddOption: boolean = false;
  size: ValueDefinitionSize = ValueDefinitionSize.large;

  constructor(private readonly searchService: SearchService) {}

  ngOnInit(): void {
    this.initProperties();
    this.choiceFormGroup = new ChoiceFormGroup(this.valueFormControl, {
      multiChoice: this.multiChoice,
      displayExplanation: this.displayExplanation,
      explanationRequired: this.explanationRequired,
    });

    this.initChoiceList();
  }

  ngOnDestroy(): void {
    this.updateField();
    this.choiceFormGroup?.destroy();
  }

  ngDoCheck() {
    // TODO: Replace with subscription when this is merged :
    // https://github.com/angular/angular/issues/10887
    this.choiceFormGroup?.syncTouchedStatus();
  }

  setFocus(): void {
    this.inputFieldRef.setFocus();
  }

  private initChoiceList(): void {
    const selectionSetId = this.valueFormControl.valueRef.type_details.selection_set_id;
    if (selectionSetId) {
      const resourceType = this.isPublic ? 'selection_set_item_public' : 'selection_set_item';
      this.choicesList$ = this.searchService.searchResources(resourceType, selectionSetId).pipe(
        map((items) => items.map((item) => item.title)),
        tap((items) => {
          this.filteredChoiceList = items;
        })
      );
    } else {
      this.choicesList$ = of(this.valueFormControl.valueRef.type_details.choices ?? []).pipe(
        tap((items) => {
          this.filteredChoiceList = items;
        })
      );
    }
  }

  private initProperties() {
    this.hint = this.valueFormControl.valueRef.hint ?? '';
    this.label = this.valueFormControl.valueRef.label ?? '';
    this.widgetType = this.valueFormControl.valueRef.type_details.widget_type ?? ChoiceFieldWidgetType.single_select;
    this.displayExplanation = this.valueFormControl.valueRef.type_details.display_explanation ?? false;
    this.explanationLabel = this.valueFormControl.valueRef.type_details.explanation_label ?? '';
    this.explanationRequired = this.valueFormControl.valueRef.type_details.explanation_required ?? false;
    this.size = this.valueFormControl.valueRef.size;
    this.multiChoice = this.valueFormControl.valueRef.type_details.multi_choices ?? false;
    this.allowAddOption = this.valueFormControl.valueRef.type_details.allow_add_option ?? false;
  }

  public addValue(event: MatChipInputEvent): void {
    const value = event.value;
    const currentValues = this.choiceFormGroup?.valuesControl.value as string[] | null;
    this.choiceFormGroup?.valuesControl.setValue([...(currentValues ?? []), value]);
    this.choiceFormGroup?.valuesControl.markAsTouched();
    this.choiceFormGroup?.valuesControl.markAsDirty();
  }

  public filterChoices(choiceList: string[], params?: SearchableSelectInputParameters): void {
    this.filteredChoiceList = choiceList.filter((choice) =>
      StringUtils.includesSubstring(choice, params?.searchValue ?? '')
    );
  }

  public updateField(): void {
    this.choiceFormGroup?.blurAdditionalTextControl();
  }
}
