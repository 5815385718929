import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PublicApiService as AbstractPublicApiService } from '@novisto/common';

import { APIConfigAddon, SupportedEnvironment } from '../../models';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class PublicAuthApiService extends AbstractPublicApiService {
  public apiConfig: APIConfigAddon;

  constructor(http: HttpClient, httpBackend: HttpBackend) {
    super(http, httpBackend);
    this.apiConfig = environment.apiConfig;
  }

  public getBaseUrl(): string {
    return `${this.getProtocol()}${this.getDomain()}`;
  }

  public getUrl(path: string): string {
    return `${this.getBaseUrl()}${path}`;
  }

  private getDomain(): string {
    switch (this.apiConfig.env) {
      case SupportedEnvironment.localhost: {
        return `platform-auth.${SupportedEnvironment.dev}.novisto.net`;
      }
      case SupportedEnvironment.production: {
        return 'platform-auth.novisto.net';
      }
      default: {
        return `platform-auth.${this.apiConfig.env}.novisto.net`;
      }
    }
  }
}
