import { MinimalDocumentMetaData } from '../models';
import { v4 as uuidv4 } from 'uuid';
import { EntityBuilder } from './entity-builder';
import { faker } from '@faker-js/faker';

export class MinimalDocumentMetaDataBuilder extends EntityBuilder<MinimalDocumentMetaData> {
  constructor() {
    const name: string = faker.lorem.slug();
    const extension: string = faker.system.commonFileExt();
    super({
      id: uuidv4(),
      extension,
      name,
      original_filename: `${name}.${extension}`,
    });
  }

  id(id: string): MinimalDocumentMetaDataBuilder {
    this._instance.id = id;
    return this;
  }

  name(name: string): MinimalDocumentMetaDataBuilder {
    this._instance.name = name;
    return this;
  }

  extension(extension: string): MinimalDocumentMetaDataBuilder {
    this._instance.extension = extension;
    return this;
  }

  originalFileName(originalFileName: string): MinimalDocumentMetaDataBuilder {
    this._instance.original_filename = originalFileName;
    return this;
  }

  public(isPublic: boolean): MinimalDocumentMetaDataBuilder {
    this._instance.public = isPublic;
    return this;
  }
}
