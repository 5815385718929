import {
  CDPInvalidConfigurationException,
  CDPInvalidTokenException,
  CDPJobInProgressException,
  SPJobInProgressException,
  PasswordReuseException,
  CDPResourceNotFoundException,
  CDPUnauthorizedException,
  CDPGeneralException,
} from '../../../exceptions';

export const ERRORS_EXCEPTION_MAP = new Map<string, Error>([
  ['sp_job_in_progress_error', new SPJobInProgressException()],
  ['cdp_job_in_progress_error', new CDPJobInProgressException()],
  ['cdp_invalid_configuration_error', new CDPInvalidConfigurationException()],
  ['cdp_invalid_token_error', new CDPInvalidTokenException()],
  ['cdp_resource_not_found_error', new CDPResourceNotFoundException()],
  ['cdp_unauthorized_error', new CDPUnauthorizedException()],
  ['cdp_general_error', new CDPGeneralException()],
  ['password_reuse', new PasswordReuseException()],
]);
