import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { DialogConfig, DialogResult, DialogSize } from '../../models';
import { ComponentType } from '@angular/cdk/overlay';
import { TranslateService } from '../../services/common';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

export interface BaseDialogConfig {
  size?: DialogSize;
  title?: string;
  body?: any;
  modal?: boolean;
  button?: string;
}

type DataDialogConfig<T = any> = T & BaseDialogConfig;

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  constructor(private translateService: TranslateService, private dialog: MatDialog) {}

  open<T, D = any, R = DialogResult>(
    component: ComponentType<T>,
    config?: MatDialogConfig<DataDialogConfig<D>>
  ): MatDialogRef<T, R> {
    if (!config) {
      config = new MatDialogConfig<DataDialogConfig<D>>();
    }

    switch (config.data?.size) {
      case DialogSize.small:
        config.width = DialogConfig.small.width;
        config.maxHeight = DialogConfig.small.maxHeight;
        config.position = DialogConfig.small.position;
        break;
      case DialogSize.large:
        config.maxWidth = DialogConfig.large.maxWidth;
        break;
      case DialogSize.full:
        config.maxWidth = DialogConfig.large.maxWidth;
        config.width = '100%';
        break;
      case DialogSize.fullscreen:
        config.height = '100%';
        config.width = '100%';
        config.maxWidth = 'none';
        break;
      default:
        config.width = DialogConfig.medium.width;
        config.position = DialogConfig.medium.position;
    }

    config.autoFocus = false;

    return this.dialog.open<T, D, R>(component, config);
  }

  info(body: string, title?: string, button?: string): MatDialogRef<InfoDialogComponent> {
    const config = new MatDialogConfig();

    config.data = {
      title,
      body,
      button,
      size: DialogSize.small,
    };

    return this.open<InfoDialogComponent, BaseDialogConfig>(InfoDialogComponent, config);
  }

  error(error: string, title?: string) {
    const config = new MatDialogConfig();

    config.data = {
      title: title ?? this.translateService.instant('Attention!'),
      body: error,
      modal: true,
      button: this.translateService.instant('OK'),
      size: DialogSize.small,
    };

    return this.open<InfoDialogComponent, BaseDialogConfig>(InfoDialogComponent, config);
  }

  modalConfirmation(body: string, title?: string, button?: string) {
    const config = new MatDialogConfig();

    config.data = {
      title,
      body,
      button,
      modal: true,
      size: DialogSize.small,
    };
    config.disableClose = true;
    config.backdropClass = 'dialog-blur';

    return this.open<InfoDialogComponent>(InfoDialogComponent, config);
  }

  modalError(error: string, title?: string) {
    const config = new MatDialogConfig();

    config.data = {
      title,
      error,
      modal: true,
      size: DialogSize.small,
    };
    config.disableClose = true;
    config.backdropClass = 'dialog-blur';

    return this.open<InfoDialogComponent>(InfoDialogComponent, config);
  }
}
