import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ChartColumnPlotOptionStackingType,
  ChartConfig,
  ChartFontSizeValue,
  ChartFontWeightValue,
  ChartType,
  COMPANY_COLOURS,
} from '../../charts';
import { DashboardStackBarRenderingType, DashboardStackChartData, DashboardWidget } from '../../models';
import { UnitSymbolPipe } from '../../pipes';

import * as Highcharts from 'highcharts';
import patternFill from 'highcharts/modules/pattern-fill';
patternFill(Highcharts);

@Component({
  selector: 'lib-chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss'],
  providers: [UnitSymbolPipe],
})
export class ChartWidgetComponent implements OnInit {
  @Input({ required: true }) widget!: DashboardWidget;
  @Input() set showEditMenu(showEditMenu: boolean) {
    this.chartOptions.navigation = {
      buttonOptions: {
        enabled: !showEditMenu,
      },
    };
  }

  @Output() remove: EventEmitter<DashboardWidget> = new EventEmitter<DashboardWidget>();
  @Output() edit: EventEmitter<DashboardWidget> = new EventEmitter<DashboardWidget>();

  readonly DEFAULT_UNIT_CODE: string = 'default';
  readonly EXPORT_FONT_SIZE_STYLE = { fontSize: '12px' };

  NO_PATTERN: string = '';
  STRIPE_PATTERN: string = 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11';
  DOT_PATTERN: string = 'M 0 5 L 5 0 L 10 5 L 5 10 L 0 5';
  COMPANY_PATTERNS: Array<string> = [this.NO_PATTERN, this.STRIPE_PATTERN, this.DOT_PATTERN];
  CATEGORY_LIMIT: number = 30;
  CATEGORIES_PER_PATTERN: number = COMPANY_COLOURS.length;

  public chartOptions: ChartConfig = {
    navigation: {
      buttonOptions: {
        enabled: true,
      },
    },
    series: [],
    exporting: {
      chartOptions: {
        title: {
          style: {
            fontSize: '14px',
            lineHeight: '4px',
            transform: 'translate(0, 0px)',
          },
        },
        subtitle: {
          style: {
            fontSize: '14px',
            lineHeight: '4px',
            transform: 'translate(0, 0px)',
          },
        },
        legend: {
          itemStyle: this.EXPORT_FONT_SIZE_STYLE,
        },
        xAxis: { labels: { style: this.EXPORT_FONT_SIZE_STYLE } },
        yAxis: {
          labels: {
            style: this.EXPORT_FONT_SIZE_STYLE,
          },
          title: {
            style: this.EXPORT_FONT_SIZE_STYLE,
          },
          stackLabels: {
            style: this.EXPORT_FONT_SIZE_STYLE,
          },
        },
        plotOptions: {
          column: {
            dataLabels: {
              style: this.EXPORT_FONT_SIZE_STYLE,
            },
          },
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
            'downloadCSV',
            'downloadXLS',
          ],
        },
      },
    },
  };

  constructor(private readonly unitSymbolPipe: UnitSymbolPipe) {}

  ngOnInit(): void {
    this.buildChart();
  }

  buildChart(): void {
    if (!this.widget.stack_chart_widget) {
      return;
    }

    const chartData: DashboardStackChartData[] = this.widget.stack_chart_widget.stack_chart.chart_data;
    const xLabels: Set<string> = new Set(chartData.map((d: DashboardStackChartData) => d.x_label).sort());
    const yLabels: Set<string> = new Set(chartData.map((d: DashboardStackChartData) => d.y_label).sort());

    // build chart series
    Array.from(yLabels.values()).forEach((yLabel, index) => {
      const data = Array.from(xLabels).map((xLabel: string) => {
        const data: DashboardStackChartData | undefined = chartData.find(
          (data: DashboardStackChartData) => data.x_label === xLabel && data.y_label === yLabel
        );
        return data?.value ?? 0;
      });
      let pattern: string = this.NO_PATTERN;
      if (index < this.CATEGORY_LIMIT) {
        pattern = this.COMPANY_PATTERNS[Math.floor(index / this.CATEGORIES_PER_PATTERN)];
      }
      this.chartOptions.series?.push({
        type: ChartType.column,
        name: yLabel,
        stack: '',
        data,
        color: {
          pattern: {
            path: {
              d: pattern,
              strokeWidth: 3,
            },
            width: 10,
            height: 10,
            opacity: 1,
          },
        } as unknown as Highcharts.PatternObject,
      });
    });

    // configure chart style
    let yAxisTitle: string | undefined;
    let stacking: ChartColumnPlotOptionStackingType = ChartColumnPlotOptionStackingType.normal;
    let tooltipFormat = undefined;

    switch (this.widget.stack_chart_widget.stack_chart.rendering_type) {
      case DashboardStackBarRenderingType.percentage:
        yAxisTitle = this.unitSymbolPipe.transform('percentage');
        stacking = ChartColumnPlotOptionStackingType.percent;
        tooltipFormat = `
          <span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>
        `;
        break;
      case DashboardStackBarRenderingType.absolute:
        if (this.widget.stack_chart_widget.stack_chart.type_details.units !== this.DEFAULT_UNIT_CODE) {
          yAxisTitle = this.unitSymbolPipe.transform(this.widget.stack_chart_widget.stack_chart.type_details.units);
        }
        break;
    }

    this.chartOptions.title = { text: this.widget.metric.code };
    this.chartOptions.subtitle = { text: this.widget.label };
    this.chartOptions.xAxis = {
      categories: Array.from(xLabels),
    };
    this.chartOptions.yAxis = {
      title: {
        text: yAxisTitle,
        style: {
          fontSize: ChartFontSizeValue.common,
          fontWeight: ChartFontWeightValue.common,
        },
      },
      stackLabels: {
        enabled: true,
        style: {
          fontSize: ChartFontSizeValue.common,
        },
      },
    };
    this.chartOptions.plotOptions = {
      column: {
        stacking,
        dataLabels: {
          enabled: true,
          style: {
            fontSize: ChartFontSizeValue.common,
          },
        },
      },
    };
    this.chartOptions.tooltip = {
      style: {
        fontSize: ChartFontSizeValue.common,
      },
    };

    if (tooltipFormat) {
      this.chartOptions.tooltip.pointFormat = tooltipFormat;
    }
  }

  removeItem(item: DashboardWidget): void {
    this.remove.emit(item);
  }

  editItem(item: DashboardWidget): void {
    this.edit.emit(item);
  }
}
