import { DataRequestParticipantsSelection } from '../models';

export class DataRequestParticipantsSelectionBuilder {
  private readonly _dataRequestParticipantsSelection: DataRequestParticipantsSelection;

  constructor() {
    this._dataRequestParticipantsSelection = {
      contributors: false,
      reviewers: false,
      approvers: false,
    };
  }

  contributors(contributors: boolean): DataRequestParticipantsSelectionBuilder {
    this._dataRequestParticipantsSelection.contributors = contributors;
    return this;
  }

  reviewers(reviewers: boolean): DataRequestParticipantsSelectionBuilder {
    this._dataRequestParticipantsSelection.reviewers = reviewers;
    return this;
  }

  approvers(approvers: boolean): DataRequestParticipantsSelectionBuilder {
    this._dataRequestParticipantsSelection.approvers = approvers;
    return this;
  }

  build(): DataRequestParticipantsSelection {
    return this._dataRequestParticipantsSelection;
  }
}
