<div *ngIf="displayIndicatorCode" class="fx-row align-baseline">
  <div
    [title]="indicatorCodeOverride ?? indicator?.code"
    class="mat-body-2 color-grey-800 pr-1"
    data-testid="indicatorCodeTitle"
  >
    {{ indicatorCodeOverride ?? indicator?.code }}
  </div>
  <mat-icon
    *ngIf="important"
    class="icon-md color-error"
    svgIcon="status"
    aria-hidden="false"
    aria-label="important-flag"
  ></mat-icon>
</div>

<div class="justify-start item-title mat-h4 color-primary-900">
  {{ indicatorDescriptionOverride ?? indicator?.description }}
</div>

<div *ngIf="prefilledFrequency" class="fx-row align-center pt-1">
  <span class="color-grey-800">
    {{ "Data was pre-filled from FY{fiscalYear}" | translate : { fiscalYear: prefilledFrequency } }}
  </span>
</div>

<div class="fx-row align-center pt-1">
  <div class="mt-1 color-grey-800 fx-row align-top">
    <mat-spinner *ngIf="updating; else updated" diameter="16" aria-label="spinner-updating"></mat-spinner>
    <ng-template #updated>
      <ng-container *ngIf="!updateFailed; else updateFailedTemplate">
        <mat-icon aria-hidden="false" aria-label="updated" svgIcon="save" class="color-success"></mat-icon>
      </ng-container>
    </ng-template>
    <ng-template #updateFailedTemplate>
      <mat-icon
        aria-hidden="false"
        aria-label="updateFailed"
        [svgIcon]="'close'"
        class="color-error icon-sm"
      ></mat-icon>
    </ng-template>
    <div class="color-grey-900 ml-1" *ngIf="valueGroupSet?.updated; else neverUpdated">
      <div class="mb-1" *ngIf="displayUpdatedBy; else noUser">
        <strong class="w-50 bold"> {{ "Updated" | translate }}: </strong>
        {{
          "{updatedDate} by {user}"
            | translate
              : {
                  updatedDate: valueGroupSet?.updated | timeAgo,
                  user:
                    valueGroupSet?.updated_by
                    | updatedByUser : users
                    | updatedByGreenProject : valueGroupSet?.status_context
                }
        }}
      </div>
      <ng-template #noUser>
        {{ "Updated {updatedDate}" | translate : { updatedDate: valueGroupSet?.updated | timeAgo } }}
      </ng-template>
      <div *ngIf="valueGroupSet?.status && valueGroupSet?.status_context">
        <strong>{{ "Status" | translate }}: </strong>
        <span class="capitalize">{{ vgsStatusToText[valueGroupSet?.status || ""] }}</span> -
        <span>{{ valueGroupSet?.status_context | vgsetStatusContext }}</span>
      </div>
    </div>
    <ng-template #neverUpdated> <div class="ml-1">Never updated</div></ng-template>
  </div>
</div>
