import { CalculatedTypeDetails, CalculatedValueReferences } from '../models';

export class CalculatedTypeDetailsBuilder {
  private readonly _calculatedTypeDetails: CalculatedTypeDetails;

  constructor() {
    this._calculatedTypeDetails = {
      formula: 'sum(a+b)',
      value_refs: {
        a: { metric_id: 'mId', value_definition_group_id: 'vdgId1', value_definition_id: 'vdId1', mandatory: false },
        b: { metric_id: 'mId', value_definition_group_id: 'vdgId2', value_definition_id: 'vdId2', mandatory: false },
      },
    };
  }

  valueRef(valueRef: Record<string, CalculatedValueReferences>): CalculatedTypeDetailsBuilder {
    this._calculatedTypeDetails.value_refs = valueRef;
    return this;
  }

  units(units: string): CalculatedTypeDetailsBuilder {
    this._calculatedTypeDetails.units = units;
    return this;
  }

  family(family: string): CalculatedTypeDetailsBuilder {
    this._calculatedTypeDetails.family = family;
    return this;
  }

  build(): CalculatedTypeDetails {
    return this._calculatedTypeDetails;
  }
}
