import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseValue, ChoiceTypeDetails, DataFormatTemplate, ValueDefinitionDisplayType } from '../../../models';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { map, startWith } from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { UntypedFormControl, FormGroupDirective, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { TranslateService } from '../../../services/common';
import { SearchService } from '../../../search';
import { Required } from '../../../decorators';

@Component({
  selector: 'lib-data-formats-choice-field',
  templateUrl: './data-formats-choice-field.component.html',
  styleUrls: ['./data-formats-choice-field.component.scss'],
})
export class DataFormatsChoiceFieldComponent implements OnInit {
  @Input() controlName!: string;

  @Input()
  @Required()
  set data(dataFormatTemplate: DataFormatTemplate<BaseValue<ChoiceTypeDetails>>) {
    if (dataFormatTemplate) {
      this._data = dataFormatTemplate;
    }
  }

  get data(): DataFormatTemplate<BaseValue<ChoiceTypeDetails>> {
    return this._data;
  }

  @Output() delete: EventEmitter<void> = new EventEmitter<void>();
  @Output() changed: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('searchableSelectInput') searchableSelectInput!: ElementRef<HTMLInputElement>;

  choicesList: string[] = [];
  filteredChoices!: Observable<string[]>;
  requiredErrorMsg = this.translateService.instant('missing value');
  ValueDefinitionType: typeof ValueDefinitionDisplayType = ValueDefinitionDisplayType;
  control!: UntypedFormControl;
  inputControl: UntypedFormControl = new UntypedFormControl();
  private _data!: DataFormatTemplate<BaseValue<ChoiceTypeDetails>>;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private searchService: SearchService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.control = this.rootFormGroup.control.get(this.controlName) as UntypedFormControl;
    this.initializeControl();
  }

  private initializeControl(): void {
    if (this.data.data.required) {
      this.control.setValidators(Validators.required);
    }

    if (this.data.data.type_details?.selection_set_id) {
      const resourceType = 'selection_set_item';
      this.searchService
        .searchResources(resourceType, this.data.data.type_details.selection_set_id)
        .pipe(map((items) => items.map((item) => item.title)))
        .subscribe((choices) => {
          this.choicesList = choices;
        });
    } else {
      this.choicesList = this.data.data.type_details?.choices ?? [];
    }

    if (this.data.dataFormatType == ValueDefinitionDisplayType.choice_searchable) {
      this.updateChoiceList();
    }
  }

  public getDisplayedChoicesList(): string[] {
    const inactiveChoices = this.getInactiveChoices();
    return [...this.choicesList, ...inactiveChoices];
  }

  private getInactiveChoices(): string[] {
    if (Array.isArray(this.data.data.value)) {
      return this.data.data.value.filter((value: string) => value && !this.choicesList.includes(value));
    } else {
      return this.data.data.value && !this.choicesList.includes(this.data.data.value) ? [this.data.data.value] : [];
    }
  }

  public onMultiSelectToggle(event: any, option: string): void {
    if (event.checked && this.data.data.value.indexOf(option) < 0) {
      this.data.data.value.push(option);
    } else {
      this.data.data.value.splice(this.data.data.value.indexOf(option), 1);
    }
    this.control.setValue(this.data.data.value);
    this.changed.emit();
  }

  public removeChoice(choice: string): void {
    const updatedValue = (this.control.value as string[]).filter((v: string) => v !== choice);
    this.control.setValue(updatedValue);
    this.changed.emit();
  }

  public add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim() && this.choicesList.map((c) => c).includes(value)) {
      if (!this.data.data.type_details.multi_choices) {
        this.data.data.value = [];
        this.data.data.value.push(value.trim());
      }
      this.control.setValue(this.data.data.value);
      this.changed.emit();
    }
    if (input) {
      input.value = '';
    }
    this.inputControl.setValue(null);
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.control.value.includes(event.option.value)) {
      const currentValue = !this.data.data.type_details.multi_choices ? [] : (this.control.value as string[]);
      const updatedValue = [...currentValue, event.option.value];
      this.control.setValue(updatedValue);
      this.changed.emit();
      this.searchableSelectInput.nativeElement.value = '';
      this.inputControl.setValue(null);
    }
  }

  deleteValue(): void {
    this.delete.emit();
  }

  private updateChoiceList(): void {
    this.filteredChoices = this.inputControl.valueChanges.pipe(
      startWith(null),
      map((choice: string | null) => (choice ? this.filter(choice) : this.choicesList.slice()))
    );
  }

  private filter(value: string): any {
    if (typeof value == 'string') {
      const filterValue = value.toLowerCase();
      return this.choicesList.filter((choice: string) => choice.toLowerCase().startsWith(filterValue));
    }
  }
}
