export class DateUtils {
  /**
   * Parses the input string into a Date object, mainly skipping the timezone issues encountered when using new Date(string) while
   * parsing date strings from our APIs.
   * Accepts only inputs in the following pattern YYYY-mm-dd.
   * This function only supports UTC dates for now.
   */
  static dateParse(dateString: string): Date {
    // TODO: support more string formats to parse
    const dateChunks = dateString.split('-');
    return new Date(Number(dateChunks[0]), Number(dateChunks[1]) - 1, Number(dateChunks[2]));
  }

  /**
   * Formats the input date in the following pattern YYYY-mm-dd, mainly skipping the timezone issues encountered when using formatDate(date)
   * when sending the date input through our APIs.
   */
  static dateFormat(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    return `${year}-${DateUtils.format2DigitsNumber(month + 1)}-${DateUtils.format2DigitsNumber(day)}`;
  }

  static nextDay(): Date {
    const date = new Date();
    date.setDate(date.getDate() + 1);

    return date;
  }

  private static format2DigitsNumber(number: number): string {
    return String(number).padStart(2, '0');
  }
}
