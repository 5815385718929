import { SearchOptions } from '../models';

export class SearchOptionsBuilder {
  private readonly _searchOptions: SearchOptions;

  constructor() {
    this._searchOptions = {
      query: {
        keywords: '',
      },
      filters: {},
      custom_filters: {},
    };
  }

  query(query: SearchOptions['query']): SearchOptionsBuilder {
    this._searchOptions.query = query;
    return this;
  }

  custom_filters(custom_filters: SearchOptions['custom_filters']): SearchOptionsBuilder {
    this._searchOptions.custom_filters = custom_filters;
    return this;
  }

  build(): SearchOptions {
    return this._searchOptions;
  }
}
