import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import {
  ApiResponse,
  ApplicationApiDefinition,
  JobQueueDefinitionDataRequest,
  JobQueueFilteringOption,
} from '../../../models';
import { ApiService } from '../../common/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ClientJobQueueService {
  private apiName: keyof ApplicationApiDefinition = 'collect';
  private resource: string;
  private servicePath: string;

  constructor(private readonly apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.taskExecutions;
  }

  public getJobQueue(body: JobQueueFilteringOption): Observable<ApiResponse<JobQueueDefinitionDataRequest[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/task_executions`, body);
  }
}
