<div class="content pt-1 pb-4 ph-4" cdkDropList [cdkDropListData]="valueDefinitionTemplates" cdkDropListSortingDisabled>
  <ng-container *ngFor="let valueDefinitionTemplate of valueDefinitionTemplates">
    <div *ngIf="valueDefinitionTemplate.id === 'category'" class="caption-3 mv-3" cdkDrag [cdkDragDisabled]="true">
      {{ valueDefinitionTemplate.name }}
    </div>
    <a
      *ngIf="valueDefinitionTemplate.id !== 'category'"
      class="card card-condensed mb-1"
      [ngClass]="{
        'disabled no-blur': disableFields || valueDefinitionTemplate.disabled || !!(isCreatingField$ | async)
      }"
      cdkDrag
      [cdkDragDisabled]="disableFields"
      [attr.data-testid]="valueDefinitionTemplate.name"
    >
      <div class="fx-row align-center">
        <mat-icon class="icon-md mr-2" [svgIcon]="valueDefinitionTemplate.code || ''"></mat-icon>
        <span>{{ valueDefinitionTemplate.name }}</span>
      </div>
      <div class="fx-row align-center datatype-placeholder" *cdkDragPlaceholder>
        <mat-icon class="icon-md mr-2" [svgIcon]="valueDefinitionTemplate.code || ''"></mat-icon>
        <span>{{ valueDefinitionTemplate.name }}</span>
      </div>
    </a>
  </ng-container>

  <ng-container *ngFor="let actionItem of specialActionItems">
    <div *ngIf="actionItem.id === 'category'" class="caption-3 mv-3">
      {{ actionItem.title }}
    </div>
    <a
      *ngIf="actionItem.id !== 'category' && !actionItem.disabled"
      class="card card-condensed mb-1"
      [ngClass]="{ 'disabled no-blur': disableSpecialTypes || !!(isCreatingField$ | async) }"
      (click)="create(actionItem.id)"
      [attr.data-testid]="actionItem.id"
    >
      <div class="fx-row align-center">
        <mat-icon class="icon-md mr-2" [svgIcon]="actionItem.icon || ''"></mat-icon>
        <span>{{ actionItem.title }}</span>
      </div>
    </a>
  </ng-container>
</div>
