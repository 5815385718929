export class NumberUtils {
  /**
   * Returns the rounded number.
   * @param number The number to round.
   * @param decimalPositionToRound The number of the decimal to round. 0 round to the unit, 1 round to the first decimal, -1 round to the ten unit
   */
  static round(number: number, decimalPositionToRound: number): string {
    const offset = Math.pow(10, decimalPositionToRound);
    return (Math.round(number * offset) / offset).toFixed(decimalPositionToRound > 0 ? decimalPositionToRound : 0);
  }

  static countDecimals(value: number): number {
    const text = value.toString();

    // verify if number 0.000005 is represented as "5e-6"
    if (text.indexOf('e-') > -1) {
      const [base, trail] = text.split('e-');
      return parseInt(trail, 10);
    }

    // count decimals for number in representation like "0.123456"
    if (Math.floor(value) !== value) {
      return value.toString().split('.')[1]?.length || 0;
    }

    return 0;
  }

  static milliseconds(h: number, m: number, s: number): number {
    return (h * 60 * 60 + m * 60 + s) * 1000;
  }
}
