import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { CustomSelectionListDialogConfig } from './models';

@Component({
  selector: 'lib-custom-selection-list-dialog',
  templateUrl: './custom-selection-list-dialog.component.html',
  styleUrls: ['./custom-selection-list-dialog.component.scss'],
})
export class CustomSelectionListDialogComponent {
  choices: { label: string }[] = [];
  isTouched: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<CustomSelectionListDialogComponent, CustomSelectionListDialogConfig>,
    @Inject(MAT_DIALOG_DATA) private data: CustomSelectionListDialogConfig
  ) {
    this.choices = this.data.choices.map((label) => ({ label }));
  }

  onChoicesChanged(choices: { label: string }[]): void {
    this.choices = choices;
    this.isTouched = true;
  }

  save(): void {
    this.dialogRef.close({ choices: this.choices.map((c) => c.label) });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
