import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ValueFormControl } from '../../models/valueFormControl';
import { DateTypeDetails, ValueDefinitionSize } from '../../../models';
import { DateInputComponent } from '../../../components/form/date-input/date-input.component';

@Component({
  selector: 'lib-metric-editor-date-field',
  templateUrl: './metric-editor-date-field.component.html',
  styleUrls: ['./metric-editor-date-field.component.scss'],
})
export class MetricEditorDateFieldComponent implements OnInit, OnDestroy {
  @Input() valueFormControl?: ValueFormControl<DateTypeDetails>;

  @ViewChild(DateInputComponent) inputFieldRef!: DateInputComponent;

  label: string = '';
  hint: string = '';
  placeholder: string = '';
  size: ValueDefinitionSize = ValueDefinitionSize.large;

  ngOnInit(): void {
    if (this.valueFormControl) {
      this.label = this.valueFormControl.valueRef.label ?? '';
      this.placeholder = this.valueFormControl.valueRef.type_details.format;
      this.hint = this.valueFormControl.valueRef.hint ?? '';
      this.size = this.valueFormControl.valueRef.size;
    }
  }

  ngOnDestroy(): void {
    this.updateValue();
  }

  setFocus(): void {
    this.inputFieldRef.setFocus();
  }

  updateValue(): void {
    this.inputFieldRef.setBlur();
  }
}
