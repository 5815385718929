import { Unit } from '../models';

export class UnitBuilder {
  private readonly _unit: Unit;

  constructor() {
    this._unit = {
      id: 'unitId',
      code: 'metric_ton_co2e',
      symbol: 't CO₂e',
      label: 'metric ton of carbon dioxide equivalent',
      family: 'emissions',
      position: 120,
    };
  }

  id(id: string): UnitBuilder {
    this._unit.id = id;
    return this;
  }

  code(code: string): UnitBuilder {
    this._unit.code = code;
    return this;
  }

  symbol(symbol: string): UnitBuilder {
    this._unit.symbol = symbol;
    return this;
  }

  label(label: string): UnitBuilder {
    this._unit.label = label;
    return this;
  }

  family(family: string): UnitBuilder {
    this._unit.family = family;
    return this;
  }

  build(): Unit {
    return this._unit;
  }
}
