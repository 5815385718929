<div class="panel-properties-content-header mh-4">
  <div class="panel-properties-content-header-title fx-row justify-space-between w-100">
    <div class="fx-row justify-center align-center">
      <mat-icon [svgIcon]="fieldHeaderDetails?.code || 'settings'" class="mr-2"></mat-icon>
      <h4 class="mat-h4">{{ fieldHeaderDetails?.name }}</h4>
    </div>
    <div
      class="fx-row justify-center align-center"
      *ngIf="valueDefinition?.id !== eValueDefinitionTemplateType.template"
    >
      <ng-container *libFeatureFlag="{ featureFlags: ['metric_structure_deactivation_enabled'] }">
        <button
          *ngIf="valueDefinition.active; else reactivate"
          lib-button
          type="stroked"
          (click)="deactivateField()"
          [disabled]="(valueDefinitionGroup | isDeactivated) || formDisabled"
        >
          {{ "Deactivate" | translate }}
        </button>
        <ng-template #reactivate>
          <button
            lib-button
            type="stroked"
            (click)="activateField()"
            [disabled]="(valueDefinitionGroup | isDeactivated) || formDisabled"
          >
            {{ "Activate" | translate }}
          </button>
        </ng-template>
      </ng-container>
      <a
        *ngIf="isAdmin || (!isAdmin && metric?.category !== eMetricCategory.THIRD_PARTY && !formDisabled)"
        class="ml-2"
        (click)="deleteField($event)"
        data-testid="deleteFieldButton"
      >
        <mat-icon svgIcon="trash"></mat-icon>
      </a>
    </div>
  </div>
</div>

<div *ngIf="dynamicFieldForm$ | async as dynamicFieldForm" class="panel-properties-content-container fx-col">
  <div #fieldsContainer class="fields-container pb-1">
    <div
      *ngIf="
        (isRepeatableGroup$ | async) === true &&
        (contextFieldPermittedValueDefinitionTypes | includes : valueDefinition.type)
      "
      class="tip mt-2 mb-3 ml-1 mr-2"
    >
      <div class="fx-row align-center">
        <mat-icon svgIcon="warning" class="tip-icon color-warning mr-2"></mat-icon>
      </div>
      <div data-testid="repeatableGroupTip" class="text content-text tip-text-warning mat-body color-grey-900 pl-3">
        {{
          "This field is part of a Repeatable Group. Define this field as Context or Input in order to configure the repeatable group for export in the Group Settings Panel."
            | translate
        }}
      </div>
    </div>
    <div *ngIf="valueDefinition.end_fiscal_year_frequency_code">
      <lib-instructional-text>{{
        "Field deactivated from {fiscalYear} inclusive"
          | translate : { fiscalYear: valueDefinition.end_fiscal_year_frequency_code }
      }}</lib-instructional-text>
    </div>

    <form
      class="form no-gap"
      [formGroup]="dynamicFieldForm"
      [ngClass]="{
        disabled: formDisabled
      }"
      data-testid="form"
    >
      <ng-container
        *ngIf="
          valueDefinition
            | isValueDefinitionOfType : [eValueDefinitionDisplayType.tip, eValueDefinitionDisplayType.calculated]
        "
      >
        <div class="formfield tip large">
          <div class="fx-row align-center">
            <mat-icon svgIcon="info" class="tip-icon color-grey-800 mr-2"></mat-icon>
          </div>
          <div class="text tip-text tip-text-dark mat-body color-grey-900 pl-3">
            <ng-container *ngIf="valueDefinition | isValueDefinitionOfType : [eValueDefinitionDisplayType.tip]">
              {{ "Instructions are only visible in the Metric library." | translate }}
            </ng-container>
            <ng-container *ngIf="valueDefinition | isValueDefinitionOfType : [eValueDefinitionDisplayType.calculated]">
              {{ "Number calculation can execute simple formulas for number fields inside this metric." | translate }}
            </ng-container>
          </div>
        </div>
      </ng-container>

      <div *ngIf="dynamicFieldForm.propertiesControls.label" class="formfield large">
        <lib-text-input
          [label]="'Field label' | translate"
          [control]="dynamicFieldForm.propertiesControls.label"
        ></lib-text-input>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.shortLabel" class="formfield large">
        <lib-text-input
          [label]="'Short label' | translate"
          [control]="dynamicFieldForm.propertiesControls.shortLabel"
          [hint]="'Overrides field label in exports' | translate"
          [maxCharacterCount]="70"
        ></lib-text-input>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.toggleRequired" class="formfield large pb-1">
        <mat-slide-toggle [formControl]="dynamicFieldForm.propertiesControls.toggleRequired!">
          {{ "This field is required" | translate }}
        </mat-slide-toggle>
      </div>

      <div class="formfield large pt-1" *ngIf="dynamicFieldForm.propertiesControls.toggleTextarea">
        <mat-slide-toggle
          [formControl]="dynamicFieldForm.propertiesControls.toggleTextarea"
          (change)="disableDisplay()"
        >
          {{ "Allow text on multiple lines" | translate }}
        </mat-slide-toggle>
      </div>

      <div class="formfield large pt-1" *ngIf="dynamicFieldForm.propertiesControls.toggleMultipleFiles">
        <mat-slide-toggle
          [formControl]="dynamicFieldForm.propertiesControls.toggleMultipleFiles!"
          (change)="toggleMaxFiles($event.checked)"
        >
          {{ "Allow multiple files" | translate }}
        </mat-slide-toggle>
      </div>

      <div
        class="formfield medium ml-8"
        *ngIf="
          dynamicFieldForm.propertiesControls.maxFiles && dynamicFieldForm.propertiesControls.toggleMultipleFiles?.value
        "
      >
        <lib-text-input
          [label]="'File attachment limit' | translate"
          [hint]="'Maximum 50' | translate"
          [control]="dynamicFieldForm.propertiesControls.maxFiles!"
          [messages]="filesLimitErrorMsgs"
          type="number"
        ></lib-text-input>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.placeholder" class="formfield large">
        <lib-text-input
          [label]="'Field placeholder text' | translate"
          [control]="dynamicFieldForm.propertiesControls.placeholder"
        ></lib-text-input>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.hint" class="formfield large">
        <lib-text-input
          [label]="'Field hint' | translate"
          [maxCharacterCount]="
            (valueDefinition | isValueDefinitionOfType : [eValueDefinitionDisplayType.text_rich]) ? 150 : 50
          "
          [control]="dynamicFieldForm.propertiesControls.hint"
        ></lib-text-input>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.characterLimit" class="formfield medium newline">
        <lib-text-input
          [label]="'Character limit' | translate"
          [hint]="'0 for no limit' | translate"
          type="number"
          [control]="dynamicFieldForm.propertiesControls.characterLimit"
          [messages]="characterLimitErrorMsgs"
        ></lib-text-input>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.instruction" class="formfield large required">
        <div class="rich-text-formfield">
          <lib-rich-text-input
            [label]="'Instruction' | translate"
            [control]="dynamicFieldForm.propertiesControls.instruction"
          ></lib-rich-text-input>
        </div>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.subtitle" class="formfield large required">
        <lib-text-input
          [label]="'Subtitle' | translate"
          [hint]="'Required' | translate"
          [maxCharacterCount]="100"
          [control]="dynamicFieldForm.propertiesControls.subtitle"
        ></lib-text-input>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.dateFormat" class="formfield medium required">
        <lib-select-input
          [label]="'Date format' | translate"
          [control]="dynamicFieldForm.propertiesControls.dateFormat"
          [options]="dateOptionsAnswers"
        >
        </lib-select-input>
      </div>

      <ng-container
        *ngIf="
          valueDefinition
            | isValueDefinitionOfType : [eValueDefinitionDisplayType.boolean, eValueDefinitionDisplayType.choice]
        "
      >
        <mat-divider class="large mt-4 ml-2 mr-2"></mat-divider>
        <div class="formfield large tag color-grey-800">{{ "Configuration" | translate }}</div>
      </ng-container>

      <div *ngIf="dynamicFieldForm.propertiesControls.labelTrue" class="formfield large required">
        <lib-text-input
          [label]="'True Option' | translate"
          [control]="dynamicFieldForm.propertiesControls.labelTrue"
        ></lib-text-input>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.promptOnTrue" class="formfield large">
        <lib-slider
          [label]="'Display explanation field for true' | translate"
          [control]="dynamicFieldForm.propertiesControls.promptOnTrue"
          (onSliderToggle)="disableDisplay()"
        >
        </lib-slider>
      </div>

      <div
        *ngIf="
          dynamicFieldForm.propertiesControls.promptOnTrue?.value && dynamicFieldForm.propertiesControls.labelPromptTrue
        "
        class="large required ml-8"
      >
        <lib-slider
          *ngIf="dynamicFieldForm.propertiesControls.requiredOnTrue"
          class="formfield"
          [label]="'True explanation field is required' | translate"
          data-testid="trueExplanationRequired"
          [control]="dynamicFieldForm.propertiesControls.requiredOnTrue"
        >
        </lib-slider>
        <lib-text-input
          [label]="'True explanation field label' | translate"
          [hint]="'This will appear as a textbox with a 5000 characters limit' | translate"
          [control]="dynamicFieldForm.propertiesControls.labelPromptTrue"
          class="formfield"
        ></lib-text-input>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.labelFalse" class="formfield large required">
        <lib-text-input
          [label]="'False Option' | translate"
          [control]="dynamicFieldForm.propertiesControls.labelFalse"
        ></lib-text-input>
      </div>

      <lib-slider
        *ngIf="dynamicFieldForm.propertiesControls.promptOnFalse"
        class="formfield large"
        [label]="'Display explanation field for false' | translate"
        [control]="dynamicFieldForm.propertiesControls.promptOnFalse"
        (onSliderToggle)="disableDisplay()"
      >
      </lib-slider>

      <div
        *ngIf="
          dynamicFieldForm.propertiesControls.promptOnFalse?.value &&
          dynamicFieldForm.propertiesControls.labelPromptFalse
        "
        class="large required ml-8"
      >
        <lib-slider
          *ngIf="dynamicFieldForm.propertiesControls.requiredOnFalse"
          class="formfield"
          [label]="'False explanation field is required' | translate"
          data-testid="falseExplanationRequired"
          [control]="dynamicFieldForm.propertiesControls.requiredOnFalse"
        >
        </lib-slider>
        <lib-text-input
          [label]="'False explanation field label' | translate"
          [hint]="'This will appear as a textbox with a 5000 characters limit' | translate"
          [control]="dynamicFieldForm.propertiesControls.labelPromptFalse"
          class="formfield"
        ></lib-text-input>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.displayUrl" class="formfield label large">
        <span class="title mb-1">{{ "URL" | translate }}</span>
        <lib-slider
          class="pb-1"
          [label]="'Display URL field' | translate"
          [control]="dynamicFieldForm.propertiesControls.displayUrl"
          (onSliderToggle)="disableDisplay()"
        >
        </lib-slider>

        <div
          *ngIf="
            dynamicFieldForm.propertiesControls.displayUrl.value && dynamicFieldForm.propertiesControls.urlRequired
          "
          class="large required"
        >
          <lib-slider
            class="pb-1"
            [label]="'URL is required' | translate"
            [control]="dynamicFieldForm.propertiesControls.urlRequired"
          >
          </lib-slider>
        </div>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.displayPageNumber" class="formfield label large">
        <span class="mb-1">{{ "Page number" | translate }} </span>
        <lib-slider
          class="pb-1"
          [label]="'Display page number' | translate"
          [control]="dynamicFieldForm.propertiesControls.displayPageNumber"
          (onSliderToggle)="disableDisplay()"
        >
        </lib-slider>

        <div
          *ngIf="
            dynamicFieldForm.propertiesControls.displayPageNumber.value &&
            dynamicFieldForm.propertiesControls.pageNumberRequired
          "
        >
          <lib-slider
            class="pb-1"
            [label]="'Page number is required' | translate"
            [control]="dynamicFieldForm.propertiesControls.pageNumberRequired"
          >
          </lib-slider>
        </div>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.displayFileExplanation" class="formfield label large">
        <span class="mb-1">{{ "File explanation" | translate }}</span>
        <lib-slider
          class="pb-1"
          [label]="'Display explanation field for file attachment' | translate"
          [control]="dynamicFieldForm.propertiesControls.displayFileExplanation"
          (onSliderToggle)="disableDisplay()"
        >
        </lib-slider>

        <div *ngIf="dynamicFieldForm.propertiesControls.displayFileExplanation.value">
          <lib-slider
            class="pb-1"
            [label]="'File explanation field is required' | translate"
            [control]="dynamicFieldForm.propertiesControls.explanationFileRequired"
          >
          </lib-slider>
          <lib-text-input
            [label]="'File explanation field label' | translate"
            [hint]="'This will appear as a textbox with a 5000 characters limit' | translate"
            [control]="dynamicFieldForm.propertiesControls.explanationFileLabel"
            class="formfield"
          ></lib-text-input>
        </div>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.type" class="formfield large required">
        <lib-radio-buttons
          [control]="dynamicFieldForm.propertiesControls.type"
          [choices]="numberTypeChoices"
          (onChoiceSelection)="selectNumberType($event)"
        ></lib-radio-buttons>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.unitFamily" class="formfield large required">
        <lib-select-input
          [label]="'Unit family' | translate"
          [control]="dynamicFieldForm.propertiesControls.unitFamily"
          [options]="(unitFamilies$ | async) ?? []"
          (selectionChangeEvent)="onUnitFamilyChange(dynamicFieldForm.propertiesControls.unitFamily.value)"
        ></lib-select-input>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.units" class="formfield large required">
        <lib-select-input
          [label]="'Unit default' | translate"
          [control]="dynamicFieldForm.propertiesControls.units"
          [options]="(unitDefaults$ | async) ?? []"
        ></lib-select-input>
      </div>

      <div
        *ngIf="
          dynamicFieldForm.propertiesControls.decimals &&
          (dynamicFieldForm.propertiesControls.type?.value === eValueDefinitionDisplayType.decimal ||
            (valueDefinition | isValueDefinitionOfType : [eValueDefinitionDisplayType.calculated]))
        "
        class="formfield medium required"
      >
        <lib-select-input
          [label]="'Decimals' | translate"
          [control]="dynamicFieldForm.propertiesControls.decimals"
          [options]="numericDecimalOptions"
        ></lib-select-input>
      </div>
      <div *ngIf="dynamicFieldForm.propertiesControls.mandatory" class="formfield large">
        <mat-slide-toggle data-testid="mandatoryToggle" [formControl]="dynamicFieldForm.propertiesControls.mandatory">
          {{ "Calculate when all values are entered" | translate }}
        </mat-slide-toggle>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.minimum" class="formfield medium newline">
        <lib-text-input
          [label]="'Minimum value' | translate"
          type="number"
          [control]="dynamicFieldForm.propertiesControls.minimum"
          [messages]="numberMinMaxErrorMsgs"
        ></lib-text-input>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.maximum" class="formfield medium">
        <lib-text-input
          [label]="'Maximum value' | translate"
          type="number"
          [control]="dynamicFieldForm.propertiesControls.maximum"
          [messages]="numberMinMaxErrorMsgs"
        ></lib-text-input>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.variables" class="formfield large required">
        <lib-metric-structure-calculated-variables
          [variablesFormControl]="dynamicFieldForm.propertiesControls.variables"
          [metric]="metric"
          [valueDefinition]="valueDefinition"
          [units]="(allUnits$ | async) ?? []"
        ></lib-metric-structure-calculated-variables>
      </div>

      <div *ngIf="dynamicFieldForm.propertiesControls.formula" class="formfield large required">
        <lib-text-input
          [label]="'Formula' | translate"
          [control]="dynamicFieldForm.propertiesControls.formula"
          [messages]="numberMinMaxErrorMsgs"
        ></lib-text-input>
      </div>

      <ng-container *ngIf="dynamicFieldForm.propertiesControls.fileSize">
        <mat-divider class="large mt-4 ml-2 mr-2"></mat-divider>

        <div class="formfield medium newline">
          <lib-text-input
            [label]="'File size limit per attachment' | translate"
            [control]="dynamicFieldForm.propertiesControls.fileSize"
            [hint]="'In megabytes' | translate"
            [suffix]="'MB' | translate"
          ></lib-text-input>
        </div>
      </ng-container>

      <lib-metric-structure-document-field-properties
        *ngIf="dynamicFieldForm.propertiesControls.document && dynamicFieldForm.propertiesControls.displayName"
        class="large"
        [linkedDocumentControl]="dynamicFieldForm.propertiesControls.document!"
        [documentDisplayControl]="dynamicFieldForm.propertiesControls.displayName!"
        [valueDefinition]="valueDefinition"
        (updateResource)="updateDocResource($event)"
      ></lib-metric-structure-document-field-properties>

      <lib-metric-structure-choice-field-properties
        *ngIf="valueDefinition | isValueDefinitionOfType : [eValueDefinitionDisplayType.choice]"
        class="large"
        [valueDefinition]="valueDefinition"
      >
      </lib-metric-structure-choice-field-properties>

      <ng-container *ngIf="dynamicFieldForm.propertiesControls.type && staticConsolidationFlag && !isThirdParty()">
        <mat-divider class="large mt-4 ml-2 mr-2"></mat-divider>
        <div class="formfield large tag color-grey-800">
          {{ "Consolidation - Reference & Custom Metrics" | translate }}
        </div>
        <ng-container *ngIf="(isRepeatableGroup$ | async) === true">
          <div class="formfield large color-grey-800">
            {{
              "Consolidation rules must be set to manual for this number field as it is part of a repeatable group"
                | translate
            }}
          </div>
        </ng-container>
        <div class="formfield large required">
          <lib-select-input
            [label]="'Consolidation rule' | translate"
            [control]="dynamicFieldForm.propertiesControls.consolidationRule"
            [options]="consolidationRuleOptions"
            (selectionChangeEvent)="setConsolidationTriggerOptions($event)"
          ></lib-select-input>
        </div>
        <div
          class="formfield large required"
          *ngIf="dynamicFieldForm.propertiesControls.consolidationRule?.value !== 'manual'"
        >
          <lib-select-input
            [label]="'Consolidation trigger' | translate"
            [control]="dynamicFieldForm.propertiesControls.consolidationTrigger"
            [options]="getConsolidationTriggerOptions()"
          ></lib-select-input>
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          valueDefinition
            | isValueDefinitionOfType
              : [
                  eValueDefinitionDisplayType.tip,
                  eValueDefinitionDisplayType.text_area,
                  eValueDefinitionDisplayType.text_simple,
                  eValueDefinitionDisplayType.integer,
                  eValueDefinitionDisplayType.decimal,
                  eValueDefinitionDisplayType.choice,
                  eValueDefinitionDisplayType.choice_multiple,
                  eValueDefinitionDisplayType.choice_searchable,
                  eValueDefinitionDisplayType.choice_radio,
                  eValueDefinitionDisplayType.calculated,
                  eValueDefinitionDisplayType.document,
                  eValueDefinitionDisplayType.date
                ]
        "
      >
        <mat-divider class="large mt-4 ml-2 mr-2"></mat-divider>
        <div class="formfield large tag color-grey-800">{{ "Display options" | translate }}</div>

        <div *ngIf="dynamicFieldForm.propertiesControls.displaySize" class="formfield large required">
          <lib-select-input
            [label]="'Display size' | translate"
            [control]="dynamicFieldForm.propertiesControls.displaySize!"
            [options]="valueDefinitionTypeSizes[valueDefinitionDisplayType]"
            [hint]="'This affects the display size of the field' | translate"
            [messages]="displaySizeErrorMsgs"
          >
          </lib-select-input>
        </div>

        <div *ngIf="dynamicFieldForm.propertiesControls.toggleNewLine" class="formfield large">
          <mat-slide-toggle
            data-testid="newlineToggle"
            [formControl]="dynamicFieldForm.propertiesControls.toggleNewLine"
          >
            {{ "Put this field on a new line" | translate }}
          </mat-slide-toggle>
        </div>

        <div *ngIf="dynamicFieldForm.propertiesControls.instructionCard" class="formfield large">
          <a
            class="card card-condensed mb-1 tip-card"
            *ngFor="let item of getTipDisplayOptions()"
            [ngClass]="{
              active: dynamicFieldForm.propertiesControls.instructionCard.value === item,
              disabled: dynamicFieldForm.propertiesControls.instructionCard.disabled
            }"
            (click)="selectTipDisplayOption(item)"
          >
            <div class="tip">
              <div class="fx-row align-center" *ngIf="item.icon">
                <mat-icon [svgIcon]="item.icon || ''" class="tip-icon mr-2" [class]="'color-' + item.image"> </mat-icon>
              </div>
              <div class="text mat-body color-grey-900 pl-3 tip-text" [class]="'tip-text-' + item.image">
                {{ item.title }}
              </div>
            </div>
          </a>
        </div>
      </ng-container>
    </form>
  </div>
  <div>
    <mat-divider class="pb-4"></mat-divider>

    <div class="form-submit">
      <a
        *ngIf="valueDefinition?.id === eValueDefinitionTemplateType.template; else existingField"
        lib-button
        [type]="'success'"
        [disabled]="!!dynamicFieldForm.invalid"
        [isLoading]="!!(updating$ | async)"
        [label]="'Add' | translate"
        (click)="addField()"
      >
      </a>

      <ng-template #existingField>
        <a
          lib-button
          [type]="'success'"
          [disabled]="!!(dynamicFieldForm.pristine || dynamicFieldForm.invalid)"
          [isLoading]="!!(updating$ | async)"
          [label]="'Save' | translate"
          (click)="saveField()"
        >
        </a>
      </ng-template>

      <a lib-button [type]="'link'" [label]="'Cancel' | translate" (click)="closeProperties()"></a>
    </div>
  </div>
</div>
