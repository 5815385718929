import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'lib-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomToolTipComponent {
  @Input() contentTemplate?: TemplateRef<any>;
}
