import { LicenseeSettings } from '../licensee-settings';
import { DataRequestToolPanelVisibilityType, DueDateNotification } from '../../data-requests';

export interface AppSettings {
  licenseeSettings: LicenseeSettings;
}

export interface ClientDataRequestSettings {
  due_date_notifications: DueDateNotification[];
  show_historical_data_to?: DataRequestToolPanelVisibilityType;
  show_recommendations_to?: DataRequestToolPanelVisibilityType;
  show_audit_trail_panel_to?: DataRequestToolPanelVisibilityType;
  show_source_consolidation_panel_to?: DataRequestToolPanelVisibilityType;
  allow_reviewers_to_edit: boolean;
  allow_approvers_to_edit: boolean;
}
