import { Indicator, Section, SectionDisplay } from '../models';

export class ReportSectionBuilder {
  private readonly _reportSection: Section;

  constructor() {
    this._reportSection = {
      id: 'sec_1',
      name: '',
      position: 1,
      display: [],
      indicators: [],
      code: 'sec_1',
      label: 'section 1',
      parent_id: null,
      children: [],
    };
  }

  code(code: string): ReportSectionBuilder {
    this._reportSection.code = code;
    return this;
  }

  label(label: string): ReportSectionBuilder {
    this._reportSection.label = label;
    return this;
  }

  display(display: SectionDisplay[]): ReportSectionBuilder {
    this._reportSection.display = display;
    return this;
  }

  indicators(indicators: Indicator[]): ReportSectionBuilder {
    this._reportSection.indicators = indicators;
    return this;
  }

  children(children: Section[]): ReportSectionBuilder {
    this._reportSection.children = children;
    return this;
  }

  childrenSections(children: Section[]): ReportSectionBuilder {
    this._reportSection.children = children;
    return this;
  }

  id(id: string): ReportSectionBuilder {
    this._reportSection.id = id;
    return this;
  }

  build(): Section {
    return this._reportSection;
  }
}
