import { ApiResponseMeta, PlatformValueGroupSetStatus } from '../models';
import { Builder } from './builder';
import { v4 as uuidv4 } from 'uuid';

export class ApiResponseMetaBuilder extends Builder<ApiResponseMeta> {
  constructor() {
    super({
      count: 1,
      total_count: 1,
      task_execution_id: uuidv4(),
      status_count_map: { not_started: 0, in_progress: 0, complete: 0 },
    });
  }

  count(count: number): ApiResponseMetaBuilder {
    this._instance.count = count;
    return this;
  }

  totalCount(totalCount: number): ApiResponseMetaBuilder {
    this._instance.total_count = totalCount;
    return this;
  }

  taskExecutionId(taskExecutionId: string): ApiResponseMetaBuilder {
    this._instance.task_execution_id = taskExecutionId;
    return this;
  }

  statusCountMap(status_count_map: Record<PlatformValueGroupSetStatus, number>): ApiResponseMetaBuilder {
    this._instance.status_count_map = status_count_map;
    return this;
  }
}
