import { Component, Input } from '@angular/core';
import { BaseMetricEditorFormStateService } from '../../../services/base-metric-editor-form-state/base-metric-editor-form-state.service';
import { FocusedFieldAction } from '../../../../models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'div[lib-field-comment-counter]',
  templateUrl: './field-comment-counter.component.html',
  styleUrls: ['./field-comment-counter.component.scss'],
})
export class FieldCommentCounterComponent {
  @Input() unresolved_regular_comments_and_replies?: number = 0;
  @Input() unresolved_rejection_comments_and_replies?: number = 0;

  readonly eFocusedFieldAction = FocusedFieldAction;

  constructor(private baseMetricEditorFormStateService: BaseMetricEditorFormStateService) {}

  public handleFocusedFieldAction(focusedFieldAction: FocusedFieldAction): void {
    this.baseMetricEditorFormStateService.setFocusedFieldAction(focusedFieldAction);
  }
}
