<!-- Context menu -->
<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <ng-container *ngFor="let action of item.children">
      <mat-divider *ngIf="action.id === 'separator'"></mat-divider>
      <a
        mat-menu-item
        *ngIf="action.id !== 'separator'"
        (click)="applyAction(action, item)"
        [ngClass]="{ 'color-error': action.id === 'delete' }"
        [disabled]="action.disabled"
      >
        <mat-icon *ngIf="action.icon" class="icon-sm" [svgIcon]="action.icon"></mat-icon>
        <span>{{ action.title }}</span>
      </a>
    </ng-container>
  </ng-template>
</mat-menu>

<div [class]="'panel-search'" [ngClass]="{ loaded: loaded, 'item-selected': selectedItemID }">
  <div class="panel-collection search-tree-content">
    <div class="collection collection-dummies">
      <lib-dummy *ngFor="let dummy of dummies" [presentation]="presentation"></lib-dummy>
    </div>
    <div class="h-100 w-100" [ngClass]="{ 'collection collection-items': dataSource.data.length }">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="search-tree">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
          <div
            class="card-container w-100 mt-1"
            [ngClass]="{
              active: activeItem === node || selectedItem === node,
              selected: selectedItem === node
            }"
            (mouseover)="onMouseOver(node)"
            (mouseout)="onMouseOut()"
            (contextmenu)="onContextMenu($event, node)"
          >
            <lib-card
              [item]="node"
              [presentation]="presentation"
              [mode]="mode"
              (action)="selectItem($event)"
            ></lib-card>
            <a *ngIf="showActions && node.children" class="context-menu" (click)="onContextMenu($event, node)">
              <mat-icon class="icon-sm" svgIcon="more"></mat-icon>
            </a>
          </div>
        </mat-tree-node>
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
          <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon
                [svgIcon]="treeControl.isExpanded(node) ? 'arrow-head-down' : 'arrow-head-right'"
                class="icon-sm"
              ></mat-icon>
            </button>
            <div
              class="card-container w-100 mt-1"
              [ngClass]="{
                active: activeItem === node || selectedItem === node,
                selected: selectedItem === node
              }"
              (mouseover)="onMouseOver(node)"
              (mouseout)="onMouseOut()"
              (contextmenu)="onContextMenu($event, node)"
            >
              <lib-card
                [item]="node"
                [presentation]="presentation"
                [mode]="mode"
                (action)="selectItem($event)"
              ></lib-card>
              <a *ngIf="showActions && node.children" class="context-menu" (click)="onContextMenu($event, node)">
                <mat-icon class="icon-sm" svgIcon="more"></mat-icon>
              </a>
            </div>
          </div>
          <div [class.search-tree-invisible]="!treeControl.isExpanded(node)" role="group">
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        </mat-nested-tree-node>
      </mat-tree>
      <div *ngIf="dataSource?.data?.length === 0" class="h-100 collection-empty">
        <div *ngIf="noData" class="h-100">
          <ng-content></ng-content>
        </div>
        <div *ngIf="!noData" class="h-100">
          <lib-empty-results [displayData]="emptyResults"></lib-empty-results>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-item" *ngIf="mode === 'nav'">
    <router-outlet></router-outlet>
  </div>
</div>
