import { Pipe, PipeTransform } from '@angular/core';
import { LimitedUser, User, ValueGroupSetStatusTransition, VGSetStatusContext } from '../../models';
import { UpdatedByUserPipe } from '../updated-by/updated-by-user.pipe';
import { TranslateService } from '../../services/common';

@Pipe({
  name: 'valueGroupSetStatusTransitionUser',
})
export class ValueGroupSetStatusTransitionUserPipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService,
    private readonly updatedByUserPipe: UpdatedByUserPipe
  ) {}

  transform(statusTransition: ValueGroupSetStatusTransition, users: (LimitedUser | User)[]): string {
    if (
      !statusTransition.created_by &&
      statusTransition.to_status_context === VGSetStatusContext.imported_from_green_project
    ) {
      return this.translateService.instant('Green Project Integration Automation');
    } else {
      return this.updatedByUserPipe.transform(statusTransition.created_by, users);
    }
  }
}
