import { Pipe, PipeTransform } from '@angular/core';
import { ValueDefinitionGroup, ValueDefinitionType } from '../../../models';

@Pipe({
  name: 'filterUncalculableGroups',
})
export class FilterUncalculableGroupsPipe implements PipeTransform {
  transform(groups?: ValueDefinitionGroup[]): ValueDefinitionGroup[] {
    return (
      groups?.filter((group) => {
        if (group.repeatable) {
          return false;
        }

        return group.table_id
          ? group.value_definitions?.some((valueDef) => valueDef.type === ValueDefinitionType.calculated)
          : group;
      }) ?? []
    );
  }
}
