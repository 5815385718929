import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';

import { EntityBuilder } from './entity-builder';
import { ValueDefinitionTargetStep } from '../models';

export class ValueDefinitionTargetStepBuilder extends EntityBuilder<ValueDefinitionTargetStep> {
  constructor() {
    super({
      id: uuidv4(),
      frequency_code: '2023',
      most_recent_value: 10,
      name: faker.name.jobDescriptor(),
      value: 5,
      value_definition_target_id: uuidv4(),
    });
  }

  frequencyCode(frequencyCode: string): ValueDefinitionTargetStepBuilder {
    this._instance.frequency_code = frequencyCode;
    return this;
  }

  mostRecentValue(mostRecentValue?: number): ValueDefinitionTargetStepBuilder {
    this._instance.most_recent_value = mostRecentValue;
    return this;
  }

  value(value: number): ValueDefinitionTargetStepBuilder {
    this._instance.value = value;
    return this;
  }

  valueDefinitionTargetId(valueDefinitionTargetId: string): ValueDefinitionTargetStepBuilder {
    this._instance.value_definition_target_id = valueDefinitionTargetId;
    return this;
  }
}
