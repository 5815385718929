import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MetricTableDefinition, NOT_APPLICABLE_NUMERIC_VALUE, ValueDefinitionSize } from '../../../models';
import { ValidationMessageService } from '../../../services/common';
import { NumberMaskConfig, NumberMaskService } from '../../../number-mask';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { NumberUtils } from '../../../classes';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ClientConfigService } from '../../../services/client/client-config/client-config.service';
import { ValidatorsUtils } from '../../../classes/FormUtils/validators-utils';

let nextId = 0;

export enum NumericInputType {
  notApplicable = 'NOT_APPLICABLE',
  maskDisabled = 'MASK_DISABLED',
  readonly = 'READONLY',
  full = 'FULL',
}

@Component({
  selector: 'lib-numeric-input',
  templateUrl: './numeric-input.component.html',
  styleUrls: ['./numeric-input.component.scss'],
})
export class NumericInputComponent implements OnChanges, OnDestroy, OnInit {
  readonly NOT_APPLICABLE = NOT_APPLICABLE_NUMERIC_VALUE;

  public readonly INTEGER_MASK = 'separator.0';
  private readonly DECIMAL_MASK = 'separator';

  @Input() label = '';
  @Input() control?: FormControl;
  @Input() messages: ValidationErrors = {};
  @Input() parentControl?: FormControl;
  @Input() hint? = '';
  @Input() placeholder = '';
  @Input() suffix? = '';
  @Input() size = ValueDefinitionSize.large;
  @Input() readonly = false;
  @Input() withDecimals = false;
  @Input() maxDecimals?: number;
  @Input() isCalculated: boolean = false;
  @Input() calculationErrorMsg: string = '';
  @Input() metricTableDefinition?: MetricTableDefinition;
  @Input() mask: string = '';

  controlNumberDisplay: FormControl = new FormControl();
  required = false;
  errorMessages: ValidationErrors = {};
  errorStateMatcher: ErrorStateMatcher = { isErrorState: () => Boolean(this.control?.touched && this.control.invalid) };
  numberMaskConfig$: Observable<NumberMaskConfig> = this.numberMaskService.numberMaskConfig$;
  controlSubscription?: Subscription;
  maskInput: string = this.mask ?? this.INTEGER_MASK;
  maskNumberDisplay: string = this.mask ?? this.INTEGER_MASK;
  numericInputChange$: Subject<string | number> = new Subject<string | number>();
  eNumericInputType: typeof NumericInputType = NumericInputType;
  isMaskDisabled = false;

  // Reflecting the native blur event
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<FocusEvent>();

  @ViewChild('input') input?: ElementRef<HTMLInputElement>;
  @ViewChild('errorDetailsMenuTrigger') errorDetailsMenuTrigger?: MatMenuTrigger;

  readonly _inputId = `numeric-input-${nextId++}`;

  constructor(
    private validationMessageService: ValidationMessageService,
    private readonly numberMaskService: NumberMaskService,
    private clientConfigService: ClientConfigService
  ) {}

  ngOnInit(): void {
    this.control?.registerOnChange((value: string | number) => {
      this.numericInputChange$.next(value);
    });
    this.isMaskDisabled = this.clientConfigService.areAnyFeatureFlagsEnabled(['numeric_mask_disabled']);
  }

  ngOnChanges(): void {
    this.initializeInput();
  }

  ngOnDestroy(): void {
    this.controlSubscription?.unsubscribe();
  }

  setFocus(): void {
    this.input?.nativeElement.focus();
  }

  setBlur(): void {
    this.input?.nativeElement.blur();
  }

  public get isInputDisabled(): boolean {
    return this.control?.disabled || this.readonly;
  }

  private initializeInput(): void {
    this.maskInput = this.mask ? this.mask : this.withDecimals ? this.DECIMAL_MASK : this.INTEGER_MASK;
    this.maskNumberDisplay = `${this.DECIMAL_MASK}.${this.maxDecimals as number}`;
    this.required = this.control?.hasValidator(Validators.required) ?? false;
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };

    this.control?.addValidators([ValidatorsUtils.systemMaxNumber, ValidatorsUtils.isANumberValidator()]);
    this.setSpecialValidations();
  }

  private setSpecialValidations(): void {
    this.controlSubscription?.unsubscribe();
    this.controlSubscription = this.numericInputChange$
      .pipe(
        startWith(this.control?.value as string),
        filter((value) => value !== this.NOT_APPLICABLE && (value != null || this.isInputDisabled)),
        map((value) => (typeof value === 'string' ? value.replace(/[A-Za-z]/, '') : value))
      )
      .subscribe((formattedValue) => {
        if (this.isMaskDisabled) {
          formattedValue = Number(formattedValue);
        }
        if (!isNaN(formattedValue as number) && String(this.control?.value) !== String(formattedValue)) {
          const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: this.maxDecimals });
          this.control?.setValue(formatter.format(Number(formattedValue)), {
            emitEvent: false,
          });
        }
        this.controlNumberDisplay.setValue(
          this.control?.value != null ? this.roundNumber(this.control.value as number) : null
        );
      });
  }

  private roundNumber(numericValue: string | number): string {
    return NumberUtils.round(+numericValue, this.maxDecimals || 0);
  }

  openErrorDetailsmenu(): void {
    this.errorDetailsMenuTrigger?.openMenu();
  }
}
