import {
  FieldAuditEventPayload,
  FieldAuditEvent,
  FieldAuditEventType,
  IndicatorType,
  FieldAuditEventOrigin,
  UserInfo,
  FieldAuditEventVariable,
} from '../models';

export class FieldAuditEventsBuilder {
  private readonly _fieldAuditEvent: FieldAuditEvent;

  constructor() {
    this._fieldAuditEvent = {
      created: '2022-10-05T17:41:00.000000+00:00',
      created_by: 'user_1',
      updated: '2020-10-05T17:41:00.000000+00:00',
      updated_by: 'user_1',
      id: 'fieldAuditEventId',
      metric_id: 'metricId',
      indicator_type: IndicatorType.collect,
      indicator_id: 'indId',
      business_unit_id: 'buId',
      event_type: FieldAuditEventType.value_edit,
      frequency_code: '2022',
      value_id: 'vId',
      payload: { new_value: 'new Value', old_value: 'old value' },
      value_definition_group_id: 'vdgId',
      value_group_id: 'vgId',
      value_group_set_id: 'vgsetId',
      variables: [],
      user_info: {
        first_name: 'Test',
        last_name: 'User 1',
      },
    };
  }

  id(id: string): FieldAuditEventsBuilder {
    this._fieldAuditEvent.id = id;
    return this;
  }

  eventType(eventType: FieldAuditEventType): FieldAuditEventsBuilder {
    this._fieldAuditEvent.event_type = eventType;
    return this;
  }

  payload(payload: FieldAuditEventPayload): FieldAuditEventsBuilder {
    this._fieldAuditEvent.payload = payload;
    return this;
  }

  origin(origin: FieldAuditEventOrigin): FieldAuditEventsBuilder {
    this._fieldAuditEvent.origin = origin;
    return this;
  }

  createdBy(createdBy: string): FieldAuditEventsBuilder {
    this._fieldAuditEvent.created_by = createdBy;
    return this;
  }

  created(created: string): FieldAuditEventsBuilder {
    this._fieldAuditEvent.created = created;
    return this;
  }

  userInfo(user_info: UserInfo): FieldAuditEventsBuilder {
    this._fieldAuditEvent.user_info = user_info;
    return this;
  }

  variables(field_audit_event_variable: FieldAuditEventVariable[]) {
    this._fieldAuditEvent.variables = field_audit_event_variable;
    return this;
  }

  build(): FieldAuditEvent {
    return this._fieldAuditEvent;
  }
}
