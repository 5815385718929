<ng-container *ngIf="fiscalYears$ | async as fiscalYearsOptions">
  <lib-filter
    *ngIf="fiscalYearsOptions.length > 0"
    [buttonProps]="{ disabled: disabled, active: !disabled, icon: 'calendar', title: getButtonTitle() }"
    [optionProps]="{
      options: fiscalYearsOptions,
      activeOption: activeOption,
      defaultValue: { id: currentYear.toString(), title: currentYear.toString() },
      defaultValueTitle: getDefaultValueTitle(),
      title: titlePlaceholder
    }"
    (optionSelected)="optionSelected.emit($event)"
  ></lib-filter>
</ng-container>
