import { UserRoles } from '../../../configs/config';
import { LoginEventStatus, LoginEventTypes } from './login-events-enum';

export {
  LoginEventRequest,
  LoginEventFilteringData,
  LoginEventFilteringOption,
  LoginEventOrderingData,
  LoginEventPaginationData,
  LoginEventFilteringUserOrder,
};

interface LoginEventRequest {
  id: string;
  user_id: string;
  email: string;
  event_type: LoginEventTypes;
  timestamp: string;
}

interface LoginEventFilteringData {
  event_status?: LoginEventStatus[];
  event_types?: LoginEventTypes[];
  user_ids?: string[];
  user_roles?: UserRoles[];
}

interface LoginEventOrderingData {
  order_by?: 'timestamp';
  order_by_direction?: 'asc' | 'desc';
}

interface LoginEventPaginationData {
  limit?: number;
  offset?: number;
}

interface LoginEventFilteringOption {
  filters?: LoginEventFilteringData;
  ordering?: LoginEventOrderingData;
  pagination?: LoginEventPaginationData;
}

interface LoginEventFilteringUserOrder {
  order_by?: string;
}
