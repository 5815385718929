export const DEFAULT_SELECT_ALL_VALUE: string = 'All';

export class FormUtils {
  static removeBlankValues(obj: any): void {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (this.isArray(obj[prop])) {
          // handle arrays
          let i = obj[prop].length;
          while (i--) {
            const item = obj[prop][i];

            if (this.containsNotNullFields(item as object)) {
              this.removeBlankValues(item);
            } else {
              obj[prop].splice(i, 1); // all field are blank, remove the object from array
            }
          }

          if (!obj[prop].length) {
            delete obj[prop]; // form array has no items after cleanup => removing it from parent
          }
        } else if (this.isObject(obj[prop]) && !this.isDate(obj[prop])) {
          // handle objects (excl date)
          if (this.containsNotNullFields(obj[prop] as object)) {
            this.removeBlankValues(obj[prop]);
          } else {
            delete obj[prop]; // all field are blank, remove the object from parent
          }
        } else {
          // handle properties
          if (!obj[prop]) {
            delete obj[prop];
          }
        }
      }
    }
  }

  static containsNotNullFields(obj: object): boolean {
    return Object.values(obj).some((o) => !!o);
  }

  static isObject(item: any): boolean {
    return item !== null && typeof item === 'object';
  }

  static isArray(item: any): boolean {
    return Array.isArray(item) && item.length > 0;
  }

  static isDate(item: any): boolean {
    return !!item && Object.prototype.toString.call(item) === '[object Date]';
  }

  static isNullOrEmpty(value: string | unknown[] | null): boolean {
    return value === null || value.length === 0;
  }

  static replaceNoneValue(value: string) {
    return !value || value == 'none' ? null : value;
  }

  //TODO: remove this and its uses after NF-8882. The component is not supposed to return a "All" string as a value.
  // This takes for granted that "All" is not a possible value
  static removeSelectAllValue(data: string[], selectAllValue: string = DEFAULT_SELECT_ALL_VALUE): string[] {
    return data.filter((value: string) => value !== selectAllValue);
  }
}
