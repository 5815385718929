import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { ValidationMessageService } from '../../../services/common';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';

let nextId = 0;

export enum AutoCompleteInputComparisonMethod {
  STARTS_WITH,
  INCLUDES,
}

@Component({
  selector: 'lib-auto-complete-input',
  templateUrl: './auto-complete-input.component.html',
  styleUrls: ['./auto-complete-input.component.scss'],
})
export class AutoCompleteInputComponent implements OnInit, OnChanges {
  @Input() label = '';
  @Input() hint?: string;
  @Input() placeholder: string = '';
  @Input() control?: UntypedFormControl;
  @Input() messages?: ValidationErrors;
  @Input() options: any[] = [];
  @Input() bindLabel?: (option: any) => string;
  @Input() bindValue?: (option: any) => any;
  @Input() minimalNumberOfCharToDisplayAutocomplete: number = 1;
  @Input() canAddNewOption: boolean = true;
  @Input() comparisonMethod: AutoCompleteInputComparisonMethod = AutoCompleteInputComparisonMethod.STARTS_WITH;
  @Input() isLoadingOptions: boolean = false;
  @Input() restoreInputOnBlur: boolean = false;

  @Output() selectValue: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('input') input!: ElementRef<HTMLInputElement>;
  @ViewChild('autocomplete') autocomplete?: MatAutocomplete;

  filteredOptions$: Observable<any[]> = of([]);
  inputControl: UntypedFormControl = new UntypedFormControl('');
  required: boolean = false;
  errorMessages: ValidationErrors = {};

  readonly _inputId = `auto-complete-input-${nextId++}`;
  private readonly OPTION_TAG: string = 'MAT-OPTION';

  constructor(private validationMessageService: ValidationMessageService) {}

  ngOnInit(): void {
    this.inputControl.setValue(this.control?.value ?? '');
    this.filteredOptions$ = this.inputControl.valueChanges.pipe(
      tap(() => this.control?.markAsTouched()),
      debounceTime(200),
      startWith(''),
      map((inputValue) => (typeof inputValue !== 'string' ? '' : inputValue)),
      map((value: string) => {
        const hasMinimalNumberOfCharToDisplayAutocomplete: boolean =
          value.length >= this.minimalNumberOfCharToDisplayAutocomplete;
        const filtered = hasMinimalNumberOfCharToDisplayAutocomplete
          ? this.options.filter((option) => {
              switch (this.comparisonMethod) {
                case AutoCompleteInputComparisonMethod.STARTS_WITH:
                  return this.getOptionLabel(option).toLowerCase().startsWith(value.toLowerCase());
                case AutoCompleteInputComparisonMethod.INCLUDES:
                  return this.getOptionLabel(option).toLowerCase().includes(value.toLowerCase());
              }
            })
          : [];
        const isExisting = this.options.some((option) => this.getOptionLabel(option).toLowerCase() === value);

        if (value !== '' && !isExisting && this.canAddNewOption) {
          filtered.push({ inputValue: value, label: `Add "${value}"` });
        }
        return filtered;
      })
    );
    this.control?.registerOnChange((value: any) => {
      this.inputControl.setValue(value);
    });
  }

  ngOnChanges(): void {
    this.initializeInput();
  }

  private initializeInput() {
    this.required = this.control?.hasValidator(Validators.required) ?? false;
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
  }

  public onBlur(event: FocusEvent): void {
    this.control?.markAsTouched();
    if ((event.relatedTarget as HTMLElement | undefined)?.tagName === this.OPTION_TAG) {
      return;
    }

    if (this.inputControl.value === '') {
      this.control?.setValue(null);
    } else if (this.restoreInputOnBlur) {
      this.inputControl.setValue(this.control?.value);
    }
  }

  public getOptionLabel(option: any): string {
    if (typeof option === 'string') {
      return option;
    }
    if ('inputValue' in option) {
      return option.label as string;
    }
    if (this.bindLabel) {
      return this.bindLabel(option);
    }
    return option;
  }

  public getOptionValue(option: any): string {
    if (typeof option === 'string') {
      return option;
    }
    if ('inputValue' in option) {
      return option.inputValue as string;
    }
    if (this.bindValue) {
      return this.bindValue(option);
    }
    return option;
  }

  public selectOption(option: MatOption): void {
    this.control?.setValue(this.getOptionValue(option.value));
    this.control?.markAsDirty();
    this.control?.markAsTouched();
    this.inputControl.setValue(this.getOptionLabel(option.value));
    this.selectValue.emit(option.value);
  }

  public setFocus(): void {
    this.input.nativeElement.focus();
  }
}
