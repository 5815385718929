import { Injectable } from '@angular/core';

import {
  AppConfig,
  SearchOptionFilters,
  SearchOptions,
  EsSearchOptionsPayload,
  SearchOptionsPayload,
  Filter,
} from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  readonly defaultFrom: number = 0;
  readonly defaultFilters: Filter[] = [];
  readonly defaultPage: number = 1;
  readonly defaultPageSize: number = 24;
  readonly defaultOrderByDirection: 'asc' | 'desc' = 'asc';
  readonly defaultSize: number;

  constructor(private config: AppConfig) {
    this.defaultSize = config.pageSize;
  }

  renameObjectProperty(o: { [key: string]: any }, old_key: string, new_key: string): { [key: string]: any } {
    if (old_key !== new_key && o[old_key]) {
      const val: any = o[old_key];
      delete o[old_key];
      o[new_key] = val;
    }
    return o;
  }

  payloadFromSearchOptions(searchOptions?: SearchOptions): SearchOptionsPayload {
    const payload: SearchOptionsPayload = {
      page: this.defaultPage,
      page_size: this.defaultPageSize,
      order_by_direction: this.defaultOrderByDirection,
    };
    if (searchOptions) {
      if (searchOptions.from) {
        payload.page = searchOptions.from;
      }
      if (searchOptions.size) {
        payload.page_size = searchOptions.size;
      }
      if (searchOptions.query.keywords) {
        payload.search_query = searchOptions.query.keywords;
      }
      if (searchOptions.sort) {
        payload.order_by = searchOptions.sort.id;
      }
    }
    return payload;
  }

  esPayloadFromSearchOptions(searchOptions?: SearchOptions): EsSearchOptionsPayload {
    const payload: EsSearchOptionsPayload = {
      from: this.defaultFrom,
      size: this.defaultSize,
      filters: [...this.defaultFilters],
    };
    if (searchOptions) {
      if (searchOptions.from) {
        payload.from = searchOptions.from;
      }
      if (searchOptions.size) {
        payload.size = searchOptions.size;
      }
      if (searchOptions.query.keywords) {
        payload.keywords = searchOptions.query.keywords;
      }
      if (searchOptions.filters) {
        for (const filter_key of Object.keys(searchOptions.filters)) {
          payload.filters.push({
            field: filter_key,
            value: searchOptions.filters[filter_key as keyof SearchOptionFilters]?.id,
          });
        }
      }
      if (searchOptions.custom_filters) {
        for (const filter_key of Object.keys(searchOptions.custom_filters)) {
          payload.filters.push({
            field: filter_key,
            value: searchOptions.custom_filters[filter_key],
          });
        }
      }
      if (searchOptions.custom_properties) {
        for (const key of Object.keys(searchOptions.custom_properties)) {
          payload[key] = searchOptions.custom_properties[key];
        }
      }
      if (searchOptions.sort) {
        switch (searchOptions.sort.id) {
          case 'updated':
            payload.sort = [
              {
                field: 'updated',
                direction: 'desc',
              },
            ];
            break;
          case 'created':
            payload.sort = [
              {
                field: 'created',
                direction: 'desc',
              },
            ];
            break;
          case 'name':
            payload.sort = [
              {
                field: 'name',
              },
            ];
            break;
          case 'start':
            payload.sort = [
              {
                field: 'start',
                direction: 'desc',
              },
            ];
            break;
          case 'description':
            payload.sort = [
              {
                field: searchOptions.sort.id,
              },
            ];
            break;
          default:
            break;
        }
      }
    }

    return payload;
  }

  newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
