import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, ApplicationApiDefinition, Taxonomy } from '../../../models';
import { ApiService } from '../../common';

@Injectable({
  providedIn: 'root',
})
export class ClientTaxonomiesService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.taxonomies;
  }

  getTaxonomy(taxonomyId: string, completeFramework = false): Observable<ApiResponse<Taxonomy>> {
    let params = new HttpParams();
    params = params.append('complete_framework', completeFramework);

    return this.apiService.get(`${this.servicePath}${this.resource}/taxonomies/${taxonomyId}`, { params });
  }
}
