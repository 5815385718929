<lib-dialog-title [title]="'Attention!' | translate" (onCloseEvent)="close()"></lib-dialog-title>

<div mat-dialog-content class="dialog-content">
  <div>
    <div class="label mb-4">
      {{
        "Changing the configuration rule to manual includes optionally reset the values. Where values are reset, completed metrics will be set back to in progress."
          | translate
      }}
    </div>

    <lib-checkbox
      [label]="'Reset existing values' | translate"
      [defaultValue]="true"
      (checkboxToggle)="toggleDefault($event)"
    ></lib-checkbox>
  </div>
</div>

<div mat-dialog-actions>
  <button lib-button type="primary" (click)="confirm()">
    {{ "Ok" | translate }}
  </button>

  <button lib-button type="cancel_with_border" class="btn ml-3" (click)="close()">
    {{ "Cancel" | translate }}
  </button>
</div>
