import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SecurityContext, OnDestroy } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';

import {
  ActionItem,
  Doc,
  ItemType,
  Unit,
  Value,
  ValueDefinitionType,
  ValueGroup,
  ValueGroupSet,
  PublicDocUploadedFileMetaData,
  FileValue,
  FileDocumentInterface,
  DocumentTypeDetails,
  BooleanValue,
  BooleanTypeDetails,
  ChoiceValue,
  ValueType,
  TypeDetails,
  PeerDataValueNullCategoriesLabels,
  PeerDataValueNullCategories,
  RequestDocMetadata,
} from '../../models';

import {
  AlertsService,
  TranslateService,
  ConditionalTriggerService,
  DownloadDocumentService,
} from '../../services/common';
import { ClientCoreService } from '../../services/client';
import { ClientDocumentsService } from '../../services/client';
import { EventsService } from '../../services/common';
import { FileUtils } from '../../classes';
import { DocumentContext } from '../../metric-editor-form';
import { DEFAULT_DOCUMENT_CONTEXT } from '../../metric-editor-form/models/documentContext';
import { StandardDocumentMetadata } from '../../documents';
import { CopyMetricValuesService } from '../../services/common/copy-metric-values/copy-metric-values.service';

export interface MetricViewFileDocumentInterface extends Omit<FileDocumentInterface, 'doc'> {
  doc?: Doc | PublicDocUploadedFileMetaData | StandardDocumentMetadata | RequestDocMetadata;
}

export enum MetricViewActionEventType {
  edit = 'edit',
  autofill = 'autofill',
}

export interface MetricViewAction {
  type: MetricViewActionEventType;
  value?: Value;
  group?: ValueGroup;
}

@Component({
  selector: 'lib-metric-view',
  templateUrl: './metric-view.component.html',
  styleUrls: ['./metric-view.component.scss'],
})
export class MetricViewComponent implements OnInit, OnDestroy {
  @Input()
  set valueGroupSet(valueGroupSet: ValueGroupSet | undefined) {
    if (valueGroupSet) {
      this.valueGroupSetData = JSON.parse(JSON.stringify(valueGroupSet)) as ValueGroupSet;
      this.initializeDocuments(this.valueGroupSetData);
      this.prepareIndicatorData(this.valueGroupSetData);
    } else {
      if (this.valueGroupSetData) {
        this.sanitizedOrderedValueGroups = [];
      }
    }
  }
  @Input() clamp: boolean = false;
  @Input() hoverAction: boolean = true;
  @Input() disableEdit: boolean = false;
  @Input() valuesOnly: boolean = false;
  @Input() set autofill(autofill: boolean) {
    (this.actions.find((action) => action.id === 'autofill') as ActionItem).disabled = !autofill;
    this.multiOptions = autofill;
  }
  @Input() enableHoverEvents: boolean = false;
  @Input() showTip: boolean = false;
  @Input() ignoreTriggers: boolean = false;
  @Input() documentContext: DocumentContext = DEFAULT_DOCUMENT_CONTEXT;
  @Input() units?: Unit[];
  @Input() isHistoricalTab: boolean = false;
  @Input() isRecommendationTab: boolean = false;
  @Input() noLoaders: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() showNullCategory: boolean = false;

  @Output() action = new EventEmitter<MetricViewAction>();

  @ViewChild(MatMenuTrigger) contextMenu?: MatMenuTrigger;

  readonly eValueDefinitionType: typeof ValueDefinitionType = ValueDefinitionType;

  actions: ActionItem[] = [
    {
      id: 'copy',
      title: this.translateService.instant('Copy'),
      icon: 'copy',
      disabled: false,
    },
    {
      id: 'autofill',
      title: this.translateService.instant('Insert'),
      icon: 'break',
      disabled: true,
    },
  ];

  nullCategoryIncompatibleTypes: ValueDefinitionType[] = [
    ValueDefinitionType.tip,
    ValueDefinitionType.subtitle,
    ValueDefinitionType.label,
  ];
  multiOptions: boolean = false;
  selectedValue?: Value;
  selectedGroup?: ValueGroup;
  contextMenuPosition = { x: '0px', y: '0px' };
  // data
  valueGroupSetData?: ValueGroupSet;
  sanitizedOrderedValueGroups: ValueGroup[] = [];
  selectedId: string = '';
  readonly ePeerDataValueNullCategoriesLabels = PeerDataValueNullCategoriesLabels;
  readonly ePeerDataValueNullCategories = PeerDataValueNullCategories;

  documents$: Observable<MetricViewFileDocumentInterface[]> = of([]);

  private docCalls: Observable<MetricViewFileDocumentInterface[]>[] = [];
  private docIds: string[] = [];
  private readonly fileValueDefinitionTypes = [ValueDefinitionType.file, ValueDefinitionType.file_v2];
  private readonly excludesTypesForContextMenu = [...this.fileValueDefinitionTypes, ValueDefinitionType.document];

  constructor(
    private translateService: TranslateService,
    private coreService: ClientCoreService,
    private alertsService: AlertsService,
    private eventsService: EventsService,
    private sanitizer: DomSanitizer,
    private conditionalTriggerService: ConditionalTriggerService,
    private commonDocumentService: DownloadDocumentService,
    public documentsService: ClientDocumentsService,
    private copyMetricValuesService: CopyMetricValuesService
  ) {}

  ngOnInit() {
    if (this.enableHoverEvents) {
      this.eventsService.getMessage('hoverItem').subscribe((id) => {
        this.selectedId = id;
      });
    }
  }

  setHoverIndex(value?: Value, group?: ValueGroup): void {
    // In order to communicate between two different lib-metric-views. (Survey compare feature)
    if (this.enableHoverEvents) {
      if (value && group) {
        this.eventsService.setMessage(
          'hoverItem',
          `${value.value_definition_id};${group.repeatable ? group.subposition ?? 1 : 1}`
        );
      } else {
        this.eventsService.setMessage('hoverItem', '');
      }
    }
  }

  isValueDefHighlighted(value?: Value, group?: ValueGroup): boolean {
    return (
      this.selectedValue === value ||
      this.selectedId === `${value?.value_definition_id ?? ''};${group?.repeatable ? group.subposition ?? 1 : 1}`
    );
  }

  onContextMenu(event: MouseEvent, value: Value, group?: ValueGroup): void {
    if (!this.excludesTypesForContextMenu.includes(value.type) && this.multiOptions) {
      event.preventDefault();
      event.stopPropagation();
      this.selectedValue = value;
      this.selectedGroup = group;
      this.contextMenuPosition.x = `${event.clientX}px`;
      this.contextMenuPosition.y = `${event.clientY}px`;
      if (this.contextMenu) {
        this.contextMenu.menuData = { value };
        this.contextMenu.menu?.focusFirstItem('mouse');
        this.contextMenu.openMenu();
      }
    }
  }

  onActionMenuSelect(action: ActionItem): void {
    switch (action.id) {
      case 'copy':
        this.copyValue(this.selectedValue);
        break;
      case 'autofill':
        this.handlerActions(MetricViewActionEventType.autofill);
        break;
    }
  }

  clearSelectedItems(): void {
    this.selectedGroup = undefined;
    this.selectedValue = undefined;
  }

  isElementDisplayed(element: ValueGroup | Value): boolean {
    if (
      !this.ignoreTriggers &&
      this.valueGroupSetData &&
      element.conditional_triggers &&
      element.conditional_triggers.length > 0
    ) {
      return this.conditionalTriggerService.hasTriggerTriggeredForValueGroupSet(
        element.conditional_triggers,
        this.valueGroupSetData
      );
    }
    return true;
  }

  private resetDocCalls(): void {
    this.docCalls = [];
    this.docIds = [];
  }

  private initializeDocuments(valueGroupSet: ValueGroupSet) {
    this.resetDocCalls();
    valueGroupSet.value_groups?.forEach((grp) => {
      grp.values
        ?.filter(
          (value) =>
            (this.fileValueDefinitionTypes.includes(value.type) && value.value?.length) ||
            value.type === ValueDefinitionType.document
        )
        .forEach((value) => {
          switch (value.type) {
            case ValueDefinitionType.file:
              this.initializeDocumentsForFileType(value);
              break;
            case ValueDefinitionType.file_v2:
              this.initializeDocumentsForFileV2Type(value);
              break;
            default:
              this.initializeDocumentsForDocumentType(value, grp);
              break;
          }
        });
    });
    if (this.docCalls.length) {
      this.documents$ = forkJoin(this.docCalls.flat()).pipe(map((calls) => calls.flatMap((docs) => docs)));
    }
    if (this.docIds.length) {
      this.documents$ = this.getDocumentsByIds(this.docIds, valueGroupSet);
    }
  }

  private initializeDocumentsForFileType(value: Value): void {
    this.docIds = this.docIds.concat(value.value as string[]);
  }

  private initializeDocumentsForFileV2Type(value: Value): void {
    const fileV2DocIds = (value.value as FileValue[] | undefined)?.map((v: FileValue) => v.file_id);
    if (fileV2DocIds) {
      this.docIds = this.docIds.concat(fileV2DocIds);
    }
  }

  private initializeDocumentsForDocumentType(value: Value, grp: ValueGroup): void {
    if (this.documentContext.itemType === ItemType.public_data_requests_request) {
      this.docCalls.push(
        this.getDocumentFromRequestPublic([(value.type_details as DocumentTypeDetails).document_id], value, grp)
      );
    } else {
      this.docIds = this.docIds.concat([(value.type_details as DocumentTypeDetails).document_id]);
    }
  }

  private getDocumentsByIds(
    docIds: string[],
    valueGroupSet: ValueGroupSet
  ): Observable<MetricViewFileDocumentInterface[]> {
    switch (this.documentContext.itemType) {
      case ItemType.public_data_requests_request:
        return this.listDocumentsUploadedForDataRequest(docIds, valueGroupSet);
      default:
        return this.getDocData(docIds);
    }
  }

  listDocumentsUploadedForDataRequest(
    docIds: string[],
    valueGroupSet: ValueGroupSet
  ): Observable<MetricViewFileDocumentInterface[]> {
    if (!this.documentContext.dataRequestInfo) {
      throw new Error('DataRequestInfo is missing');
    }
    return this.documentsService
      .listDocumentsUploadedForDataRequest(
        this.documentContext.dataRequestInfo.id,
        this.documentContext.dataRequestInfo.sourceId,
        valueGroupSet.indicator_id,
        valueGroupSet.id,
        docIds,
        this.isHistoricalTab,
        this.isRecommendationTab
      )
      .pipe(
        map((res) => res.data),
        map((docs) => FileUtils.convertPublicDocUploadedFileMetadataToFileDocuments(docs))
      );
  }

  getDocumentFromRequestPublic(
    docIds: string[],
    value: Value,
    group: ValueGroup
  ): Observable<MetricViewFileDocumentInterface[]> {
    if (!this.documentContext.dataRequestInfo) {
      throw new Error('DataRequestInfo is missing');
    }
    const calls: Observable<MetricViewFileDocumentInterface>[] = docIds.map((id) =>
      this.documentsService
        .getValueDefinitionDocumentMetaData(
          this.documentContext.dataRequestInfo?.id ?? '',
          this.documentContext.dataRequestInfo?.sourceId ?? '',
          id,
          group.metric_id ?? '',
          value.value_definition_id
        )
        .pipe(
          map((res) => res.data),
          map((doc) => ({ id: doc.id, name: doc.name, format: doc.extension }))
        )
    );
    return forkJoin(calls);
  }

  getDocData(docIds: string[]): Observable<MetricViewFileDocumentInterface[]> {
    return this.documentsService.getDocuments(docIds).pipe(
      map((res) => res.data),
      map((docs) => FileUtils.convertDocsToFileDocuments(docs))
    );
  }

  prepareIndicatorData(valueGroupSet: ValueGroupSet): void {
    this.sanitizedOrderedValueGroups = [];
    this.sanitizedOrderedValueGroups =
      valueGroupSet.value_groups
        ?.map((group) => {
          let tempValue: Value[] = group.values ? [...group.values] : [];
          tempValue.sort(orderByPosition);
          if (this.valuesOnly) {
            tempValue = tempValue
              .filter((x) => x.value)
              .map((x) => {
                this.sanitizer.sanitize(SecurityContext.HTML, x.value as ValueType);
                return x;
              });
          }
          return { ...group, values: tempValue };
        })
        .sort(orderByPosition) ?? [];
    this.valueGroupSetData = valueGroupSet;
  }

  copyValue(value?: Value, event?: MouseEvent): void {
    event?.preventDefault();
    event?.stopPropagation();
    if (value) {
      const valueToCopy = this.fetchStringValueToCopy(value);
      const result = this.copyMetricValuesService.copyValuesBasedOnType(
        valueToCopy,
        value.type,
        value.type_details as TypeDetails
      );
      if (result) {
        this.alertsService.success(this.translateService.instant('Selected value has been copied to the clipboard'));
      }
    }
  }

  handlerActions(type: MetricViewActionEventType, event?: MouseEvent): void {
    event?.preventDefault();
    event?.stopPropagation();
    this.action.emit({ type, value: this.selectedValue, group: this.selectedGroup });
  }

  selectDefaultAction(): void {
    if (!this.disableEdit) {
      this.handlerActions(MetricViewActionEventType.edit);
    }
  }

  getUnit(value: Value): string {
    return this.coreService.getValueUnit(value, this.units || []);
  }

  // Value type specific methods
  // File
  downloadFile(data: MetricViewFileDocumentInterface, value?: Value): void {
    switch (this.documentContext.itemType) {
      case ItemType.public_data_requests_request:
        this.downloadRequestPublicFile(data);
        break;
      default:
        if (value) {
          this.documentsService.downloadDocumentByItemType(
            value,
            data.name,
            {
              itemType: ItemType.docs_doc,
            },
            data.doc as Doc
          );
        }
    }
  }

  private downloadRequestPublicFile(docData: MetricViewFileDocumentInterface): void {
    this.documentsService
      .serveDocumentsUploadedForDataRequest(
        this.documentContext.dataRequestInfo?.id ?? '',
        this.documentContext.dataRequestInfo?.sourceId ?? '',
        this.valueGroupSetData?.indicator_id ?? '',
        this.valueGroupSetData?.id ?? '',
        docData.id,
        this.isHistoricalTab,
        this.isRecommendationTab
      )
      .subscribe((res) => this.commonDocumentService.downloadAction(`${docData.name}.${docData.format}`, res));
  }

  // Boolean
  getBooleanDisplayValue(value: Value): string | null {
    const booleanValue = value.value as BooleanValue;
    if (typeof booleanValue === 'string') {
      return booleanValue;
    } else if (booleanValue.value === null) {
      return null;
    } else {
      const typeDetails = value.type_details as BooleanTypeDetails;
      if (booleanValue.value) {
        return typeDetails.label_true ?? this.translateService.instant('Yes');
      } else {
        return typeDetails.label_false ?? this.translateService.instant('No');
      }
    }
  }

  ngOnDestroy(): void {
    this.eventsService.setMessage('hoverItem', '');
  }

  private fetchStringValueToCopy(value: Value): string {
    switch (value.type) {
      case ValueDefinitionType.boolean:
        return (value.value as BooleanValue).value
          ? (value.type_details as BooleanTypeDetails).label_true ?? ''
          : (value.type_details as BooleanTypeDetails).label_false ?? '';
      case ValueDefinitionType.choice:
        return (value.value as ChoiceValue).values.join(', ');
      default:
        return String(value.value);
    }
  }
}

const orderByPosition = (a: { position?: number }, b: { position?: number }): number =>
  a.position && b.position ? a.position - b.position : 0;
