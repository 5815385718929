import { CardComponent } from './card/card.component';
import { CardEmptyComponent } from './card-empty/card-empty.component';
import { CardPrimaryComponent } from './card-primary/card-primary.component';
import { DummyComponent } from './dummy/dummy.component';
import { MetricCardFooterComponent } from './card/metric-card-footer/metric-card-footer.component';
import { MetricCardRelatedMetricsComponent } from './card/metric-card-related-metrics/metric-card-related-metrics.component';
import { RichTextCardComponent } from './rich-text-card/rich-text-card.component';
import { StandardCodesCardFooterComponent } from './card/standard-codes-card-footer/standard-codes-card-footer.component';
import { MetricStatusCardComponent } from './metric-status-card/metric-status-card.component';
import { UserCardComponent } from './user-card/user-card.component';

export { CardComponent } from './card/card.component';
export { CardEmptyComponent } from './card-empty/card-empty.component';
export { CardPrimaryComponent } from './card-primary/card-primary.component';
export { DummyComponent } from './dummy/dummy.component';
export { MetricCardFooterComponent } from './card/metric-card-footer/metric-card-footer.component';
export { MetricCardRelatedMetricsComponent } from './card/metric-card-related-metrics/metric-card-related-metrics.component';
export { RichTextCardComponent } from './rich-text-card/rich-text-card.component';
export { StandardCodesCardFooterComponent } from './card/standard-codes-card-footer/standard-codes-card-footer.component';
export { MetricStatusCardComponent } from './metric-status-card/metric-status-card.component';
export { UserCardComponent } from './user-card/user-card.component';

export const CARD_COMPONENTS = [
  CardComponent,
  CardEmptyComponent,
  CardPrimaryComponent,
  DummyComponent,
  MetricCardFooterComponent,
  MetricCardRelatedMetricsComponent,
  RichTextCardComponent,
  StandardCodesCardFooterComponent,
  MetricStatusCardComponent,
  UserCardComponent,
];
