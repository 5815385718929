import { ActionItem, ItemType, Layout, Permission, Presentation, SearchBarFilterResourceArgs } from '../../models';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Required } from '../../decorators';
import { Subject } from 'rxjs';
import { TranslateService } from '../../services/common';

@Component({
  selector: 'lib-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Input() @Required() itemType!: ItemType;
  @Input() @Required() layout!: Layout;
  @Input() @Required() presentation!: Presentation;
  @Input() grid: string = '';
  @Input() filters?: Record<string, string | string[] | boolean | undefined>;
  @Input() externalFilters: ActionItem[] = [];
  @Input() properties?: Record<string, string | undefined>;
  @Input() mode: 'nav' | 'drag' | 'select' = 'nav';
  @Input() showActions: boolean = false;
  @Input() cache?: string;
  @Input() lockedFilters?: { action: 'lock' | 'hide'; filters: { [field: string]: ActionItem } } = {
    action: 'lock',
    filters: {},
  };
  @Input() sortOrder?: ActionItem;
  @Input() searchPlaceholder: string = this.translateService.instant('Search');
  @Input() filterArgs: SearchBarFilterResourceArgs = {};
  @Input() refresh: Subject<boolean> = new Subject<boolean>();
  @Input() clickItemPermissions: Permission[] = [];
  @Output() action: EventEmitter<ActionItem> = new EventEmitter<ActionItem>();

  searchOptions: string = '';
  eLayout = Layout;

  constructor(private translateService: TranslateService) {}

  change(searchOptions: string): void {
    this.searchOptions = searchOptions;
  }

  applyAction(action: ActionItem): void {
    this.action.emit(action);
  }
}
