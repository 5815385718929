import { IndicatorSearchFilterCriteria } from '../models';
import { Builder } from './builder';

export class IndicatorSearchFilterCriteriaBuilder extends Builder<IndicatorSearchFilterCriteria> {
  constructor() {
    super({});
  }

  topic(topic_id: string): IndicatorSearchFilterCriteriaBuilder {
    this._instance.topics = [topic_id];
    return this;
  }

  standardCode(framework_id: string): IndicatorSearchFilterCriteriaBuilder {
    this._instance.framework_ids = [framework_id];
    return this;
  }

  category(category_name: string): IndicatorSearchFilterCriteriaBuilder {
    this._instance.categories = [category_name];
    return this;
  }

  tag(tag_id: string): IndicatorSearchFilterCriteriaBuilder {
    this._instance.tags = [tag_id];
    return this;
  }

  industry(industry_id: string): IndicatorSearchFilterCriteriaBuilder {
    this._instance.industries = [industry_id];
    return this;
  }

  active(is_active: boolean): IndicatorSearchFilterCriteriaBuilder {
    this._instance.active = is_active;
    return this;
  }
}
