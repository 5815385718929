import {
  Framework,
  Report,
  ReportCategoryType,
  ReportIntegrationType,
  ReportStatusEnum,
  Section,
  Tag,
  Topic,
} from '../models';

export class ReportBuilder {
  private readonly _report: Report;

  constructor() {
    this._report = {
      category: ReportCategoryType.questionnaire,
      end: '2022-03-31',
      id: 'rep_1',
      name: 'A Report',
      prefill_values: false,
      show_subtitles_in_export: true,
      show_unselected_choice_field_options: true,
      display_table_fields_as_a_list: false,
      start: '2021-04-01',
      status: ReportStatusEnum.draft,
      unit_id: '',
      year: 2022,
      business_unit_id: 'bu_1',
      sections: [],
      report_frequencies: ['2022'],
      updated_by: 'user_id',
      created: new Date(),
      description: 'A sample description',
      template_report_version_name: 'v2',
    };
  }

  id(id: string): ReportBuilder {
    this._report.id = id;
    return this;
  }

  sections(sections: Section[]): ReportBuilder {
    this._report.sections = sections;
    return this;
  }

  showUnselectedChoiceFieldOptions(value: boolean): ReportBuilder {
    this._report.show_unselected_choice_field_options = value;
    return this;
  }

  businessUnitId(id: string): ReportBuilder {
    this._report.business_unit_id = id;
    return this;
  }

  unitId(id: string): ReportBuilder {
    this._report.unit_id = id;
    return this;
  }

  name(name: string): ReportBuilder {
    this._report.name = name;
    return this;
  }

  start(start: string): ReportBuilder {
    this._report.start = start;
    return this;
  }

  end(end: string): ReportBuilder {
    this._report.end = end;
    return this;
  }

  category(category: ReportCategoryType): ReportBuilder {
    this._report.category = category;
    return this;
  }

  topics(topics: Topic[]): ReportBuilder {
    this._report.topics = topics;
    return this;
  }

  tags(tags: Tag[]): ReportBuilder {
    this._report.tags = tags;
    return this;
  }

  status(status: ReportStatusEnum): ReportBuilder {
    this._report.status = status;
    return this;
  }

  reportFrequencies(reportFrequencies: string[]): ReportBuilder {
    this._report.report_frequencies = reportFrequencies;
    return this;
  }

  templateReportVersionId(templateReportVersionId: string): ReportBuilder {
    this._report.template_report_version_id = templateReportVersionId;
    return this;
  }

  recommendationsFrequencyCode(recommendationsFrequencyCode: string): ReportBuilder {
    this._report.recommendations_frequency_code = recommendationsFrequencyCode;
    return this;
  }

  frameworks(frameworks: Framework[]): ReportBuilder {
    this._report.frameworks = frameworks;
    return this;
  }

  integration(integration: ReportIntegrationType): ReportBuilder {
    this._report.integration = integration;
    return this;
  }

  build(): Report {
    return this._report;
  }
}
