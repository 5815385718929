import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { finalize, Subject, take, takeUntil, tap } from 'rxjs';
import { Required } from '../../../decorators';
import { FileDocumentInterface, FileTypeDetailsV2, FileValue, Presentation } from '../../../models';
import { ValidationMessageService } from '../../../services/common';
import { DEFAULT_DOCUMENT_CONTEXT, DocumentContext } from '../../models/documentContext';
import { ValueFormControl } from '../../models/valueFormControl';
import { BaseMetricEditorFormStateService } from '../../services/base-metric-editor-form-state/base-metric-editor-form-state.service';

const DEFAULT_MAX_FILES_LIMIT = 1;

@Component({
  selector: 'lib-metric-editor-file-attachment-v2',
  templateUrl: './metric-editor-file-attachment-v2.component.html',
  styleUrls: ['./metric-editor-file-attachment-v2.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0, display: 'none' }),
        animate('200ms ease-in', style({ display: 'flex', opacity: 1 })),
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({ display: 'flex', opacity: 1 }),
        animate('200ms ease-out', style({ display: 'none', opacity: 0 })),
      ]),
    ]),
  ],
})
export class MetricEditorFileAttachmentV2Component implements OnInit, OnDestroy {
  @Input() @Required() valueFormControl!: ValueFormControl<FileTypeDetailsV2>;
  @Input() documentContext: DocumentContext = DEFAULT_DOCUMENT_CONTEXT;
  @Input() messages?: ValidationErrors;

  fileDocumentsList: FileDocumentInterface[] = [];
  fileValues: FileValue[] = [];
  maxFilesLimit: number = DEFAULT_MAX_FILES_LIMIT;
  filesLoaded = true;
  readonly ePresentation: typeof Presentation = Presentation;

  private destroy$ = new Subject<void>();
  private valueUpdateSubject$ = new Subject<FileValue[] | null>();

  constructor(
    private validationMessageService: ValidationMessageService,
    private baseMetricEditorFormStateService: BaseMetricEditorFormStateService
  ) {}

  ngOnInit(): void {
    this.updateFileValues();
    this.messages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
    this.updateFileDocumentsList();
    this.valueFormControl.registerOnChange(() => {
      this.valueUpdateSubject$.next(this.valueFormControl.value as FileValue[]);
    });
    this.valueUpdateSubject$
      .pipe(
        tap(() => {
          this.updateFileValues();
          if (!this.isValueInFileDocumentList()) {
            this.updateFileDocumentsList();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
    this.maxFilesLimit = this.valueFormControl.valueRef.type_details.max_files || DEFAULT_MAX_FILES_LIMIT;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private isValueInFileDocumentList(): boolean {
    if (!this.valueFormControl.value) {
      return false;
    }

    return this.valueFormControl.value.some((value: FileValue) =>
      Boolean(this.fileDocumentsList.find((fileDocument: FileDocumentInterface) => fileDocument.id === value.file_id))
    );
  }

  private updateFileDocumentsList(): void {
    const documentIds =
      (this.valueFormControl.value as FileValue[] | undefined)?.map((fileValue) => fileValue.file_id) ?? [];
    if (documentIds.length) {
      this.filesLoaded = false;
      this.baseMetricEditorFormStateService
        .getDocumentMetadata(documentIds)
        .pipe(
          take(1),
          finalize(() => {
            this.filesLoaded = true;
          })
        )
        .subscribe((fileDocs) => {
          this.fileDocumentsList = fileDocs;
        });
    }
  }

  public deleteFileValue(docId: string): void {
    if (!docId) {
      this.updateFileValues();
      this.valueFormControl.enable();
    } else {
      if (this.valueFormControl.value?.length) {
        this.valueFormControl.setValue(
          (this.valueFormControl.value as FileValue[]).filter((fileVal) => fileVal.file_id !== docId)
        );
      }
      this.fileValues = this.fileValues.filter((fileVal) => fileVal.file_id !== docId);
    }
  }

  private updateFileValues(): void {
    this.fileValues = (
      this.valueFormControl.value
        ? Array.isArray(this.valueFormControl.value)
          ? [...this.valueFormControl.value]
          : [this.valueFormControl.value]
        : []
    ) as FileValue[];
  }

  public trackId(index: number, item: FileValue): string | undefined {
    return `${item.file_id}-${index}`;
  }

  addFileDoc(fileDoc: FileDocumentInterface): void {
    this.fileDocumentsList.push(fileDoc);
  }

  addFileValue(fileValue: FileValue): void {
    this.fileValues.push(fileValue);
  }
}
