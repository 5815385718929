<div class="panel-properties-tabs">
  <a (click)="closeProperties()">
    <mat-icon class="icon-md" svgIcon="close"></mat-icon>
  </a>

  <a
    (click)="setSelectedPanelTab(panelTabActions[0])"
    [ngClass]="{ active: panelTabActions[0].id === selectedPanelTabAction.id }"
  >
    <mat-icon svgIcon="{{ panelTabActions[0].icon }}"></mat-icon>
  </a>

  <ng-container *libFeatureFlag="{ featureFlags: ['field_standard_code_association_panel_enabled'] }">
    <ng-container *ngIf="(isCreatingField$ | async) === false">
      <a
        *ngIf="selectedItemType === 'valueDefinition' || selectedItemType === 'table'"
        role="button"
        aria-label="Field Information"
        (click)="setSelectedPanelTab(panelTabActions[1])"
        [ngClass]="{ active: panelTabActions[1].id === selectedPanelTabAction.id }"
      >
        <mat-icon svgIcon="{{ panelTabActions[1].icon }}"></mat-icon>
      </a>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="(isCreatingField$ | async) === false">
    <a
      *ngIf="
        selectedItem
          | canHaveConditionalTrigger : selectedItemType : parentValueDefinitionGroup : metric : canEditEveryMetrics
      "
      role="button"
      aria-label="Conditional triggers"
      (click)="setSelectedPanelTab(panelTabActions[2])"
      [ngClass]="{ active: panelTabActions[2].id === selectedPanelTabAction.id }"
    >
      <mat-icon svgIcon="{{ panelTabActions[2].icon }}"></mat-icon>
    </a>
  </ng-container>

  <a
    *ngIf="selectedItemType === eMetricStructureSelectableType.table"
    role="button"
    aria-label="Table Totals"
    (click)="setSelectedPanelTab(panelTabActions[3])"
    [ngClass]="{ active: panelTabActions[3].id === selectedPanelTabAction.id }"
  >
    <mat-icon [svgIcon]="panelTabActions[3].icon!"></mat-icon>
  </a>

  <ng-container *ngIf="isRepeatableGroup$ | async">
    <a
      *ngIf="selectedItemType === eMetricStructureSelectableType.group"
      role="button"
      aria-label="Repeatable Group Export Configuration"
      (click)="setSelectedPanelTab(panelTabActions[4])"
      [ngClass]="{ active: panelTabActions[4].id === selectedPanelTabAction.id }"
    >
      <mat-icon [svgIcon]="panelTabActions[4].icon!"></mat-icon>
    </a>
  </ng-container>
</div>
<ng-container *ngIf="selectedPanelTabAction.id === panelTabActions[0].id">
  <ng-container *ngIf="selectedItemType === eMetricStructureSelectableType.metric">
    <lib-metric-structure-settings
      class="panel-properties-content h-100"
      (closeProperties)="closeProperties()"
    ></lib-metric-structure-settings>
  </ng-container>
  <ng-container *ngIf="selectedItemType === eMetricStructureSelectableType.group">
    <lib-metric-structure-group-properties
      [valueDefinitionGroup]="$any(selectedItem)"
      class="panel-properties-content h-100"
      [formDisabled]="metric.category === eMetricCategory.THIRD_PARTY && canEditEveryMetrics === false"
      (closeGroup)="closeProperties()"
    ></lib-metric-structure-group-properties>
  </ng-container>
  <ng-container *ngIf="selectedItemType === eMetricStructureSelectableType.table">
    <lib-metric-structure-table-properties
      [metricTableGroup]="$any(selectedItem)"
      [metric]="metric"
      class="panel-properties-content h-100"
      [formDisabled]="metric.category === eMetricCategory.THIRD_PARTY && canEditEveryMetrics === false"
      (closePanel)="closeProperties()"
    ></lib-metric-structure-table-properties>
  </ng-container>
  <ng-container *ngIf="selectedItemType === eMetricStructureSelectableType.valueDefinition">
    <lib-metric-structure-field-properties
      [valueDefinition]="$any(selectedItem)"
      [metric]="metric"
      class="panel-properties-content h-100"
      [formDisabled]="metric.category === eMetricCategory.THIRD_PARTY && canEditEveryMetrics === false"
      (closePanel)="closeProperties()"
    ></lib-metric-structure-field-properties>
  </ng-container>
</ng-container>
<ng-container *libFeatureFlag="{ featureFlags: ['field_standard_code_association_panel_enabled'] }">
  <lib-metric-structure-field-information
    class="panel-properties-content"
    [metric]="metric"
    *ngIf="selectedPanelTabAction.id === panelTabActions[1].id"
    [panelTabAction]="panelTabActions[1]"
    (closePanel)="closeProperties()"
  >
  </lib-metric-structure-field-information>
</ng-container>
<ng-container *ngIf="selectedPanelTabAction.id === panelTabActions[2].id">
  <lib-metric-structure-panel-conditional-trigger
    class="panel-properties-content"
    [metric]="metric"
    [sourceValueDefinition]="$any(selectedItem)"
    [valueDefinitionGroups]="metric.value_definition_groups"
    (updateMetric)="updateMetricAndKeepSelectedTab($event)"
  ></lib-metric-structure-panel-conditional-trigger>
</ng-container>
<lib-metric-table-total-tab
  class="panel-properties-content"
  [metricTableGroup]="$any(selectedItem)"
  *ngIf="selectedPanelTabAction.id === panelTabActions[3].id"
  (close)="closeProperties()"
>
</lib-metric-table-total-tab>
<ng-container *ngIf="isRepeatableGroup$ | async">
  <lib-metric-structure-repeatable-group-properties-tab
    *ngIf="selectedPanelTabAction.id === panelTabActions[4].id"
    [valueDefinitionGroupId]="$any(selectedItem).id"
    [metric]="metric"
  >
  </lib-metric-structure-repeatable-group-properties-tab>
</ng-container>
