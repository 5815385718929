import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ActionItem,
  BaseTemplateReport,
  Permission,
  TemplateReportSection,
  TemplateReportSectionMetric,
  TemplateReportVersionPayload,
} from '../../models';
import { TranslateService } from '../../services/common';
import { TemplateReportStructureStateService } from '../services/template-report-structure-state.service';

export enum TemplateReportPanels {
  NONE = '0',
  SETTINGS = '1',
  SECTION = '2',
  SECTION_METRIC = '3',
}

@Component({
  selector: 'lib-template-report-structure-panel',
  templateUrl: './template-report-structure-panel.component.html',
  styleUrls: ['./template-report-structure-panel.component.scss'],
})
export class TemplateReportStructurePanelComponent implements OnChanges {
  @Input() managePermissions: Permission[] = [];
  @Input() selectedItem?: BaseTemplateReport | TemplateReportSection | TemplateReportSectionMetric;
  @Input() isEditing: boolean = false;
  @Input() templateReportId?: string;

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateVersion: EventEmitter<TemplateReportVersionPayload> =
    new EventEmitter<TemplateReportVersionPayload>();

  public templateReportId$: Observable<string | undefined>;
  public templateReportVersionId$: Observable<string | undefined>;
  public templateReportSectionsLabelById$: Observable<Map<string, TemplateReportSection>>;
  public panelActions: ActionItem[] = [];
  public activeTab = +TemplateReportPanels.NONE;

  public readonly eTemplateReportPanels = TemplateReportPanels;
  public readonly settingsActions: ActionItem[] = [
    {
      id: TemplateReportPanels.SETTINGS,
      title: this.translateService.instant('Settings'),
      icon: 'settings',
    },
  ];

  public readonly sectionActions: ActionItem[] = [
    {
      id: TemplateReportPanels.SECTION,
      title: this.translateService.instant('Section'),
      icon: 'group',
    },
  ];

  public readonly sectionMetricActions: ActionItem[] = [
    {
      id: TemplateReportPanels.SECTION_METRIC,
      title: this.translateService.instant('Customize'),
      icon: 'custom',
    },
  ];

  constructor(
    private readonly translateService: TranslateService,
    private readonly templateReportStructureStateService: TemplateReportStructureStateService
  ) {
    this.templateReportId$ = templateReportStructureStateService.templateReportId$;
    this.templateReportVersionId$ = templateReportStructureStateService.templateReportVersionId$;
    this.templateReportSectionsLabelById$ = templateReportStructureStateService.templateReportSectionsLabelById$;
  }

  public ngOnChanges(): void {
    if (this.selectedItem && ('name' in this.selectedItem || 'version' in this.selectedItem)) {
      this.panelActions = this.settingsActions;
      this.activeTab = +TemplateReportPanels.SETTINGS;
    } else if (!this.selectedItem || 'metrics' in this.selectedItem) {
      this.panelActions = this.sectionActions;
      this.activeTab = +TemplateReportPanels.SECTION;
    } else if ('section' in this.selectedItem && 'metric' in this.selectedItem) {
      this.panelActions = this.sectionMetricActions;
      this.activeTab = +TemplateReportPanels.SECTION_METRIC;
    } else {
      this.panelActions = [];
      this.activeTab = +TemplateReportPanels.NONE;
    }
  }
}
