<lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
<mat-form-field class="w-100" floatLabel="never">
  <mat-label>{{ data.data.type_details.placeholder }}</mat-label>
  <mat-select
    [formControl]="control"
    [multiple]="data.data.type_details.multi_choices"
    [matTooltip]="data.data.value"
    id="{{ data.id }}"
    panelClass="form-select-panel"
    (click)="filterControl.setValue('')"
    (opened)="setFocusOnSearch()"
  >
    <div
      class="select-search fx-row align-center"
      (click)="$event.stopPropagation()"
      *ngIf="(displayChoices.length || 0) > minMenuScrollItems"
    >
      <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
      <input
        placeholder="{{ 'Search' | translate }}"
        autocomplete="off"
        [formControl]="filterControl"
        tabIndex="1"
        #matSelectPanelInput
      />
      <a class="clear-search" *ngIf="filterControl.value" (click)="filterControl.setValue('')">
        <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
      </a>
      <hr />
    </div>
    <div class="options-container">
      <mat-option
        *ngFor="let option of filteredChoices | async"
        class="multiline-mat-option"
        [value]="option"
        [ngClass]="{ active: control.value === option }"
      >
        {{ option }}
      </mat-option>
      <mat-option *ngIf="!(filteredChoices | async)?.length">{{ "No results found" | translate }}</mat-option>
    </div>
  </mat-select>
  <mat-hint>{{ data.data.hint }}</mat-hint>
  <mat-error *ngIf="control.hasError('required')"> {{ "Missing value" | translate }}</mat-error>
</mat-form-field>
