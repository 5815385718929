import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { SortingTranslation } from '../sorting.translation';

export { SearchSort };

const SearchSort = {
  score: SortingTranslation.best_match,
  updated: SortingTranslation.last_updated,
  name: SortingTranslation.alphabetical,
};
