import { Tag } from '../models';

export class TagBuilder {
  private readonly _tag: Tag;

  constructor() {
    this._tag = {
      id: 'tag_id',
      code: 'tag_code',
      name: 'Tag One',
      label: 'Tag One',
      short_label: 'Tag1',
    };
  }

  name(name: string): TagBuilder {
    this._tag.name = name;
    return this;
  }

  build(): Tag {
    return this._tag;
  }
}
