<lib-search-bar
  [itemType]="itemType"
  [externalFilters]="externalFilters"
  [customFilters]="filters"
  [mode]="mode"
  [customProperties]="properties"
  [lockedFilters]="lockedFilters"
  (searchOptionsChange)="change($event)"
  [sortOrder]="sortOrder"
  [searchPlaceholder]="searchPlaceholder"
  [filterArgs]="filterArgs"
></lib-search-bar>
<lib-search-tree-items
  *ngIf="layout === eLayout.tree"
  [layout]="layout"
  [presentation]="presentation"
  [searchOptions]="searchOptions"
  [mode]="mode"
  [cache]="cache"
  [refresh]="refresh"
  [showActions]="showActions"
  (action)="applyAction($event)"
>
  <ng-content></ng-content>
</lib-search-tree-items>
<lib-search-items
  *ngIf="layout !== eLayout.tree"
  [layout]="layout"
  [presentation]="presentation"
  [searchOptions]="searchOptions"
  [mode]="mode"
  [cache]="cache"
  [refresh]="refresh"
  [showActions]="showActions"
  (action)="applyAction($event)"
  [grid]="grid"
  [clickItemPermissions]="clickItemPermissions"
>
  <ng-content></ng-content>
</lib-search-items>
