<div
  data-testid="drop-area"
  class="formfield"
  [ngClass]="{
    error: valueFormControl.errors?.length,
    disabled: valueFormControl.disabled
  }"
  (drop)="dropFile($event)"
  (dragover)="preventEventDefault($event)"
>
  <lib-form-field-label [label]="getLabel()" [control]="valueFormControl"></lib-form-field-label>
  <a
    class="card card-dashed align-center p-0 justify-center"
    tabindex="1"
    data-testid="fileCard"
    #focusElement
    [ngClass]="{ disabled: fileDocumentsList.length === maxFilesLimit }"
  >
    <div class="mat-body-2 color-grey-800" *ngIf="!uploading">
      {{ "Drag files to import, or" | translate }}
      <span *ngIf="!customBrowseDialog">
        <button lib-button type="browse">
          {{ "browse" | translate }}<input type="file" (change)="selectFileFromBrowse($event)" /></button
      ></span>
      <span *ngIf="customBrowseDialog">
        <button lib-button type="link" data-testid="browseBtn" (click)="launchCustomBrowseDialog()">
          {{ "browse" | translate }}
        </button></span
      >
    </div>
    <div class="fx-row align-center" *ngIf="uploading">
      <div class="mr-4 mat-body-2 color-grey-800">{{ "Uploading file. Please wait..." | translate }}</div>
      <div class="spinner">
        <mat-spinner aria-label="upload-spinner" diameter="20"></mat-spinner>
      </div>
    </div>
  </a>
  <mat-hint *ngIf="valueFormControl.untouched || valueFormControl.valid" class="fx-row justify-space-between">
    <div>{{ hint }}</div>
    <div>{{ "150MB" | translate }}</div>
  </mat-hint>
  <ng-container *ngIf="valueFormControl.touched && valueFormControl.invalid">
    <mat-error *ngFor="let error of valueFormControl.errors | errorKeys"> {{ messages?.[error] }}</mat-error>
  </ng-container>
</div>

<div class="card-dummies-container" [ngClass]="{ loaded: filesLoaded, 'mt-2': fileDocumentsList.length }">
  <div class="h-100 card-item-dummies">
    <div data-testid="loadingDummies" *ngFor="let i of [1, 2]" class="card card-link w-25 bkg-blink">&nbsp;</div>
  </div>
  <a
    *ngFor="let doc of fileDocumentsList"
    class="card card-link w-25 file"
    [ngClass]="{ 'card-downloading': isDocumentDownloading(doc.id) }"
    [attr.data-testid]="'file-card-' + doc.id"
  >
    <div *ngIf="!isDocumentDownloading(doc.id)" class="fx-row justify-space-between">
      <div>
        <mat-icon class="icon-sm mr-2 color-primary-600" svgIcon="link"></mat-icon>
        <button lib-button [attr.data-testid]="'fileLink-' + doc.id" type="link" (click)="downloadDoc(doc)">
          {{ doc?.name }}.{{ doc?.format }}
        </button>
      </div>
      <a
        *ngIf="valueFormControl.enabled"
        role="button"
        [attr.aria-label]="'deleteIcon-' + doc.id"
        (click)="deleteDoc(doc)"
      >
        <mat-icon class="icon-sm" svgIcon="trash"></mat-icon
      ></a>
    </div>
    <div *ngIf="isDocumentDownloading(doc.id)" class="fx-row justify-space-between white-spinner">
      <span class="color-white">{{ doc?.name }}.{{ doc?.format }}</span>
      <mat-spinner [attr.aria-label]="'download-spinner-' + doc.id" diameter="15"></mat-spinner>
    </div>
  </a>
</div>
