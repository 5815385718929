import { DataRequestSource, DataRequestSourceStatus, DataRequestUser, Source } from '../models';
import { SourceBuilder } from './source-builder';
import { faker } from '@faker-js/faker';
import { Builder } from './builder';

export abstract class BaseDataRequestSourceBuilder<T extends DataRequestSource> extends Builder<T> {
  protected constructor(instance: T) {
    super({
      ...instance,
    });
    this._instance.business_unit = new SourceBuilder().build();
    this._instance.data_request_users = [];
    this._instance.id = '123';
    this._instance.name = faker.name.jobDescriptor();
    this._instance.status = DataRequestSourceStatus.STARTED;
    this._instance.active = true;
  }

  id(id: string): BaseDataRequestSourceBuilder<T> {
    this._instance.id = id;
    return this;
  }

  business_unit(source: Source): BaseDataRequestSourceBuilder<T> {
    this._instance.business_unit = source;
    return this;
  }

  data_request_users(dataRequestUsers: DataRequestUser[]): BaseDataRequestSourceBuilder<T> {
    this._instance.data_request_users = dataRequestUsers;
    return this;
  }

  status(status: DataRequestSourceStatus): BaseDataRequestSourceBuilder<T> {
    this._instance.status = status;
    return this;
  }

  name(name: string): BaseDataRequestSourceBuilder<T> {
    this._instance.name = name;
    this._instance.business_unit.name = name;
    return this;
  }

  active(active: boolean): BaseDataRequestSourceBuilder<T> {
    this._instance.active = active;
    return this;
  }

  is_prefilling(isPrefilling: boolean): BaseDataRequestSourceBuilder<T> {
    this._instance.is_prefilling = isPrefilling;
    return this;
  }

  consolidation(consolidation: boolean): BaseDataRequestSourceBuilder<T> {
    this._instance.business_unit.consolidation = consolidation;
    return this;
  }

  prefill_frequency(prefillFrequency: string): BaseDataRequestSourceBuilder<T> {
    this._instance.prefill_frequency = prefillFrequency;
    return this;
  }
}

export class DataRequestSourceBuilder extends BaseDataRequestSourceBuilder<DataRequestSource> {
  constructor(instance: DataRequestSource) {
    super({ ...instance });
  }
}
