import { DashboardDataPoint, DashboardDataPointInputType, Value, ValueDefinition } from '../../models';
import { CreatedUpdatedBuilder } from '../created-updated-builder';
import { ValueBuilder } from '../value-builder';
import { ValueDefinitionBuilder } from '../value-definition-builder';
import { v4 as uuidv4 } from 'uuid';

export class DashboardDataPointBuilder extends CreatedUpdatedBuilder<DashboardDataPoint> {
  constructor() {
    super({
      id: uuidv4(),
      show_previous_year: false,
      input_type: DashboardDataPointInputType.integer,
      value_definition_id: uuidv4(),
      value: new ValueBuilder().build(),
      previous_year_value: new ValueBuilder().build(),
      value_definition: new ValueDefinitionBuilder().build(),
    });
  }

  id(id: string): DashboardDataPointBuilder {
    this._instance.id = id;
    return this;
  }

  value(value?: Value): DashboardDataPointBuilder {
    this._instance.value = value;
    return this;
  }

  valueDefinitionId(valueDefinitionId: string): DashboardDataPointBuilder {
    this._instance.value_definition_id = valueDefinitionId;
    return this;
  }

  valueDefinition(valueDefinition: ValueDefinition): DashboardDataPointBuilder {
    this._instance.value_definition = valueDefinition;
    return this;
  }

  previousYearValue(value?: Value): DashboardDataPointBuilder {
    this._instance.previous_year_value = value;
    return this;
  }

  showPreviousYear(showPreviousYear: boolean): DashboardDataPointBuilder {
    this._instance.show_previous_year = showPreviousYear;
    return this;
  }

  inputType(inputType: DashboardDataPointInputType): DashboardDataPointBuilder {
    this._instance.input_type = inputType;
    return this;
  }
}
