import { Entity } from '../entities';
import { PaginationFilter } from '../pagination';
import { DashboardWidget } from './widgets';

export interface Dashboard extends Entity {
  is_private: boolean;
  is_default: boolean;
  widgets: DashboardWidget[];
}

export interface DashboardTemplate {
  id: string | null;
}

export interface DashboardCreationRequest {
  name: string;
}

export interface DashboardRenameRequest {
  name: string;
}

export interface DashboardUpdateVisibilityRequest {
  is_private: boolean;
  is_default: boolean;
}

export interface DashboardCopyRequest {
  name: string;
}

export enum DashboardVisiblity {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export interface DashboardsFilteringOptions extends PaginationFilter {
  filters: {
    search_term?: string;
    owner?: string;
    visibility?: DashboardVisiblity;
  };
}
