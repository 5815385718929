import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, FormGroupDirective } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { BaseValue, DataFormatTemplate } from '../../../../models';
import { Required } from '../../../../decorators';

@Component({
  selector: 'lib-data-format-searchable-select-field',
  templateUrl: './data-format-searchable-select-field.component.html',
  styleUrls: ['./data-format-searchable-select-field.component.scss'],
})
export class DataFormatSearchableSelectFieldComponent implements OnInit {
  @Input() controlName!: string;
  @Input() displayChoices: string[] = [];
  @Input()
  @Required()
  set data(dataFormatTemplate: DataFormatTemplate<BaseValue>) {
    if (dataFormatTemplate) {
      this._data = dataFormatTemplate;
    }
  }
  get data(): DataFormatTemplate<BaseValue> {
    return this._data;
  }

  @Output() delete: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('matSelectPanelInput') matSelectPanelInput!: ElementRef<HTMLInputElement>;

  control!: UntypedFormControl;
  filterControl: UntypedFormControl = new UntypedFormControl('');
  filteredChoices!: Observable<string[]>;
  readonly minMenuScrollItems: number = 10;
  private _data!: DataFormatTemplate<BaseValue>;

  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit(): void {
    this.control = this.rootFormGroup.control.get(this.controlName) as UntypedFormControl;
    this.updateChoiceList(this.filterControl);
  }

  public setFocusOnSearch(): void {
    if (this.displayChoices.length >= this.minMenuScrollItems) {
      this.matSelectPanelInput.nativeElement.focus();
    }
  }

  public deleteValue(): void {
    this.delete.emit();
  }

  private updateChoiceList(control: UntypedFormControl): void {
    this.filteredChoices = control.valueChanges.pipe(
      startWith(null),
      map((choice: string | null) => (choice ? this.filter(choice) : this.displayChoices.slice()))
    );
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.displayChoices.filter((choice: string) => {
      return choice.toLowerCase().startsWith(filterValue);
    });
  }
}
