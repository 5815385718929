import { UpsertValue, UpsertValueGroup } from '../metric-editor-form';

export class UpsertValueGroupBuilder {
  private readonly _upsertValueGroup: UpsertValueGroup;

  constructor() {
    this._upsertValueGroup = {
      value_definition_group_id: 'vgd_1',
      values: [],
      id: 'vg_1',
    };
  }

  id(id: string): UpsertValueGroupBuilder {
    this._upsertValueGroup.id = id;
    return this;
  }

  valueDefinitionGroupId(vdgId: string): UpsertValueGroupBuilder {
    this._upsertValueGroup.value_definition_group_id = vdgId;
    return this;
  }

  values(values: UpsertValue[]): UpsertValueGroupBuilder {
    this._upsertValueGroup.values = values;
    return this;
  }

  build(): UpsertValueGroup {
    return this._upsertValueGroup;
  }
}
