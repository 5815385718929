import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { finalize, map, Observable, Subject, takeUntil } from 'rxjs';
import { ActionItemUtils } from '../../../../classes';
import { Required } from '../../../../decorators';
import {
  ActionItem,
  FieldInformationRequest,
  Indicator,
  isValueDefinition,
  ItemType,
  Metric,
  MetricCategory,
  MetricTableDefinition,
  Presentation,
  READONLY_HELPER_TEXTS_VALUE_DEFINITION_TYPES,
  RelatedMetric,
  RelatedMetricSource,
  ResourceType,
  SearchOptions,
  StandardCodes,
  ValueDefinition,
  ValueDefinitionGroup,
} from '../../../../models';
import { MetricApiService } from '../../../../services/types';
import { StandardCodesService } from '../../../../standard-codes';
import { MetricStructureSelectable, MetricTableGroup } from '../../../models';
import { IsTablePipe } from '../../../pipe/is-table/is-table.pipe';
import { MetricStructureStateService } from '../../../services/metric-structure-state.service';
import { FieldInformationForm } from './forms/field-information-form';
import { FieldInformationStore } from './metric-structure-field-information.store';
import { provideComponentStore } from '@ngrx/component-store';

interface DetailsInfo {
  id: string;
  coreVdId?: string;
  fieldPosition?: number;
}

@Component({
  selector: 'lib-metric-structure-field-information',
  templateUrl: './metric-structure-field-information.component.html',
  styleUrls: ['./metric-structure-field-information.component.scss'],
  providers: [IsTablePipe, provideComponentStore(FieldInformationStore)],
})
export class MetricStructureFieldInformationComponent implements OnInit, OnDestroy {
  public readonly ePresentation = Presentation;
  public readonly eRelatedMetricSource = RelatedMetricSource;
  public readonly eMetricCategory = MetricCategory;

  @Input() @Required() metric!: Metric;
  @Input() panelTabAction?: ActionItem;
  @Output() refresh: EventEmitter<Indicator> = new EventEmitter<Indicator>();
  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();

  valueDefinitionGroup?: ValueDefinitionGroup;
  detailsInfo?: DetailsInfo;
  public updating$: Observable<boolean> = this.metricStructureStateService.isMetricUpdating$;
  public selectedItem$: Observable<MetricStructureSelectable | undefined> = this.fieldInformationStore.selectedItem$;
  public tableDefinition$: Observable<MetricTableDefinition | undefined> = this.fieldInformationStore.tableDefinition$;

  public isAdmin = this.metricStructureStateService.isAdmin;

  public fieldInformationForm?: FieldInformationForm;

  public showDetails: boolean = false;
  public showRelatedMetricsForm: boolean = false;
  public showStandardCodesForm: boolean = false;

  public standardCodesOptions: ActionItem<StandardCodes>[] = [];
  public metricOptions: ActionItem[] = [];
  public valueDefinitionStandardCodes: StandardCodes[] = [];

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private metricStructureStateService: MetricStructureStateService,
    private standardCodeService: StandardCodesService,
    private metricsService: MetricApiService,
    private isTablePipe: IsTablePipe,
    private fieldInformationStore: FieldInformationStore
  ) {}

  public ngOnInit(): void {
    this.metricStructureStateService.selectedItem$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedItem: MetricStructureSelectable | undefined) => {
        this.fieldInformationStore.updateSelectedItem(selectedItem);
        this.initDetailsInfo(selectedItem);
        this.getStandardCodesForSelectedItem(selectedItem);
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public closeTab(): void {
    this.closePanel.emit();
  }

  public updateMetricsOptions(keywords: string) {
    this.metricsService
      .search({
        custom_filters: {
          categories: this.isAdmin ? [MetricCategory.THIRD_PARTY] : [MetricCategory.THIRD_PARTY, MetricCategory.CUSTOM],
        },
        filters: {},
        query: { keywords },
      })
      .subscribe((res) => {
        this.metricOptions = ActionItemUtils.resourcesToActionItem(res.data, ResourceType.metrics_indicator);
      });
  }

  public filterRefRelatedMetrics(rm: RelatedMetric, selectableItem?: MetricStructureSelectable): boolean {
    if (!selectableItem || rm.source === RelatedMetricSource.platform) {
      return false;
    }

    const associations = [...rm.original_metric_ids, ...rm.value_definition_ids, ...rm.metric_table_definition_ids];

    return associations.includes(selectableItem.id);
  }

  searchStandardCodes(keywords: string) {
    const searchOptions: SearchOptions = {
      item_type: ItemType.standard_codes,
      query: {
        keywords,
      },
      filters: {},
    };
    this.standardCodeService
      .search(searchOptions)
      .pipe(
        map((stdCodes) => stdCodes.map((stdCode) => ({ id: stdCode.id, title: stdCode.code, item: stdCode }))),
        takeUntil(this.destroy$)
      )
      .subscribe((standardCodes) => {
        this.standardCodesOptions = standardCodes;
      });
  }

  public saveProperties(selectedItem: MetricStructureSelectable) {
    if (this.fieldInformationForm?.valid) {
      const payload: FieldInformationRequest = this.fieldInformationForm.toModel();
      const metricStructureSelectable = <ValueDefinition | MetricTableGroup>{ ...selectedItem };

      this.metricStructureStateService.updateIsMetricUpdating(true);
      if (this.isTablePipe.transform(metricStructureSelectable)) {
        this.metricsService
          .updateMetricTableInformation(this.metric.id, metricStructureSelectable.table_id, payload)
          .pipe(finalize(() => this.metricStructureStateService.updateIsMetricUpdating(false)))
          .subscribe((result) => {
            this.metricStructureStateService.updateMetric(result.data);
            this.fieldInformationForm?.markAsPristine();
          });
      } else {
        this.metricsService
          .updateFieldInformation(
            this.metric.id,
            metricStructureSelectable.value_definition_group_id,
            metricStructureSelectable.id,
            payload
          )
          .pipe(finalize(() => this.metricStructureStateService.updateIsMetricUpdating(false)))
          .subscribe((result) => {
            this.metricStructureStateService.updateMetric(result.data);
            this.fieldInformationForm?.markAsPristine();
          });
      }
    }
  }

  private initFieldInformationForm(
    standardCodes: StandardCodes[],
    selectedItem: MetricStructureSelectable | undefined
  ): void {
    const standardCodeActionItems: ActionItem<StandardCodes>[] = standardCodes.map((item) => ({
      id: item.id,
      title: item.code || '',
      item,
    }));

    const relatedMetrics = this.metric.related_metrics?.filter((rm) => {
      const associations = [...rm.original_metric_ids, ...rm.value_definition_ids, ...rm.metric_table_definition_ids];

      return (
        (!rm.source || rm.source === RelatedMetricSource.platform) && associations.includes(String(selectedItem?.id))
      );
    });

    const relatedMetricsActionItems: ActionItem<Indicator>[] | undefined = relatedMetrics?.map(
      (rm) =>
        ActionItemUtils.resourceToActionItem(
          rm.equivalent_metric,
          ResourceType.metrics_indicator
        ) as ActionItem<Indicator>
    );

    this.initFieldsConditions(selectedItem);

    if (selectedItem) {
      if ('table_id' in selectedItem) {
        this.tableDefinition$.subscribe((tableDefinition: MetricTableDefinition | undefined) => {
          this.fieldInformationForm = new FieldInformationForm(
            standardCodeActionItems,
            relatedMetricsActionItems,
            this.metric.reference_v2 ? tableDefinition?.technical_protocol : null,
            this.showStandardCodesForm,
            this.showRelatedMetricsForm
          );
        });
      } else {
        this.fieldInformationForm = new FieldInformationForm(
          standardCodeActionItems,
          relatedMetricsActionItems,
          this.metric.reference_v2 && 'technical_protocol' in selectedItem ? selectedItem.technical_protocol : null,
          this.showStandardCodesForm,
          this.showRelatedMetricsForm
        );
      }
    }
  }

  private initFieldsConditions(selectedItem: MetricStructureSelectable | undefined): void {
    const isReadonlyField = isValueDefinition(selectedItem)
      ? READONLY_HELPER_TEXTS_VALUE_DEFINITION_TYPES.includes(selectedItem.type)
      : false;
    if (isReadonlyField) {
      this.showRelatedMetricsForm = this.showStandardCodesForm = false;
    } else if (this.isAdmin) {
      const showForm = this.metric.category === this.eMetricCategory.REFERENCE;
      this.showRelatedMetricsForm = showForm;
      this.showStandardCodesForm = showForm;
    } else {
      switch (this.metric.category) {
        case this.eMetricCategory.REFERENCE:
          this.showDetails = true;
          this.showRelatedMetricsForm = true;
          break;
        case this.eMetricCategory.CUSTOM:
          this.showStandardCodesForm = true;
          break;
      }
    }
  }

  private getStandardCodesForSelectedItem(selectedItem: MetricStructureSelectable | undefined): void {
    this.getStandardCodesBasedOnSelectableItem(selectedItem as MetricTableGroup | ValueDefinition)
      .pipe(takeUntil(this.destroy$))
      .subscribe((standardCodes) => {
        this.initFieldInformationForm(standardCodes, selectedItem);
        this.valueDefinitionStandardCodes = standardCodes;
        this.searchStandardCodes('');
        this.updateMetricsOptions('');
      });
  }

  private getStandardCodesBasedOnSelectableItem(
    selectableItem?: MetricTableGroup | ValueDefinition
  ): Observable<StandardCodes[]> {
    if (this.isTablePipe.transform(selectableItem)) {
      return this.metricsService
        .listMetricTableStandardCodes([selectableItem.table_id], this.metric.id)
        .pipe(map((mtsc) => mtsc.data[0].standard_codes));
    } else {
      return this.metricsService
        .getFieldStandardCodes(this.metric.id, selectableItem?.id || '')
        .pipe(map((vdscs) => vdscs.data[0].standard_codes));
    }
  }

  private initDetailsInfo(selectedItem: MetricStructureSelectable | undefined): void {
    if (isValueDefinition(selectedItem)) {
      this.detailsInfo = {
        id: selectedItem.id,
        coreVdId: selectedItem.core_value_definition_id,
        fieldPosition: selectedItem.position,
      };
    } else {
      this.detailsInfo = { id: selectedItem?.id ?? '' };
    }
  }
}
