import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Metric, SectionMetricDisplay, TemplateReportSectionMetricPayload } from '../../../../models';

export type ReportSectionMetricForm = {
  originalCode: FormControl<string | null>;
  placeholderCode: FormControl<string | null>;
  placeholderDescription: FormControl<string | null>;
  sectionId: FormControl<string | null>;
};
export class TemplateReportSectionMetricForm extends FormGroup<ReportSectionMetricForm> {
  constructor(
    readonly metric?: Metric,
    readonly sectionDisplay?: SectionMetricDisplay,
    readonly sectionId?: string,
    readonly fb: FormBuilder = new FormBuilder()
  ) {
    super({
      originalCode: fb.control(metric?.code || null),
      placeholderCode: fb.control<string | null>(sectionDisplay?.code ?? ''),
      placeholderDescription: fb.control<string | null>(sectionDisplay?.description ?? ''),
      sectionId: fb.control(sectionId || null, { validators: [Validators.required] }),
    });
  }

  toModel(): TemplateReportSectionMetricPayload {
    return {
      sectionId: String(this.value.sectionId),
      placeholder_code: this.value.placeholderCode || null,
      placeholder_description: this.value.placeholderDescription || null,
    };
  }
}
