import { CreatedUpdatedEntity, Framework, StandardCodes } from './entities';

export interface MinimalTaxonomy extends CreatedUpdatedEntity {
  id: string;
  label: string;
}

export enum TaxonomyCategory {
  node = 'node',
  standard_code = 'standard_code',
  third_party_metric = 'third_party_metric',
}

export interface Taxonomy extends MinimalTaxonomy {
  is_leaf: boolean;
  end?: string;
  parent_id?: string | null;
  standard_codes?: StandardCodes[];
  start_date?: string;
  children?: Taxonomy[];
  framework_id: string;
  category: TaxonomyCategory;
  code: string;
  description?: string;
  end_date?: string;
  published_date?: string;
  guidance?: string;
  disclaimers?: string;
  active: boolean;
  is_client_selectable: boolean;
  children_mutually_exclusive: boolean;
  framework?: Framework;
  value_definition_ids?: string[];
  metric_table_definition_ids?: string[];
}

export interface TaxonomiesPayload {
  label: string;
  is_leaf?: boolean;
  parent_id?: string | null;
  start?: string | null;
  end?: string | null;
  code?: string | null;
}
