import { Injectable } from '@angular/core';
import {
  ActionItem,
  ConsolidationRules,
  ConsolidationTriggers,
  TipTypeDetailsIcon,
  ValueDefinitionDisplayType,
  ValueDefinitionSize,
  ValueDefinitionTemplate,
  ValueDefinitionType,
  DefaultConsolidationRule,
} from '../../models';
import { ClientConfigService } from '../../services/client';
import { ValueDefinitionTemplateType } from '../models';
import { TranslateService } from '../../services/common';
import { ValueDefinitionSizeTranslation } from '../translations/value-definition-template.translation';
import { ValueDefinitionConstants } from '../../constants/value-definition-constants';

export const DEFAULT_UNIT_CODE = 'default';
export const DEFAULT_UNIT_FAMILY = 'general';
@Injectable({
  providedIn: 'root',
})
export class ValueDefinitionTemplateService {
  private readonly allValueDefinitionSizes = this.translateService.listResources(ValueDefinitionSizeTranslation);
  private _valueDefinitionTemplates: ValueDefinitionTemplate[] = [
    // Helpers
    {
      id: ValueDefinitionTemplateType.category,
      name: this.translateService.instant('Helpers'),
      type: ValueDefinitionType.label,
      code: 'category', // used for icons
      size: ValueDefinitionSize.large,
      newline: true,
      required: false,
      context: false,
      value_definition_group_id: '',
      active: true,
      consolidation_rule: DefaultConsolidationRule,
    },
    {
      id: ValueDefinitionTemplateType.template,
      name: this.translateService.instant('Instructions'),
      type: ValueDefinitionType.tip,
      code: 'guidance',
      type_details: {
        value: '',
        icon: TipTypeDetailsIcon.tip,
      },
      label: this.translateService.instant('Instruction'),
      size: ValueDefinitionSize.large,
      newline: true,
      required: false,
      context: false,
      value_definition_group_id: '',
      active: true,
      consolidation_rule: DefaultConsolidationRule,
    },
    {
      id: ValueDefinitionTemplateType.template,
      name: this.translateService.instant('Subtitle'),
      type: ValueDefinitionType.subtitle,
      code: 'text',
      type_details: {
        value: '',
      },
      label: this.translateService.instant('Subtitle'),
      size: ValueDefinitionSize.large,
      newline: true,
      required: false,
      context: false,
      value_definition_group_id: '',
      active: true,
      consolidation_rule: DefaultConsolidationRule,
    },
    {
      id: ValueDefinitionTemplateType.template,
      name: this.translateService.instant('Document link'),
      type: ValueDefinitionType.document,
      code: 'link',
      type_details: {
        document_id: '',
        document_host_env: 'platform',
      },
      active: true,
      label: this.translateService.instant('Document link'),
      size: ValueDefinitionSize.medium,
      newline: false,
      required: false,
      context: false,
      value_definition_group_id: '',
      document: {
        category: '',
        end: '',
        format: '',
        id: '',
        name: '',
        public: false,
        scope: 'client',
        start: '',
        storage_filename: '',
        type: '',
      },
      consolidation_rule: DefaultConsolidationRule,
    },
    // Formfields
    {
      id: ValueDefinitionTemplateType.category,
      name: this.translateService.instant('Form fields'),
      type: ValueDefinitionType.label,
      code: 'category',
      size: ValueDefinitionSize.large,
      newline: true,
      required: false,
      context: false,
      value_definition_group_id: '',
      active: true,
      consolidation_rule: DefaultConsolidationRule,
    },
    {
      id: ValueDefinitionTemplateType.template,
      name: this.translateService.instant('Text'),
      type: ValueDefinitionType.text,
      code: 'textarea',
      type_details: {
        placeholder: '',
        textarea: false,
        rich_text: false,
      },
      label: '',
      size: ValueDefinitionSize.medium,
      newline: false,
      required: false,
      context: false,
      value_definition_group_id: '',
      active: true,
      consolidation_rule: DefaultConsolidationRule,
    },
    {
      id: ValueDefinitionTemplateType.template,
      name: this.translateService.instant('Rich text'),
      type: ValueDefinitionType.text,
      code: 'rich-text',
      type_details: {
        textarea: true,
        placeholder: '',
        rich_text: true,
      },
      label: '',
      size: ValueDefinitionSize.large,
      newline: true,
      required: false,
      context: false,
      value_definition_group_id: '',
      active: true,
      consolidation_rule: DefaultConsolidationRule,
    },
    {
      id: ValueDefinitionTemplateType.template,
      name: this.translateService.instant('Number'),
      type: ValueDefinitionType.integer,
      code: 'number',
      type_details: {
        units: DEFAULT_UNIT_CODE,
        family: DEFAULT_UNIT_FAMILY,
      },
      label: '',
      size: ValueDefinitionSize.medium,
      newline: false,
      required: false,
      context: false,
      value_definition_group_id: '',
      consolidation_rule: ConsolidationRules.sum,
      consolidation_trigger: ConsolidationTriggers.update_when_one_value,
      active: true,
    },
    {
      id: ValueDefinitionTemplateType.template,
      name: this.translateService.instant('Number calculations'),
      type: ValueDefinitionType.calculated,
      code: 'unit',
      type_details: {
        formula: 'a+b',
        units: DEFAULT_UNIT_CODE,
        family: DEFAULT_UNIT_FAMILY,
      },
      label: '',
      size: ValueDefinitionSize.medium,
      newline: false,
      required: false,
      context: false,
      value_definition_group_id: '',
      active: true,
      consolidation_rule: DefaultConsolidationRule,
    },
    {
      id: ValueDefinitionTemplateType.template,
      name: this.translateService.instant('Date'),
      type: ValueDefinitionType.date,
      code: 'calendar',
      label: '',
      size: ValueDefinitionSize.medium_small,
      newline: false,
      required: false,
      context: false,
      value_definition_group_id: '',
      type_details: {
        format: 'YYYY-MM-DD',
      },
      active: true,
      consolidation_rule: DefaultConsolidationRule,
    },
    {
      id: ValueDefinitionTemplateType.template,
      name: this.translateService.instant('Choice'),
      type: ValueDefinitionType.choice,
      code: 'select',
      type_details: {
        choices: [],
        display_explanation: false,
        explanation_label: this.translateService.instant('Please Explain'),
        explanation_required: false,
        multi_choices: false,
        allow_add_option: false,
        widget_type: null,
      },
      label: '',
      size: ValueDefinitionSize.medium,
      newline: false,
      required: false,
      context: false,
      value_definition_group_id: '',
      active: true,
      consolidation_rule: DefaultConsolidationRule,
    },
    {
      id: ValueDefinitionTemplateType.template,
      name: this.translateService.instant('True/False'),
      type: ValueDefinitionType.boolean,
      code: 'choice',
      type_details: {
        prompt_on_true: false,
        on_true_text_required: false,
        prompt_on_false: false,
        on_false_text_required: false,
        label_true: this.translateService.instant('Yes'),
        label_false: this.translateService.instant('No'),
        label_prompt_true: this.translateService.instant('Please Explain'),
        label_prompt_false: this.translateService.instant('Please Explain'),
      },
      label: '',
      size: ValueDefinitionSize.large,
      newline: true,
      required: false,
      context: false,
      value_definition_group_id: '',
      active: true,
      consolidation_rule: DefaultConsolidationRule,
    },
    {
      id: ValueDefinitionTemplateType.template,
      name: this.translateService.instant('File attachment'),
      type: ValueDefinitionType.file,
      code: 'attach',
      label: '',
      size: ValueDefinitionSize.large,
      newline: false,
      required: false,
      context: false,
      value_definition_group_id: '',
      type_details: {},
      active: true,
      consolidation_rule: DefaultConsolidationRule,
    },
    {
      id: ValueDefinitionTemplateType.template,
      name: this.translateService.instant('File attachment V2'),
      type: ValueDefinitionType.file_v2,
      code: 'attach',
      label: '',
      size: ValueDefinitionSize.large,
      newline: false,
      required: false,
      context: false,
      value_definition_group_id: '',
      type_details: {
        multiple: false,
        max_files: 1,
        display_url: false,
        url_required: false,
        display_page_number: false,
        display_explanation: false,
        explanation_required: false,
        explanation_label: this.translateService.instant(ValueDefinitionConstants.DEFAULT_EXPLANATION_LABEL),
      },
      active: true,
      consolidation_rule: DefaultConsolidationRule,
    },
  ];

  public valueDefinitionTypeSizes: Record<ValueDefinitionDisplayType, ActionItem[]> = {
    [ValueDefinitionDisplayType.text_area]: this.allValueDefinitionSizes,
    [ValueDefinitionDisplayType.text_simple]: this.allValueDefinitionSizes,
    [ValueDefinitionDisplayType.integer]: this.allValueDefinitionSizes,
    [ValueDefinitionDisplayType.decimal]: this.allValueDefinitionSizes,
    [ValueDefinitionDisplayType.choice]: this.allValueDefinitionSizes,
    [ValueDefinitionDisplayType.choice_multiple]: this.allValueDefinitionSizes,
    [ValueDefinitionDisplayType.choice_searchable]: this.allValueDefinitionSizes,
    [ValueDefinitionDisplayType.choice_radio]: this.allValueDefinitionSizes,
    [ValueDefinitionDisplayType.boolean]: [],
    [ValueDefinitionDisplayType.calculated]: this.allValueDefinitionSizes,

    [ValueDefinitionDisplayType.date]: this.translateService.listResources({
      [ValueDefinitionSize.small]: ValueDefinitionSizeTranslation[ValueDefinitionSize.small],
      [ValueDefinitionSize.medium_small]: ValueDefinitionSizeTranslation[ValueDefinitionSize.medium_small],
      [ValueDefinitionSize.large_small]: ValueDefinitionSizeTranslation[ValueDefinitionSize.large_small],
    }),

    [ValueDefinitionDisplayType.datetime]: [],
    [ValueDefinitionDisplayType.document]: [],
    [ValueDefinitionDisplayType.file]: [],
    [ValueDefinitionDisplayType.file_v2]: [],
    [ValueDefinitionDisplayType.label]: [],
    [ValueDefinitionDisplayType.subtitle]: [],
    [ValueDefinitionDisplayType.text]: [],
    [ValueDefinitionDisplayType.tip]: [],
    [ValueDefinitionDisplayType.text_area_validation]: [],
    [ValueDefinitionDisplayType.text_rich]: [],
    [ValueDefinitionDisplayType.table]: [],
    [ValueDefinitionDisplayType.none]: [],
    [ValueDefinitionDisplayType.metric]: [],
  };

  constructor(private translateService: TranslateService, private clientConfigService: ClientConfigService) {}

  public getValueDefinitionTemplates(): ValueDefinitionTemplate[] {
    let valueDefinitionTemplates = this._valueDefinitionTemplates;

    if (!this.clientConfigService.areAnyFeatureFlagsEnabled(['file_attachment_v2_enabled'])) {
      valueDefinitionTemplates = valueDefinitionTemplates.filter((t) => t.type !== ValueDefinitionType.file_v2);
    }
    return valueDefinitionTemplates;
  }
}
