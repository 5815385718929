import { Framework, FrameworkCategory, MetricCategory, StandardCodeCategory } from '../models';
import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';
import { EntityBuilder } from './entity-builder';

export class FrameworkBuilder extends EntityBuilder<Framework> {
  constructor() {
    super({
      id: uuidv4(),
      code: faker.random.alphaNumeric(10),
      name: faker.company.name(),
      active: true,
      image_url: faker.image.imageUrl(),
      metric_category: MetricCategory.REFERENCE,
      standard_code_category: undefined,
      category: FrameworkCategory.METRIC,
    });
  }

  id(id: string): FrameworkBuilder {
    this._instance.id = id;
    return this;
  }

  name(name: string): FrameworkBuilder {
    this._instance.name = name;
    return this;
  }

  metricCategory(metricCategory: MetricCategory): FrameworkBuilder {
    this._instance.metric_category = metricCategory;
    return this;
  }

  standardCodeCategory(standardCodeCategory: StandardCodeCategory): FrameworkBuilder {
    this._instance.standard_code_category = standardCodeCategory;
    return this;
  }

  category(category: FrameworkCategory): FrameworkBuilder {
    this._instance.category = category;
    return this;
  }
}
