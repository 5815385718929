import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common/api/api.service';
import { CacheService } from '../../common/cache/cache.service';
import {
  ApiResponse,
  ApplicationApiDefinition,
  SearchOptions,
  SearchOptionsPayload,
  SortFilter,
  Tag,
} from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class ClientTagsService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private baseService: BaseService, private apiService: ApiService, private cacheService: CacheService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.tags;
  }

  swapOrderByScoreForName(payloadSearchOptions: SearchOptionsPayload): void {
    if (payloadSearchOptions.order_by === SortFilter.score) {
      payloadSearchOptions.order_by = SortFilter.name;
    }
  }

  search(searchOptions: SearchOptions): Observable<ApiResponse<Tag[]>> {
    const payloadSearchOptions: SearchOptionsPayload = this.baseService.payloadFromSearchOptions(searchOptions);

    // This should not be needed. There is an unknown side effect that set the orderBy to score even tho this is not a ES payload.
    this.swapOrderByScoreForName(payloadSearchOptions);

    return this.apiService.post(`${this.servicePath}${this.resource}/list`, payloadSearchOptions);
  }

  list(): Observable<ApiResponse<Tag[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/list`, {
      page_size: 1000, // return first 1000 tags, TODO: lazy load tags on autocomplete
    });
  }

  createTag(name: string): Observable<ApiResponse<Tag>> {
    return this.apiService
      .post(`${this.servicePath}${this.resource}/tags`, {
        name,
        label: name,
      })
      .pipe(
        tap(() => {
          this.cacheService.clearCategory('tag');
        })
      );
  }

  getTag(tagId: string, checkAssociations = false): Observable<ApiResponse<Tag>> {
    let params = new HttpParams();
    params = params.append('check_associations', checkAssociations);

    return this.apiService.get(`${this.servicePath}${this.resource}/tags/${tagId}`, { params });
  }

  deleteTag(tagId: string, checkAssociations = false): Observable<void> {
    let params = new HttpParams();
    params = params.append('check_associations', checkAssociations);
    return this.apiService.delete(`${this.servicePath}${this.resource}/tags/${tagId}`, '', params);
  }

  updateTag(tagId: string, updatedTagLabel: string): Observable<ApiResponse<Tag>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/tags/${tagId}`, {
      label: updatedTagLabel,
      name: updatedTagLabel,
    });
  }
}
