import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  MetricTableCalculationDefinition,
  MetricTableCalculationTypeDetailFormulaFilter,
  MetricTableCalculationTypeDetailFormulaOperation,
} from '../../../../../../models';
import { ValidatorsUtils } from '../../../../../../classes/FormUtils/validators-utils';
import { MetricTableTotalFormulaPipe } from '../../../../../../pipes/metric-table-total-formula/metric-table-total-formula.pipe';
import { startWith } from 'rxjs/operators';
import { MetricTableTotalContextColumnForm } from './metric-table-total-context-column-form';

export class MetricTableTotalForm extends UntypedFormGroup {
  readonly labelControl = this.get('label') as UntypedFormControl;
  readonly positionControl = this.get('position') as UntypedFormControl;
  readonly operationControl = this.get('operation') as UntypedFormControl;
  readonly inputColumnsFormArray = this.get('inputColumns') as UntypedFormArray;
  readonly contextColumnsFormArray = this.get('contextColumns') as UntypedFormArray;
  readonly formulaControl = this.get('formula') as UntypedFormControl;
  readonly unitsControl = this.get('units') as UntypedFormControl;
  readonly familyControl = this.get('family') as UntypedFormControl;
  readonly decimalValueControl = this.get('decimalValue') as UntypedFormControl;

  constructor(
    totalPosition: number,
    model?: MetricTableCalculationDefinition,
    readonly fb: UntypedFormBuilder = new UntypedFormBuilder(),
    private readonly metricTableTotalFormulaPipe: MetricTableTotalFormulaPipe = new MetricTableTotalFormulaPipe()
  ) {
    super(
      fb.group({
        label: fb.control(model?.label, [
          Validators.required,
          Validators.maxLength(50),
          ValidatorsUtils.isNotOnlySpaces,
        ]),
        position: fb.control(model?.position ?? totalPosition),
        operation: fb.control(
          model?.type_details.formula.operation ?? MetricTableCalculationTypeDetailFormulaOperation.SUM,
          Validators.required
        ),
        inputColumns: fb.array([], MetricTableTotalForm.atLeastOneInputColumn),
        contextColumns: fb.array([]),
        formula: fb.control({ value: '', disabled: true }),
        units: fb.control(model?.type_details.units, Validators.required),
        family: fb.control(model?.type_details.family, Validators.required),
        decimalValue: fb.control(String(model?.type_details.max_decimals ?? ''), Validators.required),
      }).controls
    );

    model?.type_details.formula.columns.forEach((inputColumn) => {
      this.addInputColumnControl(inputColumn, false);
    });

    model?.type_details.formula.filters.forEach((contextColumn) => {
      this.addContextColumnControl(contextColumn, false);
    });

    this.manageFormula();
  }

  public toModel(): MetricTableCalculationDefinition {
    return {
      label: (this.labelControl.value as string | undefined)?.trim() as string,
      position: this.positionControl.value as number,
      type_details: {
        formula: {
          operation: this.operationControl.value as MetricTableCalculationTypeDetailFormulaOperation,
          columns: this.inputColumnsFormArray.controls.map((control) => control.value.column) as string[],
          filters: this.contextColumnsFormArray.controls.map((control: AbstractControl) =>
            (control as MetricTableTotalContextColumnForm).toModel()
          ),
        },
        units: this.unitsControl.value,
        family: this.familyControl.value,
        max_decimals: this.decimalValueControl.value,
      },
    };
  }

  public addInputColumnControl(inputColumn?: string, markAsDirty: boolean = true): void {
    this.inputColumnsFormArray.push(
      new UntypedFormGroup({
        column: new UntypedFormControl(inputColumn),
      })
    );
    if (markAsDirty) {
      this.markAsDirty();
    }
  }

  public addContextColumnControl(
    contextColumn?: MetricTableCalculationTypeDetailFormulaFilter,
    markAsDirty: boolean = true
  ): void {
    this.contextColumnsFormArray.push(new MetricTableTotalContextColumnForm(contextColumn));
    if (markAsDirty) {
      this.markAsDirty();
    }
  }

  public removeInputColumnControl(index: number): void {
    this.inputColumnsFormArray.removeAt(index);
    if (!this.inputColumnsFormArray.length) {
      this.contextColumnsFormArray.clear();
    }
    this.inputColumnsFormArray.markAsDirty();
  }

  public removeContextColumnControl(index: number): void {
    this.contextColumnsFormArray.removeAt(index);
    this.contextColumnsFormArray.markAsDirty();
  }

  public containsContextColumn(column: string): boolean {
    return Boolean(
      this.contextColumnsFormArray.controls.find(
        (control) => (control as MetricTableTotalContextColumnForm).columnControl.value === column
      )
    );
  }

  private manageFormula(): void {
    this.valueChanges.pipe(startWith(undefined)).subscribe(() => {
      this.formulaControl.setValue(this.metricTableTotalFormulaPipe.transform(this.toModel().type_details.formula), {
        emitEvent: false,
      });
    });
  }

  private static atLeastOneInputColumn(control: AbstractControl): ValidationErrors | null {
    return (control as UntypedFormArray).controls.length < 1 ? { atLeastOneInputColumn: true } : null;
  }
}
