import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { OauthToken } from '../../models';
import { PublicAuthApiService } from '../api/public-auth-api.service';

@Injectable({ providedIn: 'root' })
export class TokenService {
  constructor(private apiAuthService: PublicAuthApiService) {}

  public invalidateTokens(access_token: string): Observable<unknown> {
    const headers: HttpHeaders = new HttpHeaders({ 'Content-type': 'application/json' }).append(
      'Authorization',
      'Bearer ' + access_token
    );

    return this.apiAuthService.get('/oauth2/sessions/logout', { headers });
  }

  public refreshToken({ refresh_token }: { refresh_token: string }): Observable<OauthToken> {
    const headers: HttpHeaders = new HttpHeaders({ 'Content-type': 'application/json' }).set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    const body = new HttpParams({
      fromObject: {
        refresh_token,
        grant_type: 'refresh_token',
        client_id: this.apiAuthService.apiConfig.clientId || '',
        scope: this.apiAuthService.apiConfig.scope || '',
      },
    });

    return this.apiAuthService.post('/oauth2/token', body.toString(), undefined, undefined, undefined, headers);
  }
}
