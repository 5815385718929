import { MetricStandardCode, StandardCodes } from '../models';
import { Builder } from './builder';

export class MetricFieldStandardCodeBuilder extends Builder<MetricStandardCode> {
  constructor() {
    super({
      metric_level: [],
      field_level: [],
    });
  }

  fieldLevel(field_level: StandardCodes[]): MetricFieldStandardCodeBuilder {
    this._instance.field_level = field_level;
    return this;
  }

  metricLevel(metric_level: StandardCodes[]): MetricFieldStandardCodeBuilder {
    this._instance.metric_level = metric_level;
    return this;
  }
}
