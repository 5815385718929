import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse, ApplicationApiDefinition, SearchOptions, Source } from '../../../models';

import { ApiService } from '../../common/api/api.service';
import { ClientConfigService } from '../client-config/client-config.service';

@Injectable({
  providedIn: 'root',
})
export class ClientSourcesService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;
  withStaticConsolidation = this.clientConfigService.areAnyFeatureFlagsEnabled(['static_consolidation']);

  constructor(private apiService: ApiService, private clientConfigService: ClientConfigService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.businessUnits;
  }

  payloadFromSearchOptions(searchOptions: SearchOptions): HttpParams {
    let params = new HttpParams();

    if (searchOptions.sort) {
      params = params.append('order_by', searchOptions.sort.id);
      if (searchOptions.sort.id === 'updated') {
        params = params.append('order_by_direction', 'desc');
      }
    }
    if (searchOptions.filters?.type) {
      params = params.append('business_unit_type', searchOptions.filters.type.id);
    }

    if (searchOptions.custom_filters?.company_id) {
      params = params.append('company_id', searchOptions.custom_filters.company_id as string);
    }

    if (searchOptions.query.keywords) {
      params = params.append('query', searchOptions.query.keywords);
    }

    return params;
  }

  search(searchOptions: SearchOptions): Observable<ApiResponse<Source[]>> {
    const params: any = this.payloadFromSearchOptions(searchOptions);
    return this.apiService.get<ApiResponse<Source[]>>(`${this.servicePath}${this.resource}/business_units`, { params });
  }

  createSource(payload: any): Observable<ApiResponse<Source>> {
    return this.apiService.post<ApiResponse<Source>>(`${this.servicePath}${this.resource}/business_units`, payload);
  }

  updateSource(sourceId: string, payload: any): Observable<ApiResponse<Source>> {
    return this.apiService.put<ApiResponse<Source>>(
      `${this.servicePath}${this.resource}/business_units/${sourceId}`,
      payload
    );
  }

  getSource(sourceId: string): Observable<ApiResponse<Source>> {
    return this.apiService.get<ApiResponse<Source>>(`${this.servicePath}${this.resource}/business_units/${sourceId}`);
  }

  listSources(): Observable<ApiResponse<Source[]>> {
    return this.apiService.get<ApiResponse<Source[]>>(`${this.servicePath}${this.resource}/business_units`);
  }
}
