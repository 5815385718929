import { v4 as uuidv4 } from 'uuid';

import { DataRequestFieldCommentReply, DataRequestSourceStatus } from '../models';
import { faker } from '@faker-js/faker';
import { Builder } from './builder';

export class DataRequestFieldCommentReplyBuilder extends Builder<DataRequestFieldCommentReply> {
  constructor() {
    super({
      id: uuidv4(),
      created: faker.date.past(),
      comment: faker.lorem.word(2),
      author: faker.name.fullName(),
      data_request_source_status: DataRequestSourceStatus.IN_APPROVAL,
      data_request_field_comment_id: uuidv4(),
    });
  }

  id(id: string): DataRequestFieldCommentReplyBuilder {
    this._instance.id = id;
    return this;
  }

  created(created: Date): DataRequestFieldCommentReplyBuilder {
    this._instance.created = created;
    return this;
  }

  comment(comment: string): DataRequestFieldCommentReplyBuilder {
    this._instance.comment = comment;
    return this;
  }

  author(author: string): DataRequestFieldCommentReplyBuilder {
    this._instance.author = author;
    return this;
  }

  data_request_source_status(data_request_source_status: DataRequestSourceStatus): DataRequestFieldCommentReplyBuilder {
    this._instance.data_request_source_status = data_request_source_status;
    return this;
  }
}
