import {
  MinimalMetric,
  MinimalReport,
  PlatformValueGroupSetStatus,
  ReportHistoricalValue,
  Value,
  VGSetStatusContext,
  ValueGroupSet,
} from '../models';
import { ReportBuilder } from './report-builder';
import { MetricBuilder } from './metric-builder';
import { v4 as uuidv4 } from 'uuid';
import { ValueGroupSetBuilder } from './value-group-set-builder';

export class ReportHistoricalValueBuilder {
  private readonly _reportHistoricalValue: ReportHistoricalValue;

  constructor() {
    this._reportHistoricalValue = {
      value_definition_group_id: uuidv4(),
      value_definition_ids: [uuidv4()],
      template_version_id: uuidv4(),
      mapped_report: new ReportBuilder().build(),
      mapped_metric: new MetricBuilder().build(),
      values: [],
      frequency_code: '2022',
      value_group_set: [
        new ValueGroupSetBuilder()
          .status(PlatformValueGroupSetStatus.IN_PROGRESS)
          .statusContext(VGSetStatusContext.entered_via_collect)
          .build(),
      ],
    };
  }

  valueDefinitionGroupId(valueDefinitionGroupId: string): ReportHistoricalValueBuilder {
    this._reportHistoricalValue.value_definition_group_id = valueDefinitionGroupId;
    return this;
  }

  valueDefinitionIds(valueDefinitionId: string[]): ReportHistoricalValueBuilder {
    this._reportHistoricalValue.value_definition_ids = valueDefinitionId;
    return this;
  }

  valueGroupSets(valueGroupSets: ValueGroupSet[]): ReportHistoricalValueBuilder {
    this._reportHistoricalValue.value_group_set = valueGroupSets;
    return this;
  }

  values(values: Value[]): ReportHistoricalValueBuilder {
    this._reportHistoricalValue.values = values;
    return this;
  }

  templateVersionId(templateVersionId?: string): ReportHistoricalValueBuilder {
    this._reportHistoricalValue.template_version_id = templateVersionId;
    return this;
  }

  mappedReport(mappedReport?: MinimalReport): ReportHistoricalValueBuilder {
    this._reportHistoricalValue.mapped_report = mappedReport;
    return this;
  }

  mappedMetric(mappedMetric?: MinimalMetric): ReportHistoricalValueBuilder {
    this._reportHistoricalValue.mapped_metric = mappedMetric;
    return this;
  }

  build(): ReportHistoricalValue {
    return this._reportHistoricalValue;
  }
}
