import { Component, OnInit } from '@angular/core';

import { ContextSettingsService } from './shared/services/context-settings/context-settings.service';

@Component({
  selector: 'addon-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private readonly contextSettingsService: ContextSettingsService) {}

  public ngOnInit(): void {
    this.contextSettingsService.loadSettings().subscribe();
  }
}
