<div class="panel-properties-content-header mh-2">
  <div class="panel-properties-content-header-title">
    <h4 class="mat-h4">{{ "Settings" | translate }}</h4>
  </div>
  <div class="panel-properties-content-header-actions"></div>
</div>

<div class="panel-properties-content-container fx-col">
  <div class="fields-container pb-1">
    <lib-metric-settings-details *ngIf="metric" [metric]="metric"></lib-metric-settings-details>
    <mat-divider class="mv-4"></mat-divider>

    <form class="form mt-3" [formGroup]="metricForm">
      <p *ngIf="canEditEveryMetrics && metric?.category === eMetricCategory.REFERENCE">
        {{ (this.metric?.reference_v2 ? "Metric version 2.0" : "Metric version 1.0") | translate }}
      </p>

      <div class="formfield medium required" *ngIf="codeFormControl.enabled">
        <lib-text-input
          [label]="'code' | translate"
          [prefix]="metric?.category === eMetricCategory.CUSTOM ? 'CUS' : metric?.framework?.code?.toUpperCase()"
          [hint]="'20 characters max' | translate"
          [control]="codeFormControl"
          [messages]="codeFieldValidationMsgs"
        ></lib-text-input>
      </div>

      <div class="formfield large required" *ngIf="descriptionFormControl.enabled">
        <lib-text-area-input
          [label]="'Name or description' | translate"
          [control]="descriptionFormControl"
          [messages]="descriptionFieldValidationMsgs"
        ></lib-text-area-input>
      </div>

      <div class="formfield large" *ngIf="guidanceFormControl.enabled">
        <lib-rich-text-input
          *ngIf="refMetricsV2EnabledFF && metric?.reference_v2; else defaultGuidance"
          [control]="guidanceFormControl"
          [hint]="
            'Enter your organization’s internal standards or specifications. This is visible to all users when viewing the metric, including request participants.'
              | translate
          "
          [label]="'Metric Technical Protocol' | translate"
          [enableSourceCodeOption]="canEditEveryMetrics"
        ></lib-rich-text-input>

        <ng-template #defaultGuidance>
          <lib-rich-text-input
            [control]="guidanceFormControl"
            [hint]="'Optional' | translate"
            [label]="'Guidance' | translate"
            [enableSourceCodeOption]="canEditEveryMetrics"
          ></lib-rich-text-input>
        </ng-template>
      </div>

      <lib-multi-select-chip-input
        *ngIf="topicsFormControl.enabled"
        class="large"
        [label]="'Topics' | translate"
        [hint]="'Start typing to see topics' | translate"
        [control]="topicsFormControl"
        [options]="topicOptions"
        bindLabel="title"
        compareWith="id"
      >
      </lib-multi-select-chip-input>

      <lib-multi-select-chip-input
        *ngIf="relatedMetricsFormControl.enabled"
        class="large readonly"
        [label]="'Related to' | translate"
        [hint]="'Start typing to see metrics' | translate"
        [control]="relatedMetricsFormControl"
        [options]="metricOptions"
        bindLabel="title"
        compareWith="id"
        (filterValueChanged)="updateMetricsOptions($event)"
        [forceControlValue]="true"
      >
      </lib-multi-select-chip-input>

      <lib-multi-select-chip-input
        *ngIf="standardCodesFormControl.enabled"
        class="large readonly"
        [label]="'Compatible with' | translate"
        [hint]="'Start typing to see standard codes' | translate"
        [control]="standardCodesFormControl"
        [options]="standardCodesOptions"
        (filterValueChanged)="searchStandardCodes($event)"
        bindLabel="title"
        compareWith="id"
        [forceControlValue]="true"
      >
      </lib-multi-select-chip-input>

      <lib-multi-select-chip-input
        class="large"
        [label]="'Tags' | translate"
        [hint]="'Start typing to see tags, hit enter to create a new tag' | translate"
        [control]="tagsFormControl"
        [options]="tagOptions"
        bindLabel="title"
        compareWith="id"
        [freeSolo]="true"
        (addValue)="addTag($event)"
      >
      </lib-multi-select-chip-input>
    </form>
  </div>
  <div>
    <mat-divider class="pb-4"></mat-divider>

    <div class="form-submit">
      <button
        lib-button
        type="success"
        [disabled]="metricForm.pristine || metricForm.invalid"
        [isLoading]="!!(updating$ | async)"
        (click)="saveProperties()"
        [label]="'Save' | translate"
        class="mr-4"
      ></button>
      <button lib-button type="link" (click)="closeSettings()">{{ "Cancel" | translate }}</button>
    </div>
  </div>
</div>
