<lib-panel-details *ngIf="templateReportId$ | async as templateReportId" cdkDropListGroup>
  <ng-container *libPermission="managePermissions" slot="collection">
    <lib-template-report-structure-left-panel
      class="h-100"
      [templateReportId]="templateReportId"
      (previewMetric)="previewMetric.emit($event)"
    ></lib-template-report-structure-left-panel>
  </ng-container>

  <div slot="item" class="template-report-panel" [ngClass]="{ 'item-selected': selectedItem || creatingSection }">
    <ng-container *ngIf="baseTemplateReport$ | async as templateReport">
      <div class="panel panel-main panel-expanded">
        <div class="panel-container main">
          <lib-template-report-structure-header
            [templateName]="templateReport | templateName"
            (headerClick)="selectTemplateReportSettings(templateReport)"
          ></lib-template-report-structure-header>

          <div class="panel-content sections-content">
            <ng-container *ngIf="templateReportSections$ | async as templateReportSections">
              <lib-template-report-structure-sections
                *ngIf="templateReportSections.length; else emptySections"
                [managePermissions]="managePermissions"
                [templateReport]="templateReport"
                [templateReportVersionId]="templateReportVersionId$ | async"
                [templateReportId]="templateReportId"
                [sections]="templateReportSections"
                (editSection)="editSection($event)"
                (addSubSection)="addSubSection($event)"
                (createMetric)="addSectionMetric($event)"
                (editMetric)="editSectionMetric($event)"
                (previewMetric)="previewMetric.emit($event)"
              >
              </lib-template-report-structure-sections>
            </ng-container>

            <ng-template #emptySections>
              <div class="card card-dashed h-100">
                <lib-empty-results class="h-100" [displayData]="noSection" (action)="addSection()"></lib-empty-results>
              </div>
            </ng-template>
          </div>

          <div class="panel-footer" *libPermission="managePermissions">
            <button lib-button type="primary" (click)="addSection()">
              <mat-icon class="icon-md mr-1" svgIcon="group"></mat-icon><span>{{ "Add a section" | translate }}</span>
            </button>
          </div>
        </div>
      </div>

      <lib-template-report-structure-panel
        *ngIf="templateReportId$ | async as templateReportId"
        class="panel-properties h-100"
        [managePermissions]="managePermissions"
        [selectedItem]="selectedItem"
        [isEditing]="isEditing"
        [templateReportId]="templateReportId"
        (closePanel)="closeProperties()"
        (updateVersion)="updateVersion($event, templateReportId)"
      >
      </lib-template-report-structure-panel>
    </ng-container>
  </div>
</lib-panel-details>
