import { AbstractControl, ValidationErrors } from '@angular/forms';

export class MetricStructurePropertiesValidators {
  static isNumber(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      return !isNaN(control.value) && parseInt(control.value) >= 0 && !String(control.value).includes('.')
        ? null
        : {
            notNumeric: true,
          };
    }
    return null;
  }

  static isNotOnlySpaces(control: AbstractControl): ValidationErrors | null {
    return control.value?.length && control.value.trim() === ''
      ? {
          required: true,
        }
      : null;
  }

  static isMaxBiggerThanMin(formGroup: AbstractControl): ValidationErrors | null {
    const minimumFormControl: AbstractControl | null = formGroup.get('minimum');
    const maximumFormControl: AbstractControl | null = formGroup.get('maximum');
    if (
      minimumFormControl?.value?.length &&
      maximumFormControl?.value?.length &&
      +minimumFormControl.value >= +maximumFormControl.value
    ) {
      minimumFormControl.setErrors({ minMax: true });
      maximumFormControl.setErrors({ maxMin: true });
      return { minMax: true };
    }
    minimumFormControl?.setErrors(null);
    maximumFormControl?.setErrors(null);
    return null;
  }

  static is0OrGreaterThan1(control: AbstractControl): ValidationErrors | null {
    if (control?.value && +control?.value === 1) {
      return { isNot0OrGreaterThan1: true };
    }
    return null;
  }
}
