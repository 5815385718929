<mat-radio-group
  *ngIf="control"
  [formControl]="control"
  color="primary"
  [ngClass]="{ 'fx-col': column }"
  data-testid="radio-group-test"
>
  <mat-radio-button
    *ngFor="let choice of choices"
    [value]="choice.value"
    class="radio-button"
    [ngClass]="{ 'pb-2': column, 'mr-3': !column }"
    (change)="onChoiceSelection.emit($event.value)"
  >
    {{ choice.label }}
  </mat-radio-button>
</mat-radio-group>
