import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { Status } from '../../models';

export interface ConfirmationDialogConfig {
  primaryBtn: string;
  primaryMsg: string;
  secondaryBtn?: string;
  secondaryMsg?: string;
  title: string;
}

@Component({
  selector: 'addon-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmationDialogConfig
  ) {}

  public cancel(): void {
    this.dialogRef.close({ status: Status.CANCEL });
  }

  public confirm(): void {
    this.dialogRef.close({ status: Status.CONFIRMED });
  }
}
