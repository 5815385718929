import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConditionalTrigger } from '../../../models';

export class ConditionalTriggerForm extends UntypedFormGroup {
  readonly triggerValue = this.get('triggerValue') as UntypedFormControl;
  readonly targets = this.get('targets') as UntypedFormControl;

  constructor(readonly model?: ConditionalTrigger, readonly fb: UntypedFormBuilder = new UntypedFormBuilder()) {
    super(
      fb.group({
        triggerValue: [model?.values ?? null, Validators.required],
        targets: [model?.targets ?? null, Validators.required],
      }).controls
    );
  }
}
