/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, EMPTY } from 'rxjs';
import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common/api/api.service';
import {
  ApiResponse,
  ApplicationApiDefinition,
  Indicator,
  MinimalIndicator,
  ValueGroupSet,
  GetIndicatorPayload,
} from '../../../models';
import { IndicatorsApiService } from '../../api-services';

@Injectable()
export class ClientPublicIndicatorsService extends IndicatorsApiService {
  apiName: keyof ApplicationApiDefinition = 'public';
  resource: string;
  servicePath: string;
  resourcePrefix: string = '';
  dataRequestId: string = '';
  dataRequestSourceId: string = '';

  private _isInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isInitialized$: Observable<boolean>;

  constructor(private baseService: BaseService, private apiService: ApiService) {
    super();

    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.public.resources.dataRequestCollab;
    this.isInitialized$ = this._isInitialized$.asObservable();
  }

  public initialize(dataRequestId: string, dataRequestSourceId: string): void {
    this.dataRequestId = dataRequestId;
    this.dataRequestSourceId = dataRequestSourceId;
    this.resourcePrefix = `${this.servicePath}${this.resource}/data_requests/${this.dataRequestId}/data_request_sources/${this.dataRequestSourceId}`;
    this._isInitialized$.next(true);
  }

  private initializationCheck(): void {
    if (!this.dataRequestId || !this.dataRequestSourceId || !this._isInitialized$.getValue()) {
      throw new Error('This service needs to be initialized before being used.');
    }
  }

  getIndicator(id: string, payload?: GetIndicatorPayload): Observable<ApiResponse<Indicator>> {
    this.initializationCheck();

    let params = new HttpParams();
    if (payload) {
      Object.entries(payload).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item: string) => {
            params = params.append(key, item);
          });
        } else if (value != undefined && value != null) {
          params = params.append(key, value as string);
        }
      });
    }
    return this.apiService.get(`${this.resourcePrefix}/indicators/${id}`, { params });
  }

  getIndicatorByMetricIds(
    metric_ids: string[],
    fiscal_year?: string,
    business_unit_id?: string
  ): Observable<ApiResponse<Indicator[]>> {
    this.initializationCheck();

    const payload = {
      filters: {
        metric_ids,
        active: null,
      },
      fiscal_year,
      business_unit_id,
      size: metric_ids.length,
    };
    return this.apiService.post(`${this.resourcePrefix}/indicators/search`, payload);
  }

  listMinimalIndicators(): Observable<ApiResponse<MinimalIndicator[]>> {
    throw 'not implemented';
  }

  listValueProgressSummary(): Observable<ApiResponse<ValueGroupSet[]>> {
    throw 'not implemented';
  }

  listValueProgressSummaryGroup(): Observable<ApiResponse<ValueGroupSet[]>> {
    throw 'not implemented';
  }

  public search(): Observable<ApiResponse<Indicator[]>> {
    return EMPTY;
  }
}
