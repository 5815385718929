<div class="card-container" data-testid="tableColumnDefinitionCard">
  <a class="card card-condensed mb-1">
    <div class="pr-2">
      <div class="item-title mat-body-2 color-primary-900 mb-1">{{ tableColumnDefinition.label }}</div>
      <div class="item-subtitle caption2 color-grey-800">{{ subtitleText }}</div>
      <div class="mt-1 color-grey-800">{{ unitCode | unitSymbol : true }}</div>
    </div>
  </a>
  <a class="context-menu" data-testid="tableColumnDefinitionCardMenu" (click)="handleClick($event)">
    <mat-icon class="icon-sm" svgIcon="more"></mat-icon>
  </a>
</div>
