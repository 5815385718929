import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ValidationErrors } from '@angular/forms';

import { filter, Observable } from 'rxjs';

import {
  ActionItem,
  ConfirmationDialogConfig,
  ConsolidationRules,
  ConsolidationTriggers,
  DialogResult,
  MetricCategory,
  MetricTableColumn,
  MetricTableColumnDefinitionUpsert,
  MetricTableDefinition,
  ResourceType,
  Status,
  Unit,
  ValueDefinitionType,
  DialogSize,
  UpdateMetricTableColumnDefinitionPayload,
} from '../../../../../models';
import { ColumnSelection } from '../../../../models';
import { ConfirmationDialogComponent, DialogsService } from '../../../../../dialogs';
import { ConsolidationService } from '../../../../../services/common/consolidation/consolidation.service';
import { MetricStructurePropertiesValidators } from '../../validators/metric-structure-properties-validators';
import { RadioButtonOption } from '../../../../../components/form/radio-buttons/radio';
import { SearchService } from '../../../../../search/services/search.service';
import { TableInputColumnDialogForm, TableInputColumnDialogFormResult } from './table-input-column-dialog-form';
import { TranslateService } from '../../../../../services/common/translate/translate.service';
import { TrimMethod } from '../../../../../directives/trim-whitespace/trim-whitespace.model';
import { MetricStructureStateService } from '../../../../services/metric-structure-state.service';
import { ObservableUtils } from '../../../../../classes';
import {
  ConsolidationManualDialogComponent,
  ConsolidationManualDialogResults,
} from '../../../consolidation-manual-dialog/consolidation-manual-dialog.component';

export interface TableInputColumnDialogConfig {
  columns: MetricTableColumnDefinitionUpsert[];
  metricCategory: string;
  metricTableDefinition: MetricTableDefinition;
  metricStructureService: MetricStructureStateService;
  column?: MetricTableColumn;
}

@Component({
  selector: 'lib-table-input-column-dialog',
  templateUrl: './table-input-column-dialog.component.html',
  styleUrls: ['./table-input-column-dialog.component.scss'],
})
export class TableInputColumnDialogComponent {
  public consolidationRuleOptions: ActionItem[] = [];
  public consolidationTriggerOptions: ActionItem[] = [];
  public staticConsolidationFlag: boolean = false;
  readonly eValueDefinitionType = ValueDefinitionType;
  readonly eTrimMethod: typeof TrimMethod = TrimMethod;
  readonly columnSelection = ColumnSelection;
  readonly numericDecimalOptions = Array(11)
    .fill(null)
    .map((_, i) => ({ id: `${i}`, title: `${i}` })) as ActionItem[];

  readonly numberMinMaxErrorMsgs: ValidationErrors = {
    minMax: this.translateService.instant('Min cannot be greater than max'),
    maxMin: this.translateService.instant('Max cannot be less than min'),
  };
  readonly columTypeChoices: RadioButtonOption[] = [
    {
      value: ColumnSelection.CONTEXT_COLUMN,
      label: this.translateService.instant('Context Column'),
    },
    {
      value: ColumnSelection.INPUT,
      label: this.translateService.instant('Input Column'),
    },
  ];

  readonly inputTypeChoices: RadioButtonOption[] = [
    {
      value: ValueDefinitionType.integer,
      label: this.translateService.instant('Integer'),
    },
    {
      value: ValueDefinitionType.decimal,
      label: this.translateService.instant('Decimal'),
    },
  ];
  isMetricUpdating$: Observable<boolean | undefined>;
  tableColumnDefinitionForm: TableInputColumnDialogForm;
  unitFamilies: ActionItem<string>[];
  allUnits: ActionItem<Unit>[] = [];
  unitDefaults: ActionItem<Unit>[] = [];
  isEditing: boolean = false;
  metricCategory: string;
  typeChoices: ActionItem[] = [ValueDefinitionType.label, ValueDefinitionType.integer].map((type) => ({
    id: type,
    title: type,
  }));

  constructor(
    private readonly dialogsService: DialogsService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<TableInputColumnDialogComponent>,
    private searchService: SearchService,
    private consolidationService: ConsolidationService,
    @Inject(MAT_DIALOG_DATA) private data: TableInputColumnDialogConfig
  ) {
    this.staticConsolidationFlag = this.consolidationService.staticConsolidationFlag;
    this.consolidationRuleOptions = this.consolidationService.consolidationRuleOptions;
    this.isEditing = data.column != null;
    this.metricCategory = data.metricCategory;
    this.tableColumnDefinitionForm = new TableInputColumnDialogForm(data.columns, data.column);
    this.tableColumnDefinitionForm.setValidators(MetricStructurePropertiesValidators.isMaxBiggerThanMin);

    this.unitFamilies = this._getUnitsFamily();
    this._getUnitsData();

    if (this.tableColumnDefinitionForm.controls.family.value) {
      this.onUnitFamilyChange(String(this.tableColumnDefinitionForm.controls.family.value));
    }
    this.setConsolidationTriggerOptions(this.tableColumnDefinitionForm.controls.consolidationRule.value);
    this.isMetricUpdating$ = this.data.metricStructureService.isMetricUpdating$;
  }

  get dialogTitle(): string {
    return this.translateService.instant(this.isEditing ? 'Manage input column' : 'Add input column');
  }

  consolidationParametersChanged(result: TableInputColumnDialogFormResult): boolean {
    return (
      result.consolidation_rule !== this.data.column?.consolidation_rule ||
      result.consolidation_trigger !== this.data.column?.consolidation_trigger
    );
  }

  consolidationRuleChanged(result: TableInputColumnDialogFormResult): boolean {
    return result.consolidation_rule !== this.data.column?.consolidation_rule;
  }

  save(): void {
    const result = this.tableColumnDefinitionForm.toModel();

    if (
      this.data.column?.id &&
      this.consolidationRuleChanged(result) &&
      this.data.metricTableDefinition.has_values &&
      result.consolidation_rule === ConsolidationRules.manual
    ) {
      return this.updateMetricColumnManualTableDialog(this.data.column.id, result);
    }

    if (
      this.data.column?.id &&
      this.consolidationParametersChanged(result) &&
      this.data.metricTableDefinition.has_values
    ) {
      return this.updateMetricColumnTableDialog(this.data.column.id, result);
    }

    if (this.data.column?.id) {
      return this.updateMetricColumn(this.data.column.id, result);
    }

    return this.data.metricStructureService.createMetricTableColumnDefinition(
      this.data.metricTableDefinition,
      result,
      this.closeSuccess
    );
  }

  private updateMetricColumnManualTableDialog(columnId: string, result: TableInputColumnDialogFormResult): void {
    this.dialogsService
      .open<ConsolidationManualDialogComponent>(ConsolidationManualDialogComponent, {
        data: {
          size: DialogSize.small,
        },
      })
      .afterClosed()
      .pipe(
        ObservableUtils.filterNullish(),
        filter((dialogResult: DialogResult<ConsolidationManualDialogResults>) => dialogResult.status === Status.SUCCESS)
      )
      .subscribe((dialogResult: DialogResult<ConsolidationManualDialogResults>) => {
        this.updateMetricColumn(columnId, result, dialogResult.data?.reset_consolidated_values || false);
      });
  }

  updateMetricColumnTableDialog(columnId: string, result: TableInputColumnDialogFormResult): void {
    const dialogRef = this.dialogsService.open<ConfirmationDialogComponent, ConfirmationDialogConfig>(
      ConfirmationDialogComponent,
      {
        data: {
          warningMsg: this.translateService.instant(
            'Changes to the configuration rules may result in completed metrics being set back to in progress should their values be modified.'
          ),
          primaryBtn: this.translateService.instant('OK'),
          status: Status.SUCCESS,
        },
      }
    );
    dialogRef
      .afterClosed()
      .pipe(
        ObservableUtils.filterNullish(),
        filter((dialogResult: DialogResult) => dialogResult.status === Status.CONFIRMED)
      )
      .subscribe(() => {
        this.updateMetricColumn(columnId, result);
      });
  }

  updateMetricColumn(
    columnId: string,
    result: TableInputColumnDialogFormResult,
    reset_consolidated_values: boolean = false
  ): void {
    const payload: UpdateMetricTableColumnDefinitionPayload = { ...result, reset_consolidated_values };
    payload.consolidation_trigger =
      result.consolidation_rule === ConsolidationRules.manual ? null : result.consolidation_trigger;
    this.data.metricStructureService.updateMetricTableColumnDefinition(
      this.data.metricTableDefinition,
      columnId,
      payload,
      this.closeSuccess
    );
  }

  closeDialog(): void {
    if (!this.tableColumnDefinitionForm.dirty) {
      this.dialogRef.close({ status: Status.CANCEL });
      return;
    }

    const dialogRef = this.dialogsService.open<ConfirmationDialogComponent, ConfirmationDialogConfig>(
      ConfirmationDialogComponent,
      {
        data: {
          primaryBtn: this.translateService.instant('Continue'),
          title: this.translateService.instant('Cancel action'),
          warningMsg: this.translateService.instant(
            'Are you sure you wish to cancel this action? You will lose your changes.'
          ),
        },
      }
    );

    dialogRef
      .afterClosed()
      .pipe(filter((result?: DialogResult) => result?.status === Status.CONFIRMED))
      .subscribe(() => {
        this.dialogRef.close({ status: Status.CANCEL });
      });
  }

  public onUnitFamilyChange(family?: string | undefined): void {
    const unitFamily = family || this.tableColumnDefinitionForm.controls.family.value;
    this.unitDefaults = this.allUnits
      .filter((units: ActionItem<Unit>) => units.item?.family === unitFamily)
      .map((unit: ActionItem<Unit>) => {
        (unit.id = unit.item?.code ?? ''), (unit.title = unit.item?.label ?? '');
        return unit;
      });
  }

  public getConsolidationTriggerOptions(): ActionItem[] {
    return this.consolidationService.consolidationTriggerOptions;
  }

  public setConsolidationTriggerOptions(consolidationRule: ConsolidationRules): void {
    this.consolidationService.setConsolidationTriggerOptions(consolidationRule);
    this.consolidationTriggerOptions = this.getConsolidationTriggerOptions();
    this.setConsolidationTrigger(this.consolidationService.consolidationTriggerOptions.length > 0);
  }

  public isConsolidationValueDefinitionType(): boolean {
    return this.consolidationService.consolidationValueDefinitionTypes.includes(
      this.tableColumnDefinitionForm.get('inputTypeChoice')?.value as ValueDefinitionType
    );
  }

  public isThirdParty(): boolean {
    return this.metricCategory === MetricCategory.THIRD_PARTY;
  }

  private _getUnitsFamily(): ActionItem<string>[] {
    this.searchService.searchResources(ResourceType.unit, 'families').subscribe((units) => {
      this.unitFamilies = units
        .filter((family: ActionItem<Unit>) => family.item != null)
        .map((family: ActionItem<string>) => {
          family.title = family.item ?? '';
          family.id = family.item ?? '';
          return family;
        });
    });
    return this.unitFamilies;
  }

  private _getUnitsData() {
    this.searchService.searchResources<Unit>(ResourceType.unit).subscribe((res) => {
      this.allUnits = res;
    });
  }

  private setConsolidationTrigger(triggerValue: boolean): void {
    if (triggerValue) {
      this.tableColumnDefinitionForm.get('consolidationTrigger')?.enable();
      this.tableColumnDefinitionForm.get('consolidationTrigger')?.value ||
        this.tableColumnDefinitionForm
          .get('consolidationTrigger')
          ?.setValue(ConsolidationTriggers.update_when_one_value);
    } else {
      this.tableColumnDefinitionForm.get('consolidationTrigger')?.setValue(null);
      this.tableColumnDefinitionForm.get('consolidationTrigger')?.disable();
    }
  }

  private closeSuccess = () => {
    this.dialogRef.close({ status: Status.SUCCESS });
  };
}
