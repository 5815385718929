import { MetricTableCalculationTypeDetailFormulaFilter } from '../models';

export class MetricTableCalculationTypeDetailFormulaFilterBuilder {
  private readonly _metricTableCalculationTypeDetailFormulaFilter: MetricTableCalculationTypeDetailFormulaFilter;

  constructor() {
    this._metricTableCalculationTypeDetailFormulaFilter = {
      column: 'Filter Column',
      values: ['filter 1', 'filter 2'],
    };
  }

  column(column: string): MetricTableCalculationTypeDetailFormulaFilterBuilder {
    this._metricTableCalculationTypeDetailFormulaFilter.column = column;
    return this;
  }

  values(values: string[]): MetricTableCalculationTypeDetailFormulaFilterBuilder {
    this._metricTableCalculationTypeDetailFormulaFilter.values = values;
    return this;
  }

  build(): MetricTableCalculationTypeDetailFormulaFilter {
    return this._metricTableCalculationTypeDetailFormulaFilter;
  }
}
