import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';

import { Builder } from './builder';
import { MetricTableColumnOption } from '../models';

export class MetricTableColumnOptionBuilder extends Builder<MetricTableColumnOption> {
  constructor() {
    super({
      id: uuidv4(),
      column_id: uuidv4(),
      label: faker.commerce.productName(),
      position: 1,
    });
  }

  id(id: string): MetricTableColumnOptionBuilder {
    this._instance.id = id;
    return this;
  }

  columnId(columnId: string): MetricTableColumnOptionBuilder {
    this._instance.column_id = columnId;
    return this;
  }

  label(label: string): MetricTableColumnOptionBuilder {
    this._instance.label = label;
    return this;
  }

  position(position: number): MetricTableColumnOptionBuilder {
    this._instance.position = position;
    return this;
  }
}
