<div *ngIf="control" class="formfield" [ngClass]="{ required: required, disabled: control.disabled }">
  <lib-form-field-label [label]="label" [control]="control" [for]="_inputId"></lib-form-field-label>
  <mat-form-field appearance="standard">
    <textarea
      cdkTextareaAutosize
      matInput
      #input
      [id]="_inputId"
      [placeholder]="placeholder"
      [formControl]="inputControl"
      [matAutocomplete]="autocomplete"
      (blur)="onBlur($event)"
    ></textarea>
    <mat-autocomplete
      #autocomplete="matAutocomplete"
      (optionSelected)="selectOption($event.option)"
      aria-label="autocomplete"
      [autoActiveFirstOption]="true"
    >
      <mat-option *ngIf="isLoadingOptions" class="loading-options">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </mat-option>

      <ng-container *ngIf="!isLoadingOptions">
        <mat-option *ngFor="let item of filteredOptions$ | async" [value]="getOptionValue(item)">
          {{ getOptionLabel(item) }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>

    <mat-hint>
      <ng-container *ngIf="(control.untouched || control.valid) && hint">
        {{ hint }}
      </ng-container>
      <ng-container *ngIf="control.touched && control.invalid">
        <span class="color-error" *ngFor="let error of control.errors | errorKeys"> {{ errorMessages[error] }}</span>
      </ng-container>
    </mat-hint>
  </mat-form-field>
</div>
