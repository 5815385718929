import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ConditionalTrigger,
  ConditionalTriggerTarget,
  ValueDefinition,
  ValueDefinitionGroup,
  ValueDefinitionType,
} from '../../../../models';
import { Required } from '../../../../decorators';
import { HtmlToStringService, TranslateService } from '../../../../services/common';

@Component({
  selector: 'lib-conditional-trigger-card',
  templateUrl: './conditional-trigger-card.component.html',
  styleUrls: ['./conditional-trigger-card.component.scss'],
})
export class ConditionalTriggerCardComponent {
  @Input() @Required() conditionalTrigger!: ConditionalTrigger;
  @Input() @Required() valueDefinitionGroups!: ValueDefinitionGroup[];
  @Input() disabled: boolean = false;
  @Output() menuClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  get conditionalTriggerValuesText(): string {
    const sourceValueDefinition = this.valueDefinitionGroups
      .reduce((vds: ValueDefinition[], vdg) => [...vds, ...(vdg?.value_definitions ?? [])], [])
      .find((vd) => vd.id === this.conditionalTrigger.source_value_definition_id);
    if (sourceValueDefinition && sourceValueDefinition.type === ValueDefinitionType.boolean) {
      const booleanTriggerValues = [];
      for (const value of this.conditionalTrigger.values) {
        booleanTriggerValues.push(
          value ? sourceValueDefinition.type_details.label_true : sourceValueDefinition.type_details.label_false
        );
      }
      return booleanTriggerValues.join(', ');
    }
    return this.conditionalTrigger.values.join(', ');
  }

  get conditionalTriggerTargetLabels(): string {
    return this.conditionalTrigger.targets.map((target) => this.getTargetLabel(target)).join(', ');
  }

  constructor(private translateService: TranslateService, private htmlToStringService: HtmlToStringService) {}

  private getTargetLabel(target: ConditionalTriggerTarget): string {
    if (target.value_definition_group_id) {
      const group = this.valueDefinitionGroups.find((vdg) => vdg.id === target.value_definition_group_id);
      return group
        ? group.label ?? this.translateService.instant('Group {position}', { position: group.position })
        : this.translateService.instant('Unknown');
    } else if (target.value_definition_id) {
      const targetValueDefinition = this.valueDefinitionGroups
        .reduce((vds: ValueDefinition[], vdg) => [...vds, ...(vdg?.value_definitions ?? [])], [])
        .find((vd) => vd.id === target.value_definition_id);

      if (targetValueDefinition) {
        if (targetValueDefinition.type === ValueDefinitionType.subtitle) {
          return this.translateService.instant('Subtitle: {subtitleLabel}', {
            subtitleLabel: targetValueDefinition.type_details.value,
          });
        } else if (targetValueDefinition.type === ValueDefinitionType.tip) {
          const tipInText = this.htmlToStringService.convertTipRichTextToPlain(
            String(targetValueDefinition.type_details.value),
            25
          );
          return this.translateService.instant('Instructions: {instructionLabel}', { instructionLabel: tipInText });
        } else if (targetValueDefinition.type === ValueDefinitionType.document) {
          return this.translateService.instant('Document Link: {docLabel}', {
            docLabel: targetValueDefinition.document?.name ?? this.translateService.instant('Unknown'),
          });
        } else {
          return targetValueDefinition?.label ?? this.translateService.instant('Unknown');
        }
      }
    }
    return this.translateService.instant('Unknown');
  }
}
