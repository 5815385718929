import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { EmptyResults, Metric, MetricCategory, ValueDefinition, ValueDefinitionGroup } from '../../../models';
import { delay } from 'rxjs/operators';
import { Required } from '../../../decorators';
import { MetricStructureSelectable, MetricTableGroup, ValueDefinitionGroupOrMetricTableGroup } from '../../models';
import { MetricStructureStateService } from '../../services/metric-structure-state.service';
import { Observable } from 'rxjs';
import { TranslateService } from '../../../services/common';
import { MetricTableUtils } from '../../../classes';
import { findLastIndex } from 'lodash';

@Component({
  selector: 'lib-metric-structure-form-panel',
  templateUrl: './metric-structure-form-panel.component.html',
  styleUrls: ['./metric-structure-form-panel.component.scss'],
})
export class MetricStructureFormPanelComponent implements OnInit, OnChanges {
  @Input() @Required() metric!: Metric;

  selectedItem?: MetricStructureSelectable;
  loaded: boolean = false;
  updating$: Observable<boolean> = this.metricStructureService.isMetricUpdating$.pipe(delay(0));
  isCreatingField$: Observable<boolean> = this.metricStructureService.isCreatingField$;
  canEditEveryMetrics: boolean = false;

  groupsOrTables: ValueDefinitionGroupOrMetricTableGroup[] = [];

  readonly eMetricCategory: typeof MetricCategory = MetricCategory;
  readonly emptyResults: EmptyResults = {
    title: '',
    subtitle: this.translateService.instant('Start by adding a Group or Table from the left panel'),
    image: 'laptop-sad',
  };

  canMoveUpLimit: number = 0;

  get hasNoGroupWithFields(): boolean {
    return this.groupCount === 0;
  }

  get groupCount(): number {
    return !this.metric.value_definition_groups ? 0 : this.metric.value_definition_groups.length;
  }

  get fieldCount(): number {
    return (
      this.metric.value_definition_groups?.reduce((count, vdg) => count + (vdg.value_definitions?.length ?? 0), 0) ?? 0
    );
  }

  constructor(
    private translateService: TranslateService,
    private metricStructureService: MetricStructureStateService
  ) {}

  ngOnInit(): void {
    this.metricStructureService.selectedItem$.subscribe((res) => {
      this.selectedItem = res;
    });

    this.groupsOrTables = this.mergeGroup(this.metric.value_definition_groups ?? []);

    this.loaded = true;

    this.metricStructureService.canEditEveryMetrics$.subscribe((res) => {
      this.canEditEveryMetrics = res;
      this.setLimitOfCanMoveUp(res);
    });
  }

  ngOnChanges(): void {
    this.groupsOrTables = this.mergeGroup(this.metric.value_definition_groups ?? []);
  }

  private mergeGroup(valueDefinitionGroups: ValueDefinitionGroup[]): ValueDefinitionGroupOrMetricTableGroup[] {
    const groupingByTableId: Record<string, ValueDefinitionGroup[]> = valueDefinitionGroups
      .filter((vdg) => vdg.table_id != null)
      .reduce(
        (grouping: Record<string, ValueDefinitionGroup[]>, vdg) => ({
          ...grouping,
          [vdg.table_id!]: [...(grouping[vdg.table_id!] ?? []), vdg],
        }),
        {}
      );

    const metricTableGroups: MetricTableGroup[] = Object.keys(groupingByTableId)
      .filter((key) => key != '' && groupingByTableId[key].length)
      .map((key) => MetricTableUtils.mergeTableVDGIntoMetricTableGroup(groupingByTableId[key]) as MetricTableGroup);

    const groupNotInTable = valueDefinitionGroups.filter((vdg) => vdg.table_id == null);

    return [...groupNotInTable, ...metricTableGroups].sort((a, b) => a.position - b.position);
  }

  public onSelectedItemChange(item?: Metric | ValueDefinitionGroup | ValueDefinition): void {
    this.metricStructureService.updateSelectedItem(item);
  }

  trackId(index: number, item: { id?: string }): string | undefined {
    return item.id;
  }

  private setLimitOfCanMoveUp(canEditEveryMetric: boolean): void {
    if (canEditEveryMetric || this.metric.category !== MetricCategory.REFERENCE || !this.metric.reference_v2) {
      return;
    }

    const indexOfLastCoreGroupOrTable = findLastIndex(
      this.groupsOrTables,
      (groupOrTable: ValueDefinitionGroupOrMetricTableGroup) =>
        (groupOrTable.table_id === null
          ? (groupOrTable as ValueDefinitionGroup)
          : (groupOrTable as MetricTableGroup).valueDefinitionGroups[0]
        ).core_value_definition_group_id !== null
    );

    this.canMoveUpLimit = indexOfLastCoreGroupOrTable !== -1 ? indexOfLastCoreGroupOrTable + 1 : 0;
  }
}
