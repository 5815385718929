import { Doc } from '../models';

export class DocBuilder {
  private readonly _doc: Doc;

  constructor() {
    this._doc = {
      id: 'documentId',
      name: 'doc',
      scope: 'report',
      format: 'xlsx',
      category: '',
      type: 'doc',
      public: false,
      storage_filename: 'fileName.xlsx',
      start: '',
      end: '',
    };
  }

  id(id: string): DocBuilder {
    this._doc.id = id;
    return this;
  }

  name(name: string): DocBuilder {
    this._doc.name = name;
    return this;
  }

  storageFilename(storageFilename: string): DocBuilder {
    this._doc.storage_filename = storageFilename;
    return this;
  }

  format(format: string): DocBuilder {
    this._doc.format = format;
    return this;
  }

  public(publicDoc: boolean): DocBuilder {
    this._doc.public = publicDoc;
    return this;
  }

  url(url: string): DocBuilder {
    this._doc.url = url;
    return this;
  }

  build(): Doc {
    return this._doc;
  }
}
