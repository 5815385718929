import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common/api/api.service';
import {
  ApiResponse,
  ApplicationApiDefinition,
  Benchmark,
  CreateBenchmarkRequest,
  SearchOptions,
} from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class ClientBenchmarksService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private baseService: BaseService, private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.benchmarks;
  }

  public search(searchOptions: SearchOptions): Observable<ApiResponse<Benchmark[]>> {
    let params = new HttpParams();
    if (searchOptions.filters.tag) {
      params = params.append('tags', searchOptions.filters.tag.id);
    }
    return this.apiService.get(`${this.servicePath}${this.resource}/benchmarks`, { params }).pipe(
      map((result: ApiResponse<Benchmark[]>) => {
        if (searchOptions.query.keywords) {
          result.data = result.data.filter((item) =>
            item.name.toLowerCase().includes(searchOptions.query.keywords.toLowerCase())
          );
          result.meta.count = result.data.length;
        }
        if (searchOptions.sort) {
          if (searchOptions.sort.id === 'name') {
            result.data = result.data.sort((a, b) => a.name.localeCompare(b.name));
          }
        }
        if (!searchOptions.sort) {
          result.data = result.data.sort((a, b) => {
            if (!a.updated || !b.updated) {
              return -1;
            }
            return a.updated > b.updated ? -1 : a.updated < b.updated ? 1 : 0;
          });
        }
        if (searchOptions.size) {
          result.data = result.data.splice(0, searchOptions.size);
        }
        return result;
      })
    );
  }

  public listBenchmarks(): Observable<ApiResponse<Benchmark[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/benchmarks`);
  }

  public getBenchmark(benchmark_id: string): Observable<ApiResponse<Benchmark>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/benchmarks/${benchmark_id}`);
  }

  public createBenchmark({
    name,
    metricCategory,
    reportId,
  }: CreateBenchmarkRequest): Observable<ApiResponse<Benchmark>> {
    return this.apiService.post<ApiResponse<Benchmark>>(`${this.servicePath}${this.resource}/benchmarks`, {
      name,
      metric_category: metricCategory,
      report_id: reportId,
    });
  }

  public updateBenchmark(benchmark_id: string, name: string, tags?: string[]): Observable<ApiResponse<Benchmark>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/benchmarks/${benchmark_id}`, {
      name,
      tags,
    });
  }

  public deleteBenchmark(benchmark_id: string): Observable<ApiResponse<Benchmark>> {
    return this.apiService.delete(`${this.servicePath}${this.resource}/benchmarks/${benchmark_id}`);
  }

  // Benchmark metrics

  public addMetric(benchmark_id: string, metric_id: string): Observable<ApiResponse<Benchmark>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/benchmarks/${benchmark_id}/metrics/${metric_id}`,
      {}
    );
  }

  public deleteMetric(benchmark_id: string, metric_id: string): Observable<ApiResponse<Benchmark>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/benchmarks/${benchmark_id}/metrics/${metric_id}`
    );
  }

  public moveMetric(benchmark_id: string, metric_id: string, position: number): Observable<ApiResponse<Benchmark>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/benchmarks/${benchmark_id}/metrics/${metric_id}/move/${position}`,
      {}
    );
  }

  // Benchmark orgs

  public addOrg(benchmark_id: string, year: number, org_id?: string): Observable<ApiResponse<Benchmark>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/benchmarks/${benchmark_id}/benchmark_intervals`, {
      fiscal_year: year,
      org_id,
    });
  }

  public deleteOrg(benchmark_id: string, benchmark_interval_id: string): Observable<ApiResponse<Benchmark>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/benchmarks/${benchmark_id}/benchmark_intervals/${benchmark_interval_id}`
    );
  }

  public moveOrg(
    benchmark_id: string,
    benchmark_interval_id: string,
    position: number
  ): Observable<ApiResponse<Benchmark>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/benchmarks/${benchmark_id}/benchmark_intervals/${benchmark_interval_id}/move/${position}`,
      {}
    );
  }
}
