import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  NovistoCommonModule,
  CommonTranslateModule,
  FormatNumberBrowserLocalePipe,
  UnitSymbolPipe,
  UnitsService,
} from '@novisto/common';

import { AppComponent } from './app.component';
import { AppConfig } from './shared/models';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './shared/interceptors/auth-interceptor/auth.interceptor';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { PublicCoreApiService } from './shared/services/public-core-api-service/public-core-api.service';
import { UnauthorizedErrorInterceptor } from './shared/interceptors/unauthorized-error-interceptor/unauthorized-error.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonTranslateModule,
    NovistoCommonModule.forRoot(environment as unknown as AppConfig),
    SharedModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedErrorInterceptor,
      multi: true,
    },
    { provide: UnitsService, deps: [PublicCoreApiService] },
    FormatNumberBrowserLocalePipe,
    UnitSymbolPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
