<mat-menu #menu="matMenu">
  <ng-container *ngIf="startedRequests.length">
    <a class="menu-title" mat-menu-item disabled>{{ "In progress" | translate }}</a>
    <button *ngFor="let request of startedRequests" mat-menu-item (click)="navigateToRequest(request.id)">
      <span>{{ request.name }}</span>
    </button>
  </ng-container>

  <mat-divider *ngIf="startedRequests.length && notStartedRequests.length"></mat-divider>

  <ng-container *ngIf="notStartedRequests.length">
    <a class="menu-title" mat-menu-item disabled>{{ "Not started" | translate }}</a>
    <button *ngFor="let request of notStartedRequests" mat-menu-item (click)="navigateToRequest(request.id)">
      <span>{{ request.name }}</span>
    </button>
  </ng-container>
</mat-menu>

<div *ngIf="(metricStructureService.canEditEveryMetrics$ | async) === false" class="mt-2">
  <div *ngIf="startedRequests.length || notStartedRequests.length" class="fx-row align-center">
    <mat-icon svgIcon="info" class="color-grey-800 mr-2"></mat-icon>
    <span>{{ "This metric is in an open request." | translate }}</span>
    <button lib-button type="link" [matMenuTriggerFor]="menu">
      <span>{{ "See requests" | translate }}</span>
      <mat-icon svgIcon="arrow-head-down" class="ml-1"></mat-icon>
    </button>
  </div>
</div>
