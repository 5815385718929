import { Component, Input, OnChanges } from '@angular/core';

import {
  ChartAlignValue,
  ChartColor,
  ChartConfig,
  ChartFontSizeValue,
  ChartFontWeightValue,
  ChartLineHeightValue,
  COMPANY_COLOURS,
} from '../models';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';

HC_exporting(Highcharts);
HC_exportData(Highcharts);

@Component({
  selector: 'lib-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnChanges {
  @Input() chartOptions: ChartConfig = {};

  public Highcharts: typeof Highcharts = Highcharts;

  public readonly commonStyle = { fontSize: '12px' };
  public readonly commonProperties = {
    credits: { enabled: false },
    legend: { itemStyle: this.commonStyle },
    subtitle: {
      useHTML: true,
      align: ChartAlignValue.left,
      style: {
        color: ChartColor.subtitle,
        fontSize: ChartFontSizeValue.common,
        fontWeight: ChartFontWeightValue.common,
        lineHeight: ChartLineHeightValue.common,
        transform: 'translate(0, -1.3rem)',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden !important',
      },
    },
    title: {
      align: ChartAlignValue.left,
      style: {
        color: ChartColor.subtitle,
        fontSize: ChartFontSizeValue.small,
        fontWeight: ChartFontWeightValue.common,
        lineHeight: ChartLineHeightValue.common,
        transform: 'translate(0, -0.8rem)',
      },
    },
    xAxis: { labels: { style: this.commonStyle } },
    yAxis: {
      labels: { style: this.commonStyle },
      title: { style: this.commonStyle },
    },
    colors: COMPANY_COLOURS,
  };

  public ngOnChanges(): void {
    Highcharts.setOptions({
      chart: {
        style: {
          fontFamily: 'Poppins',
        },
        backgroundColor: 'transparent',
      },
      lang: {
        numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E'],
        thousandsSep: ',',
      },
    });

    this.chartOptions = {
      ...this.chartOptions,
      plotOptions: {
        ...this.chartOptions.plotOptions,
        series: {
          ...this.chartOptions.plotOptions?.series,
          events: {
            legendItemClick: (event: Highcharts.SeriesLegendItemClickEventObject): boolean => {
              // Function to override the legendItemClick event
              // Makes it possible to toggle many series at the same time with 1 legend item if they have the same id
              // Does not impact series that don't have an id (they will work as intended)

              const targetSerieId = event.target.options.id;
              const series = event.target.chart.series;
              const visible = event.target.visible;

              if (targetSerieId) {
                for (const serie of series) {
                  if (serie.options.id === targetSerieId) {
                    if (visible) {
                      serie.hide();
                    } else {
                      serie.show();
                    }
                  }
                }
                return false;
              }
              return true;
            },
          },
        },
      },
    };
  }
}
