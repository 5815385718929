import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { map } from 'rxjs';

import { BaseTemplateReport, Permission, TemplateReportVersionPayload } from '../../../models';
import { TemplateReportPanelSettingsForm } from './template-report-panel-settings-form';
import { TemplateReportsApiService } from '../../../services/api-services';

@Component({
  selector: 'lib-template-report-panel-settings',
  templateUrl: './template-report-panel-settings.component.html',
  styleUrls: ['./template-report-panel-settings.component.scss'],
})
export class TemplateReportPanelSettingsComponent implements OnInit {
  @Input() managePermissions: Permission[] = [];
  @Input() templateReport?: BaseTemplateReport;
  @Input() templateReportId?: string;

  @Output() cancelAction: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateVersion: EventEmitter<TemplateReportVersionPayload> =
    new EventEmitter<TemplateReportVersionPayload>();

  public form?: TemplateReportPanelSettingsForm;

  constructor(private readonly templateReportsApiService: TemplateReportsApiService) {}

  public ngOnInit(): void {
    if (this.templateReport && this.templateReportId) {
      this.templateReportsApiService
        .listTemplateReportVersions(this.templateReportId, { order_by: 'version_number', order_by_direction: 'asc' })
        .pipe(map((response) => response.data))
        .subscribe((versions) => {
          const currentIndex = versions.findIndex((version) => version.id === this.templateReport?.id);
          if (this.templateReportId) {
            this.form = new TemplateReportPanelSettingsForm(
              this.templateReportsApiService,
              this.templateReportId,
              versions[currentIndex],
              versions[currentIndex - 1],
              versions[currentIndex + 1]
            );
          }
        });
    }
  }

  public onSubmit(): void {
    if (this.form) {
      this.updateVersion.emit(this.form.toModel());
    }
  }
}
