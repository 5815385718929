import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { DialogResult, Status } from '../../../models';

export interface MetricEditorErrorDialogConfig {
  valueLabel: string;
}

@Component({
  selector: 'lib-metric-editor-error-dialog',
  styleUrls: ['./metric-editor-error-dialog.component.scss'],
  templateUrl: './metric-editor-error-dialog.component.html',
})
export class MetricEditorErrorDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MetricEditorErrorDialogConfig,
    private dialogRef: MatDialogRef<MetricEditorErrorDialogConfig, DialogResult>
  ) {}

  public closeDialog(status = Status.CANCEL): void {
    this.dialogRef.close({ status });
  }

  public refresh(): void {
    window.location.reload();
  }
}
