<div *ngIf="isHtmlText; else rawText" class="expandable-text-container">
  <div
    #textContent
    [ngClass]="{ 'text-break': !readMore }"
    [ngStyle]="{ height: !readMore ? lineMaxHeight : '100%', 'line-height': lineHeight + 'px' }"
    class="color-grey-900 text"
  >
    <div [innerHTML]="text | sanitizeHtml"></div>
  </div>
  <a class="read-more-link" *ngIf="showExpandable || readMore" (click)="readMore = !readMore; $event.stopPropagation()">
    {{ (!readMore ? "Show more" : "Show less") | translate }}
  </a>
</div>

<ng-template #rawText>
  <div class="break-word">
    <ng-container *ngIf="text.length > charactersToShow; else originalText">
      <div #expandableText>
        {{ expandableText.classList.contains("expanded") ? text : text.slice(0, charactersToShow) + "..." }}

        <button lib-button (click)="expandableText.classList.toggle('expanded')" type="link" class="nowrap">
          {{ (expandableText.classList.contains("expanded") ? "Show less" : "Show more") | translate }}
        </button>
      </div>
    </ng-container>

    <ng-template #originalText>{{ text }}</ng-template>
  </div>
</ng-template>
