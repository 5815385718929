import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainNavBarComponent } from './main-nav-bar/main-nav-bar.component';
import { MaterialModule } from '../material/material.module';
import { BrandNavComponent } from './brand-nav/brand-nav.component';
import { RouterModule } from '@angular/router';
import { NavigationMenuComponent } from './navigation-menu/navigation-menu.component';
import { ProfileNavigationMenuComponent } from './profile-navigation-menu/profile-navigation-menu.component';
import { PipesModule } from '../pipes';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureFlagModule } from '../feature-flag';
import { DirectivesModule } from '../directives';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    PipesModule,
    TranslateModule,
    FeatureFlagModule,
    DirectivesModule,
  ],
  declarations: [MainNavBarComponent, BrandNavComponent, NavigationMenuComponent, ProfileNavigationMenuComponent],
  exports: [MainNavBarComponent, BrandNavComponent, NavigationMenuComponent, ProfileNavigationMenuComponent],
})
export class NavigationModule {}
