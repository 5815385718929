<button
  lib-button
  [ngClass]="{ 'active filter-button-color': !buttonProps?.disabled }"
  [disabled]="!!buttonProps?.disabled"
  data-testid="filter-button-container"
>
  <span>
    <mat-icon
      data-testid="filter-button-icon"
      class="icon-sm mr-1"
      [svgIcon]="buttonProps?.icon ?? 'filter'"
    ></mat-icon>
  </span>
  <div class="mat-body-2">{{ this.buttonProps?.title ?? this.optionProps?.activeOption?.title ?? "" }}</div>
</button>
