import { Injectable } from '@angular/core';
import { finalize, Observable, take, tap } from 'rxjs';
import { ApiResponse, Metric, ValueDefinition, ValueDefinitionGroup } from '../../../models';
import { AlertsService, TranslateService } from '../../../services/common';
import { MetricApiService } from '../../../services/types';
import { MetricStructureStateService } from '../metric-structure-state.service';
import { MetricTableGroup } from '../../models';

@Injectable()
export class ActivateEntityService {
  constructor(
    private metricsService: MetricApiService,
    private metricStructureService: MetricStructureStateService,
    private alertsService: AlertsService,
    private translateService: TranslateService
  ) {}

  activateValueDefinition(metricId: string, valueDefinition: ValueDefinition): Observable<ApiResponse<Metric>> {
    return this.metricsService
      .activateValueDefinition(metricId, valueDefinition.value_definition_group_id, valueDefinition.id)
      .pipe(
        tap((response) => {
          this.metricStructureService.updateMetric(response.data);
          this.metricStructureService.updateSelectedItem({
            ...valueDefinition,
            active: true,
            end_fiscal_year_frequency_code: undefined,
          });
          this.alertsService.success(this.translateService.instant('Field reactivated'));
        })
      );
  }

  activateValueDefinitionGroup(metricId: string, valueDefinitionGroup: ValueDefinitionGroup): void {
    this.metricsService
      .activateValueDefinitionGroup(metricId, valueDefinitionGroup.id)
      .pipe(take(1))
      .subscribe((response) => {
        this.metricStructureService.updateMetric(response.data);
        this.metricStructureService.updateSelectedItem({
          ...valueDefinitionGroup,
          active: true,
          end_fiscal_year_frequency_code: undefined,
        });
        this.alertsService.success(this.translateService.instant('Group reactivated'));
      });
  }

  activateMetricTable(metricTableGroup: MetricTableGroup): void {
    this.metricStructureService.updateIsMetricUpdating(true);
    this.metricsService
      .activateMetricTable(metricTableGroup.metric_id, metricTableGroup.table_id)
      .pipe(
        take(1),
        finalize(() => this.metricStructureService.updateIsMetricUpdating(false))
      )
      .subscribe((metricResponse) => {
        this.metricStructureService.updateMetric(metricResponse.data);

        this.metricStructureService.updateSelectedItem({
          ...metricTableGroup,
          active: true,
          end_fiscal_year_frequency_code: undefined,
        });

        this.alertsService.success(this.translateService.instant('Metric table activated'));
      });
  }
}
