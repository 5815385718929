import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginRequest } from '../../models';

export class LoginForm extends UntypedFormGroup {
  readonly username = this.get('username') as UntypedFormControl;
  readonly password = this.get('password') as UntypedFormControl;

  constructor(readonly model?: LoginRequest, readonly fb: UntypedFormBuilder = new UntypedFormBuilder()) {
    super(
      fb.group({
        username: [model?.username, Validators.required],
        password: [model?.password, Validators.required],
      }).controls
    );
  }

  toModel(): LoginRequest {
    return {
      username: this.username.value as string,
      password: this.password.value as string,
    };
  }
}
