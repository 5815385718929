import {
  DataRequestValueGroupSetStatus,
  PlatformValueGroupSetStatus,
  ValueGroup,
  ValueGroupSet,
  VGSetStatusContext,
} from '../models';
import { Builder } from './builder';

export class ValueGroupSetBuilder extends Builder<ValueGroupSet> {
  constructor() {
    super({
      id: 'vgs_1',
      name: 'vgset name',
      active: true,
      business_unit_id: 'bu_1',
      frequency_code: '2022',
      consolidated: false,
      indicator_id: 'indId',
      status: PlatformValueGroupSetStatus.NOT_STARTED,
      status_context: VGSetStatusContext.data_entered_by_user,
      value_groups: [],
    });
  }

  id(id: string): ValueGroupSetBuilder {
    this._instance.id = id;
    return this;
  }

  indicator_id(indicator_id: string): ValueGroupSetBuilder {
    this._instance.indicator_id = indicator_id;
    return this;
  }

  valueGroups(valueGroups: ValueGroup[]): ValueGroupSetBuilder {
    this._instance.value_groups = valueGroups;
    return this;
  }

  updated(updated: string): ValueGroupSetBuilder {
    this._instance.updated = updated;
    return this;
  }

  updatedBy(updatedBy: string): ValueGroupSetBuilder {
    this._instance.updated_by = updatedBy;
    return this;
  }

  frequency_code(frequency_code: string): ValueGroupSetBuilder {
    this._instance.frequency_code = frequency_code;
    return this;
  }

  businessUnitId(businessUnitId: string): ValueGroupSetBuilder {
    this._instance.business_unit_id = businessUnitId;
    return this;
  }

  status(status: PlatformValueGroupSetStatus | DataRequestValueGroupSetStatus): ValueGroupSetBuilder {
    this._instance.status = status;
    return this;
  }

  consolidated(consolidated: boolean): ValueGroupSetBuilder {
    this._instance.consolidated = consolidated;
    return this;
  }

  statusContext(statusContext?: VGSetStatusContext): ValueGroupSetBuilder {
    this._instance.status_context = statusContext;
    return this;
  }

  start(start: string): ValueGroupSetBuilder {
    this._instance.start = start;
    return this;
  }

  end(end: string): ValueGroupSetBuilder {
    this._instance.end = end;
    return this;
  }

  orgId(orgId: string): ValueGroupSetBuilder {
    this._instance.org_id = orgId;
    return this;
  }
}
