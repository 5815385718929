import { Component, Input } from '@angular/core';
import { finalize } from 'rxjs/operators';
import {
  DeactivateEntityTypes,
  Metric,
  MetricCategory,
  ValueDefinition,
  ValueDefinitionGroup,
} from '../../../../models';

import { Required } from '../../../../decorators';
import { MetricStructureSelectable, ValueDefinitionFormat } from '../../../models';
import { MetricStructureStateService } from '../../../services/metric-structure-state.service';
import { MetricApiService } from '../../../../services/types';
import { Observable } from 'rxjs';
import { DeactivateEntityService } from '../../../services/deactivate-entity/deactivate-entity.service';
import { ActivateEntityService } from '../../../services/activate-entity/activate-entity.service';
import { ValueDefinitionUtils } from '../../../classes/value-definition-utils/value-definition-utils';

@Component({
  selector: 'lib-metric-structure-group-form-details',
  templateUrl: './metric-structure-group-form-details.component.html',
  styleUrls: ['./metric-structure-group-form-details.component.scss'],
})
export class MetricStructureGroupFormDetailsComponent {
  @Input() @Required() valueDefinitionGroup!: ValueDefinitionGroup;
  @Input() @Required() index!: number;
  @Input() @Required() metric!: Metric;
  @Input() canMoveUp: boolean = false;
  @Input() canMoveDown: boolean = false;
  @Input() overlayDisabled: boolean = false;
  @Input() isCoreValueDefinitonGroupOfReferenceV2Metric: boolean = false;
  @Input() selectedItemId: string = '';

  readonly eMetricCategory: typeof MetricCategory = MetricCategory;
  canEditEveryMetrics$: Observable<boolean> = this.metricStructureService.canEditEveryMetrics$;

  constructor(
    private metricsService: MetricApiService,
    private metricStructureService: MetricStructureStateService,
    private deactivateEntityService: DeactivateEntityService,
    private activateEntityService: ActivateEntityService
  ) {}

  public isElementTriggered(element: ValueDefinition | ValueDefinitionGroup): boolean {
    return element.conditional_triggers ? element.conditional_triggers.length > 0 : false;
  }

  public getValueDefinitionFormat(valueDefinition: ValueDefinition): ValueDefinitionFormat {
    return ValueDefinitionUtils.getValueDefinitionFormat(valueDefinition);
  }

  public setSelectedItem(item?: MetricStructureSelectable): void {
    this.metricStructureService.updateSelectedItem(item);
  }

  public moveGroupDown(event: MouseEvent): void {
    const currentPosition = this.index + 1;
    event.stopPropagation();
    this.updateFieldStatus(true);
    if (this.canMoveDown) {
      this.metricsService
        .moveGroup(this.valueDefinitionGroup.metric_id, this.valueDefinitionGroup.id, currentPosition + 1)
        .pipe(finalize(() => this.updateFieldStatus(false)))
        .subscribe((response) => {
          this.updateMetricAfterPositionChange(response.data, response.data.value_definition_groups![this.index + 1]);
        });
    }
  }

  public moveGroupUp(event: MouseEvent): void {
    const currentPosition = this.index + 1;
    event.stopPropagation();
    this.updateFieldStatus(true);
    if (this.canMoveUp) {
      this.metricsService
        .moveGroup(this.valueDefinitionGroup.metric_id, this.valueDefinitionGroup.id, currentPosition - 1)
        .pipe(finalize(() => this.updateFieldStatus(false)))
        .subscribe((response) => {
          this.updateMetricAfterPositionChange(response.data, response.data.value_definition_groups![this.index - 1]);
        });
    }
  }

  public deleteGroup(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.valueDefinitionGroup.value_definitions?.length) {
      this.updateFieldStatus(true);
      this.metricsService
        .deleteGroup(this.valueDefinitionGroup.metric_id, this.valueDefinitionGroup.id)
        .pipe(finalize(() => this.updateFieldStatus(false)))
        .subscribe((response) => {
          if (this.selectedItemId === this.valueDefinitionGroup.id) {
            this.setSelectedItem(undefined);
          }
          this.metricStructureService.updateMetric(response.data);
        });
    }
  }

  private updateFieldStatus(updating: boolean): void {
    this.metricStructureService.updateIsMetricUpdating(updating);
  }

  private updateMetricAfterPositionChange(metric: Metric, vdg: ValueDefinitionGroup): void {
    this.metricStructureService.updateMetric(metric);
    this.setSelectedItem(vdg);
  }

  public deactivateFieldGroup(): void {
    this.deactivateEntityService.deactivate(
      DeactivateEntityTypes.GROUP,
      this.metric.id,
      undefined,
      this.valueDefinitionGroup
    );
  }

  public activateValueDefinitionGroup(): void {
    this.activateEntityService.activateValueDefinitionGroup(this.metric.id, this.valueDefinitionGroup);
  }
}
