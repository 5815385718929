import { Company } from '../models';

export class CompanyBuilder {
  private readonly _company: Company;

  constructor() {
    this._company = {
      parent_company_id: '',
      business_units: [],
      child_companies: [],
      id: 'HQ-1234',
      name: '',
    };
  }

  id(id: string): CompanyBuilder {
    this._company.id = id;
    return this;
  }

  name(name: string): CompanyBuilder {
    this._company.name = name;
    return this;
  }

  build(): Company {
    return this._company;
  }
}
