import { HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { delay, map } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import {
  ApiResponse,
  ApplicationApiDefinition,
  CodeCheck,
  ConditionalTrigger,
  ConditionalTriggerUpsertPayload,
  FieldInformationRequest,
  IndicatorRelatedMetrics,
  Metric,
  MetricTableCalculationDefinition,
  MetricTableDefinitionUpsertPayload,
  MetricTableDefinition,
  MetricTableStandardCodes,
  FiscalYear,
  SearchOptions,
  ValueDefinition,
  ValueDefinitionGroup,
  ValueDefinitionStandardCodes,
  MetricLoaderFlags,
  MinimalDataRequest,
  DataRequestSourceStatus,
  DataRequestStatus,
  FiscalYearStatus,
  CreateMetricTableColumnDefinitionPayload,
  MetricTableColumnDefinition,
  UpdateMetricTableColumnDefinitionPayload,
  Taxonomy,
} from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { BYPASS_INTERCEPTOR_ERROR_MANAGING } from '../../../interceptors/error-interceptor/bypass-error-constant';
import { ClientIndicatorsService } from '../client-indicators/client-indicators.service';
import { MetricApiService } from '../../types';

@Injectable({
  providedIn: 'root',
})
export class ClientMetricsService extends MetricApiService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService, private clientIndicatorsService: ClientIndicatorsService) {
    super();
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.metrics;
  }

  public search(searchOptions: SearchOptions): Observable<ApiResponse<Metric[]>> {
    return this.clientIndicatorsService.searchMetrics(searchOptions);
  }

  getMetricDefinitions(metric_id: string): Observable<ApiResponse<ValueDefinition[]>> {
    const params = new HttpParams()
      .append('load_validators', false)
      .append('load_related_equivalent', false)
      .append('load_related_core_equivalent', false);
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/${metric_id}`, { params }).pipe(
      map((result) => {
        const value_definitions: ValueDefinition[] = [];
        for (const value_definition_group of result.data.value_definition_groups || []) {
          for (const value_definition of value_definition_group.value_definitions || []) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            value_definitions.push(value_definition);
          }
        }
        return {
          meta: result.meta,
          errors: result.errors,
          data: value_definitions,
        };
      })
    );
  }

  listMetrics(metricIds: string[]): Observable<ApiResponse<Metric[]>> {
    let params = new HttpParams();
    metricIds.forEach((metricId) => {
      params = params.append('metric_ids', metricId);
    });
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics`, { params });
  }

  getMetric(id: string, params?: MetricLoaderFlags): Observable<ApiResponse<Metric>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/` + id, { params });
  }

  importMetric(core_metric_id: string): Observable<ApiResponse<Metric>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/import/` + core_metric_id, {});
  }

  createMetric(payload: any): Observable<ApiResponse<Metric>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/metrics`, payload);
  }

  updateMetric(metric_id: string, payload: any): Observable<ApiResponse<Metric>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/metrics/${metric_id}`, payload);
  }

  updateMetricTags(metric_id: string, tags?: string[]): Observable<ApiResponse<Metric>> {
    const payload: any = {};
    if (tags) {
      payload.tags = tags;
    }
    return this.apiService.put(`${this.servicePath}${this.resource}/metrics/${metric_id}/tags`, payload);
  }

  checkIfMetricCodeExists(metric_code: string): Observable<ApiResponse<CodeCheck>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/code_check/${metric_code}`);
  }

  // Groups

  createGroup(metricID: string, payload?: any): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricID}/value_definition_groups`,
      payload
    );
  }

  moveGroup(metricID: string, valueDefinitionGroupID: string, position: number): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricID}/value_definition_groups/${valueDefinitionGroupID}/move/${position}`,
      {}
    );
  }

  deleteGroup(metricID: string, valueDefinitionGroupId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricID}/value_definition_groups/${valueDefinitionGroupId}`
    );
  }

  updateGroup(metricId: string, valueDefinitionGroupID: string, payload: any): Observable<ApiResponse<Metric>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupID}`,
      payload,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true)
    );
  }

  // Fields

  createField(metricId: string, valueDefinitionGroupId: string, payload: any): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions`,
      payload
    );
  }

  duplicateField(valueDefinition: ValueDefinition): Observable<ApiResponse<ValueDefinition>> {
    const newValueDefinition: ValueDefinition = JSON.parse(JSON.stringify(valueDefinition));
    newValueDefinition.id = uuidv4();
    const response: ApiResponse<ValueDefinition> = {
      meta: {},
      errors: [],
      data: newValueDefinition,
    };
    return of(response).pipe(delay(1000));
  }

  moveField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    position: number
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/move/${position}`,
      {}
    );
  }

  transferField(
    fromValueDefinition: ValueDefinition,
    toValueDefinitionGroup: ValueDefinitionGroup
  ): Observable<ApiResponse<ValueDefinition>> {
    const newValueDefinition: ValueDefinition = JSON.parse(JSON.stringify(fromValueDefinition));
    newValueDefinition.value_definition_group_id = toValueDefinitionGroup.id;
    const response: ApiResponse<ValueDefinition> = {
      meta: {},
      errors: [],
      data: newValueDefinition,
    };
    return of(response).pipe(delay(1000));
  }

  updateField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: any
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}`,
      payload,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true)
    );
  }

  updateFieldInformation(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: FieldInformationRequest
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/field_information`,
      payload
    );
  }

  deleteField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}`,
      undefined,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true)
    );
  }

  getConditionalTriggersForValueDefinition(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string
  ): Observable<ApiResponse<ConditionalTrigger[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/conditional_triggers`
    );
  }

  createConditionalTrigger(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: ConditionalTriggerUpsertPayload
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/conditional_triggers`,
      payload
    );
  }

  updateConditionalTrigger(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    conditionTriggerId: string,
    payload: ConditionalTriggerUpsertPayload
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/conditional_triggers/${conditionTriggerId}`,
      payload
    );
  }

  deleteConditionalTrigger(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    conditionTriggerId: string
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/conditional_triggers/${conditionTriggerId}`
    );
  }

  createMetricTable(metricID: string, payload: MetricTableDefinitionUpsertPayload): Observable<ApiResponse<Metric>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/metrics/${metricID}/tables`, payload);
  }

  getMetricTable(
    metricId: string,
    tableId: string,
    checkValues = false
  ): Observable<ApiResponse<MetricTableDefinition>> {
    const params = new HttpParams().append('check_values', checkValues);
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}`, { params });
  }

  updateMetricTable(
    metricId: string,
    tableId: string,
    payload: MetricTableDefinitionUpsertPayload
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}`,
      payload,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true)
    );
  }

  moveMetricTable(metricId: string, tableId: string, position: number): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/move/${position}`,
      {}
    );
  }

  deleteMetricTable(metricId: string, tableId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}`,
      undefined,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true)
    );
  }

  createMetricTableTotal(metricId: string, tableId: string, payload: MetricTableCalculationDefinition) {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/totals`,
      payload
    );
  }

  updateMetricTableTotal(metricId: string, tableId: string, payload: MetricTableCalculationDefinition) {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/totals`,
      payload
    );
  }

  moveMetricTableTotal(
    metricId: string,
    tableId: string,
    totalId: string,
    position: number
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/totals/${totalId}/move/${position}`,
      {}
    );
  }

  deleteMetricTableTotal(metricId: string, tableId: string, totalId: string) {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/totals/${totalId}`
    );
  }

  getRelatedMetrics(id: string): Observable<ApiResponse<IndicatorRelatedMetrics>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/${id}/related_metrics`);
  }

  updateMetricTableInformation(
    metricId: string,
    tableId: string,
    payload: FieldInformationRequest
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/field_information`,
      payload
    );
  }

  getFieldStandardCodes(
    metricId: string,
    valueDefinitionId: string
  ): Observable<ApiResponse<ValueDefinitionStandardCodes[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definitions/${valueDefinitionId}/standard_codes`
    );
  }

  listMetricTableStandardCodes(
    tableIds: string[],
    metricId: string
  ): Observable<ApiResponse<MetricTableStandardCodes[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/metrics/${metricId}/tables/standard_codes/list`, {
      metric_table_definition_ids: tableIds,
    });
  }

  getLatestFiscalYearWithCollectValues(
    metricId: string,
    valueDefinitionGroupIds: string[],
    valueDefinitionIds: string[],
    metricTableIds?: string[]
  ): Observable<ApiResponse<FiscalYear | null>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/latest_fiscal_year_with_values`,
      {
        value_definition_group_ids: valueDefinitionGroupIds,
        value_definition_ids: valueDefinitionIds,
        metric_table_ids: metricTableIds,
      }
    );
  }

  deactivateValueDefinition(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/deactivate`,
      {}
    );
  }

  deactivateValueDefinitionGroup(metricId: string, valueDefinitionGroupId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/deactivate`,
      {}
    );
  }

  activateValueDefinitionGroup(metricId: string, valueDefinitionGroupId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/activate`,
      {}
    );
  }

  deactivateMetricTable(metricId: string, metricTableId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableId}/deactivate`,
      {}
    );
  }

  activateMetricTable(metricId: string, metricTableId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableId}/activate`
    );
  }

  activateValueDefinition(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/activate`
    );
  }

  public listMetricDataRequests(metricId: string): Observable<ApiResponse<MinimalDataRequest[]>> {
    const params = new HttpParams().appendAll({
      data_request_source_status: [DataRequestSourceStatus.NOT_STARTED, DataRequestSourceStatus.STARTED],
      data_request_status: [DataRequestStatus.DRAFT, DataRequestStatus.ACTIVE],
      fiscal_year_status: FiscalYearStatus.OPEN,
    });

    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/${metricId}/minimal_data_requests`, {
      params,
    });
  }

  public createMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    payload: CreateMetricTableColumnDefinitionPayload
  ): Observable<ApiResponse<MetricTableColumnDefinition>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/column_definitions`,
      payload
    );
  }

  public deleteMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    columnId: string
  ): Observable<ApiResponse<MetricTableColumnDefinition>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/column_definitions/${columnId}`
    );
  }

  public deleteMetricTableColumnOption(
    metricId: string,
    tableId: string,
    columnId: string,
    optionId: string
  ): Observable<undefined> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/column_definitions/${columnId}/options/${optionId}`
    );
  }

  public updateMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    columnId: string,
    payload: UpdateMetricTableColumnDefinitionPayload
  ): Observable<ApiResponse<MetricTableColumnDefinition>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/column_definitions/${columnId}`,
      payload,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true)
    );
  }

  public moveMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    columnId: string,
    position: number
  ): Observable<ApiResponse<MetricTableColumnDefinition>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/column_definitions/${columnId}/move/${position}`
    );
  }

  public publishMetric(): Observable<ApiResponse<Metric>> {
    return EMPTY;
  }

  public listTaxonomiesForVds(
    metricId: string,
    vdgId: string,
    vdId: string,
    completeFrameworks = false
  ): Observable<ApiResponse<Taxonomy[]>> {
    const params = new HttpParams().append('complete_frameworks', completeFrameworks);
    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${vdgId}/value_definitions/${vdId}/taxonomies`,
      { params }
    );
  }

  public listTaxonomiesForMetricTable(
    metricId: string,
    metricTableDefinitionId: string,
    completeFrameworks = false
  ): Observable<ApiResponse<Taxonomy[]>> {
    const params = new HttpParams().append('complete_frameworks', completeFrameworks);
    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableDefinitionId}/taxonomies`,
      { params }
    );
  }
}
