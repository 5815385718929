import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ActionItem } from '../../../models';
import { ValidationMessageService } from '../../../services/common';
import { DEFAULT_SELECT_ALL_VALUE } from '../../../classes';

let nextId = 0;

/**
 * Note: If "All" option is selected, for consistency between DOM and form values, the control will contain that value.
 * It's the caller responsibility to filter it before sending the data to BE.
 * That can be achieved by using the utility {@link FormUtils.removeSelectAllValue}
 */
@Component({
  selector: 'lib-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent implements OnInit {
  @ViewChild('select') select?: MatSelect;

  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() hint: string = '';
  @Input() control!: FormControl;
  @Input() options: ActionItem[] = [];
  @Input() messages: ValidationErrors = {};
  @Input() withSelectAllOption: boolean = false;
  @Input() maxCharCount: number = 25;

  required: boolean = false;
  errorMessages: ValidationErrors = {};

  allSelected = false;
  firstOptionSelectedTitle: string = '';
  indeterminateAllCheckbox: boolean = false;
  numberOfSelectedOptions: number = 0;

  readonly _labelId = `multi-select-input-${nextId++}`;
  readonly selectAllValue: string = DEFAULT_SELECT_ALL_VALUE;

  constructor(private validationMessageService: ValidationMessageService) {}

  ngOnInit(): void {
    this.required = this.control.hasValidator(Validators.required);
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
    // This casting is mandatory since the Stack Bar Chart form provide a dual type formControl as input
    // which mean we cannot assume that we'll always receive an array of string.
    // This needs to be refactored at some point.
    this.numberOfSelectedOptions = (this.control.value as string[] | undefined)?.length ?? 0;
    this.updateInnerState();
  }

  toggleAllSelection() {
    this.allSelected = !this.allSelected;

    if (this.allSelected) {
      this.select?.options.forEach((item: MatOption) => item.select());
    } else {
      this.select?.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }

    this.updateInnerState();
  }

  selectOption() {
    this.updateInnerState();
  }

  updateInnerState() {
    this.indeterminateAllCheckbox = this.isAllCheckboxOptionDeterminate();
    this.firstOptionSelectedTitle = this.computeFirstOptionSelectedTitle();
  }

  computeFirstOptionSelectedTitle(): string {
    const firstValue: string | undefined = (this.control.value as string[] | undefined)?.[0];

    if (this.numberOfSelectedOptions == this.options.length && firstValue === DEFAULT_SELECT_ALL_VALUE) {
      return DEFAULT_SELECT_ALL_VALUE;
    } else if (this.numberOfSelectedOptions > 0 && firstValue === this.selectAllValue) {
      const matchingOption = this.options.find(
        (option) => option.id === (this.control.value as string[] | undefined)?.[1]
      );
      return matchingOption?.title ?? '';
    } else {
      const matchingOption = this.options.find(
        (option) => option.id === (this.control.value as string[] | undefined)?.[0]
      );
      return matchingOption?.title ?? '';
    }
  }

  isAllCheckboxOptionDeterminate(): boolean {
    if (this.select?.options == null) {
      return false;
    }

    this.numberOfSelectedOptions = this.select.options.filter(
      (option) => option.selected && option.value != DEFAULT_SELECT_ALL_VALUE
    ).length;

    const numberOfTotalOptions = this.options.length;

    return this.numberOfSelectedOptions != numberOfTotalOptions && this.allSelected;
  }
}
