<form class="form">
  <lib-text-input
    class="large"
    [label]="passwordLabel"
    [hint]="'Minimum 15 characters, no sequential or repetitive' | translate"
    [control]="passwordConfirmationForm.controls.newPassword"
    [type]="'password'"
    [togglePassword]="true"
    [messages]="{
      required: 'New password is required' | translate,
      password_too_short: 'Password too short' | translate,
      repeating_chars: 'Too many repeating characters' | translate,
      sequential_chars: 'Too many sequential characters' | translate,
      common_password_detected: 'The password is too common' | translate,
      email_parts_detected: 'Invalid sequence in password' | translate,
      client_code_detected: 'Invalid sequence in password' | translate,
      invalid_password: 'Invalid password' | translate
    }"
  ></lib-text-input>
  <lib-text-input
    class="large newline"
    [label]="confirmPasswordLabel"
    [control]="passwordConfirmationForm.controls.confirmPassword"
    [type]="'password'"
    [togglePassword]="true"
    [messages]="{ passwordMismatch: 'Passwords do not match' | translate }"
  ></lib-text-input>
</form>
