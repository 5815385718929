<mat-toolbar class="context-bar" [ngClass]="{ separator: withSeparator }">
  <div
    class="context-bar-primary-section"
    [ngClass]="{ 'pt-0 pb-2': !pageName && !subtitles.length && !additionalActions.length }"
  >
    <div class="title-container">
      <div>
        <h4
          #title
          libEllipsifyMe
          data-testid="pageTitle"
          [matTooltip]="pageName"
          [matTooltipDisabled]="title.offsetWidth >= title.scrollWidth"
          class="page-name"
        >
          {{ pageName }}
        </h4>
        <ng-content select="[title-extra-info]"></ng-content>
      </div>
      <div class="subtitle-container" *ngIf="subtitles.length > 0">
        <span class="spacer">—</span>
        <span
          *ngFor="let subtitle of subtitles"
          [ngClass]="'subtitle-' + subtitle.themePalette"
          data-testid="pageSubtitle"
          >{{ subtitle.title }}</span
        >
      </div>
    </div>

    <div class="ml-3">
      <div class="mr-2">
        <ng-container *ngFor="let additionalAction of additionalActions">
          <ng-container *libFeatureFlag="{ featureFlags: additionalAction.featureFlag }">
            <ng-container *libPermission="additionalAction.permissions">
              <button
                mat-button
                (click)="action.emit(additionalAction.id)"
                [disabled]="additionalAction.disabled"
                [color]="additionalAction?.themePalette"
                [attr.aria-label]="additionalAction.title"
                [viewerAction]="additionalAction?.viewerAction"
                data-testid="additionalAction"
              >
                <mat-icon
                  *ngIf="additionalAction.icon"
                  [svgIcon]="additionalAction.icon"
                  class="additional-actions-icon"
                ></mat-icon>
                <span>{{ additionalAction.title }}</span>
              </button>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

      <ng-content select="[main-actions]"></ng-content>
    </div>
  </div>

  <nav *ngIf="tabs?.length" class="tabs-container">
    <ng-container *ngFor="let tab of tabs">
      <ng-container *libPermission="tab.permissions">
        <a
          role="tab"
          [routerLink]="tab.id"
          routerLinkActive="active"
          [title]="tab.title"
          *libFeatureFlag="{ featureFlags: tab.featureFlag }"
        >
          {{ tab.title }}
        </a>
      </ng-container>
    </ng-container>
  </nav>
</mat-toolbar>
