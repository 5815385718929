<ng-container *ngIf="presentation === ePresentation.card">
  <div title="{{ 'No items found' | translate }}" class="card presentation-card">
    {{ "No items found" | translate }}
  </div>
</ng-container>
<ng-container *ngIf="presentation === ePresentation.doc">
  <div title="{{ 'No items found' | translate }}" class="card presentation-doc">
    <div class="card-header">
      <div class="file-icon bkg-grey-200"></div>
      <div class="caption2 mt-2 mb-1">&nbsp;</div>
    </div>
    <div class="card-content">
      <div class="mat-body-strong color-primary-900 text-clamp text-clamp-3">{{ "No items found" | translate }}</div>
    </div>
    <div class="card-footer">
      <a [title]="linkText" [routerLink]="link" class="caption2 link">
        {{ linkText }}
      </a>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="presentation === ePresentation.highlights">
  <div title="{{ 'No items found' | translate }}" class="card presentation-highlights">
    {{ "No items found" | translate }}
  </div>
</ng-container>
