<button lib-button [matMenuTriggerFor]="dropdownMenu" [label]="label" [type]="type">
  <ng-content></ng-content>
</button>

<mat-menu #dropdownMenu="matMenu">
  <ng-container *ngFor="let option of options">
    <button mat-menu-item class="custom-option" (click)="handleSelection.emit(option)">
      {{ option.title }}
    </button>
  </ng-container>
</mat-menu>
