import { ChoiceFieldWidgetType, ChoiceTypeDetails } from '../models';

export class ChoiceTypeDetailsBuilder {
  private readonly _choiceTypeDetails: ChoiceTypeDetails;

  constructor() {
    this._choiceTypeDetails = {
      allow_add_option: false,
      choices: ['Red', 'Green', 'Blue'],
      display_explanation: false,
      explanation_label: 'Please explain',
      explanation_required: false,
      multi_choices: true,
      widget_type: ChoiceFieldWidgetType.checkboxes,
    };
  }

  widgetType(widgetType: ChoiceFieldWidgetType): ChoiceTypeDetailsBuilder {
    this._choiceTypeDetails.widget_type = widgetType;
    return this;
  }

  choices(choices: string[]): ChoiceTypeDetailsBuilder {
    this._choiceTypeDetails.choices = choices;
    return this;
  }

  displayExplanation(displayExplanation: boolean): ChoiceTypeDetailsBuilder {
    this._choiceTypeDetails.display_explanation = displayExplanation;
    return this;
  }

  build(): ChoiceTypeDetails {
    return this._choiceTypeDetails;
  }
}
