<section class="section section-dynamic" [ngClass]="{ loaded: loaded }">
  <div class="section-header">
    <h4 class="mat-h4 color-grey-900">{{ title }}</h4>
  </div>
  <div class="section-content" [ngClass]="{ loaded: loaded }">
    <div class="collection collection-dummies" data-testid="dummy-container">
      <lib-dummy *ngFor="let dummy of dummies" [presentation]="presentation"></lib-dummy>
    </div>
    <div class="collection collection-items">
      <lib-card-empty
        data-testId="empty-card"
        [presentation]="presentation"
        *ngIf="!itemCollection || itemCollection?.items?.length === 0"
      ></lib-card-empty>
      <ng-container *ngFor="let item of itemCollection?.items">
        <lib-card
          data-testId="template-card"
          [item]="item"
          [presentation]="presentation"
          [path]="item | templateLink"
          [pathPosition]="linkPosition"
          [limitedCardSize]="true"
        >
        </lib-card>
      </ng-container>
    </div>
  </div>
</section>
