import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Observable, of, filter } from 'rxjs';
import { finalize, switchMap, takeWhile, tap, take } from 'rxjs/operators';

import {
  ActionItem,
  ApiResponse,
  Doc,
  Metric,
  Status,
  ValueDefinition,
  ValueDefinitionDisplayType,
  ValueDefinitionGroup,
  ValueDefinitionTemplate,
  ValueDefinitionType,
  CONTEXT_FIELD_PERMITTED_VALUE_DEFINITION_TYPES,
  ConsolidationRules,
  MetricCategory,
  DeactivateEntityTypes,
  DialogSize,
  DialogResult,
} from '../../../../models';
import { ConsolidationService } from '../../../../services/common/consolidation/consolidation.service';
import { TranslateService } from '../../../../services/common/translate/translate.service';

import { RadioButtonOption } from '../../../../components';
import { Required } from '../../../../decorators';
import { ValueDefinitionTemplateService } from '../../../services/value-definition-template.service';
import { ValueDefinitionTemplateType } from '../../../models';
import { MetricStructureStateService } from '../../../services/metric-structure-state.service';
import { DynamicForm } from '../../../../classes';
import { MetricStructureFieldPropertiesFormService } from './forms/metric-structure-field-properties-form.service';
import { MetricStructureFieldFormModel } from './forms/metric-structure-field-properties-form-configs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorManagerService } from '../../../../services/common/error-manager/error-manager.service';
import { ConfirmationDialogComponent, DialogsService } from '../../../../dialogs';
import { MetricApiService } from '../../../../services/types';
import { DeactivateEntityService } from '../../../services/deactivate-entity/deactivate-entity.service';
import { ActivateEntityService } from '../../../services/activate-entity/activate-entity.service';
import { ValueDefinitionUtils } from '../../../classes/value-definition-utils/value-definition-utils';
import { ObservableUtils } from '../../../../classes/ObservableUtils/observable-utils';
import {
  ConsolidationManualDialogComponent,
  ConsolidationManualDialogResults,
} from '../../consolidation-manual-dialog/consolidation-manual-dialog.component';

@Component({
  selector: 'lib-metric-structure-field-properties',
  templateUrl: './metric-structure-field-properties.component.html',
  styleUrls: ['./metric-structure-field-properties.component.scss'],
  providers: [MetricStructureFieldPropertiesFormService],
})
export class MetricStructureFieldPropertiesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() @Required() valueDefinition!: ValueDefinition;
  @Input() formDisabled: boolean = false;
  @Input() metric?: Metric;

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('fieldsContainer') fieldsContainer?: ElementRef<HTMLDivElement>;

  updating$: Observable<boolean>;
  isRepeatableGroup$: Observable<boolean>;
  valueDefinitionGroup?: ValueDefinitionGroup;

  dynamicFieldForm$: Observable<DynamicForm<MetricStructureFieldFormModel> | undefined> =
    this.metricStructureFieldPropertiesFormService.dynamicFieldForm$;
  unitFamilies$: Observable<ActionItem[]> = this.metricStructureFieldPropertiesFormService.unitFamilies$;
  unitDefaults$: Observable<ActionItem[]> = this.metricStructureFieldPropertiesFormService.unitDefaults$;
  allUnits$: Observable<ActionItem[]> = this.metricStructureFieldPropertiesFormService.allUnits$;
  fieldHeaderDetails: ValueDefinitionTemplate | undefined;

  readonly contextFieldPermittedValueDefinitionTypes = CONTEXT_FIELD_PERMITTED_VALUE_DEFINITION_TYPES;
  readonly dateOptionsAnswers: ActionItem[] = [
    {
      id: 'YYYY-MM-DD',
      title: this.translateService.instant('YYYY/MM/DD'),
    },
  ];
  readonly numberTypeChoices: RadioButtonOption[] = [
    {
      value: ValueDefinitionDisplayType.integer,
      label: this.translateService.instant('Integer'),
    },
    {
      value: ValueDefinitionDisplayType.decimal,
      label: this.translateService.instant('Decimal'),
    },
  ];
  readonly numericDecimalOptions = Array(11)
    .fill(null)
    .map((_, i) => ({ id: `${i}`, title: `${i}` })) as ActionItem[];

  readonly characterLimitErrorMsgs: ValidationErrors = {
    notNumeric: this.translateService.instant('Please enter a positive integer'),
  };
  readonly numberMinMaxErrorMsgs: ValidationErrors = {
    minMax: this.translateService.instant('Min cannot be greater than max'),
    maxMin: this.translateService.instant('Max cannot be less than min'),
  };
  readonly filesLimitErrorMsgs: ValidationErrors = {
    max: this.translateService.instant('File attachment limit cannot be greater than 50'),
    min: this.translateService.instant('File attachment limit cannot be lesser than 1'),
  };
  readonly displaySizeErrorMsgs: ValidationErrors = {
    required: this.translateService.instant('Display size is required'),
  };

  fieldsThatCanChangeType = [ValueDefinitionType.decimal, ValueDefinitionType.integer];
  fieldsThatWarnWhenChanged = [ValueDefinitionType.calculated];
  canEditEveryMetrics$?: Observable<boolean>;

  readonly valueDefinitionTemplates: ValueDefinitionTemplate[];
  readonly eValueDefinitionDisplayType: typeof ValueDefinitionDisplayType = ValueDefinitionDisplayType;
  readonly eValueDefinitionTemplateType: typeof ValueDefinitionTemplateType = ValueDefinitionTemplateType;
  readonly valueDefinitionTypeSizes = this.valueDefinitionTemplateService.valueDefinitionTypeSizes;
  readonly eMetricCategory: typeof MetricCategory = MetricCategory;
  readonly isAdmin: boolean = this.metricStructureService.isAdmin;

  public consolidationRuleOptions: ActionItem[] = [];

  constructor(
    private metricStructureService: MetricStructureStateService,
    private valueDefinitionTemplateService: ValueDefinitionTemplateService,
    private metricsService: MetricApiService,
    private dialogsService: DialogsService,
    private translateService: TranslateService,
    private metricStructureFieldPropertiesFormService: MetricStructureFieldPropertiesFormService,
    private consolidationService: ConsolidationService,
    private errorManagerService: ErrorManagerService,
    private deactivateEntityService: DeactivateEntityService,
    private activateEntityService: ActivateEntityService
  ) {
    this.consolidationRuleOptions = this.consolidationService.consolidationRuleOptions;

    this.valueDefinitionTemplates = this.valueDefinitionTemplateService.getValueDefinitionTemplates();
    this.isRepeatableGroup$ = this.metricStructureService.isRepeatableGroup$;
    this.updating$ = this.metricStructureService.isMetricUpdating$;
  }

  ngOnInit(): void {
    this.updateIsRepeatableGroup();
    this.metricStructureFieldPropertiesFormService.setConsolidationTrigger(
      this.consolidationService.consolidationTriggerOptions.length > 0
    );
  }

  ngOnChanges(): void {
    this.metricStructureFieldPropertiesFormService.initializeValueDefinitionForm(this.valueDefinition, this.metric);
    this.updateIsRepeatableGroup();

    if (this.fieldsContainer) {
      this.fieldsContainer.nativeElement.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
    this.fieldHeaderDetails = this.getFieldHeaderDetails();
  }

  ngOnDestroy(): void {
    this.cleanTemporaryField();
  }

  public get staticConsolidationFlag(): boolean {
    return this.consolidationService.staticConsolidationFlag;
  }

  private setUpdating(updating: boolean): void {
    this.metricStructureService.updateIsMetricUpdating(updating);
  }

  public updateDocResource(doc: Doc): void {
    this.metricStructureService.addDocumentsToTheList([doc]);
  }

  public disableDisplay(): void {
    this.metricStructureFieldPropertiesFormService.toggleMultipleLine();
  }

  public getFieldType(type: string[]): boolean {
    const currentValueDefinitionType = this.valueDefinitionDisplayType;
    return type.some((t) => t === currentValueDefinitionType);
  }

  public getFieldHeaderDetails(): ValueDefinitionTemplate | undefined {
    const fieldTemplates = this.valueDefinitionTemplates.filter((x) => !['category', 'group'].includes(x.type));
    switch (this.valueDefinitionDisplayType) {
      case ValueDefinitionDisplayType.text_area:
      case ValueDefinitionDisplayType.text_simple:
        return fieldTemplates.find((x) => x.code === 'textarea');
      case ValueDefinitionDisplayType.text_rich:
        return fieldTemplates.find((x) => x.code === 'rich-text');
      case ValueDefinitionDisplayType.integer:
      case ValueDefinitionDisplayType.decimal:
        return fieldTemplates.find((x) => x.type === ValueDefinitionType.integer);
      case ValueDefinitionDisplayType.date:
      case ValueDefinitionDisplayType.datetime:
        return fieldTemplates.find((x) => x.type === ValueDefinitionType.date);
      case ValueDefinitionDisplayType.boolean:
        return fieldTemplates.find((x) => x.type === ValueDefinitionType.boolean);
      case ValueDefinitionDisplayType.choice:
      case ValueDefinitionDisplayType.choice_multiple:
      case ValueDefinitionDisplayType.choice_searchable:
      case ValueDefinitionDisplayType.choice_radio:
        return fieldTemplates.find((x) => x.type === ValueDefinitionType.choice);
      default:
        return fieldTemplates.find((x) => x.type === this.valueDefinition.type);
    }
  }

  public deleteField(event: MouseEvent): void {
    event.stopPropagation();
    const valueDefinitions: ValueDefinition[] | undefined = this.metric?.value_definition_groups?.find(
      (x) => this.valueDefinition.value_definition_group_id === x.id
    )?.value_definitions;
    if (valueDefinitions) {
      const index = valueDefinitions.findIndex((x) => x.id === this.valueDefinition.id);

      const valueDefinition: ValueDefinition = valueDefinitions[index];
      this.dialogsService
        .open(ConfirmationDialogComponent, {
          data: {
            title: this.translateService.instant('Delete field'),
            warningMsg: this.translateService.instant(
              valueDefinition.type === ValueDefinitionType.calculated
                ? 'Please note that deleting this calculated field will also delete any stored calculated values. The data from the variables in the formula are untouched.'
                : 'Are you sure you wish to delete this field? All the data associated with it will be lost.'
            ),
          },
        })
        .afterClosed()
        .pipe(
          takeWhile((result) => result?.status === Status.CONFIRMED),
          switchMap(() => {
            if (valueDefinition.id !== ValueDefinitionTemplateType.template) {
              this.setUpdating(true);
              return this.metricsService.deleteField(
                this.metric?.id as string,
                valueDefinition.value_definition_group_id,
                valueDefinition.id
              );
            }
            return of();
          }),
          finalize(() => this.setUpdating(false))
        )
        .subscribe({
          next: () => {
            valueDefinitions.splice(index, 1);
            this.closeProperties();
          },
          error: (errorResponse: unknown) => {
            try {
              this.metricStructureService.handleDeleteValidationErrors(errorResponse as HttpErrorResponse);
            } catch (_) {
              this.errorManagerService.handleError(errorResponse as HttpErrorResponse);
            }
          },
        });
    }
  }

  public saveField(): void {
    if (!this.metricStructureFieldPropertiesFormService.isValid) {
      return;
    }
    const fieldDefinition: ValueDefinition = this.metricStructureFieldPropertiesFormService.toValueDefinition(
      this.valueDefinition
    );

    if (!this.fieldsThatWarnWhenChanged.includes(fieldDefinition.type)) {
      if (!this.metricStructureFieldPropertiesFormService.isConsolidationParamsDirty) {
        return this.updateField(fieldDefinition, false);
      }
    }

    if (
      fieldDefinition.consolidation_rule === ConsolidationRules.manual &&
      fieldDefinition.consolidation_rule !== this.valueDefinition.consolidation_rule
    ) {
      return this.triggerConsolidationManualDialog(fieldDefinition);
    }

    return this.triggerConfirmationDialog(fieldDefinition);
  }

  private triggerConfirmationDialog(fieldDefinition: ValueDefinition): void {
    this.dialogsService
      .open(ConfirmationDialogComponent, {
        data: {
          primaryBtn: this.translateService.instant('Ok'),
          warningMsg: this.translateService.instant(
            'Changes to the configuration rules may result in completed metrics being set back to in progress should their values be modified.'
          ),
        },
      })
      .afterClosed()
      .pipe(
        takeWhile((result) => result?.status === Status.CONFIRMED),
        tap(() => {
          this.updateField(fieldDefinition, false);
        })
      )
      .subscribe();
  }

  private triggerConsolidationManualDialog(fieldDefinition: ValueDefinition): void {
    this.dialogsService
      .open<ConsolidationManualDialogComponent>(ConsolidationManualDialogComponent, {
        data: {
          size: DialogSize.small,
        },
      })
      .afterClosed()
      .pipe(
        ObservableUtils.filterNullish(),
        filter((dialogResult: DialogResult<ConsolidationManualDialogResults>) => dialogResult.status === Status.SUCCESS)
      )
      .subscribe((dialogResult: DialogResult<ConsolidationManualDialogResults>) => {
        this.updateField(fieldDefinition, dialogResult.data?.reset_consolidated_values || false);
      });
  }

  public updateField(valueDefinition: ValueDefinition, reset_consolidated_values: boolean): void {
    const canChangeType = this.fieldsThatCanChangeType.includes(valueDefinition.type);
    this.setUpdating(true);
    const payload = this.metricStructureService.getFieldPayload(valueDefinition, true, canChangeType);
    this.sendFieldUpdate(valueDefinition, { ...payload, reset_consolidated_values });
  }

  public addField() {
    let selectedItem: ValueDefinition = JSON.parse(JSON.stringify(this.valueDefinition)) as ValueDefinition;

    const get_and_create_vd = () => {
      const valueDefGroup = this.metric?.value_definition_groups?.find(
        (x: ValueDefinitionGroup) => x.id === selectedItem.value_definition_group_id
      ) as ValueDefinitionGroup;
      selectedItem = this.metricStructureFieldPropertiesFormService.toValueDefinition(selectedItem);
      this.createField(valueDefGroup.id, selectedItem, selectedItem.position ? selectedItem.position - 1 : 0);
    };

    if (selectedItem.type == ValueDefinitionType.calculated) {
      this.dialogsService
        .open(ConfirmationDialogComponent, {
          data: {
            primaryBtn: this.translateService.instant('Add'),
            warningMsg: this.translateService.instant(
              'If this metric has been marked as complete in an open fiscal year, the status will be set back to in progress as a result of adding this new calculated field.'
            ),
          },
        })
        .afterClosed()
        .pipe(
          takeWhile((result) => result?.status === Status.CONFIRMED),
          tap(() => {
            get_and_create_vd();
          })
        )
        .subscribe();
    } else {
      get_and_create_vd();
    }
  }

  private createField(valueDefinitionGroupId: string, valueDefinition: ValueDefinition, itemIndex: number): void {
    this.setUpdating(true);
    const payload = this.metricStructureService.getFieldPayload(valueDefinition);
    this.metricsService
      .createField(this.metric!.id, valueDefinitionGroupId, payload)
      .pipe(finalize(() => this.setUpdating(false)))
      .subscribe((result) => {
        const valueDefinitions = result.data.value_definition_groups?.find(
          (x) => x.id === valueDefinitionGroupId
        )?.value_definitions;
        if (valueDefinitions) {
          this.metricStructureService.updateSelectedItem(valueDefinitions[itemIndex]);
        }
        this.updateMetric(result.data);
        this.metricStructureService.setIsCreatingField(false);
      });
  }

  public closeProperties(): void {
    this.closePanel.emit();
  }

  public selectTipDisplayOption(item: ActionItem): void {
    this.metricStructureFieldPropertiesFormService.tipDisplayOption = item;
  }

  public onUnitFamilyChange(unitFamily: string | undefined): void {
    this.metricStructureFieldPropertiesFormService.getUnitByFamily(unitFamily);
  }

  public selectNumberType(numberType: string): void {
    this.metricStructureFieldPropertiesFormService.setNumberTypeValidation(numberType);
  }

  public toggleMaxFiles(isMultipleFiles: boolean): void {
    this.metricStructureFieldPropertiesFormService.setIsMultipleFiles(isMultipleFiles);
  }

  public updateCustomChoiceAnswers(customChoiceAnswers: ActionItem[]): void {
    this.metricStructureFieldPropertiesFormService.customChoiceAnswers = customChoiceAnswers;
  }

  getTipDisplayOptions(): ActionItem[] {
    return this.metricStructureService.tipDisplayOptions;
  }

  public getConsolidationTriggerOptions(): ActionItem[] {
    return this.consolidationService.consolidationTriggerOptions;
  }

  get valueDefinitionDisplayType(): ValueDefinitionDisplayType {
    return ValueDefinitionUtils.getValueDefinitionFormat(this.valueDefinition).type;
  }

  public setConsolidationTriggerOptions(consolidationRule: ConsolidationRules): void {
    this.consolidationService.setConsolidationTriggerOptions(consolidationRule);
    this.metricStructureFieldPropertiesFormService.setConsolidationTrigger(
      this.consolidationService.consolidationTriggerOptions.length > 0
    );
  }

  public isThirdParty(): boolean {
    return this.metric?.category === MetricCategory.THIRD_PARTY;
  }

  private cleanTemporaryField(): void {
    if (this.valueDefinition.id === ValueDefinitionTemplateType.template) {
      setTimeout(() => {
        this.metricStructureService.removeTemporaryField(
          this.valueDefinition,
          this.valueDefinition.position ? this.valueDefinition.position - 1 : 0
        );

        this.metricStructureService.setIsCreatingField(false);
      });
    }
  }

  deactivateField(): void {
    this.deactivateEntityService.deactivate(
      DeactivateEntityTypes.FIELD,
      this.metric?.id ?? '',
      this.valueDefinition,
      this.valueDefinitionGroup
    );
  }

  private updateMetric(metric: Metric): void {
    metric.related_metrics = this.metric?.related_metrics;
    this.metricStructureService.updateMetric(metric);
  }

  activateField(): void {
    this.activateEntityService
      .activateValueDefinition(this.metric?.id ?? '', this.valueDefinition)
      .pipe(take(1))
      .subscribe();
  }

  private sendFieldUpdate(valueDefinition: ValueDefinition, payload: Record<string, unknown>): void {
    if (this.metric) {
      this.metricsService
        .updateField(this.metric.id, valueDefinition.value_definition_group_id, valueDefinition.id, payload)
        .pipe(finalize(() => this.setUpdating(false)))
        .subscribe({
          next: (res: ApiResponse<Metric>) => {
            this.updateMetric(res.data);
            this.valueDefinition = valueDefinition;
            this.metricStructureService.updateSelectedItem(valueDefinition);
          },
          error: (errorResponse: unknown) => {
            try {
              this.metricStructureService.handleUpdateValidationErrors(errorResponse as HttpErrorResponse);
            } catch (_) {
              this.errorManagerService.handleError(errorResponse as HttpErrorResponse);
            }
          },
        });
    }
  }
  private updateIsRepeatableGroup(): void {
    this.valueDefinitionGroup = this.metric?.value_definition_groups?.find(
      (valueDefinitionGroup) => valueDefinitionGroup.id == this.valueDefinition.value_definition_group_id
    );
    this.metricStructureService.updateIsRepeatableGroup(this.valueDefinitionGroup?.repeatable ?? false);
  }
}
