import { Directive, Input, ElementRef } from '@angular/core';
import { AuthService } from '../../services/common';
import { ViewerActionType } from '../../models';

@Directive({
  selector: '[viewerAction]',
})
export class ViewerActionDirective {
  @Input() viewerAction?: any;

  constructor(private elementRef: ElementRef, private authService: AuthService) {}

  ngOnInit(): void {
    this.handleViewerAction();
  }

  public handleViewerAction(): void {
    if (this.authService.isViewer) {
      switch (this.viewerAction) {
        case ViewerActionType.disable:
          this.elementRef.nativeElement.classList.add('disabled');
          break;
        case ViewerActionType.hide:
          this.elementRef.nativeElement.style.display = 'none';
          break;
        case ViewerActionType.preventMouseAction:
          this.elementRef.nativeElement.style.pointerEvents = 'none';
          break;
        default:
          break;
      }
    }
  }
}
