import { Component, Input, OnInit } from '@angular/core';
import { ValueFormControl } from '../../models/valueFormControl';
import { Presentation, DocumentTypeDetails, FileDocumentInterface } from '../../../models';
import { SearchService } from '../../../search';
import { Icon } from '../../../icons/icons.model';
import { DEFAULT_DOCUMENT_CONTEXT, DocumentContext } from '../../models/documentContext';
import { BaseMetricEditorFormStateService } from '../../services/base-metric-editor-form-state/base-metric-editor-form-state.service';
import { DownloadDocumentService } from '../../../services/common';
import { take } from 'rxjs';
import { StandardDocumentMetadata } from '../../../documents';

@Component({
  selector: 'lib-metric-editor-document-link',
  templateUrl: './metric-editor-document-link.component.html',
  styleUrls: ['./metric-editor-document-link.component.scss'],
})
export class MetricEditorDocumentLinkComponent implements OnInit {
  @Input() documentContext: DocumentContext = DEFAULT_DOCUMENT_CONTEXT;
  @Input() valueFormControl?: ValueFormControl<DocumentTypeDetails>;

  docLoaded: boolean = false;
  docLinkPresentation: Presentation = Presentation.doclink;
  document?: StandardDocumentMetadata;
  documentFileData?: FileDocumentInterface;
  documentIcon: Icon = '';
  docFileName: string = '';
  docFormat: string = '';
  documentId: string = '';

  constructor(
    private baseMetricEditorFormStateService: BaseMetricEditorFormStateService,
    private searchService: SearchService,
    private downloadDocumentService: DownloadDocumentService
  ) {}

  ngOnInit(): void {
    this.documentId = this.valueFormControl?.valueRef.type_details.document_id ?? '';
    this.getDocument();
  }

  public downloadDocument(): void {
    if (this.valueFormControl && this.documentFileData) {
      this.baseMetricEditorFormStateService
        .getDocumentLink(
          this.documentFileData,
          this.valueFormControl.valueRef.type_details.document_host_env,
          this.valueFormControl.valueRef.value_definition_id
        )
        .pipe(take(1))
        .subscribe((docBlob: Blob) => {
          this.downloadDocumentService.downloadAction(`${this.docFileName}.${this.docFormat}`, docBlob);
        });
    }
  }

  private getDocument(): void {
    if (this.valueFormControl && this.valueFormControl.valueRef.type_details.document_id) {
      this.baseMetricEditorFormStateService
        .getDocumentLinkMetadata(
          [this.documentId],
          this.valueFormControl.valueRef.type_details.document_host_env,
          this.valueFormControl.valueRef.value_definition_id
        )
        .subscribe((documentFileData: FileDocumentInterface[]) => {
          this.documentFileData = documentFileData[0];
          this.document = documentFileData[0]?.doc as StandardDocumentMetadata;
          this.docFileName = this.valueFormControl?.valueRef.type_details.display_name || documentFileData[0]?.name;
          this.docFormat = documentFileData[0]?.format;
          this.documentIcon = documentFileData[0]?.format
            ? this.searchService.getFileFormatIcon(documentFileData[0].format)
            : '';
          this.docLoaded = true;
        });
    }
  }
}
