import { UpsertValueGroup, UpsertValueGroupSet } from '../metric-editor-form';

export class UpsertValueGroupSetBuilder {
  private readonly _upsertVgset: UpsertValueGroupSet;

  constructor() {
    this._upsertVgset = {
      business_unit_id: 'bu_1',
      frequency_code: '2022',
      value_groups: [],
    };
  }

  id(id: string): UpsertValueGroupSetBuilder {
    this._upsertVgset.id = id;
    return this;
  }

  businessUnitId(buId: string): UpsertValueGroupSetBuilder {
    this._upsertVgset.business_unit_id = buId;
    return this;
  }

  frequencyCode(frequencyCode: string): UpsertValueGroupSetBuilder {
    this._upsertVgset.frequency_code = frequencyCode;
    return this;
  }

  valueGroups(valueGroups: UpsertValueGroup[]): UpsertValueGroupSetBuilder {
    this._upsertVgset.value_groups = valueGroups;
    return this;
  }

  dataRequestId(dataRequestId: string): UpsertValueGroupSetBuilder {
    this._upsertVgset.data_request_id = dataRequestId;
    return this;
  }

  build(): UpsertValueGroupSet {
    return this._upsertVgset;
  }
}
