import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionItem, Framework, StandardCodeCategory, ResourceType } from '../../../models';
import { ButtonProps, SearchableOptionProps } from '../../models';
import { UntypedFormControl } from '@angular/forms';
import { StandardCodeCategoryTranslations } from '../../../translations';

@Component({
  selector: 'lib-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss'],
})
export class FilterSearchComponent implements OnInit {
  @Input() optionProps?: SearchableOptionProps;
  @Input() buttonProps?: ButtonProps;

  @Output() optionSelected = new EventEmitter<ActionItem>();

  searchInputControl = new UntypedFormControl();
  filteredOptions: ActionItem[] = [];
  minMenuScrollItems: number = 10;
  standardCodeResourceType = ResourceType.standard_codes;
  topicResourceType = ResourceType.topic;

  ngOnInit(): void {
    this.filteredOptions = this.optionProps?.options ?? [];

    if (this.optionProps?.minMenuScrollItems) {
      this.minMenuScrollItems = this.optionProps.minMenuScrollItems;
    }

    this.searchInputControl.valueChanges.subscribe((query: string) => {
      this.filteredOptions = this.filterItems(this.optionProps?.options ?? [], query);
    });
  }

  resetInput(): void {
    this.searchInputControl.setValue('');
  }

  filterItems(input: ActionItem[], value: string): ActionItem[] {
    const filterValue = value.toLowerCase();
    return input.filter((item) => item.title.toLowerCase().includes(filterValue));
  }

  isSearchable(): boolean {
    if (this.optionProps?.options?.length) {
      return this.optionProps.options.length > this.minMenuScrollItems;
    }
    return false;
  }

  getDefaultValueTitle(): string {
    return this.optionProps?.defaultValueTitle ?? this.optionProps?.defaultValue?.title ?? '';
  }

  getButtonTitle(): string {
    return this.buttonProps?.title ?? this.optionProps?.activeOption?.title ?? '';
  }

  public readonly eStandardCodeCategoryTranslations = StandardCodeCategoryTranslations;
  public readonly standardCodeFilterCategories: StandardCodeCategory[] = [
    StandardCodeCategory.REPORTING_STANDARD,
    StandardCodeCategory.REPORTING_FRAMEWORK,
    StandardCodeCategory.REGULATION,
    StandardCodeCategory.RATING,
  ];

  public filterStandardCodes(item: ActionItem<Framework>, category?: StandardCodeCategory): boolean {
    return item.item?.standard_code_category === category;
  }
}
