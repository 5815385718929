import { trigger, transition, style, animate, state } from '@angular/animations';

const animationDuration: string = '500ms';

export const transitions = trigger('fadeIn', [
  state(
    'invisible',
    style({
      opacity: 0,
    })
  ),
  state(
    'visible',
    style({
      opacity: 1,
    })
  ),
  transition('invisible <=> visible', [animate(`${animationDuration} ease-out`)]),
]);
