import { Metric, TemplateReportSection, SectionMetricDisplay } from '../models';
import { v4 as uuidv4 } from 'uuid';

export class TemplateReportSectionBuilder {
  private readonly _templateReportSection: TemplateReportSection;

  constructor() {
    this._templateReportSection = {
      id: uuidv4(),
      code: 'templateReportSectionCode',
      label: 'templateReportSectionLabel',
      position: 1,
      children: [],
      metrics: [],
      display: [],
    };
  }

  id(id: string): TemplateReportSectionBuilder {
    this._templateReportSection.id = id;
    return this;
  }

  code(code: string): TemplateReportSectionBuilder {
    this._templateReportSection.code = code;
    return this;
  }

  label(label: string): TemplateReportSectionBuilder {
    this._templateReportSection.label = label;
    return this;
  }

  childrenSections(children: TemplateReportSection[]): TemplateReportSectionBuilder {
    this._templateReportSection.children = children;
    return this;
  }

  metrics(metrics: Metric[]): TemplateReportSectionBuilder {
    this._templateReportSection.metrics = metrics;
    return this;
  }
  display(display: SectionMetricDisplay[]): TemplateReportSectionBuilder {
    this._templateReportSection.display = display;
    return this;
  }

  build(): TemplateReportSection {
    return this._templateReportSection;
  }
}
