import { Observable, Subject, concatMap } from 'rxjs';

export class ObservableQueueService<T> {
  observableQueue = new Subject<Observable<T>>();

  public addObservableToQueue(observable: Observable<T>) {
    this.observableQueue.next(observable);
  }

  public startQueue(): Observable<T> {
    return this.observableQueue.pipe(concatMap((observable) => observable));
  }
}
