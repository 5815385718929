import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { Required } from '../../../../decorators';
import { ValidationMessageService } from '../../../../services/common';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';

@Component({
  selector: 'lib-metric-editor-checkbox-field',
  templateUrl: './metric-editor-checkbox-field.component.html',
  styleUrls: ['./metric-editor-checkbox-field.component.scss'],
})
export class MetricEditorCheckboxFieldComponent implements OnChanges {
  @Input() @Required() control!: UntypedFormControl;
  @Input() options: string[] = [];
  @Input() messages?: ValidationErrors;
  @Input() hint?: string;

  @ViewChild('focusElement') focusElement!: ElementRef;

  required: boolean = false;
  errorMessages: ValidationErrors = {};

  constructor(private validationMessageService: ValidationMessageService) {}

  ngOnChanges(): void {
    this.initializeInput();
  }

  private initializeInput() {
    this.required = this.control.hasValidator(Validators.required);
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
  }

  public setFocus(): void {
    this.focusElement.nativeElement.focus();
  }
}
