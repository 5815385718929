import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';

@Component({
  selector: 'lib-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
})
export class CheckboxInputComponent {
  @Input() options: string[] = [];
  @Input() control!: UntypedFormControl;

  public onCheckboxToggle(event: MatCheckboxChange, option: string): void {
    const currentValues = this.control.value ?? [];
    if (event.checked && currentValues.indexOf(option) < 0) {
      this.control.setValue([...currentValues, option]);
    } else {
      this.control.setValue(currentValues.filter((value: string) => value != option));
    }
    this.control.markAsDirty();
    this.control.markAsTouched();
  }
}
