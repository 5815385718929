import { Injectable } from '@angular/core';

// @ts-ignore
import iso3166_2_db from 'iso3166-2-db/i18n/en.json';

import { Iso3166Country, Iso3166State } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private readonly countries: Iso3166Country[] = [];
  private readonly states: Iso3166State[] = [];
  private readonly compareByName = (a: any, b: any) => (a.name > b.name ? 1 : -1);

  constructor() {
    this.countries = this.getCountriesDataSource();
    this.states = this.getStatesDataSource();
  }

  getAllCountries(): Iso3166Country[] {
    return this.countries;
  }

  getAllStates(): Iso3166State[] {
    return this.states;
  }

  getStatesOfCountry(countryCode: string): Iso3166State[] {
    if (!countryCode) {
      return [];
    }

    return this.states.filter((s) => s.countryIso === countryCode);
  }

  getCountryByCode(countryCode: string): Iso3166Country | undefined {
    if (!countryCode) {
      return undefined;
    }

    return this.countries.find((c) => c.iso === countryCode);
  }

  getStateByCode(stateCode: string): Iso3166State | undefined {
    if (!stateCode) {
      return undefined;
    }

    return this.states.find((c) => c.iso === stateCode);
  }

  /**
   * The countries data entry point. This is the place to change if the countries JSON is going to be replaced.
   */
  private getCountriesDataSource(): Iso3166Country[] {
    const dataSource = iso3166_2_db as unknown as { [key: string]: any };
    return Object.values<any>(dataSource)
      .map((country) => {
        return {
          name: country.name,
          iso: country.iso,
        };
      })
      .sort(this.compareByName);
  }

  /**
   * The states data entry point. This is the place to change if the states JSON is going to be replaced.
   */
  private getStatesDataSource(): Iso3166State[] {
    const dataSource = iso3166_2_db as unknown as { [key: string]: any };
    return Object.values<any>(dataSource)
      .reduce((acc: Iso3166State[], country: any) => {
        acc.push(
          ...Object.values<any>(country.regions).map((state) => {
            return {
              name: state.name,
              iso: state.iso,
              countryIso: country.iso,
            };
          })
        );
        return acc;
      }, [])
      .sort(this.compareByName);
  }
}
