import {
  ConditionalTrigger,
  ConsolidationRules,
  ConsolidationTriggers,
  TypeDetails,
  Validator,
  ValueDefinitionSize,
  ValueDefinitionType,
  ValueType,
  DefaultConsolidationRule,
  DataRequestValue,
  Value,
} from '../models';

export class DataRequestValueBuilder<T extends TypeDetails = any, U extends ValueType = any> {
  private readonly _value: DataRequestValue;

  constructor() {
    this._value = {
      type_details: { textarea: false, rich_text: false },
      value_definition_id: 'vdId',
      id: 'vId',
      name: 'name',
      type: ValueDefinitionType.text,
      position: 1,
      size: ValueDefinitionSize.medium,
      newline: false,
      required: false,
      value_definition_group_id: 'vgd_1',
      value: '',
      label: 'field label',
      data_request_value_group_id: 'dr_vg_1',
      consolidation_rule: DefaultConsolidationRule,
    };
  }

  id(id: string): DataRequestValueBuilder<T, U> {
    this._value.id = id;
    return this;
  }

  label(label: string): DataRequestValueBuilder<T, U> {
    this._value.label = label;
    return this;
  }

  type(type: ValueDefinitionType): DataRequestValueBuilder<T, U> {
    this._value.type = type;
    return this;
  }

  required(required: boolean): DataRequestValueBuilder<T, U> {
    this._value.required = required;
    return this;
  }

  size(size: ValueDefinitionSize): DataRequestValueBuilder<T, U> {
    this._value.size = size;
    return this;
  }

  hint(hint: string): DataRequestValueBuilder<T, U> {
    this._value.hint = hint;
    return this;
  }

  typeDetails(typeDetails: T): DataRequestValueBuilder<T, U> {
    this._value.type_details = typeDetails;
    return this;
  }

  value(value: U): DataRequestValueBuilder<T, U> {
    this._value.value = value;
    return this;
  }

  valueDefinitionId(valueDefId: string): DataRequestValueBuilder<T, U> {
    this._value.value_definition_id = valueDefId;
    return this;
  }

  valueDefinitionGroupId(valueDefGroupId: string): DataRequestValueBuilder<T, U> {
    this._value.value_definition_group_id = valueDefGroupId;
    return this;
  }

  position(position: number): DataRequestValueBuilder<T, U> {
    this._value.position = position;
    return this;
  }

  conditionalTriggers(triggers: ConditionalTrigger[]): DataRequestValueBuilder<T, U> {
    this._value.conditional_triggers = triggers;
    return this;
  }

  created(created: Date): DataRequestValueBuilder<T, U> {
    this._value.created = created;
    return this;
  }

  valueRequestGroupId(valueRequestGroupId: string): DataRequestValueBuilder<T, U> {
    this._value.value_definition_group_id = valueRequestGroupId;
    return this;
  }

  validators(validators: Validator[]): DataRequestValueBuilder<T, U> {
    this._value.validators = validators;
    return this;
  }

  unit(unit: string): DataRequestValueBuilder<T, U> {
    this._value.unit = unit;
    return this;
  }

  consolidationRule(consolidationRule: ConsolidationRules): DataRequestValueBuilder<T, U> {
    this._value.consolidation_rule = consolidationRule;
    return this;
  }

  consolidationTrigger(consolidationTrigger: ConsolidationTriggers): DataRequestValueBuilder<T, U> {
    this._value.consolidation_trigger = consolidationTrigger;
    return this;
  }

  build(): Value<T, U> {
    return this._value as Value<T, U>;
  }
}
