<div class="caption3 color-grey-800 mt-1 mb-4">{{ "Displayed values" | translate }}</div>
<ng-container *ngIf="displayedValuesLoading$ | async">
  <lib-dummy [presentation]="ePresentation.card" data-testid="dummy-matTable"></lib-dummy>
</ng-container>
<ng-container *ngIf="displayedValues$ | async as displayedValues">
  <ng-container *ngIf="(displayedValuesLoading$ | async) === false">
    <ng-container *ngIf="showAllDisplayedValues$ | async">
      <li>{{ "All values" | translate }}</li>
    </ng-container>
    <ng-container *ngIf="(showAllDisplayedValues$ | async) === false">
      <div *ngFor="let dv of displayedValues">
        <li
          [ngClass]="{ 'color-error': dv.overridden_label }"
          *ngIf="
            ![eValueDefinitionDisplayType.subtitle, eValueDefinitionDisplayType.tip].includes(dv.type) && dv.is_checked
          "
        >
          {{ dv.overridden_label || dv.label }}
        </li>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<div class="mt-6">
  <div class="medium">
    <a class="btn btn-stroked" (click)="launchEditValuesDialog()">
      <mat-icon class="icon-md mr-1" svgIcon="show"></mat-icon><span>{{ "Edit displayed values" | translate }}</span>
    </a>
  </div>
</div>

<mat-divider class="mt-4"></mat-divider>
