import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { map, switchMap, takeWhile } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import {
  ApiResponse,
  ConditionalTrigger,
  ConditionalTriggerUpsertPayload,
  DialogResult,
  DialogSize,
  EmptyResults,
  Metric,
  MetricCategory,
  Presentation,
  Status,
  ValueDefinition,
  ValueDefinitionGroup,
} from '../../../models';
import { TranslateService } from '../../../services/common';

import { ConditionalTriggerAddEditDialogComponent } from '../conditional-trigger-add-edit-dialog/conditional-trigger-add-edit-dialog.component';
import { ConditionalTriggerDialogData } from '../conditional-trigger-add-edit-dialog/models';
import { ConfirmationDialogComponent, DialogsService } from '../../../dialogs';
import { MetricApiService } from '../../../services/types';
import { MetricStructureStateService } from '../../services/metric-structure-state.service';

@Component({
  selector: 'lib-metric-structure-panel-conditional-trigger',
  templateUrl: './metric-structure-panel-conditional-trigger.component.html',
  styleUrls: ['./metric-structure-panel-conditional-trigger.component.scss'],
})
export class MetricStructurePanelConditionalTriggerComponent implements OnChanges {
  @Input() metric?: Metric;
  @Input() sourceValueDefinition?: ValueDefinition;
  @Input() set valueDefinitionGroups(valueDefinitionGroups: ValueDefinitionGroup[] | undefined) {
    this._valueDefinitionGroups = valueDefinitionGroups ?? [];
  }
  get valueDefinitionGroups(): ValueDefinitionGroup[] | undefined {
    return this._valueDefinitionGroups;
  }

  @Output() updateMetric = new EventEmitter<Metric>();

  @ViewChild(MatMenuTrigger) contextMenu?: MatMenuTrigger;

  readonly ePresentation = Presentation;
  emptyResults: EmptyResults = {
    title: this.translateService.instant('No conditional triggers'),
    subtitle: this.translateService.instant('Click the button below to add your first conditional trigger'),
    image: 'laptop-neutral',
    button: this.translateService.instant('Add a trigger'),
  };

  contextMenuPosition = { x: '0px', y: '0px' };
  conditionalTriggers$: Observable<ConditionalTrigger[]> = of([]);
  disabled: boolean = false;
  private _valueDefinitionGroups: ValueDefinitionGroup[] = [];

  constructor(
    private translateService: TranslateService,
    private metricsService: MetricApiService,
    private dialogsService: DialogsService,
    private metricStructureService: MetricStructureStateService
  ) {}

  ngOnChanges(): void {
    this.fetchConditionalTriggers();
  }

  ngOnInit(): void {
    this.disabled = this.metric?.category === MetricCategory.THIRD_PARTY && !this.metricStructureService.isAdmin;
    if (this.disabled) {
      this.emptyResults.subtitle = undefined;
      this.emptyResults.button = undefined;
    }
  }

  onContextMenu(event: MouseEvent, conditionalTrigger: ConditionalTrigger): void {
    event.preventDefault();
    event.stopPropagation();
    this.contextMenuPosition.x = `${event.clientX}px`;
    this.contextMenuPosition.y = `${event.clientY}px`;
    if (this.contextMenu) {
      this.contextMenu.menuData = { item: conditionalTrigger };
      this.contextMenu.menu?.focusFirstItem('mouse');
      this.contextMenu.openMenu();
    }
  }

  public openConditionalTriggerAddEditDialog(conditionalTrigger?: ConditionalTrigger): void {
    if (this.metric && !this.disabled) {
      this.dialogsService
        .open<
          ConditionalTriggerAddEditDialogComponent,
          ConditionalTriggerDialogData,
          DialogResult<ConditionalTriggerUpsertPayload>
        >(ConditionalTriggerAddEditDialogComponent, {
          data: {
            size: DialogSize.medium,
            metricId: this.metric.id,
            sourceValueDefinition: this.sourceValueDefinition!,
            valueDefinitionGroups: this.metric.value_definition_groups ?? [],
            conditionalTrigger,
          },
        })
        .afterClosed()
        .pipe(
          takeWhile((result) => result?.status === Status.SUCCESS && result.data != null),
          map((result: DialogResult<ConditionalTriggerUpsertPayload> | undefined) => result!.data!),
          switchMap((payload: ConditionalTriggerUpsertPayload) => {
            if (conditionalTrigger) {
              return this.metricsService.updateConditionalTrigger(
                this.metric!.id,
                this.sourceValueDefinition!.value_definition_group_id,
                this.sourceValueDefinition!.id,
                conditionalTrigger.id,
                payload
              );
            }
            return this.metricsService.createConditionalTrigger(
              this.metric!.id,
              this.sourceValueDefinition!.value_definition_group_id,
              this.sourceValueDefinition!.id,
              payload
            );
          })
        )
        .subscribe((response: ApiResponse<Metric>) => {
          this.updateMetric.emit(response.data);
        });
    }
  }

  public openConditionalTriggerDeleteDialog(conditionalTrigger: ConditionalTrigger): void {
    if (this.metric) {
      const conditionalTriggerOptions = conditionalTrigger.values.join(', ');
      this.dialogsService
        .open(ConfirmationDialogComponent, {
          data: {
            title: this.translateService.instant('Delete'),
            warningMsg: this.translateService.instant(
              'Delete this conditional trigger for {conditionalTriggerOptions}. ' +
                'Where {conditionalTriggerOptions} are the options that trigger the fields. ' +
                'This action cannot be undone.',
              { conditionalTriggerOptions }
            ),
          },
        })
        .afterClosed()
        .pipe(
          takeWhile((result) => result?.status === Status.CONFIRMED),
          switchMap(() =>
            this.metricsService.deleteConditionalTrigger(
              this.metric!.id,
              this.sourceValueDefinition!.value_definition_group_id,
              this.sourceValueDefinition!.id,
              conditionalTrigger.id
            )
          )
        )
        .subscribe((response: ApiResponse<Metric>) => {
          this.updateMetric.emit(response.data);
        });
    }
  }

  private fetchConditionalTriggers(): void {
    if (this.sourceValueDefinition && this.metric?.id) {
      this.conditionalTriggers$ = this.metricsService
        .getConditionalTriggersForValueDefinition(
          this.metric.id,
          this.sourceValueDefinition.value_definition_group_id,
          this.sourceValueDefinition.id
        )
        .pipe(map((response) => response.data));
    }
  }
}
