import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, filter, tap } from 'rxjs';

import { IContextSettings } from '../../models';
import { WordEmbedderService } from '../word-embedder/word-embedder.service';

@Injectable({ providedIn: 'root' })
export class ContextSettingsService {
  public static readonly WORD_KEY = 'settings';

  private _settings = new BehaviorSubject<IContextSettings | null | undefined>(undefined);

  public readonly settings$: Observable<IContextSettings | null | undefined>;

  constructor(private readonly wordEmbedderService: WordEmbedderService) {
    this.settings$ = this._settings.asObservable().pipe(filter((settings) => settings !== undefined));
  }

  public loadSettings(): Observable<IContextSettings | null> {
    return this.wordEmbedderService.getSetting<IContextSettings>(ContextSettingsService.WORD_KEY).pipe(
      tap((settings) => {
        this.wordEmbedderService.initialize(settings);
        this._settings.next(settings || null);
      })
    );
  }

  public saveSettings(newSettings: IContextSettings): Observable<void> {
    return this.wordEmbedderService.saveSetting(ContextSettingsService.WORD_KEY, newSettings).pipe(
      tap(() => {
        this._settings.next(newSettings);
      })
    );
  }
}
