<div
  data-testid="select-input"
  *ngIf="control"
  [class]="'formfield ' + size"
  [ngClass]="{ disabled: control.disabled, required: required }"
>
  <lib-form-field-label [label]="label" [control]="labelControl || control" [id]="_labelId"></lib-form-field-label>
  <mat-form-field>
    <mat-select
      [formControl]="control"
      [aria-labelledby]="_labelId"
      [aria-label]="ariaLabel"
      (selectionChange)="onSelectionChange($event.value)"
      #focusElement
      libFocusState
    >
      <mat-option *ngIf="!required && showEmptyOption" [value]="undefined">--</mat-option>
      <mat-option
        *ngFor="let option of options"
        class="multiline-mat-option"
        [value]="option.id"
        [disabled]="option.deactivated ?? false"
        [matTooltip]="option.deactivated ? disabledOptionsTooltip : ''"
      >
        {{ option.title }}
      </mat-option>
    </mat-select>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-error *ngFor="let error of control.errors | errorKeys"> {{ errorMessages[error] }}</mat-error>
  </mat-form-field>
</div>
