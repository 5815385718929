import { DashboardValueDefinitionTargetWidget, ValueDefinitionTarget } from '../../models';
import { CreatedUpdatedBuilder } from '../created-updated-builder';
import { ValueDefinitionTargetBuilder } from '../value-definition-target-builder';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line max-len
export class DashboardValueDefinitionTargetWidgetBuilder extends CreatedUpdatedBuilder<DashboardValueDefinitionTargetWidget> {
  constructor() {
    super({
      id: uuidv4(),
      target_id: uuidv4(),
      value_definition_target: new ValueDefinitionTargetBuilder().build(),
    });
  }

  id(id: string): DashboardValueDefinitionTargetWidgetBuilder {
    this._instance.id = id;
    return this;
  }

  targetId(targetId: string): DashboardValueDefinitionTargetWidgetBuilder {
    this._instance.target_id = targetId;
    return this;
  }

  valueDefinitionTarget(valueDefinitionTarget: ValueDefinitionTarget): DashboardValueDefinitionTargetWidgetBuilder {
    this._instance.value_definition_target = valueDefinitionTarget;
    return this;
  }
}
