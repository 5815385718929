<div class="list-container" [ngClass]="containerClass">
  <ng-container *ngIf="tagAssociations">
    <div *ngIf="tagAssociations.report.count" class="list-content">
      {{ "Reports: {count}" | translate : { count: tagAssociations.report.count } }}
    </div>
    <div *ngIf="tagAssociations.metric.count" class="list-content">
      {{ "Metrics: {count}" | translate : { count: tagAssociations.metric.count } }}
    </div>
    <div *ngIf="tagAssociations.document.count" class="list-content">
      {{ "Documents: {count}" | translate : { count: tagAssociations.document.count } }}
    </div>
    <div *ngIf="tagAssociations.benchmark.count" class="list-content">
      {{ "Benchmarks: {count}" | translate : { count: tagAssociations.benchmark.count } }}
    </div>
  </ng-container>
  <ng-container *ngIf="hasNoAssociations()">
    <div class="list-content">{{ "Not used" | translate }}</div>
  </ng-container>
</div>
