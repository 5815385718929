import { StandardDocumentMetadata } from '../../documents';
import {
  Doc,
  FileDocumentInterface,
  RequestDocMetadata,
  PublicDocUploadedFileMetaData,
  DataRequestPublicDocument,
} from '../../models';

interface FileDetails {
  name: string;
  format: string;
}

export class FileUtils {
  static getFormdataFromFile(file: File): FormData {
    const formData = new FormData();
    const fileDetails = FileUtils.getFileDetails(file);
    formData.append('name', fileDetails.name);
    formData.append('document', file, file.name);
    return formData;
  }

  static getFileDetails(file: File): FileDetails {
    const fileDetails = file.name.split(' ').join('_').split('.');
    return { name: fileDetails[0], format: fileDetails[1] };
  }

  static convertDocsToFileDocuments(docs: Doc[]): FileDocumentInterface[] {
    return docs.map((doc) => ({ id: doc.id, format: doc.format, name: doc.name, doc }));
  }

  static convertRequestDocsToFileDocuments(docs: RequestDocMetadata[]): FileDocumentInterface[] {
    return docs.map((doc) => ({ id: doc.id, format: doc.extension, name: doc.name, doc }));
  }

  static convertDataRequestPublicDocumentToFileDocuments(docs: DataRequestPublicDocument[]): FileDocumentInterface[] {
    return docs.map((doc) => ({ id: doc.id, format: doc.extension, name: doc.meta.name, doc: doc.meta }));
  }

  static convertPublicDocUploadedFileMetadataToFileDocuments(
    docs: PublicDocUploadedFileMetaData[]
  ): FileDocumentInterface[] {
    return docs.map((doc) => ({ id: doc.id, format: doc.extension, name: doc.meta.name }));
  }

  static getFileDocumentFromIdAndFileDetails(docId: string, fileDetails: FileDetails): FileDocumentInterface {
    return { id: docId, name: fileDetails.name, format: fileDetails.format };
  }

  static convertStandardDocumentMetadataToFileDocuments(docs: StandardDocumentMetadata[]): FileDocumentInterface[] {
    return docs.map((doc) => ({ id: doc.id, format: doc.extension ?? '', name: doc.name, doc }));
  }

  static getDocumentStorageFileName(doc: FileDocumentInterface): string {
    return `${doc.id}.${doc.format}`;
  }
}
