import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { SearchService } from '../../../../search';
import {
  ActionItem,
  DialogResult,
  Doc,
  EmptyResults,
  ItemType,
  Layout,
  Presentation,
  Status,
} from '../../../../models';
import { Icon } from '../../../../icons/icons.model';
import { FileExtensions } from '../../../../translations';
import { TranslateService } from '../../../../services/common';

export interface MetricEditorAttachFileDialogConfig {
  file?: File;
}
export interface MetricEditorAttachFileDialogData {
  formData?: FormData;
  doc?: Doc;
}

@Component({
  selector: 'lib-metric-editor-attach-file-dialog',
  templateUrl: './metric-editor-attach-file-dialog.component.html',
  styleUrls: ['./metric-editor-attach-file-dialog.component.scss'],
})
export class MetricEditorAttachFileDialogComponent implements OnInit {
  loading: boolean = false;

  readonly emptyResults: EmptyResults = {
    title: this.translateService.instant('No documents'),
    subtitle: '',
    image: 'laptop-neutral',
  };

  readonly ePresentation = Presentation;
  readonly eLayout = Layout;

  // My documents
  searchProperties = { search_type: 'name' };
  selectedDoc?: Doc;

  // My computer
  fileDropped: boolean = false;
  file?: File;
  fileIcon: Icon = '';
  fileIconKey: string = '';
  itemTypeDoc: ItemType = ItemType.docs_doc;

  tabs: ActionItem[] = [
    {
      id: 'document-tab',
      title: this.translateService.instant('From my documents'),
    },
    {
      id: 'computer-tab',
      title: this.translateService.instant('From my computer'),
    },
  ];
  defaultTab = this.tabs[0];
  selectedtab: ActionItem = this.tabs[0];

  constructor(
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<
      MetricEditorAttachFileDialogComponent,
      DialogResult<MetricEditorAttachFileDialogData>
    >,
    @Inject(MAT_DIALOG_DATA) public dialogData: MetricEditorAttachFileDialogConfig,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    if (this.dialogData.file) {
      this.selectedtab = this.defaultTab = this.tabs[1];
      this.handleFile(this.dialogData.file);
    }
  }

  public handleSearchAction(action: ActionItem): void {
    this.selectedDoc = action.item.item;
  }

  public switchTab(tab: ActionItem): void {
    this.selectedtab = tab;
    this.clearAll();
  }

  public selectFileFromBrowse(event: Event): void {
    this.handleFile((event.target as HTMLInputElement).files![0], event);
  }

  public dropFile(event: DragEvent): void {
    if (event.dataTransfer) {
      this.handleFile(event.dataTransfer.files[0], event);
    }
  }

  private handleFile(file: File, event?: Event): void {
    if (event) {
      this.handleDragDropEvent(event);
    }
    this.file = file;
    this.setFileIcon();
    this.fileDropped = true;
  }

  public handleDragDropEvent(event: Event): void {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  private setFileIcon(): void {
    const fileExtension = this.file?.name.split('.').pop()?.toLowerCase();
    this.fileIcon = fileExtension ? this.searchService.getFileFormatIcon(fileExtension) : '';

    this.fileIconKey = Object.entries(FileExtensions).find((entry) => entry[0] === this.fileIcon)?.[1] ?? '';
  }

  public clearAll(): void {
    this.file = undefined;
    this.fileDropped = false;
    this.fileIcon = '';
    this.selectedDoc = undefined;
  }

  public attachFile(): void {
    if (this.selectedDoc) {
      this.dialogRef.close({ status: Status.SUCCESS, data: { doc: this.selectedDoc } });
    } else if (this.file != undefined) {
      const formData = new FormData();
      formData.append('document', this.file, this.file.name);
      formData.append('name', this.file.name.split(' ').join('_').split('.')[0]);
      formData.append('category', 'Metric Attachment');
      this.dialogRef.close({ status: Status.SUCCESS, data: { formData } });
    } else {
      this.close();
    }
  }

  public close(): void {
    this.dialogRef.close({ status: Status.CANCEL });
  }
}
