import { Component, Input } from '@angular/core';
import { Presentation } from '../../models';

@Component({
  selector: 'lib-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss'],
})
export class FormSectionComponent {
  @Input()
  title: string = '';

  @Input()
  withBottomSeparator: boolean = false;

  @Input()
  withTopSeparator: boolean = false;

  @Input()
  loading: boolean = false;

  readonly ePresentation = Presentation;
}
