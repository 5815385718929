import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkModule } from '../material/cdk.module';
import { MaterialModule } from '../material/material.module';
import { ComponentsModule } from '../components';
import { PipesModule } from '../pipes';
import { DirectivesModule } from '../directives';
import { TranslateModule } from '@ngx-translate/core';
import { SearchComponent } from './search/search.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchItemsComponent } from './search-items/search-items.component';
import { SearchTreeItemsComponent } from './search-tree-items/search-tree-items.component';
import { EmptyResultsComponent } from './empty-results/empty-results.component';
import { FilterComponent } from './filters/filter/filter.component';
import { FilterYearsComponent } from './filters/filter-years/filter-years.component';
import { FilterSearchComponent } from './filters/filter-search/filter-search.component';
import { RouterModule } from '@angular/router';
import { FilterBarSearchComponent } from './filter-bar/filter-bar-search/filter-bar-search.component';
import { FilterBarMenuComponent } from './filter-bar/filter-bar-menu/filter-bar-menu.component';
import { FilterBarComponent } from './filter-bar/filter-bar.component';
import { BaseSearchItemsDirective } from './base-search-items.directive';
import { PrimaryFilterComponent } from './filters/primary-filter/primary-filter.component';
import { SearchBarStandardCodeMenuComponent } from './search-bar/search-bar-standard-code-menu/search-bar-standard-code-menu.component';
import { SearchBarMenuItemComponent } from './search-bar/search-bar-menu-item/search-bar-menu-item.component';
import { FilterButtonComponent } from './filters/filter-button/filter-button.component';
import { MultiSelectFilterComponent } from './filters/multi-select-filter/multi-select-filter.component';

@NgModule({
  declarations: [
    SearchComponent,
    SearchBarComponent,
    SearchBarStandardCodeMenuComponent,
    SearchItemsComponent,
    SearchTreeItemsComponent,
    EmptyResultsComponent,
    FilterComponent,
    PrimaryFilterComponent,
    MultiSelectFilterComponent,
    FilterYearsComponent,
    FilterSearchComponent,
    FilterBarMenuComponent,
    FilterBarComponent,
    FilterBarSearchComponent,
    BaseSearchItemsDirective,
    SearchBarMenuItemComponent,
    FilterButtonComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CdkModule,
    MaterialModule,
    PipesModule,
    DirectivesModule,
    TranslateModule,
    RouterModule,
    ComponentsModule,
  ],
  exports: [
    SearchComponent,
    SearchBarComponent,
    SearchItemsComponent,
    SearchTreeItemsComponent,
    EmptyResultsComponent,
    FilterComponent,
    FilterYearsComponent,
    FilterBarComponent,
    FilterSearchComponent,
    FilterBarMenuComponent,
    FilterBarSearchComponent,
  ],
})
export class SearchModule {}
