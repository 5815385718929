import { Icon } from '../icons/icons.model';
import { ActionItem } from '../models';

export interface ButtonProps {
  title?: string;
  icon?: Icon;
  disabled?: boolean;
  active?: boolean;
}

export interface OptionProps {
  id?: string;
  title: string;
  activeOption?: ActionItem;
  options?: ActionItem[];
  defaultValue?: ActionItem;
  defaultValueTitle?: string;
  multiSelectDefaultValues?: string[];
}

export interface SearchableOptionProps extends OptionProps {
  minMenuScrollItems?: number;
}

export enum SearchResultsActionItem {
  preview = 'preview',
  details = 'details',
  edit = 'edit',
  separator = 'separator',
  delete = 'delete',
  copy = 'copy',
  review = 'review',
  share = 'share',
  deactivate = 'deactivate',
  new_draft = 'new_draft',
  complete = 'complete',
  archive = 'archive',
  unarchive = 'unarchive',
  template = 'template',
  moveUp = 'moveUp',
  moveDown = 'moveDown',
  export = 'export',
  activate = 'activate',
}
