import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';

import { CreatedUpdatedBuilder } from './created-updated-builder';
import { ReportStatusEnum, ReportStatusTransition } from '../models';

export class ReportStatusTransitionBuilder extends CreatedUpdatedBuilder<ReportStatusTransition> {
  constructor() {
    super({
      id: uuidv4(),
      comment: faker.lorem.paragraph(),
      from_status: undefined,
      report_id: uuidv4(),
      to_status: ReportStatusEnum.draft,
    });
  }

  public comment(comment: string): ReportStatusTransitionBuilder {
    this._instance.comment = comment;
    return this;
  }

  public from_status(from_status: ReportStatusEnum): ReportStatusTransitionBuilder {
    this._instance.from_status = from_status;
    return this;
  }

  public id(id: string): ReportStatusTransitionBuilder {
    this._instance.id = id;
    return this;
  }

  public report_id(report_id: string): ReportStatusTransitionBuilder {
    this._instance.report_id = report_id;
    return this;
  }

  public to_status(to_status: ReportStatusEnum): ReportStatusTransitionBuilder {
    this._instance.to_status = to_status;
    return this;
  }
}
