import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'lib-expandable-text',
  templateUrl: './expandable-text.component.html',
  styleUrls: ['./expandable-text.component.scss'],
})
export class ExpandableTextComponent implements AfterViewInit, OnInit {
  @Input() text: string = '';
  @Input() numberOfLineToShow: number = 4; // only applicable if isHtmlText = true
  @Input() charactersToShow: number = 100; // only applicable if isHtmlText = false
  @Input() isHtmlText: boolean = true;

  @ViewChild('textContent') set textContent(textContentRef: ElementRef | undefined) {
    if (textContentRef) {
      this.textContentRef = textContentRef;
    }
  }

  readMore: boolean = false;
  showExpandable: boolean = true;
  lineMaxHeight: string = '64px';
  textContentRef?: ElementRef;

  readonly lineHeight: number = 16;

  constructor(private cdr: ChangeDetectorRef) {}

  private isStartingWithList(text: string) {
    return text.startsWith('<ol>') || text.startsWith('<ul>');
  }

  ngOnInit(): void {
    if (this.isStartingWithList(this.text) && this.numberOfLineToShow === 1) {
      this.numberOfLineToShow = 2;
    }
    this.lineMaxHeight = `${this.numberOfLineToShow * this.lineHeight}px`;
  }

  ngAfterViewInit(): void {
    if (!this.textContentRef) {
      return;
    }

    const nativeElement = this.textContentRef?.nativeElement;
    this.showExpandable = nativeElement ? nativeElement.offsetHeight < nativeElement.firstChild?.offsetHeight : false;

    this.cdr.detectChanges();
  }
}
