import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { MetricTableGroup } from '../../../models';

type FormGroupMdel = { [key: string]: AbstractControl };
interface MetricTableFormModel extends FormGroupMdel {
  tableTitleControl: FormControl<string>;
  tableToggleActiveControl: FormControl<boolean>;
}

export class MetricTableForm extends FormGroup<MetricTableFormModel> {
  constructor(metricTableGroup?: MetricTableGroup, readonly fb = new FormBuilder()) {
    super({
      tableTitleControl: fb.control(metricTableGroup?.title ?? '', {
        nonNullable: true,
        validators: [Validators.maxLength(50), Validators.required],
      }),
      tableToggleActiveControl: fb.control(false, { nonNullable: true }),
    });
  }
}
