import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '../../../services/common';

@Component({
  selector: 'lib-rich-text-card',
  templateUrl: './rich-text-card.component.html',
  styleUrls: ['./rich-text-card.component.scss'],
})
export class RichTextCardComponent implements OnInit {
  @Input() selected: boolean = false;
  @Input() control?: UntypedFormControl;
  @Input() label: string = '';
  @Input() id: string = '';
  @Input() placeholder?: string = this.translateService.instant('Type message here...');

  @Output() onSelect = new EventEmitter<string>();
  @Output() onBlur = new EventEmitter<string>();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  select() {
    this.onSelect.emit(this.id);
  }

  blur() {
    this.onBlur.emit(this.id);
  }
}
