import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ActionItem } from '../../../models';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, EventsService } from '../../../services/common';

@Component({
  selector: 'lib-panel-details-items',
  templateUrl: './panel-details-items.component.html',
  styleUrls: ['./panel-details-items.component.scss'],
})
export class PanelDetailsItemsComponent implements OnDestroy, AfterViewInit {
  private readonly PANEL_OPENING_ANIMATION_IN_MS: number = 300;

  _panelActions: ActionItem[] = [];

  @Input()
  set panelActions(actions: ActionItem[]) {
    this._panelActions = actions;
    this.selectedAction = actions[0];
  }

  @Input() loaded: boolean = false;
  @Input() activeTab: number = 0;
  @Input() navigateBackOnPanelClose: boolean = true;

  @Output() activeTabChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() isOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

  selectedAction: ActionItem | undefined;
  refreshPage: boolean = false;

  constructor(
    protected translateService: TranslateService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected eventsService: EventsService
  ) {}

  ngOnDestroy(): void {
    this.eventsService.unselectItem();
    this.eventsService.setMessage('refresh', '');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isOpened.emit(true);
    }, this.PANEL_OPENING_ANIMATION_IN_MS);
  }

  close(): void {
    if (this.navigateBackOnPanelClose) {
      this.router.navigate(['../'], {
        relativeTo: this.route,
        queryParamsHandling: 'preserve',
        state: this.refreshPage ? { action: 'refresh' } : undefined,
      });
    }
    this.isOpened.emit(false);
  }

  applyAction(action: ActionItem): void {
    this.selectedAction = action;
    this.activeTab = +action.id;
    this.activeTabChange.emit(this.activeTab);
  }
}
