import { AddOnConfig, Platforms, SupportedEnvironment } from '../app/shared/models';
import info from '../assets/info.json';
import { sharedApiConfig } from './shared_environment_config';

export const environment: AddOnConfig = {
  production: false,
  version: info.version,
  date: info.date,
  pageSize: 24,
  platform: Platforms.ADD_IN,
  apiConfig: { ...sharedApiConfig, env: SupportedEnvironment.qa, clientId: '6213caee-0a7e-4697-9e1d-9828eb028fb7' },
};
