import { UpsertValue } from '../metric-editor-form';

export class UpsertValueBuilder {
  private readonly _upsertValue: UpsertValue;

  constructor() {
    this._upsertValue = {
      value_definition_id: 'vdId',
    };
  }

  id(id: string): UpsertValueBuilder {
    this._upsertValue.id = id;
    return this;
  }

  valueDefinitionId(vdId: string): UpsertValueBuilder {
    this._upsertValue.value_definition_id = vdId;
    return this;
  }

  value(value: unknown): UpsertValueBuilder {
    this._upsertValue.value = value;
    return this;
  }

  build(): UpsertValue {
    return this._upsertValue;
  }
}
