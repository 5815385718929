import { MinimalIndicator } from '../models';

export class MinimalIndicatorBuilder {
  private readonly _minimalIndicator: MinimalIndicator;

  constructor() {
    this._minimalIndicator = {
      code: 'min_ind_code',
      id: 'minIndId',
      description: 'Minimal Indicator description',
      metric_id: 'minMetricId',
    };
  }

  id(id: string): MinimalIndicatorBuilder {
    this._minimalIndicator.id = id;
    return this;
  }

  description(description: string): MinimalIndicatorBuilder {
    this._minimalIndicator.description = description;
    return this;
  }

  code(code: string): MinimalIndicatorBuilder {
    this._minimalIndicator.code = code;
    return this;
  }

  metricId(metricId: string): MinimalIndicatorBuilder {
    this._minimalIndicator.metric_id = metricId;
    return this;
  }

  build(): MinimalIndicator {
    return this._minimalIndicator;
  }
}
