import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common/api/api.service';
import { ApiResponse, ApplicationApiDefinition, Org, SearchOptions } from '../../../models';
import { BaseClientPeersService } from './base-client-peers.service';

@Injectable({
  providedIn: 'root',
})
export class ClientPeersService extends BaseClientPeersService {
  apiName: keyof ApplicationApiDefinition = 'platform';
  resource: string;
  servicePath: string;

  constructor(private baseService: BaseService, private apiService: ApiService) {
    super();

    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.platform.resources.global;
  }

  search(searchOptions: SearchOptions): Observable<ApiResponse<Org[]>> {
    return this.listFavoriteOrgs(searchOptions.filters?.industry?.id, searchOptions.query?.keywords);
  }

  listFavoriteOrgs(industry_id?: string, name?: string): Observable<ApiResponse<Org[]>> {
    let params = new HttpParams();
    if (industry_id) {
      params = params.append('industry_ids', industry_id);
    }
    if (name) {
      params = params.append('search_term', name);
    }

    return this.apiService.get(`${this.servicePath}${this.resource}/favorite_orgs`, { params });
  }

  public getFavoriteOrg(org_id: string) {
    return this.apiService.get(`${this.servicePath}${this.resource}/favorite_orgs/${org_id}`);
  }

  public addFavoriteOrg(org_id: string): Observable<ApiResponse<Org[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/favorite_orgs/${org_id}`, null);
  }

  public removeFavoriteOrg(org_id: string): Observable<ApiResponse<Org[]>> {
    return this.apiService.delete(`${this.servicePath}${this.resource}/favorite_orgs/${org_id}`);
  }
}
