import { Indicator, Metric, MinimalIndicator } from '../metrics';

export enum MetricGroupStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export interface MetricGroup {
  id: string;
  name: string;
  description: string;
  metrics_count?: number;
  metrics?: MetricGroupMetric[] | null;
  active: boolean;
}

export interface MetricGroupsFilteringOptions {
  search_term?: string;
  offset?: number;
  limit?: number;
  active?: boolean | null;
}

export interface MetricGroupRequest {
  name: string;
  description?: string;
}

export interface MetricGroupNameCheck {
  available: boolean;
}

export interface MetricGroupMetric {
  id: string;
  position: number;
  metric_id: string;
  indicator_id: string;
  metric_group_id: string;
}

export interface MetricGroupMetricWithMinimalIndicator extends MetricGroupMetric {
  indicator: MinimalIndicator;
}
