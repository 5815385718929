<ul class="pl-4 square-bullets" *ngIf="value?.value?.length">
  <li *ngFor="let fileValue of value?.value; let isLast = last" [ngClass]="{ 'mb-1': !isLast }">
    <button
      lib-button
      type="link"
      [attr.data-testid]="'fileV2List-' + fileValue.file_id"
      *ngIf="documents | getItemById : fileValue.file_id as docInfo"
      [innerHTML]="docInfo.name + '.' + docInfo.format | sanitizeHtml"
      (click)="downloadFile.emit(docInfo)"
    ></button>
    <ul>
      <li *ngIf="fileValue.url">{{ "URL: {fileUrl}" | translate : { fileUrl: fileValue?.url } | truncate : 40 }}</li>
      <li *ngIf="fileValue.page_number">
        {{ "Page number: {pageNumber}" | translate : { pageNumber: fileValue.page_number } }}
      </li>
      <li *ngIf="fileValue.explanation">
        {{ value?.type_details.explanation_label || (DEFAULT_EXPLANATION_LABEL | translate) }}:
        {{ fileValue.explanation }}
      </li>
    </ul>
  </li>
</ul>
