import { TableGroup, ValueGroup } from '../models';

export class TableGroupBuilder {
  private readonly _tableGroup: TableGroup;

  constructor() {
    this._tableGroup = {
      id: '',
      indicator_id: '',
      position: 0,
      table_id: '',
      valueGroups: [],
    };
  }

  id(id: string): TableGroupBuilder {
    this._tableGroup.id = id;
    return this;
  }

  indicator_id(indicator_id: string): TableGroupBuilder {
    this._tableGroup.indicator_id = indicator_id;
    return this;
  }

  position(position: number): TableGroupBuilder {
    this._tableGroup.position = position;
    return this;
  }

  valueGroups(valueGroups: ValueGroup[]): TableGroupBuilder {
    this._tableGroup.valueGroups = valueGroups;
    return this;
  }

  table_id(table_id: string): TableGroupBuilder {
    this._tableGroup.table_id = table_id;
    return this;
  }

  build(): TableGroup {
    return this._tableGroup;
  }
}
