<section class="section section-primary" *libPermission="allPermissions">
  <div class="section-header">
    <h4 class="mat-h4 color-grey-900">{{ title }}</h4>
    <lib-link class="mat-body-2" [routerLink]="link" [text]="linkText"></lib-link>
  </div>
  <div class="grid grid-6">
    <ng-content></ng-content>
    <ng-container *ngFor="let item of items">
      <ng-container *libPermission="item.permissions">
        <lib-card-primary
          *libFeatureFlag="{ featureFlags: item.featureFlag, hiddenWhenEnabled: item.featureFlagHiddenWhenEnabled }"
          [item]="item"
        ></lib-card-primary>
      </ng-container>
    </ng-container>
  </div>
</section>
