import { FileValue } from '../models';

export class FileValueBuilder {
  private readonly _fileValue: FileValue;

  constructor() {
    this._fileValue = {
      file_id: 'documentId',
    };
  }

  fileId(fileId: string): FileValueBuilder {
    this._fileValue.file_id = fileId;
    return this;
  }

  explanation(explanation: string): FileValueBuilder {
    this._fileValue.explanation = explanation;
    return this;
  }

  pageNumber(pageNum: number): FileValueBuilder {
    this._fileValue.page_number = pageNum;
    return this;
  }

  url(url: string): FileValueBuilder {
    this._fileValue.url = url;
    return this;
  }

  build(): FileValue {
    return this._fileValue;
  }
}
