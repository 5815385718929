import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';
import {
  BaseTemplateReport,
  DisplayedValue,
  TemplateReport,
  TemplateReportSection,
  TemplateReportSectionMetric,
  TemplateReportVersion,
} from '../../models';
import { TemplateReportsApiService } from '../../services/api-services';
import { EventsService } from '../../services/common';
import { switchMap, tap } from 'rxjs/operators';
import { ObservableUtils } from '../../classes';
import { DisplayedValuePayload } from '../../models/displayed_value';

@Injectable()
export class TemplateReportStructureStateService {
  private _baseTemplateReport$: BehaviorSubject<BaseTemplateReport | undefined> = new BehaviorSubject<
    BaseTemplateReport | undefined
  >(undefined);
  readonly baseTemplateReport$: Observable<BaseTemplateReport | undefined>;

  private _templateReportSections$: BehaviorSubject<TemplateReportSection[] | undefined> = new BehaviorSubject<
    TemplateReportSection[] | undefined
  >(undefined);

  readonly templateReportSections$: Observable<TemplateReportSection[] | undefined>;

  private _templateReportId$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  readonly templateReportId$: Observable<string | undefined>;

  private _templateReportVersionId$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(
    undefined
  );
  readonly templateReportVersionId$: Observable<string | undefined>;

  private _templateReportSectionsLabelById$: BehaviorSubject<Map<string, TemplateReportSection>> = new BehaviorSubject<
    Map<string, TemplateReportSection>
  >(new Map<string, TemplateReportSection>());
  readonly templateReportSectionsLabelById$: Observable<Map<string, TemplateReportSection>>;

  public templateReport$: Observable<TemplateReport>;

  private _displayedValues$: BehaviorSubject<DisplayedValue[] | undefined> = new BehaviorSubject<
    DisplayedValue[] | undefined
  >(undefined);
  public displayedValues$: Observable<DisplayedValue[] | undefined>;

  private _showAllDisplayedValues$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showAllDisplayedValues$: Observable<boolean | undefined>;

  private _displayedValuesLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public displayedValuesLoading$: Observable<boolean>;
  constructor(
    private readonly templateReportsApiService: TemplateReportsApiService,
    private readonly eventsService: EventsService
  ) {
    this.baseTemplateReport$ = this._baseTemplateReport$.asObservable();
    this.templateReportSections$ = this._templateReportSections$.asObservable();
    this.templateReportId$ = this._templateReportId$.asObservable();
    this.templateReportVersionId$ = this._templateReportVersionId$.asObservable();
    this.templateReportSectionsLabelById$ = this._templateReportSectionsLabelById$.asObservable();
    this.displayedValues$ = this._displayedValues$.asObservable();
    this.showAllDisplayedValues$ = this._showAllDisplayedValues$.asObservable();
    this.displayedValuesLoading$ = this._displayedValuesLoading.asObservable();
    this.eventsService.unselectItems();
    this.templateReport$ = this.templateReportId$.pipe(
      distinctUntilChanged(),
      ObservableUtils.filterNullish(),
      switchMap((templateReportId: string) =>
        this.templateReportsApiService
          .getTemplateReport(templateReportId, {
            loadFrameworks: true,
            loadTags: false,
            loadTopics: false,
            loadIndustries: false,
          })
          .pipe(map((response) => response.data))
      )
    );
  }

  public updateTemplateReport(templateReport: BaseTemplateReport | TemplateReportVersion | undefined): void {
    this._baseTemplateReport$.next(templateReport);
    this._templateReportId$.next(
      templateReport && 'template_report_id' in templateReport ? templateReport.template_report_id : templateReport?.id
    );
    this._templateReportVersionId$.next(
      templateReport && 'template_report_id' in templateReport ? templateReport.id : undefined
    );
  }

  public loadTemplateReportSections(): void {
    const templateReportId = this._templateReportId$.getValue();
    const templateReportVersionId = this._templateReportVersionId$.getValue();

    if (templateReportId) {
      this.templateReportsApiService
        .getTemplateReportSections(templateReportId, templateReportVersionId)
        .pipe(map((response) => response.data))
        .subscribe((templateReportSections) => {
          this.updateTemplateReportSections(templateReportSections);
        });
    }
  }

  public updateTemplateReportSections(templateReportSections: TemplateReportSection[]): void {
    this._templateReportSections$.next(templateReportSections);
    const sectionsLabelById = this.formatSectionsLabelById(templateReportSections);
    this._templateReportSectionsLabelById$.next(sectionsLabelById);
    this.updateTemplateReportMetricsList(sectionsLabelById);
  }

  public updateSectionMetrics(updatedSections: TemplateReportSection[], templateReportSectionId: string): void {
    const currentSections: TemplateReportSection[] = this._templateReportSections$.getValue() ?? [];
    const currentSection = this.getSectionFromId(currentSections, templateReportSectionId);
    const updatedSection = this.getSectionFromId(updatedSections, templateReportSectionId);
    if (currentSection && updatedSection) {
      currentSection.metrics = updatedSection.metrics;
    }
    this.updateTemplateReportSections(currentSections);
  }

  public loadDisplayedValues(templateId: string, templateReportVersionId: string, sectionId: string, metricId: string) {
    this._displayedValuesLoading.next(true);
    this.templateReportsApiService
      .getDisplayedValues(templateId, templateReportVersionId, sectionId, metricId)
      .pipe(map((response) => response.data))
      .subscribe((data: DisplayedValue[]) => {
        this._displayedValues$.next(data);
        this.isShowAllDisplayedValues(data);
        this._displayedValuesLoading.next(false);
      });
  }

  private isShowAllDisplayedValues(data: DisplayedValue[]) {
    let showAll = true;
    data.forEach((dv) => {
      if (!dv.is_checked || dv.overridden_label) {
        showAll = false;
      }
    });
    this._showAllDisplayedValues$.next(showAll);
  }

  private updateTemplateReportMetricsList(sectionsLabelById: Map<string, TemplateReportSection>): void {
    this.eventsService.selectItems(
      Array.from(sectionsLabelById.values())
        .flatMap((section) => section.metrics)
        .map((metric) => metric.id)
    );
  }

  public addSection(newSection: TemplateReportSection): void {
    const sections: TemplateReportSection[] = this._templateReportSections$.getValue() ?? [];
    if (newSection.parent_id) {
      const parentSection = this.getSectionFromId(sections, newSection.parent_id);
      if (parentSection) {
        parentSection.children?.push(newSection);
      }
    } else {
      sections.push(newSection);
    }

    this.updateTemplateReportSections(sections);
  }

  public editSection(updatedSection: TemplateReportSection): void {
    const sections: TemplateReportSection[] = this._templateReportSections$.getValue() ?? [];
    const section = this.getSectionFromId(sections, updatedSection.id);

    if (!section) {
      return;
    }

    section.code = updatedSection.code;
    section.label = updatedSection.label;
    section.position = updatedSection.position;
    section.display = updatedSection.display;

    if (updatedSection.parent_id !== section.parent_id) {
      const newParentSectionChildren = updatedSection.parent_id
        ? this.getSectionFromId(sections, updatedSection.parent_id)?.children
        : sections;

      const oldParentSectionChildren = section.parent_id
        ? this.getSectionFromId(sections, section.parent_id)?.children
        : sections;

      section.parent_id = updatedSection.parent_id;

      newParentSectionChildren?.push(section);

      const parentSectionChildrenIndex = oldParentSectionChildren?.findIndex((children) => section.id === children.id);

      if (parentSectionChildrenIndex != null && parentSectionChildrenIndex > -1) {
        oldParentSectionChildren?.splice(parentSectionChildrenIndex, 1);
      }
    }

    this.updateTemplateReportSections(sections);
  }

  public addMetricOnSection(
    newMetric: TemplateReportSectionMetric,
    newSectionId: string,
    metricId: string,
    oldSectionId?: string
  ): void {
    const sections: TemplateReportSection[] = this._templateReportSections$.getValue() ?? [];
    const section = this.getSectionFromId(sections, newMetric.section.id);
    const sectionForNewMetric = this.getSectionFromId(sections, newSectionId);

    if (oldSectionId && section) {
      const oldSection = this.getSectionFromId(sections, oldSectionId);

      if (oldSection) {
        oldSection.metrics = oldSection.metrics.filter((metric) => metric.id !== metricId);
      }

      sectionForNewMetric?.metrics.push(newMetric.metric);
    }

    if (newMetric.metric.id && !oldSectionId) {
      section?.metrics.push(newMetric.metric);
    }

    this.updateTemplateReportSections(sections);
  }

  private formatSectionsLabelById(
    templateReportSections: TemplateReportSection[],
    sectionsLabelById: Map<string, TemplateReportSection> = new Map<string, TemplateReportSection>()
  ): Map<string, TemplateReportSection> {
    for (const section of templateReportSections) {
      sectionsLabelById.set(section.id, section);
      if (section.children) {
        this.formatSectionsLabelById(section.children, sectionsLabelById);
      }
    }
    return sectionsLabelById;
  }

  private getSectionFromId(sections: TemplateReportSection[], sectionId: string): TemplateReportSection | undefined {
    for (const section of sections) {
      if (section.id === sectionId) {
        return section;
      } else {
        const sectionFromId = this.getSectionFromId(section.children ?? [], sectionId);
        if (sectionFromId) {
          return sectionFromId;
        }
      }
    }
    return undefined;
  }

  public saveDisplayedValues(
    data: DisplayedValuePayload[],
    templateId: string,
    versionId: string,
    sectionId: string,
    metricId: string
  ) {
    return this.templateReportsApiService.postDisplayedValues(data, templateId, versionId, sectionId, metricId).pipe(
      map((response) => response.data),
      tap((data: DisplayedValue[]) => {
        this._displayedValues$.next(data);
        this.isShowAllDisplayedValues(data);
      })
    );
  }
}
