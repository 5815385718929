import { TemplateRef } from '@angular/core';

export interface TabStepperStep {
  content: TemplateRef<undefined>;
  title: string;
  disabled?: boolean;
}

export interface StepperStep {
  content: TemplateRef<undefined>;
  title?: string;
  customLabel?: TemplateRef<undefined>;
  disabled?: boolean;
  lazyLoading?: boolean;
}
