import { TemplateReportVersion } from '../models';
import { v4 as uuidv4 } from 'uuid';

export class TemplateReportVersionBuilder {
  private readonly _templateReportVersion: TemplateReportVersion;

  constructor() {
    this._templateReportVersion = {
      id: uuidv4(),
      version: 'Final version 2.0',
      version_number: 1,
      active: true,
      sections: [],
      template_report_id: 'templateReportId',
      start: '2023-01-01',
      end: undefined,
    };
  }

  id(id: string): TemplateReportVersionBuilder {
    this._templateReportVersion.id = id;
    return this;
  }

  version(version: string): TemplateReportVersionBuilder {
    this._templateReportVersion.version = version;
    return this;
  }

  versionNumber(versionNumber: number): TemplateReportVersionBuilder {
    this._templateReportVersion.version_number = versionNumber;
    return this;
  }

  active(active: boolean): TemplateReportVersionBuilder {
    this._templateReportVersion.active = active;
    return this;
  }

  start(start: string): TemplateReportVersionBuilder {
    this._templateReportVersion.start = start;
    return this;
  }

  end(end: string): TemplateReportVersionBuilder {
    this._templateReportVersion.end = end;
    return this;
  }

  build(): TemplateReportVersion {
    return this._templateReportVersion;
  }
}
