import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { RadioButtonOption } from './radio';

@Component({
  selector: 'lib-radio-buttons',
  templateUrl: './radio-buttons.component.html',
})
export class RadioButtonsComponent {
  @Input() control?: UntypedFormControl;
  @Input() choices: RadioButtonOption[] = [];
  @Input() column: boolean = false;

  @Output() onChoiceSelection: EventEmitter<string> = new EventEmitter();
}
