import { DashboardDataPoint, DashboardDataPointWidget } from '../../models';
import { CreatedUpdatedBuilder } from '../created-updated-builder';
import { DashboardDataPointBuilder } from './dashboard-data-point-builder';
import { v4 as uuidv4 } from 'uuid';

export class DashboardDataPointWidgetBuilder extends CreatedUpdatedBuilder<DashboardDataPointWidget> {
  constructor() {
    super({
      id: uuidv4(),
      is_single_point: true,
      data_points: [new DashboardDataPointBuilder().build()],
      fiscal_year_id: uuidv4(),
    });
  }

  id(id: string): DashboardDataPointWidgetBuilder {
    this._instance.id = id;
    return this;
  }

  fiscalYearId(fiscalYearId: string): DashboardDataPointWidgetBuilder {
    this._instance.fiscal_year_id = fiscalYearId;
    return this;
  }

  dataPoints(dataPoints: DashboardDataPoint[]): DashboardDataPointWidgetBuilder {
    this._instance.data_points = dataPoints;
    return this;
  }
}
