import { BenchmarkCell, ValueGroupSet } from '../models';

export class BenchmarkCellBuilder {
  private readonly _benchmarkCell: BenchmarkCell;

  constructor() {
    this._benchmarkCell = {
      metric_id: 'metricId',
      fiscal_year: 2022,
      value_group_sets: [],
      org_id: 'orgId',
    };
  }

  valueGroupSets(vgsets: ValueGroupSet[]): BenchmarkCellBuilder {
    this._benchmarkCell.value_group_sets = vgsets;
    return this;
  }

  metricId(metricId: string): BenchmarkCellBuilder {
    this._benchmarkCell.metric_id = metricId;
    return this;
  }

  build(): BenchmarkCell {
    return this._benchmarkCell;
  }
}
