<lib-dialog-title
  [title]="(data.column ? 'Manage context column' : 'Add context column') | translate"
  (onCloseEvent)="closeDialog()"
></lib-dialog-title>

<div mat-dialog-content class="panel-dialog full-width">
  <form [formGroup]="tableContextColumnDialogForm" class="form">
    <div class="large fx-row pl-6">
      <lib-text-input
        class="formfield medium required newline w-40"
        [label]="'Column Header' | translate"
        [control]="tableContextColumnDialogForm.controls.label"
        [hint]="'Required' | translate"
        [placeholder]="'Column Header' | translate"
        [messages]="{ isUnique: 'Column label must be unique.' | translate }"
        [trimWhitespaceMethod]="eTrimMethod.full"
      ></lib-text-input>
    </div>

    <lib-options-list-edit-table
      class="formfield large"
      [editable]="!data.metricTableDefinition.has_values"
      [nonDeletableColumnIds]="nonDeletableColumnIds"
      [options]="tableContextColumnDialogForm.controls.options.value"
      [isMetricUpdating]="(data.metricStructureService.isMetricUpdating$ | async) ?? false"
      (optionsChange)="optionsChange($event)"
    ></lib-options-list-edit-table>

    <mat-error *ngIf="tableContextColumnDialogForm.hasError('maxRowCount')" class="ml-3 mt-2 large">
      {{ "You have reached the maximum number of rows." | translate }}
    </mat-error>
  </form>
</div>

<div mat-dialog-actions>
  <a
    lib-button
    [type]="'success'"
    (click)="save()"
    [disabled]="!!(tableContextColumnDialogForm.pristine || tableContextColumnDialogForm.invalid)"
    [isLoading]="!!(isMetricUpdating$ | async)"
    [label]="'Save' | translate"
  >
  </a>
  <button lib-button type="link" class="ml-4" (click)="closeDialog()">{{ "Cancel" | translate }}</button>
</div>
