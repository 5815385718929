import { ValueDefinitionTargetValue } from '../models';
import { faker } from '@faker-js/faker';
import { Builder } from './builder';

export class ValueDefinitionTargetValueBuilder extends Builder<ValueDefinitionTargetValue> {
  constructor() {
    super({
      frequency_code: '2022',
      value: Number(faker.random.numeric(2)),
    });
  }

  frequencyCode(frequencyCode: string): ValueDefinitionTargetValueBuilder {
    this._instance.frequency_code = frequencyCode;
    return this;
  }

  value(value: number): ValueDefinitionTargetValueBuilder {
    this._instance.value = value;
    return this;
  }
}
