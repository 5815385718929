<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <a mat-menu-item (click)="openConditionalTriggerAddEditDialog(item)">
      <mat-icon class="icon-sm" svgIcon="edit"></mat-icon>
      <span>{{ "Edit trigger" | translate }}</span>
    </a>
    <mat-divider></mat-divider>
    <a mat-menu-item (click)="openConditionalTriggerDeleteDialog(item)" class="color-error">
      <mat-icon class="icon-sm" svgIcon="trash"></mat-icon>
      <span>{{ "Delete" | translate }}</span>
    </a>
  </ng-template>
</mat-menu>

<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>

<div class="panel-properties-content-header mh-2">
  <div class="panel-properties-content-header-title fx-row justify-space-between w-100">
    <div class="fx-row justify-center align-center">
      <mat-icon svgIcon="condition" class="mr-2"></mat-icon>
      <h4 class="mat-h4">{{ "Conditional triggers" | translate }}</h4>
    </div>
    <div *ngIf="!disabled" class="fx-row justify-center align-center">
      <a (click)="openConditionalTriggerAddEditDialog()">
        <mat-icon svgIcon="add" data-testid="add-conditional-trigger-icon"></mat-icon>
      </a>
    </div>
  </div>
  <div class="panel-properties-content-header-actions"></div>
</div>
<div class="panel-properties-content-container">
  <div class="h-100 fx-column">
    <ng-container *ngIf="conditionalTriggers$ | async as conditionalTriggers; else loading">
      <lib-conditional-trigger-card
        *ngFor="let conditionalTrigger of conditionalTriggers"
        [conditionalTrigger]="conditionalTrigger"
        [valueDefinitionGroups]="valueDefinitionGroups!"
        [disabled]="disabled"
        (click)="openConditionalTriggerAddEditDialog(conditionalTrigger)"
        (menuClick)="onContextMenu($event, conditionalTrigger)"
      ></lib-conditional-trigger-card>
      <lib-empty-results
        *ngIf="conditionalTriggers.length === 0"
        [displayData]="emptyResults"
        (action)="openConditionalTriggerAddEditDialog()"
      ></lib-empty-results>
    </ng-container>

    <ng-template #loading>
      <lib-dummy [presentation]="ePresentation.card"></lib-dummy>
    </ng-template>
  </div>
</div>
