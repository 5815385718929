import { JobQueueDefinitionDataRequest, JobQueueOutput } from './requests';
import { JobQueueCategory, JobQueueStatus } from './job-queue-enums';

export { JobQueueRow, JobQueueDetailColumn, JobQueueStatusColumn, JobQueueTable };

interface JobQueueDetailColumn {
  output: JobQueueOutput | null;
  category: JobQueueCategory;
  status: JobQueueStatus;
}

interface JobQueueStatusColumn {
  value: string;
  key: JobQueueStatus;
}

interface JobQueueTable {
  rows: JobQueueRow[];
  size: number;
}

interface JobQueueRow
  extends Omit<JobQueueDefinitionDataRequest, 'duration_ms' | 'output' | 'updated' | 'id' | 'category' | 'status'> {
  category: string;
  status?: JobQueueStatusColumn;
  details: JobQueueDetailColumn;
}
