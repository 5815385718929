import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AuthService } from '../../services/auth/auth.service';
import { ContextSettingsService } from '../../services/context-settings/context-settings.service';

@Component({
  selector: 'addon-page-context-bar',
  templateUrl: './page-context-bar.component.html',
  styleUrls: ['./page-context-bar.component.scss'],
})
export class PageContextBarComponent {
  @Input() anchorName: string = '';
  @Input() ctaIcon: string = '';
  @Input() ctaText: string = '';
  @Input() subtitle: string = '';
  @Input() title: string = '';
  @Input() withSeparator: boolean = false;
  @Input() withSettings: boolean = false;
  @Input() withSignOut: boolean = false;

  @Output() anchorClick = new EventEmitter<void>();
  @Output() ctaClick = new EventEmitter<void>();

  constructor(
    public readonly authService: AuthService,
    public readonly contextSettingsService: ContextSettingsService
  ) {}
}
