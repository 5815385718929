import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ChipVariant } from '../../../models';

@Component({
  selector: 'lib-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent {
  @Input() text: string = '';
  @Input() maxCharCount: number = 40;
  @Input() outline?: boolean = true;
  @Input() variant?: ChipVariant = 'primary';

  @Output() handleClick = new EventEmitter<MouseEvent>();
}
