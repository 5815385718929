import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorManagerService } from '../../services/common/error-manager/error-manager.service';
import { BYPASS_INTERCEPTOR_ERROR_MANAGING } from './bypass-error-constant';
import { AuthService } from '../../services/common';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorManagerService: ErrorManagerService, private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: unknown) => {
        if (err instanceof HttpErrorResponse && err.status === 401 && !request.url.includes('/refresh')) {
          return this.authService.tokenRefresh(request, next);
        } else if (!request.context.get(BYPASS_INTERCEPTOR_ERROR_MANAGING)) {
          return this.errorManagerService.handleError(err as HttpErrorResponse);
        } else {
          return next.handle(request);
        }
      })
    );
  }
}
