<!-- Context menu -->
<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu #contextMenu="matMenu" (closed)="activeItem = undefined">
  <ng-template matMenuContent let-item="item">
    <ng-container *ngFor="let action of item.children">
      <ng-container *libPermission="action.permissions">
        <mat-divider *ngIf="action.id === 'separator'"></mat-divider>
        <a
          mat-menu-item
          *ngIf="action.id !== 'separator'"
          (click)="applyAction(action, item)"
          [ngClass]="{ 'color-error': action.id === 'delete' }"
          [disabled]="action.disabled"
        >
          <mat-icon *ngIf="action.icon" class="icon-sm" [svgIcon]="action.icon"></mat-icon>
          <span>{{ action.title }}</span>
        </a>
      </ng-container>
    </ng-container>
  </ng-template>
</mat-menu>

<div
  [class]="'panel-search layout-' + layout + ' presentation-' + presentation + ' grid-' + grid"
  [ngClass]="{ loaded: loaded, 'item-selected': selectedItemID }"
>
  <div class="panel-collection">
    <div class="collection collection-dummies">
      <lib-dummy *ngFor="let dummy of dummies" [presentation]="presentation"></lib-dummy>
    </div>
    <div
      class="h-100 w-100"
      [ngClass]="{ 'collection collection-items': itemCollection?.items?.length }"
      cdkDropList
      cdkDropListSortingDisabled
      cdkDropListConnectedTo="itemList"
      [cdkDropListData]="itemCollection?.items"
    >
      <div
        [attr.title]="
          selectedItemIDs.indexOf(item.id) >= 0 || disableItems ? ('This item is already selected' | translate) : null
        "
        *ngFor="let item of itemCollection?.items"
        cdkDrag
        class="card-container"
        [ngClass]="{
          active: activeItem === item || selectedItem === item,
          selected: selectedItemIDs.indexOf(item.id) >= 0 || selectedItem === item || disableItems
        }"
        (mouseover)="onMouseOver(item)"
        (mouseout)="onMouseOut()"
        (contextmenu)="onContextMenu($event, item)"
        [cdkDragDisabled]="selectedItemIDs.indexOf(item.id) >= 0 || disableItems"
      >
        <lib-card
          [item]="item"
          [presentation]="presentation"
          [mode]="mode"
          [canClickItem]="canClickItem"
          (action)="selectItem($event)"
        >
        </lib-card>
        <a
          *ngIf="showActions && item.children"
          class="context-menu"
          data-testid="search-context-menu"
          (click)="onContextMenu($event, item)"
        >
          <mat-icon class="icon-sm" svgIcon="more"></mat-icon>
        </a>
      </div>
      <div *ngIf="itemCollection?.items?.length === 0" cdkDragDisabled class="h-100 collection-empty">
        <div *ngIf="noData" class="h-100">
          <ng-content></ng-content>
        </div>
        <div *ngIf="!noData" class="h-100">
          <lib-empty-results [displayData]="emptyResults"></lib-empty-results>
        </div>
      </div>
      <div #spinner class="spinner">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
      <div class="grid-end"></div>
    </div>
  </div>
  <div class="panel-item" *ngIf="mode === 'nav'">
    <router-outlet></router-outlet>
  </div>
</div>
