<div class="item-dummies bkg-white h-100">
  <ng-container *ngIf="header">
    <div class="caption2 mb-1 rounded-corners bkg-blink w-25">&nbsp;</div>
    <h3 class="mat-body-strong rounded-corners bkg-blink w-100 mb-2">&nbsp;</h3>
    <div class="caption2 mb-3 rounded-corners bkg-blink w-50">&nbsp;</div>
    <div class="mat-body-strong rounded-corners bkg-blink w-100 mb-5">&nbsp;</div>
  </ng-container>
  <ng-container *ngIf="body">
    <div class="repeat bkg-white">
      <div *ngFor="let i of [1, 2, 3, 4, 5, 6, 7]" class="mb-2">
        <div class="caption2 mb-1 rounded-corners bkg-blink w-25">&nbsp;</div>
        <div class="mat-body-strong rounded-corners bkg-blink w-50">&nbsp;</div>
      </div>
    </div>
  </ng-container>
</div>
