import { Framework, ReportCategoryType, ReportTemplate } from '../models';

export class ReportTemplateBuilder {
  private readonly _reportTemplate: ReportTemplate;

  constructor() {
    this._reportTemplate = {
      id: 'reportTemplateId',
      code: 'reportTemplateCode',
      name: 'reportTemplateName',
      active: true,
      category: ReportCategoryType.questionnaire,
      frameworks: [],
      start: '12-12-22',
      end: '12-12-23',
    };
  }

  id(id: string): ReportTemplateBuilder {
    this._reportTemplate.id = id;
    return this;
  }

  name(name: string): ReportTemplateBuilder {
    this._reportTemplate.name = name;
    return this;
  }

  category(category: ReportCategoryType): ReportTemplateBuilder {
    this._reportTemplate.category = category;
    return this;
  }

  frameworks(frameworks: Framework[]): ReportTemplateBuilder {
    this._reportTemplate.frameworks = frameworks;
    return this;
  }

  build(): ReportTemplate {
    return this._reportTemplate;
  }
}
