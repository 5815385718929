import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common/api/api.service';
import {
  ApiResponse,
  ApplicationApiDefinition,
  Indicator,
  PaginationFilter,
  Report,
  ReportTemplate,
  ReportTemplateFilteringOptions,
  SearchOptions,
  SearchReportPayload,
  SectionDisplay,
  SortFilter,
  SPReportSubmmisionPayload,
  TemplateCrationCheck,
  TemplateCreateRequest,
  TemplateCreationCheckRequest,
  TemplateNameCheckRequest,
  TemplateGetRequestPayload,
  ReportAttachedCheck,
  ReportStatusTransition,
  PlatformIntegrationType,
  TemplateCompletionByStatus,
  ExportTemplateReportPayload,
  CDPReportSubmissionPayload,
  TemplateReportHierarchySummary,
  GetIndicatorPayload,
  AttributeCheckResponse,
} from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class ClientReportsService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private baseService: BaseService, private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.reports;
  }

  payloadFromSearchOptions(searchOptions: SearchOptions): SearchReportPayload {
    const payload: SearchReportPayload = { filters: {} };
    if (searchOptions.filters.year) {
      payload.filters.report_frequencies = [searchOptions.filters.year.id];
    }
    if (searchOptions.filters.source) {
      payload.filters.business_units = [searchOptions.filters.source.id];
    }
    if (searchOptions.filters.topic) {
      if (searchOptions.filters.topic.action === 'category') {
        payload.filters.topics = [searchOptions.filters.topic.id];
      } else if (searchOptions.filters.topic.action === 'group') {
        payload.filters.topics = [searchOptions.filters.topic.id];
      } else {
        payload.filters.topics = [searchOptions.filters.topic.id];
      }
    }
    if (searchOptions.filters.category) {
      payload.filters.category = searchOptions.filters.category.id;
    }
    if (searchOptions.filters.framework) {
      payload.filters.frameworks = [searchOptions.filters.framework.id];
    }
    if (searchOptions.filters.status) {
      payload.filters.status = searchOptions.filters.status.id;
    }
    if (searchOptions.filters.tag) {
      payload.filters.tags = [searchOptions.filters.tag.id];
    }
    if (searchOptions.filters.source) {
      payload.filters.business_units = [searchOptions.filters.source.id];
    }
    if (searchOptions.size) {
      payload.page_size = searchOptions.size;
    }
    if (searchOptions.from && searchOptions.from !== 0 && searchOptions.size) {
      payload.page = searchOptions.from / searchOptions.size + 1;
    }
    if (searchOptions.query.keywords) {
      payload.filters.search_term = searchOptions.query.keywords;
    }
    switch (searchOptions.sort?.id) {
      case SortFilter.name:
        payload.order_by = 'name';
        payload.order_by_direction = 'asc';
        break;
      case SortFilter.score:
        break;
      case SortFilter.updated:
      default:
        payload.order_by = 'updated';
        payload.order_by_direction = 'desc';
        break;
    }

    return payload;
  }
  // Awaiting APIs

  search(searchOptions: SearchOptions): Observable<ApiResponse<Report[]>> {
    const payload = this.payloadFromSearchOptions(searchOptions);
    return this.apiService.post(`${this.servicePath}${this.resource}/reports/list`, payload).pipe(
      map((result: ApiResponse<Report[]>) => {
        if (searchOptions.filters.exclude) {
          if (searchOptions.filters.exclude.id === 'status') {
            result.data = result.data.filter((x) => x.status !== searchOptions.filters.exclude?.title);
          }
        }
        if (searchOptions.size) {
          result.data = result.data.slice(0, searchOptions.size);
        }
        return result;
      })
    );
  }

  // Reports
  listReports(filters?: ReportTemplateFilteringOptions): Observable<ApiResponse<Report[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/reports/list`, filters);
  }

  getReport(id: string, payload: TemplateGetRequestPayload = {}): Observable<ApiResponse<Report>> {
    let params = new HttpParams();
    params = params.append('load_sections', payload.load_sections ? 'true' : 'false');
    params = params.append('load_indicators', payload.load_indicators ? 'true' : 'false');
    params = params.append('load_value_group_sets', payload.load_value_group_sets ? 'true' : 'false');
    params = params.append('load_value_groups', payload.load_value_groups ? 'true' : 'false');
    params = params.append('load_values', payload.load_values ? 'true' : 'false');
    return this.apiService.get(`${this.servicePath}${this.resource}/reports/${id}`, { params });
  }

  getTemplateCompletionByStatus(id: string): Observable<ApiResponse<TemplateCompletionByStatus>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/reports/${id}/completion_status`);
  }

  getReportHierarchySummary(id: string): Observable<ApiResponse<TemplateReportHierarchySummary[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/reports/${id}/report_hierarchy_summary`);
  }

  createReport(payload: any, template_report_id: string): Observable<ApiResponse<Report>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/core_report_templates/${template_report_id}/reports`,
      payload
    );
  }

  createReportWithVersion(
    payload: TemplateCreateRequest,
    coreTemplateId: string,
    coreTemplateVersionId: string
  ): Observable<ApiResponse<Report>> {
    return this.apiService.post<ApiResponse<Report>>(
      `${this.servicePath}${this.resource}/core_template_reports/${coreTemplateId}/core_template_report_versions/${coreTemplateVersionId}/reports`,
      payload
    );
  }

  createEmptyReport(payload: any): Observable<ApiResponse<Report>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/reports`, payload);
  }

  isReportNameAvailable(payload: TemplateNameCheckRequest): Observable<ApiResponse<AttributeCheckResponse>> {
    return this.apiService.post<ApiResponse<AttributeCheckResponse>>(
      `${this.servicePath}${this.resource}/reports/name_check`,
      payload
    );
  }

  isReportAvailable(payload: TemplateCreationCheckRequest): Observable<ApiResponse<TemplateCrationCheck>> {
    return this.apiService.post<ApiResponse<TemplateCrationCheck>>(
      `${this.servicePath}${this.resource}/reports/duplicate_check`,
      payload
    );
  }

  updateReport(id: string, payload: any, queryPayload?: TemplateGetRequestPayload): Observable<ApiResponse<Report>> {
    let params = new HttpParams();
    params = params.append('load_sections', queryPayload?.load_sections ? 'true' : 'false');
    params = params.append('load_indicators', queryPayload?.load_indicators ? 'true' : 'false');
    params = params.append('load_value_group_sets', queryPayload?.load_value_group_sets ? 'true' : 'false');
    params = params.append('load_value_groups', queryPayload?.load_value_groups ? 'true' : 'false');
    params = params.append('load_values', queryPayload?.load_values ? 'true' : 'false');
    params = params.append('load_tags', queryPayload?.load_tags ? 'true' : 'false');
    params = params.append('load_topics', queryPayload?.load_topics ? 'true' : 'false');
    return this.apiService.put(`${this.servicePath}${this.resource}/reports/${id}`, payload, params);
  }

  deleteReport(id: string): Observable<ApiResponse> {
    return this.apiService.delete(`${this.servicePath}${this.resource}/reports/${id}`);
  }

  addReportSection(
    report_id: string,
    code?: string,
    label?: string,
    parent_id?: string,
    display?: SectionDisplay
  ): Observable<ApiResponse<Report>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/reports/${report_id}/sections`, {
      code,
      label,
      ...(parent_id && { parent_id }),
      display,
    });
  }

  updateReportSection(
    report_id: string,
    section_id: string,
    payload?: { code?: string; label?: string; parent_id?: string; display?: any[] },
    queryPayload?: TemplateGetRequestPayload
  ): Observable<ApiResponse<Report>> {
    let params = new HttpParams();
    params = params.append('load_sections', queryPayload?.load_sections ? 'true' : 'false');
    params = params.append('load_indicators', queryPayload?.load_indicators ? 'true' : 'false');
    params = params.append('load_value_group_sets', queryPayload?.load_value_group_sets ? 'true' : 'false');
    params = params.append('load_value_groups', queryPayload?.load_value_groups ? 'true' : 'false');
    params = params.append('load_values', queryPayload?.load_values ? 'true' : 'false');
    return this.apiService.put(
      `${this.servicePath}${this.resource}/reports/${report_id}/sections/${section_id}`,
      payload,
      params
    );
  }

  moveSection(
    report_id: string,
    section_id: string,
    position: number,
    queryPayload?: TemplateGetRequestPayload
  ): Observable<ApiResponse<Report>> {
    let params = new HttpParams();
    params = params.append('load_sections', queryPayload?.load_sections ? 'true' : 'false');
    params = params.append('load_indicators', queryPayload?.load_indicators ? 'true' : 'false');
    params = params.append('load_value_group_sets', queryPayload?.load_value_group_sets ? 'true' : 'false');
    params = params.append('load_value_groups', queryPayload?.load_value_groups ? 'true' : 'false');
    params = params.append('load_values', queryPayload?.load_values ? 'true' : 'false');
    return this.apiService.post(
      `${this.servicePath}${this.resource}/reports/${report_id}/sections/${section_id}/move/${position}`,
      {},
      params
    );
  }

  public deleteSection(report_id: string, report_section_ids: string[]): Observable<undefined> {
    return this.apiService.delete(`${this.servicePath}${this.resource}/reports/${report_id}/sections`, {
      report_section_ids,
    });
  }

  addIndicatorToSection(
    report_id: string,
    section_id: string,
    indicator_ids: string[],
    display?: SectionDisplay[]
  ): Observable<ApiResponse<Report>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/reports/${report_id}/sections/${section_id}/indicators`,
      {
        indicator_ids,
        display,
      }
    );
  }

  removeIndicatorFromSection(
    report_id: string,
    section_id: string,
    indicator_ids: string[],
    display?: SectionDisplay[]
  ): Observable<ApiResponse<Report>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/reports/${report_id}/sections/${section_id}/indicators`,
      { indicator_ids, display }
    );
  }

  moveIndicatorInSection(
    report_id: string,
    section_id: string,
    indicator_id: string,
    position: number
  ): Observable<ApiResponse<Report>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/reports/${report_id}/sections/${section_id}/indicators/${indicator_id}/move/${position}`,
      {}
    );
  }

  completeReport(report_id: string): Observable<ApiResponse<Report>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/reports/${report_id}/complete`, {});
  }

  draftReport(report_id: string): Observable<ApiResponse<Report>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/reports/${report_id}/draft`, {});
  }

  archiveReport(report_id: string): Observable<ApiResponse<Report>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/reports/${report_id}/archive`, {});
  }

  unarchiveReport(report_id: string): Observable<ApiResponse<Report>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/reports/${report_id}/unarchive`, {});
  }

  duplicateReport(report_id: string): Observable<ApiResponse<Report>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/reports/${report_id}/duplicate`, {});
  }

  getReportTemplate(reportTemplateId: string): Observable<ApiResponse<ReportTemplate>> {
    let params = new HttpParams();
    params = params.append('load_tags', 'true');
    params = params.append('load_frameworks', 'true');
    params = params.append('load_topics', 'true');
    params = params.append('load_industries', 'true');
    return this.apiService.get(`${this.servicePath}${this.resource}/report_templates/${reportTemplateId}`, { params });
  }

  getReportIndicators(
    report_id: string,
    searchOptions: SearchOptions,
    paginationFilter: PaginationFilter,
    loadValueGroupSets: boolean = false
  ): Observable<ApiResponse<Indicator[]>> {
    let params = new HttpParams();

    if (searchOptions.query.keywords) {
      params = params.append('query', searchOptions.query.keywords);
    }

    if (searchOptions.sort) {
      if (searchOptions.sort.id === 'name') {
        params = params.append('order_by', 'name');
        params = params.append('order_by_direction', 'asc');
      }
    }

    if (searchOptions.filters.tag) {
      params = params.append('tag_id', searchOptions.filters.tag.id);
    }

    if (searchOptions.filters.industry) {
      params = params.append('industry_id', searchOptions.filters.industry.id);
    }

    if (searchOptions.filters.topic) {
      params = params.append('topic_id', searchOptions.filters.topic.id);
    }

    if (paginationFilter.page) {
      params = params.append('page', paginationFilter.page);
    }
    if (paginationFilter.page_size) {
      params = params.append('page_size', paginationFilter.page_size);
    }
    if (loadValueGroupSets) {
      params = params.append('load_value_group_sets', loadValueGroupSets);
    }

    return this.apiService.get(`${this.servicePath}${this.resource}/reports/${report_id}/indicators`, { params });
  }
  exportSPReport(report_id: string, payload: any): Observable<any> {
    return this.apiService.post(`${this.servicePath}${this.resource}/reports/${report_id}/export/xml`, payload);
  }

  submitReport(
    report_id: string,
    integration_type: PlatformIntegrationType,
    payload: SPReportSubmmisionPayload | CDPReportSubmissionPayload
  ): Observable<ApiResponse> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/reports/${report_id}/submit/${integration_type}`,
      payload
    );
  }

  reportAttachedCheck(report_id: string): Observable<ApiResponse<ReportAttachedCheck>> {
    let params: HttpParams = new HttpParams();
    params = params.append('report_id', report_id);
    return this.apiService.post(`${this.servicePath}${this.resource}/reports/${report_id}/check_report`, { params });
  }

  public getReportStatusTransitions(reportId: string): Observable<ApiResponse<ReportStatusTransition[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/reports/${reportId}/status_transitions`);
  }

  public searchReportIndicators(searchOptions: SearchOptions): Observable<ApiResponse<Indicator[]>> {
    const reportId: string = String(searchOptions.custom_properties?.reportId);

    const paginationFilter: PaginationFilter = {
      page: Math.floor(searchOptions.from || 0 / this.baseService.defaultPageSize),
      page_size: this.baseService.defaultPageSize,
    };

    return this.getReportIndicators(reportId, searchOptions, paginationFilter);
  }

  getSectionIndicators(
    sectionId: string,
    templateId: string,
    payload?: GetIndicatorPayload
  ): Observable<ApiResponse<Indicator[]>> {
    let params = new HttpParams();
    if (payload) {
      Object.entries(payload).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item: string) => {
            params = params.append(key, item);
          });
        } else if (value != undefined && value != null) {
          params = params.append(key, value as string);
        }
      });
    }

    return this.apiService.get(
      `${this.servicePath}${this.resource}/reports/${templateId}/sections/${sectionId}/indicators`,
      { params }
    );
  }

  exportTemplateReport(reportId: string, payload: ExportTemplateReportPayload): Observable<ApiResponse> {
    return this.apiService.post(`${this.servicePath}${this.resource}/report/${reportId}/export`, payload);
  }
}
