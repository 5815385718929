<form
  [formGroup]="choiceFormGroup"
  [class]="'formfield choice-formfield ' + size"
  *ngIf="choiceFormGroup"
  (libBeforeUnload)="updateField()"
>
  <lib-form-field-label [label]="label" [control]="valueFormControl"></lib-form-field-label>
  <ng-container [ngSwitch]="widgetType" *ngIf="choicesList$ | async as choicesList">
    <ng-container *ngSwitchCase="eChoiceFieldWidgetType.single_select">
      <lib-auto-complete-input
        *ngIf="allowAddOption; else noAddOption"
        data-testid="autoCompleteInput"
        [placeholder]="'Click in the field to show available options or enter your own' | translate"
        [control]="choiceFormGroup.valuesControl"
        [hint]="hint"
        [options]="choicesList"
        [canAddNewOption]="allowAddOption"
        [minimalNumberOfCharToDisplayAutocomplete]="0"
        [restoreInputOnBlur]="true"
      >
      </lib-auto-complete-input>
      <ng-template #noAddOption>
        <lib-searchable-select-input
          data-testid="searchableSelect"
          [control]="choiceFormGroup.valuesControl"
          [options]="filteredChoiceList"
          [hint]="hint"
          (loadOptions)="filterChoices(choicesList, $event)"
        ></lib-searchable-select-input>
      </ng-template>
    </ng-container>

    <lib-metric-editor-checkbox-field
      *ngSwitchCase="eChoiceFieldWidgetType.checkboxes"
      data-testid="multiSelectCheckbox"
      [control]="choiceFormGroup.valuesControl"
      [options]="choicesList"
      [hint]="hint"
      #inputFieldRef
    ></lib-metric-editor-checkbox-field>

    <ng-container *ngSwitchCase="eChoiceFieldWidgetType.multi_chip">
      <lib-multi-select-chip-input
        data-testid="multiSelectChipInput"
        [control]="choiceFormGroup.valuesControl"
        [hint]="hint"
        [options]="choicesList"
        [forceControlValue]="true"
        [freeSolo]="allowAddOption"
        (addValue)="addValue($event)"
        #inputFieldRef
      ></lib-multi-select-chip-input>
      <mat-hint>
        <span>
          {{
            (allowAddOption
              ? "Type to search for available options, or to create a new option. New options appear in blue"
              : "Type to search for available options"
            ) | translate
          }}
        </span>
      </mat-hint>
    </ng-container>

    <lib-metric-editor-radio-field
      *ngSwitchCase="eChoiceFieldWidgetType.radio_buttons"
      data-testid="choiceRadioSelect"
      [control]="choiceFormGroup.valuesControl"
      [options]="choicesList"
      [hint]="hint"
      #inputFieldRef
    ></lib-metric-editor-radio-field>
  </ng-container>
  <div class="ml-4 mt-1" *ngIf="displayExplanation && choiceFormGroup.additionalTextControl">
    <lib-text-area-input
      [label]="explanationLabel"
      [control]="choiceFormGroup.additionalTextControl"
      [maxLength]="5000"
      (handleBlur)="choiceFormGroup.blurAdditionalTextControl()"
    ></lib-text-area-input>
  </div>
</form>
