<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-item="item" let-first="first" let-last="last">
    <a mat-menu-item (click)="editTableColumn(item)">
      <mat-icon class="icon-sm" svgIcon="edit"></mat-icon>
      <span>{{ "Edit column" | translate }}</span>
    </a>
    <a
      *ngIf="!metricTableDefinition?.has_values || item.type !== eValueDefinitionType.label"
      mat-menu-item
      (click)="duplicateTableColumn(item)"
    >
      <mat-icon class="icon-sm" svgIcon="copy"></mat-icon>
      <span>{{ "Make a copy" | translate }}</span>
    </a>

    <ng-container *ngIf="!first || !last">
      <mat-divider></mat-divider>
      <a mat-menu-item (click)="moveUpMetricTableColumn(item)" *ngIf="!first">
        <mat-icon class="icon-sm" svgIcon="arrow-full-up"></mat-icon>
        <span>{{ "Move up" | translate }}</span>
      </a>
      <a mat-menu-item (click)="moveDownMetricTableColumn(item)" *ngIf="!last">
        <mat-icon class="icon-sm" svgIcon="arrow-full-down"></mat-icon>
        <span>{{ "Move down" | translate }}</span>
      </a>
    </ng-container>

    <ng-container *ngIf="!metricTableDefinition?.has_values">
      <mat-divider></mat-divider>
      <a mat-menu-item (click)="deleteTableColumn(item)" class="color-error">
        <mat-icon class="icon-sm" svgIcon="trash"></mat-icon>
        <span>{{ "Delete" | translate }}</span>
      </a>
    </ng-container>
  </ng-template>
</mat-menu>

<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>

<div class="panel-properties-content-header mh-2 fx-row justify-space-between">
  <div class="panel-properties-content-header-title fx-row align-center">
    <mat-icon svgIcon="xls" class="mr-2"></mat-icon>
    <h4 class="mat-h4">{{ "Data table" | translate }}</h4>
  </div>
  <div class="panel-properties-content-header-actions fx-row align-center">
    <ng-container *ngIf="metric?.category !== eMetricCategory.THIRD_PARTY">
      <ng-container *libFeatureFlag="{ featureFlags: ['metric_structure_deactivation_enabled'] }">
        <button
          *ngIf="metricTableGroup.active; else reactivate"
          lib-button
          type="stroked"
          (click)="deactivateMetricTableGroup()"
        >
          {{ "Deactivate" | translate }}
        </button>
        <ng-template #reactivate>
          <button lib-button type="stroked" (click)="activateMetricTableGroup()">
            {{ "Activate" | translate }}
          </button>
        </ng-template>
      </ng-container>
    </ng-container>

    <a (click)="deleteTable($event)" role="button" [attr.aria-label]="'Delete table' | translate" class="ml-2">
      <mat-icon svgIcon="trash"></mat-icon>
    </a>
  </div>
</div>
<div class="panel-properties-content-container fx-col" *ngIf="metricTableDefinition && tableForm">
  <div class="fields-container pb-1">
    <form class="form" [formGroup]="tableForm" [ngClass]="{ disabled: formDisabled }">
      <div class="formfield tip large" *ngIf="metricTableGroup.end_fiscal_year_frequency_code">
        <lib-instructional-text data-testid="deactivated-table-instruction">
          {{
            "Table deactivated from fiscal year {fiscalYear} inclusive"
              | translate : { fiscalYear: metricTableGroup.end_fiscal_year_frequency_code }
          }}</lib-instructional-text
        >
      </div>
      <div class="formfield large">
        <lib-text-input
          [label]="'Table title' | translate"
          [control]="tableForm.controls.tableTitleControl"
          [maxCharacterCount]="50"
          [placeholder]="'Sample table' | translate"
          data-testid="tableTitle"
          (handleBlur)="saveTableForm()"
        ></lib-text-input>
      </div>
    </form>
    <mat-divider class="mt-8"></mat-divider>

    <mat-accordion multi>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header class="caption3 color-grey-800 pr-1 section-header">
          {{ "Configure Columns" | translate }}
        </mat-expansion-panel-header>
        <div class="fx-column">
          <ng-container *ngIf="metricTableDefinition?.column_definitions?.length">
            <ng-container
              *ngFor="let columnDefinition of metricTableContextColumns; let first = first; let last = last"
            >
              <lib-table-column-definition-card
                [tableColumnDefinition]="columnDefinition"
                (click)="editTableColumn(columnDefinition)"
                (menuClick)="onContextMenu($event, columnDefinition, first, last)"
                (contextmenu)="onContextMenu($event, columnDefinition, first, last)"
                [disabled]="formDisabled || (metricTableGroup | isDeactivated)"
                [ngClass]="{ disabled: formDisabled || (metricTableGroup | isDeactivated) }"
              >
              </lib-table-column-definition-card>
            </ng-container>
          </ng-container>

          <button
            lib-button
            [disabled]="metricTableDefinition.has_values | boolean"
            (click)="handleContextColumn()"
            class="btn p-0 mt-2 enable-pointer-events"
            [matTooltip]="
              (metricTableDefinition.has_values ? 'Data detected, cannot add context columns' : '') | translate
            "
            *ngIf="!(metricTableGroup | isDeactivated)"
          >
            <mat-icon svgIcon="add" class="icon-md"></mat-icon>
            <div class="ml-1">{{ "Add Context Column" | translate }}</div>
          </button>

          <mat-divider class="mt-4 mb-4"></mat-divider>

          <ng-container *ngFor="let columnDefinition of metricTableInputColumns; let first = first; let last = last">
            <lib-table-column-definition-card
              [tableColumnDefinition]="columnDefinition"
              (click)="editTableColumn(columnDefinition)"
              (menuClick)="onContextMenu($event, columnDefinition, first, last)"
              (contextmenu)="onContextMenu($event, columnDefinition, first, last)"
              [disabled]="formDisabled || (metricTableGroup | isDeactivated)"
              [ngClass]="{ disabled: formDisabled || (metricTableGroup | isDeactivated) }"
            >
            </lib-table-column-definition-card>
          </ng-container>

          <button
            lib-button
            (click)="handleInputColumn()"
            class="btn p-0 mt-2"
            *ngIf="!(metricTableGroup | isDeactivated)"
          >
            <mat-icon svgIcon="add" class="icon-md"></mat-icon>
            <div class="ml-1">{{ "Add Input Column" | translate }}</div>
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div>
    <mat-divider class="pb-4"></mat-divider>

    <div class="form-submit">
      <button lib-button type="link" (click)="closeProperties()">{{ "Cancel" | translate }}</button>
    </div>
  </div>
</div>
