import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiResponse, ApplicationApiDefinition, Company, SearchOptions } from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { BaseService } from '../../common/base/base.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClientCompaniesService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private baseService: BaseService, private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.companies;
  }

  payloadFromSearchOptions(searchOptions: SearchOptions): HttpParams {
    let params = new HttpParams();

    params = params.append('order_by', 'name');
    params = params.append('order_by_direction', 'asc');
    if (searchOptions.custom_filters) {
      params = params.append('load_business_units', true);
    }

    if (searchOptions.query.keywords) {
      params = params.append('search_by_name', searchOptions.query.keywords);
    }

    return params;
  }

  companyTreePayload(searchOptions: SearchOptions): HttpParams {
    let params = new HttpParams();

    params = params.append('order_by', 'name');
    params = params.append('order_by_direction', 'asc');
    params = params.append('load_business_units', true);

    if (searchOptions.query.keywords) {
      params = params.append('search_by_name', searchOptions.query.keywords);
    }

    return params;
  }

  search(searchOptions: SearchOptions): Observable<ApiResponse<Company[]>> {
    const params: any = this.payloadFromSearchOptions(searchOptions);
    return this.apiService.get(`${this.servicePath}${this.resource}/companies`, { params });
  }

  searchCompanyTree(searchOptions: SearchOptions): Observable<ApiResponse<Company[]>> {
    const params: any = this.companyTreePayload(searchOptions);
    return this.apiService.get(`${this.servicePath}${this.resource}/companies/search_company_tree`, { params }).pipe(
      map((result) => {
        if (result.data) {
          result.data = this.flattenCompanyData(result.data as Company, []);
        }
        return result;
      })
    );
  }

  listAllCompanies(): Observable<ApiResponse<Company[]>> {
    let params = new HttpParams();
    params = params.append('order_by', 'name');
    params = params.append('order_by_direction', 'asc');

    return this.apiService.get(`${this.servicePath}${this.resource}/companies`, { params });
  }

  createCompany(payload: Partial<Company>): Observable<ApiResponse<Company>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/companies`, payload);
  }

  updateCompany(companyId: string, payload: Partial<Company>): Observable<ApiResponse<Company>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/companies/${companyId}`, payload);
  }

  getCompany(companyId: string): Observable<ApiResponse<Company>> {
    let params = new HttpParams();
    params = params.append('load_addresses', true);

    return this.apiService.get(`${this.servicePath}${this.resource}/companies/${companyId}`, { params });
  }

  getHQCompany(): Observable<ApiResponse<Company>> {
    return this.apiService.get<ApiResponse<Company>>(`${this.servicePath}${this.resource}/companies/top`);
  }

  getParentCompanies(): Observable<ApiResponse<Company[]>> {
    let params = new HttpParams();
    params = params.append('load_business_units', true);

    return this.apiService.get(`${this.servicePath}${this.resource}/companies`, { params }).pipe(
      map((result: ApiResponse<Company[]>) => {
        if (result.data.length) {
          result.data = result.data.filter(
            (item) => !item.parent_company_id || (item.parent_company_id && item.business_units.length <= 2)
          );
        }
        return result;
      })
    );
  }

  private flattenCompanyData(company: Company, flatCompaniesList: Company[]): Company[] {
    for (const c of company.child_companies) {
      flatCompaniesList.push(c);
      if (c.child_companies.length) {
        this.flattenCompanyData(c, flatCompaniesList);
      }
    }
    return [company].concat(flatCompaniesList);
  }
}
