import { Builder } from './builder';
import { Metric, RelatedMetric, RelatedMetricSource } from '../models';
import { IndicatorBuilder } from './indicator-builder';

export class RelatedMetricBuilder extends Builder<RelatedMetric> {
  constructor() {
    super({
      equivalent_metric: new IndicatorBuilder().code('eq').id('eq_1').build(),
      metric_table_definition_ids: [],
      original_metric_ids: [],
      value_definition_ids: [],
      source: RelatedMetricSource.platform,
    });
  }

  public equivalentMetric(equivalentMetric: Metric): RelatedMetricBuilder {
    this._instance.equivalent_metric = equivalentMetric;
    return this;
  }

  public metricTableDefinitionIds(metricTableDefinitionIds: string[]): RelatedMetricBuilder {
    this._instance.metric_table_definition_ids = metricTableDefinitionIds;
    return this;
  }

  public originalMetricIds(originalMetricIds: string[]): RelatedMetricBuilder {
    this._instance.original_metric_ids = originalMetricIds;
    return this;
  }

  public valueDefinitionIds(valueDefinitionIds: string[]): RelatedMetricBuilder {
    this._instance.value_definition_ids = valueDefinitionIds;
    return this;
  }

  public source(source?: RelatedMetricSource): RelatedMetricBuilder {
    this._instance.source = source;
    return this;
  }
}
