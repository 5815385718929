export {
  PlatformIntegrationType,
  PlatformIntegrationTypeToLabelMapping,
  IntegrationMetaData,
  IntegrationSetting,
  IntegrationSettingValidation,
};

enum PlatformIntegrationType {
  S_AND_P = 's_and_p',
  GREEN_PROJECT = 'green_project',
  EDCI = 'edci',
  TABLEAU = 'tableau',
  CDP = 'cdp',
  MINIMUM = 'minimum',
}

const PlatformIntegrationTypeToLabelMapping = new Map<string, string>([
  [PlatformIntegrationType.S_AND_P, 'S&P'],
  [PlatformIntegrationType.GREEN_PROJECT, 'Green Project'],
  [PlatformIntegrationType.EDCI, 'EDCI'],
  [PlatformIntegrationType.TABLEAU, 'Tableau'],
  [PlatformIntegrationType.CDP, 'CDP'],
  [PlatformIntegrationType.MINIMUM, 'Minimum'],
]);

interface IntegrationMetaData {
  company_id: number | null;
  cdp_climate_token?: string;
  process_regular_gp_emissions?: boolean;
  process_aggregated_gp_emissions?: boolean;
  process_minimum_integration?: boolean;
  process_minimum_aggregated?: boolean;
  source?: string;
}

interface IntegrationSetting {
  available: boolean;
  id: string | null;
  type: PlatformIntegrationType;
  enabled: boolean;
  meta: IntegrationMetaData | null;
  created?: Date | string;
  created_by?: string;
  updated?: Date | string;
  updated_by?: string;
}

interface IntegrationSettingValidation {
  valid: boolean;
}
