import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { finalize, take } from 'rxjs/operators';

import {
  ActionItem,
  ApiResponse,
  ConsolidationRules,
  DeactivateEntityTypes,
  Metric,
  MetricCategory,
  ValueDefinitionGroup,
} from '../../../../models';

import { Required } from '../../../../decorators';
import { Observable } from 'rxjs';
import { MetricStructureStateService } from '../../../services/metric-structure-state.service';
import { ConsolidationService, TranslateService } from '../../../../services/common';
import { MetricStructureGroupPropertiesForm } from './metric-structure-group-properties-form';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorManagerService } from '../../../../services/common/error-manager/error-manager.service';
import { MetricApiService } from '../../../../services/types';
import { DeactivateEntityService } from '../../../services/deactivate-entity/deactivate-entity.service';
import { ClientConfigService } from '../../../../services/client';
import { ActivateEntityService } from '../../../services/activate-entity/activate-entity.service';

@Component({
  selector: 'lib-metric-structure-group-properties',
  templateUrl: './metric-structure-group-properties.component.html',
  styleUrls: ['./metric-structure-group-properties.component.scss'],
})
export class MetricStructureGroupPropertiesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() @Required() valueDefinitionGroup!: ValueDefinitionGroup;
  @Input() formDisabled: boolean = false;

  @Output() closeGroup: EventEmitter<void> = new EventEmitter<void>();

  metric?: Metric;
  groupForm?: MetricStructureGroupPropertiesForm;
  updating$: Observable<boolean> = this.metricStructureService.isMetricUpdating$;
  isGroupDeletionEnabled: boolean = false;
  isNewGroup: boolean = false;
  readonly indentOptions: ActionItem[] = ['0', '1', '2', '3', '4', '5'].map((option) => ({
    title: option,
    id: option,
  }));
  readonly repeatLimitErrorMsgs: ValidationErrors = {
    required: this.translateService.instant('Limit is required'),
    notNumeric: this.translateService.instant('Please enter a positive integer'),
    isNot0OrGreaterThan1: this.translateService.instant(`The value can't be 1`),
  };

  readonly eMetricCategory: typeof MetricCategory = MetricCategory;

  constructor(
    private metricsService: MetricApiService,
    private metricStructureService: MetricStructureStateService,
    private translateService: TranslateService,
    private errorManagerService: ErrorManagerService,
    private deactivateEntityService: DeactivateEntityService,
    private activateEntityService: ActivateEntityService,
    private clientConfigService: ClientConfigService,
    private consolidationService: ConsolidationService
  ) {}

  ngOnInit(): void {
    this.metricStructureService.metric$.pipe(take(1)).subscribe((metric) => {
      this.metric = metric;
    });
    this.updateIsRepeatableGroup();
  }

  ngOnChanges(): void {
    this.groupForm = new MetricStructureGroupPropertiesForm(this.translateService, this.valueDefinitionGroup);
    this.isNewGroup = !this.valueDefinitionGroup.id.length;
    this.disableFormIfMetricGroupIsDeactivated();
    this.canDeleteGroup();
    this.updateIsRepeatableGroup();
  }

  ngOnDestroy(): void {
    this.cleanTemporaryGroup();
    this.groupForm?.destroy();
  }

  private updateIsRepeatableGroup() {
    this.metricStructureService.updateIsRepeatableGroup(this.valueDefinitionGroup.repeatable);
  }

  public displayRepeatableGroupConsolidationWarningMessage(): boolean {
    return this.consolidationService.areAnyConsolidationRulesInGroup(
      this.valueDefinitionGroup,
      ConsolidationRules.manual
    );
  }

  public canDeleteGroup(): void {
    this.isGroupDeletionEnabled = !this.valueDefinitionGroup.value_definitions?.length && !this.isNewGroup;
  }

  public deleteGroup(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.valueDefinitionGroup.value_definitions?.length) {
      this.setUpdating(true);
      this.metricsService
        .deleteGroup(this.valueDefinitionGroup.metric_id, this.valueDefinitionGroup.id)
        .pipe(finalize(() => this.setUpdating(false)))
        .subscribe((response) => {
          this.metricStructureService.updateMetric(response.data);
          this.metricStructureService.updateSelectedItem(undefined);
          this.closeProperties();
        });
    }
  }

  public addGroupForm(): void {
    if (!this.metric || !this.groupForm) {
      return;
    }

    const valueDefGroupPayload: Partial<ValueDefinitionGroup> = this.groupForm.toModel();

    this.setUpdating(true);
    this.metricsService
      .createGroup(this.metric.id, { ...valueDefGroupPayload, position: this.valueDefinitionGroup.position })
      .pipe(finalize(() => this.setUpdating(false)))
      .subscribe((response) => {
        const selectedGroup =
          response.data.value_definition_groups?.find((x) => x.position === this.valueDefinitionGroup.position) ||
          response.data.value_definition_groups?.[response.data.value_definition_groups.length - 1];
        this.metricStructureService.updateMetric(response.data);
        this.metricStructureService.updateSelectedItem(selectedGroup);
        this.metricStructureService.setIsCreatingField(false);
      });
  }

  public saveGroupForm(): void {
    if (!this.groupForm) {
      return;
    }

    const valueDefGroupPayload: Partial<ValueDefinitionGroup> = this.groupForm.toModel();

    this.setUpdating(true);
    this.metricsService
      .updateGroup(this.valueDefinitionGroup.metric_id, this.valueDefinitionGroup.id, valueDefGroupPayload)
      .pipe(finalize(() => this.setUpdating(false)))
      .subscribe({
        next: (response: ApiResponse<Metric>) => {
          this.metricStructureService.updateMetric(response.data);
          this.metricStructureService.updateIsRepeatableGroup(valueDefGroupPayload.repeatable ?? false);
        },
        error: (errorResponse: unknown) => {
          try {
            this.metricStructureService.handleUpdateValidationErrors(errorResponse as HttpErrorResponse);
          } catch (_) {
            this.errorManagerService.handleError(errorResponse as HttpErrorResponse);
          }
        },
      });
  }

  public closeProperties(): void {
    this.closeGroup.emit();
  }

  private setUpdating(updating: boolean): void {
    this.metricStructureService.updateIsMetricUpdating(updating);
  }

  private cleanTemporaryGroup(): void {
    if (this.valueDefinitionGroup.id === '') {
      setTimeout(() => {
        this.metricStructureService.removeTemporaryGroup(
          this.valueDefinitionGroup,
          this.valueDefinitionGroup.position ? this.valueDefinitionGroup.position - 1 : 0
        );

        this.metricStructureService.setIsCreatingField(false);
      });
    }
  }

  public deactivateFieldGroup(): void {
    this.deactivateEntityService.deactivate(
      DeactivateEntityTypes.GROUP,
      this.metric?.id || '',
      undefined,
      this.valueDefinitionGroup
    );
  }

  public activateValueDefinitionGroup(): void {
    this.activateEntityService.activateValueDefinitionGroup(this.metric?.id || '', this.valueDefinitionGroup);
  }

  private disableFormIfMetricGroupIsDeactivated() {
    if (
      this.clientConfigService.areAnyFeatureFlagsEnabled(['metric_structure_deactivation_enabled']) &&
      !this.valueDefinitionGroup.active
    ) {
      this.formDisabled = true;
    } else {
      this.formDisabled = false;
    }
  }
}
