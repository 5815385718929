import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { BaseService } from '../../common/base/base.service';
import { TranslateService } from '../../common/translate/translate.service';
import { AdminDocumentsService } from '../admin-documents/admin-documents.service';

import { SearchOptions, ApiResponse, FiscalYear, ItemType } from '../../../models';
import {
  CommonCategories,
  CommonTypes,
  FrameworkMetricSort,
  FrameworkMetricStatus,
  LibraryPeersPeerSort,
  LibraryPeersPeerStatus,
  MetricsIndicatorSort,
  PeerMetricCategories,
  PeerMetricSort,
  SearchSort,
  SelectionSetItemSort,
  SelectionSetSort,
  StandardCodesSort,
  UnitGroupItemSort,
  UnitGroupSort,
  UsersUserStatus,
} from '../../../translations';
import { AdminMetricService } from '../admin-metric/admin-metric.service';
import { AdminSelectionSetService } from '../admin-selection-set/admin-selection-set.service';
import { AdminUnitGroupService } from '../admin-unit-group/admin-unit-group.service';
import { AdminFrameworkService } from '../admin-framework/admin-framework.service';
import { AdminStandardCodeService } from '../admin-standard-code/admin-standard-code.service';
import { AdminTopicService } from '../admin-topic/admin-topic.service';
import { AdminIndustryService } from '../admin-industry/admin-industry.service';
import { AdminPeerService } from '../admin-peer/admin-peer.service';
import { ApiService } from '../../common/api/api.service';
import { AdminResourcesService } from '../admin-resources/admin-resources.service';
import { AdminUnitService } from '../admin-unit/admin-unit.service';

@Injectable({
  providedIn: 'root',
})
export class AdminSearchService {
  pageSize: number;

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
    private tanslateService: TranslateService,
    private documentsService: AdminDocumentsService,
    private adminSelectionSetService: AdminSelectionSetService,
    private adminUnitGroupService: AdminUnitGroupService,
    private adminFrameworkService: AdminFrameworkService,
    private adminStandardCodeService: AdminStandardCodeService,
    private adminTopicService: AdminTopicService,
    private adminIndustryService: AdminIndustryService,
    private adminMetricService: AdminMetricService,
    private adminPeerService: AdminPeerService,
    private adminResourcesService: AdminResourcesService,
    private adminUnitService: AdminUnitService
  ) {
    this.pageSize = this.apiService.apiConfig.pageSize;
  }

  search(searchOptions: SearchOptions): Observable<ApiResponse<any[]>> {
    switch (searchOptions.item_type) {
      // TODO: replace with respective service calls
      case ItemType.docs_doc:
        return this.adminResourcesService.search(searchOptions);
      case ItemType.docs_report:
        return this.adminResourcesService.search(searchOptions);
      case ItemType.docs_resource:
        return this.adminResourcesService.search(searchOptions);
      case ItemType.library_peers_report:
        return this.adminResourcesService.search(searchOptions);
      case ItemType.library_peers_peer:
        return this.adminPeerService.search(searchOptions);
      case ItemType.metrics_indicator:
      case ItemType.metrics_template:
      case ItemType.metrics_code:
      case ItemType.sources_peer:
      case ItemType.reports_report:
      case ItemType.reports_template:
      case ItemType.benchmarks_benchmark:
        return of({ meta: {}, errors: [], data: [] });
      case ItemType.selection_set:
        return this.adminSelectionSetService.search(searchOptions);
      case ItemType.selection_set_item:
        return this.adminSelectionSetService.searchItems(searchOptions);
      case ItemType.unit_group:
        return this.adminUnitGroupService.search(searchOptions);
      case ItemType.unit_group_item:
        return this.adminUnitGroupService.searchItems(searchOptions);
      case ItemType.metrics_framework:
      case ItemType.standard_codes_framework:
        return this.adminFrameworkService.searchFrameworks(searchOptions);
      case ItemType.admin_standard_codes:
        return this.adminStandardCodeService.search(searchOptions);
      case ItemType.framework_metric:
      case ItemType.peer_metric:
      case ItemType.admin_templates_metrics:
      case ItemType.related_metrics:
        return this.adminMetricService.search(searchOptions);
      default:
        throw new Error(`Search item type not supported: ${searchOptions.item_type}`);
    }
  }

  searchResources(resourceType: string, itemType?: string): Observable<ApiResponse<any[]>> {
    switch (resourceType) {
      case 'year':
        // TODO: get active fiscal year instead of current calendar year
        // Partial here since without organization context there is no actual fiscal year
        const yearItems: Partial<FiscalYear>[] = [];
        const currentYear: number = new Date().getFullYear();
        let year: number = currentYear + 1;
        while (year >= 2015) {
          yearItems.push({
            id: year.toString(),
            year,
          });
          year--;
        }
        return of({ data: yearItems, errors: [], meta: {} });
      case 'source':
        switch (itemType) {
          case 'unit':
            return this.adminPeerService.listBusinessUnits();
          case 'library_peers_report':
            return this.adminPeerService.listPeers();
          default:
            throw new Error(`Source is not supported for : ${itemType}`);
        }
      case 'topic':
        return this.adminTopicService.listTopicCategories();
      case 'category':
        switch (itemType) {
          // TODO: replace with categories
          case 'metrics_indicator':
          case 'docs_report':
          case 'docs_resource':
          case 'docs_doc':
          case 'reports_template':
            return of({ meta: {}, errors: [], data: [] });
          case 'peer_metric':
            return of({
              data: this.tanslateService.listResources(PeerMetricCategories),
              errors: [],
              meta: {},
            });
          case 'library_peers_report':
            return of({
              data: this.tanslateService.listResources(CommonCategories),
              errors: [],
              meta: {},
            });
          default:
            throw new Error(`Category is not supported for : ${itemType}`);
        }
      case 'type':
        return of({
          data: this.tanslateService.listResources(CommonTypes),
          errors: [],
          meta: {},
        });
      case 'framework':
        return this.adminFrameworkService.listFrameworks();
      case 'industry':
        return this.adminIndustryService.listAllIndustries();
      case 'tag':
        return of({ meta: {}, errors: [], data: [] });
      case 'status':
        switch (itemType) {
          // TODO: replace with status
          case 'docs_doc':
          case 'metrics_indicator':
          case 'reports_report':
            return of({ meta: {}, errors: [], data: [] });
          case 'framework_metric':
          case ItemType.admin_templates_metrics:
            return of({
              data: this.tanslateService.listResources(FrameworkMetricStatus),
              errors: [],
              meta: {},
            });
          case 'library_peers_peer':
            return of({
              data: this.tanslateService.listResources(LibraryPeersPeerStatus),
              errors: [],
              meta: {},
            });
          case ItemType.users_user: {
            return of({
              data: this.tanslateService.listResources(UsersUserStatus),
              errors: [],
              meta: {},
            });
          }
          default:
            throw new Error(`Status is not supported for : ${itemType}`);
        }
      case 'author':
        return of({ meta: {}, errors: [], data: [] });
      case 'sort':
        switch (itemType) {
          case 'metrics_indicator':
            return of({
              data: this.tanslateService.listResources(MetricsIndicatorSort),
              errors: [],
              meta: {},
            });
          case 'selection_set':
            return of({
              data: this.tanslateService.listResources(SelectionSetSort),
              errors: [],
              meta: {},
            });
          case 'selection_set_item':
            return of({
              data: this.tanslateService.listResources(SelectionSetItemSort),
              errors: [],
              meta: {},
            });
          case 'unit_group':
            return of({
              data: this.tanslateService.listResources(UnitGroupSort),
              errors: [],
              meta: {},
            });
          case 'unit_group_item':
            return of({
              data: this.tanslateService.listResources(UnitGroupItemSort),
              errors: [],
              meta: {},
            });
          case 'standard_codes':
            return of({
              data: this.tanslateService.listResources(StandardCodesSort),
              errors: [],
              meta: {},
            });
          case 'framework_metric':
            return of({
              data: this.tanslateService.listResources(FrameworkMetricSort),
              errors: [],
              meta: {},
            });
          case 'peer_metric':
            return of({
              data: this.tanslateService.listResources(PeerMetricSort),
              errors: [],
              meta: {},
            });
          case 'library_peers_peer':
            return of({
              data: this.tanslateService.listResources(LibraryPeersPeerSort),
              errors: [],
              meta: {},
            });
          default:
            return of({ data: this.tanslateService.listResources(SearchSort), errors: [], meta: {} });
        }
      case 'unit':
        switch (itemType) {
          case 'families':
            return this.adminUnitService.listUnitFamilies();
          default:
            return this.adminUnitService.listUnits(itemType);
        }
      case 'min_metrics':
        return this.adminMetricService.listMinimal();
      case 'selection_set':
        return this.adminSelectionSetService.search();
      case 'selection_set_item':
        return this.adminSelectionSetService.searchItems({
          query: {
            keywords: '',
          },
          filters: {},
          custom_filters: { id: itemType },
        });
      case 'org':
        return of({ meta: {}, errors: [], data: [] });
      case 'template':
        switch (itemType) {
          case 'report':
            return of({ meta: {}, errors: [], data: [] });
          default:
            throw new Error(`Template is not supported for : ${itemType}`);
        }
      case 'start':
      case 'user':
        return of({ meta: {}, errors: [], data: [] });
      default:
        throw new Error(`Resource type not supported: ${resourceType}`);
    }
  }
}
