import { Injectable } from '@angular/core';
import {
  ChoiceFieldWidgetType,
  ChoiceTypeDetails,
  TextTypeDetails,
  Value,
  ValueDefinition,
  ValueDefinitionDisplayType,
  ValueDefinitionSize,
  ValueDefinitionType,
} from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ValueDefinitionService {
  public isChoiceField(displayType: ValueDefinitionDisplayType): boolean {
    return [
      ValueDefinitionDisplayType.choice,
      ValueDefinitionDisplayType.choice_multiple,
      ValueDefinitionDisplayType.choice_searchable,
      ValueDefinitionDisplayType.choice_radio,
    ].includes(displayType);
  }

  public isNumericField(displayType: ValueDefinitionDisplayType): boolean {
    return [
      ValueDefinitionDisplayType.integer,
      ValueDefinitionDisplayType.decimal,
      ValueDefinitionDisplayType.calculated,
    ].includes(displayType);
  }

  public getDisplayType(valueRef: Value | ValueDefinition): ValueDefinitionDisplayType {
    switch (valueRef.type) {
      case ValueDefinitionType.text: {
        const textValueRef = valueRef as Value<TextTypeDetails>;
        if (textValueRef.type_details.rich_text) {
          return ValueDefinitionDisplayType.text_rich;
        } else if (textValueRef.type_details.textarea) {
          return ValueDefinitionDisplayType.text_area;
        } else {
          return ValueDefinitionDisplayType.text_simple;
        }
      }
      case ValueDefinitionType.tip: {
        return ValueDefinitionDisplayType.tip;
      }
      case ValueDefinitionType.subtitle:
      case ValueDefinitionType.label: {
        return ValueDefinitionDisplayType.subtitle;
      }
      case ValueDefinitionType.date: {
        return ValueDefinitionDisplayType.date;
      }
      case ValueDefinitionType.datetime: {
        return ValueDefinitionDisplayType.datetime;
      }
      case ValueDefinitionType.boolean: {
        return ValueDefinitionDisplayType.boolean;
      }
      case ValueDefinitionType.file: {
        return ValueDefinitionDisplayType.file;
      }
      case ValueDefinitionType.file_v2: {
        return ValueDefinitionDisplayType.file_v2;
      }
      case ValueDefinitionType.calculated: {
        return ValueDefinitionDisplayType.calculated;
      }
      case ValueDefinitionType.document: {
        return ValueDefinitionDisplayType.document;
      }
      case ValueDefinitionType.integer: {
        return ValueDefinitionDisplayType.integer;
      }
      case ValueDefinitionType.decimal: {
        return ValueDefinitionDisplayType.decimal;
      }
      case ValueDefinitionType.choice: {
        const choiceValueRef = valueRef as Value<ChoiceTypeDetails>;
        switch (choiceValueRef.type_details.widget_type) {
          case ChoiceFieldWidgetType.multi_chip: {
            return ValueDefinitionDisplayType.choice_searchable;
          }
          case ChoiceFieldWidgetType.checkboxes: {
            return ValueDefinitionDisplayType.choice_multiple;
          }
          default: {
            return ValueDefinitionDisplayType.choice;
          }
        }
      }
      case ValueDefinitionType.metric: {
        return ValueDefinitionDisplayType.metric;
      }
    }
  }

  public isLargeField(valueRef: Value | ValueDefinition): boolean {
    return [
      ValueDefinitionDisplayType.choice_searchable,
      ValueDefinitionDisplayType.choice_radio,
      ValueDefinitionDisplayType.file,
      ValueDefinitionDisplayType.subtitle,
      ValueDefinitionDisplayType.tip,
      ValueDefinitionDisplayType.text_area,
      ValueDefinitionDisplayType.text_rich,
    ].includes(this.getDisplayType(valueRef));
  }

  public getValueDefinitionSize(value: Value | ValueDefinition): ValueDefinitionSize {
    if (this.isLargeField(value)) {
      return ValueDefinitionSize.large;
    }
    return value.size;
  }

  public isFocusableDisplayField(valueType: ValueDefinitionDisplayType): boolean {
    return ![
      ValueDefinitionDisplayType.document,
      ValueDefinitionDisplayType.label,
      ValueDefinitionDisplayType.subtitle,
      ValueDefinitionDisplayType.tip,
      ValueDefinitionDisplayType.metric,
    ].includes(valueType);
  }

  static isEditableField(valueType: ValueDefinitionType): boolean {
    return ![
      ValueDefinitionType.calculated,
      ValueDefinitionType.document,
      ValueDefinitionType.label,
      ValueDefinitionType.subtitle,
      ValueDefinitionType.tip,
      ValueDefinitionType.metric,
    ].includes(valueType);
  }
}
