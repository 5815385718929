<div
  class="card-container"
  *ngFor="let tableTotal of tableTotals; let first = first; let last = last"
  (click)="tableTotalClick.emit(tableTotal)"
  [attr.data-testid]="tableTotal.label + 'card'"
  libRightClickMenuTrigger
  [matMenuTrigger]="trigger"
  [menuData]="{ tableTotal: tableTotal, first: first, last: last }"
  (position)="menuPosition = $event"
  [ngClass]="{ inactive: isDisabled }"
>
  <div class="card card-condensed table-total-card" data-testid="table-total-card">
    <div class="total-info">
      <div class="item-title mat-body-2 color-primary-900 mb-1">{{ tableTotal.label }}</div>
      <div
        class="item-subtitle caption2 color-grey-800 formula"
        [title]="tableTotal.type_details.formula | metricTableTotalFormula"
      >
        {{ tableTotal.type_details.formula | metricTableTotalFormula }}
      </div>
      <div class="mt-1 color-grey-800">{{ tableTotal.type_details.units | unitSymbol : true }}</div>
    </div>
    <button
      class="context-menu"
      [matMenuTriggerFor]="menu"
      [matMenuTriggerData]="{ menuData: { tableTotal: tableTotal, first: first, last: last } }"
      [attr.data-testid]="tableTotal.label + 'menu'"
      (click)="$event.stopPropagation()"
      [disabled]="isDisabled"
    >
      <mat-icon class="icon-sm" svgIcon="more"></mat-icon>
    </button>
  </div>
</div>

<div
  class="hidden-menu-trigger"
  [ngStyle]="{ left: menuPosition.x, top: menuPosition.y }"
  #trigger="matMenuTrigger"
  [matMenuTriggerFor]="menu"
></div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-menuData="menuData">
    <button
      *ngIf="!menuData.first"
      mat-menu-item
      (click)="moveTableTotalCard.emit({ tableTotal: menuData.tableTotal, direction: eMoveDirection.up })"
    >
      <mat-icon svgIcon="arrow-full-up" class="icon-sm"></mat-icon>
      <span>{{ "Move Up" | translate }}</span>
    </button>
    <button
      *ngIf="!menuData.last"
      mat-menu-item
      (click)="moveTableTotalCard.emit({ tableTotal: menuData.tableTotal, direction: eMoveDirection.down })"
    >
      <mat-icon svgIcon="arrow-full-down" class="icon-sm"></mat-icon>
      <span>{{ "Move Down" | translate }}</span>
    </button>
    <mat-divider *ngIf="menuData.first !== menuData.last"></mat-divider>
    <button mat-menu-item (click)="deleteTableTotal(menuData.tableTotal)" class="color-error">
      <mat-icon class="icon-sm" svgIcon="trash"></mat-icon>
      <span>{{ "Delete" | translate }}</span>
    </button>
  </ng-template>
</mat-menu>
