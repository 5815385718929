<ng-container [ngSwitch]="data.dataFormatType">
  <!--choice: multiple=false, searchable=true-->
  <ng-container *ngSwitchCase="ValueDefinitionType.choice">
    <lib-data-format-searchable-select-field
      [data]="data"
      [controlName]="controlName"
      [displayChoices]="getDisplayedChoicesList()"
      (delete)="deleteValue()"
    ></lib-data-format-searchable-select-field>
  </ng-container>

  <!--choice_multiple: checkbox: multiple=true, searchable=false-->
  <ng-container *ngSwitchCase="ValueDefinitionType.choice_multiple">
    <div class="multi-select" tabindex="0" id="{{ data.id }}">
      <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
      <div class="fx-col w-100 choice-formfield" [ngClass]="{ error: control.touched && control.invalid }">
        <div class="fx-col">
          <mat-checkbox
            color="primary"
            *ngFor="let option of getDisplayedChoicesList()"
            [checked]="data.data.value?.includes(option)"
            [value]="option"
            (change)="onMultiSelectToggle($event, option)"
            class="pb-2 mat-body-2"
          >
            {{ option }}</mat-checkbox
          >
        </div>
        <mat-hint *ngIf="control.valid || control.touched">{{ data.data.hint }}</mat-hint>
        <mat-error *ngIf="control.touched && control.hasError('required')">
          {{ requiredErrorMsg }}
        </mat-error>
      </div>
    </div>
  </ng-container>

  <!--choice_searchable: autocomplete: multiple=true, searchable=true-->
  <ng-container *ngSwitchCase="ValueDefinitionType.choice_searchable">
    <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
    <mat-form-field class="w-100" floatLabel="never">
      <mat-chip-list #chipList class="w-100">
        <mat-chip *ngFor="let option of control.value" (removed)="removeChoice(option)">
          <span class="mat-body-1"> {{ option }}</span>
          <mat-icon matChipRemove svgIcon="close"></mat-icon>
        </mat-chip>
        <input
          #searchableSelectInput
          class="mat-body-1"
          [formControl]="inputControl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          (matChipInputTokenEnd)="add($event)"
          id="{{ data.id }}"
        />
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let choice of filteredChoices | async" class="multiline-mat-option" [value]="choice">
          <span class="mat-body-1"> {{ choice }}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-hint>{{ data.data.hint }}</mat-hint>
    </mat-form-field>
  </ng-container>

  <!--choice_radio: radio : multiple=false, searchable=false-->
  <ng-container *ngSwitchCase="ValueDefinitionType.choice_radio">
    <div class="choice-radio" tabindex="0" id="{{ data.id }}">
      <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
      <div class="choice-formfield">
        <mat-radio-group color="primary" class="fx-col" [formControl]="control">
          <mat-radio-button *ngFor="let option of getDisplayedChoicesList()" [value]="option" class="pb-2 mat-body-2">
            {{ option }}
          </mat-radio-button>
        </mat-radio-group>
        <mat-hint>{{ data.data.hint }}</mat-hint>
      </div>
    </div>
  </ng-container>
</ng-container>
