import { Component, Input } from '@angular/core';

import { Metric, MetricCategory, RelatedMetric, RelatedMetricSource } from '../../../../../models';
import { Required } from '../../../../../decorators';

@Component({
  selector: 'lib-metric-settings-details',
  templateUrl: './metric-settings-details.component.html',
  styleUrls: ['./metric-settings-details.component.scss'],
})
export class MetricSettingsDetailsComponent {
  @Input() @Required() metric!: Metric;

  public readonly eRelatedMetricSource = RelatedMetricSource;
  public readonly eMetricCategory = MetricCategory;

  public filterRelatedMetrics = (relatedMetric: RelatedMetric): boolean =>
    relatedMetric.original_metric_ids.includes(this.metric.id);
}
