import {
  MetricTableCalculationTypeDetailFormula,
  MetricTableCalculationTypeDetailFormulaFilter,
  MetricTableCalculationTypeDetailFormulaOperation,
} from '../models';
import { MetricTableCalculationTypeDetailFormulaFilterBuilder } from './metric-table-calculation-type-detail-formula-filter-builder';

export class MetricTableCalculationTypeDetailFormulaBuilder {
  private readonly _metricTableCalculationTypeDetailFormula: MetricTableCalculationTypeDetailFormula;

  constructor() {
    this._metricTableCalculationTypeDetailFormula = {
      operation: MetricTableCalculationTypeDetailFormulaOperation.SUM,
      columns: ['Input Column'],
      filters: [new MetricTableCalculationTypeDetailFormulaFilterBuilder().build()],
    };
  }

  operation(
    operation: MetricTableCalculationTypeDetailFormulaOperation
  ): MetricTableCalculationTypeDetailFormulaBuilder {
    this._metricTableCalculationTypeDetailFormula.operation = operation;
    return this;
  }

  columns(columns: string[]): MetricTableCalculationTypeDetailFormulaBuilder {
    this._metricTableCalculationTypeDetailFormula.columns = columns;
    return this;
  }

  filters(filters: MetricTableCalculationTypeDetailFormulaFilter[]): MetricTableCalculationTypeDetailFormulaBuilder {
    this._metricTableCalculationTypeDetailFormula.filters = filters;
    return this;
  }

  build(): MetricTableCalculationTypeDetailFormula {
    return this._metricTableCalculationTypeDetailFormula;
  }
}
