import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ApplicationApiDefinition,
  DashboardPlotUpdateRequest,
  DashboardSingleDataPointWidgetCreationRequest,
  DashboardSingleDataPointWidgetUpdateRequest,
  DashboardStackBarWidgetCreationRequest,
  DashboardStackBarChartWidgetUpdateRequest,
  DashboardWidget,
  DashboardValueDefinitionTargetWidgetCreationRequest,
  DashboardValueDefinitionTargetWidgetUpdateRequest,
} from '../../../models';
import { ApiService } from '../../common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientDashboardWidgetsService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.dashboards;
  }

  public createSingleDataPointWidget(
    dashboardId: string,
    singleDataPointWidgetCreationRequest: DashboardSingleDataPointWidgetCreationRequest
  ): Observable<ApiResponse<DashboardWidget>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/dashboards/${dashboardId}/single_data_point_widget`,
      singleDataPointWidgetCreationRequest
    );
  }

  public createStackBarChartWidget(
    dashboardId: string,
    stackBarWidgetCreationRequest: DashboardStackBarWidgetCreationRequest
  ): Observable<ApiResponse<DashboardWidget>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/dashboards/${dashboardId}/stack_bar_chart_widget`,
      stackBarWidgetCreationRequest
    );
  }

  public createValueDefinitionTargetWidget(
    dashboardId: string,
    valueDefinitionTargetWidgetCreationRequest: DashboardValueDefinitionTargetWidgetCreationRequest
  ): Observable<ApiResponse<DashboardWidget>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/dashboards/${dashboardId}/value_definition_target_widget`,
      valueDefinitionTargetWidgetCreationRequest
    );
  }

  public updateDashboardPlot(
    dashboardId: string,
    dashboardPlotRequest: DashboardPlotUpdateRequest
  ): Observable<ApiResponse<DashboardWidget[]>> {
    return this.apiService.patch(
      `${this.servicePath}${this.resource}/dashboards/${dashboardId}/widgets/plot`,
      dashboardPlotRequest
    );
  }

  public removeDashboardWidget(dashboardId: string, dashboardWidgetId: string): Observable<ApiResponse> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/dashboards/${dashboardId}/dashboard_widgets/${dashboardWidgetId}`
    );
  }

  public updateSingleDataPointWidget(
    dashboardId: string,
    dashboardWidgetId: string,
    singleDataPointWidgetUpdateRequest: DashboardSingleDataPointWidgetUpdateRequest
  ): Observable<ApiResponse<DashboardWidget>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/dashboards/${dashboardId}/single_data_point_widget/${dashboardWidgetId}`,
      singleDataPointWidgetUpdateRequest
    );
  }

  public updateStackBarChartWidget(
    dashboardId: string,
    dashboardWidgetId: string,
    stackBarChartWidgetUpdateRequest: DashboardStackBarChartWidgetUpdateRequest
  ): Observable<ApiResponse<DashboardWidget>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/dashboards/${dashboardId}/stack_bar_chart_widget/${dashboardWidgetId}`,
      stackBarChartWidgetUpdateRequest
    );
  }

  public updateValueDefinitionTargetWidget(
    dashboardId: string,
    dashboardWidgetId: string,
    valueDefinitionTargetWidgetUpdateRequest: DashboardValueDefinitionTargetWidgetUpdateRequest
  ): Observable<ApiResponse<DashboardWidget>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/dashboards/${dashboardId}/value_definition_target_widget/${dashboardWidgetId}`,
      valueDefinitionTargetWidgetUpdateRequest
    );
  }
}
