import { IntegrationPartnerLogin, PlatformIntegrationType } from '../models';
import { v4 as uuidv4 } from 'uuid';
import { faker } from '@faker-js/faker';

export class IntegrationPartnerLoginBuilder {
  private readonly _integrationPartnerLogin: IntegrationPartnerLogin;

  constructor() {
    this._integrationPartnerLogin = {
      client_id: uuidv4(),
      url: faker.internet.url(),
      enabled: false,
      integration_type: PlatformIntegrationType.GREEN_PROJECT,
    };
  }

  client_id(client_id: string): IntegrationPartnerLoginBuilder {
    this._integrationPartnerLogin.client_id = client_id;
    return this;
  }

  url(url: string): IntegrationPartnerLoginBuilder {
    this._integrationPartnerLogin.url = url;
    return this;
  }

  enabled(enabled: boolean): IntegrationPartnerLoginBuilder {
    this._integrationPartnerLogin.enabled = enabled;
    return this;
  }

  integration_type(integration_type: PlatformIntegrationType): IntegrationPartnerLoginBuilder {
    this._integrationPartnerLogin.integration_type = integration_type;
    return this;
  }

  build(): IntegrationPartnerLogin {
    return this._integrationPartnerLogin;
  }
}
