import { FiscalYearAuditTrailRow, FiscalYearAuditTrailStatus } from '../models';
import { faker } from '@faker-js/faker';
import { formatDate } from '@angular/common';

export class FiscalYearAuditTrailRowBuilder {
  private readonly _fiscalYearAuditTrailRow: FiscalYearAuditTrailRow;

  constructor() {
    this._fiscalYearAuditTrailRow = {
      year: new Date().getFullYear(),
      event_type: FiscalYearAuditTrailStatus.OPEN,
      user_name: faker.name.fullName(),
      timestamp: formatDate(new Date(), 'yyyy-MM-dd', 'en-CA'),
      notes: faker.lorem.paragraph(),
    };
  }

  year(year: number): FiscalYearAuditTrailRowBuilder {
    this._fiscalYearAuditTrailRow.year = year;
    return this;
  }

  eventType(eventType: FiscalYearAuditTrailStatus): FiscalYearAuditTrailRowBuilder {
    this._fiscalYearAuditTrailRow.event_type = eventType;
    return this;
  }

  userName(userName: string): FiscalYearAuditTrailRowBuilder {
    this._fiscalYearAuditTrailRow.user_name = userName;
    return this;
  }

  timestamp(timestamp: string): FiscalYearAuditTrailRowBuilder {
    this._fiscalYearAuditTrailRow.timestamp = timestamp;
    return this;
  }

  notes(notes: string): FiscalYearAuditTrailRowBuilder {
    this._fiscalYearAuditTrailRow.notes = notes;
    return this;
  }

  build(): FiscalYearAuditTrailRow {
    return this._fiscalYearAuditTrailRow;
  }
}
