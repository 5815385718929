import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ValueDefinitionSize } from '../../../models';

@Component({
  selector: 'lib-slider',
  templateUrl: './slider.component.html',
})
export class SliderComponent {
  @Input() label = '';
  @Input() control?: UntypedFormControl;
  @Input() hint?: string;
  @Input() size: ValueDefinitionSize = ValueDefinitionSize.large;

  @Output() onSliderToggle: EventEmitter<boolean> = new EventEmitter();
}
