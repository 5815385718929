import {
  Framework,
  Industry,
  ReportCategoryType,
  ReportIntegrationType,
  TemplateReport,
  TemplateReportVersion,
} from '../models';
import { v4 as uuidv4 } from 'uuid';

export class TemplateReportBuilder {
  private readonly _templateReport: TemplateReport;

  constructor() {
    this._templateReport = {
      id: uuidv4(),
      code: 'templateReportCode',
      name: 'templateReportName',
      active: true,
      reference_v2: false,
      category: ReportCategoryType.questionnaire,
      versions: [],
      frameworks: [],
      industries: [],
      tags: [],
      topics: [],
    };
  }

  id(id: string): TemplateReportBuilder {
    this._templateReport.id = id;
    return this;
  }

  active(active: boolean): TemplateReportBuilder {
    this._templateReport.active = active;
    return this;
  }

  name(name: string): TemplateReportBuilder {
    this._templateReport.name = name;
    return this;
  }

  category(category: ReportCategoryType): TemplateReportBuilder {
    this._templateReport.category = category;
    return this;
  }

  versions(versions: TemplateReportVersion[]): TemplateReportBuilder {
    this._templateReport.versions = versions;
    return this;
  }

  frameworks(frameworks: Framework[]): TemplateReportBuilder {
    this._templateReport.frameworks = frameworks;
    return this;
  }

  industries(industries: Industry[]): TemplateReportBuilder {
    this._templateReport.industries = industries;
    return this;
  }

  integration(integration: ReportIntegrationType): TemplateReportBuilder {
    this._templateReport.integration = integration;
    return this;
  }

  build(): TemplateReport {
    return this._templateReport;
  }
}
