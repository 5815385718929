import { Component, Input, OnChanges } from '@angular/core';
import {
  ChoiceFieldWidgetType,
  ChoiceTypeDetails,
  ValueDefinition,
  ValueDefinitionDisplayType,
} from '../../../../models';
import { SearchService } from '../../../../search';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Required } from '../../../../decorators';

@Component({
  selector: 'lib-metric-structure-choice-field',
  templateUrl: './metric-structure-choice-field.component.html',
  styleUrls: ['./metric-structure-choice-field.component.scss'],
})
export class MetricStructureChoiceFieldComponent implements OnChanges {
  @Input() @Required() valueDefinitionDisplayType!: ValueDefinitionDisplayType;
  @Input() @Required() valueDefinition!: ValueDefinition<ChoiceTypeDetails>;
  @Input() @Required() typeDetails!: ChoiceTypeDetails;
  @Input() isTriggered: boolean = false;
  @Input() isTrigger: boolean = false;
  readonly eValueDefinitionDisplayType: typeof ValueDefinitionDisplayType = ValueDefinitionDisplayType;
  readonly eChoiceWidgetType: typeof ChoiceFieldWidgetType = ChoiceFieldWidgetType;

  public options$?: Observable<string[]> = of([]);

  constructor(private searchService: SearchService) {}

  ngOnChanges(): void {
    if (this.typeDetails.selection_set_id != null) {
      this.fetchOptions(this.typeDetails.selection_set_id);
    } else if (this.typeDetails.choices != null) {
      this.options$ = of(this.typeDetails.choices);
    } else {
      this.options$ = of([]);
    }
  }

  private fetchOptions(selectionSetId: string): void {
    this.options$ = this.searchService
      .searchResources('selection_set_item', selectionSetId)
      .pipe(map((res) => res.map((x) => x.item.name)));
  }
}
