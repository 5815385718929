<mat-menu #errorDetailsMenu="matMenu" yPosition="above">
  <ng-template matMenuContent>
    <div class="p-2">
      {{ calculationErrorMsg }}
    </div>
  </ng-template>
</mat-menu>

<div class="numeric-input-container">
  <div
    class="error-menu-trigger"
    #errorDetailsMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="errorDetailsMenu"
  ></div>

  <div
    *ngIf="control"
    [class]="'formfield ' + size"
    [ngClass]="{ required: required, disabled: control.disabled || readonly }"
    data-testid="numeric-field"
  >
    <ng-container
      *ngIf="numberMaskConfig$ | async as numberMaskConfig"
      [ngSwitch]="control | numericInputType : isMaskDisabled : isInputDisabled"
    >
      <lib-form-field-label
        [label]="label"
        [control]="control"
        [for]="_inputId"
        [displayReset]="!isCalculated"
        [metricTableDefinition]="metricTableDefinition"
      ></lib-form-field-label>
      <mat-form-field class="maximum-field-width">
        <input
          *ngSwitchCase="eNumericInputType.notApplicable"
          #input
          matInput
          class="color-grey-900"
          type="text"
          [formControl]="control"
          [errorStateMatcher]="errorStateMatcher"
          [attr.aria-label]="_inputId"
          [id]="_inputId"
          [readonly]="isInputDisabled"
          data-testid="numeric-field-input-not-applicable"
        />
        <input
          *ngSwitchCase="eNumericInputType.maskDisabled"
          matInput
          class="color-grey-900"
          type="number"
          [formControl]="control"
          [errorStateMatcher]="errorStateMatcher"
          [attr.aria-label]="_inputId"
          [id]="_inputId"
          [placeholder]="placeholder"
          [readonly]="isInputDisabled"
          libFocusState
          data-testid="numeric-field-input-mask-disabled"
        />
        <input
          *ngSwitchCase="eNumericInputType.readonly"
          #input
          matInput
          class="color-grey-900"
          type="text"
          [formControl]="controlNumberDisplay"
          [errorStateMatcher]="errorStateMatcher"
          [attr.aria-label]="_inputId"
          [id]="_inputId"
          [placeholder]="placeholder"
          [readonly]="true"
          [mask]="maskNumberDisplay"
          [thousandSeparator]="numberMaskConfig.thousandSeparator"
          [decimalMarker]="numberMaskConfig.decimalMarker"
          [allowNegativeNumbers]="true"
          [clearIfNotMatch]="false"
          libFocusState
          data-testid="numeric-field-input-display"
        />
        <input
          *ngSwitchCase="eNumericInputType.full"
          #input
          matInput
          class="color-grey-900"
          type="text"
          [formControl]="control"
          [errorStateMatcher]="errorStateMatcher"
          [attr.aria-label]="_inputId"
          [id]="_inputId"
          [placeholder]="placeholder"
          [readonly]="isInputDisabled"
          [mask]="maskInput"
          [thousandSeparator]="numberMaskConfig.thousandSeparator"
          [decimalMarker]="numberMaskConfig.decimalMarker"
          [allowNegativeNumbers]="true"
          [clearIfNotMatch]="false"
          libFocusState
          data-testid="numeric-field-input"
        />
        <mat-hint *ngIf="hint" class="fx-row justify-space-between w-100">
          <span>{{ hint }}</span>
        </mat-hint>
        <mat-spinner *ngIf="control.status === 'PENDING'" [diameter]="16" matSuffix></mat-spinner>
        <div matSuffix class="color-grey-900 fx-row align-center">
          <span>{{ suffix }}</span>
          <div *ngIf="isCalculated && calculationErrorMsg" class="error-msg-icon">
            <mat-icon
              aria-hidden="false"
              aria-label="errorIcon"
              class="ml-1 color-warning"
              svgIcon="warning"
              (mouseenter)="openErrorDetailsmenu()"
            ></mat-icon>
          </div>
        </div>
        <mat-hint class="mat-error" *ngFor="let errorKey of parentControl?.errors ?? null | errorKeys">{{
          errorMessages[errorKey]
        }}</mat-hint>
        <mat-error *ngFor="let errorKey of control.errors | errorKeys">{{ errorMessages[errorKey] }}</mat-error>
        <mat-error *ngIf="control.errors?.min && !messages.min"
          >{{ "Value must not be below {count}" | translate : { count: control.errors?.min.min } }}
        </mat-error>
        <mat-error *ngIf="control.errors?.max && !messages.max">
          {{ "Value must not be above {count}" | translate : { count: control.errors?.max.max } }}
        </mat-error>
        <mat-error *ngIf="control?.errors?.nonNumericError">
          {{ "Value must be a valid number" | translate }}
        </mat-error>
      </mat-form-field>
    </ng-container>
  </div>
</div>
