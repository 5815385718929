<header class="questionnaire-header">
  <div>
    <div class="color-grey-800">{{ "Questionnaire Template" | translate }}</div>
    <h3 class="mb-2">{{ templateName }}</h3>

    <mat-divider class="bkg-grey-600"></mat-divider>

    <div class="overlay" (click)="headerClick.emit()" data-testid="header-overlay"></div>
  </div>
</header>
