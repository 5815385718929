import { fireEvent } from '@testing-library/angular';
import userEvent from '@testing-library/user-event';

export const typeIntoAndBlur = (input: HTMLInputElement, text: string) => {
  userEvent.type(input, text);
  fireEvent.blur(input);
};

export const clearAndType = (input: HTMLInputElement, text: string) => {
  userEvent.clear(input);
  userEvent.type(input, text);
};
