import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { BaseTemplateReport, TemplateReportVersionPayload } from '../../../models';
import { TemplateReportsApiService } from '../../../services/api-services';
import { TemplateReportVersionValidators } from '../../validators';
import { UniquenessValidator } from '../../../validators';

import dayjs from 'dayjs';
import { map } from 'rxjs';

export class TemplateReportPanelSettingsForm extends UntypedFormGroup {
  public readonly end = this.get('end') as UntypedFormControl;
  public readonly name = this.get('name') as UntypedFormControl;
  public readonly start = this.get('start') as UntypedFormControl;
  public readonly version_number = this.get('version_number') as UntypedFormControl;

  constructor(
    templateReportsApiService: TemplateReportsApiService,
    templateReportId: string,
    templateReport: BaseTemplateReport,
    previousTemplateReport?: BaseTemplateReport,
    subsequentTemplateReport?: BaseTemplateReport,
    private readonly fb: UntypedFormBuilder = new UntypedFormBuilder()
  ) {
    const name = templateReport.name ?? templateReport.version;
    const uniquenessCheck = (value: string) =>
      templateReportsApiService
        .checkTemplateReportVersionName(templateReportId, value)
        .pipe(map((res) => res.data.available));

    super(
      {
        name: fb.control(
          name,
          [Validators.required, Validators.maxLength(200)],
          UniquenessValidator.validate(uniquenessCheck, name ? [name] : [])
        ),
        end: fb.control(templateReport.end ? dayjs(templateReport.end).format('YYYY-MM-DD') : null),
        start: fb.control(
          templateReport.start ? dayjs(templateReport.start).format('YYYY-MM-DD') : null,
          Validators.required
        ),
        version_number: fb.control(templateReport.version_number ?? 1),
      },
      {
        validators: TemplateReportVersionValidators.validateDates(
          previousTemplateReport?.end,
          previousTemplateReport?.start,
          subsequentTemplateReport?.start
        ),
      }
    );
  }

  toModel(): TemplateReportVersionPayload {
    return {
      end: this.end.value ? (this.end.value as string) : null,
      start: this.start.value as string,
      version: this.name.value as string,
    };
  }
}
