import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse, SamlProvider, Token } from '../../../models/entities';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';
import { BaseService } from '../base/base.service';
import { ApplicationApiDefinition } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class SamlService {
  _providers?: SamlProvider[];
  apiName: keyof ApplicationApiDefinition = 'auth';
  resource: string;
  servicePath: string;
  apiDomain: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apiService: ApiService,
    private auth: AuthService,
    private baseService: BaseService
  ) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.apiDomain = apiService.getBaseUrl();
    this.resource = this.apiService.apiConfig.apis.auth.resources.saml;
  }

  listProviders(): Observable<SamlProvider[]> {
    if (this._providers) {
      return of(this._providers);
    } else {
      return this.apiService.get(`${this.servicePath}${this.resource}/providers`).pipe(
        map((result) => {
          this._providers = result.data;
          return this._providers || [];
        })
      );
    }
  }

  login(session_id: string): Observable<boolean> {
    return this.apiService.get(`${this.servicePath}${this.resource}/session/${session_id}`).pipe(
      map((result: ApiResponse<Token>) => {
        if (result.data.hydra_redirect_uri != null) {
          window.location.replace(result.data.hydra_redirect_uri);
          return false;
        }
        return this.auth.saveUser(result.data);
      })
    );
  }
}
