import { BenchmarkColumn, Org } from '../models';

export class BenchmarkColumnBuilder {
  private readonly _benchmarkColumn: BenchmarkColumn;

  constructor() {
    this._benchmarkColumn = {
      benchmark_interval_id: 'biId',
      fiscal_year: 2022,
      position: 1,
      org: undefined,
      created: new Date(),
      updated: new Date(),
    };
  }

  position(position: number): BenchmarkColumnBuilder {
    this._benchmarkColumn.position = position;
    return this;
  }

  org(org: Org): BenchmarkColumnBuilder {
    this._benchmarkColumn.org = org;
    return this;
  }

  build(): BenchmarkColumn {
    return this._benchmarkColumn;
  }
}
