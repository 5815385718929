import { EncryptUtils } from '@novisto/common';
import every from 'lodash/every';
import includes from 'lodash/includes';
import keyBy from 'lodash/keyBy';

import {
  ConditionalTrigger,
  EmbedderValue,
  EmbedderValueField,
  EmbedderValueId,
  IContextSettings,
  Indicator,
  Value,
  ValueDefinitionType,
  ValueGroup,
} from '../models';

export class EmbedderUtils {
  private static readonly SUPPORTED_TYPES = [
    ValueDefinitionType.boolean,
    ValueDefinitionType.calculated,
    ValueDefinitionType.choice,
    ValueDefinitionType.date,
    ValueDefinitionType.decimal,
    ValueDefinitionType.file,
    ValueDefinitionType.file_v2,
    ValueDefinitionType.integer,
    ValueDefinitionType.text,
  ];

  public static encodeId(id: EmbedderValueId) {
    return EncryptUtils.encoded(id);
  }

  public static formatId(
    settings: IContextSettings,
    embedderValue: EmbedderValue,
    field: string = EmbedderValueField.value
  ): string {
    const id: EmbedderValueId = {
      embedderValue,
      field,
      fiscalYear: settings.fiscalYear.id,
      metricId: embedderValue.metricId,
      sourceId: settings.source.id,
      tableId: embedderValue.table?.[0].table_id,
      valueDefinitionId: embedderValue.value?.value_definition_id,
    };
    return this.encodeId(id);
  }

  public static formatEmbedderValues(indicator: Indicator): EmbedderValue[] {
    let table: ValueGroup[] = [];
    const metricId = String(indicator.metric_id);
    const values: EmbedderValue[] = [];
    const vgs = indicator.value_group_sets?.[0];
    const allValues = vgs?.value_groups?.flatMap((vg) => vg.values).filter((v): v is Value => Boolean(v)) || [];

    vgs?.value_groups?.forEach((vg) => {
      if (this.checkTriggers(allValues, vg.conditional_triggers)) {
        if (vg.table_id) {
          table.push(vg);
        } else {
          const group = vg.repeatable ? vg.values?.filter((v) => this.isSupportedValue(allValues, v)) : undefined;
          table = this.appendTable(values, table, metricId);
          vg.values?.forEach((v) => {
            if (this.isSupportedValue(allValues, v)) {
              values.push({ id: this.formatEmbedderValueId(vg, v), group, metricId, value: v });
            }
          });
        }
      }
    });
    table = this.appendTable(values, table, metricId);

    return values;
  }

  public static fetchId(encodedId: string): EmbedderValueId {
    return EncryptUtils.decoded(encodedId);
  }

  public static getEmbedderValueByIndicator(indicators: Indicator[]): Record<string, Record<string, EmbedderValue>> {
    return indicators.reduce((acc: Record<string, Record<string, EmbedderValue>>, indicator) => {
      acc[String(indicator.metric_id)] = keyBy(this.formatEmbedderValues(indicator), 'id');
      return acc;
    }, {});
  }

  private static appendTable(values: EmbedderValue[], table: ValueGroup[], metricId: string): ValueGroup[] {
    if (table.length) {
      let tableTotals: ValueGroup | undefined;

      if (table[table.length - 1].values?.every((v) => v.type === ValueDefinitionType.calculated)) {
        tableTotals = table.pop();
      }

      values.push({ id: this.formatEmbedderValueId(table[0]), metricId, table, tableTotals });
    }
    return [];
  }

  private static checkTriggers(values: Value[], triggers?: ConditionalTrigger[]): boolean {
    if (!triggers?.length) {
      return true;
    }

    return triggers.some((t) =>
      this.isTriggerTriggered(
        t,
        values.find((v) => v.value_definition_id === t.source_value_definition_id)
      )
    );
  }

  private static formatEmbedderValueId(vg: ValueGroup, v?: Value): string {
    return vg.table_id || `${vg.value_definition_group_id}-${vg.subposition}-${v?.value_definition_id}`;
  }

  private static isSupportedValue(allValues: Value[], value: Value): boolean {
    const triggered = this.checkTriggers(allValues, value.conditional_triggers);
    return Boolean(this.SUPPORTED_TYPES.includes(value.type) && triggered);
  }

  private static isTriggerTriggered(trigger: ConditionalTrigger, value?: Value): boolean {
    if (value?.value) {
      const sourceValueValue = value.type === ValueDefinitionType.boolean ? value.value.value : value.value.values;

      return Array.isArray(sourceValueValue)
        ? every(trigger.values, (value) => sourceValueValue.includes(value))
        : includes(trigger.values, sourceValueValue);
    }

    return false;
  }
}
