import { Pipe, PipeTransform } from '@angular/core';
import { BaseValue, LabelTypeDetails, Value, ValueDefinitionType, ValueGroup } from '../models';

interface MetricTableCellValueDisplay {
  contextColumns: string[];
  inputColumns?: BaseValue[];
}

@Pipe({
  name: 'cellValueDisplay',
})
export class MetricTableCellValueDisplayPipe implements PipeTransform {
  transform(valueGroup: ValueGroup): MetricTableCellValueDisplay {
    const contextColumns = valueGroup.values
      ?.filter((val) => val.type === ValueDefinitionType.label)
      .map((val: Value<LabelTypeDetails>) => `${val.label}: ${val.type_details.value}`);
    const inputColumns = valueGroup.values?.filter(
      (val) => val.type === ValueDefinitionType.integer || val.type === ValueDefinitionType.decimal
    );
    return {
      inputColumns,
      contextColumns: contextColumns ?? [],
    };
  }
}
