import { Benchmark, BenchmarkCell, BenchmarkColumn, BenchmarkMetricCategory, MetricWithPosition } from '../models';
import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';
import { EntityBuilder } from './entity-builder';

export class BenchmarkBuilder extends EntityBuilder<Benchmark> {
  constructor() {
    const metricCategory = faker.helpers.arrayElement([
      BenchmarkMetricCategory.REFERENCE,
      BenchmarkMetricCategory.THIRD_PARTY,
    ]);
    const reportId = metricCategory === BenchmarkMetricCategory.THIRD_PARTY ? uuidv4() : undefined;
    super({
      id: uuidv4(),
      name: faker.company.bsNoun(),
      metric_category: metricCategory,
      report_id: reportId,
      active: true,
      cells: [],
      columns: [],
      metrics: [],
    });
  }

  cells(cells: BenchmarkCell[]): BenchmarkBuilder {
    this._instance.cells = cells;
    return this;
  }

  columns(columns: BenchmarkColumn[]): BenchmarkBuilder {
    this._instance.columns = columns;
    return this;
  }

  metrics(metrics: MetricWithPosition[]): BenchmarkBuilder {
    this._instance.metrics = metrics;
    return this;
  }

  metricCategory(metricCategory: BenchmarkMetricCategory): BenchmarkBuilder {
    this._instance.metric_category = metricCategory;
    return this;
  }
}
