import { ApiResponse, ApiResponseMeta } from '../models';

export class ApiResponseBuilder<T = unknown> {
  private readonly _apiResponse: ApiResponse<T>;

  constructor() {
    this._apiResponse = {
      data: {} as T,
      errors: [],
      meta: {},
    };
  }

  data(data: T): ApiResponseBuilder<T> {
    this._apiResponse.data = data;
    return this;
  }

  errors(errors: any[]): ApiResponseBuilder<T> {
    this._apiResponse.errors = errors;
    return this;
  }

  meta(meta: ApiResponseMeta): ApiResponseBuilder<T> {
    this._apiResponse.meta = meta;
    return this;
  }

  build(): ApiResponse<T> {
    return this._apiResponse;
  }
}
