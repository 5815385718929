import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActionItem } from '../../models';

@Component({
  selector: 'lib-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  @Input() items: ActionItem[] = [];
  @Input() preserveQueryParams: boolean = true;
  @Output() change = new EventEmitter<ActionItem>();

  onChange(item: ActionItem): void {
    this.change.emit(item);
  }
}
