import { OauthToken } from './tokens';

export interface PublicUser {
  aud: string[];
  auth_time: number;
  client_code: string;
  email: string;
  iat: number;
  iss: string;
  rat: number;
  sub: string;
  user_email: string;
  user_id: string;
  user_name: string;
}

export interface OauthUser extends OauthToken, PublicUser {
  exp: number;
  roles: string[];
}
