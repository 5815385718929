<ng-container *ngIf="valueGroupSetForm">
  <div class="fx-row align-center pb-4" *ngIf="disabled && disabledReason">
    <mat-icon svgIcon="info" class="tip-icon color-grey-800 mr-2"></mat-icon>
    <div class="text mat-body color-grey-900">
      {{ disabledReason }}
    </div>
  </div>

  <form [formGroup]="valueGroupSetForm" class="metric-editor-form">
    <ng-container *ngFor="let formGroup of valueGroupSetForm.allDisplayedFormGroups(); trackBy: formGroupTrackBy">
      <lib-metric-editor-group-handler
        *ngIf="formGroup | isValueGroupFormGroup"
        data-testid="group-handler"
        [class]="'metric-editor-form-group form-group-' + $any(formGroup).valueGroupRef.indent"
        [valueGroupFormGroup]="$any(formGroup)"
        [documentContext]="documentContext"
        [repeatableGroupCount]="formGroup | repeatableGroupsCount : valueGroupSetForm.groupFormGroups()"
        [indicatorId]="valueGroupSet.indicator_id"
        [vgsetId]="valueGroupSet.id"
        [displayFieldActions]="displayFieldActions"
        [collaboratorResponsibility]="collaboratorResponsibility"
        [valueGroupSetStatus]="valueGroupSet.status"
        [dataRequestSourceStatus]="dataRequestSourceStatus"
        (update)="onUpdateValueGroup($event)"
        (resetValue)="onResetValue($event)"
        (addGroup)="handleAddValueGroup($event)"
        (moveGroup)="handleMoveValueGroup($event)"
        (deleteGroup)="handleDeleteValueGroup($event)"
        (metricLinkEdit)="metricLinkEdit.emit($event)"
      ></lib-metric-editor-group-handler>
      <lib-metric-editor-table-handler
        *ngIf="formGroup | isTableFormGroup"
        data-testid="table-handler"
        [tableFormGroup]="$any(formGroup)"
        [isConsolidatedBU]="valueGroupSet.consolidated"
        [displayFieldActions]="displayFieldActions"
        [userResponsibility]="collaboratorResponsibility"
        [valueGroupSetStatus]="valueGroupSet.status"
        [dataRequestSourceStatus]="dataRequestSourceStatus"
        [documentContext]="documentContext"
        [indicatorId]="valueGroupSet.indicator_id"
        [vgsetId]="valueGroupSet.id"
        (update)="onUpdateValueGroup($event)"
        (resetValue)="onResetValue($event)"
      >
      </lib-metric-editor-table-handler>
    </ng-container>
  </form>
</ng-container>
