import { Component, Input } from '@angular/core';

import { TranslateService } from '../../../../../services/common';

import { Required } from '../../../../../decorators';
import { ActionItem, ChoiceFieldWidgetType, ChoiceTypeDetails, ValueDefinition } from '../../../../../models';
import { DynamicForm } from '../../../../../classes';
import { MetricStructureFieldFormModel } from '../forms/metric-structure-field-properties-form-configs';
import { MetricStructureFieldPropertiesFormService } from '../forms/metric-structure-field-properties-form.service';
import { Observable } from 'rxjs';
import { MetricStructureStateService } from '../../../../services/metric-structure-state.service';

@Component({
  selector: 'lib-metric-structure-choice-field-properties',
  templateUrl: './metric-structure-choice-field-properties.component.html',
})
export class MetricStructureChoiceFieldPropertiesComponent {
  @Input() @Required() valueDefinition!: ValueDefinition<ChoiceTypeDetails>;

  dynamicFieldForm$: Observable<DynamicForm<MetricStructureFieldFormModel> | undefined> =
    this.metricStructureFieldPropertiesFormService.dynamicFieldForm$;

  canCreateCustomChoices$: Observable<boolean> = this.metricStructureStateService.canCreateCustomChoices$;

  readonly widgetTypeTitle = {
    [ChoiceFieldWidgetType.single_select]: this.translateService.instant('Single-select (dropdown)'),
    [ChoiceFieldWidgetType.checkboxes]: this.translateService.instant('Checkboxes'),
    [ChoiceFieldWidgetType.radio_buttons]: this.translateService.instant('Radio Buttons'),
    [ChoiceFieldWidgetType.multi_chip]: this.translateService.instant('Multi-select (dropdown)'),
  };

  readonly widgetTypes: ActionItem[] = Object.values(ChoiceFieldWidgetType).map((type) => ({
    id: type,
    title: this.widgetTypeTitle[type],
  }));

  constructor(
    private translateService: TranslateService,
    private metricStructureFieldPropertiesFormService: MetricStructureFieldPropertiesFormService,
    private metricStructureStateService: MetricStructureStateService
  ) {}

  public updateCustomChoiceAnswers(customChoiceAnswers: ActionItem[]): void {
    this.metricStructureFieldPropertiesFormService.customChoiceAnswers = customChoiceAnswers;
  }

  public toggleDisplay(displayExplanation: boolean): void {
    this.metricStructureFieldPropertiesFormService.toggleMultipleLine();
    this.metricStructureFieldPropertiesFormService.toggleExplanationLabelValidator(displayExplanation);
  }

  public widgetTypeChanged(): void {
    this.metricStructureFieldPropertiesFormService.toggleAllowAddOption();
    this.metricStructureFieldPropertiesFormService.toggleMultipleLine();
  }
}
