<div
  *ngIf="control"
  [class]="'formfield ' + size"
  [ngClass]="{ required: required, disabled: control.disabled || readonly }"
  data-testid="date-field"
>
  <lib-form-field-label [label]="label" [control]="control" [for]="_inputId"></lib-form-field-label>
  <mat-form-field class="w-100" floatLabel="never">
    <input
      matInput
      [formControl]="tempControl"
      [matDatepicker]="picker"
      class="color-grey-900"
      [placeholder]="placeholder"
      [id]="_inputId"
      name="date-field"
      #focusElement
      libFocusState
      [focusStateControl]="control"
      [readonly]="readonly"
      [min]="minDate"
      [max]="maxDate"
      data-testid="date-field-input"
    />
    <mat-datepicker-toggle class="datepicker-toggle-padding" matSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon svgIcon="calendar" class="datepicker-icon"></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker-toggle *ngIf="tempControl.value" matSuffix (click)="clearSelectedDate()">
      <mat-icon
        matDatepickerToggleIcon
        class="datepicker-icon"
        svgIcon="clear"
        data-testid="clearSelectedDate"
      ></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-error *ngFor="let error of tempControl.errors | errorKeys"> {{ errorMessages[error] }}</mat-error>
  </mat-form-field>
</div>
