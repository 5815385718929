<div
  #group
  cdkDropList
  [cdkDropListData]="valueDefinitionGroup.value_definitions"
  (cdkDropListDropped)="drop($event)"
  [attr.data-testid]="valueDefinitionGroup.id"
  *ngIf="!(valueDefinitionGroup | isEntityFromCoreRefV2Metric : metric); else coreVDG"
>
  <lib-metric-structure-group-form-details
    [valueDefinitionGroup]="valueDefinitionGroup"
    [index]="index"
    [canMoveDown]="canMoveDown"
    [canMoveUp]="canMoveUp"
    [overlayDisabled]="overlayDisabled"
    [metric]="metric"
    [selectedItemId]="selectedItemId"
    [isCoreValueDefinitonGroupOfReferenceV2Metric]="valueDefinitionGroup | isEntityFromCoreRefV2Metric : metric"
  ></lib-metric-structure-group-form-details>
</div>
<ng-template #coreVDG>
  <div [attr.data-testid]="'core-vdg-' + valueDefinitionGroup.id">
    <lib-metric-structure-group-form-details
      [valueDefinitionGroup]="valueDefinitionGroup"
      [index]="index"
      [canMoveDown]="canMoveDown"
      [canMoveUp]="canMoveUp"
      [overlayDisabled]="overlayDisabled"
      [metric]="metric"
      [selectedItemId]="selectedItemId"
      [isCoreValueDefinitonGroupOfReferenceV2Metric]="valueDefinitionGroup | isEntityFromCoreRefV2Metric : metric"
    ></lib-metric-structure-group-form-details>
  </div>
</ng-template>
