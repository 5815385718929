import { Pipe, PipeTransform } from '@angular/core';

import get from 'lodash/get';

@Pipe({ name: 'get' })
export class GetPipe implements PipeTransform {
  transform(value: Record<string, unknown> | ArrayLike<unknown> | unknown, key: number | string | symbol): unknown {
    return get(value, key);
  }
}
