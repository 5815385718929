import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { ComponentsModule } from '../components';
import { DataTableModule } from '../data-table';
import { DirectivesModule } from '../directives';
import { MaterialModule } from '../material/material.module';
import { MetricSearchComponent } from './metric-search.component';
import { MetricSearchValueDefinitionsComponent } from './metric-search-value-definitions/metric-search-value-definitions.component';
import { PipesModule } from '../pipes';
import { SearchModule } from '../search';

@NgModule({
  declarations: [MetricSearchComponent, MetricSearchValueDefinitionsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ComponentsModule,
    PipesModule,
    DirectivesModule,
    TranslateModule,
    SearchModule,
    DataTableModule,
  ],
  exports: [MetricSearchComponent],
})
export class MetricSearchModule {}
