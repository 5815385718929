import { AuthService } from '../auth/auth.service';
import { IlogClass } from '../../../models';
import * as Sentry from '@sentry/angular-ivy';
import { CaptureContext } from '@sentry/types';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export abstract class LogToSentryService implements IlogClass {
  constructor(protected readonly authService: AuthService) {}

  logMessage(
    log: string,
    context: CaptureContext = { level: 'debug', user: { id: this.authService.user?.user_id ?? 'undefined' } }
  ) {
    Sentry.captureMessage(log, context);
  }

  logError(
    error: Error,
    context: CaptureContext = { level: 'error', user: { id: this.authService.user?.user_id ?? 'undefined' } }
  ) {
    Sentry.captureException(`${error.name}: ${error.message}`, { extra: { error }, ...context });
  }
}
