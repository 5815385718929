<lib-numeric-input
  [label]="label"
  [control]="valueFormControl"
  [hint]="hint"
  [suffix]="unit"
  [size]="size"
  [readonly]="valueFormControl?.disabled || isCalculated"
  [withDecimals]="isDecimal"
  [maxDecimals]="maxDecimals"
  [isCalculated]="isCalculated"
  [metricTableDefinition]="metricTableDefinition"
  [calculationErrorMsg]="calculationErrorMsg"
  (libBeforeUnload)="updateValue()"
></lib-numeric-input>
