import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Metric, MoveDirection, Permission } from '../../../models';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { TemplateReportsApiService } from '../../../services/api-services';
import { TemplateReportStructureStateService } from '../../services/template-report-structure-state.service';

@Component({
  selector: 'lib-template-report-structure-section-metric',
  templateUrl: './template-report-structure-section-metric.component.html',
  styleUrls: [
    '../template-report-structure-sections.component.scss',
    './template-report-structure-section-metric.component.scss',
  ],
})
export class TemplateReportStructureSectionMetricComponent {
  @Input() managePermissions: Permission[] = [];
  @Input() metric?: Metric;
  @Input() position: number = 1;
  @Input() isFirst: boolean = false;
  @Input() isLast: boolean = false;
  @Input() templateReportId?: string;
  @Input() templateReportSectionId?: string;
  @Input() templateReportVersionId?: string | null;

  @Output() editMetric: EventEmitter<void> = new EventEmitter<void>();
  @Output() previewMetric: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('metricTrigger') metricMenu?: MatMenuTrigger;

  public highlightMetric: boolean = false;
  public menuPosition = { x: '0px', y: '0px' };

  constructor(
    private readonly templateReportsApiService: TemplateReportsApiService,
    private readonly templateReportStructureStateService: TemplateReportStructureStateService
  ) {}

  public openMetricContextMenu(event: MouseEvent): void {
    this.highlightMetric = true;
    event.preventDefault();
    event.stopPropagation();
    this.menuPosition.x = `${event.clientX}px`;
    this.menuPosition.y = `${event.clientY}px`;
    if (this.metricMenu) {
      this.metricMenu.menuData = { metric: this.metric };
      this.metricMenu.menu?.focusFirstItem('mouse');
      this.metricMenu.openMenu();
    }
  }

  public moveMetricUp() {
    this.moveSection(MoveDirection.up);
  }

  public moveMetricDown() {
    this.moveSection(MoveDirection.down);
  }

  public deleteMetric() {
    if (!this.metric) {
      return;
    }

    this.templateReportsApiService
      .removeMetricFromTemplateReportSection(
        this.templateReportId as string,
        this.templateReportSectionId as string,
        this.metric.id,
        this.templateReportVersionId ?? undefined
      )
      .subscribe({
        next: () => {
          this.templateReportStructureStateService.loadTemplateReportSections();
        },
      });
  }

  private moveSection(direction: MoveDirection): void {
    if (this.templateReportId && this.templateReportVersionId && this.templateReportSectionId && this.metric) {
      this.templateReportsApiService
        .moveTemplateReportSectionMetric(
          this.templateReportId,
          this.templateReportVersionId,
          this.templateReportSectionId,
          this.metric.id,
          this.position + (direction === MoveDirection.up ? -1 : 1)
        )
        .subscribe({
          next: (res) => {
            this.templateReportStructureStateService.updateSectionMetrics(
              res.data,
              String(this.templateReportSectionId)
            );
          },
        });
    }
  }
}
