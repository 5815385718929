import { IndicatorType, FieldAuditEventsRequest } from '../models';

export class FieldAuditEventRequestBuilder {
  private readonly _fieldAuditEventRequest: FieldAuditEventsRequest;

  constructor() {
    this._fieldAuditEventRequest = {
      indicator_type: IndicatorType.collect,
      value_id: 'vId',
      value_group_id: 'vgId',
      business_unit_id: 'buId',
      frequency_code: '2022',
    };
  }

  build(): FieldAuditEventsRequest {
    return this._fieldAuditEventRequest;
  }
}
