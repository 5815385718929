import { DataRequestSourceStatus, DataRequestSourceSummary, DataRequestUser } from '../models';
import { DataRequestIndicatorLinkBuilder } from './data-request-indicator-link-builder';
import { DataRequestUserBuilder } from './data-request-user-builder';

export class DataRequestSourceSummaryBuilder {
  private readonly _dataRequestSourceSummary: DataRequestSourceSummary;

  constructor() {
    this._dataRequestSourceSummary = {
      active: true,
      created: new Date(),
      created_by: 'Bob',
      data_request_users: [new DataRequestUserBuilder().build()],
      id: '123',
      indicator_links: [new DataRequestIndicatorLinkBuilder().build()],
      name: '',
      status: DataRequestSourceStatus.STARTED,
      updated: new Date(),
      updated_by: 'Bob',
    };
  }

  id(id: string): DataRequestSourceSummaryBuilder {
    this._dataRequestSourceSummary.id = id;
    return this;
  }

  data_request_users(dataRequestUsers: DataRequestUser[]): DataRequestSourceSummaryBuilder {
    this._dataRequestSourceSummary.data_request_users = dataRequestUsers;
    return this;
  }

  status(status: DataRequestSourceStatus): DataRequestSourceSummaryBuilder {
    this._dataRequestSourceSummary.status = status;
    return this;
  }

  name(name: string): DataRequestSourceSummaryBuilder {
    this._dataRequestSourceSummary.name = name;
    return this;
  }

  active(active: boolean): DataRequestSourceSummaryBuilder {
    this._dataRequestSourceSummary.active = active;
    return this;
  }

  build(): DataRequestSourceSummary {
    return this._dataRequestSourceSummary;
  }
}
