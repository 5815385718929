import { DataRequestNote } from '../models';

export class DataRequestNoteBuilder {
  private readonly _dataRequestNote: DataRequestNote;

  constructor() {
    this._dataRequestNote = {
      created: new Date(),
      created_by: '',
      note_author: '',
      id: 'id',
      title: 'Note title',
      note: 'A note',
      data_request_id: 'dataRequestId',
      children: [],
    };
  }

  id(id: string): DataRequestNoteBuilder {
    this._dataRequestNote.id = id;
    return this;
  }

  title(title: string): DataRequestNoteBuilder {
    this._dataRequestNote.title = title;
    return this;
  }

  note(note: string): DataRequestNoteBuilder {
    this._dataRequestNote.note = note;
    return this;
  }

  data_request_id(dataRequestId: string): DataRequestNoteBuilder {
    this._dataRequestNote.data_request_id = dataRequestId;
    return this;
  }

  createdBy(createdById: string): DataRequestNoteBuilder {
    this._dataRequestNote.created_by = createdById;
    return this;
  }

  children(children: DataRequestNote[]): DataRequestNoteBuilder {
    this._dataRequestNote.children = children;
    return this;
  }

  noteAuthor(noteAuthor: string): DataRequestNoteBuilder {
    this._dataRequestNote.note_author = noteAuthor;
    return this;
  }

  build(): DataRequestNote {
    return this._dataRequestNote;
  }
}
