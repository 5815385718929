import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

import { Observable, of } from 'rxjs';

import { ConfirmationDialogComponent, DialogsService } from '../../../../dialogs';
import {
  ConsolidationRules,
  ConsolidationTriggers,
  DialogResult,
  MetricTableCalculationDefinition,
  MetricTableColumnDefinition,
  MetricTableDefinition,
  Status,
  ValueDefinitionType,
  Metric,
  MetricCategory,
  DeactivateEntityTypes,
  MetricTableColumn,
} from '../../../../models';
import { ActivateEntityService } from '../../../services/activate-entity/activate-entity.service';
import { ClientConfigService } from '../../../../services/client';
import { DeactivateEntityService } from '../../../services/deactivate-entity/deactivate-entity.service';
import { MetricStructureFieldPropertiesFormService } from '../metric-structure-field-properties/forms/metric-structure-field-properties-form.service';
import { MetricStructureStateService } from '../../../services/metric-structure-state.service';
import { MetricTableForm } from './metric-table-form';
import { MetricTableGroup } from '../../../models';
import { Required } from '../../../../decorators';
import {
  TableContextColumnDialogComponent,
  TableContextColumnDialogComponentConfig,
} from './table-context-column-dialog/table-context-column-dialog.component';
import {
  TableInputColumnDialogComponent,
  TableInputColumnDialogConfig,
} from './table-input-column-dialog/table-input-column-dialog.component';
import { TranslateService } from '../../../../services/common';

@Component({
  selector: 'lib-metric-structure-table-properties',
  templateUrl: './metric-structure-table-properties.component.html',
  styleUrls: ['./metric-structure-table-properties.component.scss'],
  providers: [MetricStructureFieldPropertiesFormService],
})
export class MetricStructureTablePropertiesComponent implements OnChanges {
  @Input() @Required() metricTableGroup!: MetricTableGroup;
  @Input() @Required() metric!: Metric;

  @Input() formDisabled: boolean = false;
  @Input() maxRowCount: number = 200;

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(MatMenuTrigger) contextMenu?: MatMenuTrigger;

  tableForm?: MetricTableForm;
  metricTableDefinition?: MetricTableDefinition;
  metricTableContextColumns: MetricTableColumn[] = [];
  metricTableInputColumns: MetricTableColumn[] = [];
  metricTableCalculations: MetricTableCalculationDefinition[] = [];

  private get metricTableColumns(): MetricTableColumn[] {
    return this.metricTableInputColumns.concat(this.metricTableContextColumns);
  }

  updating$: Observable<boolean> = of(false);

  readonly eMetricCategory: typeof MetricCategory = MetricCategory;
  readonly eValueDefinitionType: typeof ValueDefinitionType = ValueDefinitionType;

  contextMenuPosition = { x: '0px', y: '0px' };
  constructor(
    private dialogsService: DialogsService,
    private metricStructureService: MetricStructureStateService,
    private translateService: TranslateService,
    private clientConfigService: ClientConfigService,
    private deactivateEntityService: DeactivateEntityService,
    private activateEntityService: ActivateEntityService
  ) {
    this.updating$ = this.metricStructureService.isMetricUpdating$;
  }

  ngOnChanges(): void {
    this.tableForm = new MetricTableForm(this.metricTableGroup);

    if (this.clientConfigService.areAnyFeatureFlagsEnabled(['metric_structure_deactivation_enabled'])) {
      if (this.metricTableGroup.active) {
        this.tableForm.enable();
      } else {
        this.tableForm.disable();
      }
    }
    this._fetchMetricTableDefinition();
  }

  public onContextMenu(
    event: MouseEvent,
    tableColumnDefinition: MetricTableColumn,
    first?: boolean,
    last?: boolean
  ): void {
    event.preventDefault();
    event.stopPropagation();
    this.contextMenuPosition.x = `${event.clientX}px`;
    this.contextMenuPosition.y = `${event.clientY}px`;
    if (this.contextMenu) {
      this.contextMenu.menuData = { item: tableColumnDefinition, first, last };
      this.contextMenu.menu?.focusFirstItem('mouse');
      this.contextMenu.openMenu();
    }
  }

  public handleContextColumn(column?: MetricTableColumn): void {
    if (!this.metricTableDefinition) {
      return;
    }

    this.dialogsService.open<TableContextColumnDialogComponent, TableContextColumnDialogComponentConfig>(
      TableContextColumnDialogComponent,
      {
        data: {
          column,
          columns: this.metricTableColumns.filter((col) => col.id !== column?.id),
          maxRowCount: this.maxRowCount,
          metricStructureService: this.metricStructureService,
          metricTableDefinition: this.metricTableDefinition,
        },
      }
    );
  }

  public handleInputColumn(column?: MetricTableColumn): void {
    if (!this.metricTableDefinition) {
      return;
    }

    this.dialogsService.open<TableInputColumnDialogComponent, TableInputColumnDialogConfig>(
      TableInputColumnDialogComponent,
      {
        data: {
          column,
          columns: this.metricTableColumns.filter((col) => col.id !== column?.id),
          metricCategory: this.metric.category,
          metricStructureService: this.metricStructureService,
          metricTableDefinition: this.metricTableDefinition,
        },
      }
    );
  }

  public editTableColumn(column: MetricTableColumn): void {
    if (this.formDisabled || this.metricTableGroup.active === false) {
      return;
    }

    if (column.type === ValueDefinitionType.label) {
      this.handleContextColumn(column);
    } else {
      this.handleInputColumn(column);
    }
  }

  public deleteTableColumn(column: MetricTableColumn): void {
    if (this.metricTableDefinition) {
      this.dialogsService
        .open(ConfirmationDialogComponent, {
          data: {
            title: this.translateService.instant('Delete Column'),
            warningMsg: this.translateService.instant('Are you sure you wish to delete this Column?'),
          },
        })
        .afterClosed()
        .subscribe((result?: DialogResult) => {
          if (this.metricTableDefinition && column.id && result?.status === Status.CONFIRMED) {
            this.metricStructureService.deleteMetricTableColumnDefinition(this.metricTableDefinition, column.id);
          }
        });
    }
  }

  public duplicateTableColumn(column: MetricTableColumnDefinition): void {
    const clonedColumn = {
      ...column,
      id: undefined,
      label: '',
      context_options: column.context_options.map((option) => ({ ...option, id: '' })),
    };

    if (column.type === ValueDefinitionType.label) {
      this.handleContextColumn(clonedColumn);
    } else {
      this.handleInputColumn(clonedColumn);
    }
  }

  public moveUpMetricTableColumn(column: MetricTableColumnDefinition) {
    if (!this.metricTableDefinition) {
      return;
    }

    this.metricStructureService.moveMetricTableColumnDefinition(
      this.metricTableDefinition,
      column.id,
      column.position - 1
    );
  }

  public moveDownMetricTableColumn(column: MetricTableColumnDefinition) {
    if (!this.metricTableDefinition) {
      return;
    }

    this.metricStructureService.moveMetricTableColumnDefinition(
      this.metricTableDefinition,
      column.id,
      column.position + 1
    );
  }

  public saveTableForm(): void {
    if (this.tableForm?.valid && this.metricTableDefinition) {
      this.metricStructureService.updateMetricTable(this.metricTableDefinition, {
        title: this.tableForm.controls.tableTitleControl.value,
      });
    }
  }

  public deleteTable(event: MouseEvent): void {
    event.stopPropagation();
    this.metricStructureService.deleteMetricTable(this.metricTableGroup.metric_id, this.metricTableGroup.id);
  }

  public closeProperties(): void {
    this.closePanel.emit();
  }

  private setDefaultConsolidationValues(column: MetricTableColumnDefinition): {
    consolidationRule: ConsolidationRules;
    consolidationTrigger?: ConsolidationTriggers | null;
  } {
    const consolidationRule = column.consolidation_rule ?? ConsolidationRules.sum;
    const defaultTrigger =
      column.consolidation_rule === ConsolidationRules.sum || column.consolidation_rule === ConsolidationRules.average
        ? ConsolidationTriggers.update_when_one_value
        : undefined;
    const consolidationTrigger =
      column.consolidation_trigger === undefined ? defaultTrigger : column.consolidation_trigger;
    return { consolidationRule, consolidationTrigger };
  }

  private _fetchMetricTableDefinition(): void {
    this.metricStructureService
      .getMetricTableDefinition(this.metricTableGroup.metric_id, this.metricTableGroup.id)
      .subscribe((metricTableDefinition) => {
        this.metricTableDefinition = metricTableDefinition;
        this.metricTableContextColumns = this.metricTableDefinition.column_definitions.filter(
          (column) => column.type === ValueDefinitionType.label
        );

        this.metricTableInputColumns = this.metricTableDefinition.column_definitions
          .filter((column) => column.type !== ValueDefinitionType.label)
          .map((column) => {
            const consolidationConfig = this.setDefaultConsolidationValues(column);
            column.consolidation_rule = consolidationConfig.consolidationRule;
            column.consolidation_trigger = consolidationConfig.consolidationTrigger;
            return column;
          });
        this.metricTableCalculations = this.metricTableDefinition.calculation_definitions;
      });
  }

  public deactivateMetricTableGroup(): void {
    this.deactivateEntityService.deactivate(
      DeactivateEntityTypes.TABLE,
      this.metricTableGroup.metric_id,
      undefined,
      undefined,
      this.metricTableGroup
    );
  }

  public activateMetricTableGroup(): void {
    this.activateEntityService.activateMetricTable(this.metricTableGroup);
  }
}
