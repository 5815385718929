import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { ClientConfigService } from '../../services/client';
import { FeatureFlags } from '../../models';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard {
  constructor(private clientConfigService: ClientConfigService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const requiredFeatureFlags: (keyof FeatureFlags)[] = route.data['requiredFeatureFlags'];
    const featureFlagRedirect: string = (route.data['featureFlagRedirect'] as string) || '**';

    return this.clientConfigService.isClientReady$().pipe(
      first((isClientConfigReady: boolean) => isClientConfigReady),
      map(() =>
        this.clientConfigService.areAnyFeatureFlagsEnabled(requiredFeatureFlags)
          ? true
          : this.router.createUrlTree([featureFlagRedirect])
      )
    );
  }
}
