<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <header>
      <a mat-menu-item disabled>{{ optionProps?.title }}</a>
    </header>

    <ng-container *ngIf="optionProps && optionProps.defaultValue">
      <hr />
      <a mat-menu-item (click)="optionSelected.emit(optionProps.defaultValue)">
        {{ defaultValueTitle }}
      </a>
    </ng-container>

    <hr />

    <a
      mat-menu-item
      *ngFor="let option of optionProps?.options"
      (click)="optionSelected.emit(option)"
      [ngClass]="{
        active: optionProps?.activeOption?.id === option.id
      }"
    >
      {{ option.title }}
    </a>
  </ng-template>
</mat-menu>

<div
  class="btn btn-filter filter-container"
  [matMenuTriggerFor]="menu"
  [ngClass]="[buttonProps?.disabled ? 'btn-disabled disabled' : '', buttonProps?.active ? 'active' : '']"
  data-testid="filter-button-container"
>
  <span *ngIf="buttonProps && buttonProps.icon">
    <mat-icon class="icon-sm mr-1" [svgIcon]="buttonProps.icon"></mat-icon>
  </span>
  <div class="mat-body-2">{{ "Filtered by:" | translate }} {{ buttonTitle }}</div>
</div>
