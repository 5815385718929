export enum ConsolidationRules {
  sum = 'sum',
  average = 'average',
  manual = 'manual',
}

export const DefaultConsolidationRule: ConsolidationRules = ConsolidationRules.manual;

export enum ConsolidationTriggers {
  update_when_one_value = 'update_when_one_value',
  update_when_all_values = 'update_when_all_values',
}

export const ConsolidationRuleOptions = [
  { id: ConsolidationRules.sum, title: 'Sum (default)' },
  { id: ConsolidationRules.average, title: 'Average' },
  { id: ConsolidationRules.manual, title: 'No calculation - allow manual entry' },
];
