import { Component, DoCheck, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MetricEditorBooleanService } from '../../services/metric-editor-boolean/metric-editor-boolean.service';
import { BooleanTypeDetails, ValueDefinitionSize } from '../../../models';
import { ValueFormControl } from '../../models/valueFormControl';
import { SelectInputComponent } from '../../../components';
import { TranslateService } from '../../../services/common';
import { RadioButtonOption } from '../../../components';
import { BooleanFieldFormGroup } from './forms/metric-editor-boolean-field-form';

@Component({
  selector: 'lib-metric-editor-boolean',
  templateUrl: './metric-editor-boolean.component.html',
  styleUrls: ['./metric-editor-boolean.component.scss'],
})
export class MetricEditorBooleanComponent implements OnInit, DoCheck, OnDestroy {
  @Input({ required: true }) valueFormControl!: ValueFormControl<BooleanTypeDetails>;

  @ViewChild(SelectInputComponent) inputFieldRef!: SelectInputComponent;

  booleanFormGroup?: BooleanFieldFormGroup;

  hint: string = '';
  label: string = '';
  size: ValueDefinitionSize = ValueDefinitionSize.large;
  options: RadioButtonOption[] = [];
  required: boolean = false;

  constructor(
    private translateService: TranslateService,
    private metricEditorBooleanService: MetricEditorBooleanService
  ) {}

  ngOnInit(): void {
    this.booleanFormGroup = new BooleanFieldFormGroup(this.valueFormControl, this.metricEditorBooleanService);
    this.initVariables();
  }

  ngDoCheck(): void {
    this.booleanFormGroup?.handleValueChange();
    this.booleanFormGroup?.handleEnableDisableStatus();
    this.booleanFormGroup?.handleTouchedStatusChange();
  }

  ngOnDestroy(): void {
    this.booleanFormGroup?.updateValueFormControl(true);
    this.booleanFormGroup?.destroy();
  }

  setFocus(): void {
    this.inputFieldRef.setFocus();
  }

  private initVariables(): void {
    this.hint = this.valueFormControl.valueRef.hint ?? '';
    this.label = this.valueFormControl.valueRef.label ?? '';
    this.size = this.valueFormControl.valueRef.size;
    this.required = this.valueFormControl.hasValidator(Validators.required);
    this.options = [
      {
        value: 'true',
        label: this.valueFormControl.valueRef.type_details.label_true ?? '',
      },
      {
        value: 'false',
        label: this.valueFormControl.valueRef.type_details.label_false ?? '',
      },
    ];
  }

  public updateField(): void {
    this.booleanFormGroup?.blurAdditionalTextControl();
  }
}
