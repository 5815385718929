import { SourceType, Status } from './ui';
import { Indicator, Metric, ValueGroupSet, PlatformValueGroupSetStatus } from './metrics';
import { Permission } from './configs';
import { FiscalYear } from './fiscal-years';
import { Value } from './values';
import { MinimalTaxonomy } from './taxonomies';

interface Entity {
  id: string;
  name: string;
  code?: string;
  created?: Date | string;
  created_by?: string;
  updated?: Date | string;
  updated_by?: string;
  active?: boolean;
}

interface CreatedUpdatedEntity {
  created?: Date | string;
  created_by?: string;
  updated?: Date | string;
  updated_by?: string;
}

export interface ApiResponseMeta {
  count?: number;
  total_count?: number;
  task_execution_id?: string;
  status_count_map?: Record<PlatformValueGroupSetStatus, number>;
}

export interface ApiCall<T> {
  loading: boolean;
  status?: Status;
  data?: T;
  errors?: any[];
}

export interface ApiError<T> {
  detail?: T;
  type: string;
}

export interface ApiResponse<T = void, E = unknown> {
  meta: ApiResponseMeta;
  errors: ApiError<E>[];
  data: T;
}

export interface VersionInfo {
  date?: string;
  sentry_release_name?: string;
  version: string;
}

// Database models

export {
  // Generic
  Entity,
  CreatedUpdatedEntity,
  // Main Entities
  Doc,
  MinimalEntity,
  Dataset,
  DocMetaData,
  Source,
  UploadDocument,
  // Helpers
  CodeCheck,
  DisplayIndicator,
  Framework,
  Industry,
  MetricEquivalent,
  OauthProvider,
  Org,
  RequestDocMetadata,
  SamlProvider,
  Section,
  SectionDisplay,
  SectionNodeIndicator,
  SectionTemplate,
  SectionNode,
  StandardCodes,
  PublicDocUploadedFileMetaData,
  SourceAddress,
  Tag,
  Token,
  TopicCategory,
  TopicGroup,
  Topic,
  TwoFactorStatus,
  Unit,
  LimitedUser,
  User,
  UserEmailCheck,
  UserType,
  SelectionSet,
  SelectionSetItem,
  UnitGroup,
  UnitGroupItem,
  Company,
  CompanyAddress,
  Client,
  DataTableDisplay,
  IntegrationPartnerLogin,
  CDPSector,
  WDIIndustry,
  MarketIdentifierCode,
};

interface OauthProvider {
  id: string;
  type: 'microsoft' | 'google' | 'okta' | 'auth0';
  label: string;
}

interface SamlProvider {
  id: string;
  type: 'microsoft' | 'google' | 'okta' | 'auth0' | 'generic';
  label: string;
}

interface Source extends Entity {
  industries: string[];
  fiscal_years?: FiscalYear[];
  is_default: boolean;
  org_id?: string;
  type: SourceType;
  address?: SourceAddress;
  external_id?: string;
  company_id?: string;
  slug?: string;
  consolidation?: boolean;
  start_fiscal_year_frequency_code?: string;
  end_fiscal_year_frequency_code?: string;
  start_fiscal_year?: FiscalYear;
  end_fiscal_year?: FiscalYear;
}

interface Dataset extends Entity {
  from_year: number;
  to_year: number;
  metrics: Metric[];
  sources?: Source[];
}

interface Doc extends Entity {
  scope: 'client' | 'report' | 'resource';
  org_id?: string;
  org_name?: string;
  format: string;
  category: string;
  type: string;
  url?: string;
  public: boolean;
  year?: number;
  industries?: string[];
  tags?: string[];
  storage_filename: string;
  start: string;
  end: string;
}

interface DocMetaData {
  name: string;
  category: string;
  doc_type: string;
  fiscal_year: string | number;
  data_period_start: string;
  data_period_end: string;
  public?: boolean;
  business_unit_ids: string[];
  tag_ids?: string[];
  deleted?: string;
  extension?: string;
  id?: string;
  original_filename?: string;
  url: string;
}

export enum MetricCategory {
  THIRD_PARTY = 'third_party',
  REFERENCE = 'reference',
  PEER = 'peer',
  CUSTOM = 'custom',
}

export enum StandardCodeCategory {
  RATING = 'rating',
  REGULATION = 'regulation',
  REPORTING_FRAMEWORK = 'reporting_framework',
  REPORTING_STANDARD = 'reporting_standard',
}

export enum FrameworkCategory {
  STANDARD_CODE = 'standard_code',
  METRIC = 'metric',
}

interface Framework extends Entity {
  metrics?: Metric[];
  image_url?: string;
  metric_category?: MetricCategory;
  standard_code_category?: StandardCodeCategory;
  category?: FrameworkCategory;
}

interface StandardCodes {
  active: boolean;
  code: string;
  created: string;
  created_by: string;
  description: string;
  end: string;
  framework?: Framework;
  framework_id: string;
  guidance?: string;
  disclaimers?: string;
  id: string;
  industries: Industry[];
  taxonomies: MinimalTaxonomy[];
  topics: Topic[];
  tags: Tag[];
  metrics: Metric[];
  start: string;
  updated: string;
  updated_by: string;
  name: string;
}

interface Industry extends Entity {
  description?: string;
  sector_id?: string;
}

interface MinimalEntity extends Entity {
  description: string;
}

interface MetricEquivalent {
  original_metric_id: string;
  equivalent_metric_id: string;
  match: 'full' | 'partial';
  created: string;
  updated: string;
}

interface CodeCheck {
  available: boolean;
}

interface CDPSector extends CreatedUpdatedEntity {
  id: string;
  name: string;
}

interface WDIIndustry extends CreatedUpdatedEntity {
  id: string;
  name: string;
}

interface MarketIdentifierCode {
  id: string;
  name: string;
}

interface Org extends Entity {
  slug?: string;
  favorited?: boolean;
  brand_name: string | null;
  website: string | null;
  headquarter_location: string | null;
  listed: boolean | null;
  lucid_axon: boolean | null;
  fte: number | null;
  stock_exchange_market: string | null;
  ticker: string | null;
  primary_isin: string | null;
  lei: string | null;
  cdp_account_number: string | null;
  cdp_sector: CDPSector | null;
  wdi_industry: WDIIndustry | null;
  industries?: Industry[];
  fiscal_year_information?: {
    end?: string | null;
    start: string;
    regular: boolean;
  };
}

interface Section extends SectionTemplate {
  indicators?: Indicator[];
  display?: SectionDisplay[];
}

interface SectionDisplay {
  code?: string;
  description?: string;
  display_indicators?: DisplayIndicator[];
  metametric: boolean;
}

interface DataTableDisplay {
  year: number;
  value?: Value;
  vgs?: ValueGroupSet;
}

interface DisplayIndicator {
  indicator_id: string;
  value_definition_group_id?: string;
  value_definition_id?: string;
  value_label?: string;
  yearlyData?: DataTableDisplay[];
  indicator?: Indicator;
}

interface SectionTemplate extends Entity {
  label?: string;
  position: number;
  parent_id?: string | null;
  children?: Section[];
  metrics?: Metric[];
}

interface SectionNodeIndicator extends SectionDisplay {
  indicator: Indicator;
}

interface SectionNode<T = any> {
  id?: number;
  section_id?: string;
  section_position?: number;
  section_parent_id?: string | null;
  error?: boolean;
  children?: SectionNode<T>[];
  metrics?: Metric[];
  item?: T;
  parent?: Section;
  isLastItem?: boolean;
  display_items?: SectionDisplay[];
  level?: number;
  isExpanded?: boolean;
  loading?: boolean;
}

interface RequestDocMetadata {
  data_period_end: string;
  data_period_start: string;
  extension: string;
  fiscal_year: number;
  id: string;
  name: string;
  updated: string;
  deleted?: string;
}

interface PublicDocUploadedFileMetaData extends Omit<Entity, 'name'> {
  container: string;
  file_hash: string;
  extension: string;
  meta: {
    business_unit_ids: string[];
    category: string;
    data_period_end: string;
    data_period_start: string;
    doc_type: string;
    fiscal_year: string;
    name: string;
    org_id: string;
    public: boolean;
    scope: string;
    tag_ids: string[];
  };
  original_filename: string;
}

export interface Association {
  count: number;
  resource_ids: string[];
}

export interface TagAssociations {
  metric: Association;
  report: Association;
  benchmark: Association;
  document: Association;
}

interface Tag extends Entity {
  label: string;
  short_label: string;
  core_tag_id?: string;
  associations?: TagAssociations;
}

interface TopicCategory extends Entity {
  slug: string;
  topic_groups: TopicGroup[];
}

interface TopicGroup extends Entity {
  slug: string;
  topic_category_id: string;
  topics: Topic[];
}

interface Topic extends Entity {
  slug: string;
  topic_group_id: string;
}

interface Token {
  access_token: string;
  refresh_token: string;
  redirect_uri?: string;
  hydra_redirect_uri?: string;
}

interface TwoFactorStatus {
  enabled: boolean;
}

interface Unit {
  id: string;
  code: string;
  symbol: string;
  label: string;
  family: string;
  position: number;
}

interface UploadDocument {
  document_uploaded_id: string;
  parsing_job_id: string;
}

interface LimitedUser {
  id: string;
  active: boolean;
  first_name: string;
  last_name: string;
  email: string;
  type: UserType;
  title?: string;
  department?: string;
}

interface User extends LimitedUser, Entity {
  aud: string;
  active: boolean;
  allow_basic_auth: boolean;
  client_code: string;
  client_id: string;
  exp: number;
  iat: number;
  iss: string;
  org_id: string;
  org_name: string;
  roles: string[];
  user_id: string;
  username: string;
  access_token: string;
  refresh_token: string;
  rexp: number;
  password_last_changed?: string;
  default_to_hq_fiscal_year: boolean;
  require_source_on_documents: boolean;
  permissions: Permission[];
  twofactor_status?: TwoFactorStatus;
}

interface UserEmailCheck {
  available: boolean;
}

enum UserType {
  STANDARD = 'standard',
  PUBLIC = 'public',
}

interface SelectionSet extends Entity {
  description: string;
  public: boolean;
  selection_set_items: SelectionSetItem[];
}

interface SelectionSetItem extends Entity {
  tooltip?: string;
  position: number;
  public: boolean;
  selection_set_id: string;
}

interface UnitGroup extends Entity {
  description: string;
  public: boolean;
}

interface UnitGroupItem extends Entity {
  tooltip?: string;
  symbol?: string;
  position: number;
  public: boolean;
  unit_group_id: string;
}

interface Company extends Entity {
  name: string;
  business_units: Source[];
  parent_company_id: string;
  child_companies: Company[];
  address?: CompanyAddress;
}

interface CompanyAddress {
  address_1: string;
  address_2?: string;
  city: string;
  country: string;
  state: string;
  zip?: string;
}

export interface Iso3166Country {
  name: string;
  iso: string;
}

export interface Iso3166State {
  name: string;
  iso: string;
  countryIso: string;
}

interface SourceAddress {
  address_1: string;
  address_2?: string;
  city: string;
  country: string;
  state: string;
  zip?: string;
}

interface Client {
  created: string;
  created_by: string | null;
  updated: string;
  updated_by: string | null;
  id: string;
  name: string;
  org_id: string;
  code: string;
  agent_id: string | null;
  require_source_on_documents: boolean;
  communication_lang: string[];
  source_configuration: string;
  regular_fiscal_years: boolean;
  integration_green_project_enabled: boolean;
  integration_edci_enabled: boolean;
  integration_tableau_enabled: boolean;
  integration_s_and_p_enabled: boolean;
  integration_cdp_enabled: boolean;
  integration_minimum_enabled: boolean;
  client_id: string;
  org_name: string;
  integration_partner_logins: IntegrationPartnerLogin[];
}

interface IntegrationPartnerLogin {
  client_id: string;
  integration_type: string;
  url: string;
  enabled: boolean;
}

export enum DeactivateEntityTypes {
  FIELD = 'Field',
  GROUP = 'Group',
  TABLE = 'Table',
}
