<div
  *ngIf="control"
  class="formfield"
  data-testid="multi-select-dropdown"
  [ngClass]="{ required: required, disabled: control.disabled }"
>
  <lib-form-field-label [label]="label" [control]="control" [id]="_labelId"></lib-form-field-label>
  <mat-form-field [floatLabel]="'never'">
    <mat-select #select [formControl]="control" multiple [placeholder]="placeholder" [aria-labelledby]="_labelId">
      <mat-select-trigger class="select-trigger">
        <div libEllipsifyMe [maxCharCount]="maxCharCount" class="first-selection">{{ firstOptionSelectedTitle }}</div>
        <div
          *ngIf="control.value?.length > 1 && firstOptionSelectedTitle !== selectAllValue"
          class="additional-selection"
        >
          +
          {{
            "{count, plural, =1{{count} other} other{{count} others} }"
              | translate : { count: numberOfSelectedOptions - 1 }
          }}
        </div>
      </mat-select-trigger>

      <mat-option
        *ngIf="withSelectAllOption"
        [value]="selectAllValue"
        class="hide-checkbox checkbox-color"
        (click)="toggleAllSelection()"
      >
        <mat-checkbox
          class="checkbox-override checkbox-color"
          (click)="$event.preventDefault()"
          [checked]="allSelected"
          [indeterminate]="indeterminateAllCheckbox"
        >
          {{ "Select All" | translate }}
        </mat-checkbox>
      </mat-option>
      <mat-divider class="ml-1 mr-1"></mat-divider>

      <mat-option
        *ngFor="let option of options"
        [value]="option.id"
        [disabled]="option.disabled"
        class="checkbox-color"
        (click)="selectOption()"
      >
        {{ option.title }}
      </mat-option>
    </mat-select>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-error *ngFor="let error of control.errors | errorKeys"> {{ errorMessages[error] }}</mat-error>
  </mat-form-field>
</div>
