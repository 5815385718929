import { formatDate } from '@angular/common';

import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';

import { Builder } from './builder';
import { BUILDER_DATE_FORMAT, BUILDER_LOCAL } from './builder-utils';
import { CreatedUpdatedEntity } from '../models';

export abstract class CreatedUpdatedBuilder<T extends CreatedUpdatedEntity> extends Builder<T> {
  protected constructor(instance: T) {
    super({
      created: formatDate(faker.date.past(), BUILDER_DATE_FORMAT, BUILDER_LOCAL),
      updated: formatDate(faker.date.past(), BUILDER_DATE_FORMAT, BUILDER_LOCAL),
      created_by: uuidv4(),
      updated_by: uuidv4(),
      ...instance,
    });
  }

  created(created: Date | string): CreatedUpdatedBuilder<T> {
    this._instance.created = created;
    return this;
  }

  created_by(created_by: string | undefined): CreatedUpdatedBuilder<T> {
    this._instance.created_by = created_by;
    return this;
  }

  updated(updated: Date | string): CreatedUpdatedBuilder<T> {
    this._instance.updated = updated;
    return this;
  }

  updated_by(updated_by: string | undefined): CreatedUpdatedBuilder<T> {
    this._instance.updated_by = updated_by;
    return this;
  }
}
