import { SPCompany, SPQuestionnaire } from '../models';
import { faker } from '@faker-js/faker';

export class SPCompanyBuilder {
  private readonly _spCompany: SPCompany;

  constructor() {
    this._spCompany = {
      business_id: faker.datatype.number({ min: 10000000, max: 100000000 }),
      company_id: faker.datatype.number({ min: 10000000, max: 100000000 }),
      long_name: faker.random.alpha(10),
    };
  }

  business_id(business_id: number): SPCompanyBuilder {
    this._spCompany.business_id = business_id;
    return this;
  }

  company_id(company_id: number): SPCompanyBuilder {
    this._spCompany.company_id = company_id;
    return this;
  }

  long_name(long_name: string): SPCompanyBuilder {
    this._spCompany.long_name = long_name;
    return this;
  }

  build(): SPCompany {
    return this._spCompany;
  }
}

export class SPQuestionnaireBuilder {
  private readonly _spQuestion: SPQuestionnaire;

  constructor() {
    this._spQuestion = {
      id: faker.datatype.number({ min: 10000000, max: 100000000 }),
      cam_id: faker.datatype.number({ min: 10000000, max: 100000000 }),
      cam_name: faker.random.alpha(10),
      start_date: faker.datatype.datetime(),
      end_date: faker.datatype.datetime(),
      editable: true,
      will_expire_soon: false,
      prefill_blocked: false,
      avg_status: faker.datatype.float(),
      validity: '100 days',
      progress: '10%',
      enable_start_questionnaire: true,
    };
  }

  editable(editable: boolean): SPQuestionnaireBuilder {
    this._spQuestion.editable = editable;
    return this;
  }

  id(id: number): SPQuestionnaireBuilder {
    this._spQuestion.id = id;
    return this;
  }

  cam_id(cam_id: number): SPQuestionnaireBuilder {
    this._spQuestion.cam_id = cam_id;
    return this;
  }

  cam_name(cam_name: string): SPQuestionnaireBuilder {
    this._spQuestion.cam_name = cam_name;
    return this;
  }

  build(): SPQuestionnaire {
    return this._spQuestion;
  }
}
