import { MetricTableCalculationDefinition, MetricTableColumnDefinition, MetricTableDefinition } from '../models';

export class MetricTableDefinitionBuilder {
  private readonly _metricTableDefinition: MetricTableDefinition;

  constructor() {
    this._metricTableDefinition = {
      id: 'id',
      title: 'Test Table',
      metric_id: 'metric_id',
      column_definitions: [],
      calculation_definitions: [],
    };
  }

  id(id: string): MetricTableDefinitionBuilder {
    this._metricTableDefinition.id = id;
    return this;
  }

  columnDefinitions(columnDefinitions: MetricTableColumnDefinition[]): MetricTableDefinitionBuilder {
    this._metricTableDefinition.column_definitions = columnDefinitions;
    return this;
  }

  calculationDefinitions(calculationDefinitions: MetricTableCalculationDefinition[]): MetricTableDefinitionBuilder {
    this._metricTableDefinition.calculation_definitions = calculationDefinitions;
    return this;
  }

  metricId(metricId: string): MetricTableDefinitionBuilder {
    this._metricTableDefinition.metric_id = metricId;
    return this;
  }

  active(active?: boolean): MetricTableDefinitionBuilder {
    this._metricTableDefinition.active = active;
    return this;
  }

  hasValues(hasValues?: boolean): MetricTableDefinitionBuilder {
    this._metricTableDefinition.has_values = hasValues;
    return this;
  }

  title(value: string): MetricTableDefinitionBuilder {
    this._metricTableDefinition.title = value;
    return this;
  }

  technicalProtocol(technicalProtocol: string): MetricTableDefinitionBuilder {
    this._metricTableDefinition.technical_protocol = technicalProtocol;
    return this;
  }

  build(): MetricTableDefinition {
    return this._metricTableDefinition;
  }
}
