import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private _messages: any = {};
  private _singleSelection: string = 'selectedItem';
  private _multipleSelection: string = 'selectedItems';
  private _previousSelected: string = 'previousItem';
  private _nextSelected: string = 'nextItem';
  private _disableSelection: string = 'disableSelection';
  private saveSubject = new Subject<boolean>();
  public save$: Observable<boolean> = this.saveSubject.asObservable();

  public setMessage(type: string, msg: string) {
    const typeMessage = this._messages[type];
    if (!typeMessage) {
      this._messages[type] = new BehaviorSubject<string>('');
    }
    this._messages[type].next(msg);
  }

  public getMessage(type: string): Observable<string> {
    const typeMessage = this._messages[type];
    if (!typeMessage) {
      this._messages[type] = new BehaviorSubject<string>('');
    }
    return this._messages[type].asObservable();
  }

  // Selection

  public selectItem(id: string) {
    return this.setMessage(this._singleSelection, id);
  }

  public setPreviousItem(id: string) {
    return this.setMessage(this._previousSelected, id);
  }

  public setNextItem(id: string) {
    return this.setMessage(this._nextSelected, id);
  }

  public getSelectedItem(): Observable<string> {
    return this.getMessage(this._singleSelection);
  }

  public getPreviousItem(): Observable<string> {
    return this.getMessage(this._previousSelected);
  }

  public getNextItem(): Observable<string> {
    return this.getMessage(this._nextSelected);
  }

  public unselectItem() {
    this.setMessage(this._previousSelected, '');
    this.setMessage(this._nextSelected, '');
    return this.setMessage(this._singleSelection, '');
  }

  public selectItems(ids: string[]) {
    return this.setMessage(this._multipleSelection, ids.join('::'));
  }

  public getSelectedItems(): Observable<string[]> {
    return this.getMessage(this._multipleSelection).pipe(
      map((result) => {
        let items: string[] = [];
        if (result) {
          items = result.split('::');
        }
        return items;
      })
    );
  }

  public addSelectedItem(id: string) {
    const typeMessage = this._messages[this._multipleSelection];
    if (!typeMessage) {
      return this.selectItem(id);
    } else {
      const ids: string[] = (typeMessage + '').split('::');
      if (ids.indexOf(id) < 0) {
        ids.push(id);
        return this.selectItems(ids);
      }
    }
  }

  public unselectItems() {
    return this.setMessage(this._multipleSelection, '');
  }

  public disableSelection(disable: boolean) {
    return this.setMessage(this._disableSelection, disable ? 'true' : 'false');
  }

  public getDisableSelection(): Observable<string> {
    return this.getMessage(this._disableSelection);
  }

  public saveTriggered(success: boolean = true) {
    this.saveSubject.next(success);
  }
}
