import { FiscalYearClosingValidation, MetricsMappingCount } from '../models';
import { faker } from '@faker-js/faker';

export class FiscalYearValidateBuilder {
  private readonly _fiscalYearValidate: FiscalYearClosingValidation;

  constructor() {
    this._fiscalYearValidate = {
      metrics_mapping_count: [],
      data_request_count: faker.datatype.number(10),
    };
  }

  metricMappingCount(metricMappingCount: MetricsMappingCount[]): FiscalYearValidateBuilder {
    this._fiscalYearValidate.metrics_mapping_count = metricMappingCount;
    return this;
  }

  dataRequestCount(dataRequestCount: number): FiscalYearValidateBuilder {
    this._fiscalYearValidate.data_request_count = dataRequestCount;
    return this;
  }

  build(): FiscalYearClosingValidation {
    return this._fiscalYearValidate;
  }
}
