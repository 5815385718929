import { faker } from '@faker-js/faker';
import { FiscalYearRequest, FiscalYearStatus } from '../models';

export class FiscalYearRequestBuilder {
  private readonly _fiscalYearRequest: FiscalYearRequest;

  constructor() {
    this._fiscalYearRequest = {
      frequency_code: faker.date.recent().getFullYear(),
      start: faker.date.past(),
      end: faker.date.recent(),
      regular_fiscal_years: true,
      status: FiscalYearStatus.OPEN,
    };
  }

  regular_fiscal_years(regular_fiscal_years: boolean): FiscalYearRequestBuilder {
    this._fiscalYearRequest.regular_fiscal_years = regular_fiscal_years;
    return this;
  }

  frequency_code(frequency_code: number): FiscalYearRequestBuilder {
    this._fiscalYearRequest.frequency_code = frequency_code;
    return this;
  }

  start(start: Date): FiscalYearRequestBuilder {
    this._fiscalYearRequest.start = start;
    return this;
  }

  end(end: Date): FiscalYearRequestBuilder {
    this._fiscalYearRequest.end = end;
    return this;
  }

  build(): FiscalYearRequest {
    return this._fiscalYearRequest;
  }
}
