import { ActionItem, Permission, ResourceType } from '../models';
import { ActionItemUtils } from '../classes';
import { Icon } from '../icons/icons.model';

export class ActionItemBuilder<T> {
  private readonly _actionItem: ActionItem<T>;

  constructor() {
    this._actionItem = {
      id: 'id',
      title: 'title',
    };
  }

  id(id: string): ActionItemBuilder<T> {
    this._actionItem.id = id;
    return this;
  }

  action(action: string): ActionItemBuilder<T> {
    this._actionItem.action = action;
    return this;
  }

  title(title: string): ActionItemBuilder<T> {
    this._actionItem.title = title;
    return this;
  }

  item(item: T): ActionItemBuilder<T> {
    this._actionItem.item = item;
    return this;
  }

  updated(updated: Date): ActionItemBuilder<T> {
    this._actionItem.updated = updated;
    return this;
  }

  permissions(permissions: Permission[]): ActionItemBuilder<T> {
    this._actionItem.permissions = permissions;
    return this;
  }

  fromResource(resource: T, itemType: ResourceType): ActionItemBuilder<T> {
    const result: ActionItem<T> | ActionItem<T>[] = ActionItemUtils.resourceToActionItem<T>(resource, itemType);

    if (result instanceof Array) {
      throw new Error(`Cannot build from array: ${itemType}`);
    }

    Object.assign(this._actionItem, result);
    return this;
  }

  icon(icon: Icon): ActionItemBuilder<T> {
    this._actionItem.icon = icon;
    return this;
  }

  image(image: string): ActionItemBuilder<T> {
    this._actionItem.image = image;
    return this;
  }

  build(): ActionItem<T> {
    return this._actionItem;
  }
}
