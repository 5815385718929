import { SourceAddress } from '../models';

export class SourceAddressBuilder {
  private readonly _sourceAddress: SourceAddress;

  constructor() {
    this._sourceAddress = {
      address_1: '1221 rue du CN',
      address_2: '1222 rue du CN',
      city: 'Toronto',
      country: 'CA',
      state: 'CA-O',
      zip: '123456',
    };
  }

  address1(address: string): SourceAddressBuilder {
    this._sourceAddress.address_1 = address;
    return this;
  }

  address2(address: string): SourceAddressBuilder {
    this._sourceAddress.address_2 = address;
    return this;
  }

  city(city: string): SourceAddressBuilder {
    this._sourceAddress.city = city;
    return this;
  }
  country(country: string): SourceAddressBuilder {
    this._sourceAddress.country = country;
    return this;
  }
  state(state: string): SourceAddressBuilder {
    this._sourceAddress.state = state;
    return this;
  }

  zip(zip: string): SourceAddressBuilder {
    this._sourceAddress.zip = zip;
    return this;
  }

  build(): SourceAddress {
    return this._sourceAddress;
  }
}
