<mat-menu #groupMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item [disabled]="moveUpDisabled" (click)="moveUp()">
      <mat-icon svgIcon="arrow-full-up" class="icon-sm"> </mat-icon>
      <span>{{ "Move up" | translate }}</span>
    </button>
    <button mat-menu-item [disabled]="moveDownDisabled" (click)="moveDown()">
      <mat-icon svgIcon="arrow-full-down" class="icon-sm"></mat-icon>
      <span>{{ "Move down" | translate }}</span>
    </button>
    <hr />
    <button mat-menu-item (click)="delete()">
      <mat-icon svgIcon="trash" class="icon-sm"></mat-icon>
      <span>{{ "Delete" | translate }}</span>
    </button>
  </ng-template>
</mat-menu>

<ng-container *ngIf="valueGroupFormGroup.valueGroupRef.show_label_as_subtitle">
  <h4>{{ valueGroupFormGroup.valueGroupRef.label }}</h4>
</ng-container>
<a role="button" aria-label="options" *ngIf="showOptionMenu" class="options" [matMenuTriggerFor]="groupMenu">
  <mat-icon svgIcon="more"></mat-icon>
</a>
<div class="form">
  <ng-container
    *ngFor="let valueFormControl of valueGroupFormGroup.displayedValueFormControls(); trackBy: valueFormControlTrackBy"
  >
    <lib-metric-editor-field-handler
      *ngIf="!isACalculatedFieldInARepeatableGroup(valueFormControl)"
      data-testid="field-handler"
      [class]="'fx-col justify-end ' + (valueFormControl.valueRef | valueDefinitionFieldSize)"
      [ngClass]="{ newline: valueFormControl.valueRef.newline }"
      [valueFormControl]="valueFormControl"
      [documentContext]="documentContext"
      [focusId]="getFocusId(valueFormControl.valueRef)"
      [indicatorId]="indicatorId"
      [vgsetId]="vgsetId"
      [groupId]="valueGroupFormGroup.valueGroupRef.id"
      [displayFieldActions]="displayFieldActions"
      [collaboratorResponsibility]="collaboratorResponsibility"
      [valueGroupSetStatus]="valueGroupSetStatus"
      [dataRequestSourceStatus]="dataRequestSourceStatus"
      (metricLinkEdit)="metricLinkEdit.emit($event)"
      (update)="onUpdateValue($event)"
      (resetValue)="handleResetValue($event)"
    ></lib-metric-editor-field-handler>
  </ng-container>
</div>
<mat-divider class="w-100 mb-2 mt-4" *ngIf="showOptionMenu"> </mat-divider>
<a
  *ngIf="isShowAddGroupButton()"
  class="fx-row justify-start align-center mb-4 mt-2 btn btn-primary"
  (click)="repeatGroup()"
>
  <mat-icon svgIcon="add" class="mr-1"></mat-icon><span>{{ "Add group" | translate }}</span>
</a>
