import { OauthProvider } from '../models';

export class OauthProviderBuilder {
  private readonly _oauthProvider: OauthProvider;

  constructor() {
    this._oauthProvider = {
      id: '123',
      label: 'label',
      type: 'microsoft',
    };
  }

  build(): OauthProvider {
    return this._oauthProvider;
  }
}
