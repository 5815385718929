import { ConditionalTrigger, getValueGroupId, ValueDefinitionType } from '../../models';
import { ValueGroupSetForm } from '../models/valueGroupSetForm';
import { ValueFormControl } from '../models/valueFormControl';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConditionalTriggerService {
  private readonly SUPPORTED_VALUE_DEFINITION_TYPE = [ValueDefinitionType.boolean, ValueDefinitionType.choice];

  public isSupportedType(type: ValueDefinitionType): boolean {
    return this.SUPPORTED_VALUE_DEFINITION_TYPE.includes(type);
  }

  public isElementDisplayed(triggers: ConditionalTrigger[] | undefined, valueGroupSetForm: ValueGroupSetForm): boolean {
    if (triggers && triggers.length > 0) {
      return this.hasTriggerTriggeredForValueGroupSetForm(triggers, valueGroupSetForm);
    }

    return true;
  }

  public getUntriggeredFilledValueFormControls(
    updatedValueFormControl: ValueFormControl,
    valueGroupSetForm: ValueGroupSetForm
  ): ValueFormControl[] {
    const untriggeredGroups = valueGroupSetForm
      .groupFormGroups()
      .filter((vgf) =>
        this.hasUntriggeredTriggerForValue(vgf.valueGroupRef.conditional_triggers ?? [], updatedValueFormControl)
      );
    const filledValuesFromUntriggeredGroups = untriggeredGroups
      .reduce((vfcs: ValueFormControl[], groupForm) => [...vfcs, ...groupForm.valueFormControls()], [])
      .filter((vfc) => vfc.value != null && vfc.value != '');

    const untriggeredFilledValues =
      valueGroupSetForm
        .groupFormGroups()
        .find((vgf) => vgf.valueGroupRef.id === getValueGroupId(updatedValueFormControl.valueRef))
        ?.valueFormControls()
        .filter((vfc) =>
          this.hasUntriggeredTriggerForValue(vfc.valueRef.conditional_triggers ?? [], updatedValueFormControl)
        )
        .filter((vfc) => vfc.value != null && vfc.value != '') ?? [];

    return [...filledValuesFromUntriggeredGroups, ...untriggeredFilledValues];
  }

  private hasUntriggeredTriggerForValue(triggers: ConditionalTrigger[], value: ValueFormControl): boolean {
    return triggers
      .filter((t) => t.source_value_definition_id === value.valueRef.value_definition_id)
      .some((t) => !this.isTriggerTriggered(t, value));
  }

  private hasTriggerTriggeredForValueGroupSetForm(
    triggers: ConditionalTrigger[],
    valueGroupSetForm: ValueGroupSetForm
  ): boolean {
    const allValues = valueGroupSetForm
      .groupFormGroups()
      .reduce(
        (valueFormControls: ValueFormControl[], valueGroupForm) => [
          ...valueFormControls,
          ...valueGroupForm.valueFormControls(),
        ],
        []
      );
    return this.hasTriggerTriggered(triggers, allValues);
  }

  private hasTriggerTriggered(triggers: ConditionalTrigger[], valueFormControls: ValueFormControl[]): boolean {
    return triggers.some((t) =>
      this.isTriggerTriggered(
        t,
        valueFormControls.find((vfc) => vfc.valueRef.value_definition_id === t.source_value_definition_id)
      )
    );
  }

  private isTriggerTriggered(trigger: ConditionalTrigger, valueFormControl?: ValueFormControl): boolean {
    if (valueFormControl) {
      const sourceValueValue =
        valueFormControl.valueRef.type === ValueDefinitionType.boolean
          ? (valueFormControl.value?.value as boolean)
          : (valueFormControl.value?.values as string[]);

      return Array.isArray(sourceValueValue)
        ? (trigger.values as string[]).some((value) => sourceValueValue.includes(value))
        : (trigger.values as boolean[]).includes(sourceValueValue);
    }
    return false;
  }
}
