import { Industry } from '../models';

export class IndustryBuilder {
  private readonly _industry: Industry;

  constructor() {
    this._industry = {
      id: 'industry_id',
      code: 'industry_code',
      name: 'Industry name',
    };
  }

  id(id: string): IndustryBuilder {
    this._industry.id = id;
    return this;
  }

  name(name: string): IndustryBuilder {
    this._industry.name = name;
    return this;
  }

  updated(updated: Date): IndustryBuilder {
    this._industry.updated = updated;
    return this;
  }

  build(): Industry {
    return this._industry;
  }
}
