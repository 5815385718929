import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';

import { finalize, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, Subject, forkJoin, of } from 'rxjs';

import {
  ActionItem,
  Collection,
  ItemType,
  Metric,
  MetricCategory,
  RelatedMetricSource,
  ResourceType,
  SearchOptions,
  StandardCodes,
} from '../../../../models';
import { ActionItemUtils } from '../../../../classes';
import { ClientConfigService, ClientTagsService } from '../../../../services/client';
import { MetricApiService } from '../../../../services/types';
import { MetricStructureStateService } from '../../../services/metric-structure-state.service';
import { SearchService } from '../../../../search';
import { StandardCodesService } from '../../../../standard-codes/standard-codes.service';
import { TranslateService } from '../../../../services/common';
import { UniquenessValidator } from '../../../../validators';

@Component({
  selector: 'lib-metric-structure-settings',
  templateUrl: './metric-structure-settings.component.html',
  styleUrls: ['./metric-structure-settings.component.scss'],
})
export class MetricStructureSettingsComponent implements OnInit, OnDestroy {
  @Output() closeProperties: EventEmitter<void> = new EventEmitter<void>();

  metric?: Metric;
  metricForm: UntypedFormGroup;
  codeFormControl: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(20)]);
  descriptionFormControl: UntypedFormControl = new UntypedFormControl('', Validators.required);
  guidanceFormControl: UntypedFormControl = new UntypedFormControl('');
  topicsFormControl: UntypedFormControl = new UntypedFormControl(null);
  tagsFormControl: UntypedFormControl = new UntypedFormControl(null);
  relatedMetricsFormControl: UntypedFormControl = new UntypedFormControl(null);
  standardCodesFormControl: UntypedFormControl = new UntypedFormControl(null);

  readonly codeFieldValidationMsgs: ValidationErrors = {
    required: this.translateService.instant('Code is required'),
    maxlength: this.translateService.instant('Code is too long'),
    isUnique: this.translateService.instant('This metric code already exists'),
  };

  readonly descriptionFieldValidationMsgs: ValidationErrors = {
    required: this.translateService.instant('Description is required'),
  };

  topicOptions: ActionItem[] = [];
  tagOptions: ActionItem[] = [];
  metricOptions: ActionItem[] = [];
  standardCodesOptions: ActionItem<StandardCodes>[] = [];
  readonly eMetricCategory: typeof MetricCategory = MetricCategory;

  updating$: Observable<boolean> = this.metricStructureService.isMetricUpdating$;
  canEditEveryMetrics: boolean = false;
  refMetricsV2EnabledFF: boolean = false;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private fb: UntypedFormBuilder,
    private metricsService: MetricApiService,
    private searchService: SearchService,
    private tagsService: ClientTagsService,
    private metricStructureService: MetricStructureStateService,
    private standardCodesService: StandardCodesService,
    private clientConfigService: ClientConfigService
  ) {
    this.metricForm = fb.group({
      codeFormControl: this.codeFormControl,
      descriptionFormConttrol: this.descriptionFormControl,
      guidanceFormControl: this.guidanceFormControl,
      topicsControl: this.topicsFormControl,
      relatedMetrics: this.relatedMetricsFormControl,
      compatibleWith: this.standardCodesFormControl,
      tagsControl: this.tagsFormControl,
    });

    this.metricStructureService.canEditEveryMetrics$.pipe(first()).subscribe((canEditEveryMetrics) => {
      this.canEditEveryMetrics = canEditEveryMetrics;
    });
  }

  ngOnInit(): void {
    this.refMetricsV2EnabledFF = this.clientConfigService.areAnyFeatureFlagsEnabled(['enable_ref_metrics_v2']);

    forkJoin([
      this.searchService.searchResources(ResourceType.topic),
      this.searchService.searchResources(ResourceType.tag),
    ]).subscribe((results) => {
      this.topicOptions = results[0].filter((x) => x.item?.topic_group_id);
      this.tagOptions = results[1];
    });
    this.metricStructureService.metric$.subscribe((metric) => {
      this.initializeMetricProperties(metric);
    });
    this.metricsService.getRelatedMetrics(this.metric?.id || '').subscribe((result) => {
      const cloneMetric: Metric = Object.assign({}, this.metric);
      cloneMetric.related_metrics = result.data;
      this.metricStructureService.updateMetric(cloneMetric);
    });

    this.searchStandardCodes('');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  public addTag(event: MatChipInputEvent): void {
    const value = event.value;
    this.tagsService
      .createTag(value.trim())
      .pipe(switchMap((newTagRes) => forkJoin([of(newTagRes), this.searchService.searchResources('tag')])))
      .subscribe({
        next: ([newTagRes, tagsRes]) => {
          const tag: ActionItem = {
            id: newTagRes.data.id,
            title: newTagRes.data.label,
            item: newTagRes.data,
          };
          this.tagOptions = tagsRes;
          this.tagsFormControl.setValue([...((this.tagsFormControl.value as ActionItem[] | null) ?? []), tag]);
          this.tagsFormControl.markAsDirty();
        },
      });
  }

  public searchStandardCodes(keywords: string): void {
    const searchOptions: SearchOptions = {
      item_type: ItemType.standard_codes,
      query: {
        keywords,
      },
      filters: {},
    };
    this.standardCodesService
      .search(searchOptions)
      .pipe(
        map((stdCodes) => stdCodes.map((stdCode) => ({ id: stdCode.id, title: stdCode.code, item: stdCode }))),
        takeUntil(this.destroy$)
      )
      .subscribe((standardCodes) => {
        this.standardCodesOptions = standardCodes.filter(
          (standardCode) =>
            !this.metric?.standard_codes?.field_level.some(
              (fieldStandardCode) => fieldStandardCode.id === standardCode.id
            )
        );
      });
  }

  private getRelatedMetrics(keywords: string) {
    this.searchRelatedMetrics(keywords)
      .pipe(takeUntil(this.destroy$))
      .subscribe((collection) => {
        this.metricOptions = collection.items;
      });
  }

  private searchRelatedMetrics<T>(keywords: string): Observable<Collection<T>> {
    const searchOptions = {
      item_type: ItemType.related_metrics,
      query: {
        keywords,
      },
      filters: {},
      excludes: {},
      sort: {
        id: 'score',
        title: 'Best Match',
      },
    };

    switch (this.metric?.category) {
      case MetricCategory.THIRD_PARTY:
        searchOptions.excludes = {
          framework_ids: [this.metric.framework_id || ''],
        };
        break;
      case MetricCategory.REFERENCE:
        searchOptions.excludes = {
          categories: [MetricCategory.REFERENCE],
        };
        break;
    }

    return this.searchService.search(searchOptions) as Observable<Collection<T>>;
  }

  public updateMetricsOptions(keywords: string) {
    this.getRelatedMetrics(keywords);
  }

  public saveProperties(): void {
    if (this.metricForm.valid) {
      this.metricStructureService.updateIsMetricUpdating(true);
      this.metricsService
        .updateMetric(String(this.metric?.id), this.constructPayload())
        .pipe(
          finalize(() => {
            this.metricStructureService.updateIsMetricUpdating(false);
          })
        )
        .subscribe((response) => {
          this.metricStructureService.updateMetric(response.data);
          this.metricForm.markAsPristine();
        });
    }
  }

  public closeSettings(): void {
    this.closeProperties.emit();
  }

  private constructPayload(): { [key: string]: string | any[] } {
    return {
      ...(this.codeFormControl.enabled && {
        code: `${this.metric?.category === MetricCategory.CUSTOM ? 'CUS ' : ''}${this.codeFormControl.value}`,
      }),
      ...(this.descriptionFormControl.enabled && { description: this.descriptionFormControl.value }),
      ...(this.guidanceFormControl.enabled && { guidance: this.guidanceFormControl.value }),
      ...(this.topicsFormControl.enabled && {
        topics: (this.topicsFormControl.value as ActionItem[] | null)?.map((x) => x.id) ?? [],
      }),
      ...(this.tagsFormControl.enabled && {
        tags: (this.tagsFormControl.value as ActionItem[] | null)?.map((x) => x.id) ?? [],
      }),
      ...(this.standardCodesFormControl.enabled && {
        standard_codes: (this.standardCodesFormControl.value as ActionItem[] | null)?.map((x) => x.id) ?? [],
      }),
      ...(this.relatedMetricsFormControl.enabled && {
        metric_equivalents: (this.relatedMetricsFormControl.value as ActionItem[] | null)?.map((x) => x.id) ?? [],
      }),
    };
  }

  private initMetricFormControls(): void {
    if (this.canEditEveryMetrics) {
      const completeCode = this.metric?.code || '';
      const [prefix, ...codeComponents] = completeCode.split(' ');
      const code = codeComponents.join(' ');
      this.codeFormControl.clearAsyncValidators();
      this.codeFormControl.setValue(code);
      this.codeFormControl.addAsyncValidators(
        UniquenessValidator.validate(
          (code: string) =>
            this.metricsService.checkIfMetricCodeExists(code, prefix).pipe(map((res) => res.data.available)),
          [String(code)]
        )
      );
      this.descriptionFormControl.setValue(this.metric?.description);
      this.guidanceFormControl.setValue(this.metric?.guidance);
      if (this.metric?.category !== MetricCategory.REFERENCE) {
        this.standardCodesFormControl.disable();
      }
    } else {
      switch (this.metric?.category) {
        case MetricCategory.CUSTOM:
          const code = this.metric.code?.split(' ').splice(1).join(' ');
          this.codeFormControl.clearAsyncValidators();
          this.codeFormControl.setValue(code);
          this.codeFormControl.addAsyncValidators(
            UniquenessValidator.validate(
              (code: string) =>
                this.metricsService.checkIfMetricCodeExists(code).pipe(map((res) => res.data.available)),
              [String(code)]
            )
          );
          this.codeFormControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
            if (!this.codeFormControl.touched) {
              this.codeFormControl.markAsTouched({ onlySelf: true });
            }
          });
          this.descriptionFormControl.setValue(this.metric.description);
          this.guidanceFormControl.setValue(this.metric.guidance);
          break;
        case MetricCategory.REFERENCE:
          this.codeFormControl.disable();
          this.descriptionFormControl.setValue(this.metric.description);
          this.topicsFormControl.disable();
          this.guidanceFormControl.setValue(this.metric.guidance);
          this.standardCodesFormControl.disable();
          break;
        case MetricCategory.THIRD_PARTY:
          this.codeFormControl.disable();
          this.descriptionFormControl.disable();
          this.topicsFormControl.disable();
          this.guidanceFormControl.disable();
          this.standardCodesFormControl.disable();
          break;
      }
    }
  }

  private initializeMetricProperties(metric?: Metric): void {
    this.metric = metric;
    this.initMetricFormControls();
    this.initMetricFormControlsValue();
  }

  private initMetricFormControlsValue(): void {
    this.topicsFormControl.setValue(
      this.metric?.topics?.map((topic) => ({ id: topic.id, title: topic.name, item: topic })) ?? []
    );
    if (this.metric?.related_metrics) {
      const equivalent = this.metric.related_metrics.filter(
        (rm) =>
          rm.original_metric_ids.includes(String(this.metric?.id)) &&
          (this.canEditEveryMetrics || rm.source === RelatedMetricSource.platform)
      );
      const relatedMetrics = equivalent.map((e) => e.equivalent_metric);
      const relatedMetricsActionItems: ActionItem[] = ActionItemUtils.resourcesToActionItem(
        relatedMetrics,
        ResourceType.metrics_indicator
      );
      this.relatedMetricsFormControl.setValue(relatedMetricsActionItems);
    }
    this.tagsFormControl.setValue(this.metric?.tags?.map((tag) => ({ id: tag.id, title: tag.label, item: tag })) ?? []);
    this.standardCodesFormControl.setValue(
      this.metric?.standard_codes?.metric_level.map((stdCode: StandardCodes) => ({
        id: stdCode.id,
        title: stdCode.code,
        item: stdCode,
      }))
    );
  }
}
