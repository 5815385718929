<mat-menu #menu="matMenu" (close)="resetInput()">
  <ng-template matMenuContent>
    <a mat-menu-item class="menu-title" disabled>{{ optionProps?.title }}</a>
    <div class="menu-search" (click)="$event.stopPropagation()" *ngIf="isSearchable()">
      <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
      <input
        matInput
        type="text"
        placeholder="{{ 'Search' | translate }}"
        autocomplete="off"
        (keydown)="$event.stopPropagation()"
        [formControl]="searchInputControl"
      />
      <a class="clear-search" *ngIf="searchInputControl.value" (click)="resetInput()" data-testid="clearButton">
        <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
      </a>
    </div>

    <ng-container *ngIf="optionProps && optionProps.defaultValue">
      <hr />
      <a mat-menu-item (click)="optionSelected.emit(optionProps.defaultValue)">
        {{ getDefaultValueTitle() }}
      </a>
    </ng-container>

    <hr class="hr-light" />
    <div [ngClass]="{ 'menu-items-container': filteredOptions.length > minMenuScrollItems }">
      <ng-content></ng-content>
      <ng-container *ngIf="optionProps?.id !== standardCodeResourceType">
        <a
          mat-menu-item
          *ngFor="let option of filteredOptions"
          [title]="option.title"
          (click)="optionSelected.emit(option)"
          [ngClass]="{ active: optionProps?.activeOption?.id === option.id }"
          data-testid="filteredOption"
        >
          <span *ngIf="optionProps?.id !== topicResourceType">{{ option.title }}</span>
          <span
            *ngIf="optionProps?.id === topicResourceType"
            [ngClass]="{ 'pl-2': option.action === 'group', 'pl-4': option.action === 'topic' }"
            >{{ option.title }}</span
          >
        </a>
      </ng-container>

      <ng-container *ngIf="optionProps?.id === standardCodeResourceType">
        <div *ngFor="let standardCodeCategory of standardCodeFilterCategories">
          <hr class="hr-light" />
          <button mat-menu-item class="menu-title" disabled>
            {{ eStandardCodeCategoryTranslations[standardCodeCategory] | translate }}
          </button>
          <button
            mat-menu-item
            *ngFor="let option of filteredOptions | filter : filterStandardCodes : standardCodeCategory"
            [title]="option.title"
            (click)="optionSelected.emit(option)"
            [ngClass]="{ active: optionProps?.activeOption?.id === option.id }"
            data-testid="filteredOption"
          >
            <span>{{ option.title | uppercase }}</span>
          </button>
        </div>
      </ng-container>
    </div>
  </ng-template>
</mat-menu>

<lib-filter-button [buttonProps]="buttonProps" [optionProps]="optionProps" [matMenuTriggerFor]="menu">
</lib-filter-button>
