import { Icon } from '../icons/icons.model';
import { FeatureFlags, Permission, ViewerActionType } from '../models';
import { ThemePalette } from '@angular/material/core';

export interface ContextAction {
  id: string;
  title: string;
  icon?: Icon;
  disabled?: boolean;
  featureFlag?: keyof FeatureFlags;
  permissions?: Permission[];
  themePalette?: ThemePalette;
  viewerAction?: ViewerActionType;
}

export interface ContextSubTitle {
  title: string;
  themePalette: ThemePalette;
}
