import { DataRequestIndicatorLink, DataRequestSourceWithIndicatorLinks } from '../models';
import { BaseDataRequestSourceBuilder } from './data-request-source-builder';

export class DataRequestSourceWithIndicatorLinksBuilder<
  T extends DataRequestSourceWithIndicatorLinks
> extends BaseDataRequestSourceBuilder<T> {
  constructor(instance: T) {
    super({
      ...instance,
    });
    this._instance.indicator_links = [];
  }

  indicator_links(indicator_links: DataRequestIndicatorLink[]): DataRequestSourceWithIndicatorLinksBuilder<T> {
    this._instance.indicator_links = indicator_links;
    return this;
  }
}
