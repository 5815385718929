import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Doc, FileTypeDetailsV2, FileValue } from '../../../../../models';

export class FileAttachmentFormGroupV2 extends UntypedFormGroup {
  docId: string = '';
  docUrl?: UntypedFormControl;
  pageNumber?: UntypedFormControl;
  explanation?: UntypedFormControl;

  constructor(
    fileTypeDetails?: FileTypeDetailsV2,
    fileValue?: FileValue,
    fileDoc?: Doc,
    readonly fb: UntypedFormBuilder = new UntypedFormBuilder()
  ) {
    super(fb.group({}).controls);
    if (fileTypeDetails && fileValue) {
      this.docId = fileValue.file_id;
      this.addFileControls(fileTypeDetails, fileValue, fileDoc);
    }
  }

  private addFileControls(fileTypeDetails: FileTypeDetailsV2, fileValue: FileValue, fileDoc?: Doc): void {
    if (fileTypeDetails.display_url) {
      const isPublicUrl = fileDoc?.public && !!fileDoc.url;
      this.docUrl = new UntypedFormControl((isPublicUrl ? fileDoc.url : fileValue.url) ?? '', {
        validators: fileTypeDetails.url_required ? [Validators.required] : [],
      });
      if (isPublicUrl) {
        this.docUrl.disable();
      }
      this.addControl(`docUrl-${fileValue.file_id}`, this.docUrl);
    }
    if (fileTypeDetails.display_page_number) {
      this.pageNumber = new UntypedFormControl(fileValue.page_number ?? '', {
        validators: fileTypeDetails.page_number_required ? [Validators.required] : [],
      });
      this.addControl(`docPageNumber-${fileValue.file_id}`, this.pageNumber);
    }
    if (fileTypeDetails.display_explanation) {
      this.explanation = new UntypedFormControl(fileValue.explanation ?? '', {
        validators: fileTypeDetails.explanation_required ? [Validators.required] : [],
      });
      this.addControl(`docExplanation-${fileValue.file_id}`, this.explanation);
    }
  }

  toModel(): FileValue {
    return {
      file_id: this.docId,
      ...(this.docUrl && { url: this.docUrl.value }),
      ...(this.pageNumber && { page_number: this.pageNumber.value }),
      ...(this.explanation && { explanation: this.explanation.value }),
    };
  }
}
