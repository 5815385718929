<div *ngIf="control" class="formfield">
  <lib-form-field-label [label]="label" [control]="control" [id]="_labelId"></lib-form-field-label>
  <mat-selection-list
    #matSelectionList
    class="listbox-wrapper"
    [formControl]="control"
    [attr.aria-labelledby]="_labelId"
    [ngClass]="{ 'color-error': control.touched && control.invalid, required: required, disabled: control.disabled }"
  >
    <mat-list-option
      #selectAllOption
      *ngIf="withSelectAllOption"
      class="all-option"
      color="primary"
      checkboxPosition="before"
      [value]="selectAllItem.id"
      (click)="onSelectAll(matSelectionList)"
    >
      {{ selectAllItem.title }}
    </mat-list-option>
    <div class="scrollable-list">
      <mat-list-option
        color="primary"
        checkboxPosition="before"
        *ngFor="let option of options"
        [value]="option.id"
        [disabled]="option.disabled"
        (selectedChange)="onOptionSelected(matSelectionList)"
      >
        {{ option.title }}
      </mat-list-option>
    </div>
  </mat-selection-list>

  <mat-error *ngIf="control.hasError('required')" class="error-message">{{ "Required" | translate }}</mat-error>
</div>
