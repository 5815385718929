import { PlatformIntegrationType, IntegrationMetaData, IntegrationSetting } from '../models';
import { v4 as uuidv4 } from 'uuid';
import { Builder } from './builder';

export class IntegrationSettingBuilder extends Builder<IntegrationSetting> {
  constructor() {
    super({
      id: uuidv4(),
      available: true,
      enabled: true,
      type: PlatformIntegrationType.S_AND_P,
      meta: null,
    });
  }

  id(id: string): IntegrationSettingBuilder {
    this._instance.id = id;
    return this;
  }

  available(available: boolean): IntegrationSettingBuilder {
    this._instance.available = available;
    return this;
  }

  enabled(enabled: boolean): IntegrationSettingBuilder {
    this._instance.enabled = enabled;
    return this;
  }

  type(type: PlatformIntegrationType): IntegrationSettingBuilder {
    this._instance.type = type;
    return this;
  }

  meta(meta: IntegrationMetaData): IntegrationSettingBuilder {
    this._instance.meta = meta;
    return this;
  }
}
