import { PasswordConfirmationComponent } from './password-confirmation/password-confirmation.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../pipes';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../components';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

@NgModule({
  declarations: [PasswordConfirmationComponent],
  imports: [
    CommonModule,
    PipesModule,
    TranslateModule,
    MaterialModule,
    ReactiveFormsModule,
    ComponentsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [PasswordConfirmationComponent],
})
export class PasswordConfirmationModule {}
