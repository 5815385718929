import { Injectable } from '@angular/core';
import {
  BaseValue,
  BooleanValue,
  ChoiceValue,
  ConditionalTrigger,
  isValue,
  Value,
  ValueDefinitionType,
  ValueGroupSet,
  ValueGroupSetsTemplate,
  ValueTemplate,
} from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class ConditionalTriggerService {
  private readonly SUPPORTED_VALUE_DEFINITION_TYPE = [ValueDefinitionType.boolean, ValueDefinitionType.choice];

  isSupportedType(type: ValueDefinitionType): boolean {
    return this.SUPPORTED_VALUE_DEFINITION_TYPE.includes(type);
  }

  hasTriggerTriggeredForValueGroupSetTemplate(
    triggers: ConditionalTrigger[],
    valueGroupSet: ValueGroupSetsTemplate<BaseValue>
  ): boolean {
    const allValues = valueGroupSet.group
      .reduce((values: ValueTemplate<BaseValue>[], group) => [...values, ...group.value], [])
      .map((valueTemplate) => this.valueTemplateToBaseValue(valueTemplate));
    return this.hasTriggerTriggered(triggers, allValues);
  }

  hasTriggerTriggeredForValueGroupSet(triggers: ConditionalTrigger[], valueGroupSet: ValueGroupSet): boolean {
    const allValues =
      valueGroupSet.value_groups?.reduce((values: Value[], group) => [...values, ...(group.values ?? [])], []) ?? [];

    return this.hasTriggerTriggered(triggers, allValues);
  }

  private hasTriggerTriggered(triggers: ConditionalTrigger[], values: BaseValue[]): boolean {
    return triggers.some((t) =>
      this.isTriggerTriggered(
        t,
        values.find((v) => v.value_definition_id === t.source_value_definition_id)
      )
    );
  }

  getUntriggeredFilledValues<T extends BaseValue>(
    updatedValueTemplate: ValueTemplate<T>,
    valueGroupSet: ValueGroupSetsTemplate<T>
  ): ValueTemplate<T>[] {
    const updatedValue = this.valueTemplateToBaseValue(updatedValueTemplate);
    const untriggeredGroups = valueGroupSet.group.filter((group) =>
      this.hasUntriggeredTriggerForValue(group.conditional_triggers ?? [], updatedValue)
    );
    const filledValuesFromUntriggeredGroups = untriggeredGroups
      .reduce((values: ValueTemplate<T>[], group) => [...values, ...(group.value ?? [])], [])
      .filter((v) => v.value != null && v.value != '');

    const untriggeredFilledValues =
      valueGroupSet.group
        .find((group) => group.id === this.getValueGroupId(updatedValue))
        ?.value.filter((v) => this.hasUntriggeredTriggerForValue(v.conditional_triggers ?? [], updatedValue))
        .filter((v) => v.value != null && v.value != '') ?? [];

    return [...filledValuesFromUntriggeredGroups, ...untriggeredFilledValues];
  }

  private isTriggerTriggered(trigger: ConditionalTrigger, value?: BaseValue<any, BooleanValue | ChoiceValue>): boolean {
    if (value) {
      const sourceValueValue =
        value.type === ValueDefinitionType.boolean
          ? ((value as BaseValue<any, BooleanValue>).value?.value as boolean)
          : ((value as BaseValue<any, ChoiceValue>).value?.values as string[]);

      return Array.isArray(sourceValueValue)
        ? (trigger.values as string[]).every((value) => sourceValueValue.includes(value))
        : (trigger.values as boolean[]).includes(sourceValueValue);
    }
    return false;
  }

  private hasUntriggeredTriggerForValue(triggers: ConditionalTrigger[], value: BaseValue): boolean {
    return triggers
      .filter((t) => t.source_value_definition_id === value.value_definition_id)
      .some((t) => !this.isTriggerTriggered(t, value));
  }

  private valueTemplateToBaseValue(valueTemplate: ValueTemplate<BaseValue>): BaseValue {
    return { ...valueTemplate.dataFormats.data, value: valueTemplate.value };
  }

  private getValueGroupId(value: BaseValue): string | undefined {
    if (isValue(value)) {
      return value.value_group_id;
    }

    return undefined;
  }
}
