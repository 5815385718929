import {
  DataRequestNote,
  DataRequestSourceStatus,
  DataRequestTransition,
  DataRequestUserResponsibility,
  DataRequestValueGroupSetStatus,
} from '../models';

export class DataRequestTransitionBuilder {
  private readonly _dataRequestTransition: DataRequestTransition;

  constructor() {
    this._dataRequestTransition = {
      id: 'id',
      data_request_note_id: 'noteId',
      value_group_set_id: 'vgsId',
      from_status: DataRequestValueGroupSetStatus.COMPLETED,
      to_status: DataRequestValueGroupSetStatus.REJECTED,
      created: new Date(),
      responsibility: DataRequestUserResponsibility.CONTRIBUTOR,
    };
  }

  id(id: string): DataRequestTransitionBuilder {
    this._dataRequestTransition.id = id;
    return this;
  }

  data_request_note_id(dataRequestNoteId: string): DataRequestTransitionBuilder {
    this._dataRequestTransition.data_request_note_id = dataRequestNoteId;
    return this;
  }

  note(dataRequestNote: DataRequestNote): DataRequestTransitionBuilder {
    this._dataRequestTransition.note = dataRequestNote;
    return this;
  }

  value_group_set_id(valueGroupSetId: string): DataRequestTransitionBuilder {
    this._dataRequestTransition.value_group_set_id = valueGroupSetId;
    return this;
  }

  from_status(fromStatus: DataRequestValueGroupSetStatus | DataRequestSourceStatus): DataRequestTransitionBuilder {
    this._dataRequestTransition.from_status = fromStatus;
    return this;
  }

  to_status(toStatus: DataRequestValueGroupSetStatus | DataRequestSourceStatus): DataRequestTransitionBuilder {
    this._dataRequestTransition.to_status = toStatus;
    return this;
  }

  created(created: Date): DataRequestTransitionBuilder {
    this._dataRequestTransition.created = created;
    return this;
  }

  responsibility(responsibility: DataRequestUserResponsibility): DataRequestTransitionBuilder {
    this._dataRequestTransition.responsibility = responsibility;
    return this;
  }

  build(): DataRequestTransition {
    return this._dataRequestTransition;
  }
}
