import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '../../services/common';

@Component({
  selector: 'lib-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent implements OnInit {
  public title: string = '';
  public body: string = '';
  public error: string = '';
  public button: string = '';
  public modal: boolean = false;

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.title = this.data.title || this.translateService.instant('Information!');
    this.body = this.data.body;
    this.error = this.data.error;
    this.button = this.data.button || this.translateService.instant('OK');
    this.modal = this.data.modal || false;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public confirm(): void {
    this.dialogRef.close({ confirmed: true });
  }
}
