import { Component, Input, OnInit } from '@angular/core';
import { Required } from '../../../decorators';

@Component({
  selector: 'lib-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss'],
})
export class StatusChipComponent implements OnInit {
  @Input() @Required() status!: string;
  @Input() type?: string;

  cssClassName: string = '';

  ngOnInit(): void {
    this.cssClassName = this.status;

    if (this.type) {
      this.cssClassName = `${this.status}-${this.type}`;
    }
  }
}
