import { MetricGroupMetric, MetricGroupMetricWithMinimalIndicator, MinimalIndicator } from '../models';
import { MinimalIndicatorBuilder } from './minimal-indicator-builder';

export class MetricGroupMetricWithMinimalIndicatorBuilder {
  private readonly _metricGroupMetric: MetricGroupMetricWithMinimalIndicator;

  constructor() {
    this._metricGroupMetric = {
      id: 'MGM01',
      position: 1,
      metric_id: 'M01',
      indicator_id: 'I01',
      metric_group_id: 'MG01',
      indicator: new MinimalIndicatorBuilder().build(),
    };
  }

  id(id: string): MetricGroupMetricWithMinimalIndicatorBuilder {
    this._metricGroupMetric.id = id;
    return this;
  }

  position(position: number): MetricGroupMetricWithMinimalIndicatorBuilder {
    this._metricGroupMetric.position = position;
    return this;
  }

  metricGroupId(id: string): MetricGroupMetricWithMinimalIndicatorBuilder {
    this._metricGroupMetric.metric_group_id = id;
    return this;
  }

  metricId(id: string): MetricGroupMetricWithMinimalIndicatorBuilder {
    this._metricGroupMetric.metric_id = id;
    return this;
  }

  indicatorId(id: string): MetricGroupMetricWithMinimalIndicatorBuilder {
    this._metricGroupMetric.indicator_id = id;
    return this;
  }

  indicator(indicator: MinimalIndicator): MetricGroupMetricWithMinimalIndicatorBuilder {
    this._metricGroupMetric.indicator = indicator;
    return this;
  }

  build(): MetricGroupMetric {
    return this._metricGroupMetric;
  }
}
