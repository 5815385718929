import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContextAction, ContextSubTitle } from '../models';
import { ActionItem } from '../../models';

@Component({
  selector: 'lib-page-context-bar',
  templateUrl: './page-context-bar.component.html',
  styleUrls: ['./page-context-bar.component.scss'],
})
export class PageContextBarComponent {
  @Input() pageName: string = '';
  @Input() additionalActions: ContextAction[] = [];
  @Input() subtitles: ContextSubTitle[] = [];
  @Input() tabs: ActionItem[] = [];
  @Input() withSeparator: boolean = false;

  @Output() action = new EventEmitter<string>();
}
