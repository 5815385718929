import { ActionItem, FilterBarOption, FilterType } from '../models';

export class FilterBarOptionBuilder {
  private readonly _filterBarOption: FilterBarOption;

  constructor() {
    this._filterBarOption = {
      icon: 'watch',
      id: '1',
      options: [
        {
          id: '1',
          title: 'first option',
        },
      ],
      optionType: FilterType.list,
      displayAll: true,
      title: 'primary',
    };
  }

  id(id: string): FilterBarOptionBuilder {
    this._filterBarOption.id = id;
    return this;
  }

  title(title: string): FilterBarOptionBuilder {
    this._filterBarOption.title = title;
    return this;
  }

  options(options: ActionItem<any>[]): FilterBarOptionBuilder {
    this._filterBarOption.options = options;
    return this;
  }

  optionType(optionType: FilterType): FilterBarOptionBuilder {
    this._filterBarOption.optionType = optionType;
    return this;
  }

  displayAll(displayAll: boolean): FilterBarOptionBuilder {
    this._filterBarOption.displayAll = displayAll;
    return this;
  }

  build(): FilterBarOption {
    return this._filterBarOption;
  }
}
