<button mat-menu-item class="menu-title" disabled>{{ menuTitle }}</button>
<ng-container *ngIf="initialItems">
  <div
    class="menu-search"
    (click)="$event.stopPropagation()"
    *ngIf="control && initialItems.length > minMenuScrollItems"
  >
    <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
    <input
      matInput
      type="text"
      [placeholder]="'Search' | translate"
      autocomplete="off"
      (keydown)="$event.stopPropagation()"
      [formControl]="control"
      data-testid="search-input"
    />
    <button class="clear-search" *ngIf="control.value" (click)="control.setValue('')">
      <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
    </button>
  </div>

  <ng-container *ngIf="allOption">
    <hr />
    <button mat-menu-item (click)="setItem.emit()">{{ "All" | translate }}</button>
  </ng-container>

  <ng-container *ngIf="filteredItems as items">
    <div [ngClass]="{ 'menu-items-container': items.length > minMenuScrollItems }">
      <ng-container *ngIf="categories?.length; else simpleView">
        <div *ngFor="let categorie of categories">
          <hr class="hr-light" />
          <button mat-menu-item class="menu-title" disabled>
            {{ categorie | translate }}
          </button>
          <button
            mat-menu-item
            *ngFor="let item of items | filter : filterItemsFunction : categorie"
            [title]="item.title"
            (click)="setItem.emit(item)"
            [ngClass]="{ active: selectedItemId === item.id }"
            [attr.aria-label]="item.id"
          >
            {{ item.title }}
          </button>
        </div>
      </ng-container>
      <ng-template #simpleView>
        <div>
          <hr class="hr-light" />
          <button
            mat-menu-item
            *ngFor="let item of items"
            [title]="item.title"
            (click)="setItem.emit(item)"
            [ngClass]="{ active: selectedItemId === item.id }"
            [attr.aria-label]="item.id"
          >
            {{ item.title }}
          </button>
        </div>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
