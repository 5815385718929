<div class="single-data-point-widget-card" *ngIf="widget">
  <div class="single-data-point-widget-card-content">
    <div class="top-content">
      <div class="single-data-point-widget-card-title">
        <span>{{ widget.metric.code }}</span>
        <button
          *ngIf="showEditMenu"
          lib-button
          class="action-button"
          [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{ item: widget }"
          data-testid="widgetContextMenu"
        >
          <mat-icon svgIcon="more"></mat-icon>
        </button>
      </div>
      <div libEllipsifyMe [maxCharCount]="35" class="single-data-point-widget-card-subtitle">
        <span>{{ widget.label }}</span>
      </div>
    </div>

    <div class="value-grid">
      <div class="value-wrapper" *ngIf="dataPoint?.value?.value; else noData">
        <span class="single-data-point-widget-card-value">
          {{ dataPoint?.value?.value | numberMask : dataPoint?.value_definition?.type_details?.max_decimals }}
        </span>
        <span
          class="single-data-point-widget-card-unit"
          *ngIf="dataPoint?.value_definition?.type_details?.units !== 'default'"
          >{{ dataPoint?.value_definition?.type_details?.units | unitSymbol }}</span
        >

        <div class="previous-value-indicator" *ngIf="variation !== 0">
          <mat-icon data-testid="variationIcon" [svgIcon]="variation > 0 ? 'trend-up' : 'trend-down'"></mat-icon>
          <span>{{ variation }} %</span>
        </div>
      </div>

      <div *ngIf="dataPoint?.show_previous_year" class="single-data-point-widget-card-prev-year">
        <span>{{ "Prev. year: " | translate }}</span>
        <span *ngIf="dataPoint?.previous_year_value?.value; else noPreviousYearData">
          {{ dataPoint?.previous_year_value?.value }}
        </span>

        <ng-template #noPreviousYearData>
          <span>{{ "No data" | translate }}</span>
        </ng-template>
      </div>

      <div class="footer-information">
        <span>{{ "Source:" | translate }} {{ sourceName }}</span>
        <span>{{ "FY:" | translate }} {{ year }}</span>
      </div>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="removeItem(item)">
      <mat-icon svgIcon="trash"></mat-icon>
      <span>{{ "Remove" | translate }}</span>
    </button>
    <button mat-menu-item (click)="editItem(item)">
      <mat-icon svgIcon="edit"></mat-icon>
      <span>{{ "Edit" | translate }}</span>
    </button>
  </ng-template>
</mat-menu>

<ng-template #noData>
  <span class="single-data-point-widget-card-value">{{ "No data" | translate }}</span>
</ng-template>
