import { SamlProvider } from '../models';

export class SamlProviderBuilder {
  private readonly _samlProvider: SamlProvider;

  constructor() {
    this._samlProvider = {
      id: '123',
      label: 'label',
      type: 'okta',
    };
  }

  build(): SamlProvider {
    return this._samlProvider;
  }
}
