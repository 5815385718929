import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MetricStructureComponent } from './metric-structure.component';
import { MetricStructureFormPanelComponent } from './components/metric-structure-form-panel/metric-structure-form-panel.component';
import { MetricStructureTableComponent } from './components/metric-structure-form-panel/metric-structure-table/metric-structure-table.component';
import { AddVariableDialogComponent } from './components/add-variable-dialog/add-variable-dialog.component';
import { ConditionalTriggerCardComponent } from './components/metric-structure-panel-conditional-trigger/conditional-trigger-card/conditional-trigger-card.component';
import { ConditionalTriggerAddEditDialogComponent } from './components/conditional-trigger-add-edit-dialog/conditional-trigger-add-edit-dialog.component';
import { CustomSelectionListDialogComponent } from './components/custom-selection-list-dialog/custom-selection-list-dialog.component';
import { LinkDocumentDialogComponent } from './components/link-document-dialog/link-document-dialog.component';
import { MetricStructureChoiceFieldComponent } from './components/metric-structure-form-panel/metric-structure-choice-field/metric-structure-choice-field.component';
import { MetricStructureDocumentFieldComponent } from './components/metric-structure-document-field/metric-structure-document-field.component';
import { MetricStructureFieldsSelectionComponent } from './components/metric-structure-fields-selection/metric-structure-fields-selection.component';
import { MetricStructurePanelConditionalTriggerComponent } from './components/metric-structure-panel-conditional-trigger/metric-structure-panel-conditional-trigger.component';
import { MetricStructurePropertiesPanelComponent } from './components/metric-structure-properties-panel/metric-structure-properties-panel.component';
import { MetricStructureLabelComponent } from './components/metric-structure-label/metric-structure-label.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { MetricStructureFieldFormComponent } from './components/metric-structure-form-panel/metric-structure-field-form/metric-structure-field-form.component';
import { MetricStructureGroupFormComponent } from './components/metric-structure-form-panel/metric-structure-group-form/metric-structure-group-form.component';
import { MetricStructureSettingsComponent } from './components/metric-structure-properties-panel/metric-structure-settings/metric-structure-settings.component';
import { MetricStructureTablePropertiesComponent } from './components/metric-structure-properties-panel/metric-structure-table-properties/metric-structure-table-properties.component';
import { MetricStructureGroupPropertiesComponent } from './components/metric-structure-properties-panel/metric-structure-group-properties/metric-structure-group-properties.component';
import { MetricStructureFieldPropertiesComponent } from './components/metric-structure-properties-panel/metric-structure-field-properties/metric-structure-field-properties.component';
import { MetricSettingsDetailsComponent } from './components/metric-structure-properties-panel/metric-structure-settings/metric-settings-details/metric-settings-details.component';
import { TableContextColumnDialogComponent } from './components/metric-structure-properties-panel/metric-structure-table-properties/table-context-column-dialog/table-context-column-dialog.component';
import { TableInputColumnDialogComponent } from './components/metric-structure-properties-panel/metric-structure-table-properties/table-input-column-dialog/table-input-column-dialog.component';
import { TableColumnDefinitionCardComponent } from './components/metric-structure-properties-panel/metric-structure-table-properties/table-column-definition-card/table-column-definition-card.component';
import { ComponentsModule } from '../components';
import { OptionsListEditTableComponent } from './components/options-list-edit-table/options-list-edit-table.component';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { MetricStructureDocumentFieldPropertiesComponent } from './components/metric-structure-document-field-properties/metric-structure-document-field-properties.component';
import { PipesModule } from '../pipes';
import { DirectivesModule } from '../directives';
import { CdkModule } from '../material/cdk.module';
import { IsSupportedTypeAsVariablePipe } from './components/add-variable-dialog/is-supported-type-as-variable.pipe';
import { DeactivatableMetricStructureEditorDirective } from './components/deactivatable-metric-structure-editor/deactivatable-metric-structure-editor.directive';
import { MetricStructureCalculatedVariablesComponent } from './components/metric-structure-properties-panel/metric-structure-calculated-variables/metric-structure-calculated-variables.component';
import { IsValueDefinitionOfTypePipe } from './pipe/is-value-definition-of-type.pipe';
import { MetricStructureChoiceFieldSelectionComponent } from './components/metric-structure-properties-panel/metric-structure-field-properties/metric-structure-choice-field-properties/metric-structure-choice-field-selection/metric-structure-choice-field-selection.component';
import { MetricStructureChoiceFieldPropertiesComponent } from './components/metric-structure-properties-panel/metric-structure-field-properties/metric-structure-choice-field-properties/metric-structure-choice-field-properties.component';
import { SearchModule } from '../search';
import { DialogsModule } from '../dialogs';
import { PanelsModule } from '../panels';
import { MetricPreviewModule } from '../metric-preview';
import { MetricTableTotalDialogComponent } from './components/metric-structure-properties-panel/metric-structure-table-properties/metric-table-total-dialog/metric-table-total-dialog.component';
import { MetricTableTotalCardComponent } from './components/metric-structure-properties-panel/metric-structure-table-properties/metric-table-total-card/metric-table-total-card.component';
import { MetricTableTotalColumnsComponent } from './components/metric-structure-properties-panel/metric-structure-table-properties/metric-table-total-dialog/metric-table-total-columns/metric-table-total-columns.component';
import { ContextColumnDefinitionPipe } from './components/metric-structure-properties-panel/metric-structure-table-properties/metric-table-total-dialog/pipes/context-column-definition/context-column-definition.pipe';
import { FilterUncalculableGroupsPipe } from './components/add-variable-dialog/filter-uncalculable-groups.pipe';
import { MetricStructureRepeatableGroupPropertiesTabComponent } from './components/metric-structure-properties-panel/metric-structure-group-properties/metric-structure-repeatable-group-properties-tab/metric-structure-repeatable-group-properties-tab.component';
import { FeatureFlagModule } from '../feature-flag';
import { MetricTableTotalTabComponent } from './components/metric-structure-properties-panel/metric-structure-table-properties/metric-table-total-tab/metric-table-total-tab.component';
import { DocumentTitlePipe } from './components/metric-structure-document-field/pipes/document-title.pipe';
import { IsTablePipe } from './pipe/is-table/is-table.pipe';
import { MetricStructureOpenRequestsComponent } from './components/metric-structure-form-panel/metric-structure-open-requests/metric-structure-open-requests.component';
import { IsDeactivatedPipe } from './pipe/is-deactivated/is-deactivated.pipe';
import { IsValueDefinitionDragDisabledPipe } from './pipe/is-value-definition-drag-disabled/is-value-definition-drag-disabled.pipe';
import { MetricStructureGroupFormDetailsComponent } from './components/metric-structure-form-panel/metric-structure-group-form-details/metric-structure-group-form-details.component';
import { IsEntityFromCoreRefV2MetricPipe } from './pipe/is-entity-from-core-ref-v2-metric/is-entity-from-core-ref-v2-metric.pipe';
import { CanHaveConditionalTriggerPipe } from './pipe/can-have-conditional-trigger/can-have-conditional-trigger.pipe';
import { MetricStructureFieldInformationComponent } from './components/metric-structure-properties-panel/metric-structure-field-information/metric-structure-field-information.component';
import { DisableContextSwitchPipe } from './components/metric-structure-properties-panel/metric-structure-group-properties/metric-structure-repeatable-group-properties-tab/disable-context-switch-pipe/disable-context-switch.pipe';
import { ConsolidationManualDialogComponent } from './components/consolidation-manual-dialog/consolidation-manual-dialog.component';
import { MetricStructureTableDetailsComponent } from './components/metric-structure-form-panel/metric-structure-table/metric-structure-table-details/metric-structure-table-details.component';

@NgModule({
  declarations: [
    AddVariableDialogComponent,
    ConditionalTriggerAddEditDialogComponent,
    ConditionalTriggerCardComponent,
    ConsolidationManualDialogComponent,
    CustomSelectionListDialogComponent,
    LinkDocumentDialogComponent,
    MetricStructureChoiceFieldComponent,
    MetricStructureComponent,
    MetricStructureDocumentFieldComponent,
    MetricStructureFieldFormComponent,
    MetricStructureFieldPropertiesComponent,
    MetricStructureDocumentFieldPropertiesComponent,
    MetricStructureFieldsSelectionComponent,
    MetricStructureFormPanelComponent,
    MetricStructureGroupFormComponent,
    MetricStructureGroupPropertiesComponent,
    MetricStructureLabelComponent,
    MetricStructurePanelConditionalTriggerComponent,
    MetricStructurePropertiesPanelComponent,
    MetricStructureSettingsComponent,
    MetricSettingsDetailsComponent,
    MetricStructureTableComponent,
    MetricStructureTableComponent,
    MetricStructureTablePropertiesComponent,
    MetricStructureTableDetailsComponent,
    TableContextColumnDialogComponent,
    TableInputColumnDialogComponent,
    TableColumnDefinitionCardComponent,
    OptionsListEditTableComponent,
    IsSupportedTypeAsVariablePipe,
    DeactivatableMetricStructureEditorDirective,
    MetricStructureCalculatedVariablesComponent,
    IsValueDefinitionOfTypePipe,
    MetricStructureChoiceFieldSelectionComponent,
    MetricStructureChoiceFieldPropertiesComponent,
    MetricStructureOpenRequestsComponent,
    MetricTableTotalDialogComponent,
    MetricTableTotalColumnsComponent,
    MetricTableTotalCardComponent,
    ContextColumnDefinitionPipe,
    FilterUncalculableGroupsPipe,
    MetricStructureRepeatableGroupPropertiesTabComponent,
    MetricTableTotalTabComponent,
    DocumentTitlePipe,
    IsTablePipe,
    IsDeactivatedPipe,
    IsValueDefinitionDragDisabledPipe,
    MetricStructureGroupFormDetailsComponent,
    IsEntityFromCoreRefV2MetricPipe,
    CanHaveConditionalTriggerPipe,
    MetricStructureFieldInformationComponent,
    DisableContextSwitchPipe,
  ],
  exports: [MetricStructureComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CdkModule,
    MaterialModule,
    ComponentsModule,
    PipesModule,
    DirectivesModule,
    // SyncFusion
    RichTextEditorModule,
    GridModule,
    TranslateModule,
    SearchModule,
    DialogsModule,
    PanelsModule,
    MetricPreviewModule,
    FeatureFlagModule,
    FeatureFlagModule,
  ],
})
export class MetricStructureModule {}
