import { Injectable } from '@angular/core';
import {
  ActionItem,
  ConsolidationRuleOptions,
  ConsolidationRules,
  ConsolidationTriggers,
  ValueDefinitionType,
  BaseValue,
  isDataRequestValue,
  ValueDefinitionGroup,
} from '../../../models';
import { TranslateService } from '../../../services/common/translate/translate.service';
import { ClientConfigService } from '../../../services/client/client-config/client-config.service';

@Injectable({
  providedIn: 'root',
})
export class ConsolidationService {
  public staticConsolidationFlag: boolean = false;

  public readonly consolidationRuleOptions: ActionItem[] = ConsolidationRuleOptions.map((rule) => ({
    id: `${rule.id}`,
    title: this.translateService.instant(rule.title),
  }));
  public consolidationTriggerOptions: ActionItem[] = [];

  constructor(
    private readonly translateService: TranslateService,
    private readonly clientConfigService: ClientConfigService
  ) {
    this.staticConsolidationFlag = this.clientConfigService.areAnyFeatureFlagsEnabled(['static_consolidation']);
  }

  get consolidationValueDefinitionTypes(): ValueDefinitionType[] {
    return [ValueDefinitionType.integer, ValueDefinitionType.decimal];
  }

  public setConsolidationTriggerOptions(consolidationRule: ConsolidationRules): void {
    const consolidationTriggerSumOptions = [
      {
        id: ConsolidationTriggers.update_when_one_value,
        title: this.translateService.instant('Sum as values are added to sources (default)'),
      },
      {
        id: ConsolidationTriggers.update_when_all_values,
        title: this.translateService.instant('Sum only when all sources have values'),
      },
    ];

    const consolidationTriggerAverageOptions = [
      {
        id: ConsolidationTriggers.update_when_one_value,
        title: this.translateService.instant('Average as values are added to sources (default)'),
      },
      {
        id: ConsolidationTriggers.update_when_all_values,
        title: this.translateService.instant('Average only when all sources have values'),
      },
    ];

    switch (consolidationRule) {
      case 'sum':
        this.consolidationTriggerOptions = consolidationTriggerSumOptions;
        break;
      case 'average':
        this.consolidationTriggerOptions = consolidationTriggerAverageOptions;
        break;
      default:
        this.consolidationTriggerOptions = [];
        break;
    }
  }

  public getConsolidatedDataRequestInfo(rule?: ConsolidationRules, trigger?: ConsolidationTriggers): string {
    const consolidationTooltips = {
      [ConsolidationRules.average]: {
        [ConsolidationTriggers.update_when_one_value]: this.translateService.instant(
          'The consolidated average is calculated based on the sources having values. This value will not be updated until the request is imported.'
        ),
        [ConsolidationTriggers.update_when_all_values]: this.translateService.instant(
          'The consolidated average is calculated only when all sources have values. This value will not be updated until the request is imported.'
        ),
      },
      [ConsolidationRules.sum]: {
        [ConsolidationTriggers.update_when_one_value]: this.translateService.instant(
          'The consolidated sum is calculated as values are added to sources. This value will not be updated until the request is imported.'
        ),
        [ConsolidationTriggers.update_when_all_values]: this.translateService.instant(
          'The consolidated sum is calculated only when all sources have values. This value will not be updated until the request is imported.'
        ),
      },
      [ConsolidationRules.manual]: {
        [ConsolidationTriggers.update_when_one_value]: '',
        [ConsolidationTriggers.update_when_all_values]: '',
      },
    };

    if (!rule || !trigger) {
      return '';
    }
    return consolidationTooltips[rule][trigger];
  }

  public getConsolidatedInfo(rule?: ConsolidationRules, trigger?: ConsolidationTriggers): string {
    const consolidationTooltips = {
      [ConsolidationRules.average]: {
        [ConsolidationTriggers.update_when_one_value]: this.translateService.instant(
          'The consolidated average is calculated based on the sources having values.'
        ),
        [ConsolidationTriggers.update_when_all_values]: this.translateService.instant(
          'The consolidated average is calculated only when all sources have values.'
        ),
      },
      [ConsolidationRules.sum]: {
        [ConsolidationTriggers.update_when_one_value]: this.translateService.instant(
          'The consolidated sum is calculated as values are added to sources.'
        ),
        [ConsolidationTriggers.update_when_all_values]: this.translateService.instant(
          'The consolidated sum is calculated only when all sources have values.'
        ),
      },
      [ConsolidationRules.manual]: {
        [ConsolidationTriggers.update_when_one_value]: '',
        [ConsolidationTriggers.update_when_all_values]: '',
      },
    };

    if (!rule || !trigger) {
      return '';
    }
    return consolidationTooltips[rule][trigger];
  }

  public getConsolidatedInfoBasedOnValueType(value?: BaseValue): string {
    if (!value) {
      return '';
    }

    if (![ValueDefinitionType.integer, ValueDefinitionType.decimal].includes(value.type)) {
      return '';
    }

    if (isDataRequestValue(value)) {
      return this.getConsolidatedDataRequestInfo(value.consolidation_rule, value.consolidation_trigger);
    }
    return this.getConsolidatedInfo(value.consolidation_rule, value.consolidation_trigger);
  }

  public areAnyConsolidationRulesInGroup(
    valueDefinitionGroup: ValueDefinitionGroup,
    consolidationRule: ConsolidationRules
  ): boolean {
    if (this.staticConsolidationFlag) {
      return !!valueDefinitionGroup.value_definitions?.some((vd) => vd.consolidation_rule !== consolidationRule);
    }
    return false;
  }

  public static isQuantitativeRule(rule?: ConsolidationRules): boolean {
    const consolidation_rules_fields: Array<ConsolidationRules | undefined> = [
      ConsolidationRules.sum,
      ConsolidationRules.average,
    ];

    return consolidation_rules_fields.includes(rule);
  }
}
