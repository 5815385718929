import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Output } from '@angular/core';
import { distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[libVerticalResizable]',
})
export class VerticalResizableDirective {
  @Output('libVerticalResizable')
  readonly resizable = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mousedown').pipe(
    tap((e) => e.preventDefault()),
    switchMap(() => {
      const { height, bottom } = this.elementRef.nativeElement.closest('td')?.getBoundingClientRect() as any;

      return fromEvent<MouseEvent>(this.documentRef, 'mousemove').pipe(
        map(({ clientY }) => height + clientY - bottom),
        distinctUntilChanged(),
        takeUntil(fromEvent(this.documentRef, 'mouseup'))
      );
    })
  );

  constructor(
    @Inject(DOCUMENT) private readonly documentRef: Document,
    @Inject(ElementRef) private readonly elementRef: ElementRef<HTMLElement>
  ) {}
}
