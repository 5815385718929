import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, Observable, of, switchMap, take } from 'rxjs';
import { ApiResponse, UserEmailCheck } from '../models';
import { ClientUsersService } from '../services/client';
import { AdminUsersService } from '../services/admin';
export class UserValidators {
  static isEmailUnique(usersService: ClientUsersService | AdminUsersService, initialValue?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.pristine || initialValue == control.value || !UserValidators.hasValidEmail(control)) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          take(1),
          switchMap(() => usersService.userEmailCheck(control.value as string)),
          map(
            (response: ApiResponse<UserEmailCheck>): ValidationErrors | null =>
              response.data.available ? null : { isUnique: true },
            () => ({ isUnique: true })
          )
        );
      }
    };
  }

  private static hasValidEmail(control: AbstractControl): boolean {
    return !control.hasError('pattern');
  }
}
