/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import {
  ActionItem,
  AdminUserRoles,
  ApiResponse,
  CDPSector,
  Entity,
  FiscalYear,
  Framework,
  Indicator,
  Industry,
  Iso3166Country,
  Metric,
  MetricGroup,
  Org,
  ReportTemplate,
  ResourceType,
  SelectionSet,
  SelectionSetItem,
  Source,
  StandardCodes,
  Tag,
  Taxonomy,
  TopicCategory,
  Unit,
  User,
  WDIIndustry,
} from '../../models';
import { AdminUserRoleLabel } from '../../translations';

export class ActionItemUtils {
  /**
   * Converts a Resource to an ActionItem<Resource>.
   *
   * **Notes:**
   * - It only handles simple items which can be converted statically.
   * - As of now, **metrics_indicator** and **role** are not supported due to more complex conversion needs.
   */
  static resourceToActionItem<T extends Entity | unknown>(
    item: T,
    itemType: ResourceType
  ): ActionItem<T> | ActionItem<T>[] {
    const currentYear: number = new Date().getFullYear();

    switch (itemType) {
      case ResourceType.year:
      case ResourceType.year_public:
        const yearItem = item as FiscalYear;
        return {
          id: String(yearItem.year),
          title: String(yearItem.year),
          action: +yearItem.year === currentYear ? 'current' : undefined,
          item,
        };
      case ResourceType.year_with_id:
        const fiscalYear = item as FiscalYear;
        return {
          id: fiscalYear.id,
          title: String(fiscalYear.year),
          action: +fiscalYear.year === currentYear ? 'current' : undefined,
          item,
        };
      case ResourceType.category:
      case ResourceType.type:
      case ResourceType.status:
      case ResourceType.sort:
        const actionItem = item as ActionItem<void>;
        return { id: actionItem.id, title: actionItem.title };
      case ResourceType.standard_codes:
        const standardCode = item as StandardCodes;
        return {
          id: standardCode.id,
          title: standardCode.name,
          action: 'category',
          item,
        };
      case ResourceType.source:
        const source = item as Source;
        return { id: source.id, title: source.name, action: 'category', item };
      case ResourceType.framework:
      case ResourceType.questionnaire_framework:
        const framework = item as Framework;
        return { id: framework.id, title: framework.name, action: 'category', item };
      case ResourceType.industry:
        const industry = item as Industry;
        return { id: industry.id, title: industry.name, action: 'category', item };
      case ResourceType.unit:
        const unit = item as Unit;
        return { id: unit.id, title: unit.label, action: 'category', item };
      case ResourceType.org:
      case ResourceType.cdp_sector:
      case ResourceType.wdi_industry:
        const org = item as Org | CDPSector | WDIIndustry;
        return { id: org.id, title: org.name, action: 'category', item };
      case ResourceType.template:
        const reportTemplate = item as ReportTemplate;
        return {
          id: reportTemplate.id,
          title: reportTemplate.name,
          action: 'category',
          item,
        };
      case ResourceType.topic:
        const topicItems: ActionItem[] = [];
        const topic = item as TopicCategory;
        topicItems.push({ id: topic.id, title: topic.name, action: 'category', item: topic });
        for (const topic_group of topic.topic_groups) {
          topicItems.push({ id: topic_group.id, title: topic_group.name, action: 'group', item: topic_group });
          for (const topic of topic_group.topics) {
            topicItems.push({ id: topic.id, title: topic.name, action: 'topic', item: topic });
          }
        }
        return topicItems;
      case ResourceType.tag:
        const tag = item as Tag;
        return { id: tag.id, title: tag.name, item };
      case ResourceType.selection_set:
        const selectionSet = item as SelectionSet;
        return {
          id: selectionSet.id,
          title: selectionSet.name,
          item,
          deactivated: !selectionSet.active,
        };
      case ResourceType.selection_set_item:
      case ResourceType.selection_set_item_public:
        const selectionSetItem = item as SelectionSetItem;
        return { id: selectionSetItem.id, title: selectionSetItem.name, item };
      case ResourceType.user:
        const user = item as User;
        return { id: user.id, title: `${user.first_name} ${user.last_name}`, item };
      case ResourceType.min_metrics:
        const minMetrics = item as Indicator;
        return { id: minMetrics.id, title: minMetrics.description, item };
      case ResourceType.metrics_indicator:
        const metric = item as Metric;
        return { id: metric.id, title: String(metric.code), item };
      case ResourceType.metric_group:
        const metricGroup = item as MetricGroup;
        return {
          id: metricGroup.id,
          title: metricGroup.name,
          item,
        };
      case ResourceType.role:
        const role = item as AdminUserRoles;
        return {
          id: role,
          title: AdminUserRoleLabel[role],
          item,
        };
      case ResourceType.taxonomy:
        const taxonomy = item as Taxonomy;
        return {
          id: taxonomy.id,
          title: taxonomy.label,
          item,
        };
      case ResourceType.country:
        const country = item as Iso3166Country;
        return {
          id: country.iso,
          title: country.name,
          item,
        };
      default:
        throw new Error(`Item type not supported: ${itemType}`);
    }
  }

  static responseToActionItem<T extends Entity | unknown>(
    response: ApiResponse<T[]>,
    itemType: ResourceType
  ): ActionItem<T>[] {
    return this.resourcesToActionItem(response.data, itemType);
  }

  static resourcesToActionItem<T extends Entity | unknown>(items: T[], itemType: ResourceType): ActionItem<T>[] {
    const actionItems: ActionItem<T>[] = [];
    for (const item of items) {
      const result: ActionItem<T> | ActionItem<T>[] = this.resourceToActionItem(item, itemType);
      if (result instanceof Array) {
        actionItems.push(...result);
      } else {
        actionItems.push(result);
      }
    }

    return actionItems;
  }
}
