import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ValueDefinition, DialogResult, Status } from '../../../models';

export interface ConsolidationManualDialogResults {
  reset_consolidated_values: boolean;
}

@Component({
  selector: 'lib-consolidation-manual-dialog',
  templateUrl: './consolidation-manual-dialog.component.html',
  styleUrls: ['./consolidation-manual-dialog.component.scss'],
})
export class ConsolidationManualDialogComponent {
  reset_consolidated_values: boolean = true;

  constructor(
    private readonly dialogRef: MatDialogRef<
      ConsolidationManualDialogComponent,
      DialogResult<ConsolidationManualDialogResults>
    >,
    @Inject(MAT_DIALOG_DATA) public data: ValueDefinition
  ) {}

  confirm(): void {
    this.dialogRef.close({
      status: Status.SUCCESS,
      data: { reset_consolidated_values: this.reset_consolidated_values },
    });
  }

  toggleDefault(value: Event) {
    this.reset_consolidated_values = !!value;
  }

  close(): void {
    this.dialogRef.close({ status: Status.CANCEL });
  }
}
