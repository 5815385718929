import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';
import { CDPTaskErrorType, JobQueueOutputCDPReportSubmission, JobQueueOutputCDPReportSubmissionError } from '../models';

export class CdpJobQueueOutputBuilder {
  private readonly _cdpJobQueueOutput: JobQueueOutputCDPReportSubmission;

  constructor() {
    this._cdpJobQueueOutput = {
      report_id: uuidv4(),
      report_name: faker.random.alpha(10),
      error_message: faker.random.words(10),
      cdp_error_type: CDPTaskErrorType.GENERIC,
      log_text: '',
      errors: [],
    };
  }

  report_id(report_id: string): CdpJobQueueOutputBuilder {
    this._cdpJobQueueOutput.report_id = report_id;
    return this;
  }

  report_name(report_name: string): CdpJobQueueOutputBuilder {
    this._cdpJobQueueOutput.report_name = report_name;
    return this;
  }

  error_message(error_message: string): CdpJobQueueOutputBuilder {
    this._cdpJobQueueOutput.error_message = error_message;
    return this;
  }

  cdp_error_type(cdp_error_type: CDPTaskErrorType): CdpJobQueueOutputBuilder {
    this._cdpJobQueueOutput.cdp_error_type = cdp_error_type;
    return this;
  }

  log_text(log_text: string): CdpJobQueueOutputBuilder {
    this._cdpJobQueueOutput.log_text = log_text;
    return this;
  }

  errors(errors: JobQueueOutputCDPReportSubmissionError[]): CdpJobQueueOutputBuilder {
    this._cdpJobQueueOutput.errors = errors;
    return this;
  }

  build(): JobQueueOutputCDPReportSubmission {
    return this._cdpJobQueueOutput;
  }
}
