import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Required } from '../../../decorators';
import { ValueFormControl } from '../../../metric-editor-form/models/valueFormControl';
import { FormUtils } from '../../../classes';
import { ConsolidationRules, MetricTableDefinition, ValueDefinitionType } from '../../../models';
import { ConsolidationService } from '../../../services/common/consolidation/consolidation.service';
import { TableTotalFormulaPipe } from '../../../metric-editor-form/pipes/table-total-formula/table-total-formula.pipe';
import { MetricTableTotalFormulaPipe } from '../../../pipes';

@Component({
  selector: 'lib-form-field-label',
  templateUrl: './form-field-label.component.html',
  styleUrls: ['./form-field-label.component.scss'],
})
export class FormFieldLabelComponent implements OnChanges {
  @Input() @Required() control!: UntypedFormControl | ValueFormControl;
  @Input() id: string = '';
  @Input() for: string = '';
  @Input() label: string = '';
  @Input() displayReset: boolean = true;
  @Input() metricTableDefinition?: MetricTableDefinition;

  required: boolean = false;
  fieldInfo: string = '';
  displayConsolidatedInfo: boolean = false;
  displayTableTotalInfo: boolean = false;
  tableTotalFormulaPipe = new TableTotalFormulaPipe();
  metricTableTotalFormulaPipe = new MetricTableTotalFormulaPipe();

  constructor(private consolidationService: ConsolidationService) {}

  ngOnChanges(): void {
    this.required = this.control.hasValidator(Validators.required);
    this.setDisplayConsolidatedInfo();
    this.setDisplayTableCalculatedInfo();
    this.fieldInfo = this.setToolTipInfo();
  }

  displayDeleteIcon(): boolean {
    if (!this.isValueFormControl(this.control) || !this.displayReset || this.control.disabled) {
      return false;
    }

    const hasValueAndId = this.hasValue() && !FormUtils.isNullOrEmpty(this.control.valueRef.id);

    if (this.control.isConsolidated()) {
      return hasValueAndId && !ConsolidationService.isQuantitativeRule(this.control.valueRef.consolidation_rule);
    }
    return hasValueAndId;
  }

  setToolTipInfo(): string {
    if (this.displayConsolidatedInfo) {
      return this.formatConsolidatedInfo();
    }
    if (this.displayTableTotalInfo) {
      return this.formatMetricTableTotalInfo();
    }
    return '';
  }

  setDisplayConsolidatedInfo(): void {
    if (this.isValueFormControl(this.control)) {
      const isConsolidated = this.control.isConsolidated();
      let isCalculatedField = false;

      if (this.control instanceof ValueFormControl) {
        isCalculatedField = this.control.valueRef.type == ValueDefinitionType.calculated;
      }
      if (
        isConsolidated &&
        !isCalculatedField &&
        this.consolidationService.staticConsolidationFlag &&
        this.control.valueRef.consolidation_rule != ConsolidationRules.manual
      ) {
        this.displayConsolidatedInfo = true;
      }
    }
  }

  setDisplayTableCalculatedInfo(): void {
    if (this.isValueFormControl(this.control)) {
      let isMetricTable = false;

      if (this.metricTableDefinition) {
        isMetricTable = true;
      }
      if (isMetricTable) {
        this.displayTableTotalInfo = true;
      }
    }
  }

  formatMetricTableTotalInfo(): string {
    let calculatedTableInfo = '';
    if (this.control instanceof ValueFormControl && this.metricTableDefinition) {
      calculatedTableInfo = this.metricTableTotalFormulaPipe.transform(
        this.tableTotalFormulaPipe.transform(this.metricTableDefinition.calculation_definitions, this.control.valueRef)
      );
    }
    return calculatedTableInfo;
  }

  deleteValue(): void {
    if (this.isValueFormControl(this.control)) {
      this.control.resetValue();
    }
  }

  formatConsolidatedInfo(): string {
    let consolidatedInfo = '';
    if (this.control instanceof ValueFormControl) {
      consolidatedInfo = this.consolidationService.getConsolidatedInfoBasedOnValueType(this.control.valueRef);
    }
    return consolidatedInfo;
  }

  private hasValue(): boolean {
    const value = this.control.value as unknown;
    return Array.isArray(value) ? value.length > 0 : !!value || value === 0;
  }

  private isValueFormControl(control: UntypedFormControl): control is ValueFormControl {
    return 'valueRef' in control;
  }
}
