import { v4 as uuidv4 } from 'uuid';

import {
  DataRequestFieldComment,
  DataRequestFieldCommentReply,
  DataRequestFieldCommentType,
  DataRequestSourceStatus,
} from '../models';
import { faker } from '@faker-js/faker';
import { Builder } from './builder';

export class DataRequestFieldCommentBuilder extends Builder<DataRequestFieldComment> {
  constructor() {
    super({
      id: uuidv4(),
      comment: faker.lorem.word(2),
      author: faker.name.fullName(),
      created: faker.date.past(),
      data_request_source_status: DataRequestSourceStatus.IN_APPROVAL,
      comment_type: DataRequestFieldCommentType.REGULAR,
      resolved: false,
      data_request_value_group_set_id: uuidv4(),
      data_request_value_group_id: uuidv4(),
      value_definition_id: uuidv4(),
      latest_reply: null,
      reply_count: 0,
    });
  }

  id(id: string): DataRequestFieldCommentBuilder {
    this._instance.id = id;
    return this;
  }

  comment(comment: string): DataRequestFieldCommentBuilder {
    this._instance.comment = comment;
    return this;
  }

  author(author: string): DataRequestFieldCommentBuilder {
    this._instance.author = author;
    return this;
  }

  created(created: Date): DataRequestFieldCommentBuilder {
    this._instance.created = created;
    return this;
  }

  data_request_source_status(data_request_source_status: DataRequestSourceStatus): DataRequestFieldCommentBuilder {
    this._instance.data_request_source_status = data_request_source_status;
    return this;
  }

  comment_type(comment_type: DataRequestFieldCommentType): DataRequestFieldCommentBuilder {
    this._instance.comment_type = comment_type;
    return this;
  }

  resolved(resolved: boolean): DataRequestFieldCommentBuilder {
    this._instance.resolved = resolved;
    return this;
  }

  data_request_value_group_set_id(data_request_value_group_set_id: string): DataRequestFieldCommentBuilder {
    this._instance.data_request_value_group_set_id = data_request_value_group_set_id;
    return this;
  }

  value_definition_id(value_definition_id: string): DataRequestFieldCommentBuilder {
    this._instance.value_definition_id = value_definition_id;
    return this;
  }

  latest_reply(latest_reply: DataRequestFieldCommentReply) {
    this._instance.latest_reply = latest_reply;
    return this;
  }

  reply_count(reply_count: number) {
    this._instance.reply_count = reply_count;
    return this;
  }
}
