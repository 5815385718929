import { Metric, MetricCategory, isValueDefinition, isValueDefinitionGroup } from '../../../models';
import { MetricStructureSelectable } from '../../models';

export class MetricUtils {
  public static isEntityFromCoreRefV2Metric(
    metricStructureSelectable?: MetricStructureSelectable,
    metric?: Metric
  ): boolean {
    if (metric && MetricUtils.isRefV2Metric(metric)) {
      return (
        (isValueDefinition(metricStructureSelectable) && Boolean(metricStructureSelectable.core_value_definition_id)) ||
        (isValueDefinitionGroup(metricStructureSelectable) &&
          Boolean(metricStructureSelectable.core_value_definition_group_id))
      );
    }
    return false;
  }

  public static isRefV2Metric(metric: Metric): boolean {
    return Boolean(metric.reference_v2) && metric.category === MetricCategory.REFERENCE;
  }
}
