import { MetricGroupMetric } from '../models';

export class MetricGroupMetricBuilder {
  private readonly _metricGroupMetric: MetricGroupMetric;

  constructor() {
    this._metricGroupMetric = {
      id: 'MGM01',
      position: 1,
      metric_id: 'M01',
      indicator_id: 'I01',
      metric_group_id: 'MG01',
    };
  }

  id(id: string): MetricGroupMetricBuilder {
    this._metricGroupMetric.id = id;
    return this;
  }

  position(position: number): MetricGroupMetricBuilder {
    this._metricGroupMetric.position = position;
    return this;
  }

  metricGroupId(id: string): MetricGroupMetricBuilder {
    this._metricGroupMetric.metric_group_id = id;
    return this;
  }

  metricId(id: string): MetricGroupMetricBuilder {
    this._metricGroupMetric.metric_id = id;
    return this;
  }

  indicatorId(id: string): MetricGroupMetricBuilder {
    this._metricGroupMetric.indicator_id = id;
    return this;
  }

  build(): MetricGroupMetric {
    return this._metricGroupMetric;
  }
}
