import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControlStatus } from '@angular/forms';
import merge from 'lodash/merge';
import { finalize, Subscription } from 'rxjs';
import { Required } from '../../../../decorators';
import { ConfirmationDialogComponent, DialogsService } from '../../../../dialogs';
import {
  ConfirmationDialogConfig,
  Doc,
  FileDocumentInterface,
  FileTypeDetailsV2,
  FileValue,
  ItemType,
  Status,
} from '../../../../models';
import { DownloadDocumentService, TranslateService } from '../../../../services/common';
import { DEFAULT_DOCUMENT_CONTEXT } from '../../../models/documentContext';
import { FileAttachmentFormGroupV2 } from './file-attachment-form-v2/file-attachment-form-v2';
import { ValueFormControl } from '../../../models/valueFormControl';
import { BaseMetricEditorFormStateService } from '../../../services/base-metric-editor-form-state/base-metric-editor-form-state.service';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

@Component({
  selector: 'lib-metric-editor-file-card',
  templateUrl: './metric-editor-file-card.component.html',
  styleUrls: ['./metric-editor-file-card.component.scss'],
})
export class MetricEditorFileCardComponent implements OnInit, OnDestroy, OnChanges {
  @Input() @Required() fileValue!: FileValue;
  @Input() @Required() valueFormControl!: ValueFormControl<FileTypeDetailsV2>;
  @Input() fileDoc?: FileDocumentInterface;
  @Input() documentContext = DEFAULT_DOCUMENT_CONTEXT;
  @Input() status?: FormControlStatus;

  @Output() deleteFileValue: EventEmitter<string> = new EventEmitter<string>();

  fileFormGroup?: FileAttachmentFormGroupV2;
  isDownloading: boolean = false;
  isFileAdded: boolean = false;
  explanationLabel: string = this.translateService.instant('Explanation field for the attached file');

  private statusChangesSubscription?: Subscription;
  private isFileAddedSubscription?: Subscription;

  constructor(
    private dialogsService: DialogsService,
    private translateService: TranslateService,
    private baseMetricEditorFormStateService: BaseMetricEditorFormStateService,
    private downloadDocumentService: DownloadDocumentService
  ) {}

  ngOnInit(): void {
    this.isFileAdded = ((this.valueFormControl.value ?? []) as FileValue[]).some(
      (doc) => doc.file_id === this.fileValue.file_id
    );
    this.isFileAddedSubscription = this.valueFormControl.valueChanges.subscribe((value) => {
      this.isFileAdded = ((value ?? []) as FileValue[]).some((doc) => doc.file_id === this.fileValue.file_id);
    });

    this.fileFormGroup = new FileAttachmentFormGroupV2(
      this.valueFormControl.valueRef.type_details,
      this.fileValue,
      this.documentContext.itemType === ItemType.metrics_indicator ? (this.fileDoc?.doc as Doc) : undefined
    );
    this.updateValueFormAvailability();

    if (!this.isFileAdded) {
      this.handleFileFormGroupStatus(this.fileFormGroup.status);
    }

    if (this.canUpdateValueFormControl()) {
      this.updateValueFormControl();
    }
    this.statusChangesSubscription = this.fileFormGroup.statusChanges.subscribe((status) => {
      this.handleFileFormGroupStatus(status);
    });
    this.explanationLabel = this.valueFormControl.valueRef.type_details.explanation_label || this.explanationLabel;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.status?.currentValue !== changes?.status?.previousValue) {
      this.updateValueFormAvailability();
    }
  }

  ngOnDestroy(): void {
    this.updateValueFormControl();
    this.isFileAddedSubscription?.unsubscribe();
    this.statusChangesSubscription?.unsubscribe();
  }

  public updateValueFormControl(): void {
    if (this.fileFormGroup?.status === 'VALID') {
      if (this.fileFormGroup) {
        const currentValue = this.valueFormControl.value ? [...(this.valueFormControl.value as FileValue[])] : [];
        let afterValue = [...currentValue];
        if (this.isFileAdded) {
          const index = currentValue.findIndex((doc: FileValue) => doc.file_id === this.fileDoc?.id);
          afterValue[index] = this.fileFormGroup.toModel();
        } else {
          afterValue = [...currentValue, this.fileFormGroup.toModel()];
        }

        if (
          !isEqual(
            MetricEditorFileCardComponent.getBasicFileValues(currentValue),
            MetricEditorFileCardComponent.getBasicFileValues(afterValue)
          )
        ) {
          this.valueFormControl.setValue(afterValue);
        }
      }
    }
  }

  public downloadDoc(): void {
    if (this.fileDoc) {
      this.isDownloading = true;
      this.baseMetricEditorFormStateService
        .getDocument(this.fileDoc)
        .pipe(
          finalize(() => {
            this.isDownloading = false;
          })
        )
        .subscribe((blob) => {
          const blobOctetStream: Blob = new Blob([blob], { type: 'application/octet-stream' });
          this.downloadDocumentService.downloadAction(
            `${this.fileDoc?.name ?? ''}.${this.fileDoc?.format ?? ''}`,
            blobOctetStream
          );
        });
    }
  }

  public deleteDocument(): void {
    this.dialogsService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant('Delete file'),
          warningMsg: this.translateService.instant('Are you sure you wish to delete the file?'),
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.status === Status.CONFIRMED) {
          this.deleteFileValue.emit(this.isFileAdded ? this.fileDoc?.id : '');
        }
      });
  }

  private handleFileFormGroupStatus(status: FormControlStatus): void {
    if (status === 'INVALID') {
      this.valueFormControl.disable();

      this.valueFormControl.setErrors(
        merge(
          this.fileFormGroup?.docUrl?.errors,
          this.fileFormGroup?.pageNumber?.errors,
          this.fileFormGroup?.explanation?.errors
        ) ?? null
      );
    } else if (status === 'VALID') {
      this.valueFormControl.enable();
    }
  }

  private canUpdateValueFormControl(): boolean {
    return (
      !this.isFileAdded &&
      !!this.fileFormGroup &&
      (this.fileFormGroup.valid ||
        (Object.keys(this.fileFormGroup.controls).length === 1 && !!this.fileFormGroup.docUrl?.disabled))
    );
  }

  private updateValueFormAvailability(): void {
    if (!this.valueFormControl.errors && this.valueFormControl.disabled) {
      this.fileFormGroup?.disable();
    } else if (this.fileFormGroup?.docUrl?.value && this.fileFormGroup.docUrl.disabled) {
      this.fileFormGroup.pageNumber?.enable();
      this.fileFormGroup.explanation?.enable();
    } else {
      this.fileFormGroup?.enable();
    }
  }

  private static getBasicFileValues(fileValues: FileValue[]): Partial<FileValue>[] {
    return fileValues.map((fileValue) => pick(fileValue, ['file_id', 'url', 'page_number', 'explanation']));
  }
}
