import { Entity } from '../entities';

export interface FiscalYear extends Entity {
  year: number;
  start: string;
  end: string;
  unit_id: string;
  id: string;
  status: FiscalYearStatus;
  frequency_code?: string;
}

export interface FiscalYearsFilteringOptions {
  offset?: number;
  limit?: number;
}

export interface FiscalYearRequest {
  frequency_code: number;
  start: Date;
  end: Date;
  regular_fiscal_years: boolean;
  status: FiscalYearStatus;
}

export enum FiscalYearStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

export type FiscalYearTemplateRow = FiscalYear & { item?: FiscalYear };

export interface FiscalYearTable {
  rows: FiscalYearTemplateRow[];
  size: number;
}

export interface FiscalYearNotesRequest {
  notes: string | null;
}

export interface MetricsMappingCount {
  source_name: string;
  metric_count: number;
}

export interface FiscalYearClosingValidation {
  metrics_mapping_count: MetricsMappingCount[];
  data_request_count: number;
}
