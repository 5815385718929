import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common/api/api.service';
import { ApiResponse, ApplicationApiDefinition, ItemType } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class ClientAssetsService {
  apiName: keyof ApplicationApiDefinition = 'documents';
  resource: string;
  servicePath: string;

  constructor(private baseService: BaseService, private apiService: ApiService, private http: HttpClient) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.documents.resources.assets;
  }

  getDoc(itemType: ItemType, storage_filename: string): Observable<Blob> {
    let cat: string = '';
    switch (itemType) {
      case ItemType.benchmark_v2_exports:
      case ItemType.docs_doc:
      case ItemType.metric_data_exports:
      case ItemType.report_exports:
        cat = 'documents';
        break;
      case ItemType.docs_report:
      case ItemType.docs_resource:
        cat = 'resources';
        break;
      default:
        throw new Error(`Document category not supported: ${itemType}`);
    }
    const apiUrl: string = this.apiService.getBaseUrl();
    return this.http.get(`${apiUrl}${this.servicePath}/${this.resource}/${cat}/v1/${storage_filename}`, {
      headers: { 'Content-Type': 'application/octet-stream' },
      responseType: 'blob',
    });
  }

  uploadDocument(
    formData: FormData,
    reportProgress = false
  ): Observable<ApiResponse<{ document_uploaded_id: string }>> {
    return this.apiService.upload(`${this.servicePath}/${this.resource}/documents/v1/`, formData, reportProgress);
  }
}
