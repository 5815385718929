import { Injectable } from '@angular/core';

import { ApiService } from '../../common/api/api.service';
import { AppConfig, ApplicationApiDefinition, ClientConfig, FeatureFlags, Platforms } from '../../../models';
import { BehaviorSubject, Observable } from 'rxjs';
import { ContextService } from '../../common/context/context.service';

const DEFAULT_CLIENT_CONFIG: ClientConfig = {
  basic_auth_enabled: false,
  metric_table_enabled: false,
  standard_companies_enabled: false,
  user_groups_enabled: false,
  translation_enabled: false,
  metric_structure_enabled: false,
  metric_editor_form_focus_enabled: false,
  static_consolidation: false,
  data_export_enabled: false,
  file_attachment_v2_enabled: false,
  logs_enabled: false,
  numeric_mask_disabled: false,
  theme: {},
  security_2fa_enabled: false,
  password_security_settings_enabled: false,
  metric_library_grid_enabled: false,
  field_standard_code_association_panel_enabled: false,
  export_backend_enabled: false,
  framework_template_management: false,
  novisto_light_enabled: false,
  framework_template: false,
  targets_management_enabled: false,
  help_desk_enabled: false,
  field_comments_enabled: false,
  enable_ref_metrics_v2: false,
  metric_structure_deactivation_enabled: false,
  metrics_library_export_phase_2_enabled: false,
  benchmark_v2_enabled: false,
  data_requests_documents_config_enabled: false,
  due_date_auto_reminders_enabled: false,
  consolidation_background_tasks_enabled: true,
  metric_library_compare_enabled: false,
  metric_data_audit_export_enabled: false,
  benchmark_v2_ref_dropdown_enabled: false,
  source_management_enabled: false,
  participants_panel_enabled: false,
  target_widget_enabled: false,
};

const DEFAULT_ADMIN_CONFIG: ClientConfig = {
  basic_auth_enabled: true,
  metric_table_enabled: false,
  standard_companies_enabled: false,
  user_groups_enabled: false,
  translation_enabled: false,
  metric_structure_enabled: false,
  metric_editor_form_focus_enabled: false,
  static_consolidation: false,
  data_export_enabled: false,
  file_attachment_v2_enabled: true,
  logs_enabled: false,
  numeric_mask_disabled: false,
  theme: {},
  security_2fa_enabled: false,
  password_security_settings_enabled: false,
  metric_library_grid_enabled: false,
  field_standard_code_association_panel_enabled: true,
  export_backend_enabled: false,
  framework_template_management: true,
  framework_template: false,
  novisto_light_enabled: false,
  targets_management_enabled: false,
  help_desk_enabled: false,
  field_comments_enabled: false,
  enable_ref_metrics_v2: false,
  metric_structure_deactivation_enabled: false,
  metrics_library_export_phase_2_enabled: false,
  benchmark_v2_enabled: false,
  data_requests_documents_config_enabled: false,
  due_date_auto_reminders_enabled: false,
  consolidation_background_tasks_enabled: true,
  metric_library_compare_enabled: false,
  metric_data_audit_export_enabled: false,
  benchmark_v2_ref_dropdown_enabled: false,
  source_management_enabled: false,
  participants_panel_enabled: false,
  target_widget_enabled: false,
};

const DEFAULT_CONFIG: ClientConfig = {
  basic_auth_enabled: false,
  metric_table_enabled: false,
  standard_companies_enabled: false,
  user_groups_enabled: false,
  translation_enabled: false,
  metric_structure_enabled: false,
  metric_editor_form_focus_enabled: false,
  static_consolidation: false,
  data_export_enabled: false,
  file_attachment_v2_enabled: false,
  logs_enabled: false,
  numeric_mask_disabled: false,
  theme: {},
  security_2fa_enabled: false,
  password_security_settings_enabled: false,
  metric_library_grid_enabled: false,
  field_standard_code_association_panel_enabled: false,
  export_backend_enabled: false,
  framework_template_management: false,
  framework_template: false,
  novisto_light_enabled: false,
  targets_management_enabled: false,
  help_desk_enabled: false,
  field_comments_enabled: false,
  enable_ref_metrics_v2: false,
  metric_structure_deactivation_enabled: false,
  metrics_library_export_phase_2_enabled: false,
  benchmark_v2_enabled: false,
  data_requests_documents_config_enabled: false,
  due_date_auto_reminders_enabled: false,
  consolidation_background_tasks_enabled: true,
  metric_library_compare_enabled: false,
  metric_data_audit_export_enabled: false,
  benchmark_v2_ref_dropdown_enabled: false,
  source_management_enabled: false,
  participants_panel_enabled: false,
  target_widget_enabled: false,
};

@Injectable({
  providedIn: 'root',
})
export class ClientConfigService {
  private clientConfig?: ClientConfig;
  private isClientConfigReadySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private apiName: keyof ApplicationApiDefinition = 'auth';
  private resource: string;
  private servicePath: string;
  private errorMessage: string =
    'An error occurred while fetching the client configs. This session will use the default client configs. All feature flags will be set to false.';

  constructor(private apiService: ApiService, private contextService: ContextService, private appConfig: AppConfig) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.auth.resources.config;

    this.initializeConfig();
  }

  private setClientConfig(clientConfig: ClientConfig) {
    this.clientConfig = clientConfig;
    this.isClientConfigReadySubject.next(true);
  }

  private initializeConfig(): void {
    if (this.appConfig.platform === Platforms.CLIENT) {
      this.apiService.get(`${this.servicePath}${this.resource}/configs`).subscribe({
        next: (result) => {
          const mergedConfig: ClientConfig = { ...DEFAULT_CLIENT_CONFIG, ...result.data };
          this.setClientConfig(mergedConfig);
        },
        error: (error: unknown) => {
          console.error(this.errorMessage, error);
          this.setClientConfig(DEFAULT_CLIENT_CONFIG);
        },
      });
    } else if (this.appConfig.platform === Platforms.ADMIN) {
      this.setClientConfig(DEFAULT_ADMIN_CONFIG);
    } else {
      this.setClientConfig(DEFAULT_CONFIG);
    }
  }

  public areAnyFeatureFlagsEnabled(flag: (keyof FeatureFlags)[]): boolean {
    if (this.clientConfig) {
      return flag.some((flag: keyof FeatureFlags) => this.clientConfig?.[flag]);
    }
    return false;
  }

  public isClientReady$(): Observable<boolean> {
    return this.isClientConfigReadySubject.asObservable();
  }
}
