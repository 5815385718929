import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionItem, FilterBarOption, FilterBarSelection, FilterType } from '../../models';
import { TranslateService, FilterService } from '../../services/common';
import { ButtonProps, SearchableOptionProps } from '../models';

interface SearchFilterOption {
  optionType: FilterType;
  filterOption: SearchableOptionProps;
  buttonOption: ButtonProps;
  multiSelect: boolean;
}

@Component({
  selector: 'lib-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
})
export class FilterBarComponent implements OnInit {
  @Input() withPadding: boolean = true;
  @Input() withSearchBar: boolean = false;
  @Input() primaryFilters: FilterBarOption[] = [];
  @Input() secondaryFilters: FilterBarOption[] = [];
  @Input() searchBarPlaceholder: string = this.translateService.instant('Search');

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFilterChanged = new EventEmitter<FilterBarSelection[]>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSearchChanged = new EventEmitter<string>();

  FilterType = FilterType;

  hasSelectedFilters = false;
  primaryOptions: FilterBarOption[] = [];
  secondaryOptions: FilterBarOption[] = [];
  primaryFilteringOptions: SearchFilterOption[] = [];
  secondaryFilteringOptions: SearchFilterOption[] = [];
  selectedOptions: FilterBarSelection[] = [];

  constructor(private readonly filterService: FilterService, private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.primaryOptions = this.primaryFilters;
    this.secondaryOptions = this.secondaryFilters;

    this.setupSelectedOptions();
    this.setupOptions();
  }

  optionSelected(selectedOption: ActionItem, filterOption: SearchFilterOption): void {
    const index = this.selectedOptions.findIndex((option) => option.id === filterOption.filterOption.id);

    if (this.selectedOptions[index].selection.some((selection) => selection.id == selectedOption.id)) {
      return;
    }

    this.selectedOptions[index].selection = [selectedOption];

    this.filterChanged();
  }

  optionsSelected(selectedOptions: ActionItem[], filterOption: SearchFilterOption): void {
    const index = this.selectedOptions.findIndex((option) => option.id === filterOption.filterOption.id);

    this.selectedOptions[index].selection = selectedOptions;

    this.filterChanged();
  }

  resetFilters(): void {
    const primaryOptions = this.mapFilterBarOptionsToFilterBarSelection(this.primaryOptions, true);
    const secondaryOptions = this.mapFilterBarOptionsToFilterBarSelection(this.secondaryOptions);

    this.selectedOptions = [...primaryOptions, ...secondaryOptions];

    this.filterChanged();
  }

  private filterChanged() {
    this.hasSelectedFilters = this.hasSelectedOption();
    this.setupOptions();

    this.onFilterChanged.emit(this.selectedOptions);
  }

  private setupSelectedOptions() {
    const primaryOptions = this.mapFilterBarOptionsToFilterBarSelection(this.primaryOptions, true);
    const secondaryOptions = this.mapFilterBarOptionsToFilterBarSelection(this.secondaryOptions);

    this.selectedOptions = [...primaryOptions, ...secondaryOptions];

    this.hasSelectedFilters = this.hasSelectedOption();
  }

  private setupOptions() {
    this.primaryFilteringOptions = this.mapFilterBarOptionsToSearchFilterOption(this.primaryOptions);
    this.secondaryFilteringOptions = this.mapFilterBarOptionsToSearchFilterOption(this.secondaryOptions);
  }

  private mapFilterBarOptionsToFilterBarSelection(
    filterBarOptions: FilterBarOption[],
    isPrimary: boolean = false
  ): FilterBarSelection[] {
    return filterBarOptions.map((option) => ({
      id: option.id,
      isPrimary,
      selection: option.defaultValue
        ? [option.options.find((subOption) => subOption.title === option.defaultValue)!]
        : [this.filterService.filterListDefaultValue],
    }));
  }

  private mapFilterBarOptionsToSearchFilterOption(filterBarOptions: FilterBarOption[]): SearchFilterOption[] {
    return filterBarOptions.map((option) => {
      const selectedOption = this.selectedOptions.filter((selectedOption) => selectedOption.id === option.id)[0];

      return {
        optionType: option.optionType,
        filterOption: this.filterService.makeFilterOption(option, selectedOption.selection[0]),
        buttonOption: this.filterService.makeButtonOption(option, selectedOption.selection[0]),
        multiSelect: option.multiSelect ?? false,
      };
    });
  }

  private hasSelectedOption(): boolean {
    return !!this.selectedOptions.find(
      (option) =>
        option.selection.some((selection) => selection !== this.filterService.filterListDefaultValue) &&
        !option.isPrimary &&
        option.selection.some(
          (selection) =>
            selection.title !==
            this.secondaryFilters.find((secondaryFilter) => secondaryFilter.id === option.id)?.defaultValue
        )
    );
  }
}
