import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  ApiResponse,
  ApplicationApiDefinition,
  CodeCheck,
  MinimalEntity,
  SearchOptions,
  StandardCodes,
} from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { BaseService } from '../../common/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class AdminStandardCodeService {
  apiName: keyof ApplicationApiDefinition = 'admin';
  resource: string;
  servicePath: string;

  constructor(private baseService: BaseService, private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.admin.resources.standard_codes;
  }

  standardCodesPayloadFromSearchOptions(searchOptions: SearchOptions): any {
    const payload: any = {};

    payload.filters = {};
    payload.order_by = 'code';

    payload.load_topics = true;
    payload.load_industries = true;

    payload.from = searchOptions.from || 0;
    payload.size = searchOptions.size || this.apiService.apiConfig.pageSize;

    if (searchOptions.query.keywords) {
      payload.keywords = searchOptions.query.keywords;
    }

    if (searchOptions.filters.topic) {
      if (searchOptions.filters.topic.action === 'category') {
        payload.filters.topic_categories = [searchOptions.filters.topic.id];
      } else if (searchOptions.filters.topic.action === 'group') {
        payload.filters.topic_groups = [searchOptions.filters.topic.id];
      } else {
        payload.filters.topics = [searchOptions.filters.topic.id];
      }
    }

    if (searchOptions.filters.industry) {
      payload.filters.industries = [searchOptions.filters.industry.id];
    }

    if (searchOptions.filters.start) {
      payload.filters.start = searchOptions.filters.start.item.split('T')[0];
    }

    if (searchOptions.custom_filters) {
      for (const filterKey of Object.keys(searchOptions.custom_filters)) {
        payload.filters[filterKey] = searchOptions.custom_filters[filterKey];
      }
    }
    if (searchOptions.sort) {
      switch (searchOptions.sort.id) {
        case 'updated':
          payload.order_by = 'updated';
          payload.order_by_direction = 'desc';
          break;
        default:
          break;
      }
    }

    return payload;
  }

  public search(searchOptions: SearchOptions): Observable<ApiResponse<StandardCodes[]>> {
    const payload: any = this.standardCodesPayloadFromSearchOptions(searchOptions);
    return this.apiService.post(`${this.servicePath}${this.resource}/standard_codes/list`, payload);
  }

  public createStandardCode(standardCode: any): Observable<ApiResponse<StandardCodes>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/standard_codes`, standardCode);
  }

  public getStandardCode(standardCodeId: string): Observable<ApiResponse<StandardCodes>> {
    let params = new HttpParams();
    params = params.append('load_taxonomies', true);
    params = params.append('load_topics', true);
    params = params.append('load_industries', true);
    params = params.append('load_metrics', true);
    return this.apiService.get(`${this.servicePath}${this.resource}/standard_codes/${standardCodeId}`, { params });
  }

  public updateStandardCode(standardCodeId: string, payload: any): Observable<ApiResponse<StandardCodes>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/standard_codes/${standardCodeId}`, payload);
  }

  public activateStandardCode(standardCodeId: string): Observable<ApiResponse<StandardCodes>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/standard_codes/${standardCodeId}/activate`, {});
  }

  public deactivateStandardCode(standardCodeId: string): Observable<ApiResponse<StandardCodes>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/standard_codes/${standardCodeId}/deactivate`, {});
  }

  public listMinimal(): Observable<ApiResponse<MinimalEntity[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/standard_codes/minimal`, {});
  }

  public checkIfStandardCodeExists(standard_code: string): Observable<ApiResponse<CodeCheck>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/standard_codes/code_check/${standard_code}`);
  }
}
