import { Indicator, IndicatorRelatedMetrics, Industry, MetricCategory, Topic, ValueGroupSet } from '../models';
import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';
import { formatDate } from '@angular/common';
import { BUILDER_DATE_FORMAT, BUILDER_LOCAL } from './builder-utils';
import { EntityBuilder } from './entity-builder';

export class IndicatorBuilder extends EntityBuilder<Indicator> {
  constructor() {
    super({
      name: faker.name.jobDescriptor(),
      id: uuidv4(),
      category: MetricCategory.CUSTOM,
      active: true,
      description: faker.lorem.sentences(2),
      start_year: faker.date.past().getFullYear(),
      core_metric_id: uuidv4(),
      metric_id: uuidv4(),
      value_group_sets: [],
      start: formatDate(faker.date.past(), BUILDER_DATE_FORMAT, BUILDER_LOCAL),
      reference_v2: false,
      guidance: '',
    });
  }

  value_group_sets(vgsets: ValueGroupSet[]): IndicatorBuilder {
    this._instance.value_group_sets = vgsets;
    return this;
  }

  description(description: string): IndicatorBuilder {
    this._instance.description = description;
    return this;
  }

  metricId(metricId: string): IndicatorBuilder {
    this._instance.metric_id = metricId;
    return this;
  }

  topics(topics: Topic[]): IndicatorBuilder {
    this._instance.topics = topics;
    return this;
  }

  industries(industries: Industry[]): IndicatorBuilder {
    this._instance.industries = industries;
    return this;
  }

  relatedMetrics(relatedMetrics: IndicatorRelatedMetrics): IndicatorBuilder {
    this._instance.related_metrics = relatedMetrics;
    return this;
  }

  start(start: string): IndicatorBuilder {
    this._instance.start = start;
    return this;
  }

  category(category: MetricCategory): IndicatorBuilder {
    this._instance.category = category;
    return this;
  }

  name(name: string): IndicatorBuilder {
    this._instance.name = name;
    return this;
  }

  code(code: string): IndicatorBuilder {
    this._instance.code = code;
    return this;
  }

  updatedBy(updatedBy: string): IndicatorBuilder {
    this._instance.updated_by = updatedBy;
    return this;
  }

  startYear(startYear: number): IndicatorBuilder {
    this._instance.start_year = startYear;
    return this;
  }

  referenceV2(referenceV2: boolean): IndicatorBuilder {
    this._instance.reference_v2 = referenceV2;
    return this;
  }

  guidance(guidance: string): IndicatorBuilder {
    this._instance.guidance = guidance;
    return this;
  }
}
