import { LoginEventRequest, LoginEventTypes } from '../models';

export class LoginEventsRequestBuilder {
  private readonly loginEventsRequest: LoginEventRequest;

  constructor() {
    this.loginEventsRequest = {
      id: '1',
      email: 'novisto@novisto.com',
      event_type: LoginEventTypes.login_success,
      timestamp: new Date().toString(),
      user_id: 'user_id',
    };
  }

  id(id: string): LoginEventsRequestBuilder {
    this.loginEventsRequest.id = id;
    return this;
  }

  email(email: string): LoginEventsRequestBuilder {
    this.loginEventsRequest.email = email;
    return this;
  }

  eventType(event_type: LoginEventTypes): LoginEventsRequestBuilder {
    this.loginEventsRequest.event_type = event_type;
    return this;
  }

  userId(userId: string): LoginEventsRequestBuilder {
    this.loginEventsRequest.user_id = userId;
    return this;
  }

  build(): LoginEventRequest {
    return this.loginEventsRequest;
  }
}
