import { StandardDocumentMetadata } from '../documents';
import { Doc, FileDocumentInterface, RequestDocMetadata } from '../models';
import { Builder } from './builder';
import { DocBuilder } from './doc-builder';

export class FileDocumentBuilder extends Builder<FileDocumentInterface> {
  constructor() {
    const doc = new DocBuilder().build();
    super({
      format: doc.format,
      name: doc.name,
      id: doc.id,
      doc,
    });
  }

  id(id: string): FileDocumentBuilder {
    this._instance.id = id;
    return this;
  }

  name(name: string): FileDocumentBuilder {
    this._instance.name = name;
    return this;
  }

  format(format: string): FileDocumentBuilder {
    this._instance.format = format;
    return this;
  }

  doc(doc: Doc | RequestDocMetadata | StandardDocumentMetadata): FileDocumentBuilder {
    this._instance.doc = doc;
    return this;
  }
}
