import { ConditionalTrigger, ConditionalTriggerTarget } from '../models';

export class ConditionalTriggerBuilder {
  private readonly _conditionalTrigger: ConditionalTrigger;

  constructor() {
    this._conditionalTrigger = {
      id: '1234',
      source_value_definition_id: 'vdId',
      targets: [],
      values: ['Option 1'],
    };
  }

  id(id: string): ConditionalTriggerBuilder {
    this._conditionalTrigger.id = id;
    return this;
  }

  sourceValueDefId(sourceValueDefId: string): ConditionalTriggerBuilder {
    this._conditionalTrigger.source_value_definition_id = sourceValueDefId;
    return this;
  }

  targets(targets: ConditionalTriggerTarget[]): ConditionalTriggerBuilder {
    this._conditionalTrigger.targets = targets;
    return this;
  }

  values(values: string[] | boolean[]): ConditionalTriggerBuilder {
    this._conditionalTrigger.values = values;
    return this;
  }

  build(): ConditionalTrigger {
    return this._conditionalTrigger;
  }
}
