import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NOT_APPLICABLE_NUMERIC_VALUE } from '../../models';

export class ValidatorsUtils {
  static SYSTEM_MAX_NUMBER = 999999999999999;

  static isNotOnlySpaces(control: AbstractControl): ValidationErrors | null {
    return control.value?.length && control.value.trim() === ''
      ? {
          required: true,
        }
      : null;
  }

  static systemMaxNumber(control: AbstractControl): ValidationErrors | null {
    return Number(control.value) > ValidatorsUtils.SYSTEM_MAX_NUMBER ? { systemMax: true } : null;
  }

  // TODO: it verify that it does not contains only a string 'All' which is returned from the lib-multiselect-dropdown.
  //  It's not clean but helps until the component is fixed. Delete when NF-8882 is done.
  static isNotOnlyAll(control: AbstractControl): ValidationErrors | null {
    return control.value?.length && control.value.filter((item: string) => item != 'All').length
      ? null
      : {
          required: true,
        };
  }

  static isANumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      isNaN(Number(control.value)) && control.value != NOT_APPLICABLE_NUMERIC_VALUE
        ? { nonNumericError: { value: control.value as number } }
        : null;
  }
}
