import { LANGUAGES } from '../../../common';

export { LicenseeSettings, LanguageLicenseeSettings, LoginSecurityLicenseeSettings };

interface LicenseeSettings extends LanguageLicenseeSettings, LoginSecurityLicenseeSettings {}

interface LanguageLicenseeSettings {
  language: LANGUAGES;
}

interface LoginSecurityLicenseeSettings {
  logout_inactivity_time: number;
  warn_inactivity_time: number;
  max_login_attempts: number;
  login_lock_time: number;
  enforce_2fa: boolean;
  allow_risky_extensions_public: boolean;
  allow_risky_extensions_platform: boolean;
  password_reuse_max_number: number;
  password_reuse_max_age_months: number;
  password_expiration_days: number;
}
