<mat-menu #menu="matMenu">
  <ng-template *ngIf="actions?.action_list?.length" matMenuContent>
    <ng-container *ngFor="let action of actions.action_list">
      <ng-container *libPermission="action.permissions">
        <ng-container *libFeatureFlag="{ featureFlags: action.featureFlag }">
          <mat-divider *ngIf="action.id === 'separator'"></mat-divider>
          <a
            mat-menu-item
            *ngIf="action.id !== 'separator'"
            [ngClass]="{ 'color-error': action.id === 'delete' }"
            (click)="setAction(action)"
            [disabled]="action.disabled"
          >
            <mat-icon [svgIcon]="action.icon!" class="icon-sm"></mat-icon>
            <span>{{ action.title }}</span>
          </a>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #dropDownMenu="matMenu">
  <ng-template *ngIf="actions?.drop_down?.children" matMenuContent>
    <ng-container *ngFor="let action of actions?.drop_down?.children">
      <a mat-menu-item (click)="setAction(action)">
        <mat-icon *ngIf="action.icon" class="icon-sm" [svgIcon]="action.icon"></mat-icon>
        <span>{{ action.title }}</span>
      </a>
    </ng-container>
  </ng-template>
</mat-menu>

<div class="toolbar" [ngClass]="class">
  <header data-testid="page-header">
    <div class="toolbar-title">
      <a class="open-menu" title="{{ 'Open menu' | translate }}" (click)="openMenu()">
        <mat-icon svgIcon="menu"></mat-icon>
      </a>
      <a
        class="parent-link"
        title="{{ 'Back' | translate }}"
        role="button"
        aria-label="backArrowNav"
        *ngIf="withNavigation"
        [routerLink]="parentLink"
        [queryParamsHandling]="preserveQueryParams ? 'preserve' : ''"
      >
        <mat-icon svgIcon="arrow-full-left"></mat-icon>
      </a>

      <ng-content select="[slot=title]"></ng-content>

      <div
        class="title"
        [ngClass]="{ editable: editable, 'no-tabs': !tabs.length }"
        (click)="edit()"
        [viewerAction]="viewerAction.preventMouseAction"
        *ngIf="title"
      >
        <ng-container *ngIf="!titleEditing">
          <div class="title-text" title="" [matTooltip]="title.length > 30 ? title : ''">{{ title }}</div>
          <mat-icon class="title-icon icon-md" svgIcon="edit"></mat-icon>
        </ng-container>
        <ng-container *ngIf="titleEditing">
          <input
            type="text"
            data-testid="text-input"
            title=""
            #titleInput
            [(ngModel)]="currentTitle"
            (keydown)="keyDown($event)"
          />
          <div class="title-actions">
            <a class="action-close" data-testid="cancel-edit" (click)="cancel($event)">
              <mat-icon svgIcon="close"></mat-icon>
            </a>
            <a class="action-save" (click)="save($event)">
              <mat-icon svgIcon="check"></mat-icon>
              {{ "Save" | translate }}
            </a>
          </div>
        </ng-container>
      </div>

      <lib-page-header-subtitles [subtitles]="subtitles" [useChips]="useChipsSubtitles"></lib-page-header-subtitles>
    </div>
    <div class="toolbar-actions">
      <div *ngIf="progress" class="fx-row justify-space-between align-center" style="width: 26rem">
        <mat-progress-bar mode="determinate" [value]="progress.percent" style="width: 20rem"></mat-progress-bar>
        <!-- TODO: Make progress bar not fixed width? -->
        <div class="mat-body color-grey-200 ml-2">
          {{ "{current} of {total}" | translate : { current: progress.current, total: progress.total } }}
        </div>
      </div>
      <ng-container *ngIf="actions?.main_actions?.length">
        <a
          *ngFor="let action of actions?.main_actions"
          [title]="action.title"
          (click)="setAction(action)"
          [ngClass]="{ disabled: action.disabled }"
        >
          <mat-icon [svgIcon]="action.icon!"></mat-icon>
        </a>
      </ng-container>
      <a
        *ngIf="actions?.action_list?.length"
        title="{{ 'More' | translate }}"
        [matMenuTriggerFor]="menu"
        aria-label="header context menu"
        role="button"
        [ngClass]="{ active: menuOpened, disabled: actions.disabled }"
      >
        <mat-icon svgIcon="more"></mat-icon>
      </a>
      <a *ngIf="!disableHelp" routerLink="/app/help" title="{{ 'Help' | translate }}">
        <mat-icon svgIcon="help"></mat-icon>
      </a>
      <ng-content select="[slot=action-button]"></ng-content>
      <ng-container *ngFor="let button of actions?.buttons">
        <ng-container *libPermission="button.permissions">
          <a
            *libFeatureFlag="{ featureFlags: button.featureFlag }"
            lib-button
            [type]="'success'"
            aria-label="headerActionButton"
            role="button"
            (click)="setAction(button)"
            [disabled]="!!button.disabled"
            [label]="button.title"
            [viewerAction]="button.viewerAction"
          ></a>
        </ng-container>
      </ng-container>
      <a
        *ngIf="actions.drop_down"
        class="btn btn-dropdown fx-row align-center {{ actions.drop_down.text || 'bkg-white' }}"
        [title]="actions.drop_down.title"
        [matMenuTriggerFor]="dropDownMenu"
      >
        <span class="mr-1">{{ actions.drop_down.item?.title }}</span>
        <mat-icon svgIcon="arrow-head-down"></mat-icon>
      </a>
    </div>
  </header>
  <nav *ngIf="tabs?.length">
    <ng-container *ngFor="let tab of tabs">
      <ng-container *libPermission="tab.permissions">
        <a
          role="tab"
          [routerLink]="tab.id"
          routerLinkActive="active"
          [ngClass]="{ disabled: tab.disabled }"
          [title]="tab.title"
          *libFeatureFlag="{ featureFlags: tab.featureFlag }"
        >
          {{ tab.title }}
        </a>
      </ng-container>
    </ng-container>
  </nav>
</div>
