import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-panel-toggle',
  templateUrl: './panel-toggle.component.html',
  styleUrls: ['./panel-toggle.component.scss'],
})
export class PanelToggleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
