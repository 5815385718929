import { FiscalYear, FiscalYearStatus } from '../models';
import { faker } from '@faker-js/faker';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { INPUT_DATE_FORMAT } from './builder-utils';

export class FiscalYearBuilder {
  private readonly _fiscalYear: FiscalYear;

  constructor() {
    const randomDate: Date = dayjs(faker.date.past(20)).month(3).toDate();
    const randomYear: number = randomDate.getFullYear();

    this._fiscalYear = {
      id: uuidv4(),
      name: String(randomYear),
      year: randomYear,
      start: dayjs(randomDate).format(INPUT_DATE_FORMAT),
      end: dayjs(randomDate).add(1, 'year').format(INPUT_DATE_FORMAT),
      unit_id: faker.datatype.uuid(),
      status: FiscalYearStatus.OPEN,
      frequency_code: String(randomYear),
    };
  }

  fromYear(year: string): FiscalYearBuilder {
    this._fiscalYear.id = year;
    this._fiscalYear.name = year;
    this._fiscalYear.year = Number(year);
    return this;
  }

  id(id: string): FiscalYearBuilder {
    this._fiscalYear.id = id;
    return this;
  }

  name(name: string): FiscalYearBuilder {
    this._fiscalYear.name = name;
    return this;
  }

  year(year: string | number): FiscalYearBuilder {
    this._fiscalYear.year = Number(year);
    return this;
  }

  start(start: string): FiscalYearBuilder {
    this._fiscalYear.start = start;
    return this;
  }

  end(end: string): FiscalYearBuilder {
    this._fiscalYear.end = end;
    return this;
  }

  status(status: FiscalYearStatus): FiscalYearBuilder {
    this._fiscalYear.status = status;
    return this;
  }

  frequencyCode(frequencyCode: string): FiscalYearBuilder {
    this._fiscalYear.frequency_code = frequencyCode;
    return this;
  }

  build(): FiscalYear {
    return this._fiscalYear;
  }
}
