import { MetricTableCalculationTypeDetail, MetricTableCalculationTypeDetailFormula } from '../models';
import { MetricTableCalculationTypeDetailFormulaBuilder } from './metric-table-calculation-type-detail-formula-builder';

export class MetricTableCalculationTypeDetailBuilder {
  private readonly _metricTableCalculationTypeDetail: MetricTableCalculationTypeDetail;

  constructor() {
    this._metricTableCalculationTypeDetail = {
      formula: new MetricTableCalculationTypeDetailFormulaBuilder().build(),
      units: 'percentage',
      family: 'general',
      max_decimals: 2,
    };
  }

  formula(formula: MetricTableCalculationTypeDetailFormula): MetricTableCalculationTypeDetailBuilder {
    this._metricTableCalculationTypeDetail.formula = formula;
    return this;
  }

  units(units: string): MetricTableCalculationTypeDetailBuilder {
    this._metricTableCalculationTypeDetail.units = units;
    return this;
  }

  family(family: string): MetricTableCalculationTypeDetailBuilder {
    this._metricTableCalculationTypeDetail.family = family;
    return this;
  }

  maxDecimals(decimalValue: number | string): MetricTableCalculationTypeDetailBuilder {
    this._metricTableCalculationTypeDetail.max_decimals = decimalValue;
    return this;
  }

  build(): MetricTableCalculationTypeDetail {
    return this._metricTableCalculationTypeDetail;
  }
}
