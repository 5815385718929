import { ValueDefinitionGroup } from '../models';
import { MetricTableGroup } from '../metric-structure/models';

export class MetricTableGroupBuilder {
  private readonly _metricTableGroup: MetricTableGroup;

  constructor() {
    this._metricTableGroup = {
      id: 'id',
      title: 'Test Table',
      table_id: 'table_id',
      metric_id: 'metric_id',
      position: 1,
      valueDefinitionGroups: [],
      active: true,
    };
  }

  id(id: string): MetricTableGroupBuilder {
    this._metricTableGroup.id = id;
    return this;
  }

  tableId(tableId: string): MetricTableGroupBuilder {
    this._metricTableGroup.table_id = tableId;
    return this;
  }

  metricId(metricId: string): MetricTableGroupBuilder {
    this._metricTableGroup.metric_id = metricId;
    return this;
  }

  position(position: number): MetricTableGroupBuilder {
    this._metricTableGroup.position = position;
    return this;
  }

  valueDefinitionGroups(valueDefinitionGroups: ValueDefinitionGroup[]): MetricTableGroupBuilder {
    this._metricTableGroup.valueDefinitionGroups = valueDefinitionGroups;
    return this;
  }

  active(active: boolean): MetricTableGroupBuilder {
    this._metricTableGroup.active = active;
    return this;
  }

  build(): MetricTableGroup {
    return this._metricTableGroup;
  }
}
