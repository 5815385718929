import { TopicCategory, TopicGroup } from '../models';
import { TopicGroupBuilder } from './topic-group-builder';

export class TopicCategoryBuilder {
  private readonly _topicCategory: TopicCategory;

  constructor() {
    this._topicCategory = {
      id: 'topic_category_id',
      code: 'topic_category_code',
      name: 'Topic Category One',
      slug: 'Topic-Category-One',
      topic_groups: [new TopicGroupBuilder().build()],
    };
  }

  name(name: string): TopicCategoryBuilder {
    this._topicCategory.name = name;
    return this;
  }

  topicGroups(topicGroups: TopicGroup[]): TopicCategoryBuilder {
    this._topicCategory.topic_groups = topicGroups;
    return this;
  }

  build(): TopicCategory {
    return this._topicCategory;
  }
}
