import {
  CollaboratorDataRequest,
  DataRequestSourceStatus,
  DataRequestType,
  DataRequestUserResponsibility,
} from '../models';
import { v4 as uuidv4 } from 'uuid';
import { faker } from '@faker-js/faker';

export class CollaboratorDataRequestBuilder {
  private readonly _collaboratorDataRequest: CollaboratorDataRequest;

  constructor() {
    this._collaboratorDataRequest = {
      name: faker.name.jobDescriptor(),
      status: DataRequestSourceStatus.IN_APPROVAL,
      responsibility: DataRequestUserResponsibility.REVIEWER,
      last_updated: new Date(),
      data_request_user_id: uuidv4(),
      data_request_id: uuidv4(),
      data_request_source_id: uuidv4(),
      prefill_frequency_code: faker.date.past().getFullYear().toString(),
      due_date: new Date(),
      frequency_code: faker.date.past().getFullYear().toString(),
      source: faker.name.jobDescriptor(),
      data_request_source_users: [],
      type: DataRequestType.REGULAR,
    };
  }

  name(name: string): CollaboratorDataRequestBuilder {
    this._collaboratorDataRequest.name = name;
    return this;
  }

  frequency_code(frequency_code: string): CollaboratorDataRequestBuilder {
    this._collaboratorDataRequest.frequency_code = frequency_code;
    return this;
  }

  due_date(due_date?: Date): CollaboratorDataRequestBuilder {
    this._collaboratorDataRequest.due_date = due_date;
    return this;
  }

  status(status: DataRequestSourceStatus): CollaboratorDataRequestBuilder {
    this._collaboratorDataRequest.status = status;
    return this;
  }

  responsibility(responsibility: DataRequestUserResponsibility): CollaboratorDataRequestBuilder {
    this._collaboratorDataRequest.responsibility = responsibility;
    return this;
  }

  type(type: DataRequestType): CollaboratorDataRequestBuilder {
    this._collaboratorDataRequest.type = type;
    return this;
  }

  build(): CollaboratorDataRequest {
    return this._collaboratorDataRequest;
  }
}
