import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../../services/common';
import { VGSetStatusContext } from '../../models';

@Pipe({
  name: 'updatedByGreenProject',
})
export class UpdatedByGreenProjectPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(updatedByUser: string, vgsStatusContext: VGSetStatusContext | undefined | null): string | undefined {
    return vgsStatusContext === VGSetStatusContext.imported_from_green_project
      ? this.translateService.instant('Green Project Automation')
      : updatedByUser;
  }
}
