import { Component, OnInit, Input } from '@angular/core';
import { Doc, ItemType, BaseValue, Presentation, DocumentTypeDetails } from '../../../models';
import { SearchService } from '../../../search';
import { ClientDocumentsService } from '../../../services/client';
import { ValueDefinitionTemplateType } from '../../../metric-structure/models';
import { Icon } from '../../../icons/icons.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'lib-data-formats-document',
  templateUrl: './data-formats-document.component.html',
  styleUrls: ['./data-formats-document.component.scss'],
})
export class DataFormatsDocumentComponent implements OnInit {
  @Input() value?: BaseValue<DocumentTypeDetails>;
  @Input() itemType: ItemType = ItemType.metrics_indicator;
  document?: Doc;
  documentIcon: Icon = '';
  docFileName: string = '';
  docFormat: string = '';
  docLoaded: boolean = false;
  docLinkPresentation: Presentation = Presentation.doclink;
  readonly eValueDefinitionTemplateType: typeof ValueDefinitionTemplateType = ValueDefinitionTemplateType;

  constructor(private documentService: ClientDocumentsService, private searchService: SearchService) {}

  ngOnInit(): void {
    this.getDocument();
  }

  public downloadDocument(): void {
    const documentContext = { itemType: this.itemType };
    if (this.document && this.value) {
      this.documentService.downloadDocumentByItemType(
        this.value,
        `${this.docFileName}.${this.docFormat}`,
        documentContext,
        this.document
      );
    }
  }

  private getDocument() {
    const documentContext = { itemType: this.itemType };
    if (this.value) {
      this.documentService
        .getDocumentMetaDataByItemType(
          this.itemType,
          this.documentService.getDocumentPayloadOptions(this.value, documentContext, this.docFileName)
        )
        .pipe(take(1))
        .subscribe((res) => {
          this.document = res.doc;
          this.docFileName = this.value?.type_details.display_name || res.doc_name;
          this.docFormat = res.doc_format;
          this.documentIcon = res.doc_format ? this.searchService.getFileFormatIcon(res.doc_format) : '';
          this.docLoaded = true;
        });
    }
  }
}
