import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChartComponent } from './chart/chart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { PipesModule } from '../pipes';

@NgModule({
  declarations: [ChartComponent],
  imports: [CommonModule, HighchartsChartModule, PipesModule],
  exports: [ChartComponent],
})
export class ChartsModule {}
