import { Value, ValueGroup } from '../../models';

export enum EmbedderFontColors {
  deleted = '98a2b3',
}

export enum EmbedderHighlightColors {
  deleted = 'eaecf0',
  none = '',
  standard = 'bbeddfd',
  updated = 'fbcc8e',
}

export enum EmbedderValueField {
  explanation = 'explanation',
  value = 'value',
}

export interface EmbedderValue {
  id: string;
  group?: Value[];
  metricId: string;
  table?: ValueGroup[];
  tableTotals?: ValueGroup;
  value?: Value;
}

export interface EmbedderValueId {
  embedderValue: EmbedderValue;
  field: EmbedderValueField | string;
  fiscalYear: string;
  metricId: string;
  sourceId: string;
  tableId?: string | null;
  valueDefinitionId?: string;
}
