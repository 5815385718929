import { DisplayIndicator, SectionDisplay } from '../models';

export class ReportSectionDisplayBuilder {
  private readonly _reportSectionDisplay: SectionDisplay;

  constructor() {
    this._reportSectionDisplay = {
      metametric: false,
      display_indicators: [
        {
          indicator_id: 'indicator id',
        },
      ],
      code: 'code',
      description: 'description',
    };
  }

  code(code: string): ReportSectionDisplayBuilder {
    this._reportSectionDisplay.code = code;
    return this;
  }

  metametric(metametric: boolean): ReportSectionDisplayBuilder {
    this._reportSectionDisplay.metametric = metametric;
    return this;
  }

  description(description: string): ReportSectionDisplayBuilder {
    this._reportSectionDisplay.description = description;
    return this;
  }

  displayIndicators(displayIndicators: DisplayIndicator[]): ReportSectionDisplayBuilder {
    this._reportSectionDisplay.display_indicators = displayIndicators;
    return this;
  }

  build(): SectionDisplay {
    return this._reportSectionDisplay;
  }
}
