import { ValueDefinitionDisplayType } from './ui';

export interface DisplayedValuePayload {
  value_definition_id?: string;
  is_checked?: boolean;
  overridden_label?: string | null;
  type?: ValueDefinitionDisplayType;
}

export interface DisplayedValuesPayload {
  displayed_values?: DisplayedValuePayload[];
}
