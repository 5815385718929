import { TemplateReportMapping } from '../models';

export class TemplateReportMappingBuilder {
  private readonly _templateReportVersion: TemplateReportMapping;

  constructor() {
    this._templateReportVersion = {
      id: 'templateReportMappingId',
      template_report_version_id: 'templateReportVersionId',
      metric_id: 'metricId',
      value_definition_id: 'valueDefinitionId',
      value_definition_group_id: 'valueDefinitionGroupId',
      from_template_report_version_id: 'fromTemplateReportVersionId',
      from_metric_id: 'fromMetricId',
      from_value_definition_id: 'fromvalueDefinitionId',
      from_value_definition_group_id: 'fromValueDefinitionGroupId',
      note: '',
    };
  }

  public id(id: string): TemplateReportMappingBuilder {
    this._templateReportVersion.id = id;
    return this;
  }

  public templateReportVersionId(templateReportVersionId: string): TemplateReportMappingBuilder {
    this._templateReportVersion.template_report_version_id = templateReportVersionId;
    return this;
  }

  public metricId(metricId: string): TemplateReportMappingBuilder {
    this._templateReportVersion.metric_id = metricId;
    return this;
  }

  public valueDefinitionId(valueDefinitionId: string): TemplateReportMappingBuilder {
    this._templateReportVersion.value_definition_id = valueDefinitionId;
    return this;
  }

  public valueDefinitionGroupId(valueDefinitionGroupId: string): TemplateReportMappingBuilder {
    this._templateReportVersion.value_definition_group_id = valueDefinitionGroupId;
    return this;
  }

  public fromTemplateReportVersionId(fromTemplateReportVersionId: string): TemplateReportMappingBuilder {
    this._templateReportVersion.from_template_report_version_id = fromTemplateReportVersionId;
    return this;
  }

  public fromMetricId(fromMetricId: string): TemplateReportMappingBuilder {
    this._templateReportVersion.from_metric_id = fromMetricId;
    return this;
  }

  public fromValueDefinitionId(fromValueDefinitionId: string): TemplateReportMappingBuilder {
    this._templateReportVersion.from_value_definition_id = fromValueDefinitionId;
    return this;
  }

  public fromValueDefinitionGroupId(fromValueDefinitionGroupId: string): TemplateReportMappingBuilder {
    this._templateReportVersion.from_value_definition_group_id = fromValueDefinitionGroupId;
    return this;
  }

  public note(note: string): TemplateReportMappingBuilder {
    this._templateReportVersion.note = note;
    return this;
  }

  public build(): TemplateReportMapping {
    return this._templateReportVersion;
  }
}
