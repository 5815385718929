<div class="panel-properties-content-header mh-2">
  <div class="panel-properties-content-header-title fx-row justify-space-between w-100">
    <div class="fx-row justify-center align-center">
      <mat-icon [svgIcon]="panelTabAction?.icon ?? ''" class="mr-2"></mat-icon>
      <h4 class="mat-h4">{{ panelTabAction?.title }}</h4>
    </div>
  </div>
  <div class="panel-properties-content-header-actions"></div>
</div>
<ng-container *ngIf="selectedItem$ | async as selectedItem">
  <div class="panel-properties-content-container ml-2 fx-col" *ngIf="fieldInformationForm; else loadingForm">
    <div class="fields-container pb-1">
      <ng-container *ngIf="showDetails">
        <div class="formfield tip large">
          <div class="fx-row align-center">
            <mat-icon svgIcon="info" class="tip-icon color-grey-800 mr-2"></mat-icon>
          </div>
          <div class="text tip-text tip-text-dark mat-body color-grey-900 pl-3">
            {{
              "These associations have been configured by Novisto and are not editable. Related metrics display in Recommendations and Standard Codes display in the Guidance panel of your metric"
                | translate
            }}
          </div>
        </div>

        <div class="grid grid-2 grid-auto-rows mt-5">
          <div class="color-grey-800">{{ "Related to" | translate }}</div>
          <div class="color-grey-900">
            <button
              lib-button
              type="primary"
              class="btn-xs mr-1 mb-1"
              [disabled]="related_metric.source === eRelatedMetricSource.core"
              *ngFor="let related_metric of metric?.related_metrics | filter : filterRefRelatedMetrics : selectedItem"
            >
              {{ related_metric.equivalent_metric.code }}
            </button>
          </div>
        </div>

        <div class="grid grid-2 grid-auto-rows mt-5">
          <div class="color-grey-800">{{ "Compatible with" | translate }}</div>
          <div class="color-grey-900">
            <button
              lib-button
              type="stroked"
              class="btn-xs mr-1 mb-1"
              [disabled]="true"
              *ngFor="let standard_code of valueDefinitionStandardCodes"
            >
              <span>{{ standard_code.code }}</span>
            </button>
          </div>
        </div>

        <mat-divider *ngIf="showRelatedMetricsForm || showStandardCodesForm" class="mv-5"></mat-divider>
      </ng-container>

      <ng-container>
        <div>
          <h4 class="color-grey-800 m-0">{{ "Field ID" | translate }}</h4>
          <p class="caption5">{{ detailsInfo?.id }}</p>
        </div>
        <div *ngIf="detailsInfo?.coreVdId">
          <h4 class="color-grey-800 m-0">{{ "Core Field ID" | translate }}</h4>
          <p class="caption5">{{ detailsInfo?.coreVdId }}</p>
        </div>
        <div *ngIf="detailsInfo?.fieldPosition">
          <h4 class="color-grey-800 m-0">{{ "Field Position" | translate }}</h4>
          <p class="caption5">{{ detailsInfo?.fieldPosition }}</p>
        </div>
      </ng-container>

      <ng-container *libFeatureFlag="{ featureFlags: ['enable_ref_metrics_v2'] }">
        <ng-container *ngIf="metric?.reference_v2">
          <mat-divider class="mv-5"></mat-divider>

          <lib-rich-text-input
            [control]="fieldInformationForm.controls.technicalProtocol"
            [hint]="
              'Enter your organization’s internal standards or specifications. This is visible to all users when viewing the metric, including request participants.'
                | translate
            "
            [label]="'Field Technical Protocol' | translate"
            [enableSourceCodeOption]="true"
          ></lib-rich-text-input>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="showRelatedMetricsForm || showStandardCodesForm">
        <ng-container *ngIf="!isAdmin">
          <div class="formfield large tag color-grey-800 mt-5 mb-2">
            {{ "Add your associations" | translate }}
          </div>

          <lib-instructional-text>
            {{ "You can also add or remove your own associations" | translate }}
          </lib-instructional-text>
        </ng-container>

        <div *ngIf="showRelatedMetricsForm" class="mt-5">
          <lib-multi-select-chip-input
            class="large readonly"
            [label]="'Related metrics' | translate"
            [hint]="'Start typing to see metrics' | translate"
            [control]="fieldInformationForm.controls.relatedMetrics"
            [options]="metricOptions"
            bindLabel="title"
            compareWith="id"
            (filterValueChanged)="updateMetricsOptions($event)"
            [forceControlValue]="true"
          >
          </lib-multi-select-chip-input>
        </div>
        <div *ngIf="showStandardCodesForm" class="mt-5">
          <lib-multi-select-chip-input
            class="large readonly mt-5"
            [label]="'Compatible standard codes' | translate"
            [hint]="'Start typing to see standard codes' | translate"
            [control]="fieldInformationForm.controls.standardCodes"
            [options]="standardCodesOptions"
            (filterValueChanged)="searchStandardCodes($event)"
            bindLabel="title"
            compareWith="id"
            [forceControlValue]="true"
          >
          </lib-multi-select-chip-input>
        </div>
      </ng-container>
    </div>
    <div>
      <mat-divider class="pb-4"></mat-divider>

      <div class="form-submit">
        <button
          lib-button
          type="success"
          [disabled]="fieldInformationForm.pristine || fieldInformationForm.invalid"
          [isLoading]="!!(updating$ | async)"
          (click)="saveProperties(selectedItem)"
          [label]="'Save' | translate"
          class="mr-4"
        ></button>
        <button lib-button type="link" (click)="closeTab()">{{ "Cancel" | translate }}</button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingForm>
  <div class="m-4">
    <lib-dummy [presentation]="ePresentation.form" [numberOfDummies]="6"></lib-dummy>
  </div>
</ng-template>
