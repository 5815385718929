import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Observable, Subscription, forkJoin, map, of } from 'rxjs';
import { combineLatestWith } from 'rxjs/operators';
import { ActionItemUtils } from '../classes';
import { DataTableExpandedRowConfig, DataTablePaginatorConfiguration } from '../data-table';
import {
  ActionItem,
  ChipGroupItem,
  FilterBarOption,
  FilterBarSelection,
  FilterType,
  Framework,
  Indicator,
  IndicatorAction,
  IndicatorRelatedMetrics,
  ItemType,
  Metric,
  MetricCategory,
  MetricGroup,
  MetricSearchInitialSelection,
  MetricSearchSelection,
  Presentation,
  RelatedMetricsResourceType,
  ResourceType,
  SearchOptionFilters,
  SortFilter,
  StandardCodes,
  TableColumn,
  Tag,
  Topic,
  TopicCategory,
} from '../models';
import { ClientCoreService, ClientMetricGroupsService, ClientTagsService } from '../services/client';
import { TranslateService } from '../services/common';
import { MetricsIndicatorCategories, PartialMetricsMetricSort } from '../translations';
import { MetricSearchStateService } from './metric-search-state.service';
import { MetricSearchValueDefinitionsComponent } from './metric-search-value-definitions/metric-search-value-definitions.component';

type IndicatorRow = Pick<Indicator, 'id' | 'code' | 'description' | 'topics'> & {
  action: Indicator;
  topics_string: string;
  related_to: string;
  compatible_with: string;
  selected: boolean;
};

interface Data {
  tableFilters: FilterBarOption[];
  isLoading: boolean;
  isLoadingNextPage: boolean;
  metrics: Indicator[];
  dataTablePaginationConfig: DataTablePaginatorConfiguration;
  selections: Record<string, boolean>;
  topicsString?: string;
  allDataLoaded?: boolean;
}

@Component({
  selector: 'lib-metric-search',
  templateUrl: './metric-search.component.html',
  styleUrls: ['./metric-search.component.scss'],
  providers: [MetricSearchStateService],
})
export class MetricSearchComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild('actionCell', { static: true }) actionCell?: TemplateRef<unknown>;
  @ViewChild('selectedCell', { static: true }) selectedCell?: TemplateRef<unknown>;
  @ViewChild('topicsCell', { static: true }) topicsCell?: TemplateRef<unknown>;
  @ViewChild('relatedToCell', { static: true }) relatedToCell?: TemplateRef<unknown>;
  @ViewChild('compatibleWithCell', { static: true }) compatibleWithCell?: TemplateRef<unknown>;

  @Input() withInfiniteScroll: boolean = false;
  @Input() actions: ActionItem[] = [];

  @Input() initialSelections?: MetricSearchInitialSelection;
  @Input() enableValueDefinitionSelection: boolean = false;
  @Input() defaultSortFilter: SortFilter.description | SortFilter.start = SortFilter.start;
  @Input() isCheckable: boolean = true;
  @Input() withQuestionsSyntax: boolean = false;
  @Input() withTopicFilter: boolean = true;
  @Input() withCategoryFilter: boolean = true;
  @Input() withStandardCodeFilter: boolean = true;
  @Input() withTagFilter: boolean = true;
  @Input() withMetricGroupFilter: boolean = true;
  @Input() withSort: boolean = false;
  @Input() withRelatedToColumn: boolean = true;
  @Input() showRowSelection: boolean = false;
  @Input() filters: SearchOptionFilters = {};

  @Output() selectionChange = new EventEmitter<MetricSearchSelection[]>();
  @Output() indicatorClick = new EventEmitter<Metric>();
  @Output() relatedMetricClick = new EventEmitter<ActionItem<Indicator | StandardCodes>>();
  @Output() actionClick = new EventEmitter<IndicatorAction>();

  public readonly expandedRowConfig?: DataTableExpandedRowConfig = {
    component: MetricSearchValueDefinitionsComponent,
    componentProperties: [{ inputName: 'metricId', rowPropertyName: 'metric_id' }],
  };

  tableFilters$?: Observable<FilterBarOption[]>;
  isLoading$: Observable<boolean> = this.metricSearchStateService.isLoading$;
  isLoadingNextPage$: Observable<boolean> = this.metricSearchStateService.isLoadingNextPage$;
  metrics$: Observable<Indicator[]> = this.metricSearchStateService.metrics$;
  selections$: Observable<Record<string, boolean>> = this.metricSearchStateService.selections$;
  dataTablePaginationConfig$: Observable<DataTablePaginatorConfiguration> =
    this.metricSearchStateService.dataTablePaginationConfig$;
  allDataLoaded$: Observable<boolean> = this.metricSearchStateService.allDataLoaded$;

  data$?: Observable<Data>;

  metricTableColumns: TableColumn<IndicatorRow>[] = [];

  _selectedIndicators: Indicator[] = [];

  emptyIndicatorRelatedMetrics: IndicatorRelatedMetrics = [];

  readonly ePresentation = Presentation;

  private selectionSubscription?: Subscription;

  constructor(
    private translateService: TranslateService,
    private clientCoreService: ClientCoreService,
    private tagsService: ClientTagsService,
    private clientMetricGroupsService: ClientMetricGroupsService,
    private metricSearchStateService: MetricSearchStateService
  ) {}

  public ngOnInit(): void {
    const tags$ = this.withTagFilter
      ? this.tagsService.list().pipe(map((res) => ActionItemUtils.resourcesToActionItem(res.data, ResourceType.tag)))
      : of([]);
    const topics$ = this.withTopicFilter
      ? this.clientCoreService
          .listTopicCategories()
          .pipe(map((res) => ActionItemUtils.resourcesToActionItem(res.data, ResourceType.topic)))
      : of([]);
    const standardCodes$ = this.withStandardCodeFilter
      ? this.clientCoreService
          .listFrameworks('standard_code')
          .pipe(map((res) => ActionItemUtils.resourcesToActionItem(res.data, ResourceType.standard_codes)))
      : of([]);
    const groups$ = this.withMetricGroupFilter
      ? this.clientMetricGroupsService
          .listMetricGroups()
          .pipe(map((res) => ActionItemUtils.resourcesToActionItem(res.data, ResourceType.metric_group)))
      : of([]);

    this.tableFilters$ = forkJoin([topics$, standardCodes$, tags$, groups$]).pipe(
      map(([topics, standardCodes, tags, groups]) => this.getFilterOptions(topics, standardCodes, tags, groups))
    );

    this.data$ = this.tableFilters$.pipe(
      combineLatestWith(
        this.isLoading$,
        this.isLoadingNextPage$,
        this.metrics$,
        this.dataTablePaginationConfig$,
        this.selections$,
        this.allDataLoaded$
      ),
      map(
        ([
          tableFilters,
          isLoading,
          isLoadingNextPage,
          metrics,
          dataTablePaginationConfig,
          selections,
          allDataLoaded,
        ]) => ({
          tableFilters,
          isLoading,
          isLoadingNextPage,
          metrics: this.addTopicsStringToMetrics(metrics),
          dataTablePaginationConfig,
          selections,
          allDataLoaded,
        })
      )
    );

    this.metricSearchStateService.initialize(
      this.withInfiniteScroll,
      {
        id: this.defaultSortFilter,
        title: this.defaultSortFilter === SortFilter.start ? 'Last updated' : 'Alphabetical',
      },
      this.filters,
      this.initialSelections
    );
    this.metricSearchStateService.loadMetrics();
    this.selectionSubscription = this.metricSearchStateService.selections$.subscribe(() => {
      this.selectionChange.emit(this.metricSearchStateService.getSelections());
    });
  }

  public ngAfterContentInit(): void {
    this.setupColumns();
  }

  public ngOnDestroy(): void {
    this.selectionSubscription?.unsubscribe();
  }

  public get selectedIndicators(): Indicator[] {
    return this._selectedIndicators;
  }

  public get selections(): MetricSearchSelection[] {
    return this.metricSearchStateService.getSelections();
  }

  public onSearchChange(searchQuery: string): void {
    this.metricSearchStateService.onSearchChange(searchQuery);
  }

  public onFilterChange(options: FilterBarSelection[]): void {
    this.metricSearchStateService.onFilterChange(options);
  }

  public onPageChange(event?: PageEvent): void {
    this.metricSearchStateService.onPageChange(event);
  }

  public getRelatedMetricsActionItem(indicator: Indicator): ActionItem<Indicator> {
    indicator = { ...indicator, standard_codes: { field_level: [], metric_level: [] } };
    return {
      id: indicator.id,
      title: indicator.description,
      item: indicator,
      item_type: ItemType.metrics_indicator,
    };
  }

  public getCompatibleMetricsActionItem(indicator: Indicator): ActionItem<Indicator> {
    indicator = { ...indicator, related_metrics: this.emptyIndicatorRelatedMetrics };
    return {
      id: indicator.id,
      title: indicator.description,
      item: indicator,
      item_type: ItemType.metrics_indicator,
    };
  }

  public onChipsClick(action: ActionItem<Indicator | StandardCodes>): void {
    switch (action.id) {
      case RelatedMetricsResourceType.standard_code:
      case RelatedMetricsResourceType.core_metric:
        this.relatedMetricClick.emit(action);
        break;
      case RelatedMetricsResourceType.imported_metric:
        const metric = action.item as Metric;
        if (metric.indicator_id) {
          if (metric.category === MetricCategory.THIRD_PARTY) {
            this.relatedMetricClick.emit(action);
          } else {
            this.indicatorClick.emit(metric);
          }
        }
        break;
      default:
        break;
    }
  }

  public onCheckChanged(metrics: Indicator[], selection?: string, all?: boolean) {
    if (typeof all === 'boolean') {
      this._selectedIndicators = all ? metrics : [];
      metrics.forEach((i) => this.metricSearchStateService.handleSelection(i.id, { force: all }));
    } else if (selection) {
      const selected = this.metricSearchStateService.handleSelection(selection, {
        fetchValueDefinitions: this.enableValueDefinitionSelection,
      });
      const indicator = metrics.find((i) => i.metric_id === selection);
      if (indicator && selected) {
        this._selectedIndicators.push(indicator);
      } else if (!selected) {
        this._selectedIndicators = this._selectedIndicators.filter((i) => i !== indicator);
      }
    }
  }

  public onSelectionChanged(metric: Metric): void {
    this.metricSearchStateService.handleSelection(metric.id, { singleSelection: true });
    this.indicatorClick.emit(metric);
  }

  public topicToChip(topic: Topic): ChipGroupItem {
    return { outline: false, text: topic.name, variant: 'light' };
  }

  public stopDefaultClickAction(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public refresh(): void {
    this.metricSearchStateService.refresh();
  }

  private addTopicsStringToMetrics(metrics: Indicator[]): Indicator[] {
    metrics.map((metric) => {
      metric.topicsString = metric.topics?.map((topic) => topic.name).join(', ');
      return metric;
    });
    return metrics;
  }

  private setupColumns(): void {
    this.metricTableColumns = [
      {
        name: this.translateService.instant(this.withQuestionsSyntax ? 'Question Number' : 'Metric Code'),
        dataKey: 'code',
        width: '10%',
      },
      {
        name: this.translateService.instant(this.withQuestionsSyntax ? 'Question' : 'Metric Description'),
        dataKey: 'description',
        maxCharCount: 50,
      },
    ];

    if (this.withTopicFilter) {
      this.metricTableColumns.push({
        name: this.translateService.instant('Topics'),
        dataKey: 'topicsString',
        width: '20%',
        maxCharCount: 40,
      });
    }

    if (this.withRelatedToColumn) {
      this.metricTableColumns.push({
        name: this.translateService.instant('Related To'),
        dataKey: 'relatedTo',
        cellTemplate: this.relatedToCell,
        width: '15%',
        maxCharCount: 40,
      });
    }

    if (this.withStandardCodeFilter) {
      this.metricTableColumns.push({
        name: this.translateService.instant('Standard Code'),
        dataKey: 'compatibleWith',
        cellTemplate: this.compatibleWithCell,
        width: '20%',
        maxCharCount: 40,
      });
    }

    if (this.actions.length) {
      this.metricTableColumns.push({
        name: this.translateService.instant('Actions'),
        dataKey: 'action',
        cellTemplate: this.actionCell,
        width: '5%',
        noHeader: true,
      });
    }
  }

  private getFilterOptions(
    topics: ActionItem<TopicCategory>[],
    standardCodes: ActionItem<Framework>[],
    tags: ActionItem<Tag>[],
    groups: ActionItem<MetricGroup>[]
  ): FilterBarOption[] {
    const filters: FilterBarOption[] = [];

    if (this.withTopicFilter) {
      filters.push({
        title: this.translateService.instant('Topic'),
        id: ResourceType.topic,
        optionType: FilterType.searchList,
        displayAll: true,
        options: topics,
      });
    }

    if (this.withCategoryFilter) {
      filters.push({
        title: this.translateService.instant('Category'),
        id: ResourceType.category,
        optionType: FilterType.list,
        displayAll: true,
        options: this.translateService.listResources(MetricsIndicatorCategories),
      });
    }

    if (this.withStandardCodeFilter) {
      filters.push({
        title: this.translateService.instant('Standard Code'),
        id: ResourceType.standard_codes,
        optionType: FilterType.searchList,
        displayAll: true,
        options: standardCodes,
      });
    }

    if (this.withTagFilter) {
      filters.push({
        title: this.translateService.instant('Tags'),
        id: ResourceType.tag,
        optionType: FilterType.searchList,
        displayAll: true,
        options: tags,
      });
    }

    if (this.withMetricGroupFilter) {
      filters.push({
        title: this.translateService.instant('Group'),
        id: 'metric_groups',
        optionType: FilterType.list,
        displayAll: true,
        options: groups,
      });
    }

    if (this.withSort) {
      filters.push({
        icon: 'sort',
        title: this.translateService.instant('Sort'),
        id: ResourceType.sort,
        optionType: FilterType.list,
        displayAll: false,
        options: this.translateService.listResources(PartialMetricsMetricSort),
        defaultValue: this.defaultSortFilter === SortFilter.start ? 'Last updated' : 'Alphabetical',
      });
    }

    return filters;
  }

  onAction(action: ActionItem, indicator: Indicator) {
    this.actionClick.emit({ action_id: action.id, indicator_id: indicator.id });
  }
}
