import { Injectable } from '@angular/core';
import { ApiResponse, ApplicationApiDefinition, DataRequestIndicator } from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UpsertValueGroupSet } from '../../../metric-editor-form';
import { BaseClientDataRequestIndicatorsService } from '../client-data-request-indicator/base-client-data-request-indicator';
import { HttpContext } from '@angular/common/http';
import { BYPASS_INTERCEPTOR_ERROR_MANAGING, BYPASS_PUBLIC_INTERCEPTOR_ERROR_MANAGING } from '../../../interceptors';

@Injectable()
export class ClientPublicDataRequestIndicatorService extends BaseClientDataRequestIndicatorsService {
  apiName: keyof ApplicationApiDefinition = 'public';
  resource: string;
  servicePath: string;
  resourcePrefix: string = '';
  dataRequestId: string = '';
  dataRequestSourceId: string = '';

  private _isInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isInitialized$: Observable<boolean>;

  constructor(private apiService: ApiService) {
    super();

    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.public.resources.dataRequestCollab;
    this.isInitialized$ = this._isInitialized$.asObservable();
  }

  private initializationCheck(): void {
    if (!this.dataRequestId || !this.dataRequestSourceId || !this._isInitialized$.getValue()) {
      throw new Error('This service needs to be initialized before being used.');
    }
  }

  public initialize(dataRequestId: string, dataRequestSourceId: string): void {
    this.dataRequestId = dataRequestId;
    this.dataRequestSourceId = dataRequestSourceId;
    this.resourcePrefix = `${this.servicePath}${this.resource}/data_requests/${this.dataRequestId}/data_request_sources/${this.dataRequestSourceId}`;
    this._isInitialized$.next(true);
  }

  public getDataRequestIndicator(
    dataRequestIndicatorId: string,
    dataRequestId?: string,
    businessUnitId?: string
  ): Observable<ApiResponse<DataRequestIndicator>> {
    return this.apiService.get(
      `${this.resourcePrefix}/data_request_indicators/${dataRequestIndicatorId}/business_units/${
        businessUnitId as string
      }`
    );
  }

  public upsertDataRequestIndicatorValues(
    dataRequestIndicatorId: string,
    upsertValueGroupSet: UpsertValueGroupSet
  ): Observable<ApiResponse<DataRequestIndicator>> {
    this.initializationCheck();

    return this.apiService.put(
      `${this.resourcePrefix}/data_request_indicators/${dataRequestIndicatorId}/values`,
      upsertValueGroupSet,
      undefined,
      undefined,
      new HttpContext().set(BYPASS_PUBLIC_INTERCEPTOR_ERROR_MANAGING, true).set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true)
    );
  }

  public moveDataRequestIndicatorValueGroup(
    indicatorId: string,
    valueGroupSetId: string,
    valueGroupId: string,
    position: number
  ): Observable<ApiResponse<DataRequestIndicator>> {
    this.initializationCheck();

    return this.apiService.post(
      `${this.resourcePrefix}/data_request_indicators/${indicatorId}/value_group_sets/${valueGroupSetId}/value_groups/${valueGroupId}/move/${position}`,
      {}
    );
  }

  public deleteDataRequestIndicatorValue(
    indicatorId: string,
    valueGroupSetId: string,
    valueGroupId: string,
    valueId: string
  ): Observable<ApiResponse<DataRequestIndicator>> {
    this.initializationCheck();

    return this.apiService.delete(
      `${this.resourcePrefix}/data_request_indicators/${indicatorId}/value_group_sets/${valueGroupSetId}/value_groups/${valueGroupId}/values/${valueId}`
    );
  }

  public getCollectIndicatorInDataRequest(
    dataRequestId: string,
    dataRequestSourceId: string,
    indicatorId: string,
    frequencyCode: string
  ): Observable<ApiResponse<DataRequestIndicator>> {
    this.initializationCheck();

    return this.apiService.get(`${this.resourcePrefix}/indicators/${indicatorId}/collect/frequencies/${frequencyCode}`);
  }

  public deleteDataRequestIndicatorValueGroup(
    indicatorId: string,
    valueGroupSetId: string,
    valueGroupId: string
  ): Observable<ApiResponse<DataRequestIndicator>> {
    this.initializationCheck();

    return this.apiService.delete(
      `${this.resourcePrefix}/data_request_indicators/${indicatorId}/value_group_sets/${valueGroupSetId}/value_groups/${valueGroupId}/data_request_indicators`
    );
  }
}
