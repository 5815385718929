import { Permission } from './configs';

export interface PermissionAttribute {
  permission?: Permission;
}

export interface TestsParamsPermissionTab extends PermissionAttribute {
  tab: string;
}

export interface TestsParamsPermissionSectionName extends PermissionAttribute {
  primarySectionName: string;
}

export interface TestsParamsPermissionSubSectionTitle extends PermissionAttribute {
  subSectionTitle: string;
}
