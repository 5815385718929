import { Dashboard, DashboardWidget } from '../../models';
import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';
import { EntityBuilder } from '../entity-builder';

export class DashboardBuilder extends EntityBuilder<Dashboard> {
  constructor() {
    super({
      id: uuidv4(),
      name: faker.name.jobDescriptor(),
      created_by: uuidv4(),
      is_private: true,
      is_default: false,
      widgets: [],
    });
  }

  isPrivate(isPrivate: boolean): DashboardBuilder {
    this._instance.is_private = isPrivate;
    return this;
  }

  is_default(isDefault: boolean): DashboardBuilder {
    this._instance.is_default = isDefault;
    return this;
  }

  widgets(widgets: DashboardWidget[]): DashboardBuilder {
    this._instance.widgets = widgets;
    return this;
  }

  created_by(userId: string): DashboardBuilder {
    this._instance.created_by = userId;
    return this;
  }

  build(): Dashboard {
    return this._instance;
  }
}
