import { Indicator, Section, SectionNode } from '../models';

export class ReportSectionNodeBuilder {
  private readonly _reportSectionNode: SectionNode;

  constructor() {
    this._reportSectionNode = {
      id: 1,
      children: [],
      display_items: [],
      section_parent_id: 'sec_1',
      section_position: 1,
      section_id: undefined,
      item: {
        indicator: undefined,
      },
      isExpanded: false,
      loading: false,
    };
  }

  item(item: Indicator): ReportSectionNodeBuilder {
    this._reportSectionNode.item.indicator = item;
    return this;
  }

  section(section: Section): ReportSectionNodeBuilder {
    this._reportSectionNode.item = section;
    this._reportSectionNode.section_id = section.id;
    return this;
  }

  isExpanded(isExpanded: boolean): ReportSectionNodeBuilder {
    this._reportSectionNode.isExpanded = isExpanded;
    return this;
  }

  loading(isLoading?: boolean): ReportSectionNodeBuilder {
    this._reportSectionNode.loading = isLoading;
    return this;
  }

  build(): SectionNode {
    return this._reportSectionNode;
  }
}
