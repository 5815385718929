export const ENGLISH_THOUSAND_SEPARATOR = ',';
export const ENGLISH_DECIMAL_MARKER = '.';

export const FRENCH_THOUSAND_SEPARATOR = ' ';
export const FRENCH_DECIMAL_MARKER = ',';

export type DecimalMarker = '.' | ',' | ['.', ','];
export interface NumberMaskConfig {
  thousandSeparator: string;
  decimalMarker: DecimalMarker;
}
