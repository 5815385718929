import { PublicDocUploadedFileMetaData } from '../models';

export class PublicDocUploadedFileMetaDataBuilder {
  private readonly _document: PublicDocUploadedFileMetaData;

  constructor() {
    this._document = {
      id: 'doc_1',
      container: 'documents',
      file_hash: 'qweeretrytuytiuiuoioiipvfcxbgf',
      extension: 'pdf',
      meta: {
        business_unit_ids: [],
        category: '',
        data_period_end: '',
        data_period_start: '',
        doc_type: '',
        fiscal_year: '2022',
        name: 'document_one',
        org_id: 'org_id',
        public: true,
        scope: 'client',
        tag_ids: [],
      },
      original_filename: 'document_one.pdf',
    };
  }

  id(id: string): PublicDocUploadedFileMetaDataBuilder {
    this._document.id = id;
    return this;
  }

  name(name: string): PublicDocUploadedFileMetaDataBuilder {
    this._document.meta.name = name;
    return this;
  }

  extension(extension: string): PublicDocUploadedFileMetaDataBuilder {
    this._document.extension = extension;
    return this;
  }

  build(): PublicDocUploadedFileMetaData {
    return this._document;
  }
}
