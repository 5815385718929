import { Pipe, PipeTransform } from '@angular/core';
import { FileDocumentInterface } from '../../../models';

@Pipe({
  name: 'findFileDocument',
})
export class FindFileDocumentPipe implements PipeTransform {
  transform(fileId: string, fileDocumentsList: FileDocumentInterface[]): FileDocumentInterface | undefined {
    return fileDocumentsList.find((doc) => doc.id === fileId);
  }
}
