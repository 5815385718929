import {
  IndicatorRelatedMetrics,
  Industry,
  Metric,
  MetricCategory,
  MetricStandardCode,
  Topic,
  ValueDefinitionGroup,
} from '../models';

export class MetricBuilder {
  private readonly _metric: Metric;

  constructor() {
    this._metric = {
      id: 'metricId',
      name: 'name',
      active: true,
      category: MetricCategory.CUSTOM,
      start_year: 0,
      description: 'metric one',
      code: 'metric 001',
      indicator_id: 'indId',
      value_definition_groups: [],
      reference_v2: false,
      tags: [],
      start: '2022-03-01',
      updated_by: undefined,
    };
  }

  id(id: string): MetricBuilder {
    this._metric.id = id;
    return this;
  }

  indicatorId(indicatorId?: string): MetricBuilder {
    this._metric.indicator_id = indicatorId;
    return this;
  }

  name(name: string): MetricBuilder {
    this._metric.name = name;
    return this;
  }

  category(category: MetricCategory): MetricBuilder {
    this._metric.category = category;
    return this;
  }

  valueDefinitionGroups(valueDefinitionGroups: ValueDefinitionGroup[]): MetricBuilder {
    this._metric.value_definition_groups = valueDefinitionGroups;
    return this;
  }

  code(code: string): MetricBuilder {
    this._metric.code = code;
    return this;
  }

  guidance(guidance: string): MetricBuilder {
    this._metric.guidance = guidance;
    return this;
  }

  description(description: string): MetricBuilder {
    this._metric.description = description;
    return this;
  }

  topics(topics: Topic[]): MetricBuilder {
    this._metric.topics = topics;
    return this;
  }

  industries(industries: Industry[]): MetricBuilder {
    this._metric.industries = industries;
    return this;
  }

  relatedMetrics(relatedMetrics: IndicatorRelatedMetrics): MetricBuilder {
    this._metric.related_metrics = relatedMetrics;
    return this;
  }

  standardCodes(standardCodes: MetricStandardCode): MetricBuilder {
    this._metric.standard_codes = standardCodes;
    return this;
  }

  imported(imported: boolean): MetricBuilder {
    this._metric.imported = imported;
    return this;
  }

  start(start: string): MetricBuilder {
    this._metric.start = start;
    return this;
  }

  coreMetricId(coreMetricId: string): MetricBuilder {
    this._metric.core_metric_id = coreMetricId;
    return this;
  }

  updatedBy(updatedBy: string): MetricBuilder {
    this._metric.updated_by = updatedBy;
    return this;
  }

  referenceV2(reference_v2: boolean): MetricBuilder {
    this._metric.reference_v2 = reference_v2;
    return this;
  }

  build(): Metric {
    return this._metric;
  }
}
