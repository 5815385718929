import { Framework, Industry, Metric, StandardCodes, Taxonomy, Topic } from '../models';

export class StandardCodeBuilder {
  private readonly _standardCode: StandardCodes;

  constructor() {
    this._standardCode = {
      active: true,
      code: `framework_name a code`,
      created: '2022-03-05',
      created_by: 'test creator',
      description: 'a description',
      disclaimers: '',
      end: '2022-04-05',
      framework_id: 'framework_id',
      guidance: 'some guidance',
      id: 'test id',
      industries: [],
      start: '2022-03-05',
      tags: [],
      taxonomies: [],
      topics: [],
      metrics: [],
      updated: '2022-03-05',
      updated_by: 'Ginette Reno',
      name: 'A Standard Code',
    };
  }

  id(id: string): StandardCodeBuilder {
    this._standardCode.id = id;
    return this;
  }

  active(value: boolean): StandardCodeBuilder {
    this._standardCode.active = value;
    return this;
  }

  frameworkId(id: string): StandardCodeBuilder {
    this._standardCode.framework_id = id;
    return this;
  }

  framework(framework: Framework): StandardCodeBuilder {
    this._standardCode.framework = framework;
    return this;
  }

  taxonomies(taxonomies: Taxonomy[]): StandardCodeBuilder {
    this._standardCode.taxonomies = taxonomies;
    return this;
  }

  topics(topics: Topic[]): StandardCodeBuilder {
    this._standardCode.topics = topics;
    return this;
  }

  industry(industries: Industry[]): StandardCodeBuilder {
    this._standardCode.industries = industries;
    return this;
  }

  metrics(metrics: Metric[]): StandardCodeBuilder {
    this._standardCode.metrics = metrics;
    return this;
  }

  start(start: string): StandardCodeBuilder {
    this._standardCode.start = start;
    return this;
  }

  end(end: string): StandardCodeBuilder {
    this._standardCode.end = end;
    return this;
  }

  code(code: string): StandardCodeBuilder {
    this._standardCode.code = code;
    return this;
  }

  description(description: string): StandardCodeBuilder {
    this._standardCode.description = description;
    return this;
  }

  guidance(guidance: string): StandardCodeBuilder {
    this._standardCode.guidance = guidance;
    return this;
  }

  disclaimers(disclaimers: string): StandardCodeBuilder {
    this._standardCode.disclaimers = disclaimers;
    return this;
  }

  build(): StandardCodes {
    return this._standardCode;
  }
}
