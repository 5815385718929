import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common/api/api.service';
import { ApiResponse, ApplicationApiDefinition, Doc, ItemType, SearchOptions } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class AdminResourcesService {
  apiName: keyof ApplicationApiDefinition = 'search';
  resource: string;
  servicePath: string;

  constructor(private baseService: BaseService, private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.search.resources.resources;
  }

  payloadFromSearchOptions(searchOptions: SearchOptions): any {
    if (!searchOptions.custom_filters) {
      searchOptions.custom_filters = {};
    }
    searchOptions.filters = this.baseService.renameObjectProperty(searchOptions.filters, 'source', 'org_id');
    switch (searchOptions.item_type) {
      case ItemType.library_peers_report:
        searchOptions.custom_filters.scope = 'report';
        break;
      default:
        break;
    }
    return this.baseService.esPayloadFromSearchOptions(searchOptions);
  }

  search(searchOptions: SearchOptions): Observable<ApiResponse<Doc[]>> {
    const payload: any = this.payloadFromSearchOptions(searchOptions);
    return this.apiService.post(`${this.servicePath}${this.resource}/resources`, payload);
  }
}
