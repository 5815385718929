import { TemplateReportHierarchySummary } from '../models';
import { Builder } from './builder';
import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';

export class TemplateReportHierarchySummaryBuilder extends Builder<TemplateReportHierarchySummary> {
  constructor() {
    super({
      section_id: uuidv4(),
      indicators_ids: faker.helpers.arrayElement(),
    });
  }

  sectionId(sectionId: string): TemplateReportHierarchySummaryBuilder {
    this._instance.section_id = sectionId;
    return this;
  }

  indicatorIds(indicatorIds: string[]): TemplateReportHierarchySummaryBuilder {
    this._instance.indicators_ids = indicatorIds;
    return this;
  }
}
