import { Pipe, PipeTransform } from '@angular/core';
import { Value, ValueGroup } from '../../models';

@Pipe({
  name: 'formatFieldUniqueId',
})
export class FormatFieldUniqueIdPipe implements PipeTransform {
  transform(group: ValueGroup, value: Value): string {
    return `${group.value_definition_group_id}-${group.position}-${group.subposition ?? 1}-${
      value.value_definition_id
    }-${value.position}`;
  }
}
