import { FileTypeDetailsV2 } from '../models';

export class FileTypeDetailsV2Builder {
  private readonly _fileTypeDetails: FileTypeDetailsV2;

  constructor() {
    this._fileTypeDetails = {
      display_explanation: true,
      display_page_number: true,
      display_url: true,
      explanation_label: 'Explanation',
      explanation_required: true,
      max_files: 2,
      multiple: true,
      page_number_required: true,
      url_required: true,
    };
  }

  displayExplanation(displayExplanation: boolean): FileTypeDetailsV2Builder {
    this._fileTypeDetails.display_explanation = displayExplanation;
    return this;
  }

  displayPageNumber(displayPageNumber: boolean): FileTypeDetailsV2Builder {
    this._fileTypeDetails.display_page_number = displayPageNumber;
    return this;
  }

  displayUrl(displayUrl: boolean): FileTypeDetailsV2Builder {
    this._fileTypeDetails.display_url = displayUrl;
    return this;
  }

  explanationLabel(explanationLabel: string): FileTypeDetailsV2Builder {
    this._fileTypeDetails.explanation_label = explanationLabel;
    return this;
  }

  build(): FileTypeDetailsV2 {
    return this._fileTypeDetails;
  }
}
