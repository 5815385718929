import { Injectable } from '@angular/core';
import { ApiResponse, ApplicationApiDefinition, Org } from '../../../models';
import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { BaseClientPeersService } from './base-client-peers.service';

@Injectable({
  providedIn: 'root',
})
export class ClientPublicPeersService extends BaseClientPeersService {
  apiName: keyof ApplicationApiDefinition = 'public';
  resource: string;
  servicePath: string;

  constructor(private baseService: BaseService, private apiService: ApiService) {
    super();

    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.public.resources.platform;
  }

  public listFavoriteOrgs(industry_id?: string, name?: string): Observable<ApiResponse<Org[]>> {
    let params = new HttpParams();
    if (industry_id) {
      params = params.append('industry_ids', industry_id);
    }
    if (name) {
      params = params.append('search_term', name);
    }

    return this.apiService.get(`${this.servicePath}${this.resource}/favorite_orgs`, { params });
  }
}
