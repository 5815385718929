import {
  DEFAULT_METRIC_STRUCTURE_COLUMNS,
  ExportFormat,
  ExportMetricsStructureCurrentViewPayload,
  SearchIndicatorPayload,
} from '../models';
import { formatDate } from '@angular/common';
import { Builder } from './builder';
import { SearchIndicatorPayloadBuilder } from './search-indicator-payload-builder';

export class ExportMetricsStructureCurrentViewPayloadBuilder extends Builder<ExportMetricsStructureCurrentViewPayload> {
  constructor() {
    const currentDate: Date = new Date();
    super({
      file_name: `MetricLibrary - ${formatDate(currentDate, 'yyyy-MM-dd', 'en-CA')}`,
      output_format: ExportFormat.EXCEL,
      columns: DEFAULT_METRIC_STRUCTURE_COLUMNS,
      search_indicator_v1_request: new SearchIndicatorPayloadBuilder().build(),
    });
  }

  fileName(file_name: string): ExportMetricsStructureCurrentViewPayloadBuilder {
    this._instance.file_name = file_name;
    return this;
  }

  searchIndicatorV1Request(search_v1_request: SearchIndicatorPayload): ExportMetricsStructureCurrentViewPayloadBuilder {
    this._instance.search_indicator_v1_request = search_v1_request;
    return this;
  }
}
