import { ActionItem, Collection } from '../models';

export class CollectionBuilder<T> {
  private readonly _collection: Collection<T>;

  constructor() {
    this._collection = {
      count: 0,
      items: [],
    };
  }

  count(count: number): CollectionBuilder<T> {
    this._collection.count = count;
    return this;
  }

  items(items: ActionItem<T>[]): CollectionBuilder<T> {
    this._collection.items = items;
    return this;
  }

  build(): Collection<T> {
    return this._collection;
  }
}
