<lib-dialog-title [title]="dialogTitle" (onCloseEvent)="closeDialog()"></lib-dialog-title>

<div mat-dialog-content [ngClass]="{ loaded: true }">
  <div class="tip mb-4 mt-4">
    <div class="fx-row align-center">
      <mat-icon svgIcon="info" class="tip-icon color-primary-900 mr-2"></mat-icon>
    </div>
    <div class="text tip-text tip-text-primary mat-body color-grey-900 pl-3">
      {{
        "Select an option that will trigger the display of another field or group. In the case of another field, it must be
      in the same group as the trigger. It is possible to select multiple options that trigger the same display
      independent of one another." | translate
      }}
    </div>
  </div>
  <form [formGroup]="conditionalTriggerForm" class="form p-2">
    <lib-multi-select-chip-input
      *ngIf="triggerValueChoiceList$ | async as triggerValueChoiceList"
      label="{{ 'Select the option(s) that trigger the display' | translate }}"
      hint="{{
        'Select one or multiple options that will trigger the display of another field or group of fields' | translate
      }}"
      class="large"
      [control]="conditionalTriggerForm.triggerValue"
      [options]="triggerValueChoiceList"
    ></lib-multi-select-chip-input>

    <lib-multi-select-chip-input
      label="{{ 'Select field(s) or group(s) to display when triggered' | translate }}"
      hint="{{ 'Select field(s) or group(s) from the metric to display when triggered' | translate }}"
      class="large"
      [control]="conditionalTriggerForm.targets"
      [options]="targetChoiceList"
      bindLabel="name"
      [bindValue]="targetOptionToConditionTriggerTarget"
      [compareWith]="compareConditionalTriggerTarget"
    ></lib-multi-select-chip-input>
  </form>
</div>

<div mat-dialog-actions>
  <button type="submit" class="btn btn-success" (click)="submit()" [disabled]="conditionalTriggerForm.invalid">
    {{ submitButtonText }}
  </button>
  <button lib-button type="link" class="ml-4" (click)="closeDialog()">{{ "Cancel" | translate }}</button>
</div>
