<section
  class="mb-2 mt-3"
  [ngClass]="{
    'separator-top': withTopSeparator,
    'separator-bottom': withBottomSeparator
  }"
  data-testid="form-section"
>
  <div *ngIf="!loading; else dummy">
    <h3 class="mat-h3 color-grey-900">{{ title }}</h3>

    <ng-content></ng-content>
  </div>

  <ng-template #dummy>
    <div class="pb-2 pt-2" data-testid="loading">
      <lib-dummy [presentation]="ePresentation.highlights"></lib-dummy>
    </div>
  </ng-template>
</section>
