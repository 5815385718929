import { Directive, HostBinding, Input, OnChanges } from '@angular/core';
import { Value } from '../../../models';
import { ClientConfigService } from '../../../services/client';

@Directive({
  selector: '[libIsActiveField]',
})
export class IsActiveFieldDirective implements OnChanges {
  @Input() libIsActiveField?: Value | null;
  @Input() value?: Value;
  @Input() focusFieldUniqueId: string | null = '';
  @Input() focusId: string = '';

  @HostBinding('class.active') isActive: boolean = true;

  constructor(private clientConfigService: ClientConfigService) {}

  ngOnChanges(): void {
    this.isActive =
      this.isFocused() && this.clientConfigService.areAnyFeatureFlagsEnabled(['metric_editor_form_focus_enabled']);
  }

  private isFocused(): boolean {
    return (
      (this.libIsActiveField?.id && this.value?.id === this.libIsActiveField?.id) ||
      this.focusFieldUniqueId === this.focusId
    );
  }
}
