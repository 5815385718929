import { MetricStandardCode, StandardCodes } from '../../models';

export class StandardCodeUtils {
  public static mergeStandardCodes(standard_codes: MetricStandardCode | undefined): StandardCodes[] {
    if (!standard_codes) {
      return [];
    }

    return [...standard_codes.metric_level, ...standard_codes.field_level];
  }
}
