import { Injectable } from '@angular/core';
import { ApiResponse, ApplicationApiDefinition, FiscalYear } from '../../../models';
import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common/api/api.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClientPublicPlatformService {
  apiName: keyof ApplicationApiDefinition = 'public';
  resource: string;
  servicePath: string;

  constructor(private baseService: BaseService, private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.public.resources.platform;
  }

  public listFiscalYears(searchArguments?: { [key: string]: any }): Observable<ApiResponse<FiscalYear[]>> {
    let params = new HttpParams();

    if (searchArguments?.businessUnitId) {
      params = params.append('business_unit_id', searchArguments.businessUnitId as string);
    }

    const fiscalYears$ = this.apiService
      .get(`${this.servicePath}${this.resource}/fiscal_years`, { params })
      .pipe(map((fiscalYear) => fiscalYear.data));

    const data$ = fiscalYears$.pipe(
      map((data) =>
        data.map((element: { id: string; year: string; start: string; end: string; business_unit_id: string }) => ({
          id: element.id,
          year: element.end.substring(0, 4),
          start: element.start,
          end: element.end,
          unit_id: element.business_unit_id,
        }))
      )
    );
    return data$.pipe(map((x) => ({ meta: { count: x.length }, errors: [], data: x })));
  }
}
