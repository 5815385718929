import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';

import { Builder } from './builder';
import { MetricSearchValueDefinition } from '../models';

export class MetricSearchValueDefinitionBuilder extends Builder<MetricSearchValueDefinition> {
  constructor() {
    super({
      key: uuidv4(),
      label: faker.random.alphaNumeric(10),
      children: undefined,
    });
  }

  public childs(children: MetricSearchValueDefinition[]): MetricSearchValueDefinitionBuilder {
    this._instance.children = children;
    return this;
  }

  public key(key: string): MetricSearchValueDefinitionBuilder {
    this._instance.key = key;
    return this;
  }

  public label(label: string): MetricSearchValueDefinitionBuilder {
    this._instance.label = label;
    return this;
  }
}
