import { Client, IntegrationPartnerLogin, SOURCE_CONFIGURATION } from '../models';
import { v4 as uuidv4 } from 'uuid';
import { faker } from '@faker-js/faker';
import { formatDate } from '@angular/common';
import { BUILDER_DATE_FORMAT, BUILDER_LOCAL } from './builder-utils';

export class ClientBuilder {
  private readonly _client: Client;

  constructor() {
    this._client = {
      created: formatDate(faker.date.past(), BUILDER_DATE_FORMAT, BUILDER_LOCAL),
      created_by: uuidv4(),
      updated: formatDate(faker.date.past(), BUILDER_DATE_FORMAT, BUILDER_LOCAL),
      updated_by: uuidv4(),
      id: faker.datatype.uuid(),
      name: faker.company.name(),
      org_id: faker.datatype.uuid(),
      code: '',
      agent_id: faker.datatype.uuid(),
      require_source_on_documents: false,
      communication_lang: ['en'],
      source_configuration: SOURCE_CONFIGURATION.dynamic_consolidation,
      regular_fiscal_years: false,
      integration_green_project_enabled: false,
      integration_edci_enabled: false,
      integration_tableau_enabled: false,
      integration_s_and_p_enabled: false,
      integration_cdp_enabled: false,
      integration_minimum_enabled: false,
      client_id: faker.datatype.uuid(),
      org_name: faker.company.name(),
      integration_partner_logins: [],
    };
  }

  id(id: string): ClientBuilder {
    this._client.id = id;
    return this;
  }

  code(code: string): ClientBuilder {
    this._client.code = code;
    return this;
  }

  name(name: string): ClientBuilder {
    this._client.name = name;
    return this;
  }

  require_source_on_documents(require_source_on_documents: boolean): ClientBuilder {
    this._client.require_source_on_documents = require_source_on_documents;
    return this;
  }

  integration_green_project_enabled(integration_green_project_enabled: boolean): ClientBuilder {
    this._client.integration_green_project_enabled = integration_green_project_enabled;
    return this;
  }

  integration_edci_enabled(integration_edci_enabled: boolean): ClientBuilder {
    this._client.integration_edci_enabled = integration_edci_enabled;
    return this;
  }

  integration_tableau_enabled(integration_tableau_enabled: boolean): ClientBuilder {
    this._client.integration_tableau_enabled = integration_tableau_enabled;
    return this;
  }

  integration_s_and_p_enabled(integration_s_and_p_enabled: boolean): ClientBuilder {
    this._client.integration_s_and_p_enabled = integration_s_and_p_enabled;
    return this;
  }

  integration_cdp_enabled(integration_cdp_enabled: boolean): ClientBuilder {
    this._client.integration_cdp_enabled = integration_cdp_enabled;
    return this;
  }

  integration_minimum_enabled(integration_minimum_enabled: boolean): ClientBuilder {
    this._client.integration_minimum_enabled = integration_minimum_enabled;
    return this;
  }

  integration_partner_logins(integration_partner_logins: IntegrationPartnerLogin[]): ClientBuilder {
    this._client.integration_partner_logins = integration_partner_logins;
    return this;
  }

  build(): Client {
    return this._client;
  }
}
