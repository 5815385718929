export abstract class Builder<T> {
  protected _instance: T;

  protected constructor(instance: T) {
    this._instance = instance;
  }

  build(): T {
    return this._instance;
  }
}
