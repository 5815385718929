<ng-container *libFeatureFlag="{ featureFlags: featureFlags }">
  <ng-container *libPermission="permissions">
    <ng-container *ngIf="navigationGroups?.length; else noOptionMenuNavigation">
      <div class="nav-container" [ngClass]="{ 'root-active': isDirectoryPath() }">
        <button
          mat-button
          class="nav-button"
          [matMenuTriggerFor]="matMenu"
          [ngClass]="{ 'root-active--button': isDirectoryPath() }"
        >
          <ng-container *ngTemplateOutlet="content"></ng-container>
          <mat-icon svgIcon="arrow-head-down" class="more-option-icon" data-testid="moreOptionIcon"></mat-icon>
        </button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<mat-menu #matMenu="matMenu">
  <ng-container *ngFor="let group of filteredNavigationGroups$ | async; last as last">
    <ng-container *libFeatureFlag="{ featureFlags: group?.featureFlag }">
      <label *ngIf="group.groupLabel" class="group-label"> {{ group.groupLabel | titlecase }} </label>
      <ng-container *ngFor="let menu of group.options">
        <ng-container *libFeatureFlag="{ featureFlags: menu?.featureFlag }">
          <ng-container *libPermission="menu?.permissions">
            <a
              *ngIf="!menu.externalLink; else externalLinkNavMenu"
              mat-menu-item
              routerLinkActive="active-item"
              class="item"
              target="_self"
              [routerLink]="menu.routerLink"
            >
              {{ menu.label | titlecase }}
            </a>
            <ng-template #externalLinkNavMenu>
              <a mat-menu-item class="item" target="_blank" [href]="menu.externalLink">
                <mat-icon *ngIf="menu.iconName" [svgIcon]="menu.iconName" data-testid="partner-login-icon"></mat-icon>
                {{ menu.label }}
              </a>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-container>
      <mat-divider *ngIf="!last && group.options.length > 0" data-testid="divider"></mat-divider>
    </ng-container>
  </ng-container>
</mat-menu>

<ng-template #noOptionMenuNavigation>
  <div class="nav-container" [ngClass]="{ 'root-active': isDirectoryPath() }">
    <a mat-button class="nav-button" [routerLink]="directoryRootPath" routerLinkActive="root-active--button">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
  </div>
</ng-template>

<ng-template #content><ng-content></ng-content></ng-template>
