import { DashboardWidgetSource } from '../../models';
import { CreatedUpdatedBuilder } from '../created-updated-builder';
import { v4 as uuidv4 } from 'uuid';

export class DashboardWidgetSourceBuilder extends CreatedUpdatedBuilder<DashboardWidgetSource> {
  constructor() {
    super({
      widget_id: uuidv4(),
      business_unit_id: uuidv4(),
    });
  }

  widgetId(widgetId: string): DashboardWidgetSourceBuilder {
    this._instance.widget_id = widgetId;
    return this;
  }

  businessUnitId(businessUnitId: string): DashboardWidgetSourceBuilder {
    this._instance.business_unit_id = businessUnitId;
    return this;
  }
}
