import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlags } from '../models';
import { ClientConfigService } from '../services/client';

interface FeatureFlagConfig {
  featureFlags?: keyof FeatureFlags | (keyof FeatureFlags)[];
  hiddenWhenEnabled?: boolean;
}

@Directive({
  selector: '[libFeatureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  @Input('libFeatureFlag') libFeatureFlagConfig?: FeatureFlagConfig;
  @Input() libFeatureFlagElse?: TemplateRef<unknown>;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef,
    private clientConfigService: ClientConfigService
  ) {}

  ngOnInit() {
    this.clientConfigService.isClientReady$().subscribe((isReady: boolean) => {
      if (isReady) {
        this.updateView();
      }
    });
  }

  private updateView() {
    if (this.isEnabled()) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else if (this.libFeatureFlagElse) {
      this.viewContainerRef.createEmbeddedView(this.libFeatureFlagElse);
    } else {
      this.viewContainerRef.clear();
    }
  }

  private isEnabled() {
    const featureFlags = this.libFeatureFlagConfig?.featureFlags;

    if (featureFlags) {
      const featureFlagEnabled = this.clientConfigService.areAnyFeatureFlagsEnabled(
        Array.isArray(featureFlags) ? featureFlags : [featureFlags]
      );
      return this.libFeatureFlagConfig?.hiddenWhenEnabled ? !featureFlagEnabled : featureFlagEnabled;
    }
    return true;
  }
}
