import { LANGUAGES, UserEmailNotificationSettings, UserSettings, UserSettingsRequest } from '../models';

export class UserSettingsRequestBuilder {
  private readonly _userSettings: UserSettingsRequest;

  constructor() {
    this._userSettings = {
      id: 'id',
      user_id: 'userId',
      app_language: LANGUAGES.FR,
      name: '',
    };
  }

  appLanguage(language: LANGUAGES): UserSettingsRequestBuilder {
    this._userSettings.app_language = language;
    return this;
  }

  emailNotificationSettings(email_notification_settings?: UserEmailNotificationSettings): UserSettingsRequestBuilder {
    this._userSettings.email_notification_settings = email_notification_settings;
    return this;
  }

  userId(userId: string): UserSettingsRequestBuilder {
    this._userSettings.user_id = userId;
    return this;
  }

  build(): UserSettingsRequest {
    return this._userSettings;
  }

  buildUserSettings(): UserSettings {
    return {
      app_language: this._userSettings.app_language,
    };
  }
}
