import { Topic } from '../models';

export class TopicBuilder {
  private readonly _topic: Topic;

  constructor() {
    this._topic = {
      id: 'topic_id',
      code: 'topic_code',
      name: 'Topic One',
      slug: 'Topic-One',
      topic_group_id: 'topic_group_id',
    };
  }

  id(id: string): TopicBuilder {
    this._topic.id = id;
    return this;
  }

  name(name: string): TopicBuilder {
    this._topic.name = name;
    return this;
  }

  build(): Topic {
    return this._topic;
  }
}
