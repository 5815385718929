import { Pipe, PipeTransform } from '@angular/core';
import { LimitedUser, User } from '../../models';
import { TranslateService } from '../../services/common';

@Pipe({
  name: 'updatedByUser',
})
export class UpdatedByUserPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(updateByUserId: string | undefined, users: (LimitedUser | User)[]): string {
    const updatedByUser: LimitedUser | User | undefined = users.find(
      (user: LimitedUser | User) => user.id === updateByUserId
    );
    return updatedByUser ? UpdatedByUserPipe.getUserFullName(updatedByUser) : this.translateService.instant('Unknown');
  }

  private static getUserFullName(user: LimitedUser | User): string {
    return `${user.first_name} ${user.last_name}`;
  }
}
