import { User } from '../../models';

export class UserUtils {
  static sortUsersByName(users: User[]): User[] {
    return users.sort((u1: User, u2: User): number => {
      if (u1.first_name.toLowerCase() < u2.first_name.toLowerCase()) {
        return -1;
      } else if (u1.first_name.toLowerCase() > u2.first_name.toLowerCase()) {
        return 1;
      }
      if (u1.last_name.toLowerCase() < u2.last_name.toLowerCase()) {
        return -1;
      }
      return 1;
    });
  }
}
