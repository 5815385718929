import { faker } from '@faker-js/faker';
import { CDPErrorType, JobQueueOutputCDPReportSubmissionGenericError } from '../models';

export class CdpJobQueueOutputGenericErrorBuilder {
  private readonly _cdpJobQueueOutputGenericError: JobQueueOutputCDPReportSubmissionGenericError;

  constructor() {
    this._cdpJobQueueOutputGenericError = {
      error_type: CDPErrorType.GENERIC,
      error_message: faker.random.word(),
      question_id: 1234,
    };
  }

  error_message(error_message: string): CdpJobQueueOutputGenericErrorBuilder {
    this._cdpJobQueueOutputGenericError.error_message = error_message;
    return this;
  }

  question_id(question_id: number): CdpJobQueueOutputGenericErrorBuilder {
    this._cdpJobQueueOutputGenericError.question_id = question_id;
    return this;
  }

  build(): JobQueueOutputCDPReportSubmissionGenericError {
    return this._cdpJobQueueOutputGenericError;
  }
}
