import { CreatedUpdatedEntity } from '../entities';
import { Metric, MetricTableColumnDefinition, ValueDefinition } from '../metrics';
import { Value } from '../values';
import { GridsterItem } from 'angular-gridster2';
import { IntegerTypeDetails } from '../type_details';
import { ValueDefinitionTarget } from '../targets';

interface DashboardWidgetPosition {
  row_location: number;
  column_location: number;
}

interface DashboardWidgetDimensions {
  height: number;
  width: number;
}

type DashboardWidgetGridProperties = DashboardWidgetPosition & DashboardWidgetDimensions;

export interface DashboardWidget extends CreatedUpdatedEntity, DashboardWidgetGridProperties {
  id: string;
  label: string;
  widget_type: DashboardWidgetType;
  dashboard_id: string;
  metric: Metric;
  data_point_widget?: DashboardDataPointWidget;
  stack_chart_widget?: DashboardChartWidget;
  value_definition_target_widget?: DashboardValueDefinitionTargetWidget;
  widget_sources: DashboardWidgetSource[];
}

export interface DashboardWidgetSource extends CreatedUpdatedEntity {
  widget_id: string;
  business_unit_id: string;
}

export interface DashboardDataPointWidget extends CreatedUpdatedEntity {
  id: string;
  is_single_point: boolean;
  data_points: DashboardDataPoint[];
  fiscal_year_id: string;
}

export interface DashboardValueDefinitionTargetWidget extends CreatedUpdatedEntity {
  id: string;
  target_id: string;
  value_definition_target?: ValueDefinitionTarget;
}

export interface DashboardDataPoint extends CreatedUpdatedEntity {
  id: string;
  show_previous_year: boolean;
  input_type: DashboardDataPointInputType;
  value_definition_id: string;
  value?: Value;
  previous_year_value?: Value;
  value_definition: ValueDefinition;
}

export interface DashboardStackChartData extends CreatedUpdatedEntity {
  value?: number;
  x_label: string;
  y_label: string;
}

export interface DashboardStackChart extends CreatedUpdatedEntity {
  id: string;
  input_type: DashboardStackBarInputType;
  rendering_type: DashboardStackBarRenderingType;
  fiscal_year_start_id: string;
  fiscal_year_end_id: string | null;
  value_definition_id?: string;
  value_definition?: ValueDefinition;
  metric_table_column_definition_id?: string;
  metric_table_column_definition?: MetricTableColumnDefinition;
  type_details: IntegerTypeDetails;
  chart_data: DashboardStackChartData[];
}

export interface DashboardChartWidget extends CreatedUpdatedEntity {
  id: string;
  dashboard_widget_id: string;
  stack_chart: DashboardStackChart;
}

export enum DashboardWidgetType {
  DATA_POINT_WIDGET = 'data_point_widget',
  CHART_WIDGET = 'chart_widget',
  VALUE_DEFINITION_TARGET_WIDGET = 'value_definition_target_widget',
}

export enum DashboardDataPointInputType {
  integer = 'integer',
  decimal = 'decimal',
  calculated = 'calculated',
  table_total = 'table_total',
}

export enum DashboardStackBarInputType {
  integer = 'integer',
  decimal = 'decimal',
  calculated = 'calculated',
  table_total = 'table_total',
  table_input_column = 'table_input_column',
}

export enum DashboardStackBarRenderingType {
  absolute = 'absolute',
  percentage = 'percentage',
}

export interface DashboardSingleDataPointWidgetCreationRequest extends DashboardWidgetPosition {
  label: string;
  fiscal_year_id: string | null;
  source_id: string;
  metric_id: string;
  value_definition_id: string;
  field_type: DashboardDataPointInputType;
  show_previous_year: boolean;
}

export interface DashboardStackBarWidgetCreationRequest extends DashboardWidgetPosition {
  label: string;
  start_fiscal_year_id: string;
  end_fiscal_year_id: string | null;
  sources: string[];
  metric_id: string;
  value_definition_id: string | null;
  metric_table_column_definition_id: string | null;
  field_type: DashboardStackBarInputType;
  show_percentage: boolean;
}

export interface DashboardValueDefinitionTargetWidgetCreationRequest extends DashboardWidgetPosition {
  label: string;
  metric_id: string;
  value_definition_target_id: string;
}

export interface DashboardSingleDataPointWidgetUpdateRequest {
  label: string;
  fiscal_year_id: string | null;
  source_id: string;
  metric_id: string;
  value_definition_id: string;
  field_type: DashboardDataPointInputType;
  show_previous_year: boolean;
}

export interface DashboardValueDefinitionTargetWidgetUpdateRequest {
  label: string;
  metric_id: string;
  value_definition_target_id: string;
}

export interface DashboardPlotUpdateRequest {
  widgets: DashboardPlotWidgetUpdateRequest[];
}

export interface DashboardPlotWidgetUpdateRequest {
  id: string;
  height: number;
  width: number;
  row_location: number;
  column_location: number;
}
export interface DashboardStackBarChartWidgetUpdateRequest {
  label: string;
  start_fiscal_year_id: string;
  end_fiscal_year_id: string | null;
  sources: string[];
  metric_id: string;
  value_definition_id: string | null;
  metric_table_column_definition_id: string | null;
  field_type: DashboardStackBarInputType;
  show_percentage: boolean;
}

export type WidgetGridster2Dimensions = Pick<GridsterItem, 'cols' | 'rows'>;
