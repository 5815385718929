import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import {
  ConfirmationDialogConfig,
  ConfirmationDialogType,
  ConfirmedItem,
  DialogConfig,
  DialogResult,
  Status,
} from '../../models';
import { TranslateService } from '../../services/common';

export class ItemNode {
  children!: ItemNode[];
  item!: string;
}

export class ItemFlatNode {
  item!: string;
  level!: number;
  expandable!: boolean;
}

@Component({
  selector: 'lib-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  title: string = this.translateService.instant('Attention!');
  primaryBtn: string = this.translateService.instant('Delete');
  secondaryBtn: string = this.translateService.instant('Cancel');
  buttonStatusClass: string = 'btn-error';
  buttonDisabled: boolean = false;
  warningMsg: string = this.translateService.instant('Are you sure you want to discard your changes?');
  checked: boolean = false;
  type: ConfirmationDialogType = ConfirmationDialogType.delete;

  // Tree
  flatNodeMap = new Map<ItemFlatNode, ItemNode>();
  nestedNodeMap = new Map<ItemNode, ItemFlatNode>();
  selectedParent: ItemFlatNode | null = null;
  newItemName = '';
  // sources
  treeControl!: FlatTreeControl<ItemFlatNode>;
  treeFlattener!: MatTreeFlattener<ItemNode, ItemFlatNode>;
  dataSource!: MatTreeFlatDataSource<ItemNode, ItemFlatNode>;
  // checked
  checklistSelection = new SelectionModel<ItemFlatNode>(true);
  previousChecklistSelection!: SelectionModel<ItemFlatNode>;

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent, DialogResult<ConfirmedItem[]>>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmationDialogConfig
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    if (this.data?.body) {
      this.dataSource.data = this.buildFileTree(this.data.body as Record<string, unknown>, 0);
    }
  }

  ngOnInit(): void {
    if (this.data.title) {
      this.title = this.data?.title;
    }
    if (this.data.primaryBtn) {
      this.primaryBtn = this.data.primaryBtn;
    }
    if (this.data.warningMsg) {
      this.warningMsg = this.data.warningMsg;
    }
    if (this.data.type) {
      this.type = this.data?.type;
    }
    if (this.type === 'discard') {
      if (!this.data.primaryBtn) {
        this.primaryBtn = this.translateService.instant('Discard');
      }
      this.secondaryBtn = this.translateService.instant('Keep editing');
    }
    if (this.data?.status === 'success') {
      this.buttonStatusClass = 'btn-success';
    }
    if (this.data?.checked) {
      this.checkAll();
    }
    if (this.data.allowAction !== undefined) {
      this.buttonDisabled = !this.data.allowAction;
    }
    this.dialogRef.updateSize(DialogConfig.small.width);
  }

  ngAfterViewInit(): void {
    if (this.data?.body) {
      this.treeControl.expandAll();
    }
  }

  closeDialog(): void {
    this.dialogRef.close({ status: Status.CANCEL });
  }

  confirm(): void {
    const confirmed = this.checklistSelection.selected.map(
      (x: ItemFlatNode): ConfirmedItem => ({ content: x.item, checked: true, item: x })
    );
    this.dialogRef.close({ status: Status.CONFIRMED, data: confirmed });
  }

  // Tree
  getLevel = (node: ItemFlatNode) => node.level;

  isExpandable = (node: ItemFlatNode) => node.expandable;

  getChildren = (node: ItemNode): ItemNode[] => node.children;

  hasChild = (_: number, nodeData: ItemFlatNode) => nodeData.expandable;

  transformer = (node: ItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item ? existingNode : new ItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  buildFileTree(obj: Record<string, unknown>, level: number): ItemNode[] {
    if (!obj) {
      return [];
    }
    return Object.keys(obj)
      .sort()
      .reduce<ItemNode[]>((accumulator, key) => {
        const value = obj[key] as Record<string, unknown> | string;
        const node = new ItemNode();
        node.item = key;
        if (value != null) {
          if (typeof value === 'object') {
            node.children = this.buildFileTree(value, level + 1);
          } else {
            node.item = value;
          }
        }
        return accumulator.concat(node);
      }, []);
  }

  checkAll() {
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      if (!this.checklistSelection.isSelected(this.treeControl.dataNodes[i])) {
        this.checklistSelection.toggle(this.treeControl.dataNodes[i]);
      }
      this.treeControl.expand(this.treeControl.dataNodes[i]);
    }
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) => this.checklistSelection.isSelected(child));
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: ItemFlatNode): void {
    let parent: ItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: ItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) => this.checklistSelection.isSelected(child));
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: ItemFlatNode): ItemFlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}
