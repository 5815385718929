import { FiscalYear, Source, SourceType } from '../models';
import { FiscalYearBuilder } from './fiscal-year-builder';
import { SourceAddressBuilder } from './source-address-builder';

export class SourceBuilder {
  private readonly _source: Source;

  constructor() {
    const start_fiscal_year = new FiscalYearBuilder().build();

    this._source = {
      id: '123',
      industries: [],
      is_default: false,
      name: 'a source',
      type: SourceType.business_unit,
      address: new SourceAddressBuilder().build(),
      start_fiscal_year_frequency_code: start_fiscal_year.id,
      start_fiscal_year,
      end_fiscal_year_frequency_code: undefined,
      end_fiscal_year: undefined,
      consolidation: false,
      company_id: '345',
    };
  }

  id(id: string): SourceBuilder {
    this._source.id = id;
    return this;
  }

  name(name: string): SourceBuilder {
    this._source.name = name;
    return this;
  }

  start_fiscal_year(start_fiscal_year: FiscalYear): SourceBuilder {
    this._source.start_fiscal_year = start_fiscal_year;
    this._source.start_fiscal_year_frequency_code = start_fiscal_year.id;
    return this;
  }

  end_fiscal_year(end_fiscal_year: FiscalYear): SourceBuilder {
    this._source.end_fiscal_year = end_fiscal_year;
    this._source.end_fiscal_year_frequency_code = end_fiscal_year.id;
    return this;
  }

  consolidation(consolidation: boolean): SourceBuilder {
    this._source.consolidation = consolidation;
    return this;
  }

  companyId(id: string): SourceBuilder {
    this._source.company_id = id;
    return this;
  }

  isDefault(isDefault: boolean): SourceBuilder {
    this._source.is_default = isDefault;
    return this;
  }

  build(): Source {
    return this._source;
  }
}
