import { Pipe, PipeTransform } from '@angular/core';
import { MetricTableColumnDefinition } from '../../../../../../../models';

@Pipe({
  name: 'contextColumnDefinition',
})
export class ContextColumnDefinitionPipe implements PipeTransform {
  transform(
    column: string,
    contextColumnDefinitions: MetricTableColumnDefinition[]
  ): MetricTableColumnDefinition | undefined {
    return contextColumnDefinitions.find((col) => col.label === column);
  }
}
