import { TemplateRef, Type } from '@angular/core';

export interface MenuItemConfig {
  id: string;
  isSectionTitle?: boolean;
  reloadOnChange?: boolean;
  defaultSelectedTab?: boolean;
  title: string;
  icon?: string;
  component?: Type<unknown>;
  componentProperties?: Partial<unknown>;
  componentTemplate?: TemplateRef<unknown>;
}

// This is needed because ActionItem cannot have an item with an id equal to null and since
// the source-dropdown.component leverage on the lib-select that also leverage on the ActionItem we had to get creative
// and assign an arbitrary value that is not null but would still represent the dynamic consolidation source id that
// needs to be sent to the backend as null.
export const DYNAMIC_CONSOLIDATION_SOURCE_ID = 'none';
