import { DataRequestTransition, DataRequestValueGroupSet, DataRequestValueGroupSetStatus, ValueGroup } from '../models';

export class DataRequestValueGroupSetBuilder {
  private readonly _dataRequestValueGroupSet: DataRequestValueGroupSet;

  constructor() {
    this._dataRequestValueGroupSet = {
      notes: [],
      transitions: [],
      business_unit_id: 'a business Id',
      consolidated: false,
      id: '1123',
      indicator_id: '321',
      name: 'a name',
      status: DataRequestValueGroupSetStatus.NOT_STARTED,
      value_groups: [],
    };
  }

  id(id: string): DataRequestValueGroupSetBuilder {
    this._dataRequestValueGroupSet.id = id;
    return this;
  }

  status(dataRequestValueGroupSetStatus: DataRequestValueGroupSetStatus): DataRequestValueGroupSetBuilder {
    this._dataRequestValueGroupSet.status = dataRequestValueGroupSetStatus;
    return this;
  }

  transitions(transitions: DataRequestTransition[]): DataRequestValueGroupSetBuilder {
    this._dataRequestValueGroupSet.transitions = transitions;
    return this;
  }

  valueGroups(valueGroups: ValueGroup[]): DataRequestValueGroupSetBuilder {
    this._dataRequestValueGroupSet.value_groups = valueGroups;
    return this;
  }

  updatedBy(updatedBy: string): DataRequestValueGroupSetBuilder {
    this._dataRequestValueGroupSet.updated_by = updatedBy;
    return this;
  }

  updated(updated: Date | string): DataRequestValueGroupSetBuilder {
    this._dataRequestValueGroupSet.updated = updated;
    return this;
  }

  frequencyCode(frequencyCode: string): DataRequestValueGroupSetBuilder {
    this._dataRequestValueGroupSet.frequency_code = frequencyCode;
    return this;
  }

  indicatorId(indicatorId: string): DataRequestValueGroupSetBuilder {
    this._dataRequestValueGroupSet.indicator_id = indicatorId;
    return this;
  }

  businessUnitId(businessUnitId: string): DataRequestValueGroupSetBuilder {
    this._dataRequestValueGroupSet.business_unit_id = businessUnitId;
    return this;
  }

  consolidated(consolidated: boolean): DataRequestValueGroupSetBuilder {
    this._dataRequestValueGroupSet.consolidated = consolidated;
    return this;
  }

  build(): DataRequestValueGroupSet {
    return this._dataRequestValueGroupSet;
  }
}
