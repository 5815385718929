import { BaseTemplateReport, TemplateReportSection, TemplateReportVersion } from '../models';

export class BaseTemplateReportBuilder {
  private readonly _templateReport: BaseTemplateReport;

  constructor() {
    this._templateReport = {
      id: 'templateReportVersionId',
      name: 'Template report name',
      version: 'Final version 2.0',
      active: true,
      sections: [],
    };
  }

  id(id: string): BaseTemplateReportBuilder {
    this._templateReport.id = id;
    return this;
  }

  name(name: string): BaseTemplateReportBuilder {
    this._templateReport.name = name;
    return this;
  }

  version(version: string): BaseTemplateReportBuilder {
    this._templateReport.version = version;
    return this;
  }

  active(active: boolean): BaseTemplateReportBuilder {
    this._templateReport.active = active;
    return this;
  }

  sections(sections: TemplateReportSection[]): BaseTemplateReportBuilder {
    this._templateReport.sections = sections;
    return this;
  }

  build(): BaseTemplateReport {
    return this._templateReport;
  }
}
