import { LANGUAGES } from '../../common';

export { UserSettings, UserEmailNotificationSettings };

interface UserEmailNotificationSettings {
  data_request_comment_activity?: boolean;
  data_request_daily_reminders?: boolean;
}

interface UserSettings {
  app_language?: LANGUAGES;
  email_notification_settings?: UserEmailNotificationSettings;
}
