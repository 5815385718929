import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MetricApiService } from '../../../../services/types';
import { MetricStructureStateService } from '../../../services/metric-structure-state.service';
import { Required } from '../../../../decorators';
import { DataRequestSourceStatus, Metric, MinimalDataRequest } from '../../../../models';

@Component({
  selector: 'lib-metric-structure-open-requests',
  templateUrl: './metric-structure-open-requests.component.html',
  styleUrls: ['./metric-structure-open-requests.component.scss'],
  providers: [],
})
export class MetricStructureOpenRequestsComponent implements OnInit {
  @Input() @Required() metric!: Metric;

  public startedRequests: MinimalDataRequest[] = [];
  public notStartedRequests: MinimalDataRequest[] = [];

  constructor(
    public readonly metricStructureService: MetricStructureStateService,
    private readonly metricsService: MetricApiService,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    this.metricsService.listMetricDataRequests(this.metric.id).subscribe((res) => {
      this.startedRequests = res.data.filter((r) => r.source_statuses.includes(DataRequestSourceStatus.STARTED));
      this.notStartedRequests = res.data.filter(
        (r) => r.source_statuses.includes(DataRequestSourceStatus.NOT_STARTED) && r.source_statuses.length === 1
      );
    });
  }

  public navigateToRequest(requestId: string): void {
    this.router.navigate([`/app/metrics/requests/${requestId}/edit/structure`]);
  }
}
