import { ValueDefinitionDisplayType } from '../../models';

export const ValueDefinitionDisplayTypeDebounceTime: Record<ValueDefinitionDisplayType, number> = {
  boolean: 0,
  calculated: 0,
  choice: 0,
  choice_multiple: 500,
  choice_radio: 0,
  choice_searchable: 0,
  date: 0,
  datetime: 0,
  decimal: 0,
  document: 0,
  file: 0,
  file_v2: 0,
  integer: 0,
  label: 0,
  metric: 0,
  none: 0,
  subtitle: 0,
  table: 0,
  text: 0,
  text_area: 0,
  text_area_validation: 0,
  text_rich: 0,
  text_simple: 0,
  tip: 0,
};
