import { Observable } from 'rxjs';
import {
  ApiResponse,
  CollaboratorDataRequestWithSource,
  DataRequestNote,
  DataRequestSource,
  DataRequestSourceSubmission,
  DataRequestSourceSubmitAction,
  DataRequestTransition,
  DataRequestValueGroupSet,
  DataRequestValueGroupSetStatus,
  User,
} from '../../../models';

export abstract class BaseClientCollaboratorsService {
  abstract getListOfUserFromDataRequestSourceForCollaborator(
    dataRequestId: string,
    dataRequestSourceId: string
  ): Observable<ApiResponse<User[]>>;
  abstract getCollaboratorDataRequestWithSource(
    dataRequestId: string,
    dataRequestSourceId: string
  ): Observable<ApiResponse<CollaboratorDataRequestWithSource>>;
  abstract postChangeStatusOfDataRequestVgset(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestVgsetId: string,
    status: DataRequestValueGroupSetStatus,
    note?: string
  ): Observable<ApiResponse<DataRequestValueGroupSet>>;
  abstract postAutomaticImportDataRequestSource(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestVgsetIds: string[],
    dataRequestSourceSubmitAction: DataRequestSourceSubmitAction
  ): Observable<ApiResponse<DataRequestSource>>;
  abstract postChangeStatusOfDataRequestSource(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestSourceSubmission: DataRequestSourceSubmission
  ): Observable<ApiResponse>;
  abstract getDataRequestNotes(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestNoteIds: string[]
  ): Observable<ApiResponse<DataRequestNote[]>>;
  abstract getDataRequestSourceTransitions(
    dataRequestId: string,
    dataRequestSourceId: string
  ): Observable<ApiResponse<DataRequestTransition[]>>;
  abstract getDataRequestValueGroupSetTransitions(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestVgsetId: string
  ): Observable<ApiResponse<DataRequestTransition[]>>;
  abstract getDataRequestSourceTicket(
    dataRequestId: string,
    dataRequestSourceId: string
  ): Observable<ApiResponse<string>>;
  abstract postDataRequestNoteReply(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestNoteId: string,
    replyText: string
  ): Observable<ApiResponse<DataRequestNote>>;
}
