import { ReportCategoryType } from '../../templates';
import { JobQueueCategory, JobQueueStatus } from './job-queue-enums';

export {
  JobQueueFilteringData,
  JobQueueDefinitionDataRequest,
  JobQueueFilteringOption,
  JobQueueOutput,
  JobQueueOutputConsolidateBusinessUnits,
  JobQueueOutputCreateReportQuestionnaire,
  JobQueueOutputDeleteMetric,
  JobQueueOutputExportDocument,
  JobQueueOutputPrefillDataRequest,
  JobQueueOutputPrefillDataRequestSource,
  JobQueueOutputSPReportSubmission,
  JobQueueOutputCDPReportSubmission,
  JobQueueOutputCDPReportSubmissionError,
  JobQueueOutputCDPReportSubmissionGenericError,
  JobQueueOutputCDPReportSubmissionFileError,
  JobQueueOutputCDPReportSubmissionFieldError,
  JobQueueOutputReportExportV1,
  JobQueueOutputBenchmarkExportV2,
  CDPErrorType,
  CDPTaskErrorType,
};

type JobQueueOutput =
  | JobQueueOutputConsolidateBusinessUnits
  | JobQueueOutputCreateReportQuestionnaire
  | JobQueueOutputDeleteMetric
  | JobQueueOutputExportDocument
  | JobQueueOutputPrefillDataRequest
  | JobQueueOutputPrefillDataRequestSource
  | JobQueueOutputSPReportSubmission
  | JobQueueOutputCDPReportSubmission
  | JobQueueOutputReportExportV1
  | JobQueueOutputBenchmarkExportV2;

type JobQueueOutputCDPReportSubmissionError =
  | JobQueueOutputCDPReportSubmissionGenericError
  | JobQueueOutputCDPReportSubmissionFileError
  | JobQueueOutputCDPReportSubmissionFieldError;

interface JobQueueOutputCreateReportQuestionnaire {
  report_id: string;
  report_name: string;
}

interface JobQueueOutputExportDocument {
  document_id: string;
  document_name: string;
  error_message?: string;
}

interface JobQueueOutputConsolidateBusinessUnits {
  frequency_codes: string[];
  message: string;
}

interface JobQueueOutputDeleteMetric {
  metric_id: string;
  metric_code: string;
}

interface JobQueueOutputPrefillDataRequest {
  data_request_id: string;
}

interface JobQueueOutputPrefillDataRequestSource {
  data_request_id: string;
  data_request_source_id: string;
}

interface JobQueueOutputSPReportSubmission {
  filename?: string;
  file_id?: string;
  url?: string;
  report_id: string;
  report_name: string;
  sp_upload_instance_id?: string;
  error_message?: string;
  prefill_log_text?: string;
}

interface JobQueueOutputCDPReportSubmission {
  report_id: string;
  report_name: string;
  error_message: string;
  cdp_error_type: CDPTaskErrorType;
  log_text?: string;
  errors: JobQueueOutputCDPReportSubmissionError[];
}

enum CDPErrorType {
  GENERIC = 'generic',
  FILE_UPLOAD = 'file_upload',
  METRIC = 'metric',
}

enum CDPTaskErrorType {
  GENERIC = 'generic',
  UNAUTHORIZED = 'unauthorized',
}

interface JobQueueOutputCDPReportSubmissionGenericError {
  error_type: CDPErrorType.GENERIC;
  error_message: string;
  question_id: number;
}

interface JobQueueOutputCDPReportSubmissionFileError {
  error_type: CDPErrorType.FILE_UPLOAD;
  error_message: string;
  question_id: number;
  filename: string;
  document_id: string;
  question?: string;
  metric_code?: string;
  metric_name?: string;
}

interface JobQueueOutputCDPReportSubmissionFieldError {
  error_type: CDPErrorType.METRIC;
  error_message: string;
  question_id?: number;
  question?: string;
  metric_code?: string;
  metric_name?: string;
  value?: string;
}

interface JobQueueOutputReportExportV1 {
  report_id: string;
  report_name: string;
  report_category: ReportCategoryType;
  document_name?: string;
  document_id?: string;
}

interface JobQueueOutputBenchmarkExportV2 {
  benchmark_id: string;
  benchmark_name: string;
  document_name?: string;
  document_id?: string;
}

interface JobQueueDefinitionDataRequest {
  id: string;
  status: JobQueueStatus;
  category: JobQueueCategory;
  start: string;
  end: string | null;
  created_by: string | null;
  duration_ms: number | null;
  output: JobQueueOutput | null;
  updated: string;
}

interface JobQueueFilteringData {
  category?: string[];
  status?: string[];
  created_by?: string[];
}

interface JobQueueFilteringOption {
  page_size: number;
  page: number;
  filters: JobQueueFilteringData;
}
