import { NgModule } from '@angular/core';
import { ChartWidgetComponent } from './chart-widget/chart-widget.component';
import { CommonModule } from '@angular/common';
import { ChartsModule } from '../charts';
import { MaterialModule } from '../material/material.module';
import { ComponentsModule } from '../components';

@NgModule({
  declarations: [ChartWidgetComponent],
  imports: [CommonModule, ChartsModule, MaterialModule, ComponentsModule],
  exports: [ChartWidgetComponent],
})
export class ChartWidgetModule {}
