import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { Observable, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import {
  ActionItem,
  CalculatedValueReferences,
  EmptyResults,
  Metric,
  MinimalIndicator,
  ValueDefinition,
  ValueDefinitionGroup,
} from '../../../models';

import { TranslateService } from '../../../services/common/translate/translate.service';
import { AlphabeticKeys } from '../../../translations';
import { SearchService } from '../../../search';
import { MetricApiService } from '../../../services/types';
import cloneDeep from 'lodash/cloneDeep';
import { AutoCompleteInputComparisonMethod } from '../../../components';
import { IndicatorsApiService } from '../../../services/api-services';

export interface Variables {
  variablesDict: {
    [key: string]: CalculatedValueReferences;
  };
}

export interface AddVariableDialogData {
  metric: Metric;
  targetValueDefinitionId: string;
  variables?: ActionItem<CalculatedValueReferences>[];
  selectedMetric?: Metric;
}

@Component({
  selector: 'lib-add-variable-dialog',
  templateUrl: './add-variable-dialog.component.html',
  styleUrls: ['./add-variable-dialog.component.scss'],
})
export class AddVariableDialogComponent implements OnInit {
  valueDefinitionsChecked: CalculatedValueReferences[] = [];
  tabIndex: number = 1;
  keys = this.translateService.listResources(AlphabeticKeys);
  loaded: boolean = false;
  isLoadingInterMetrics: boolean = true;

  selectedMetric?: Metric;
  targetValueDefinitionId: string = '';
  readonly emptyResults: EmptyResults = {
    title: this.translateService.instant('No metric values'),
    subtitle: this.translateService.instant('Select a metric to see its values'),
    image: 'laptop-neutral',
  };

  indicators$: Observable<(Metric | MinimalIndicator)[]> = of([]);
  metricFormControl: UntypedFormControl = new UntypedFormControl();
  readonly eAutoCompleteInputComparisonMethod = AutoCompleteInputComparisonMethod;

  constructor(
    private dialogRef: MatDialogRef<AddVariableDialogComponent, Variables>,
    private searchService: SearchService,
    private metricsService: MetricApiService,
    private translateService: TranslateService,
    private indicatorsApiService: IndicatorsApiService,
    @Inject(MAT_DIALOG_DATA) public data: AddVariableDialogData
  ) {}

  ngOnInit(): void {
    this.selectedMetric = cloneDeep(this.data.metric);
    this.targetValueDefinitionId = this.data.targetValueDefinitionId;
    this.valueDefinitionsChecked = cloneDeep(this.data.variables?.map((variable: ActionItem) => variable.item) || []);
    this.loaded = true;

    this.indicators$ = this.indicatorsApiService.listMinimalIndicators(this.data.metric.framework_id, {}).pipe(
      map((response) => response.data),
      finalize(() => {
        this.isLoadingInterMetrics = false;
      })
    );
  }

  isValueDefAdded(valueDefinitionId: string): boolean {
    return this.valueDefinitionsChecked.some((x) => x.value_definition_id == valueDefinitionId);
  }

  checkboxChanged(event: MatCheckboxChange, valuedefinitionGroupId: string, valueDefinitionId: string): void {
    const valueRef = this.valueDefinitionsChecked.find((def) => def.value_definition_id === event.source.value);
    if (event.checked && !valueRef) {
      this.valueDefinitionsChecked.push({
        metric_id: this.selectedMetric?.id ?? '',
        value_definition_group_id: valuedefinitionGroupId,
        value_definition_id: valueDefinitionId,
        mandatory: false,
      });
    } else if (valueRef) {
      this.valueDefinitionsChecked.splice(this.valueDefinitionsChecked.indexOf(valueRef), 1);
    }
  }

  addVariables(): void {
    const variablesDict: { [key: string]: CalculatedValueReferences } = {};
    this.valueDefinitionsChecked.forEach((valueRef, index) => {
      variablesDict[this.keys[index].title] = valueRef;
    });
    this.dialogRef.close({ variablesDict });
  }

  selected(selectedIndicator: Metric | MinimalIndicator): void {
    this.loaded = false;
    const metricId = selectedIndicator.metric_id ?? selectedIndicator.id;
    this.metricsService
      .getMetric(metricId, {
        load_value_definition_groups: true,
        load_value_definitions: true,
        show_deactivated: false,
      })
      .pipe(
        finalize(() => {
          this.loaded = true;
        })
      )
      .subscribe((result) => {
        this.selectedMetric = cloneDeep(result.data);
      });
  }

  switchTab(tabIndex: number): void {
    this.loaded = false;
    switch (tabIndex) {
      case 1:
        this.tabIndex = 1;
        this.selectedMetric = cloneDeep(this.data.metric);
        this.metricFormControl.reset();
        break;
      case 2:
        this.tabIndex = 2;
        this.selectedMetric = undefined;
        break;
    }
    this.loaded = true;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public bindMetricLabel(metric: Metric): string {
    return `${metric.code}: ${metric.description}`;
  }

  filterOutDeactivatedValueDefinitions(valueDefinition: ValueDefinition): boolean {
    if (typeof valueDefinition.active == 'boolean') {
      return valueDefinition.active;
    }
    return true;
  }

  filterOutDeactivatedValueDefinitionGroups(valueDefinitionGroup: ValueDefinitionGroup): boolean {
    if (typeof valueDefinitionGroup.active == 'boolean') {
      return valueDefinitionGroup.active;
    }
    return true;
  }
}
