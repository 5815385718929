import { Topic, TopicGroup } from '../models';
import { TopicBuilder } from './topic-builder';

export class TopicGroupBuilder {
  private readonly _topicGroup: TopicGroup;

  constructor() {
    this._topicGroup = {
      id: 'topic_group_id',
      code: 'topic_group_code',
      name: 'Topic Group One',
      slug: 'Topic-Group-One',
      topic_category_id: 'topic_category_id',
      topics: [new TopicBuilder().build()],
    };
  }

  name(name: string): TopicGroupBuilder {
    this._topicGroup.name = name;
    return this;
  }

  topics(topics: Topic[]): TopicGroupBuilder {
    this._topicGroup.topics = topics;
    return this;
  }

  build(): TopicGroup {
    return this._topicGroup;
  }
}
