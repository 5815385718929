import { DataRequestUser, DataRequestUserResponsibility } from '../models';

export class DataRequestUserBuilder {
  private readonly _dataRequestUser: DataRequestUser;

  constructor() {
    this._dataRequestUser = {
      id: '123',
      responsibility: DataRequestUserResponsibility.CONTRIBUTOR,
      show_historical_data: false,
      user_id: '122',
      active: true,
    };
  }

  id(id: string): DataRequestUserBuilder {
    this._dataRequestUser.id = id;
    return this;
  }

  user_id(userId: string): DataRequestUserBuilder {
    this._dataRequestUser.user_id = userId;
    return this;
  }

  responsibility(responsibility: DataRequestUserResponsibility): DataRequestUserBuilder {
    this._dataRequestUser.responsibility = responsibility;
    return this;
  }

  active(active: boolean): DataRequestUserBuilder {
    this._dataRequestUser.active = active;
    return this;
  }

  build(): DataRequestUser {
    return this._dataRequestUser;
  }
}
