import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AppConfig, ValueDefinitionSize } from '../../../models';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { ValidationMessageService } from '../../../services/common';
import {
  HtmlEditorService,
  LinkService,
  QuickToolbarService,
  ToolbarService,
  RichTextEditorComponent,
  ChangeEventArgs,
  ToolbarSettingsModel,
  BeforeSanitizeHtmlArgs,
  PasteCleanupSettingsModel,
  PasteCleanupService,
} from '@syncfusion/ej2-angular-richtexteditor';
import { DOCUMENT } from '@angular/common';
import { SanitizeHtmlPipe } from '../../../pipes';
import { interval, Subscription } from 'rxjs';

let nextId = 0;

@Component({
  selector: 'lib-rich-text-input',
  templateUrl: './rich-text-input.component.html',
  styleUrls: ['./rich-text-input.component.scss'],
  providers: [
    HtmlEditorService,
    QuickToolbarService,
    LinkService,
    ToolbarService,
    SanitizeHtmlPipe,
    PasteCleanupService,
  ],
})
export class RichTextInputComponent implements OnChanges, AfterViewInit, OnInit, OnDestroy {
  @Input() label: string = '';
  @Input() control?: UntypedFormControl;
  @Input() messages?: ValidationErrors;
  @Input() hint?: string;
  @Input() placeholder?: string;
  @Input() autofocus: boolean = false;
  @Input() size: ValueDefinitionSize = ValueDefinitionSize.large;
  @Input() enableSourceCodeOption: boolean = false;
  @Input() autoSaveInterval?: number;

  // Reflecting the native blur event
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<void>();

  @ViewChild('richTextEditorComponent')
  public richTextEditorComponent?: RichTextEditorComponent;

  readonly _inputId = `rich-text-input-${nextId++}`;

  editorToolbarConfig: ToolbarSettingsModel;
  required: boolean = false;
  errorMessages: ValidationErrors = {};
  pasteCleanupSettings: PasteCleanupSettingsModel;
  updatedControlValue: string | null = '';

  private autoSaveSubscription?: Subscription;

  constructor(
    private config: AppConfig,
    private validationMessageService: ValidationMessageService,
    private sanitizeHtml: SanitizeHtmlPipe,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.editorToolbarConfig = this.config.apiConfig.components.editor.toolbar;
    this.pasteCleanupSettings = this.config.apiConfig.components.editor.pasteCleanupSettings;
  }

  public ngOnInit(): void {
    if (this.enableSourceCodeOption) {
      const toolbarItems = [...(this.editorToolbarConfig.items ?? [])] as string[];
      toolbarItems.splice(-2, 0, 'SourceCode');
      this.editorToolbarConfig = {
        ...this.editorToolbarConfig,
        items: toolbarItems,
      };
    }
    this.updatedControlValue = this.control?.value ?? '';

    this.control?.registerOnChange((updatedValue: string | null) => {
      this.updatedControlValue = updatedValue;
    });
  }

  public ngOnChanges(): void {
    this.initializeInput();
  }

  public ngAfterViewInit(): void {
    if (this.autofocus) {
      this.setFocus();
    }
  }

  public ngOnDestroy(): void {
    this.updateControlValue();
    this.autoSaveSubscription?.unsubscribe();
  }

  private initializeInput() {
    this.required = this.control?.hasValidator(Validators.required) ?? false;
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
  }

  public onBeforeSanitizeHtml(event: BeforeSanitizeHtmlArgs): void {
    event.cancel = true;
    event.helper = (value: string) => this.sanitizeHtml.transform(value);
  }

  public onChange(event: ChangeEventArgs): void {
    if (event.value !== undefined) {
      this.updatedControlValue = this.sanitizeHtml.transform(event.value);
    }
  }

  public onBlur(): void {
    this.updateControlValue();
    this.autoSaveSubscription?.unsubscribe();
  }

  public onFocus(): void {
    if (this.autoSaveInterval) {
      this.autoSaveSubscription = interval(this.autoSaveInterval).subscribe(() => {
        this.updateControlValue();
      });
    }
  }

  public setFocus(): void {
    this.richTextEditorComponent?.focusIn();
  }

  private updateControlValue(): void {
    if (
      this.control?.value != this.updatedControlValue &&
      !(!this.control?.value && this.updatedControlValue && this.updatedControlValue.length <= 0)
    ) {
      this.control?.setValue(this.updatedControlValue);
    }
    this.blur.emit();
  }
}
