import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DataRequestSourceStatus } from '../../../models';

export const DataRequestUserResponsibilityTranslation = {
  CONTRIBUTOR: _('Contributor'),
  REVIEWER: _('Reviewer'),
  APPROVER: _('Approver'),
};

export const DueDateNotificationTimeframeTypeTranslation = {
  CALENDAR_DAYS: _('Days'),
  WEEKS: _('Weeks'),
};

export const DueDateNotificationRelativeToTypeTranslation = {
  BEFORE: _('Before'),
  AFTER: _('After'),
};

export const DueDateNotificationScheduleTypeTranslation = {
  ONE_WEEK_BEFORE: _('One week before'),
  ONE_DAY_BEFORE: _('One day before'),
  ON_DUE_DATE: _('On due date'),
  ONE_DAY_AFTER: _('One day after'),
  EVERY_DAY_PAST_DUE_DATE: _('Every day past due date'),
  CUSTOM: _('Custom'),
};

export const DataRequestSourceStatusToText = {
  [DataRequestSourceStatus.IN_APPROVAL]: _('In approval'),
  [DataRequestSourceStatus.NOT_STARTED]: _('Not started'),
  [DataRequestSourceStatus.STARTED]: _('In contribution'),
  [DataRequestSourceStatus.IN_REVIEW]: _('In review'),
  [DataRequestSourceStatus.IMPORTED]: _('Imported'),
};
