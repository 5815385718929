import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActionItem, FieldInformationRequest, Indicator, StandardCodes } from '../../../../../models';

type FieldInformationFormModel = {
  relatedMetrics: FormControl<ActionItem<Indicator>[] | null | undefined>;
  standardCodes: FormControl<ActionItem<StandardCodes>[] | null>;
  technicalProtocol: FormControl<string | null | undefined>;
};

export class FieldInformationForm extends FormGroup<FieldInformationFormModel> {
  constructor(
    standardCodes: ActionItem<StandardCodes>[],
    relatedMetrics: ActionItem<Indicator>[] | undefined,
    technicalProtocol: string | null | undefined,
    private showStandardCodesForm: boolean = false,
    private showRelatedMetricsForm: boolean = false,
    readonly fb: FormBuilder = new FormBuilder()
  ) {
    super({
      relatedMetrics: fb.control(showRelatedMetricsForm ? relatedMetrics : null),
      standardCodes: fb.control(showStandardCodesForm ? standardCodes : null),
      technicalProtocol: fb.control(technicalProtocol),
    });
  }

  public toModel(): FieldInformationRequest {
    return {
      related_metric_ids: this.showRelatedMetricsForm
        ? this.controls.relatedMetrics.value?.map((sc) => sc.id)
        : undefined,
      standard_code_ids: this.showStandardCodesForm ? this.controls.standardCodes.value?.map((sc) => sc.id) : undefined,
      technical_protocol: this.controls.technicalProtocol.value ?? undefined,
    };
  }
}
