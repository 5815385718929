import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ApplicationApiDefinition,
  CopyDataRequestPayload,
  CreationDataRequest,
  DataRequest,
  DataRequestFilteringOptions,
  DataRequestIndicator,
  DataRequestIndicatorLink,
  DataRequestUserResponsibility,
  DataRequestWithSource,
  SearchOptions,
  UpdateDataRequestIndicatorLink,
  UpdateDataRequestPayload,
  User,
} from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClientDataRequestsService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.dataRequests;
  }

  public createDataRequest(dataRequest: CreationDataRequest): Observable<ApiResponse<DataRequest>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/data_requests`, dataRequest);
  }

  public getDataRequest(dataRequestId: string): Observable<ApiResponse<DataRequest>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/data_requests/${dataRequestId}`);
  }

  public listDataRequests(searchOptions: SearchOptions): Observable<ApiResponse<DataRequest[]>> {
    const params: any = this.payloadFromDataRequestSearchOptions(searchOptions);
    return this.apiService.get(`${this.servicePath}${this.resource}/data_requests`, { params }).pipe(
      map((result: ApiResponse<DataRequest[]>) => {
        if (searchOptions.size) {
          result.data = result.data.slice(0, searchOptions.size);
        }
        return result;
      })
    );
  }

  public listDataTableDataRequests(filterOptions: DataRequestFilteringOptions): Observable<ApiResponse<DataRequest[]>> {
    const params = this.filterOptionsToParams(filterOptions);
    return this.apiService.get(`${this.servicePath}${this.resource}/data_requests`, { params });
  }

  public getDataRequestWithSource(
    dataRequestId: string,
    dataRequestSourceId: string
  ): Observable<ApiResponse<DataRequestWithSource>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/merged`
    );
  }

  public getDataRequestIndicator(
    dataRequestId: string,
    dataRequestIndicatorId: string,
    businessUnitId: string
  ): Observable<ApiResponse<DataRequestIndicator>> {
    let params = new HttpParams();
    params = params.append('business_unit_ids', businessUnitId);

    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/indicators/${dataRequestIndicatorId}`,
      { params }
    );
  }

  public getDataRequestSourceUsers(
    dataRequestId: string,
    dataRequestSourceId: string
  ): Observable<ApiResponse<User[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/users`
    );
  }

  public updateDataRequest(
    dataRequestId: string,
    updatedDataRequestPayload: UpdateDataRequestPayload
  ): Observable<ApiResponse<DataRequest>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}`,
      updatedDataRequestPayload
    );
  }

  public activateDataRequest(
    dataRequestId: string,
    emailResponsibilities?: DataRequestUserResponsibility[]
  ): Observable<ApiResponse<DataRequest>> {
    const payload: any = {};
    if (emailResponsibilities) {
      payload.users_responsibilities_email = emailResponsibilities;
    }
    return this.apiService.post(`${this.servicePath}${this.resource}/data_requests/${dataRequestId}/activate`, payload);
  }

  public activateDataRequestPrefill(
    dataRequestId: string,
    emailResponsibilities?: DataRequestUserResponsibility[]
  ): Observable<ApiResponse> {
    const payload: any = {};
    if (emailResponsibilities) {
      payload.users_responsibilities_email = emailResponsibilities;
    }
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/activate/prefill`,
      payload
    );
  }

  public deleteDataRequest(dataRequestId: string): Observable<void> {
    return this.apiService.delete(`${this.servicePath}${this.resource}/data_requests/${dataRequestId}`);
  }

  public deactivateDataRequest(dataRequestId: string): Observable<ApiResponse<DataRequest>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/data_requests/${dataRequestId}/deactivate`);
  }

  public copyDataRequest(
    dataRequestId: string,
    copyDataRequestPayload: CopyDataRequestPayload
  ): Observable<ApiResponse<DataRequest>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/duplicate`,
      copyDataRequestPayload
    );
  }

  public deleteDataRequestIndicatorLink(dataRequestId: string, linkedIndicatorId: string): Observable<void> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_indicator_links/${linkedIndicatorId}`
    );
  }

  public updateDataRequestIndicatorLink(
    dataRequestId: string,
    indicatorId: string,
    updateDataRequestIndicatorLink: UpdateDataRequestIndicatorLink
  ): Observable<ApiResponse<DataRequestIndicatorLink>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_indicator_links/${indicatorId}`,
      updateDataRequestIndicatorLink
    );
  }

  public createDataRequestIndicatorLink(
    dataRequestId: string,
    indicatorId: string,
    position?: number,
    instructions?: string,
    important?: boolean
  ): Observable<ApiResponse<DataRequestIndicatorLink>> {
    const payload = {
      position: position ?? null,
      instructions: instructions ?? null,
      important: important ?? false,
      indicator_id: indicatorId,
    };

    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_indicator_links`,
      payload
    );
  }

  private filterOptionsToParams(options: DataRequestFilteringOptions): object {
    const params: any = {
      ['limit']: options.pagination?.limit,
      ['offset']: options.pagination?.offset,
      ['order_by']: options.ordering?.order_by,
      ['order_by_direction']: options.ordering?.order_by_direction,
    };

    if (options.search?.query) {
      params['query'] = options.search.query;
    }

    if (options.filters?.frequency_code) {
      params['frequency_code'] = options.filters.frequency_code;
    }

    if (options.filters?.status) {
      params['status'] = options.filters.status;
    }

    if (options.filters?.tag) {
      params['tag'] = options.filters.tag;
    }

    if (options.filters?.business_unit_id) {
      params['business_unit_id'] = options.filters.business_unit_id;
    }

    return params;
  }

  private payloadFromDataRequestSearchOptions(searchOptions: SearchOptions): HttpParams {
    let params = new HttpParams();

    if (searchOptions.query.keywords) {
      params = params.append('query', searchOptions.query.keywords);
    }

    if (searchOptions.sort) {
      if (searchOptions.sort.id === 'name') {
        params = params.append('order_by', 'name');
        params = params.append('order_by_direction', 'asc');
      }
    }

    if (searchOptions.filters) {
      if (searchOptions.filters.source) {
        params = params.append('business_unit_id', searchOptions.filters.source.id);
      }
      if (searchOptions.filters.year) {
        params = params.append('frequency_code', searchOptions.filters.year.id);
      }
      if (searchOptions.filters.tag) {
        params = params.append('tag', searchOptions.filters.tag.id);
      }
      if (searchOptions.filters.status) {
        params = params.append('status', searchOptions.filters.status.id);
      }
      if (searchOptions.filters.indicator?.id) {
        params = params.append('indicator_id', searchOptions.filters.indicator.id);
      }
    }

    if (searchOptions.custom_filters) {
      if (searchOptions.custom_filters.templateId) {
        params = params.append('report_id', searchOptions.custom_filters.templateId as string);
      }
      if (searchOptions.custom_filters.requestType) {
        params = params.append('request_type', searchOptions.custom_filters.requestType as string);
      }
    }

    return params;
  }
}
