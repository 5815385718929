import { Injectable } from '@angular/core';
import { DeactivationUtils } from '../../../classes/DeactivationUtils/deactivation-utils';
import {
  Metric,
  PlatformValueGroupSetStatus,
  ValueGroupSet,
  ValueGroup,
  ValueDefinitionGroup,
  ValueDefinition,
  Value,
} from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class MetricPreviewVgsetConstructorService {
  constructVgset(metric: Metric, sourceId?: string, year?: string): ValueGroupSet {
    return {
      business_unit_id: sourceId ?? '',
      consolidated: false,
      id: '',
      indicator_id: metric.indicator_id ?? '',
      name: '',
      status: PlatformValueGroupSetStatus.NOT_STARTED,
      value_groups: metric.value_definition_groups
        ?.filter((vdg) => (DeactivationUtils.isDeactivated(vdg) ? vdg.active : true))
        .map((vdg) => this.constructValueGroup(vdg)),
      frequency_code: year,
      status_context: undefined,
    };
  }

  private constructValueGroup(grpDef: ValueDefinitionGroup): ValueGroup {
    return {
      id: '',
      indent: grpDef.indent,
      indicator_id: '',
      name: '',
      position: grpDef.position,
      active: grpDef.active,
      code: grpDef.code,
      conditional_triggers: grpDef.conditional_triggers,
      repeatable: grpDef.repeatable,
      required: grpDef.required,
      subposition: 1,
      table_id: grpDef.table_id,
      show_label_as_subtitle: grpDef.show_label_as_subtitle,
      value_definition_group_id: grpDef.id,
      values: grpDef.value_definitions
        ?.filter((vd) => (DeactivationUtils.isDeactivated(vd) ? vd.active : true))
        .map((valDef) => this.constructValue(valDef)),
      group_original_presentation: grpDef.group_original_presentation ?? '',
      group_max_repetition: grpDef.group_max_repetition ?? 0,
      label: grpDef.label,
    };
  }

  private constructValue(valueDef: ValueDefinition): Value {
    return {
      id: '',
      name: '',
      newline: valueDef.newline,
      position: valueDef.position,
      size: valueDef.size,
      type: valueDef.type,
      type_details: valueDef.type_details,
      value_definition_group_id: valueDef.value_definition_group_id,
      value_definition_id: valueDef.id,
      active: valueDef.active,
      code: valueDef.code,
      consolidation_rule: valueDef.consolidation_rule,
      conditional_triggers: valueDef.conditional_triggers,
      hint: valueDef.hint,
      label: valueDef.label,
      required: valueDef.required,
      unit: valueDef.unit,
      validators: valueDef.validators,
      value: null,
      value_group_id: '',
    };
  }
}
