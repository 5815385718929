import { LANGUAGES } from '../../common';
import { ProfileInfo } from '../profile';
import { UserType } from '../../entities';
import { UserRoles } from '../../configs/config';

export { UserInfo, CreateUser, ListLimitedUsersSearchOptions, ListUsersSearchOptions };

interface UserInfo extends ProfileInfo {
  allow_basic_auth: boolean;
  roles: string[];
  email: string;
}

interface CreateUser extends UserInfo {
  app_language?: LANGUAGES;
}

interface ListLimitedUsersSearchOptions {
  order_by_direction?: 'asc' | 'desc';
  order_by?: string;
  user_ids?: string[];
  type?: UserType | 'null';
  active?: boolean;
  query?: string; // Search users by first and last name
}

interface ListUsersSearchOptions extends ListLimitedUsersSearchOptions {
  roles?: UserRoles[];
}
