import { Injectable } from '@angular/core';
import { ComponentStore, OnStoreInit, tapResponse } from '@ngrx/component-store';
import { MetricApiService } from '../../../../services/types';
import { EMPTY, Observable, switchMap } from 'rxjs';
import { ApiResponse, MetricTableDefinition } from '../../../../models';
import { MetricStructureSelectable, MetricTableGroup } from '../../../models';
import { filter, map } from 'rxjs/operators';
import { ObservableUtils } from '../../../../classes';

export interface FieldInformationState {
  selectedItem: MetricStructureSelectable | undefined;
  tableDefinition: MetricTableDefinition | undefined;
}

@Injectable()
export class FieldInformationStore extends ComponentStore<FieldInformationState> implements OnStoreInit {
  private static readonly DEFAULT_STATE: FieldInformationState = {
    selectedItem: undefined,
    tableDefinition: undefined,
  };

  public readonly selectedItem$: Observable<MetricStructureSelectable | undefined> = this.select(
    (state) => state.selectedItem
  );
  public readonly tableDefinition$: Observable<MetricTableDefinition | undefined> = this.select(
    (state) => state.tableDefinition
  );

  constructor(private readonly metricsService: MetricApiService) {
    super(FieldInformationStore.DEFAULT_STATE);
  }

  ngrxOnStoreInit(): void {
    this.tableDefinitionEffect();
  }

  public readonly updateSelectedItem = this.updater(
    (state: FieldInformationState, selectedItem: MetricStructureSelectable | undefined): FieldInformationState => ({
      ...state,
      selectedItem,
    })
  );

  private readonly updateTableDefinition = this.updater(
    (state: FieldInformationState, tableDefinition: MetricTableDefinition | undefined): FieldInformationState => ({
      ...state,
      tableDefinition,
    })
  );

  private readonly tableDefinitionEffect = this.effect((_) =>
    this.selectedItem$.pipe(
      ObservableUtils.filterNullish(),
      filter((selectedItem: MetricStructureSelectable) => 'table_id' in selectedItem),
      map((selectedItem: MetricStructureSelectable) => selectedItem as MetricTableGroup),
      switchMap((selectedItem: MetricTableGroup) =>
        this.metricsService.getMetricTable(selectedItem.metric_id, selectedItem.table_id)
      ),
      tapResponse(
        (tableDefinition: ApiResponse<MetricTableDefinition>) => {
          this.updateTableDefinition(tableDefinition.data);
        },
        (_err) => {
          this.updateTableDefinition(undefined);
          return EMPTY;
        }
      )
    )
  );
}
