import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BaseTemplateReport,
  EmptyResults,
  Metric,
  Permission,
  TemplateReportSection,
  TemplateReportSectionMetric,
  TemplateReportVersionPayload,
} from '../models';
import { TranslateService } from '../services/common';
import { TemplateReportsApiService } from '../services/api-services';
import { finalize, Observable } from 'rxjs';
import { TemplateReportStructureStateService } from './services/template-report-structure-state.service';

@Component({
  selector: 'lib-template-report-structure',
  templateUrl: './template-report-structure.component.html',
  styleUrls: ['./template-report-structure.component.scss'],
  providers: [TemplateReportStructureStateService],
})
export class TemplateReportStructureComponent {
  @Input() managePermissions: Permission[] = [];
  @Input() set templateReport(templateReport: BaseTemplateReport | undefined) {
    this.templateReportStructureStateService.updateTemplateReport(templateReport);
    if (templateReport) {
      this.templateReportStructureStateService.loadTemplateReportSections();
    }
  }

  @Output() previewMetric: EventEmitter<Metric> = new EventEmitter<Metric>();

  readonly noSection: EmptyResults = {
    title: '',
    subtitle: this.translateService.instant('Start by adding your first section'),
    image: 'laptop-neutral',
    button: this.translateService.instant('Add a section'),
  };

  baseTemplateReport$: Observable<BaseTemplateReport | undefined>;
  templateReportSections$: Observable<TemplateReportSection[] | undefined>;
  templateReportId$: Observable<string | undefined>;
  templateReportVersionId$: Observable<string | undefined>;

  selectedItem?: BaseTemplateReport | TemplateReportSection | TemplateReportSectionMetric;

  creatingSection: boolean = false;
  isEditing: boolean = false;

  constructor(
    private readonly templateReportsApiService: TemplateReportsApiService,
    private readonly templateReportStructureStateService: TemplateReportStructureStateService,
    private readonly translateService: TranslateService
  ) {
    this.baseTemplateReport$ = templateReportStructureStateService.baseTemplateReport$;
    this.templateReportSections$ = templateReportStructureStateService.templateReportSections$;
    this.templateReportId$ = templateReportStructureStateService.templateReportId$;
    this.templateReportVersionId$ = templateReportStructureStateService.templateReportVersionId$;
  }

  public selectTemplateReportSettings(templateReport: BaseTemplateReport | TemplateReportSection): void {
    this.selectedItem = templateReport;
  }

  public addSection(): void {
    this.closeProperties();
    this.creatingSection = true;
  }

  public addSubSection(templateReportSection: TemplateReportSection): void {
    this.closeProperties();
    this.selectedItem = templateReportSection;
  }

  public editSection(templateReportSection: TemplateReportSection): void {
    this.closeProperties();
    this.selectedItem = templateReportSection;
    this.isEditing = true;
  }

  public addSectionMetric(templateReportSectionMetric: TemplateReportSectionMetric): void {
    this.closeProperties();
    this.selectedItem = templateReportSectionMetric;
  }

  public editSectionMetric(templateReportSectionMetric: TemplateReportSectionMetric): void {
    this.closeProperties();
    this.selectedItem = templateReportSectionMetric;
    this.isEditing = true;
  }

  public updateVersion(payload: TemplateReportVersionPayload, templateReportId: string): void {
    if (this.selectedItem) {
      this.templateReportsApiService
        .updateTemplateReportVersion(templateReportId, (this.selectedItem as BaseTemplateReport).id, payload)
        .pipe(finalize(() => this.closeProperties()))
        .subscribe({
          next: (res) => {
            this.templateReport = res.data;
          },
        });
    }
  }

  public closeProperties(): void {
    this.selectedItem = undefined;
    this.creatingSection = false;
    this.isEditing = false;
  }
}
