import { Component, Input } from '@angular/core';

import { BaseService } from '../../services/common/base/base.service';
import { ActionItem } from '../../models';

@Component({
  selector: 'lib-section-secondary',
  templateUrl: './section-secondary.component.html',
  styleUrls: ['./section-secondary.component.scss'],
})
export class SectionSecondaryComponent {
  @Input() title: string = '';
  @Input() link: string = '';
  @Input() linkText: string = '';
  @Input() items?: ActionItem[];

  initialItems: any[] = new Array(6);

  constructor(private baseService: BaseService) {}
}
