<ng-container *ngIf="dynamicFieldForm$ | async as dynamicFieldForm">
  <lib-select-input
    class="formfield fx-grow required pt-1 pb-2"
    [label]="'Option list display type' | translate"
    [hint]="'Pick one' | translate"
    [control]="dynamicFieldForm.propertiesControls.widgetType"
    [options]="widgetTypes"
    (selectionChangeEvent)="widgetTypeChanged()"
  >
  </lib-select-input>

  <div class="formfield large pt-1 pb-2" *ngIf="dynamicFieldForm.propertiesControls.choiceList">
    <lib-metric-structure-choice-field-selection
      [selectionListFormControl]="dynamicFieldForm.propertiesControls.choiceList"
      [valueDefinition]="valueDefinition"
      [canCreateCustomChoices]="!!(canCreateCustomChoices$ | async)"
      (customChoiceAnswersChange)="updateCustomChoiceAnswers($event)"
    >
    </lib-metric-structure-choice-field-selection>
  </div>

  <lib-slider
    [label]="'Include an Explanation Text Field' | translate"
    [control]="dynamicFieldForm.propertiesControls.displayExplanation"
    (onSliderToggle)="toggleDisplay($event)"
  >
  </lib-slider>

  <div
    *ngIf="
      dynamicFieldForm.propertiesControls.displayExplanation?.value &&
      dynamicFieldForm.propertiesControls.explanationLabel
    "
    class="large required ml-8 pt-1 pb-4"
  >
    <lib-slider
      class="formfield pt-1 pb-2"
      [label]="'Explanation Text Field is required' | translate"
      [control]="dynamicFieldForm.propertiesControls.explanationRequired"
    >
    </lib-slider>
    <lib-text-input
      [label]="'Explanation Text Field label' | translate"
      [hint]="'This will appear as a textbox with a 5000 characters limit' | translate"
      [control]="dynamicFieldForm.propertiesControls.explanationLabel"
      class="formfield pt-1 pb-2"
    ></lib-text-input>
  </div>

  <lib-slider
    *ngIf="dynamicFieldForm.propertiesControls.allowAddOption?.enabled"
    class="formfield"
    [label]="'Allows users to manually enter options' | translate"
    [control]="dynamicFieldForm.propertiesControls.allowAddOption"
  >
  </lib-slider>
</ng-container>
