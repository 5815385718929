import { MetricTableCalculationDefinition, MetricTableCalculationTypeDetail } from '../models';
import { MetricTableCalculationTypeDetailBuilder } from './metric-table-calculation-type-detail-builder';

export class MetricTableCalculationDefinitionBuilder {
  private readonly _metricTableCalculationDefinition: MetricTableCalculationDefinition;

  constructor() {
    this._metricTableCalculationDefinition = {
      id: 'id',
      metric_table_definition_id: '',
      label: 'Calculation Label',
      position: 1,
      type_details: new MetricTableCalculationTypeDetailBuilder().build(),
    };
  }

  id(id: string): MetricTableCalculationDefinitionBuilder {
    this._metricTableCalculationDefinition.id = id;
    return this;
  }

  metricTableDefinitionId(metricTableDefinitionId: string): MetricTableCalculationDefinitionBuilder {
    this._metricTableCalculationDefinition.metric_table_definition_id = metricTableDefinitionId;
    return this;
  }

  label(label: string): MetricTableCalculationDefinitionBuilder {
    this._metricTableCalculationDefinition.label = label;
    return this;
  }

  position(position: number): MetricTableCalculationDefinitionBuilder {
    this._metricTableCalculationDefinition.position = position;
    return this;
  }

  typeDetails(typeDetails: MetricTableCalculationTypeDetail): MetricTableCalculationDefinitionBuilder {
    this._metricTableCalculationDefinition.type_details = typeDetails;
    return this;
  }

  build(): MetricTableCalculationDefinition {
    return this._metricTableCalculationDefinition;
  }
}
