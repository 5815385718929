import { Injectable } from '@angular/core';
import { ApiResponse, ApplicationApiDefinition, ClientInfo, SelectionSetItem, Unit } from '../../../models';
import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common/api/api.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ClientPublicCoreService {
  apiName: keyof ApplicationApiDefinition = 'public';
  resource: string;
  servicePath: string;

  constructor(private baseService: BaseService, private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.public.resources.core;
  }

  public listSelectionSetItems(selectionSetId: string): Observable<ApiResponse<SelectionSetItem[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/selection_sets/${selectionSetId}/selection_set_items`
    );
  }

  public listUnits(family?: string): Observable<ApiResponse<Unit[]>> {
    let params = new HttpParams();
    params = family ? params.append('family', family) : params;
    return this.apiService.get(`${this.servicePath}${this.resource}/units`, { params });
  }

  public getClientInfo(): Observable<ApiResponse<ClientInfo>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/client_info`);
  }
}
