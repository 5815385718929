import { MESSAGE_TYPES } from './message-types';
import { DataRequestIndicator, DataRequestSource, ReportCategoryType } from '../models';

enum MessageContextType {
  background_task = 'background_task',
}

export interface MessageContext {
  type: MessageContextType;
  user_id?: string;
}

export interface UpdateDataRequestIndicatorPayload {
  user_id: string;
  indicator: DataRequestIndicator;
}

export interface UpdateDataRequestSourcePayload {
  user_id: string;
  data_request_source: DataRequestSource;
}

export interface ReportCompletePayload {
  report_name: string;
  report_id: string;
  report_category: ReportCategoryType;
}

export interface DataRequestPrefillPayload {
  data_request_id: string;
}

export interface DataRequestSourcePrefillPayload {
  data_request_id: string;
  data_request_source_id: string;
}

export interface ExportPayload {
  document_id: string;
  document_name: string;
  error_message?: string;
  error_details?: string;
}

export interface SPReportPayload {
  report_id: string;
  report_name: string;
  sp_upload_instance_id?: string;
  filename?: string;
  url?: string;
  error_message?: string;
  error_details?: string;
}

export interface CDPReportPayload {
  report_id: string;
  report_name: string;
  error_message?: string;
  error_details?: string;
}

export interface TemplateReportExportMessagePayload {
  document_name: string;
  document_id?: string;
  error_message?: string;
  error_details?: string;
}

export interface ConsolidationPayload {
  type: string;
  frequency_codes: string[];
}

export type MessagePayload = UpdateDataRequestIndicatorPayload | UpdateDataRequestSourcePayload;

export interface Message<T> {
  id: string;
  type: MESSAGE_TYPES;
  version: string;
  source: string;
  context?: MessageContext;
  time: string;
  data: T;
}

export function isUpdateDataRequestIndicatorMessage(
  message: Message<unknown>
): message is Message<UpdateDataRequestIndicatorPayload> {
  return message.type === MESSAGE_TYPES.PLATFORM_DATA_REQUEST_INDICATOR_VALUE_GROUP_SET_UPDATE;
}

export function isUpdateDataRequestSourceMessage(
  message: Message<unknown>
): message is Message<UpdateDataRequestSourcePayload> {
  return message.type === MESSAGE_TYPES.PLATFORM_DATA_REQUEST_SOURCE_UPDATE;
}
