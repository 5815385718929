import { ApiResponse, Entity, Tag, Org } from './entities';
import { Metric, ValueGroupSet } from './metrics';
import { PeerDataValueNullCategories } from './values';

export interface Benchmark extends Entity {
  metric_category: BenchmarkMetricCategory;
  report_id?: string;
  reporting_year?: string;
  metrics?: MetricWithPosition[];
  tags?: Tag[];
  columns?: BenchmarkColumn[];
  cells?: BenchmarkCell[];
}

export interface BenchmarkV2 extends Entity {
  metric_category: BenchmarkMetricCategory;
  report_id?: string;
  reporting_year?: string;
  metrics: BenchmarkV2Metric[];
  orgs: Org[];
}

export interface MetricWithPosition extends Metric {
  position: number;
}

export interface BenchmarkColumn {
  benchmark_interval_id: string;
  fiscal_year: number;
  position: number;
  org: Org | undefined;
  created: Date;
  updated: Date;
}

export interface BenchmarkCell {
  org_id?: string | undefined;
  metric_id: string;
  fiscal_year: number;
  value_group_sets?: ValueGroupSet[];
}

export interface CreateBenchmarkRequest {
  name: string;
  metricCategory: BenchmarkMetricCategory;
  reportId?: string;
}

export enum BenchmarkMetricCategory {
  THIRD_PARTY = 'third_party',
  REFERENCE = 'reference',
  LEGACY = 'legacy',
}

export interface ListBenchmarkRequest {
  page?: number;
  pageSize?: number;
  searchQuery?: string | undefined;
  tags?: string | undefined;
  metricCategory?: string | undefined;
  orderBy?: string | undefined;
}

export interface ExportBenchmarkV2Request {
  name: string;
  export_format: BenchmarkV2ExportFormat;
}

export enum BenchmarkV2Attribute {
  all = 'all',
  explanation = 'explanation',
  pageNumber = 'page_number',
  url = 'url',
}

export enum BenchmarkV2ExportFormat {
  csv = 'csv',
  xlsx = 'xlsx',
}

export interface BenchmarkV2ValueDefinition {
  id: string;
  attribute: BenchmarkV2Attribute;
}

export interface BenchmarkV2Metric {
  id: string;
  value_definitions: BenchmarkV2ValueDefinition[];
}

export interface BenchmarkIndicatorValuesRequest {
  frequency_code: string;
  metrics: BenchmarkV2Metric[];
  page: number;
  page_size: number;
  business_unit_id?: string;
  report_id?: string;
}

export interface BenchmarkValuesRequest {
  frequency_code: string;
  metrics: BenchmarkV2Metric[];
  page: number;
  page_size: number;
  peers: string[];
}

export interface DuplicateBenchmarkV2Request {
  name: string;
}

export interface UpsertBenchmarkV2Request {
  metrics: BenchmarkV2Metric[];
  name: string;
  peers: string[];
  report_id: string;
}

export interface BenchmarkV2ResponseValue {
  peer_id: string;
  attribute: BenchmarkV2Attribute;
  value_definition_id: string;
  null_categories?: (PeerDataValueNullCategories | null)[];
  values?: unknown[];
}

export interface BenchmarkV2ResponseIndicatorValue {
  attribute: BenchmarkV2Attribute;
  value_definition_id: string;
  values?: unknown[];
}

export type BenchmarkValuesV2Response = ApiResponse<BenchmarkV2ResponseValue[]>;

export type BenchmarkIndicatorValuesV2Response = ApiResponse<BenchmarkV2ResponseIndicatorValue[]>;

export interface BenchmarkV2ListValue {
  value: string;
  children?: BenchmarkV2ListValue[];
}

export interface BenchmarkV2RowValue {
  empty: boolean;
  value: string | BenchmarkV2ListValue[];
}

export interface BenchmarkV2Row extends Record<string, BenchmarkV2RowValue | boolean | string> {
  id: string;
  attribute: BenchmarkV2Attribute;
  code: string;
  data: BenchmarkV2RowValue;
  description: string;
  label: string;
  list: boolean;
  richText: boolean;
  table: boolean;
  valueDefinitionId: string;
}
