import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { distinctUntilChanged, startWith, Subject, tap } from 'rxjs';
import { ValueFormControl } from '../../models/valueFormControl';
import {
  CalculatedTypeDetails,
  DecimalTypeDetails,
  IntegerTypeDetails,
  ItemType,
  MetricTableDefinition,
  NOT_APPLICABLE_NUMERIC_VALUE,
  ValueDefinitionSize,
  ValueDefinitionType,
} from '../../../models';
import { UnitsService } from '../../../units';
import { NumericInputComponent } from '../../../components/form/numeric-input/numeric-input.component';
import isEqual from 'lodash/isEqual';
import { TranslateService } from '../../../services/common';

@Component({
  selector: 'lib-metric-editor-numeric-field',
  templateUrl: './metric-editor-numeric-field.component.html',
  styleUrls: ['./metric-editor-numeric-field.component.scss'],
})
export class MetricEditorNumericFieldComponent implements OnInit, OnDestroy {
  @Input() valueFormControl?: ValueFormControl<IntegerTypeDetails | DecimalTypeDetails | CalculatedTypeDetails>;
  @Input() metricTableDefinition?: MetricTableDefinition;
  @Input() indicatorId: string = '';
  @Input() vgsetId: string = '';
  @Input() groupId: string = '';
  @Input() itemType: ItemType = ItemType.metrics_indicator;

  @ViewChild(NumericInputComponent) inputFieldRef!: NumericInputComponent;

  hint = '';
  label = '';
  unit = '';
  size: ValueDefinitionSize = ValueDefinitionSize.large;
  isCalculated = false;
  isDecimal = false;
  maxDecimals?: number;
  calculationErrorMsg: string = '';
  private valueUpdateSubject$ = new Subject<string[] | null>();

  constructor(private readonly unitsService: UnitsService, private translateService: TranslateService) {}

  ngOnInit(): void {
    if (this.valueFormControl) {
      this.hint = this.valueFormControl.valueRef.hint ?? '';
      this.label = this.valueFormControl.valueRef.label ?? '';
      this.unit = this.getUnitSymbol(this.valueFormControl.valueRef.type_details.units);
      this.size = this.valueFormControl.valueRef.size;

      if (this.valueFormControl.valueRef.type === ValueDefinitionType.calculated) {
        this.isCalculated = true;
        this.valueFormControl.registerOnChange(() => {
          this.valueUpdateSubject$.next(this.valueFormControl?.value as string[]);
        });

        this.valueUpdateSubject$
          .pipe(
            startWith(this.valueFormControl.value as string | null),
            distinctUntilChanged(isEqual),
            tap((value) => {
              this.calculationErrorMsg = '';
              if (value === NOT_APPLICABLE_NUMERIC_VALUE || value == null) {
                this.updateErrorMessage();
              }
            })
          )
          .subscribe();
      }

      if (
        this.valueFormControl.valueRef.type === ValueDefinitionType.calculated ||
        this.valueFormControl.valueRef.type === ValueDefinitionType.decimal
      ) {
        this.isDecimal = true;
        this.maxDecimals = (
          this.valueFormControl.valueRef.type_details as DecimalTypeDetails | CalculatedTypeDetails
        ).max_decimals;
        if (!this.metricTableDefinition && !this.maxDecimals && this.valueFormControl.value % 1 !== 0) {
          this.maxDecimals = 2;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.updateValue();
  }

  setFocus(): void {
    this.inputFieldRef.setFocus();
  }

  updateValue(): void {
    this.inputFieldRef.setBlur();
  }

  private getUnitSymbol(code: string | undefined): string {
    if (code) {
      const unit = this.unitsService.getUnit(code);
      return unit?.symbol ?? '';
    }
    return '';
  }

  private updateErrorMessage(): void {
    if (this.indicatorId && this.vgsetId && this.groupId && this.valueFormControl?.valueRef) {
      this.calculationErrorMsg = this.translateService.instant('Cannot calculate the field');
    }
  }
}
