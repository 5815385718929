import { Directive, HostBinding, Input } from '@angular/core';
import { Required } from '../../../decorators';

@Directive({
  selector: '[libDeactivatableMetricStructureEditor]',
})
export class DeactivatableMetricStructureEditorDirective {
  @Input('libDeactivatableMetricStructureEditor') @Required() disabled!: boolean;

  @HostBinding('class') get disablingClass(): string {
    return this.disabled ? 'disabled-interactions' : '';
  }
}
