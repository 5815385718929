export abstract class BaseJobInProgressException extends Error {
  protected constructor(message: string) {
    super(message);
  }
}

export class SPJobInProgressException extends BaseJobInProgressException {
  constructor() {
    super('S&P submit job is already in progress');
  }
}

export class CDPJobInProgressException extends BaseJobInProgressException {
  constructor() {
    super('CDP submit job is already in progress');
  }
}

export class PasswordReuseException extends Error {
  constructor() {
    super('Password must be different from last used password');
  }
}

export class CDPInvalidTokenException extends Error {
  constructor() {
    super('The token used to access CDP was invalid');
  }
}

export class CDPInvalidConfigurationException extends Error {
  constructor() {
    super('CDP integration is not rightly configured');
  }
}

export class CDPResourceNotFoundException extends Error {
  constructor() {
    super('CDP resource not found');
  }
}

export class CDPUnauthorizedException extends Error {
  constructor() {
    super('Operation unauthorized on the CDP Portal');
  }
}

export class CDPGeneralException extends Error {}
