import { Pipe, PipeTransform } from '@angular/core';
import { Metric } from '../../../models';
import { MetricStructureSelectable } from '../../models';
import { MetricUtils } from '../../classes/metric-utils/metric-utils';

@Pipe({
  name: 'isEntityFromCoreRefV2Metric',
})
export class IsEntityFromCoreRefV2MetricPipe implements PipeTransform {
  transform(metricStructureSelectable?: MetricStructureSelectable, metric?: Metric): boolean {
    return MetricUtils.isEntityFromCoreRefV2Metric(metricStructureSelectable, metric);
  }
}
