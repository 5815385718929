import { RequestDocMetadata } from '../models';

export class RequestDocMetadataBuilder {
  private readonly _requestDocMetadata: RequestDocMetadata;

  constructor() {
    this._requestDocMetadata = {
      id: 'docId',
      data_period_end: '',
      data_period_start: '',
      extension: 'pdf',
      fiscal_year: 2022,
      name: 'file_name',
      updated: '2020-10-05T17:41:00.000000+00:00',
      deleted: '2020-10-05T17:41:00.000000+00:00',
    };
  }

  id(id: string): RequestDocMetadataBuilder {
    this._requestDocMetadata.id = id;
    return this;
  }

  name(name: string): RequestDocMetadataBuilder {
    this._requestDocMetadata.name = name;
    return this;
  }

  extension(extension: string): RequestDocMetadataBuilder {
    this._requestDocMetadata.extension = extension;
    return this;
  }

  build(): RequestDocMetadata {
    return this._requestDocMetadata;
  }
}
