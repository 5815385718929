import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ActionItem } from '../../../models';

@Component({
  selector: 'lib-filter-bar-menu',
  templateUrl: './filter-bar-menu.component.html',
  styleUrls: ['./filter-bar-menu.component.scss'],
})
export class FilterBarMenuComponent {
  @Input() menuTitle: string = '';
  @Input() allOption: boolean = false;
  @Input() minMenuScrollItems: number = 10;
  @Input() control?: FormControl;
  @Input() initialItems: ActionItem[] = [];
  @Input() filteredItems: ActionItem[] | null = this.initialItems;
  @Input() selectedItemId?: string;
  @Input() categories?: string[];
  @Input() filterItemsFunction: (item: ActionItem, category?: string) => boolean = () => true;

  @Output() setItem = new EventEmitter<ActionItem | undefined>();
}
