import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Observable } from 'rxjs';

import { ActionItem, Framework, StandardCodeCategory } from '../../../models';
import { StandardCodeCategoryTranslations } from '../../../translations';

@Component({
  selector: 'lib-search-bar-standard-code-menu',
  templateUrl: './search-bar-standard-code-menu.component.html',
  styleUrls: ['./search-bar-standard-code-menu.component.scss'],
})
export class SearchBarStandardCodeMenuComponent {
  @Input() control?: UntypedFormControl;
  @Input() filteredItems?: Observable<ActionItem<Framework>[]>;
  @Input() items: ActionItem<Framework>[] = [];
  @Input() minMenuScrollItems: number = 10;
  @Input() selectedItemId?: string;

  @Output() setItem = new EventEmitter<ActionItem<Framework> | undefined>();

  public readonly eStandardCodeCategoryTranslations = StandardCodeCategoryTranslations;
  public readonly standardCodeFilterCategories: StandardCodeCategory[] = [
    StandardCodeCategory.REPORTING_STANDARD,
    StandardCodeCategory.REPORTING_FRAMEWORK,
    StandardCodeCategory.REGULATION,
    StandardCodeCategory.RATING,
  ];

  public filterStandardCodes(item: ActionItem<Framework>, category?: StandardCodeCategory): boolean {
    return item.item?.standard_code_category === category;
  }
}
