import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Permission, User } from '../../models';
import { AuthService } from '../../services/common';
import { ObservableUtils } from '../../classes';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[libPermission]',
})
export class PermissionDirective implements OnInit, OnDestroy {
  @Input() libPermission?: Permission[];
  @Input() libPermissionElse?: TemplateRef<unknown>;

  private isHidden: boolean = true;
  private permissionDirective$?: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.permissionDirective$ = this.authService
      .getUserInfo()
      .pipe(
        ObservableUtils.filterNullish(),
        map((user: User) => this.updateView(user.permissions))
      )
      .subscribe();
  }

  private hasPermission(userPermissions: Permission[]): boolean {
    if (!this.libPermission?.length) {
      return true;
    }

    return this.libPermission.some((requiredPermission: Permission) => userPermissions.includes(requiredPermission));
  }

  private updateView(userPermissions: Permission[]) {
    if (this.hasPermission(userPermissions)) {
      if (this.isHidden) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else if (this.libPermissionElse) {
      this.viewContainerRef.createEmbeddedView(this.libPermissionElse);
    } else {
      this.viewContainerRef.clear();
      this.isHidden = true;
    }
  }

  ngOnDestroy(): void {
    this.permissionDirective$?.unsubscribe();
  }
}
