import { Component, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { SearchService } from '../services/search.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { BaseSearchItemsDirective } from '../base-search-items.directive';
import { ActionItemNode } from '../../models';
import { EventsService } from '../../services/common';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-search-tree-items',
  templateUrl: './search-tree-items.component.html',
  styleUrls: ['./search-tree-items.component.scss'],
})
export class SearchTreeItemsComponent extends BaseSearchItemsDirective implements OnInit, OnChanges, OnDestroy {
  treeControl = new NestedTreeControl<ActionItemNode>((node) => node.childNodes);
  dataSource = new MatTreeNestedDataSource<ActionItemNode>();
  hasChild = (_: number, node: ActionItemNode) => !!node.childNodes && node.childNodes.length > 0;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(renderer: Renderer2, eventsService: EventsService, private searchService: SearchService) {
    super(renderer, eventsService);
  }

  ngOnInit(): void {
    this.search();
    this.eventsService
      .getSelectedItem()
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedItemID) => {
        setTimeout(() => {
          this.selectedItemID = selectedItemID;
        });
      });
    this.refresh.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (response) {
        this.search();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // prevent search for unintended events
    // emit changes is triggered twice on search bar on keywords control enter, once with an Event object
    if (changes.searchOptions && typeof changes.searchOptions.currentValue === 'string') {
      this.search();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  search(): void {
    if (this.searchOptions) {
      const searchOptions = JSON.parse(this.searchOptions);
      this.noData = !Object.keys(searchOptions.filters).length && !searchOptions.query.keywords;
      this.loaded = false;
      this.searchService.search(searchOptions, this.cache).subscribe((result) => {
        const treeData = this.treefy(result.items);
        this.treeControl.dataNodes = treeData;
        this.dataSource.data = treeData;
        this.treeControl.expandAll();
        this.loaded = true;
      });
    }
  }

  treefy(flatArray: ActionItemNode[]): ActionItemNode[] {
    const map = new Map<string, number>();
    let node,
      treeData: ActionItemNode[] = [];

    for (let i = 0; i < flatArray.length; i++) {
      map.set(flatArray[i].id, i);
      flatArray[i].childNodes = [];
    }

    for (let i = 0; i < flatArray.length; i++) {
      node = flatArray[i];
      if (node.parent_id) {
        flatArray[map.get(node.parent_id)!].childNodes?.push(node);
      } else {
        treeData.push(node);
      }
    }
    return treeData;
  }
}
