import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

import { DateUtils } from '../../../classes';
import { Org } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class PeersService {
  constructor() {}

  public getOrgDescription(org: Org): string {
    let description = '';

    if (org.industries?.length) {
      description += org.industries.map((i) => i.name).join(', ');
    }

    if (org.fiscal_year_information) {
      description += ' — ';
      description += this.getFiscalYearDescription(org);
    }

    return description;
  }

  getFiscalYearDescription(org: Org): string {
    let description = '';
    if (org.fiscal_year_information) {
      switch (org.fiscal_year_information.regular) {
        case null:
          description += 'No fiscal year information';
          break;
        case true:
          const startDate = DateUtils.dateParse(org.fiscal_year_information.start);
          const endDate = this.getPeriodEnd(org.fiscal_year_information.start);
          description += `${formatDate(startDate, 'MMM d', 'en-CA')} to ${formatDate(endDate, 'MMM d', 'en-CA')}`;
          break;
        case false:
          description += 'Irregular fiscal year';
          break;
      }
    }
    return description;
  }

  getPeriodEnd(startDateString: string): Date {
    const end = DateUtils.dateParse(startDateString);
    end.setFullYear(end.getFullYear() + 1);
    end.setDate(end.getDate() - 1);
    return end;
  }
}
