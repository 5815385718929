import { StandardDocumentMetadata } from '../documents';
import { Builder } from './builder';
import { v4 as uuidv4 } from 'uuid';

export class StandardDocumentMetadataBuilder extends Builder<StandardDocumentMetadata> {
  constructor() {
    super({
      business_unit_ids: [],
      id: uuidv4(),
      name: 'A document',
      public: true,
      scope: '',
      tag_ids: [],
      category: 'Report',
      extension: 'pdf',
    });
  }

  id(id: string): StandardDocumentMetadataBuilder {
    this._instance.id = id;
    return this;
  }
}
