import { Component, Input } from '@angular/core';

import { Presentation } from '../../../models';
import { Required } from '../../../decorators';

@Component({
  selector: 'lib-dummy',
  templateUrl: './dummy.component.html',
  styleUrls: ['./dummy.component.scss'],
})
export class DummyComponent {
  @Input() @Required() presentation?: Presentation;
  @Input() numberOfDummies?: number;

  readonly ePresentation = Presentation;
  readonly dummyDataTableDisplayedColumns: string[] = ['dummy1', 'dummy2', 'dummy3', 'dummy4'];
  readonly DUMMY_ELEMENT_DATA = [
    { dummy1: '', dummy2: '', dummy3: '', dummy4: '' },
    { dummy1: '', dummy2: '', dummy3: '', dummy4: '' },
    { dummy1: '', dummy2: '', dummy3: '', dummy4: '' },
    { dummy1: '', dummy2: '', dummy3: '', dummy4: '' },
    { dummy1: '', dummy2: '', dummy3: '', dummy4: '' },
    { dummy1: '', dummy2: '', dummy3: '', dummy4: '' },
    { dummy1: '', dummy2: '', dummy3: '', dummy4: '' },
    { dummy1: '', dummy2: '', dummy3: '', dummy4: '' },
    { dummy1: '', dummy2: '', dummy3: '', dummy4: '' },
    { dummy1: '', dummy2: '', dummy3: '', dummy4: '' },
  ];
}
