import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/common';
import { CustomValidators } from '../../classes/CustomValidators/custom-validators';

interface DashboardCopyFormModel {
  newPassword: FormControl<string>;
  confirmPassword: FormControl<string>;
}

export class PasswordConfirmationForm extends FormGroup<DashboardCopyFormModel> {
  constructor(
    readonly authService: AuthService,
    readonly token?: string,
    readonly fb: FormBuilder = new FormBuilder()
  ) {
    super(
      {
        newPassword: fb.nonNullable.control('', {
          validators: [Validators.required],
          asyncValidators: [CustomValidators.passwordValidator(authService, token)],
        }),
        confirmPassword: fb.nonNullable.control('', { validators: [Validators.required] }),
      },
      CustomValidators.MatchPasswordValidator('newPassword', 'confirmPassword')
    );
  }

  toModel(): string {
    const password = this.controls.newPassword.value;

    return password;
  }
}
