import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';

import { BenchmarkV2, BenchmarkMetricCategory, BenchmarkV2Metric, Org } from '../models';
import { EntityBuilder } from './entity-builder';

export class BenchmarkV2Builder extends EntityBuilder<BenchmarkV2> {
  constructor() {
    super({
      id: uuidv4(),
      name: faker.company.bsNoun(),
      metric_category: BenchmarkMetricCategory.THIRD_PARTY,
      report_id: uuidv4(),
      reporting_year: '2023',
      orgs: [],
      metrics: [],
    });
  }

  public metricCategory(metricCategory: BenchmarkMetricCategory): BenchmarkV2Builder {
    this._instance.metric_category = metricCategory;
    return this;
  }

  public metrics(metrics: BenchmarkV2Metric[]): BenchmarkV2Builder {
    this._instance.metrics = metrics;
    return this;
  }

  public name(name: string): BenchmarkV2Builder {
    this._instance.name = name;
    return this;
  }

  public orgs(orgs: Org[]): BenchmarkV2Builder {
    this._instance.orgs = orgs;
    return this;
  }

  public reportId(reportId: string): BenchmarkV2Builder {
    this._instance.report_id = reportId;
    return this;
  }

  public reportingYear(reportingYear: string): BenchmarkV2Builder {
    this._instance.reporting_year = reportingYear;
    return this;
  }
}
