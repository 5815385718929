import {
  ConditionalTrigger,
  ConsolidationRules,
  ConsolidationTriggers,
  TypeDetails,
  Validator,
  Value,
  ValueDefinitionSize,
  ValueDefinitionType,
  ValueType,
  DefaultConsolidationRule,
  PeerDataValueNullCategories,
} from '../models';

export class ValueBuilder<T extends TypeDetails = any, U extends ValueType = any> {
  private readonly _value: Value;

  constructor() {
    this._value = {
      type_details: { textarea: false, rich_text: false },
      value_definition_id: 'vdId',
      id: 'vId',
      name: 'name',
      type: ValueDefinitionType.text,
      position: 1,
      size: ValueDefinitionSize.medium,
      newline: false,
      required: false,
      value_definition_group_id: 'vgd_1',
      value: '',
      label: 'field label',
      value_group_id: 'vg_1',
      consolidation_rule: DefaultConsolidationRule,
      null_category: undefined,
      unresolved_regular_comments_and_replies: 0,
      unresolved_rejection_comments_and_replies: 0,
    };
  }

  id(id: string): ValueBuilder<T, U> {
    this._value.id = id;
    return this;
  }

  label(label: string): ValueBuilder<T, U> {
    this._value.label = label;
    return this;
  }

  type(type: ValueDefinitionType): ValueBuilder<T, U> {
    this._value.type = type;
    return this;
  }

  required(required: boolean): ValueBuilder<T, U> {
    this._value.required = required;
    return this;
  }

  size(size: ValueDefinitionSize): ValueBuilder<T, U> {
    this._value.size = size;
    return this;
  }

  hint(hint: string): ValueBuilder<T, U> {
    this._value.hint = hint;
    return this;
  }

  typeDetails(typeDetails: T): ValueBuilder<T, U> {
    this._value.type_details = typeDetails;
    return this;
  }

  value(value: U): ValueBuilder<T, U> {
    this._value.value = value;
    return this;
  }

  valueDefinitionId(valueDefId: string): ValueBuilder<T, U> {
    this._value.value_definition_id = valueDefId;
    return this;
  }

  valueDefinitionGroupId(valueDefGroupId: string): ValueBuilder<T, U> {
    this._value.value_definition_group_id = valueDefGroupId;
    return this;
  }

  position(position: number): ValueBuilder<T, U> {
    this._value.position = position;
    return this;
  }

  conditionalTriggers(triggers: ConditionalTrigger[]): ValueBuilder<T, U> {
    this._value.conditional_triggers = triggers;
    return this;
  }

  created(created: Date): ValueBuilder<T, U> {
    this._value.created = created;
    return this;
  }

  valueGroupId(valueGroupId: string): ValueBuilder<T, U> {
    this._value.value_group_id = valueGroupId;
    return this;
  }

  validators(validators: Validator[]): ValueBuilder<T, U> {
    this._value.validators = validators;
    return this;
  }

  unit(unit: string): ValueBuilder<T, U> {
    this._value.unit = unit;
    return this;
  }

  consolidationRule(consolidationRule: ConsolidationRules): ValueBuilder<T, U> {
    this._value.consolidation_rule = consolidationRule;
    return this;
  }

  consolidationTrigger(consolidationTrigger: ConsolidationTriggers): ValueBuilder<T, U> {
    this._value.consolidation_trigger = consolidationTrigger;
    return this;
  }

  nullCategory(value: PeerDataValueNullCategories): ValueBuilder<T, U> {
    this._value.null_category = value;
    return this;
  }

  unresolvedRegularCommentsAndReplies(unresolvedRegularCommentsAndReplies: number): ValueBuilder<T, U> {
    this._value.unresolved_regular_comments_and_replies = unresolvedRegularCommentsAndReplies;
    return this;
  }

  technicalProtocol(technical_protocol: string): ValueBuilder<T, U> {
    this._value.technical_protocol = technical_protocol;
    return this;
  }

  unresolvedRejectionCommentsAndReplies(unresolvedRejectionCommentsAndReplies: number): ValueBuilder<T, U> {
    this._value.unresolved_rejection_comments_and_replies = unresolvedRejectionCommentsAndReplies;
    return this;
  }

  build(): Value<T, U> {
    return this._value as Value<T, U>;
  }
}
