import { DocumentTypeDetails } from '../models';
import { Builder } from './builder';
import { v4 as uuidv4 } from 'uuid';

export class DocumentTypeDetailsBuilder extends Builder<DocumentTypeDetails> {
  constructor() {
    super({
      document_host_env: 'platform',
      document_id: uuidv4(),
    });
  }

  displayName(displayName: string): DocumentTypeDetailsBuilder {
    this._instance.display_name = displayName;
    return this;
  }
}
