import {
  ChoiceFieldWidgetType,
  ValueDefinition,
  ValueDefinitionDisplayType,
  ValueDefinitionSize,
  ValueDefinitionType,
} from '../../../models';
import { MetricStructureSelectable, ValueDefinitionFormat } from '../../models';

export class ValueDefinitionUtils {
  public static isValueDefinitionOfType(
    valueDefinition: MetricStructureSelectable,
    types: ValueDefinitionDisplayType[]
  ): boolean {
    const currentValueDefinitionType = ValueDefinitionUtils.getValueDefinitionFormat(
      valueDefinition as ValueDefinition
    ).type;
    return types.some((t) => t === currentValueDefinitionType);
  }

  public static getValueDefinitionFormat(valueDefinition: ValueDefinition): ValueDefinitionFormat {
    let type: ValueDefinitionDisplayType | undefined;
    let size: ValueDefinitionSize = valueDefinition.size;
    let readonly: boolean = false;
    switch (valueDefinition.type) {
      case ValueDefinitionType.text:
        if (valueDefinition.type_details?.rich_text) {
          type = ValueDefinitionDisplayType.text_rich;
          size = ValueDefinitionSize.large;
        } else if (valueDefinition.type_details?.textarea) {
          type = ValueDefinitionDisplayType.text_area;
          size = ValueDefinitionSize.large;
        } else {
          type = ValueDefinitionDisplayType.text_simple;
        }
        break;
      case ValueDefinitionType.tip:
        type = ValueDefinitionDisplayType.tip;
        size = ValueDefinitionSize.large;
        break;
      case ValueDefinitionType.subtitle:
      case ValueDefinitionType.label:
        type = ValueDefinitionDisplayType.subtitle;
        size = ValueDefinitionSize.large;
        break;
      case ValueDefinitionType.integer:
        type = ValueDefinitionDisplayType.integer;
        break;
      case ValueDefinitionType.decimal:
        type = ValueDefinitionDisplayType.decimal;
        break;
      case ValueDefinitionType.datetime:
      case ValueDefinitionType.date:
        type = ValueDefinitionDisplayType.date;
        break;
      case ValueDefinitionType.choice:
        type = ValueDefinitionDisplayType.choice;
        if (valueDefinition.type_details?.widget_type === ChoiceFieldWidgetType.multi_chip) {
          size = ValueDefinitionSize.large;
        }
        break;
      case ValueDefinitionType.boolean:
        type = ValueDefinitionDisplayType.boolean;
        break;
      case ValueDefinitionType.calculated:
        type = ValueDefinitionDisplayType.calculated;
        readonly = true;
        break;
      case ValueDefinitionType.file:
        type = ValueDefinitionDisplayType.file;
        size = ValueDefinitionSize.large;
        break;
      case ValueDefinitionType.file_v2:
        type = ValueDefinitionDisplayType.file_v2;
        size = ValueDefinitionSize.large;
        break;
      case ValueDefinitionType.document:
        type = ValueDefinitionDisplayType.document;
    }
    if (!type) {
      throw new Error(`Value type not supported: ${valueDefinition.type}`);
    }
    return {
      type,
      size,
      readonly,
    };
  }
}
