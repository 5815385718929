<div *ngIf="control" aria-label="due-date-reminder-wrapper">
  <label *ngIf="label !== '' && control.length" class="due-date-reminder-label">
    <span>{{ label }}</span>
  </label>

  <form
    *ngFor="let row of control.controls; let first = first; let i = index"
    [formGroup]="row"
    class="due-date-reminder-form"
    [class.due-date-reminder-form-small]="isSmallScreen"
    [class.row-separator]="!first"
    aria-label="due-date-reminder-form"
  >
    <lib-select-input
      class="status"
      [ariaLabel]="'Status'"
      [control]="row.controls.source_status"
      [options]="sourceStatusItems"
    >
    </lib-select-input>

    <lib-select-input
      class="participants"
      [ariaLabel]="'Participant'"
      [control]="row.controls.participants"
      [options]="participantItems"
    >
    </lib-select-input>

    <lib-select-input
      class="schedule"
      [ariaLabel]="'Schedule'"
      [control]="row.controls.schedule"
      [options]="scheduleItems"
    >
    </lib-select-input>

    <button
      data-testid="removeBtn"
      [attr.type]="'button'"
      (click)="removeRow(i)"
      lib-button
      class="trash remove-row-btn"
    >
      <mat-icon class="icon-lg color-error" svgIcon="trash"></mat-icon>
    </button>

    <ng-container
      *ngIf="row.controls.participants.value === 'custom'"
      [ngTemplateOutlet]="customParticipants"
      [ngTemplateOutletContext]="{ row: row }"
    >
    </ng-container>
    <ng-container
      *ngIf="row.controls.schedule.value === eDueDateNotificationScheduleType.CUSTOM"
      [ngTemplateOutlet]="customSchedule"
      [ngTemplateOutletContext]="{ row: row }"
    >
    </ng-container>
  </form>

  <button
    *ngIf="control.length < maxItems; else maxItemsReached"
    class="mb-2"
    [attr.type]="'button'"
    (click)="addRow()"
    lib-button
    type="link"
  >
    {{ "+ Add notification" | translate }}
  </button>

  <lib-tip *ngIf="control.length" [iconConfig]="tipIcons.info">
    {{ "Reminders are not sent retroactively" | translate }}
  </lib-tip>
</div>

<ng-template #maxItemsReached>
  <lib-tip [iconConfig]="tipIcons.warning">
    {{ "You cannot send more than {maxItems} notifications per request " | translate : { maxItems } }}
  </lib-tip>
</ng-template>

<ng-template #customParticipants let-row="row">
  <div *ngIf="!isSmallScreen" class="custom-participants-label custom-input-label">
    {{ "Custom participants" | translate }}
  </div>
  <lib-multi-select-dropdown
    class="custom-participants"
    [placeholder]="'Select participants' | translate"
    [control]="row.controls.customParticipants"
    [options]="customParticipantItems"
  ></lib-multi-select-dropdown>
</ng-template>

<ng-template #customSchedule let-row="row">
  <div *ngIf="!isSmallScreen" class="custom-schedule-label custom-input-label">
    {{ "Custom schedule" | translate }}
  </div>
  <div class="custom-schedule">
    <lib-numeric-input
      class="schedule-frequency"
      [control]="row.controls.scheduleFrequency"
      [messages]="errorMessages"
    ></lib-numeric-input>
    <lib-select-input [control]="row.controls.scheduleTimeFrame" [options]="scheduleTimeframeItems"> </lib-select-input>
    <lib-select-input [control]="row.controls.scheduleRelativeTo" [options]="scheduleRelativeToItems">
    </lib-select-input>
  </div>
</ng-template>
