import {
  ConsolidationRules,
  ConsolidationTriggers,
  StandardCodes,
  TypeDetails,
  ValueDefinition,
  ValueDefinitionSize,
  ValueDefinitionType,
  DefaultConsolidationRule,
  ConditionalTrigger,
} from '../models';

export class ValueDefinitionBuilder<T extends TypeDetails> {
  private readonly _valueDefinition: ValueDefinition<T>;

  constructor() {
    this._valueDefinition = {
      id: 'vdId',
      name: 'name',
      type: ValueDefinitionType.text,
      position: 1,
      size: ValueDefinitionSize.medium,
      newline: false,
      required: false,
      context: false,
      value_definition_group_id: 'vdgId',
      label: undefined,
      unit: undefined,
      standard_codes: [],
      consolidation_rule: DefaultConsolidationRule,
      active: true,
    };
  }

  id(id: string): ValueDefinitionBuilder<T> {
    this._valueDefinition.id = id;
    return this;
  }

  valueDefinitionGroupId(value_definition_group_id: string) {
    this._valueDefinition.value_definition_group_id = value_definition_group_id;
    return this;
  }

  label(label?: string): ValueDefinitionBuilder<T> {
    this._valueDefinition.label = label;
    return this;
  }

  unit(unit?: string): ValueDefinitionBuilder<T> {
    this._valueDefinition.unit = unit;
    return this;
  }

  type(type: ValueDefinitionType): ValueDefinitionBuilder<T> {
    this._valueDefinition.type = type;
    return this;
  }

  size(size: ValueDefinitionSize): ValueDefinitionBuilder<T> {
    this._valueDefinition.size = size;
    return this;
  }

  consolidationRule(consolidationRule: ConsolidationRules): ValueDefinitionBuilder<T> {
    this._valueDefinition.consolidation_rule = consolidationRule;
    return this;
  }

  consolidationTrigger(consolidationTrigger: ConsolidationTriggers | null): ValueDefinitionBuilder<T> {
    this._valueDefinition.consolidation_trigger = consolidationTrigger;
    return this;
  }

  hint(hint: string): ValueDefinitionBuilder<T> {
    this._valueDefinition.hint = hint;
    return this;
  }

  position(position: number): ValueDefinitionBuilder<T> {
    this._valueDefinition.position = position;
    return this;
  }

  typeDetails(typeDetails: T): ValueDefinitionBuilder<T> {
    this._valueDefinition.type_details = typeDetails;
    return this;
  }

  newline(newline: boolean): ValueDefinitionBuilder<T> {
    this._valueDefinition.newline = newline;
    return this;
  }

  shortLabel(shortLabel: string): ValueDefinitionBuilder<T> {
    this._valueDefinition.short_label = shortLabel;
    return this;
  }

  standardCodes(standardCodes: StandardCodes[]): ValueDefinitionBuilder<T> {
    this._valueDefinition.standard_codes = standardCodes;
    return this;
  }

  active(active?: boolean): ValueDefinitionBuilder<T> {
    this._valueDefinition.active = active;
    return this;
  }

  endFiscalYearFrequencyCode(endFiscalYearFrequencyCode: string): ValueDefinitionBuilder<T> {
    this._valueDefinition.end_fiscal_year_frequency_code = endFiscalYearFrequencyCode;
    return this;
  }

  conditionalTriggers(conditionalTriggers: ConditionalTrigger[]): ValueDefinitionBuilder<T> {
    this._valueDefinition.conditional_triggers = conditionalTriggers;
    return this;
  }

  coreValueDefinitionId(coreValueDefinitionId: string): ValueDefinitionBuilder<T> {
    this._valueDefinition.core_value_definition_id = coreValueDefinitionId;
    return this;
  }

  published(date: string): ValueDefinitionBuilder<T> {
    this._valueDefinition.published = date;
    return this;
  }

  publishedBy(userId: string): ValueDefinitionBuilder<T> {
    this._valueDefinition.published_by = userId;
    return this;
  }
  context(context: boolean): ValueDefinitionBuilder<T> {
    this._valueDefinition.context = context;
    return this;
  }

  technicalProtocol(technicalProtocol: string): ValueDefinitionBuilder<T> {
    this._valueDefinition.technical_protocol = technicalProtocol;
    return this;
  }

  build(): ValueDefinition<T> {
    return this._valueDefinition;
  }
}
