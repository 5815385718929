import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { FieldAuditEventOriginContext, FieldAuditEventType } from '../../models';

export { FieldAuditEventOriginDisplayText, FieldAuditEventTypeDisplayText };

const FieldAuditEventOriginDisplayText = {
  [FieldAuditEventOriginContext.imported_via_data_request]: _('Imported via request'),
  [FieldAuditEventOriginContext.prefill_data_request]: _('Prefilled request'),
  [FieldAuditEventOriginContext.imported_from_green_project]: _('Imported via API'),
  [FieldAuditEventOriginContext.consolidation_parameters_change]: _('Change to consolidation configuration rules'),
};

const FieldAuditEventTypeDisplayText = {
  [FieldAuditEventType.value_create]: _('Field Update'),
  [FieldAuditEventType.value_edit]: _('Field Update'),
  [FieldAuditEventType.value_reset]: _('Field Reset'),
};
