import { AbstractControl, ValidationErrors } from '@angular/forms';

export interface FormGroupControls {
  [key: string]: (string | ((control: AbstractControl) => ValidationErrors | null))[];
}

export class InvalidFormException extends Error {
  constructor(message: string = 'Form is invalid') {
    super(message);
  }
}
