import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TextInputComponent } from '../../../components';
import { ValueDefinitionSize } from '../../../models';
import { ValueFormControl } from '../../models/valueFormControl';

@Component({
  selector: 'lib-metric-editor-text-field',
  templateUrl: './metric-editor-text-field.component.html',
  styleUrls: ['./metric-editor-text-field.component.scss'],
})
export class MetricEditorTextFieldComponent implements OnInit, OnDestroy {
  @Input() valueFormControl?: ValueFormControl;
  @ViewChild(TextInputComponent) inputFieldRef!: TextInputComponent;

  hint = '';
  label = '';
  placeholder = '';
  size: ValueDefinitionSize = ValueDefinitionSize.large;
  maxLength?: number;

  ngOnInit(): void {
    if (this.valueFormControl) {
      this.hint = this.valueFormControl.valueRef.hint ?? '';
      this.label = this.valueFormControl.valueRef.label ?? '';
      this.placeholder = this.valueFormControl.valueRef.type_details.placeholder ?? '';
      this.size = this.valueFormControl.valueRef.size;

      const max = this.valueFormControl.valueRef.validators?.find(
        (v) => v.validator_type === 'max_length'
      )?.instructions;
      this.maxLength = max ? parseInt(String(max)) : undefined;
    }
  }

  ngOnDestroy(): void {
    this.updateValue();
  }

  setFocus(): void {
    this.inputFieldRef.setFocus();
  }

  updateValue(): void {
    this.inputFieldRef.setBlur();
  }
}
