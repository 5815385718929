import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { of } from 'rxjs';

import {
  ConsolidationRules,
  ConsolidationTriggers,
  DecimalTypeDetails,
  MetricTableColumnDefinitionUpsert,
  TypeDetails,
  Validator,
  ValueDefinitionType,
} from '../../../../../models';
import { UniquenessValidator } from '../../../../../validators';

export interface TableInputColumnDialogFormResult {
  label: string;
  type: ValueDefinitionType;
  type_details: TypeDetails;
  validators: Validator[];
  consolidation_rule?: ConsolidationRules;
  consolidation_trigger?: ConsolidationTriggers | null;
}

type TableInputColumnDialogFormModel = {
  label: FormControl<string>;
  inputType: FormControl<null>;
  inputTypeChoice: FormControl<ValueDefinitionType>;
  family: FormControl<string | null>;
  unitDefault: FormControl<string | null>;
  decimalValue: FormControl<string | null>;
  minimum: FormControl<string | null>;
  maximum: FormControl<string | null>;
  consolidationRule: FormControl<ConsolidationRules>;
  consolidationTrigger: FormControl<ConsolidationTriggers | null>;
};

export class TableInputColumnDialogForm extends FormGroup<TableInputColumnDialogFormModel> {
  public static delay = 0;

  constructor(
    existingColumns: MetricTableColumnDefinitionUpsert[],
    metricTableColumnDefinition?: MetricTableColumnDefinitionUpsert,
    fb: FormBuilder = new FormBuilder()
  ) {
    const typeDetails = metricTableColumnDefinition?.type_details as DecimalTypeDetails | undefined;
    const min = metricTableColumnDefinition?.validators
      .find((v) => v.validator_type === 'min_val')
      ?.instructions?.toString();
    const max = metricTableColumnDefinition?.validators
      .find((v) => v.validator_type === 'max_val')
      ?.instructions?.toString();

    super({
      label: fb.control(metricTableColumnDefinition?.label ?? '', {
        nonNullable: true,
        validators: Validators.required,
        asyncValidators: UniquenessValidator.validate(
          (value) => of(!existingColumns.some((col) => value.toLowerCase() === col.label.toLowerCase())),
          [],
          TableInputColumnDialogForm.delay
        ),
      }),
      inputType: fb.control(null),
      inputTypeChoice: fb.control(metricTableColumnDefinition?.type ?? ValueDefinitionType.integer, {
        nonNullable: true,
      }),
      decimalValue: fb.control(String(typeDetails?.max_decimals ?? 0), { validators: Validators.required }),
      family: fb.control(typeDetails?.family || null, { validators: Validators.required }),
      unitDefault: fb.control(typeDetails?.units || null, { validators: Validators.required }),
      minimum: fb.control(min || null),
      maximum: fb.control(max || null),
      consolidationRule: fb.control(metricTableColumnDefinition?.consolidation_rule ?? ConsolidationRules.sum, {
        nonNullable: true,
        validators: Validators.required,
      }),
      consolidationTrigger: fb.control(
        metricTableColumnDefinition?.consolidation_trigger === undefined
          ? ConsolidationTriggers.update_when_one_value
          : metricTableColumnDefinition.consolidation_trigger,
        { nonNullable: true, validators: Validators.required }
      ),
    });
  }

  public toModel(): TableInputColumnDialogFormResult {
    return {
      label: this.controls.label.value,
      type: this.controls.inputTypeChoice.value,
      type_details:
        this.controls.inputTypeChoice.value === ValueDefinitionType.integer
          ? {
              family: this.controls.family.value ?? '',
              units: this.controls.unitDefault.value ?? '',
            }
          : {
              family: this.controls.family.value ?? '',
              units: this.controls.unitDefault.value ?? '',
              max_decimals: Number(this.controls.decimalValue.value),
            },
      consolidation_rule: this.controls.consolidationRule.value,
      consolidation_trigger:
        this.controls.consolidationRule.value !== ConsolidationRules.manual
          ? this.controls.consolidationTrigger.value || undefined
          : null,
      validators: this.createValidators(),
    };
  }

  private createValidators(): Validator[] {
    const validators: Validator[] = [];

    const minVal: string = this.controls.minimum.value ?? '';
    minVal && validators.push({ validator_type: 'min_val', instructions: minVal });

    const maxVal = this.controls.maximum.value ?? '';
    maxVal && validators.push({ validator_type: 'max_val', instructions: maxVal });

    return validators;
  }
}
