<!-- Menus -->

<mat-menu #filterMenu="matMenu">
  <ng-container *ngIf="itemType">
    <!-- <ng-template matMenuContent> -->
    <ng-container
      *ngIf="
        lockedFilters?.action === 'lock'
          ? (searchOptions.filters | json) !== (lockedFilters?.filters | json)
          : (searchOptions.filters | json) !== '{}'
      "
    >
      <a mat-menu-item class="color-error" title="{{ 'Reset' | translate }}" (click)="resetFilters()">
        <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
        {{ "Reset" | translate }}
      </a>
      <div class="divider divider-horizontal"></div>
    </ng-container>
    <a
      mat-menu-item
      *ngIf="searchFilterConfig[itemType].year && !(lockedFilters?.filters)!['year']"
      [ngClass]="{ active: searchOptions.filters.year }"
      title="{{ 'Year' | translate }}"
      [matMenuTriggerFor]="yearMenu"
    >
      <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
      <span *ngIf="!searchOptions.filters.year">{{ "Year" | translate }}</span>
      <span *ngIf="searchOptions.filters.year">
        {{ "FY{fiscalYear}" | translate : { fiscalYear: searchOptions.filters.year.title } }}
      </span>
    </a>
    <a
      mat-menu-item
      *ngIf="searchFilterConfig[itemType].year && (lockedFilters?.filters)?.['year'] && lockedFilters?.action === 'lock'"
      class="active"
    >
      <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
      {{ "FY{fiscalYear}" | translate: { fiscalYear: (lockedFilters?.filters)?.["year"]?.title } }}
    </a>
    <a
      mat-menu-item
      *ngIf="searchFilterConfig[itemType].source && !(lockedFilters?.filters)!['source']"
      [ngClass]="{ active: searchOptions.filters.source }"
      title="{{ 'Source' | translate }}"
      [matMenuTriggerFor]="sourceMenu"
    >
      <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
      <span *ngIf="!searchOptions.filters.source">{{ "Source" | translate }}</span>
      <span *ngIf="searchOptions.filters.source">
        {{ searchOptions.filters.source.title }}
      </span>
    </a>
    <a
      mat-menu-item
      *ngIf="
        searchFilterConfig[itemType].source && (lockedFilters?.filters)!['source'] && lockedFilters?.action === 'lock'
      "
      class="active"
    >
      <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>{{ (lockedFilters?.filters)?.["source"]?.title }}
    </a>
    <a
      mat-menu-item
      *ngIf="searchFilterConfig[itemType]?.topic"
      [ngClass]="{ active: searchOptions.filters.topic }"
      title="{{ 'Topics' | translate }}"
      [matMenuTriggerFor]="topicMenu"
    >
      <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
      <span *ngIf="!searchOptions.filters.topic">{{ "Topic" | translate }}</span>
      <span *ngIf="searchOptions.filters.topic">
        {{ searchOptions.filters.topic.title }}
      </span>
    </a>
    <a
      mat-menu-item
      *ngIf="searchFilterConfig[itemType]?.category && !(lockedFilters?.filters)!['category']"
      [ngClass]="{ active: searchOptions.filters.category }"
      title="{{ 'Categories' | translate }}"
      [matMenuTriggerFor]="categoryMenu"
    >
      <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
      <span *ngIf="!searchOptions.filters.category">{{ "Category" | translate }}</span>
      <span *ngIf="searchOptions.filters.category">
        {{ searchOptions.filters.category.title | titlecase }}
      </span>
    </a>
    <a
      mat-menu-item
      *ngIf="searchFilterConfig[itemType]?.type"
      [ngClass]="{ active: searchOptions.filters.type }"
      title="{{ 'Types' | translate }}"
      [matMenuTriggerFor]="typeMenu"
    >
      <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
      <span *ngIf="!searchOptions.filters.type">{{ "Type" | translate }}</span>
      <span *ngIf="searchOptions.filters.type">
        {{ searchOptions.filters.type.title }}
      </span>
    </a>
    <a
      mat-menu-item
      *ngIf="searchFilterConfig[itemType]?.framework"
      [ngClass]="{ active: searchOptions.filters.framework }"
      title="{{ 'Frameworks' | translate }}"
      [matMenuTriggerFor]="frameworkMenu"
    >
      <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
      <span *ngIf="!searchOptions.filters.framework">{{ "Framework" | translate }}</span>
      <span *ngIf="searchOptions.filters.framework">
        {{ searchOptions.filters.framework.title }}
      </span>
    </a>
    <a
      mat-menu-item
      *ngIf="searchFilterConfig[itemType]?.standard_codes && !(lockedFilters?.filters)!['standard_codes']"
      [ngClass]="{ active: searchOptions.filters.standard_codes }"
      [title]="'Standard codes' | translate"
      [matMenuTriggerFor]="standardCodeMenu"
    >
      <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
      <span *ngIf="!searchOptions.filters.standard_codes">{{ "Standard codes" | translate }}</span>
      <span *ngIf="searchOptions.filters.standard_codes">
        {{ searchOptions.filters.standard_codes.title }}
      </span>
    </a>
    <a
      mat-menu-item
      *ngIf="searchFilterConfig[itemType]?.industry"
      [ngClass]="{ active: searchOptions.filters.industry }"
      title="{{ 'Industries' | translate }}"
      [matMenuTriggerFor]="industryMenu"
    >
      <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
      <span *ngIf="!searchOptions.filters.industry">{{ "Industry" | translate }}</span>
      <span *ngIf="searchOptions.filters.industry">
        {{ searchOptions.filters.industry.title }}
      </span>
    </a>
    <a
      mat-menu-item
      *ngIf="searchFilterConfig[itemType]?.tag"
      [ngClass]="{ active: searchOptions.filters.tag }"
      title="{{ 'Categories' | translate }}"
      [matMenuTriggerFor]="tagMenu"
    >
      <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
      <span *ngIf="!searchOptions.filters.tag">{{ "Tags" | translate }}</span>
      <span *ngIf="searchOptions.filters.tag">
        {{ searchOptions.filters.tag.title }}
      </span>
    </a>
    <a
      mat-menu-item
      *ngIf="searchFilterConfig[itemType]?.role"
      [ngClass]="{ active: searchOptions.filters.role }"
      title="{{ 'Role' | translate }}"
      [matMenuTriggerFor]="roleMenu"
    >
      <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
      <span *ngIf="!searchOptions.filters.role">{{ "Role" | translate }}</span>
      <span *ngIf="searchOptions.filters.role">
        {{ searchOptions.filters.role.title }}
      </span>
    </a>
    <a
      mat-menu-item
      *ngIf="searchFilterConfig[itemType]?.status"
      [ngClass]="{ active: searchOptions.filters.status }"
      title="{{ 'Status' | translate }}"
      [matMenuTriggerFor]="statusMenu"
    >
      <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
      <span *ngIf="!searchOptions.filters.status">{{ "Status" | translate }}</span>
      <span *ngIf="searchOptions.filters.status">
        {{ searchOptions.filters.status.title }}
      </span>
    </a>
    <a
      mat-menu-item
      *ngIf="searchFilterConfig[itemType]?.author"
      [ngClass]="{ active: searchOptions.filters.author }"
      title="{{ 'Creator' | translate }}"
      [matMenuTriggerFor]="authorMenu"
    >
      <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
      <span *ngIf="!searchOptions.filters.author">{{ "Creator" | translate }}</span>
      <span *ngIf="searchOptions.filters.author">
        {{ searchOptions.filters.author.title }}
      </span>
    </a>
    <ng-container *ngIf="mode === 'drag'">
      <div class="divider divider-horizontal"></div>
      <a mat-menu-item class="active" title="{{ 'Sort' | translate }}" [matMenuTriggerFor]="sortMenu">
        <mat-icon class="icon-sm" svgIcon="sort"></mat-icon>
        {{ searchOptions.sort?.title }}
      </a>
    </ng-container>
    <!-- </ng-template> -->
  </ng-container>
</mat-menu>

<mat-menu #externalFiltersMenu="matMenu">
  <ng-template matMenuContent let-menuData="menuData">
    <a mat-menu-item class="menu-title" disabled>{{ menuData.title }}</a>
    <div
      class="menu-search"
      (click)="$event.stopPropagation()"
      *ngIf="filters[menuData.id].control && filters[menuData.id].items.length > minMenuScrollItems"
    >
      <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
      <input
        matInput
        type="text"
        placeholder="{{ 'Search' | translate }}"
        autocomplete="off"
        (keydown)="$event.stopPropagation()"
        [formControl]="filters[menuData.id].control!"
      />
      <a
        class="clear-search"
        *ngIf="filters[menuData.id].control!.value"
        (click)="filters[menuData.id].control!.setValue('')"
      >
        <mat-icon class="icon-xs" svgIcon="filter"></mat-icon>
      </a>
    </div>
    <hr />
    <a mat-menu-item (click)="setItem(menuData.id)">{{ "All" | translate }}</a>
    <hr class="hr-light" />
    <div [ngClass]="{ 'menu-items-container': filters[menuData.id].items.length > minMenuScrollItems }">
      <a
        mat-menu-item
        *ngFor="let item of filters[menuData.id].items"
        [title]="item.title"
        (click)="setItem(menuData.id, item)"
        [ngClass]="{ active: searchOptions.filters[menuData.id]?.id === item.id }"
      >
        <span>{{ item.title }}</span>
      </a>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #yearMenu="matMenu">
  <ng-template matMenuContent>
    <header>
      <a mat-menu-item disabled>{{ "Fiscal year" | translate }}</a>
    </header>
    <hr />
    <a mat-menu-item (click)="setItem('year')">
      <ng-container *ngIf="itemType === 'metrics_indicator'">
        {{ "Current ({currentYear})" | translate : { currentYear: currentYear } }}
      </ng-container>
      <ng-container *ngIf="itemType !== 'metrics_indicator'"> {{ "All" | translate }}</ng-container>
    </a>
    <hr />
    <a
      *ngFor="let item of filters.year.items"
      mat-menu-item
      (click)="setItem('year', item)"
      [ngClass]="{
        active:
          searchOptions.filters.year?.id === item.id ||
          (!searchOptions.filters.year && itemType === 'metrics_indicator' && item.id === currentYear)
      }"
    >
      {{ item.title }}
    </a>
  </ng-template>
</mat-menu>

<mat-menu #sourceMenu="matMenu">
  <ng-template matMenuContent>
    <a mat-menu-item class="menu-title" disabled>{{ "Source" | translate }}</a>
    <div
      class="menu-search"
      (click)="$event.stopPropagation()"
      *ngIf="filters.source.control && filters.source.items.length > minMenuScrollItems"
    >
      <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
      <input
        matInput
        type="text"
        placeholder="{{ 'Search' | translate }}"
        autocomplete="off"
        (keydown)="$event.stopPropagation()"
        [formControl]="filters.source.control"
      />
      <a class="clear-search" *ngIf="filters.source.control.value" (click)="filters.source.control.setValue('')">
        <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
      </a>
    </div>
    <hr />
    <a mat-menu-item (click)="setItem('source')">{{ "All" | translate }}</a>
    <hr class="hr-light" />
    <div [ngClass]="{ 'menu-items-container': filters.source.items.length > minMenuScrollItems }">
      <a
        mat-menu-item
        *ngFor="let item of filters.source.filteredItems | async"
        [title]="item.title"
        (click)="setItem('source', item)"
        [ngClass]="{ active: searchOptions.filters.source?.id === item.id }"
      >
        <span>{{ item.title }}</span>
      </a>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #topicMenu="matMenu">
  <ng-template matMenuContent>
    <a mat-menu-item class="menu-title" disabled>{{ "Topic" | translate }}</a>
    <div
      class="menu-search"
      (click)="$event.stopPropagation()"
      *ngIf="filters.topic.control && filters.topic.items.length > minMenuScrollItems"
    >
      <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
      <input
        matInput
        type="text"
        placeholder="{{ 'Search' | translate }}"
        autocomplete="off"
        (keydown)="$event.stopPropagation()"
        [formControl]="filters.topic.control"
      />
      <a class="clear-search" *ngIf="filters.topic.control.value" (click)="filters.topic.control.setValue('')">
        <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
      </a>
    </div>
    <hr />
    <a mat-menu-item (click)="setItem('topic')">{{ "All" | translate }}</a>
    <hr class="hr-light" />
    <div [ngClass]="{ 'menu-items-container': filters.topic.items.length > minMenuScrollItems }">
      <a
        mat-menu-item
        *ngFor="let item of filters.topic.filteredItems | async"
        [title]="item.title"
        (click)="setItem('topic', item)"
        [ngClass]="{ active: searchOptions.filters.topic?.id === item.id }"
      >
        <span [ngClass]="{ 'pl-2': item.action === 'group', 'pl-4': item.action === 'topic' }">{{ item.title }}</span>
      </a>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #categoryMenu="matMenu">
  <ng-template matMenuContent>
    <a mat-menu-item class="menu-title" disabled>{{ "Category" | translate }}</a>
    <div
      class="menu-search"
      (click)="$event.stopPropagation()"
      *ngIf="filters.category.control && filters.category.items.length > minMenuScrollItems"
    >
      <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
      <input
        matInput
        type="text"
        placeholder="{{ 'Search' | translate }}"
        autocomplete="off"
        (keydown)="$event.stopPropagation()"
        [formControl]="filters.category.control"
      />
      <a class="clear-search" *ngIf="filters.category.control.value" (click)="filters.category.control.setValue('')">
        <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
      </a>
    </div>
    <hr />
    <a mat-menu-item (click)="setItem('category')">{{ "All" | translate }}</a>
    <hr class="hr-light" />
    <div [ngClass]="{ 'menu-items-container': filters.category.items.length > minMenuScrollItems }">
      <a
        *ngFor="let item of filters.category.filteredItems | async"
        [title]="item.title"
        mat-menu-item
        (click)="setItem('category', item)"
        [ngClass]="{ active: searchOptions.filters.category?.id === item.id }"
      >
        <span>{{ item.title }}</span>
      </a>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #typeMenu="matMenu">
  <ng-template matMenuContent>
    <a mat-menu-item class="menu-title" disabled>{{ "Type" | translate }}</a>
    <div
      class="menu-search"
      (click)="$event.stopPropagation()"
      *ngIf="filters.type.control && filters.type.items.length > minMenuScrollItems"
    >
      <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
      <input
        matInput
        type="text"
        placeholder="{{ 'Search' | translate }}"
        autocomplete="off"
        (keydown)="$event.stopPropagation()"
        [formControl]="filters.type.control"
      />
      <a class="clear-search" *ngIf="filters.type.control.value" (click)="filters.type.control.setValue('')">
        <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
      </a>
    </div>
    <hr />
    <a mat-menu-item (click)="setItem('type')">{{ "All" | translate }}</a>
    <hr class="hr-light" />
    <div [ngClass]="{ 'menu-items-container': filters.type.items.length > minMenuScrollItems }">
      <a
        mat-menu-item
        *ngFor="let item of filters.type.filteredItems | async"
        [title]="item.title"
        (click)="setItem('type', item)"
        [ngClass]="{ active: searchOptions.filters.type?.id === item.id }"
      >
        <span>{{ item.title }}</span>
      </a>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #frameworkMenu="matMenu">
  <ng-template matMenuContent>
    <a mat-menu-item class="menu-title" disabled>{{ "Framework" | translate }}</a>
    <hr />
    <a mat-menu-item (click)="setItem('framework')">{{ "All" | translate }}</a>
    <hr class="hr-light" />
    <a
      *ngFor="let item of filters.framework.items"
      mat-menu-item
      [ngClass]="{ active: searchOptions.filters.framework === item }"
      (click)="setItem('framework', item)"
    >
      {{ item.title }}
    </a>
  </ng-template>
</mat-menu>

<mat-menu #questionnaireFrameworkMenu="matMenu">
  <ng-template matMenuContent>
    <lib-search-bar-menu-item
      [menuTitle]="'Questionnaire Framework' | translate"
      [resourceType]="eSearchBarFilterResourceType.questionnaire_framework"
      [filterItems]="filters.questionnaire_framework.items"
      [selectedFilterItemId]="searchOptions.filters.questionnaire_framework?.id ?? ''"
      (setItem)="setItem(eSearchBarFilterResourceType.questionnaire_framework, $event)"
    ></lib-search-bar-menu-item>
  </ng-template>
</mat-menu>

<mat-menu #industryMenu="matMenu">
  <ng-template matMenuContent>
    <a mat-menu-item class="menu-title" disabled>{{ "Industry" | translate }}</a>
    <div
      class="menu-search"
      (click)="$event.stopPropagation()"
      *ngIf="filters.industry.control && filters.industry.items.length > minMenuScrollItems"
    >
      <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
      <input
        matInput
        type="text"
        placeholder="{{ 'Search' | translate }}"
        autocomplete="off"
        (keydown)="$event.stopPropagation()"
        [formControl]="filters.industry.control"
      />
      <a class="clear-search" *ngIf="filters.industry.control.value" (click)="filters.industry.control.setValue('')">
        <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
      </a>
    </div>
    <hr />
    <a mat-menu-item (click)="setItem('industry')">{{ "All" | translate }}</a>
    <hr class="hr-light" />
    <div [ngClass]="{ 'menu-items-container': filters.industry.items.length > minMenuScrollItems }">
      <a
        mat-menu-item
        *ngFor="let item of filters.industry.filteredItems | async"
        [title]="item.title"
        (click)="setItem('industry', item)"
        [ngClass]="{ active: searchOptions.filters.industry?.id === item.id }"
      >
        <span>{{ item.title | titlecase }}</span>
      </a>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #tagMenu="matMenu">
  <ng-template matMenuContent>
    <a mat-menu-item class="menu-title" disabled>{{ "Tag" | translate }}</a>
    <div
      class="menu-search"
      (click)="$event.stopPropagation()"
      *ngIf="filters.tag.control && filters.tag.items.length > minMenuScrollItems"
    >
      <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
      <input
        matInput
        type="text"
        placeholder="{{ 'Search' | translate }}"
        autocomplete="off"
        (keydown)="$event.stopPropagation()"
        [formControl]="filters.tag.control"
      />
      <a class="clear-search" *ngIf="filters.tag.control.value" (click)="filters.tag.control.setValue('')">
        <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
      </a>
    </div>
    <hr />
    <a mat-menu-item (click)="setItem('tag')">{{ "All" | translate }}</a>
    <hr class="hr-light" />
    <div [ngClass]="{ 'menu-items-container': filters.tag.items.length > minMenuScrollItems }">
      <a
        mat-menu-item
        *ngFor="let item of filters.tag.filteredItems | async"
        [title]="item.title"
        (click)="setItem('tag', item)"
        [ngClass]="{ active: searchOptions.filters.tag?.id === item.id }"
      >
        <span>{{ item.title | titlecase }}</span>
      </a>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #statusMenu="matMenu">
  <ng-template matMenuContent>
    <a mat-menu-item class="menu-title" disabled>{{ "Status" | translate }}</a>
    <hr />
    <a mat-menu-item (click)="setItem('status')">{{ "All" | translate }}</a>
    <hr class="hr-light" />
    <a
      *ngFor="let item of filters.status.items"
      mat-menu-item
      [ngClass]="{ active: searchOptions.filters.status?.id === item.id }"
      (click)="setItem('status', item)"
    >
      {{ item.title }}
    </a>
  </ng-template>
</mat-menu>

<mat-menu #authorMenu="matMenu">
  <ng-template matMenuContent>
    <header>
      <a mat-menu-item disabled>{{ "Author" | translate }}</a>
      <a class="mr-3" (click)="setItem('author')">
        <mat-icon
          class="icon-sm"
          svgIcon="close"
          [ngClass]="{ 'color-white': !searchOptions.filters.author }"
        ></mat-icon>
      </a>
    </header>
    <hr />
    <a mat-menu-item (click)="setItem('author', { id: '', title: 'All' })"> {{ "All" | translate }}</a>
    <hr />
    <a
      *ngFor="let item of filters.author.items"
      mat-menu-item
      [ngClass]="{ active: searchOptions.filters.author === item }"
      (click)="setItem('author', item)"
    >
      {{ item.title }}
    </a>
  </ng-template>
</mat-menu>

<mat-menu #roleMenu="matMenu">
  <ng-template matMenuContent>
    <a mat-menu-item class="menu-title" disabled>{{ "Role" | translate }}</a>
    <hr />
    <a mat-menu-item (click)="setItem('role')">{{ "All" | translate }}</a>
    <hr class="hr-light" />
    <a
      *ngFor="let item of filters.role.items"
      mat-menu-item
      [ngClass]="{ active: searchOptions.filters.role?.id === item.id }"
      (click)="setItem('role', item)"
    >
      {{ item.title }}
    </a>
  </ng-template>
</mat-menu>

<mat-menu #sortMenu="matMenu">
  <ng-template matMenuContent>
    <a mat-menu-item class="menu-title" disabled>{{ "Sort" | translate }}</a>
    <hr />
    <a
      *ngFor="let item of filters.sort.items"
      mat-menu-item
      [ngClass]="{
        active: searchOptions.sort?.id === item.id,
        hide: item.id === 'score' && !searchOptions.query.keywords
      }"
      (click)="applySort(item)"
    >
      {{ item.title }}
    </a>
  </ng-template>
</mat-menu>

<mat-menu #standardCodeMenu="matMenu">
  <ng-template matMenuContent>
    <lib-search-bar-standard-code-menu
      [control]="filters.standard_codes.control"
      [minMenuScrollItems]="minMenuScrollItems"
      [items]="filters.standard_codes.items"
      [filteredItems]="filters.standard_codes.filteredItems"
      [selectedItemId]="searchOptions.filters.standard_codes?.id"
      (setItem)="setItem('standard_codes', $event)"
    ></lib-search-bar-standard-code-menu>
  </ng-template>
</mat-menu>

<!-- Layout -->

<div class="search-wrapper">
  <div class="search-keywords" [ngClass]="{ active: searchOptions.query.keywords }">
    <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
    <input
      type="text"
      [placeholder]="searchPlaceholder"
      [ngModel]="searchOptions.query.keywords"
      (ngModelChange)="onSearchQueryChanged($event)"
    />
    <a *ngIf="searchOptions.query.keywords" class="clear-search" (click)="clearSearch()">
      <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
    </a>
  </div>
  <div class="search-options" data-testid="searchOptions">
    <ng-container *ngIf="mode !== 'nav'">
      <div class="divider divider-vertical"></div>
      <a
        class="btn btn-filter mr-1"
        [title]="'Filter' | translate"
        [ngClass]="{ active: filterCount }"
        [matMenuTriggerFor]="filterMenu"
      >
        <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
        <span>
          {{ "Filter" | translate }}
          <ng-container *ngIf="filterCount"> ({{ filterCount }}) </ng-container>
        </span>
      </a>
    </ng-container>
    <div class="search-filters" *ngIf="mode === 'nav'">
      <ng-container *ngIf="searchOptions.filters && itemType">
        <ng-container *ngIf="(searchOptions.filters | json) !== '{}'">
          <a class="btn btn-warn" title="{{ 'Reset' | translate }}" (click)="resetFilters()">
            <mat-icon class="icon-sm" svgIcon="frequency"></mat-icon>
            <span>{{ "Reset" | translate }}</span>
          </a>
          <div class="divider divider-vertical"></div>
        </ng-container>

        <ng-container *ngFor="let filter of externalFilters">
          <a
            [ngClass]="{ active: searchOptions.filters[filter.id] }"
            class="btn btn-filter"
            [title]="filter.title"
            [matMenuTriggerFor]="externalFiltersMenu"
            [matMenuTriggerData]="{ menuData: filter }"
          >
            <mat-icon class="icon-sm" [svgIcon]="'filter'"></mat-icon>
            <span *ngIf="!searchOptions.filters[filter.id]">{{ filter.title }}</span>
            <span *ngIf="searchOptions.filters[filter.id]">
              {{ searchOptions.filters[filter.id]!.title }}
            </span>
          </a>
        </ng-container>

        <ng-container *ngIf="searchFilterConfig[itemType]?.start">
          <!--To hide the input while still position the calendar popup correctly, this styling was mandatory-->
          <mat-form-field style="width: 1px; visibility: hidden">
            <input
              matInput
              [matDatepicker]="startDatePicker"
              (dateChange)="setItem('start', { id: 'start', title: 'Start Date', item: $event.value })"
            />
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>

          <a
            (click)="startDatePicker.open()"
            class="btn btn-filter active"
            [ngClass]="{ active: searchOptions.filters.start }"
            [title]="'Start Date' | translate"
          >
            <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
            <span *ngIf="!searchOptions.filters.start">
              <ng-container> {{ "Start Date" | translate }}</ng-container>
            </span>
            <span *ngIf="searchOptions.filters.start">
              {{ searchOptions.filters.start.item | date }}
            </span>
          </a>
        </ng-container>

        <a
          *ngIf="searchFilterConfig[itemType]?.year"
          class="btn btn-filter active"
          [ngClass]="{ active: searchOptions.filters.year }"
          [title]="'Year' | translate"
          [matMenuTriggerFor]="yearMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          <span *ngIf="!searchOptions.filters.year">
            <ng-container *ngIf="itemType === 'metrics_indicator'">
              {{ "FY{fiscalYear}" | translate : { fiscalYear: this.currentFiscalYear } }}
            </ng-container>
            <ng-container *ngIf="itemType !== 'metrics_indicator'"> {{ "Fiscal year" | translate }}</ng-container>
          </span>
          <span *ngIf="searchOptions.filters.year">
            {{ "FY{fiscalYear}" | translate : { fiscalYear: searchOptions.filters.year.title } }}</span
          >
        </a>
        <a
          *ngIf="searchFilterConfig[itemType]?.source"
          [ngClass]="{ active: searchOptions.filters.source }"
          class="btn btn-filter"
          [title]="'Source' | translate"
          [matMenuTriggerFor]="sourceMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          <span *ngIf="!searchOptions.filters.source">{{ "Source" | translate }}</span>
          <span *ngIf="searchOptions.filters.source">
            {{ searchOptions.filters.source.title }}
          </span>
        </a>
        <a
          *ngIf="searchFilterConfig[itemType]?.topic"
          [ngClass]="{ active: searchOptions.filters.topic }"
          class="btn btn-filter"
          [title]="'Topics' | translate"
          [matMenuTriggerFor]="topicMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          <span *ngIf="!searchOptions.filters.topic">{{ "Topic" | translate }}</span>
          <span *ngIf="searchOptions.filters.topic">
            {{ searchOptions.filters.topic.title }}
          </span>
        </a>
        <a
          *ngIf="searchFilterConfig[itemType]?.category"
          [ngClass]="{ active: searchOptions.filters.category }"
          class="btn btn-filter"
          [title]="'Categories' | translate"
          [matMenuTriggerFor]="categoryMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          <span *ngIf="!searchOptions.filters.category">{{ "Category" | translate }}</span>
          <span *ngIf="searchOptions.filters.category">
            <span>{{ searchOptions.filters.category.title }}</span>
          </span>
        </a>
        <a
          *ngIf="searchFilterConfig[itemType]?.type"
          [ngClass]="{ active: searchOptions.filters.type }"
          class="btn btn-filter"
          [title]="'Types' | translate"
          [matMenuTriggerFor]="typeMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          <span *ngIf="!searchOptions.filters.type">{{ "Type" | translate }}</span>
          <span *ngIf="searchOptions.filters.type">
            {{ searchOptions.filters.type.title }}
          </span>
        </a>
        <a
          *ngIf="searchFilterConfig[itemType]?.standard_codes"
          class="btn btn-filter active"
          [ngClass]="{ active: searchOptions.filters.standard_codes }"
          [title]="'Standard codes' | translate"
          [matMenuTriggerFor]="standardCodeMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          <span *ngIf="!searchOptions.filters.standard_codes"> {{ "Standard codes" | translate }} </span>
          <span *ngIf="searchOptions.filters.standard_codes">
            {{ searchOptions.filters.standard_codes.title }}
          </span>
        </a>
        <a
          *ngIf="searchFilterConfig[itemType]?.framework"
          [ngClass]="{ active: searchOptions.filters.framework }"
          class="btn btn-filter"
          [title]="'Frameworks' | translate"
          [matMenuTriggerFor]="frameworkMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          <span *ngIf="!searchOptions.filters.framework">{{ "Framework" | translate }}</span>
          <span *ngIf="searchOptions.filters.framework">
            {{ searchOptions.filters.framework.title }}
          </span>
        </a>
        <a
          *ngIf="searchFilterConfig[itemType]?.questionnaire_framework"
          [ngClass]="{ active: searchOptions.filters.questionnaire_framework }"
          class="btn btn-filter"
          [title]="'Questionnaire Framework' | translate"
          [matMenuTriggerFor]="questionnaireFrameworkMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          <span *ngIf="!searchOptions.filters.questionnaire_framework">{{
            "Questionnaire Framework" | translate
          }}</span>
          <span *ngIf="searchOptions.filters.questionnaire_framework">
            {{ searchOptions.filters.questionnaire_framework.title }}
          </span>
        </a>
        <a
          *ngIf="searchFilterConfig[itemType]?.industry"
          [ngClass]="{ active: searchOptions.filters.industry }"
          class="btn btn-filter"
          [title]="'Industries' | translate"
          [matMenuTriggerFor]="industryMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          <span *ngIf="!searchOptions.filters.industry">{{ "Industry" | translate }}</span>
          <span *ngIf="searchOptions.filters.industry">
            {{ searchOptions.filters.industry.title }}
          </span>
        </a>
        <a
          *ngIf="searchFilterConfig[itemType]?.tag"
          [ngClass]="{ active: searchOptions.filters.tag }"
          class="btn btn-filter"
          [title]="'Tags' | translate"
          [matMenuTriggerFor]="tagMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          <span *ngIf="!searchOptions.filters.tag">{{ "Tags" | translate }}</span>
          <span *ngIf="searchOptions.filters.tag">
            {{ searchOptions.filters.tag.title }}
          </span>
        </a>
        <a
          *ngIf="searchFilterConfig[itemType]?.role"
          class="btn btn-filter active"
          [ngClass]="{ active: searchOptions.filters.role }"
          [title]="'Role' | translate"
          [matMenuTriggerFor]="roleMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          <span *ngIf="!searchOptions.filters.role"> {{ "Role" | translate }}</span>
          <span *ngIf="searchOptions.filters.role">
            {{ searchOptions.filters.role.title }}
          </span>
        </a>

        <a
          *ngIf="searchFilterConfig[itemType]?.status"
          [ngClass]="{ active: searchOptions.filters.status }"
          class="btn btn-filter"
          [title]="'Status' | translate"
          [matMenuTriggerFor]="statusMenu"
          role="button"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          <span *ngIf="!searchOptions.filters.status">{{ "Status" | translate }}</span>
          <span *ngIf="searchOptions.filters.status">
            {{ searchOptions.filters.status.title }}
          </span>
        </a>
        <a
          *ngIf="searchFilterConfig[itemType]?.author"
          [ngClass]="{ active: searchOptions.filters.author }"
          class="btn btn-filter"
          [title]="'Creator' | translate"
          [matMenuTriggerFor]="authorMenu"
        >
          <mat-icon class="icon-sm" svgIcon="filter"></mat-icon>
          <span *ngIf="!searchOptions.filters.author">{{ "Author" | translate }}</span>
          <span *ngIf="searchOptions.filters.author">
            {{ searchOptions.filters.author.title }}
          </span>
        </a>
        <div class="divider divider-vertical"></div>
      </ng-container>
      <a class="btn btn-filter active" title="{{ 'Sort' | translate }}" [matMenuTriggerFor]="sortMenu">
        <mat-icon class="icon-sm" svgIcon="sort"></mat-icon>
        {{ searchOptions.sort?.title }}
      </a>
    </div>
  </div>
</div>
