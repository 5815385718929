import { Observable } from 'rxjs';

import {
  ApiResponse,
  ApplicationApiDefinition,
  CodeCheck,
  ConditionalTrigger,
  ConditionalTriggerUpsertPayload,
  FiscalYear,
  FieldInformationRequest,
  IndicatorRelatedMetrics,
  Metric,
  MetricLoaderFlags,
  MetricTableCalculationDefinition,
  MetricTableDefinition,
  MetricTableDefinitionUpsertPayload,
  MetricTableStandardCodes,
  MinimalDataRequest,
  SearchOptions,
  ValueDefinition,
  ValueDefinitionStandardCodes,
  CreateMetricTableColumnDefinitionPayload,
  MetricTableColumnDefinition,
  UpdateMetricTableColumnDefinitionPayload,
  MetricTableDefinitionUpdatePayload,
} from '../models';

export abstract class BaseApiService {
  abstract apiName: keyof ApplicationApiDefinition;
  abstract resource: string;
  abstract servicePath: string;
}
export abstract class MetricApiService extends BaseApiService {
  abstract getMetricDefinitions(metric: string): Observable<ApiResponse<ValueDefinition[]>>;

  abstract listMetrics(metricIds: string[]): Observable<ApiResponse<Metric[]>>;

  abstract listMetricDataRequests(metricId: string): Observable<ApiResponse<MinimalDataRequest[]>>;

  abstract search(searchOptions: SearchOptions): Observable<ApiResponse<Metric[]>>;

  abstract createMetric(payload: any): Observable<ApiResponse<Metric>>;

  abstract checkIfMetricCodeExists(
    metric_code: string,
    metric_code_prefix?: string
  ): Observable<ApiResponse<CodeCheck>>;

  abstract getMetric(id: string, params?: MetricLoaderFlags): Observable<ApiResponse<Metric>>;

  abstract updateMetric(metric_id: string, payload: any): Observable<ApiResponse<Metric>>;

  abstract createGroup(metricID: string, payload?: any): Observable<ApiResponse<Metric>>;

  abstract updateGroup(metricId: string, valueDefinitionGroupID: string, payload: any): Observable<ApiResponse<Metric>>;

  abstract deleteGroup(metricID: string, valueDefinitionGroupId: string): Observable<ApiResponse<Metric>>;

  abstract moveGroup(
    metricID: string,
    valueDefinitionGroupID: string,
    position: number
  ): Observable<ApiResponse<Metric>>;

  abstract createField(metricId: string, valueDefinitionGroupId: string, payload: any): Observable<ApiResponse<Metric>>;

  abstract updateField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: any
  ): Observable<ApiResponse<Metric>>;

  abstract deleteField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string
  ): Observable<ApiResponse<Metric>>;

  abstract moveField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    position: number
  ): Observable<ApiResponse<Metric>>;

  abstract updateFieldInformation(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: FieldInformationRequest
  ): Observable<ApiResponse<Metric>>;

  abstract getMetricTable(
    metricID: string,
    tableId: string,
    checkValues?: boolean
  ): Observable<ApiResponse<MetricTableDefinition>>;

  abstract createMetricTable(
    metricID: string,
    payload: MetricTableDefinitionUpsertPayload
  ): Observable<ApiResponse<Metric>>;

  abstract updateMetricTable(
    metricID: string,
    tableId: string,
    payload: MetricTableDefinitionUpdatePayload
  ): Observable<ApiResponse<Metric>>;

  abstract deleteMetricTable(metricID: string, tableId: string): Observable<ApiResponse<Metric>>;

  abstract createMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    payload: CreateMetricTableColumnDefinitionPayload,
    callback?: () => void
  ): Observable<ApiResponse<MetricTableColumnDefinition>>;

  abstract deleteMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    columnId: string
  ): Observable<ApiResponse<MetricTableColumnDefinition>>;

  abstract deleteMetricTableColumnOption(
    metricId: string,
    tableId: string,
    columnId: string,
    optionId: string
  ): Observable<undefined>;

  abstract updateMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    columnId: string,
    payload: UpdateMetricTableColumnDefinitionPayload,
    callback?: () => void
  ): Observable<ApiResponse<MetricTableColumnDefinition>>;

  abstract moveMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    columnId: string,
    position: number
  ): Observable<ApiResponse<MetricTableColumnDefinition>>;

  abstract createMetricTableTotal(
    metricId: string,
    tableId: string,
    payload: MetricTableCalculationDefinition
  ): Observable<ApiResponse<Metric>>;

  abstract updateMetricTableTotal(
    metricId: string,
    tableId: string,
    payload: MetricTableCalculationDefinition
  ): Observable<ApiResponse<Metric>>;

  abstract deleteMetricTableTotal(metricId: string, tableId: string, totalId: string): Observable<ApiResponse<Metric>>;

  abstract moveMetricTableTotal(
    metricId: string,
    tableId: string,
    totalId: string,
    position: number
  ): Observable<ApiResponse<Metric>>;

  abstract moveMetricTable(metricId: string, tableId: string, position: number): Observable<ApiResponse<Metric>>;

  abstract getConditionalTriggersForValueDefinition(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string
  ): Observable<ApiResponse<ConditionalTrigger[]>>;

  abstract createConditionalTrigger(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: ConditionalTriggerUpsertPayload
  ): Observable<ApiResponse<Metric>>;

  abstract updateConditionalTrigger(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    conditionTriggerId: string,
    payload: ConditionalTriggerUpsertPayload
  ): Observable<ApiResponse<Metric>>;

  abstract deleteConditionalTrigger(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    conditionTriggerId: string
  ): Observable<ApiResponse<Metric>>;

  abstract getRelatedMetrics(metricId: string): Observable<ApiResponse<IndicatorRelatedMetrics>>;

  abstract getFieldStandardCodes(
    metricId: string,
    valueDefinitionId: string
  ): Observable<ApiResponse<ValueDefinitionStandardCodes[]>>;

  abstract updateMetricTableInformation(
    metricId: string,
    tableId: string,
    payload: FieldInformationRequest
  ): Observable<ApiResponse<Metric>>;

  abstract listMetricTableStandardCodes(
    tableIds: string[],
    metricId?: string
  ): Observable<ApiResponse<MetricTableStandardCodes[]>>;

  abstract getLatestFiscalYearWithCollectValues(
    metricId: string,
    valueDefinitionGroupIds: string[],
    valueDefinitionIds: string[],
    metricTableIds?: string[]
  ): Observable<ApiResponse<FiscalYear | null>>;

  abstract deactivateValueDefinition(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string
  ): Observable<ApiResponse<Metric>>;

  abstract deactivateValueDefinitionGroup(
    metricId: string,
    valueDefinitionGroupId: string
  ): Observable<ApiResponse<Metric>>;

  abstract activateValueDefinitionGroup(
    metricId: string,
    valueDefinitionGroupId: string
  ): Observable<ApiResponse<Metric>>;

  abstract deactivateMetricTable(metricId: string, metricTableId: string): Observable<ApiResponse<Metric>>;

  abstract activateValueDefinition(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string
  ): Observable<ApiResponse<Metric>>;

  abstract activateMetricTable(metricId: string, metricTable: string): Observable<ApiResponse<Metric>>;

  abstract publishMetric(metricId: string): Observable<ApiResponse<Metric>>;
}
