export interface PaginationData {
  limit?: number;
  offset?: number;
}

export interface PaginationFilter {
  page?: number;
  page_size?: number;
  initial_offset?: number;
}
