import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';

import { CreatedUpdatedBuilder } from './created-updated-builder';
import { Framework, StandardCodes, Taxonomy, TaxonomyCategory } from '../models';

export class TaxonomyBuilder extends CreatedUpdatedBuilder<Taxonomy> {
  constructor() {
    super({
      id: uuidv4(),
      label: faker.random.alphaNumeric(10),
      is_leaf: false,
      end: undefined,
      parent_id: null,
      standard_codes: undefined,
      children: [],
      active: true,
      category: TaxonomyCategory.node,
      children_mutually_exclusive: true,
      code: faker.random.word(),
      framework_id: uuidv4(),
      is_client_selectable: true,
    });
  }

  id(id: string): TaxonomyBuilder {
    this._instance.id = id;
    return this;
  }

  endDate(endDate: string): TaxonomyBuilder {
    this._instance.end_date = endDate;
    return this;
  }

  isLeaf(isLeaf: boolean): TaxonomyBuilder {
    this._instance.is_leaf = isLeaf;
    return this;
  }

  label(label: string): TaxonomyBuilder {
    this._instance.label = label;
    return this;
  }

  name(label: string): TaxonomyBuilder {
    this._instance.label = label;
    return this;
  }

  parentId(parentId?: string): TaxonomyBuilder {
    this._instance.parent_id = parentId;
    return this;
  }

  standardCodes(standardCodes?: StandardCodes[]): TaxonomyBuilder {
    this._instance.standard_codes = standardCodes;
    return this;
  }

  startDate(startDate: string): TaxonomyBuilder {
    this._instance.start_date = startDate;
    return this;
  }

  taxonomyChildren(children?: Taxonomy[]): TaxonomyBuilder {
    this._instance.children = children;
    return this;
  }

  framework(framework: Framework): TaxonomyBuilder {
    this._instance.framework = framework;
    return this;
  }

  guidance(guidance: string): TaxonomyBuilder {
    this._instance.guidance = guidance;
    return this;
  }

  disclaimers(disclaimers: string): TaxonomyBuilder {
    this._instance.disclaimers = disclaimers;
    return this;
  }

  description(description: string): TaxonomyBuilder {
    this._instance.description = description;
    return this;
  }

  code(code: string): TaxonomyBuilder {
    this._instance.code = code;
    return this;
  }
}
