import { SearchIndicatorPayload, IndicatorSearchFilterCriteria } from '../models';
import { Builder } from './builder';
import { IndicatorSearchFilterCriteriaBuilder } from './indicator-search-filter-criteria-builder';

export class SearchIndicatorPayloadBuilder extends Builder<SearchIndicatorPayload> {
  constructor() {
    const indicatorSearchFilterCriteria: IndicatorSearchFilterCriteria =
      new IndicatorSearchFilterCriteriaBuilder().build();

    super({
      filters: indicatorSearchFilterCriteria,
      keywords: null,
      sort: [
        {
          field: 'start',
          direction: 'desc',
        },
      ],
      return_perfect_matching_group: true,
      load_value_group_sets: false,
      load_value_groups: false,
      load_values: false,
    });
  }

  keywords(keywords_for_search: string): SearchIndicatorPayloadBuilder {
    this._instance.keywords = keywords_for_search;
    return this;
  }

  sort(criteria: object): SearchIndicatorPayloadBuilder {
    this._instance.sort = [criteria];
    return this;
  }

  filters(filter_args: IndicatorSearchFilterCriteria | undefined): SearchIndicatorPayloadBuilder {
    this._instance.filters = filter_args;
    return this;
  }
}
