export interface CheckPublicIndicatorValuesUpdated {
  has_changed: boolean;
  value_id: string;
}

export interface CheckPublicIndicatorValuesUpdatedPayload {
  previous_values: { value: unknown; value_id: string }[];
}

export interface GetPublicIndicatorParams extends Record<string, string | string[]> {
  business_unit_id: string;
  frequencies: [string];
}

export interface SearchPublicIndicatorPayload {
  filters: SearchPublicIndicatorPayloadFilters;
  from?: number;
  size?: number;
  business_unit_id?: string;
  fiscal_year?: string;
  keywords?: string | null;
  sort?: { field: string; direction: string }[];
}

export interface SearchPublicIndicatorPayloadFilters {
  tags?: string[];
  topics?: string[];
  categories?: string[];
  metric_group_ids?: string[];
  metric_ids?: string[];
}
