<ng-container *ngIf="sectionForm">
  <form class="form" [formGroup]="sectionForm">
    <div class="formfield tip large">
      <div class="fx-row align-center">
        <mat-icon svgIcon="info" class="tip-icon color-grey-800 mr-2"></mat-icon>
      </div>
      <div class="text tip-text tip-text-dark mat-body color-grey-900 pl-3">
        {{ "This section will be displayed in the table of contents for this report." | translate }}
      </div>
    </div>

    <div class="formfield large">
      <lib-text-input [label]="'Section code' | translate" [control]="sectionForm.code"></lib-text-input>
    </div>

    <div class="formfield large">
      <lib-text-input [label]="'Section label' | translate" [control]="sectionForm.label"></lib-text-input>
    </div>

    <div class="formfield large">
      <lib-select-input
        [label]="'Parent section' | translate"
        [control]="sectionForm.parentId"
        [options]="parentOptions"
      >
      </lib-select-input>
    </div>
  </form>

  <mat-divider class="mt-4"></mat-divider>

  <div *libPermission="managePermissions" class="mt-4">
    <div class="form-submit">
      <button
        lib-button
        type="success"
        (click)="saveSection()"
        [disabled]="sectionForm.invalid || sectionForm.pristine"
        [isLoading]="isLoading"
        [label]="'Save' | translate"
      ></button>
      <button lib-button [label]="'Cancel' | translate" (click)="closePanel.emit()"></button>
    </div>
  </div>
</ng-container>
