import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ValueFormControl } from '../../metric-editor-form/models/valueFormControl';
import { Subscription } from 'rxjs';
import { ObservableUtils } from '../../classes';
import { filter } from 'rxjs/operators';
import { StripNonPrintableCharactersService } from '../../services/common';

@Directive({
  selector:
    'input[libStripNonPrintableCharacters], textarea[libStripNonPrintableCharacters], ejs-richtexteditor[libStripNonPrintableCharacters]',
})
export class StripNonPrintableCharactersDirective implements OnInit, OnDestroy {
  @Input() formControl?: UntypedFormControl | ValueFormControl;

  private valueChangeSubscription?: Subscription;
  private blurEventStop?: () => void;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private stripNonPrintableCharactersService: StripNonPrintableCharactersService
  ) {}

  ngOnInit() {
    if (this.formControl) {
      this.valueChangeSubscription = this.formControl.valueChanges
        .pipe(
          ObservableUtils.filterNullish(),
          filter((value: string) => this.stripNonPrintableCharactersService.containsNonPrintableCharacters(value))
        )
        .subscribe((value: string) => {
          this.formControl?.setValue(this.stripNonPrintableCharactersService.sanitize(value));
        });
    } else {
      this.blurEventStop = this.renderer.listen(this.elementRef.nativeElement, 'blur', (event: FocusEvent) => {
        const value = (event.target as HTMLInputElement).value;
        if (value) {
          if (this.stripNonPrintableCharactersService.containsNonPrintableCharacters(value)) {
            this.renderer.setProperty(
              this.elementRef.nativeElement,
              'value',
              this.stripNonPrintableCharactersService.sanitize(value)
            );
          }
        }
      });
    }
  }

  ngOnDestroy() {
    this.valueChangeSubscription?.unsubscribe();
    if (this.blurEventStop) {
      this.blurEventStop();
    }
  }
}
