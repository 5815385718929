import { FiscalYear, FiscalYearAuditTrail, FiscalYearAuditTrailStatus, User } from '../models';
import { v4 as uuidv4 } from 'uuid';
import { FiscalYearBuilder } from './fiscal-year-builder';
import { faker } from '@faker-js/faker';
import { UserBuilder } from './user-builder';

export class FiscalYearAuditTrailBuilder {
  private readonly _fiscalYearAuditTrail: FiscalYearAuditTrail;

  constructor() {
    this._fiscalYearAuditTrail = {
      id: uuidv4(),
      notes: faker.lorem.paragraph(),
      name: faker.lorem.text(),
      fiscal_year: new FiscalYearBuilder().build(),
      event_type: FiscalYearAuditTrailStatus.OPEN,
      created_by_user: new UserBuilder().build(),
    };
  }

  id(id: string): FiscalYearAuditTrailBuilder {
    this._fiscalYearAuditTrail.id = id;
    return this;
  }

  notes(notes: string): FiscalYearAuditTrailBuilder {
    this._fiscalYearAuditTrail.notes = notes;
    return this;
  }

  fiscalYear(fiscalYear: FiscalYear): FiscalYearAuditTrailBuilder {
    this._fiscalYearAuditTrail.fiscal_year = fiscalYear;
    return this;
  }

  eventType(eventType: FiscalYearAuditTrailStatus): FiscalYearAuditTrailBuilder {
    this._fiscalYearAuditTrail.event_type = eventType;
    return this;
  }

  createdByUser(createdByUser: User): FiscalYearAuditTrailBuilder {
    this._fiscalYearAuditTrail.created_by_user = createdByUser;
    return this;
  }

  build(): FiscalYearAuditTrail {
    return this._fiscalYearAuditTrail;
  }
}
