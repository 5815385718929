import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NumberMaskConfig, DecimalMarker } from './number-mask';
import { getUserLocale } from 'get-user-locale';
import { DecimalPipe, NumberSymbol, getLocaleNumberSymbol } from '@angular/common';
import { StripNonPrintableCharactersService } from '../services/common';

@Injectable({
  providedIn: 'root',
})
export class NumberMaskService {
  constructor(
    private decimalPipe: DecimalPipe,
    private stripNonPrintableCharacters: StripNonPrintableCharactersService
  ) {}

  readonly DEFAULT_LOCALE = 'en-US';

  private userLocale: string = this.getCurrentUserLocale();

  private currentLocaleConfig: NumberMaskConfig = {
    thousandSeparator: this.getGroupSeparator(),
    decimalMarker: this.getDecimalSeparator(),
  };

  private _numberMaskConfig: BehaviorSubject<NumberMaskConfig> = new BehaviorSubject<NumberMaskConfig>(
    this.currentLocaleConfig
  );

  private countDecimalsOfANumber(number: number) {
    const converted = number.toString();
    if (converted.includes('.')) {
      return converted.split('.')[1].length;
    }
    return 0;
  }

  public readonly numberMaskConfig$: Observable<NumberMaskConfig> = this._numberMaskConfig.asObservable();

  public setUserLocaleAndUpdateMaskConfig(locale: string): void {
    this.userLocale = locale;
    this.currentLocaleConfig = {
      thousandSeparator: this.getGroupSeparator(),
      decimalMarker: this.getDecimalSeparator(),
    };
    this.setMaskConfig(this.currentLocaleConfig);
  }

  public setMaskConfig(numberMaskConfig: NumberMaskConfig): void {
    return this._numberMaskConfig.next(numberMaskConfig);
  }

  public getCurrentUserLocale(): string {
    return getUserLocale({ fallbackLocale: this.DEFAULT_LOCALE }) || this.DEFAULT_LOCALE;
  }

  public getGroupSeparator(locale: string = this.userLocale): string {
    let groupSeparator: string = '';
    try {
      groupSeparator = getLocaleNumberSymbol(locale, NumberSymbol.CurrencyGroup);
    } catch (error) {
      groupSeparator = getLocaleNumberSymbol(this.DEFAULT_LOCALE, NumberSymbol.CurrencyGroup);
    }
    return this.stripNonPrintableCharacters.sanitizeWhitespaces(groupSeparator);
  }

  public getDecimalSeparator(locale: string = this.userLocale): DecimalMarker {
    try {
      return getLocaleNumberSymbol(locale, NumberSymbol.CurrencyDecimal) as DecimalMarker;
    } catch (error) {
      return getLocaleNumberSymbol(this.DEFAULT_LOCALE, NumberSymbol.CurrencyDecimal) as DecimalMarker;
    }
  }

  public maskCurrency(value?: number, unit?: string): string {
    return this.maskNumber(value, 2, unit, true, true);
  }

  public maskNumberNoSymbol(value?: number, maxNumberDecimal?: number): string {
    return this.maskNumber(value, maxNumberDecimal, '', true, false);
  }

  public maskNumber(
    value?: number,
    maxNumberDecimal?: number,
    unit?: string,
    padding: boolean = false,
    invertSymbol: boolean = false,
    unitSpace: boolean = false,
    withSymbol: boolean = true
  ): string {
    if (value === undefined) {
      return '';
    }

    let minNumberDecimals: number = this.countDecimalsOfANumber(value);

    maxNumberDecimal = maxNumberDecimal ?? minNumberDecimals;

    if (padding || minNumberDecimals > maxNumberDecimal) {
      minNumberDecimals = maxNumberDecimal;
    }

    const pipedNumber =
      this.decimalPipe.transform(value, `1.${minNumberDecimals}-${maxNumberDecimal}`, this.userLocale) || '0';
    let number = pipedNumber;
    if (withSymbol) {
      const unitSymbol = unit ?? '';
      number = invertSymbol ? `${unitSymbol}${pipedNumber}` : `${pipedNumber}${unitSpace ? ' ' : ''}${unitSymbol}`;
    }

    return this.stripNonPrintableCharacters.sanitizeWhitespaces(number);
  }
}
