import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getItemById',
})
export class GetItemByIdPipe implements PipeTransform {
  transform(items: any[], itemId: string): any | undefined {
    return items.find((item) => (item['id'] as string) === itemId);
  }
}
