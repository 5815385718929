import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '../../services/common';

@Component({
  selector: 'lib-panel-details',
  templateUrl: './panel-details.component.html',
  styleUrls: ['./panel-details.component.scss'],
})
export class PanelDetailsComponent implements OnInit {
  @Input() title: string = this.translateService.instant('Metrics');
  collectionCollapsed: boolean = false;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  toggleCollapse() {
    this.collectionCollapsed = !this.collectionCollapsed;
  }
}
