import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  ApplicationApiDefinition,
  DataRequestSource,
  DataRequestUser,
  DataRequestUserPayload,
  UpdateDataRequestUserPayload,
  DataRequestUserResponsibility,
  DataRequestSourceSummary,
} from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClientDataRequestSourcesService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.dataRequests;
  }

  public listDataRequestSources(dataRequestId: string): Observable<ApiResponse<DataRequestSource[]>> {
    return this.apiService
      .get(`${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources`)
      .pipe(
        map((response: ApiResponse<DataRequestSource[]>) => {
          response.data.sort((a: DataRequestSource, b: DataRequestSource) =>
            a.business_unit.name.localeCompare(b.business_unit.name)
          );
          return response;
        })
      );
  }

  public listDataRequestSourcesSummary(dataRequestId: string): Observable<ApiResponse<DataRequestSourceSummary[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/summary`
    );
  }

  public getDataRequestSource(
    dataRequestId: string,
    requestSourceId: string
  ): Observable<ApiResponse<DataRequestSource>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${requestSourceId}`
    );
  }

  public createDataRequestSource(
    dataRequestId: string,
    businessUnitId: string
  ): Observable<ApiResponse<DataRequestSource>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources`,
      {
        business_unit_id: businessUnitId,
      }
    );
  }

  public activateDataRequestSource(
    dataRequestId: string,
    dataRequestSourceId: string,
    emailResponsibilities?: DataRequestUserResponsibility[]
  ): Observable<void> {
    const payload: Record<string, unknown> = {};
    if (emailResponsibilities) {
      payload.users_responsibilities_email = emailResponsibilities;
    }
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/activate`,
      payload
    );
  }

  public activateDataRequestSourcePrefill(
    dataRequestId: string,
    dataRequestSourceId: string,
    emailResponsibilities?: DataRequestUserResponsibility[]
  ): Observable<ApiResponse> {
    const payload: Record<string, unknown> = {};
    if (emailResponsibilities) {
      payload.users_responsibilities_email = emailResponsibilities;
    }
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/activate/prefill`,
      payload
    );
  }

  public deactivateDataRequestSource(dataRequestId: string, dataRequestSourceId: string): Observable<void> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/deactivate`,
      {}
    );
  }

  public deleteDataRequestSource(dataRequestId: string, dataRequestSourceId: string): Observable<void> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}`
    );
  }

  public getDataRequestSourceForDataRequest(
    dataRequestId: string,
    dataRequestSourceId: string
  ): Observable<ApiResponse<DataRequestSource>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}`
    );
  }

  public createDataRequestSourceCollaborator(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestUserPayload: DataRequestUserPayload
  ): Observable<ApiResponse<DataRequestUser>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/data_request_users`,
      dataRequestUserPayload
    );
  }

  public editDataRequestSourceCollaborator(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestUserId: string,
    updateDataRequestUserPayload: UpdateDataRequestUserPayload
  ): Observable<ApiResponse<DataRequestUser>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/data_request_users/${dataRequestUserId}`,
      updateDataRequestUserPayload
    );
  }

  public deleteDataRequestSourceCollaborator(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestUserId: string
  ): Observable<void> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/data_request_users/${dataRequestUserId}`
    );
  }

  public deactivateDataRequestSourceCollaborator(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestUserId: string
  ): Observable<void> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/data_request_users/${dataRequestUserId}/deactivate`,
      {}
    );
  }

  public activateDataRequestSourceCollaborator(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestUserId: string
  ): Observable<void> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/data_request_users/${dataRequestUserId}/activate`,
      {}
    );
  }

  public postDataRequestSignedPublicAccessKey(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestUserId: string
  ): Observable<ApiResponse<string>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/data_request_users/${dataRequestUserId}/share_key`
    );
  }

  public remindDataRequestParticipants(
    dataRequestId: string,
    dataRequestSourceId: string,
    emailResponsibilities?: DataRequestUserResponsibility[]
  ): Observable<void> {
    const payload: any = {};
    if (emailResponsibilities) {
      payload.users_responsibilities_email = emailResponsibilities;
    }
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/send_reminder_email`,
      payload
    );
  }

  public emailCollaborator(
    dataRequestId: string,
    dataRequestSourceId: string,
    dataRequestUserId: string
  ): Observable<void> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/data_request_users/${dataRequestUserId}/send_reminder_email`
    );
  }
}
