import { BenchmarkV2Attribute, MetricSearchValueDefinitionField } from '../../models';

export class BenchmarkV2Utils {
  public static toBenchmarkV2Attribute(field: string): BenchmarkV2Attribute {
    switch (field) {
      case MetricSearchValueDefinitionField.explanation:
        return BenchmarkV2Attribute.explanation;
      case MetricSearchValueDefinitionField.pageNumber:
        return BenchmarkV2Attribute.pageNumber;
      case MetricSearchValueDefinitionField.url:
        return BenchmarkV2Attribute.url;
      default:
        return BenchmarkV2Attribute.all;
    }
  }

  public static toMetricSearchValueDefinitionField(field: BenchmarkV2Attribute): MetricSearchValueDefinitionField {
    switch (field) {
      case BenchmarkV2Attribute.explanation:
        return MetricSearchValueDefinitionField.explanation;
      case BenchmarkV2Attribute.pageNumber:
        return MetricSearchValueDefinitionField.pageNumber;
      case BenchmarkV2Attribute.url:
        return MetricSearchValueDefinitionField.url;
      default:
        return MetricSearchValueDefinitionField.all;
    }
  }
}
