import { faker } from '@faker-js/faker';
import { v4 as uuid4 } from 'uuid';

import { CDPSector } from '../models';
import { CreatedUpdatedBuilder } from './created-updated-builder';

export class CDPSectorBuilder extends CreatedUpdatedBuilder<CDPSector> {
  constructor() {
    super({ id: uuid4(), name: faker.company.name() });
  }

  public id(id: string): CDPSectorBuilder {
    this._instance.id = id;
    return this;
  }

  public name(name: string): CDPSectorBuilder {
    this._instance.name = name;
    return this;
  }
}
