import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';
import { JobQueueOutputSPReportSubmission } from '../models';

export class SPJobQueueOutputBuilder {
  private readonly _spJobQueueOutput: JobQueueOutputSPReportSubmission;

  constructor() {
    this._spJobQueueOutput = {
      report_id: uuidv4(),
      report_name: faker.random.alpha(10),
      filename: '',
      file_id: '',
      url: '',
      sp_upload_instance_id: '',
      error_message: '',
      prefill_log_text: '',
    };
  }

  report_id(report_id: string): SPJobQueueOutputBuilder {
    this._spJobQueueOutput.report_id = report_id;
    return this;
  }

  report_name(report_name: string): SPJobQueueOutputBuilder {
    this._spJobQueueOutput.report_name = report_name;
    return this;
  }

  filename(filename: string): SPJobQueueOutputBuilder {
    this._spJobQueueOutput.filename = filename;
    return this;
  }

  file_id(file_id: string): SPJobQueueOutputBuilder {
    this._spJobQueueOutput.file_id = file_id;
    return this;
  }

  url(url: string): SPJobQueueOutputBuilder {
    this._spJobQueueOutput.url = url;
    return this;
  }

  sp_upload_instance_id(sp_upload_instance_id: string): SPJobQueueOutputBuilder {
    this._spJobQueueOutput.sp_upload_instance_id = sp_upload_instance_id;
    return this;
  }

  error_message(error_message: string): SPJobQueueOutputBuilder {
    this._spJobQueueOutput.error_message = error_message;
    return this;
  }

  prefill_log_text(prefill_log_text: string): SPJobQueueOutputBuilder {
    this._spJobQueueOutput.prefill_log_text = prefill_log_text;
    return this;
  }

  build(): JobQueueOutputSPReportSubmission {
    return this._spJobQueueOutput;
  }
}
