import { faker } from '@faker-js/faker';

import { CDPSector, Industry, Org, WDIIndustry } from '../models';

export class OrgBuilder {
  private readonly _org: Org;

  constructor() {
    this._org = {
      id: 'orgId',
      name: 'Novisto',
      active: true,
      code: '',
      brand_name: faker.company.name(),
      website: faker.internet.url(),
      headquarter_location: faker.address.countryCode(),
      listed: true,
      lucid_axon: false,
      fte: 0,
      stock_exchange_market: faker.commerce.department(),
      ticker: faker.commerce.product(),
      primary_isin: 'US012071998',
      lei: 'ABCDEFGHIJKLMNOPQRST',
      cdp_account_number: faker.address.zipCode(),
      cdp_sector: null,
      wdi_industry: null,
      fiscal_year_information: {
        regular: true,
        start: '2022-01-01',
        end: '2022-12-31',
      },
      industries: [],
    };
  }

  id(id: string): OrgBuilder {
    this._org.id = id;
    return this;
  }

  name(name: string): OrgBuilder {
    this._org.name = name;
    return this;
  }

  cdpSector(cdpSector: CDPSector): OrgBuilder {
    this._org.cdp_sector = cdpSector;
    return this;
  }

  industries(industries: Industry[]): OrgBuilder {
    this._org.industries = industries;
    return this;
  }

  wdiIndustry(wdiIndustry: WDIIndustry): OrgBuilder {
    this._org.wdi_industry = wdiIndustry;
    return this;
  }

  build(): Org {
    return this._org;
  }
}
