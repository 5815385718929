import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { EMPTY, filter, map, Observable, switchMap, take, tap } from 'rxjs';
import { ObservableUtils } from '../../../../classes';
import { Required } from '../../../../decorators';
import { DialogsService } from '../../../../dialogs';
import { StandardDocumentMetadata } from '../../../../documents';
import {
  DialogResult,
  Doc,
  FileDocumentInterface,
  FileTypeDetailsV2,
  FileValue,
  ItemType,
  RequestDocMetadata,
  Status,
} from '../../../../models';
import { TranslateService } from '../../../../services/common';
import { DEFAULT_DOCUMENT_CONTEXT } from '../../../models/documentContext';
import { ValueFormControl } from '../../../models/valueFormControl';
import { BaseMetricEditorFormStateService } from '../../../services/base-metric-editor-form-state/base-metric-editor-form-state.service';
import {
  MetricEditorAttachFileDialogComponent,
  MetricEditorAttachFileDialogConfig,
  MetricEditorAttachFileDialogData,
} from '../../metric-editor-file-attachment/metric-editor-attach-file-dialog/metric-editor-attach-file-dialog.component';

@Component({
  selector: 'lib-metric-editor-file-upload-card',
  templateUrl: './metric-editor-file-upload-card.component.html',
  styleUrls: ['./metric-editor-file-upload-card.component.scss'],
})
export class MetricEditorFileUploadCardComponent implements OnInit {
  @Input() @Required() valueFormControl!: ValueFormControl<FileTypeDetailsV2>;
  @Input() documentContext = DEFAULT_DOCUMENT_CONTEXT;
  @Input() messages?: ValidationErrors;
  @Input() disabled: boolean = false;

  @Output() addFileDoc: EventEmitter<FileDocumentInterface> = new EventEmitter<FileDocumentInterface>();
  @Output() addFileValue: EventEmitter<FileValue> = new EventEmitter<FileValue>();

  uploading: boolean = false;
  hint: string = '';
  customBrowseDialog = false;

  constructor(
    private baseMetricEditorFormStateService: BaseMetricEditorFormStateService,
    private dialogsService: DialogsService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.customBrowseDialog = this.documentContext.itemType === ItemType.metrics_indicator;
    this.hint = this.valueFormControl.valueRef.hint ?? '';
  }

  public dropFile(event: DragEvent): void {
    if (event.dataTransfer) {
      this.handleFile(event.dataTransfer.files[0], event);
    }
  }

  private handleFile(file: File, event: Event): void {
    if (this.valueFormControl.enabled) {
      this.preventEventDefault(event);
      this.uploadFile(file);
    }
  }

  public preventEventDefault(evt: Event): void {
    evt.preventDefault();
    evt.stopImmediatePropagation();
  }

  public selectFileFromBrowser(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      this.handleFile(files[0], event);
    }
  }

  private uploadFile(file: File): void {
    switch (this.documentContext.itemType) {
      case ItemType.data_requests_request:
      case ItemType.public_data_requests_request:
        this.uploading = true;
        this.baseMetricEditorFormStateService
          .uploadDocument(file)
          .pipe(take(1))
          .subscribe((doc: FileDocumentInterface) => {
            this.addADocIdAndUpdate(doc.id, doc.name, doc.format);
          });
        break;
      case ItemType.metrics_preview:
        this.launchUnsupportedTypeErrorDialog();
        break;
      default:
        this.launchCustomBrowseDialog(file);
    }
  }

  public launchCustomBrowseDialog(file?: File): void {
    this.dialogsService
      .open<
        MetricEditorAttachFileDialogComponent,
        MetricEditorAttachFileDialogConfig,
        DialogResult<MetricEditorAttachFileDialogData>
      >(MetricEditorAttachFileDialogComponent, {
        data: {
          file,
        },
      })
      .afterClosed()
      .pipe(
        filter((result) => result?.status === Status.SUCCESS),
        map((result) => result?.data),
        ObservableUtils.filterNullish(),
        switchMap((attachedFileData) => this.handleCustomBrowseDialogAfterClosed(attachedFileData))
      )
      .subscribe();
  }

  private handleCustomBrowseDialogAfterClosed(
    attachedFileData: MetricEditorAttachFileDialogData
  ): Observable<FileDocumentInterface> {
    this.uploading = true;
    if (attachedFileData.doc) {
      this.addADocIdAndUpdate(
        attachedFileData.doc.id,
        attachedFileData.doc.name,
        attachedFileData.doc.format,
        attachedFileData.doc,
        attachedFileData.doc.public ? attachedFileData.doc.url : ''
      );
      return EMPTY;
    } else if (attachedFileData.formData) {
      const file = <File>attachedFileData.formData.get('document');
      return this.baseMetricEditorFormStateService.uploadDocument(file, attachedFileData.formData).pipe(
        take(1),
        tap((doc) => this.addADocIdAndUpdate(doc.id, doc.name, doc.format, doc.doc))
      );
    }
    return EMPTY;
  }

  private addADocIdAndUpdate(
    id: string,
    name = 'no-name',
    format = 'no-format',
    doc?: Doc | RequestDocMetadata | StandardDocumentMetadata,
    docUrl?: string
  ): void {
    this.addFileDoc.emit({ id, format, name, doc });
    this.addFileValue.emit({ file_id: id, url: docUrl });
    this.uploading = false;
  }

  private launchUnsupportedTypeErrorDialog(): void {
    this.dialogsService.error(
      this.translateService.instant('Uploading a file is not supported in preview mode.'),
      this.translateService.instant('Action not supported')
    );
  }

  public getLabel(): string {
    if (this.valueFormControl.invalid && this.valueFormControl.touched) {
      return this.translateService.instant('File upload error');
    } else if (this.uploading) {
      return this.translateService.instant('File upload in progress');
    }
    return this.valueFormControl.valueRef.label ?? '';
  }
}
