import { ConditionalTrigger, Value, ValueGroup } from '../models';

export class ValueGroupBuilder {
  private readonly _valueGrp: ValueGroup;

  constructor() {
    this._valueGrp = {
      id: 'vg_1',
      group_max_repetition: 0,
      group_original_presentation: '',
      indent: 0,
      indicator_id: 'ind_1',
      name: '',
      position: 1,
      value_definition_group_id: 'vgd_1',
      values: [],
    };
  }

  id(id: string): ValueGroupBuilder {
    this._valueGrp.id = id;
    return this;
  }

  indicatorId(indicatorId: string): ValueGroupBuilder {
    this._valueGrp.indicator_id = indicatorId;
    return this;
  }

  table_id(table_id: string): ValueGroupBuilder {
    this._valueGrp.table_id = table_id;
    return this;
  }

  values(values: Value[]): ValueGroupBuilder {
    this._valueGrp.values = values;
    return this;
  }

  repeatable(repeatable: boolean): ValueGroupBuilder {
    this._valueGrp.repeatable = repeatable;
    return this;
  }

  name(name: string): ValueGroupBuilder {
    this._valueGrp.name = name;
    return this;
  }

  group_max_repetition(group_max_repetition: number): ValueGroupBuilder {
    this._valueGrp.group_max_repetition = group_max_repetition;
    return this;
  }

  group_original_presentation(group_original_presentation: string): ValueGroupBuilder {
    this._valueGrp.group_original_presentation = group_original_presentation;
    return this;
  }

  value_definition_group_id(vdg_id: string): ValueGroupBuilder {
    this._valueGrp.value_definition_group_id = vdg_id;
    return this;
  }

  position(position: number): ValueGroupBuilder {
    this._valueGrp.position = position;
    return this;
  }

  subposition(subPosition?: number | null): ValueGroupBuilder {
    this._valueGrp.subposition = subPosition;
    return this;
  }

  conditionalTriggers(conditionalTriggers: ConditionalTrigger[]): ValueGroupBuilder {
    this._valueGrp.conditional_triggers = conditionalTriggers;
    return this;
  }

  label(label: string): ValueGroupBuilder {
    this._valueGrp.label = label;
    return this;
  }

  build(): ValueGroup {
    return this._valueGrp;
  }
}
