import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '../../common/base/base.service';
import { ApiService } from '../../common/api/api.service';
import { ApiResponse, ApplicationApiDefinition, Doc, SearchOptions, DocMetaData } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class ClientResourcesService {
  apiName: keyof ApplicationApiDefinition = 'search';
  apiNameDocuments: keyof ApplicationApiDefinition = 'documents';
  resourceSearch: string;
  resource: string;
  serviceDocumentsPath: string;
  servicePath: string;

  constructor(private baseService: BaseService, private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resourceSearch = this.apiService.apiConfig.apis.search.resources.resources;
    this.serviceDocumentsPath = apiService.getServicePath(this.apiNameDocuments);
    this.resource = this.apiService.apiConfig.apis.documents.resources.resources;
  }

  payloadFromSearchOptions(searchOptions: SearchOptions): any {
    if (!searchOptions.custom_filters) searchOptions.custom_filters = {};
    searchOptions.filters = this.baseService.renameObjectProperty(searchOptions.filters, 'source', 'org_id');
    switch (searchOptions.item_type) {
      case 'docs_report':
        searchOptions.custom_filters.scope = 'report';
        break;
      case 'docs_resource':
        searchOptions.custom_filters.scope = 'resource';
        break;
      default:
        break;
    }
    return this.baseService.esPayloadFromSearchOptions(searchOptions);
  }

  search(searchOptions: SearchOptions): Observable<ApiResponse<Doc[]>> {
    const payload: any = this.payloadFromSearchOptions(searchOptions);
    return this.apiService.post(`${this.servicePath}${this.resourceSearch}/resources`, payload);
  }

  getDocumentMetaData(doc_id: string): Observable<ApiResponse<DocMetaData>> {
    return this.apiService.get(`${this.serviceDocumentsPath}${this.resource}/${doc_id}/metadata`);
  }
}
