import { WaitableForNextUpdate } from '../models/waitableForNextUpdate';
import { delayWhen, iif, Observable, of, timeout } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

export function waitForNextUpdate<T>(
  target: WaitableForNextUpdate,
  timeoutTime: number = 3000
): (source$: Observable<T>) => Observable<T> {
  return (source$: Observable<T>): Observable<T> =>
    source$.pipe(
      delayWhen(() =>
        target.isWaitingForNextUpdate$.pipe(
          filter((value) => !value),
          timeout({ each: timeoutTime, with: () => of(true) })
        )
      )
    );
}

export function debounceTimeIfAny<T>(time: number): (source$: Observable<T>) => Observable<T> {
  return (source$: Observable<T>): Observable<T> => iif(() => time > 0, source$.pipe(debounceTime(time)), source$);
}
