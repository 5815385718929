import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { SearchService } from '../../services/search.service';
import { Observable, iif, of } from 'rxjs';
import { ActionItem, FiscalYear } from '../../../models';
import { TranslateService } from '../../../services/common';

@Component({
  selector: 'lib-filter-years',
  templateUrl: './filter-years.component.html',
  styleUrls: ['./filter-years.component.scss'],
})
export class FilterYearsComponent implements OnInit {
  @Input() options: ActionItem<FiscalYear>[] = [];
  @Input() activeOption?: ActionItem;
  @Input() disabled: boolean = false;

  @Output() optionSelected = new EventEmitter<ActionItem<FiscalYear>>();

  currentYear = new Date().getFullYear().toString();
  titlePlaceholder: string = this.translateService.instant('Fiscal year');

  fiscalYears$: Observable<ActionItem<FiscalYear>[]> | undefined;

  constructor(private searchService: SearchService, private translateService: TranslateService) {}

  ngOnInit(): void {
    //temporary fix for NF-6645, needs to be re-worked in NF-6657
    this.fiscalYears$ = iif(
      () => this.options.length > 0,
      of(this.options),
      this.searchService.searchResources('year')
    );
  }

  getDefaultValueTitle(): string {
    return this.translateService.instant('Current ({currentYear})', { currentYear: this.currentYear });
  }

  getButtonTitle(): string {
    return this.translateService.instant('FY{fiscalYear}', { fiscalYear: this.activeOption?.title ?? '' });
  }
}
