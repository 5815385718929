import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { fromEvent, merge, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base/base.service';
import { AppConfig, Platforms, SupportedEnvironment, VersionInfo } from '../../../models';
import { LocalStorageUtils } from '../../../classes';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  private _data: any = undefined;

  public get network(): Observable<boolean> {
    return merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  public get platform(): Platforms {
    return this.document.location.host.startsWith('localhost')
      ? this.document.location.port === '4200'
        ? Platforms.CLIENT
        : Platforms.ADMIN
      : this.document.location.host.includes('core.')
      ? Platforms.ADMIN
      : Platforms.CLIENT;
  }

  public get env(): 'client' | 'novisto' {
    if (
      this.document.location.host.startsWith('localhost') ||
      this.document.location.host.includes('platform.novisto.net') ||
      this.document.location.host.includes('novistoam.novisto.net')
    ) {
      return 'novisto';
    }
    return 'client';
  }

  public get info(): Observable<VersionInfo> {
    return this.http.get<VersionInfo>('assets/info.json?' + this.baseService.newGuid());
  }

  public get version(): string {
    return this.appConfig.version;
  }

  public get environment(): SupportedEnvironment {
    const host = this.document.location.host.toLowerCase();
    switch (true) {
      case this.checkEnv(SupportedEnvironment.localhost, host):
        return SupportedEnvironment.localhost;
      case this.checkEnv(SupportedEnvironment.dev, host):
        return SupportedEnvironment.dev;
      case this.checkEnv(SupportedEnvironment.qa, host):
        return SupportedEnvironment.qa;
      case this.checkEnv(SupportedEnvironment.experiment, host):
        return SupportedEnvironment.experiment;
      case this.checkEnv(SupportedEnvironment.staging, host):
        return SupportedEnvironment.staging;
      case this.checkEnv(SupportedEnvironment.production, host):
      default:
        return SupportedEnvironment.production;
    }
  }

  public get isProductionEnvironment(): boolean {
    return this.environment === SupportedEnvironment.production;
  }

  public getEnvironmentInfo(orgName: string): string {
    let envInUpperCase: string = '';
    if (
      this.environment === SupportedEnvironment.dev ||
      this.environment === SupportedEnvironment.qa ||
      this.environment === SupportedEnvironment.experiment
    ) {
      envInUpperCase = this.environment.toUpperCase();
    }
    return `${orgName ? orgName + ' - ' : ''} ${envInUpperCase || this.environment} environment - ${this.version}`;
  }

  public get data(): any {
    if (this._data == undefined) {
      try {
        const dataItem = LocalStorageUtils.getFromStorage('data');
        this._data = dataItem ?? {};
      } catch {
        this._data = {};
      }
    }
    return this._data;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private baseService: BaseService,
    private appConfig: AppConfig
  ) {}

  // Local storage data
  // TODO: Refactor this method https://novisto.atlassian.net/browse/NF-1323
  public upsertData(key: string, value: any): void {
    const data: any = this.data;
    if (value) {
      data[key] = value;
    } else {
      delete data[key];
    }
    LocalStorageUtils.addToStorage('data', data);
    this._data = undefined;
  }

  public getData(key: string): any {
    return this.data[key];
  }

  public isClientPlatform(): boolean {
    return this.platform === Platforms.CLIENT;
  }

  private checkEnv(environment: SupportedEnvironment, host: string): boolean {
    return environment === SupportedEnvironment.localhost
      ? host.startsWith(environment)
      : host.startsWith(`${environment}.`);
  }
}
