import { Pipe, PipeTransform } from '@angular/core';

import merge from 'lodash/merge';

@Pipe({ name: 'objectAssign' })
export class ObjectAssignPipe implements PipeTransform {
  transform(mutableObject: object, assignObject?: object): object {
    return merge(mutableObject, assignObject);
  }
}
