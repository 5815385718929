import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';

import { EntityBuilder } from './entity-builder';
import {
  IntegerTypeDetails,
  Metric,
  Source,
  ValueDefinition,
  ValueDefinitionTarget,
  ValueDefinitionTargetStep,
  ValueDefinitionTargetValue,
} from '../models';
import { MetricBuilder } from './metric-builder';
import { SourceBuilder } from './source-builder';
import { ValueDefinitionBuilder } from './value-definition-builder';

export class ValueDefinitionTargetBuilder extends EntityBuilder<ValueDefinitionTarget> {
  constructor() {
    super({
      id: uuidv4(),
      baseline_frequency_code: '2022',
      business_unit: new SourceBuilder().build(),
      metric: new MetricBuilder().build(),
      name: faker.helpers.unique(() => faker.name.jobTitle()),
      target_name: faker.helpers.unique(() => faker.name.jobArea()),
      target_description: faker.lorem.paragraph(),
      target_steps: [],
      value_definition: new ValueDefinitionBuilder<IntegerTypeDetails>().label(faker.name.jobDescriptor()).build(),
      baseline_value: null,
      most_recent_baseline_value: 10,
      values: [],
    });
  }

  baselineFrequencyCode(baselineFrequencyCode: string): ValueDefinitionTargetBuilder {
    this._instance.baseline_frequency_code = baselineFrequencyCode;
    return this;
  }

  baselineValue(baselineValue?: number): ValueDefinitionTargetBuilder {
    this._instance.baseline_value = baselineValue;
    return this;
  }

  businessUnit(businessUnit: Source): ValueDefinitionTargetBuilder {
    this._instance.business_unit = businessUnit;
    return this;
  }

  metric(metric: Metric): ValueDefinitionTargetBuilder {
    this._instance.metric = metric;
    return this;
  }

  mostRecentBaselineValue(mostRecentBaselineValue: number): ValueDefinitionTargetBuilder {
    this._instance.most_recent_baseline_value = mostRecentBaselineValue;
    return this;
  }

  targetName(targetName: string): ValueDefinitionTargetBuilder {
    this._instance.target_name = targetName;
    return this;
  }

  targetDescription(targetDescription?: string): ValueDefinitionTargetBuilder {
    this._instance.target_description = targetDescription;
    return this;
  }

  targetSteps(targetSteps: ValueDefinitionTargetStep[]): ValueDefinitionTargetBuilder {
    this._instance.target_steps = targetSteps;
    return this;
  }

  valueDefinition(valueDefinition: ValueDefinition): ValueDefinitionTargetBuilder {
    this._instance.value_definition = valueDefinition;
    return this;
  }

  values(values: ValueDefinitionTargetValue[]): ValueDefinitionTargetBuilder {
    this._instance.values = values;
    return this;
  }
}
