import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';

import { BenchmarkV2ResponseValue, BenchmarkV2Attribute, PeerDataValueNullCategories } from '../models';
import { Builder } from './builder';

export class BenchmarkV2ResponseValueBuilder extends Builder<BenchmarkV2ResponseValue> {
  constructor() {
    super({
      attribute: BenchmarkV2Attribute.all,
      null_categories: [null],
      peer_id: uuidv4(),
      value_definition_id: uuidv4(),
      values: [faker.company.bsNoun()],
    });
  }

  public attribute(attribute: BenchmarkV2Attribute): BenchmarkV2ResponseValueBuilder {
    this._instance.attribute = attribute;
    return this;
  }

  public nullCategories(nullCategories?: PeerDataValueNullCategories[]): BenchmarkV2ResponseValueBuilder {
    this._instance.null_categories = nullCategories;
    return this;
  }

  public peerId(peerId: string): BenchmarkV2ResponseValueBuilder {
    this._instance.peer_id = peerId;
    return this;
  }

  public valueDefinitionId(valueDefinitionId: string): BenchmarkV2ResponseValueBuilder {
    this._instance.value_definition_id = valueDefinitionId;
    return this;
  }

  public values(values?: unknown[]): BenchmarkV2ResponseValueBuilder {
    this._instance.values = values;
    return this;
  }
}
