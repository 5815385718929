<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <header>
      <a mat-menu-item disabled>{{ optionProps?.title }}</a>
    </header>

    <ng-container *ngIf="optionProps && optionProps.defaultValue">
      <hr />
      <a mat-menu-item (click)="optionSelected.emit(optionProps.defaultValue)">
        {{ getDefaultValueTitle() }}
      </a>
    </ng-container>

    <hr />

    <ng-container *ngIf="optionProps?.id === sortResourceType">
      <a
        mat-menu-item
        *ngFor="let option of optionProps?.options"
        (click)="optionSelected.emit(option)"
        [ngClass]="{
          active: optionProps?.activeOption?.id === option.id
        }"
      >
        {{ option.title }}
      </a>
    </ng-container>
    <ng-container *ngIf="optionProps?.id !== sortResourceType">
      <a
        mat-menu-item
        *ngFor="let option of optionProps?.options"
        (click)="optionSelected.emit(option)"
        [ngClass]="{
          active: optionProps?.activeOption?.id === option.id
        }"
      >
        {{ option.title }}
      </a>
    </ng-container>
  </ng-template>
</mat-menu>

<lib-filter-button
  [buttonProps]="buttonProps"
  [optionProps]="optionProps"
  [matMenuTriggerFor]="buttonProps?.disabled ? null : menu"
>
</lib-filter-button>
