import { Component, EventEmitter, Input, Output } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { DocumentContext, MoveValueGroupEvent, ResetValueEvent, UpsertValueGroupSet } from '../metric-editor-form';
import { ItemType, Metric, ValueGroupSet } from '../models';
import { MetricPreviewConditionalTriggerService } from './services/metric-preview-conditional-trigger/metric-preview-conditional-trigger.service';
import { MetricPreviewStateService } from './services/metric-preview-state/metric-preview-state.service';
import { MetricPreviewVgsetConstructorService } from './services/metric-preview-vgset-constructor/metric-preview-vgset-constructor.service';

@Component({
  selector: 'lib-metric-preview',
  templateUrl: './metric-preview.component.html',
  styleUrls: ['./metric-preview.component.scss'],
  providers: [MetricPreviewStateService, MetricPreviewConditionalTriggerService],
})
export class MetricPreviewComponent {
  @Input() set metric(metric: Metric) {
    this.valueGroupSet = this.metricPreviewVgsetConstructor.constructVgset(metric);
    this.metricPreviewStateService.initializeVgsetAndMetric(this.valueGroupSet, metric);
  }
  @Input() documentContext: DocumentContext = { itemType: ItemType.metrics_preview };

  @Output() updatedVgs: EventEmitter<ValueGroupSet> = new EventEmitter<ValueGroupSet>();

  valueGroupSet?: ValueGroupSet;

  constructor(
    private metricPreviewStateService: MetricPreviewStateService,
    private metricPreviewVgsetConstructor: MetricPreviewVgsetConstructorService
  ) {
    this.metricPreviewStateService.valueGroupSet$.subscribe((vgset) => {
      this.valueGroupSet = cloneDeep(vgset);
      this.updatedVgs.emit(this.valueGroupSet);
    });
  }

  update(updateVGSEvent: UpsertValueGroupSet): void {
    this.metricPreviewStateService.updateVgset(updateVGSEvent);
  }

  deleteGroup(groupId: string): void {
    this.metricPreviewStateService.deleteGroup(groupId);
  }

  resetValue(resetValueEvent: ResetValueEvent): void {
    this.metricPreviewStateService.resetValue(resetValueEvent);
  }

  moveGroup(moveValueGroupEvent: MoveValueGroupEvent): void {
    this.metricPreviewStateService.moveGroup(moveValueGroupEvent);
  }
}
