import { Entity, Source } from './entities';
import { Metric, ValueDefinition } from './metrics';
import { IntegerTypeDetails } from './type_details';

export interface ValueDefinitionTargetStep extends Entity {
  frequency_code: string;
  value: number;
  value_definition_target_id: string;
  most_recent_value?: number;
}

export interface ValueDefinitionTargetValue {
  frequency_code: string;
  value: number;
}

export interface ValueDefinitionTarget extends Entity {
  baseline_frequency_code: string;
  business_unit: Source;
  metric: Metric;
  target_name: string;
  target_steps: ValueDefinitionTargetStep[];
  value_definition: ValueDefinition<IntegerTypeDetails>;
  baseline_value?: number | null;
  most_recent_baseline_value?: number;
  target_description?: string;
  values?: ValueDefinitionTargetValue[];
}

export interface ValueDefinitionTargetListPayload {
  business_unit_ids?: string[];
  metric_id?: string;
  order_by?: string;
  order_by_direction?: string;
  page?: number;
  page_size?: number;
  search_term?: string;
  topic_ids?: string[];
  value_definition_id?: string;
}

export interface ValueDefinitionTargetStepPayload {
  frequency_code: ValueDefinitionTargetStep['frequency_code'];
  value: ValueDefinitionTargetStep['value'];
}

export interface ValueDefinitionTargetPayload {
  baseline_frequency_code: ValueDefinitionTarget['baseline_frequency_code'];
  baseline_value: ValueDefinitionTarget['baseline_value'] | null;
  business_unit_id: string;
  description: ValueDefinitionTarget['target_description'];
  name: ValueDefinitionTarget['target_name'];
  target_steps: ValueDefinitionTargetStepPayload[];
  value_definition_id: string;
}
