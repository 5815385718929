import { DisplayMetric, SectionMetricDisplay } from '../models';
import { Builder } from './builder';
import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';

export class SectionMetricDisplayBuilder extends Builder<SectionMetricDisplay> {
  constructor() {
    super({
      metametric: false,
      code: faker.lorem.slug(),
      description: faker.lorem.slug(),
      display_metrics: [
        {
          metric_id: uuidv4(),
        },
      ],
    });
  }

  code(code: string): SectionMetricDisplayBuilder {
    this._instance.code = code;
    return this;
  }

  metametric(metametric: boolean): SectionMetricDisplayBuilder {
    this._instance.metametric = metametric;
    return this;
  }

  description(description: string): SectionMetricDisplayBuilder {
    this._instance.description = description;
    return this;
  }

  displayMetrics(displayMetrics: DisplayMetric[]): SectionMetricDisplayBuilder {
    this._instance.display_metrics = displayMetrics;
    return this;
  }
}
