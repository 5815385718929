import { Pipe, PipeTransform } from '@angular/core';
import { ValueGroupFormGroup } from '../models/valueGroupFormGroup';
import { MetricEditorFormGroup } from '../models/metricEditorFormGroup';

@Pipe({
  name: 'repeatableGroupsCount',
})
export class RepeatableGroupsCountPipe implements PipeTransform {
  transform(formGroup: MetricEditorFormGroup, metricFormGroups: MetricEditorFormGroup[]): number {
    if (formGroup instanceof ValueGroupFormGroup) {
      const valueDefinitionGroupId = formGroup.valueGroupRef.value_definition_group_id;
      return metricFormGroups
        .filter((group): group is ValueGroupFormGroup => group instanceof ValueGroupFormGroup)
        .filter(
          (vgf) =>
            vgf.valueGroupRef.repeatable && vgf.valueGroupRef.value_definition_group_id === valueDefinitionGroupId
        ).length;
    }
    return 0;
  }
}
