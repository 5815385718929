import { DocMetaData } from '../models';

export class DocMetadataBuilder {
  private readonly _docMetadata: DocMetaData;

  constructor() {
    this._docMetadata = {
      id: 'docId',
      data_period_end: '',
      data_period_start: '',
      extension: 'pdf',
      fiscal_year: 2022,
      name: 'file_name',
      business_unit_ids: [],
      category: '',
      doc_type: '',
      original_filename: 'docId.pdf',
      url: '',
    };
  }

  name(name: string): DocMetadataBuilder {
    this._docMetadata.name = name;
    return this;
  }

  deleted(deleted: string): DocMetadataBuilder {
    this._docMetadata.deleted = deleted;
    return this;
  }

  build(): DocMetaData {
    return this._docMetadata;
  }
}
