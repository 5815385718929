export interface UpsertValue {
  id?: string;
  value_definition_id: string;
  value?: unknown;
}

export interface UpsertValueGroup {
  id?: string;
  value_definition_group_id: string;
  values: UpsertValue[];
  subposition?: number | null;
}

export interface UpsertValueGroupSet {
  id?: string;
  frequency_code: string;
  data_request_id?: string;
  business_unit_id: string;
  value_groups: UpsertValueGroup[];
  report_id?: string;
}
