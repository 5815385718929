import { BehaviorSubject, Observable } from 'rxjs';
import { DataTablePaginatorConfiguration, DEFAULT_PAGE_SIZE } from '../models';

const DEFAULT_PAGINATION_CONFIGURATION: DataTablePaginatorConfiguration = {
  pageSize: DEFAULT_PAGE_SIZE,
  currentPage: 0,
  total: 0,
};

export abstract class DataTableStateService {
  protected readonly _paginationConfiguration$ = new BehaviorSubject<DataTablePaginatorConfiguration>(
    DEFAULT_PAGINATION_CONFIGURATION
  );

  readonly paginationConfiguration$: Observable<DataTablePaginatorConfiguration>;

  constructor() {
    this.paginationConfiguration$ = this._paginationConfiguration$.asObservable();
  }

  changeFilters(options?: unknown) {
    this._paginationConfiguration$.next({
      ...this._paginationConfiguration$.value,
      currentPage: 0,
    });
  }

  changePagination(tablePaginationConfig: DataTablePaginatorConfiguration, options?: unknown) {
    this._paginationConfiguration$.next({
      ...tablePaginationConfig,
    });
  }

  changeOrdering(options?: unknown) {
    this._paginationConfiguration$.next({
      ...this._paginationConfiguration$.value,
      currentPage: 0,
    });
  }

  changeSearch(options?: unknown) {
    this._paginationConfiguration$.next({
      ...this._paginationConfiguration$.value,
      currentPage: 0,
    });
  }
}
