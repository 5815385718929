<lib-metric-editor-file-upload-card
  [valueFormControl]="valueFormControl"
  [documentContext]="documentContext"
  [messages]="messages"
  [disabled]="valueFormControl.disabled || fileValues.length === maxFilesLimit"
  (addFileDoc)="addFileDoc($event)"
  (addFileValue)="addFileValue($event)"
></lib-metric-editor-file-upload-card>

<ng-container *ngIf="filesLoaded; else loading">
  <div class="file-cards-container" @fadeIn>
    <lib-metric-editor-file-card
      *ngFor="let fileValue of fileValues; trackBy: trackId"
      [fileValue]="fileValue"
      [valueFormControl]="valueFormControl"
      [fileDoc]="fileValue.file_id | findFileDocument : fileDocumentsList"
      [documentContext]="documentContext"
      [status]="valueFormControl.status"
      (deleteFileValue)="deleteFileValue($event)"
    ></lib-metric-editor-file-card>
  </div>
</ng-container>

<ng-template #loading>
  <div class="dummies-container w-100 h-100 fx-col" @fadeOut>
    <div class="h-100 pt-2" data-testid="loadingDummies">
      <lib-dummy *ngFor="let i of [1, 2]" [presentation]="ePresentation.cardLink"></lib-dummy>
    </div>
  </div>
</ng-template>
