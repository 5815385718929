<button mat-menu-item class="menu-title" disabled>{{ "Standard codes" | translate }}</button>
<div class="menu-search" (click)="$event.stopPropagation()" *ngIf="control && items.length > minMenuScrollItems">
  <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
  <input
    matInput
    type="text"
    [placeholder]="'Search' | translate"
    autocomplete="off"
    (keydown)="$event.stopPropagation()"
    [formControl]="control"
  />
  <button class="clear-search" *ngIf="control.value" (click)="control.setValue('')">
    <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
  </button>
</div>
<hr />
<button mat-menu-item (click)="setItem.emit()">{{ "All" | translate }}</button>
<ng-container *ngIf="filteredItems | async as standardCodes">
  <div [ngClass]="{ 'menu-items-container': items.length > minMenuScrollItems }">
    <div *ngFor="let standardCodeCategory of standardCodeFilterCategories">
      <hr class="hr-light" />
      <button mat-menu-item class="menu-title" disabled>
        {{ eStandardCodeCategoryTranslations[standardCodeCategory] | translate }}
      </button>
      <button
        mat-menu-item
        *ngFor="let item of standardCodes | filter : filterStandardCodes : standardCodeCategory"
        [title]="item.title"
        (click)="setItem.emit(item)"
        [ngClass]="{ active: selectedItemId === item.id }"
      >
        <span>{{ item.title | uppercase }}</span>
      </button>
    </div>
  </div>
</ng-container>
