import { DataRequestIndicator, DataRequestValueGroupSet, IndicatorRelatedMetrics, MetricCategory } from '../models';
import { DataRequestValueGroupSetBuilder } from './data-request-value-group-set-builder';
import { v4 as uuidv4 } from 'uuid';
import { faker } from '@faker-js/faker';

export class DataRequestIndicatorBuilder {
  private readonly _dataRequestIndicator: DataRequestIndicator;

  constructor() {
    this._dataRequestIndicator = {
      active: true,
      category: MetricCategory.CUSTOM,
      description: faker.lorem.sentences(2),
      id: uuidv4(),
      indicator_id: uuidv4(),
      name: faker.name.jobDescriptor(),
      start_year: faker.date.past().getFullYear(),
      value_group_sets: [new DataRequestValueGroupSetBuilder().build()],
    };
  }

  id(id: string): DataRequestIndicatorBuilder {
    this._dataRequestIndicator.id = id;
    return this;
  }

  name(name: string): DataRequestIndicatorBuilder {
    this._dataRequestIndicator.name = name;
    return this;
  }

  description(description: string): DataRequestIndicatorBuilder {
    this._dataRequestIndicator.description = description;
    return this;
  }

  value_group_sets(dataRequestValueGroupSets: DataRequestValueGroupSet[]): DataRequestIndicatorBuilder {
    this._dataRequestIndicator.value_group_sets = dataRequestValueGroupSets;
    return this;
  }

  metricId(metricId: string): DataRequestIndicatorBuilder {
    this._dataRequestIndicator.metric_id = metricId;
    return this;
  }

  indicatorId(indicatorId: string): DataRequestIndicatorBuilder {
    this._dataRequestIndicator.indicator_id = indicatorId;
    return this;
  }

  relatedMetrics(relatedMetrics: IndicatorRelatedMetrics): DataRequestIndicatorBuilder {
    this._dataRequestIndicator.related_metrics = relatedMetrics;
    return this;
  }

  code(code: string): DataRequestIndicatorBuilder {
    this._dataRequestIndicator.code = code;
    return this;
  }

  category(category: MetricCategory): DataRequestIndicatorBuilder {
    this._dataRequestIndicator.category = category;
    return this;
  }

  build(): DataRequestIndicator {
    return this._dataRequestIndicator;
  }
}
