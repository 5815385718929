import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { MAT_NATIVE_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { DateFormats } from '../../../../models/date';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: string): string {
    if (displayFormat === DateFormats.yyyymmdd) {
      return formatDate(date, 'yyyy-MM-dd', this.locale);
    } else {
      return date.toDateString();
    }
  }

  parse(value: string): Date | null {
    switch (MAT_NATIVE_DATE_FORMATS.parse.dateInput) {
      case DateFormats.yyyymmdd:
      default:
        if (value === '') {
          return null;
        } else if (value.includes('-')) {
          const str = value.split('-');
          const date = new Date(Number(str[0]), Number(str[1]) - 1, Number(str[2]));
          if (!this.isValid(date)) return this.invalid();
          if (date.getDate() === Number(str[2])) return date;
        }
        return this.invalid();
    }
  }
}
