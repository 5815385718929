import { Message, MESSAGE_TYPES, MessagePayload } from '../websocket';

export class MessageBuilder<T extends MessagePayload> {
  private readonly _message: Message<T>;

  constructor() {
    this._message = {
      id: '',
      source: 'platform',
      time: '',
      type: MESSAGE_TYPES.REPORT_CREATED,
      version: '1',
      data: {} as unknown as T,
    };
  }

  type(type: MESSAGE_TYPES): MessageBuilder<T> {
    this._message.type = type;
    return this;
  }

  data(data: T): MessageBuilder<T> {
    this._message.data = data;
    return this;
  }

  build(): Message<T> {
    return this._message;
  }
}
