import { faker } from '@faker-js/faker';
import { CDPErrorType, JobQueueOutputCDPReportSubmissionFieldError } from '../models';

export class CdpJobQueueOutputFieldErrorBuilder {
  private readonly _cdpJobQueueOutputFieldError: JobQueueOutputCDPReportSubmissionFieldError;

  constructor() {
    this._cdpJobQueueOutputFieldError = {
      error_type: CDPErrorType.METRIC,
      error_message: faker.random.word(),
      question_id: 1234,
      question: faker.random.word(),
      metric_code: faker.random.word(),
      metric_name: faker.random.word(),
      value: faker.random.word(),
    };
  }

  error_message(error_message: string): CdpJobQueueOutputFieldErrorBuilder {
    this._cdpJobQueueOutputFieldError.error_message = error_message;
    return this;
  }

  question_id(question_id: number): CdpJobQueueOutputFieldErrorBuilder {
    this._cdpJobQueueOutputFieldError.question_id = question_id;
    return this;
  }

  question(question: string): CdpJobQueueOutputFieldErrorBuilder {
    this._cdpJobQueueOutputFieldError.question = question;
    return this;
  }

  metric_code(metric_code: string): CdpJobQueueOutputFieldErrorBuilder {
    this._cdpJobQueueOutputFieldError.metric_code = metric_code;
    return this;
  }

  metric_name(metric_name: string): CdpJobQueueOutputFieldErrorBuilder {
    this._cdpJobQueueOutputFieldError.metric_name = metric_name;
    return this;
  }

  value(value: string): CdpJobQueueOutputFieldErrorBuilder {
    this._cdpJobQueueOutputFieldError.value = value;
    return this;
  }

  build(): JobQueueOutputCDPReportSubmissionFieldError {
    return this._cdpJobQueueOutputFieldError;
  }
}
