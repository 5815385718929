import { FieldAuditEventVariable, ValueDefinitionType } from '../models';
import { CreatedUpdatedBuilder } from './created-updated-builder';
export class FieldAuditEventVariableBuilder extends CreatedUpdatedBuilder<FieldAuditEventVariable> {
  constructor() {
    super({
      id: 'fieldAuditVariableEventId',
      field_audit_trail_id: 'fieldAuditEventId',
      indicator_id: 'indId',
      mandatory: false,
      metric_code: 'CUS 001',
      metric_description: 'Custom Metric',
      tag: 'a',
      unit: 'general',
      value: '2',
      value_definition_id: 'vdId',
      value_definition_label: 'A',
      value_definition_type: ValueDefinitionType.decimal,
      value_updated: '2020-10-05T17:41:00.000000+00:00',
      user_info: {
        first_name: 'Test',
        last_name: 'User 1',
      },
    });
  }

  id(id: string): FieldAuditEventVariableBuilder {
    this._instance.id = id;
    return this;
  }
  fieldAuditEventId(field_audit_trail_id: string): FieldAuditEventVariableBuilder {
    this._instance.field_audit_trail_id = field_audit_trail_id;
    return this;
  }
  indicatorId(indicator_id: string): FieldAuditEventVariableBuilder {
    this._instance.indicator_id = indicator_id;
    return this;
  }
  metricCode(metric_code: string): FieldAuditEventVariableBuilder {
    this._instance.metric_code = metric_code;
    return this;
  }
  metricDescription(metric_description: string): FieldAuditEventVariableBuilder {
    this._instance.metric_description = metric_description;
    return this;
  }
  tag(tag: string): FieldAuditEventVariableBuilder {
    this._instance.tag = tag;
    return this;
  }
  unit(unit: string): FieldAuditEventVariableBuilder {
    this._instance.unit = unit;
    return this;
  }
  value(value: string): FieldAuditEventVariableBuilder {
    this._instance.value = value;
    return this;
  }
  valueDefinitionId(value_definition_id: string): FieldAuditEventVariableBuilder {
    this._instance.value_definition_id = value_definition_id;
    return this;
  }
  valueDefinitionLabel(value_definition_label: string): FieldAuditEventVariableBuilder {
    this._instance.value_definition_label = value_definition_label;
    return this;
  }
  build(): FieldAuditEventVariable {
    return this._instance;
  }
}
