import { Component, Input, OnChanges, ViewContainerRef } from '@angular/core';
import {
  DisplayedValue,
  Presentation,
  TemplateReport,
  TemplateReportSectionMetric,
  ValueDefinitionDisplayType,
} from '../../../../models';
import { TemplateReportStructureStateService } from '../../../services/template-report-structure-state.service';
import { Required } from '../../../../decorators';
import { Observable } from 'rxjs';
import { DialogsService } from '../../../../dialogs';
import {
  TemplateReportStructureDisplayedValuesDialogComponent,
  TemplateReportStructureDisplayedValuesDialogData,
} from '../template-report-structure-displayed-values-form/template-report-structure-displayed-values-dialog.component';

@Component({
  selector: 'lib-template-report-structure-displayed-values-display',
  templateUrl: './template-report-structure-displayed-values-display.component.html',
})
export class TemplateReportStructureDisplayedValuesDisplayComponent implements OnChanges {
  @Input() @Required() template!: TemplateReport;
  @Input() @Required() templateReportVersionId!: string;
  @Input() @Required() templateReportSectionMetric!: TemplateReportSectionMetric;
  readonly ePresentation = Presentation;
  readonly eValueDefinitionDisplayType = ValueDefinitionDisplayType;
  public loading: boolean = false;
  public displayedValues$: Observable<DisplayedValue[] | undefined>;
  public showAllDisplayedValues$: Observable<boolean | undefined>;
  public displayedValuesLoading$: Observable<boolean | undefined>;
  constructor(
    private dialogsService: DialogsService,
    private readonly templateReportStructureStateService: TemplateReportStructureStateService,
    private viewContainerRef: ViewContainerRef
  ) {
    this.displayedValues$ = this.templateReportStructureStateService.displayedValues$;
    this.showAllDisplayedValues$ = this.templateReportStructureStateService.showAllDisplayedValues$;
    this.displayedValuesLoading$ = this.templateReportStructureStateService.displayedValuesLoading$;
  }

  ngOnChanges() {
    this.templateReportStructureStateService.loadDisplayedValues(
      this.template.id,
      this.templateReportVersionId,
      this.templateReportSectionMetric.section.id,
      this.templateReportSectionMetric.metric.id
    );
  }

  launchEditValuesDialog(): void {
    this.dialogsService.open<
      TemplateReportStructureDisplayedValuesDialogComponent,
      TemplateReportStructureDisplayedValuesDialogData
    >(TemplateReportStructureDisplayedValuesDialogComponent, {
      viewContainerRef: this.viewContainerRef,
      data: {
        templateId: this.template.id,
        versionId: this.templateReportVersionId,
        sectionId: this.templateReportSectionMetric.section.id,
        metricId: this.templateReportSectionMetric.metric.id,
        metricCode: this.templateReportSectionMetric.metric.code || '',
      },
    });
  }
}
