import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '../translate/translate.service';
import { ValidatorsUtils } from '../../../classes/FormUtils/validators-utils';

@Injectable({
  providedIn: 'root',
})
export class ValidationMessageService {
  constructor(private translateService: TranslateService) {}

  get validationMessages(): ValidationErrors {
    return {
      required: this.translateService.instant('Required'),
      minlength: this.translateService.instant('Too Short'),
      maxlength: this.translateService.instant('Too Long'),
      min: this.translateService.instant('Value too low'),
      max: this.translateService.instant('Value too high'),
      pattern: this.translateService.instant('Forbidden Entry'),
      email: this.translateService.instant('Invalid Entry'),
      date: this.translateService.instant('Invalid Date'),
      isUnique: this.translateService.instant('Must be unique. This is already used'),
      matDatepickerParse: this.translateService.instant('Invalid date'),
      systemMax: this.translateService.instant(`The system doesn't allow number greater than {systemMax}`, {
        systemMax: ValidatorsUtils.SYSTEM_MAX_NUMBER,
      }),
    };
  }
}
