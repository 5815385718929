import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ApiResponse,
  ApplicationApiDefinition,
  BaseValue,
  DataFormatTemplate,
  Indicator,
  MinimalEntity,
  SearchOptions,
  Unit,
  Value,
  ValueDefinitionDisplayType,
  ValueDefinitionSize,
  ValueDefinitionType,
  ValueGroup,
  ValueGroupSet,
  ValueGroupSetsTemplate,
  ValueGroupTemplate,
  ValueTemplate,
} from '../../../models';

import { ApiService } from '../../common/api/api.service';
import { BaseService } from '../../common/base/base.service';

@Injectable({
  providedIn: 'root',
})
export class AdminMetricService {
  apiName: keyof ApplicationApiDefinition = 'admin';
  resource: string;
  servicePath: string;

  constructor(private baseService: BaseService, private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.admin.resources.metrics;
  }

  metricPayloadFromSearchOptions(searchOptions?: SearchOptions): any {
    const payload: any = {};

    if (searchOptions) {
      payload.load_value_group_sets = false;
      payload.from = searchOptions.from || 0;
      payload.size = searchOptions.size || this.apiService.apiConfig.pageSize;
      payload.filters = {};

      if (searchOptions.sort?.id !== 'score') {
        payload.sort = [
          {
            field: 'code',
          },
        ];
      }

      if (searchOptions.query.keywords) {
        payload.keywords = searchOptions.query.keywords;
      }

      if (searchOptions.filters.topic) {
        if (searchOptions.filters.topic.action === 'category') {
          payload.filters.topic_categories = [searchOptions.filters.topic.id];
        } else if (searchOptions.filters.topic.action === 'group') {
          payload.filters.topic_groups = [searchOptions.filters.topic.id];
        } else {
          payload.filters.topics = [searchOptions.filters.topic.id];
        }
      }

      if (searchOptions.filters.category) {
        payload.filters.categories = [searchOptions.filters.category.id];
      }

      if (searchOptions.filters.framework) {
        payload.filters.framework_ids = [searchOptions.filters.framework.id];
      }

      if (searchOptions.filters.status) {
        payload.filters.active = searchOptions.filters.status.id;
      }

      if (searchOptions.filters.industry) {
        payload.filters.industries = [searchOptions.filters.industry.id];
      }

      if (searchOptions.filters.status) {
        payload.filters.active = searchOptions.filters.status.id;
      }

      if (searchOptions.filters.reference_v2) {
        payload.filters.reference_v2 = searchOptions.filters.reference_v2;
      }

      if (searchOptions.custom_filters) {
        for (const filterKey of Object.keys(searchOptions.custom_filters)) {
          payload.filters[filterKey] = searchOptions.custom_filters[filterKey];
        }
      }

      if (searchOptions.excludes) {
        payload.excludes = searchOptions.excludes;
      }

      if (searchOptions.sort) {
        switch (searchOptions.sort.id) {
          case 'updated':
            payload.sort = [
              {
                field: 'updated',
                direction: 'desc',
              },
            ];
            break;
          default:
            break;
        }
      }
    }

    return payload;
  }

  public search(searchOptions?: SearchOptions): Observable<ApiResponse<Indicator[]>> {
    const payload: any = this.metricPayloadFromSearchOptions(searchOptions);
    return this.apiService.post(`${this.servicePath}${this.resource}/search`, payload);
  }

  public listMinimal(): Observable<ApiResponse<MinimalEntity[]>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/minimal`);
  }

  public getOne(metricId: string, payload?: any): Observable<ApiResponse<Indicator>> {
    const body: any = {
      metric_ids: [metricId],
      ...payload,
    };

    return this.apiService.post(`${this.servicePath}${this.resource}/list`, body).pipe(
      map((r) => {
        r.data = r.data[0];
        return r;
      })
    );
  }

  public getByMetricIds(metricIds: Array<string>, payload?: any): Observable<ApiResponse<Indicator[]>> {
    const body: any = {
      metric_ids: metricIds,
      ...payload,
    };

    return this.apiService.post(`${this.servicePath}${this.resource}/list`, body);
  }

  public updateMetric(metricId: string, payload: any): Observable<ApiResponse<Indicator>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/metrics/${metricId}`, payload);
  }

  public activateMetric(metricId: string): Observable<ApiResponse<Indicator>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/metrics/${metricId}/activate`, {});
  }

  public deactivateMetric(metricId: string): Observable<ApiResponse<Indicator>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/metrics/${metricId}/deactivate`, {});
  }

  getDataFields<T extends BaseValue>(
    valueGroups: ValueGroup[],
    valueGroupSet: ValueGroupSet
  ): ValueGroupSetsTemplate<T> {
    let dataFormatsId = 1;
    const tempValueGroups: ValueGroupTemplate<T>[] = valueGroups
      .map((valueGroup) => {
        const tempValue: ValueTemplate<T>[] = [];
        valueGroup.values?.forEach((value) => {
          const tempValueData = { ...value } as T;
          const tempDataFormat = this.getDataFormatTemplateFromValue(tempValueData, dataFormatsId);
          dataFormatsId++;
          if (!(valueGroup.repeatable && value.type === 'calculated')) {
            tempValue.push({
              id: value.id ? value.id : undefined,
              value_definition_id: value.value_definition_id,
              position: value.position,
              dataFormats: tempDataFormat,
              size: value.size,
              newline: value.newline,
              required: value.required,
              value: tempValueData.value ? tempValueData.value : '',
              isEdited: false,
              ...(tempValueData.type === 'file' && { file: [] }),
            });
          }
        });
        tempValue.sort((a, b) => (a.position && b.position ? a.position - b.position : 0));
        return {
          id: valueGroup.id,
          value_definition_group_id: valueGroup.value_definition_group_id,
          repeatable: valueGroup.repeatable,
          position: valueGroup.position,
          subposition: valueGroup.subposition,
          value: tempValue,
          showOptions: valueGroup.repeatable!,
          group_max_repetition: valueGroup?.group_max_repetition,
          indent: valueGroup.indent,
        };
      })
      .sort((a, b) => (a.position && b.position ? a.position - b.position : 0));
    return {
      id: valueGroupSet.id,
      group: tempValueGroups,
      explanation: valueGroupSet.explanation!,
      lastValueId: dataFormatsId,
    };
  }

  public getValueUnit(value: Value, units: Unit[]): string {
    if (value.type_details.units && units) {
      let unit;
      if (value.unit) {
        unit = units.find((u) => u.code === value.unit);
      } else {
        unit = units.find((u) => u.code === value.type_details.units);
      }
      return unit ? (unit.symbol ? unit.symbol : '') : '';
    }
    return '';
  }

  private getDataFormatTemplateFromValue<T extends BaseValue>(value: T, index: number): DataFormatTemplate<T> {
    const tempValueData = { ...value };
    switch (value.type) {
      case ValueDefinitionType.text: {
        if (value.type_details.rich_text) {
          value.size = ValueDefinitionSize.large;
          return {
            id: ('text' + index).toString(),
            dataFormatType: ValueDefinitionDisplayType.text_rich,
            data: tempValueData,
          };
        } else if (value.type_details.textarea) {
          const data = value.validators?.find(
            (v) => v.validator_type === 'max_length' || v.validator_type === 'min_length'
          );
          if (data) {
            return {
              id: index.toString(),
              dataFormatType: ValueDefinitionDisplayType.text_area_validation,
              data: tempValueData,
            };
          } else {
            return {
              id: index.toString(),
              dataFormatType: ValueDefinitionDisplayType.text_area,
              data: tempValueData,
            };
          }
        } else {
          return {
            id: index.toString(),
            dataFormatType: ValueDefinitionDisplayType.text_simple,
            data: tempValueData,
          };
        }
      }
      case ValueDefinitionType.tip: {
        value.size = ValueDefinitionSize.large;
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.tip,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.subtitle:
      case ValueDefinitionType.label: {
        value.size = ValueDefinitionSize.large;
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.subtitle,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.integer: {
        tempValueData.unit = this.getValueUnit(tempValueData, []);
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.integer,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.decimal: {
        tempValueData.unit = this.getValueUnit(tempValueData, []);
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.decimal,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.datetime:
      case ValueDefinitionType.date: {
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.date,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.choice: {
        let format = ValueDefinitionDisplayType.choice;
        if (tempValueData.type_details.multi_choices) {
          format = ValueDefinitionDisplayType.choice_multiple;
        }
        if (tempValueData.type_details.searchable) {
          value.size = ValueDefinitionSize.large;
          format = ValueDefinitionDisplayType.choice_searchable;
        }
        return { id: index.toString(), dataFormatType: format, data: tempValueData };
      }
      case ValueDefinitionType.boolean: {
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.boolean,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.file: {
        value.size = ValueDefinitionSize.large;
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.file,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.file_v2: {
        value.size = ValueDefinitionSize.large;
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.file_v2,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.calculated: {
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.calculated,
          data: tempValueData,
        };
      }
      case ValueDefinitionType.document:
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.document,
          data: tempValueData,
        };
      case ValueDefinitionType.metric:
        return {
          id: index.toString(),
          dataFormatType: ValueDefinitionDisplayType.metric,
          data: tempValueData,
        };
    }
  }
}
