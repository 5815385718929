import { Metric, ValueDefinitionMapping, ValueDefinition, ValueDefinitionGroup } from '../models';
import { MetricBuilder } from './metric-builder';
import { ValueDefinitionBuilder } from './value-definition-builder';
import { ValueDefinitionGroupBuilder } from './value-definition-group-builder';
import { v4 as uuidv4 } from 'uuid';
import { Builder } from './builder';

export class ValueDefinitionMappingBuilder extends Builder<ValueDefinitionMapping> {
  constructor() {
    super({
      id: uuidv4(),
      template_report_mapping_id: uuidv4(),
      to_template_report_version_id: uuidv4(),
      to_metric: new MetricBuilder().build(),
      to_value_definition: new ValueDefinitionBuilder().build(),
      to_value_definition_group: new ValueDefinitionGroupBuilder().build(),
      from_template_report_version_id: uuidv4(),
      from_metric: new MetricBuilder().build(),
      from_value_definition: new ValueDefinitionBuilder().build(),
      from_value_definition_group: new ValueDefinitionGroupBuilder().build(),
      active: true,
      note: 'Note that explain the mapping',
    });
  }

  public id(id: string): ValueDefinitionMappingBuilder {
    this._instance.id = id;
    return this;
  }

  public templateReportMappingId(templateReportMappingId?: string): ValueDefinitionMappingBuilder {
    this._instance.template_report_mapping_id = templateReportMappingId;
    return this;
  }

  public toTemplateReportVersionId(templateReportVersionId: string): ValueDefinitionMappingBuilder {
    this._instance.to_template_report_version_id = templateReportVersionId;
    return this;
  }

  public toMetric(metric: Metric): ValueDefinitionMappingBuilder {
    this._instance.to_metric = metric;
    return this;
  }

  public toValueDefinition(valueDefinition: ValueDefinition): ValueDefinitionMappingBuilder {
    this._instance.to_value_definition = valueDefinition;
    return this;
  }

  public toValueDefinitionGroup(valueDefinitionGroup: ValueDefinitionGroup): ValueDefinitionMappingBuilder {
    this._instance.to_value_definition_group = valueDefinitionGroup;
    return this;
  }

  public fromTemplateReportVersionId(templateReportVersionId?: string): ValueDefinitionMappingBuilder {
    this._instance.from_template_report_version_id = templateReportVersionId;
    return this;
  }

  public fromMetric(metric: Metric): ValueDefinitionMappingBuilder {
    this._instance.from_metric = metric;
    return this;
  }

  public fromValueDefinition(valueDefinition: ValueDefinition): ValueDefinitionMappingBuilder {
    this._instance.from_value_definition = valueDefinition;
    return this;
  }

  public fromValueDefinitionGroup(valueDefinitionGroup: ValueDefinitionGroup): ValueDefinitionMappingBuilder {
    this._instance.from_value_definition_group = valueDefinitionGroup;
    return this;
  }

  public active(active: boolean): ValueDefinitionMappingBuilder {
    this._instance.active = active;
    return this;
  }

  public note(note: string): ValueDefinitionMappingBuilder {
    this._instance.note = note;
    return this;
  }
}
