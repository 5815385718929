<lib-panel-details cdkDropListGroup [title]="'Types'">
  <lib-metric-structure-fields-selection
    class="h-100"
    slot="collection"
    [disableFields]="isMetricFieldSelectionDisabled"
    [valueDefinitionTemplates]="valueDefinitionTemplates"
    [isMetricTableEnabled]="metricTableEnabled"
    [disableSpecialTypes]="disableSpecialTypes"
  ></lib-metric-structure-fields-selection>
  <div slot="item" class="panel-metric" [ngClass]="{ 'item-selected': selectedItem }">
    <ng-container *ngIf="metricData">
      <lib-metric-structure-form-panel [metric]="metricData!" class="panel-content"></lib-metric-structure-form-panel>
      <lib-metric-structure-properties-panel
        [metric]="metricData!"
        [metricStructureSelectable]="selectedItem"
        class="panel-properties h-100"
        (closePanel)="onPanelClose()"
      ></lib-metric-structure-properties-panel>
    </ng-container>
  </div>
</lib-panel-details>
