import { TemplateCompletionByStatus } from '../models';
import { Builder } from './builder';
import { faker } from '@faker-js/faker';

export class TemplateCompletionByStatusBuilder extends Builder<TemplateCompletionByStatus> {
  constructor() {
    super({
      not_started: faker.datatype.number(),
      in_progress: faker.datatype.number(),
      complete: faker.datatype.number(),
    });
  }

  notStarted(notStarted: number): TemplateCompletionByStatusBuilder {
    this._instance.not_started = notStarted;
    return this;
  }

  inProgress(inProgress: number): TemplateCompletionByStatusBuilder {
    this._instance.in_progress = inProgress;
    return this;
  }

  complete(complete: number): TemplateCompletionByStatusBuilder {
    this._instance.complete = complete;
    return this;
  }
}
