import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { EditService } from '@syncfusion/ej2-angular-grids';
import { Required } from '../../../decorators';
import {
  DataRequestSourceStatus,
  DataRequestUserResponsibility,
  DataRequestValueGroupSetStatus,
  FocusedFieldAction,
  MetricTableDefinition,
  PlatformValueGroupSetStatus,
  Value,
  ValueDefinitionType,
} from '../../../models';
import { TableFormGroup } from '../../models/tableFormGroup';
import { ValueGroupFormGroup } from '../../models/valueGroupFormGroup';
import { UpsertValueGroup } from '../../models/upsertValue';
import { ValueFormControl } from '../../models/valueFormControl';
import { forkJoin, iif, map, merge, Observable, of, Subject, take, takeUntil } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { BaseMetricEditorFormStateService } from '../../services/base-metric-editor-form-state/base-metric-editor-form-state.service';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { ResetValueEventWithoutVgsetId } from '../../models/resetValueEvent';
import { FormUtils } from '../../../classes';
import { isNullishValue } from '../../utils/valueUtils';
import { FormatFieldUniqueIdPipe } from '../../../pipes';
import { partition } from 'lodash';
import { isTableContextValue } from './utils/is-table-context-value';
import { ConsolidationService } from '../../../services/common/consolidation/consolidation.service';
import { DEFAULT_DOCUMENT_CONTEXT, DocumentContext } from '../../models/documentContext';
@Component({
  selector: 'lib-metric-editor-table-handler',
  templateUrl: './metric-editor-table-handler.component.html',
  styleUrls: ['./metric-editor-table-handler.component.scss'],
  providers: [EditService, FormatFieldUniqueIdPipe],
})
export class MetricEditorTableHandlerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() @Required() tableFormGroup!: TableFormGroup;
  @Input() @Required() isConsolidatedBU!: boolean;
  @Input() documentContext: DocumentContext = DEFAULT_DOCUMENT_CONTEXT;
  @Input() indicatorId: string = '';
  @Input() vgsetId: string = '';
  @Input() displayFieldActions: boolean = false;
  @Input() userResponsibility?: DataRequestUserResponsibility;
  @Input() valueGroupSetStatus!: PlatformValueGroupSetStatus | DataRequestValueGroupSetStatus;
  @Input() dataRequestSourceStatus!: DataRequestSourceStatus;
  @Output() update: EventEmitter<UpsertValueGroup> = new EventEmitter<UpsertValueGroup>();
  @Output() resetValue: EventEmitter<ResetValueEventWithoutVgsetId> = new EventEmitter<ResetValueEventWithoutVgsetId>();

  readonly eValueDefinitionType = ValueDefinitionType;
  readonly eFocusedFieldAction = FocusedFieldAction;
  readonly eDataRequestUserResponsibility = DataRequestUserResponsibility;

  headerValues: Value[] = [];
  contextHeaderValues: Value[] = [];
  tableTitle: string = '';
  activeValueGroupFormGroup?: ValueGroupFormGroup;
  showTableTotalComputation: boolean = true;
  metricTableDefinition?: MetricTableDefinition;
  isFocusEnabled: boolean = false;
  consolidatedInfoMap: Map<string, string> = new Map();
  tableTotalFormGroup?: ValueGroupFormGroup;
  staticConsolidationEnabled: boolean = false;

  focusedField$: Observable<Value | undefined>;
  focusFieldUniqueId$: Observable<string>;
  private destroy$ = new Subject<void>();

  constructor(
    private baseMetricEditorFormStateService: BaseMetricEditorFormStateService,
    private formatFieldUniqueId: FormatFieldUniqueIdPipe,
    private consolidationService: ConsolidationService
  ) {
    this.showTableTotalComputation = this.baseMetricEditorFormStateService.getShowTableTotalComputation();
    this.focusedField$ = this.baseMetricEditorFormStateService.focusedField$;
    this.focusFieldUniqueId$ = this.baseMetricEditorFormStateService.focusFieldUniqueId$;
    this.staticConsolidationEnabled = this.consolidationService.staticConsolidationFlag;
  }

  ngOnInit(): void {
    this.setupValueChangesSubscription();
    this.setTableDefinitions();
    this.isFocusEnabled = this.baseMetricEditorFormStateService.enableFocus;
    this.setFocusHandler();
    this.consolidatedInfoMap = this.formatConsolidatedInfo();
    this.tableTotalFormGroup = this.tableFormGroup.getGroupFormGroupTableTotals();
  }

  private formatConsolidatedInfo(): Map<string, string> {
    const consolidatedInfoMap: Map<string, string> = new Map();

    if (!this.isConsolidatedBU) {
      return consolidatedInfoMap;
    }

    for (const headerValue of this.headerValues) {
      consolidatedInfoMap.set(
        headerValue.value_definition_id,
        this.consolidationService.getConsolidatedInfoBasedOnValueType(headerValue)
      );
    }
    return consolidatedInfoMap;
  }

  private setTableDefinitions(): void {
    this.baseMetricEditorFormStateService
      .getMetricTableDefinition(this.tableFormGroup.getMetricId(), this.tableFormGroup.id)
      .pipe(take(1))
      .subscribe((metricTableDefinition) => {
        this.metricTableDefinition = metricTableDefinition;
      });
  }

  private setFocusHandler() {
    forkJoin(
      this.tableFormGroup
        .getGroupFormGroups()
        .flatMap((valueGroupFormGroup) =>
          valueGroupFormGroup.valueFormControls().map((control) => ({ group: valueGroupFormGroup, control }))
        )
        .map((formDetails: { group: ValueGroupFormGroup; control: ValueFormControl }) =>
          formDetails.control.focusValue$
            .pipe(
              filter((focusValue) => !!focusValue),
              takeUntil(this.destroy$)
            )
            .pipe(tap((focusValue) => this.selectFieldForFocus(formDetails.group, focusValue as Value)))
        )
    ).subscribe();
  }

  ngOnChanges(): void {
    this.handleTableTitle();
    this.handleHeaderValues();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public selectFieldForFocus(groupFormGroup: ValueGroupFormGroup, value: Value): void {
    const group = groupFormGroup.valueGroupRef;
    if (value.type !== ValueDefinitionType.label) {
      this.baseMetricEditorFormStateService.setFocusFieldAndUniqueId(
        this.formatFieldUniqueId.transform(group, value),
        value
      );
    }
  }

  public handleFocusedFieldAction(focusedFieldAction: FocusedFieldAction): void {
    this.baseMetricEditorFormStateService.setFocusedFieldAction(focusedFieldAction);
  }

  private setupValueChangesSubscription(): void {
    this.mergeUpdates(this.tableFormGroup)
      .pipe(
        mergeMap(([valueFormControl, valueGroupFormGroup]) =>
          iif(
            () => !isNullishValue(valueFormControl.value),
            this.handleUpdateValue(valueFormControl, valueGroupFormGroup),
            this.handleImplicitResetValue(valueFormControl, valueGroupFormGroup)
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private handleUpdateValue(
    valueFormControl: ValueFormControl,
    valueGroupFormGroup: ValueGroupFormGroup
  ): Observable<void> {
    return of(undefined).pipe(
      filter(() => valueFormControl.valid),
      tap(() => this.update.emit(valueGroupFormGroup.toUpsertValueGroup([valueFormControl.toUpsertValue()])))
    );
  }

  private handleImplicitResetValue(
    valueFormControl: ValueFormControl,
    valueGroupFormGroup: ValueGroupFormGroup
  ): Observable<void> {
    return of(undefined).pipe(
      filter(() => !FormUtils.isNullOrEmpty(valueFormControl.valueRef.id)),
      tap(() => this.resetValue.emit({ valueGroupId: valueGroupFormGroup.id, valueId: valueFormControl.valueRef.id }))
    );
  }

  private handleTableTitle(): void {
    this.tableTitle = this.tableFormGroup.tableGroupRef.valueGroups[0].label ?? '';
  }

  private handleHeaderValues(): void {
    [this.contextHeaderValues, this.headerValues] = partition(
      this.tableFormGroup.tableGroupRef.valueGroups[0].values,
      (v) => isTableContextValue(v)
    );
  }

  private mergeUpdates(form: UntypedFormGroup): Observable<[ValueFormControl, ValueGroupFormGroup]> {
    return merge(
      ...Object.keys(form.controls).map((controlName: string) => {
        const control = form.get(controlName) as ValueFormControl | ValueGroupFormGroup;
        return control instanceof UntypedFormGroup
          ? this.mergeUpdates(control)
          : control.valueChanges.pipe(map(() => [control, form] as [ValueFormControl, ValueGroupFormGroup]));
      })
    );
  }

  public filterLabelTypeValue(item: ValueFormControl): boolean {
    return isTableContextValue(item.valueRef);
  }

  public filterValuesExceptLabelType(item: ValueFormControl): boolean {
    return !isTableContextValue(item.valueRef);
  }

  protected readonly FocusedFieldAction = FocusedFieldAction;
  protected readonly DataRequestUserResponsibility = DataRequestUserResponsibility;
}
