import { ValidatorFn, AsyncValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { Observable, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from '../../services/common';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = regex.test(control.value as string);
      return !control.value ? null : valid ? null : error;
    };
  }

  static passwordValidator(auth: AuthService, token?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const delay = 500;

      return timer(delay).pipe(
        switchMap(() => auth.validatePassword(control.value as string, token)),
        map((result) => {
          const response: any = {};
          if (result) {
            response[result] = true;
            return response;
          } else {
            return null;
          }
        })
      );
    };
  }

  static MatchPasswordValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors | null => {
      const control = abstractControl.get(controlName);
      const matchingControl = abstractControl.get(matchingControlName);

      if (matchingControl?.errors && !matchingControl?.errors.passwordMismatch) {
        return null;
      }
      if (control?.value !== matchingControl?.value) {
        matchingControl?.setErrors({ passwordMismatch: true });
      } else {
        matchingControl?.setErrors(null);
      }
      return null;
    };
  }
}
