import { Injectable } from '@angular/core';

import { Platforms, SupportedEnvironment } from '../../models';

export interface APIConfigAddon {
  minivisto: boolean;
  minivistoPort: string;
  pageSize: number;
  apis: ApplicationApiDefinitionAddon;
  env?: SupportedEnvironment;
  clientId?: string;
  scope?: string;
  components: {
    editor: {
      toolbar: Record<string, unknown>;
      pasteCleanupSettings: Record<string, unknown>;
    };
    [component: string]: Record<string, unknown>;
  };
}

export interface ApplicationApiDefinitionAddon {
  auth: BaseAPI<AuthResource>;
  external: BaseAPI<ExternalResource>;
}

interface BaseAPI<T> {
  api: string;
  localPort: string;
  resources: T;
}

interface ExternalResource {
  documents: string;
  fiscalYears: string;
  frameworks: string;
  indicators: string;
  metricGroups: string;
  source: string;
  standardCodes: string;
  tags: string;
  topics: string;
  units: string;
}

interface AuthResource {
  auth_tokens: string;
  oauth: string;
}

@Injectable({ providedIn: 'root' })
export class AddOnConfig {
  production: boolean = false;
  version: string = '';
  date: string = '';
  pageSize: number = 0;
  platform?: Platforms;
  apiConfig: APIConfigAddon = {
    apis: {
      auth: {
        api: '',
        localPort: '',
        resources: {
          auth_tokens: '',
          oauth: '',
        },
      },
      external: {
        api: '',
        localPort: '8091',
        resources: {
          documents: '/documents/v1',
          fiscalYears: '/fiscal_years/v1',
          frameworks: '/frameworks/v1',
          indicators: '/indicators/v1',
          metricGroups: '/metric_groups/v1',
          source: '/business_units/v1',
          standardCodes: '/standard_codes/v1',
          tags: '/tags/v1',
          topics: '/topics/v1',
          units: '/units/v1',
        },
      },
    },
    components: {
      editor: {
        toolbar: {},
        pasteCleanupSettings: {},
      },
    },
    pageSize: 0,
    minivisto: false,
    minivistoPort: '9080',
  };
}
