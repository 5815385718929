import { ApiCall, ApiResponse, Status } from '../../models';
import { Subject } from 'rxjs';

export class ApiCallSubject<T> extends Subject<ApiCall<T>> {
  setLoading() {
    this.next({ loading: true });
  }

  handleSuccess(response: ApiResponse<T>) {
    this.next({ loading: false, status: Status.SUCCESS, data: response.data });
  }

  handleError() {
    this.next({ loading: false, status: Status.ERROR });
  }
}
