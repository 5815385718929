import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ValueDefinitionDisplayType, ValueDefinitionSize } from '../../models';

export const FieldTypeTranslation = {
  [ValueDefinitionDisplayType.boolean]: _('Boolean'),
  [ValueDefinitionDisplayType.calculated]: _('Calculated'),
  [ValueDefinitionDisplayType.choice]: _('Choice'),
  [ValueDefinitionDisplayType.date]: _('Date'),
  [ValueDefinitionDisplayType.datetime]: _('Datetime'),
  [ValueDefinitionDisplayType.decimal]: _('Decimal'),
  [ValueDefinitionDisplayType.document]: _('Document'),
  [ValueDefinitionDisplayType.file]: _('File'),
  [ValueDefinitionDisplayType.integer]: _('Integer'),
  [ValueDefinitionDisplayType.label]: _('Label'),
  [ValueDefinitionDisplayType.subtitle]: _('Subtitle'),
  [ValueDefinitionDisplayType.text]: _('Text'),
  [ValueDefinitionDisplayType.tip]: _('Tip'),
  [ValueDefinitionDisplayType.text_area]: _('Text area'),
  [ValueDefinitionDisplayType.text_area_validation]: _('Text area validation'),
  [ValueDefinitionDisplayType.text_rich]: _('Rich text'),
  [ValueDefinitionDisplayType.text_simple]: _('Simple text'),
  [ValueDefinitionDisplayType.table]: _('Table'),
  [ValueDefinitionDisplayType.choice_radio]: _('Radio choice'),
  [ValueDefinitionDisplayType.choice_multiple]: _('Multiple choice'),
  [ValueDefinitionDisplayType.choice_searchable]: _('Searchable choice'),
  [ValueDefinitionDisplayType.none]: _('None'),
  [ValueDefinitionDisplayType.metric]: _('Metric'),
};

export const ValueDefinitionSizeTranslation = {
  [ValueDefinitionSize.small]: _('Small label & field'),
  [ValueDefinitionSize.medium]: _('Medium label & field'),
  [ValueDefinitionSize.medium_small]: _('Medium label & small field'),
  [ValueDefinitionSize.large]: _('Large label & field'),
  [ValueDefinitionSize.large_small]: _('Large label & small field'),
  [ValueDefinitionSize.large_medium]: _('Large label & medium field'),
};
