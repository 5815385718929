import { ClientDataRequestSettings, DataRequestToolPanelVisibilityType, DueDateNotification } from '../models';

export class ClientDataRequestSettingsBuilder {
  private readonly _clientDataRequestSettings: ClientDataRequestSettings;

  constructor() {
    this._clientDataRequestSettings = {
      show_historical_data_to: DataRequestToolPanelVisibilityType.NONE,
      show_recommendations_to: DataRequestToolPanelVisibilityType.NONE,
      show_audit_trail_panel_to: DataRequestToolPanelVisibilityType.NONE,
      allow_approvers_to_edit: false,
      allow_reviewers_to_edit: false,
      due_date_notifications: [],
    };
  }

  public showHistoricalDataTo(
    dataRequestToolPanelVisibilityType: DataRequestToolPanelVisibilityType
  ): ClientDataRequestSettingsBuilder {
    this._clientDataRequestSettings.show_historical_data_to = dataRequestToolPanelVisibilityType;
    return this;
  }

  public showRecommendationsTo(
    dataRequestToolPanelVisibilityType: DataRequestToolPanelVisibilityType
  ): ClientDataRequestSettingsBuilder {
    this._clientDataRequestSettings.show_recommendations_to = dataRequestToolPanelVisibilityType;
    return this;
  }

  public showAuditTrailPanelTo(
    dataRequestToolPanelVisibilityType: DataRequestToolPanelVisibilityType
  ): ClientDataRequestSettingsBuilder {
    this._clientDataRequestSettings.show_audit_trail_panel_to = dataRequestToolPanelVisibilityType;
    return this;
  }
  public allowApproversToEdit(allowApproversToEdit: boolean): ClientDataRequestSettingsBuilder {
    this._clientDataRequestSettings.allow_approvers_to_edit = allowApproversToEdit;
    return this;
  }

  public allowReviewersToEdit(allowReviewersToEdit: boolean): ClientDataRequestSettingsBuilder {
    this._clientDataRequestSettings.allow_reviewers_to_edit = allowReviewersToEdit;
    return this;
  }

  public dueDateNotifications(dueDateNotifications: DueDateNotification[]): ClientDataRequestSettingsBuilder {
    this._clientDataRequestSettings.due_date_notifications = dueDateNotifications;
    return this;
  }

  build(): ClientDataRequestSettings {
    return this._clientDataRequestSettings;
  }
}
