export interface ListPeerFilters extends Record<string, string | boolean | string[] | undefined> {
  active?: boolean;
  cdp_sector_id?: string;
  headquarter_location?: string;
  industries?: string[];
  listed?: boolean;
  lucid_axon?: boolean;
  wdi_industry_id?: string;
}

export interface ListPeersRequest {
  filters?: ListPeerFilters;
  keywords?: string | undefined;
  load_industries?: boolean | undefined;
  page?: number;
  page_size?: number;
  order_by?: string | undefined;
}

export interface UpsertPeerPayload {
  name: string;
  brand_name: string | null;
  website: string | null;
  headquarter_location: string | null;
  listed: boolean | null;
  lucid_axon: boolean | null;
  fte: number | null;
  stock_exchange_market: string | null;
  ticker: string | null;
  primary_isin: string | null;
  lei: string | null;
  cdp_account_number: string | null;
  cdp_sector_id: string | null;
  start: string;
  wdi_industry_id: string | null;
  industries: string[] | null;
}
