import { ActionItem, FilterBarSelection } from '../models';
import { ActionItemBuilder } from './action-item-builder';

export class FilterBarSelectionBuilder {
  private readonly _filterBarSelection: FilterBarSelection;

  constructor() {
    this._filterBarSelection = {
      selection: [new ActionItemBuilder().build()],
      id: 'id',
      isPrimary: false,
    };
  }

  id(id: string): FilterBarSelectionBuilder {
    this._filterBarSelection.id = id;
    return this;
  }

  selection(selection: ActionItem[]): FilterBarSelectionBuilder {
    this._filterBarSelection.selection = selection;
    return this;
  }

  isPrimary(isPrimary: boolean | undefined): FilterBarSelectionBuilder {
    this._filterBarSelection.isPrimary = isPrimary;
    return this;
  }

  build(): FilterBarSelection {
    return this._filterBarSelection;
  }
}
