import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';

@Component({
  selector: 'lib-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input() label: string = '';
  @Input() defaultValue: boolean = false;
  @Input() disabled: boolean = false;

  @Output() checkboxToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onCheckboxToggle(event: MatCheckboxChange) {
    this.checkboxToggle.emit(event.checked);
  }
}
