import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, tap } from 'rxjs/operators';
import { TranslateService } from '../../../services/common';

@Component({
  selector: 'lib-filter-bar-search',
  templateUrl: './filter-bar-search.component.html',
  styleUrls: ['./filter-bar-search.component.scss'],
})
export class FilterBarSearchComponent implements OnInit, OnDestroy {
  private readonly DEBOUNCE_TIME: number = 500;

  @Input() searchBarPlaceholder: string = this.translateService.instant('Search');

  @Output() onSearchChanged: EventEmitter<string> = new EventEmitter();

  searchQuery: string = '';

  searchQueryChanged$: Subject<{ value: string; debounceTime: number }> = new Subject();
  searchQueryChangedSubscription: Subscription | undefined;

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.searchQueryChangedSubscription = this.searchQueryChanged$
      .pipe(
        debounce((queryData: { value: string; debounceTime: number }) => timer(queryData.debounceTime)),
        distinctUntilChanged(),
        tap((queryData) => (this.searchQuery = queryData.value))
      )
      .subscribe(() => this.onSearchChanged.emit(this.searchQuery));
  }

  ngOnDestroy(): void {
    this.searchQueryChangedSubscription?.unsubscribe();
  }

  clearSearch(): void {
    this.searchQueryChanged$.next({
      value: '',
      debounceTime: 0,
    });
  }

  onSearchQueryChanged(query: string): void {
    this.searchQueryChanged$.next({
      value: query,
      debounceTime: this.DEBOUNCE_TIME,
    });
  }
}
