<div
  class="form-group-header"
  [ngClass]="{
    'color-error': !valueDefinitionGroup.value_definitions?.length || isElementTriggered(valueDefinitionGroup)
  }"
>
  <mat-icon
    *ngIf="isElementTriggered(valueDefinitionGroup)"
    class="icon-md mr-0"
    svgIcon="condition-trigger"
  ></mat-icon>
  <mat-icon class="icon-md" svgIcon="group"></mat-icon>

  <span class="mr-1 status-chip" *ngIf="valueDefinitionGroup | isDeactivated">{{ "Inactive" | translate }}</span>
  <span class="mat-small" [ngClass]="{ inactive: valueDefinitionGroup | isDeactivated }">{{
    valueDefinitionGroup.label || "Group {iteration}" | translate : { iteration: index + 1 }
  }}</span>
</div>

<div class="form-group-empty-content" *ngIf="!valueDefinitionGroup.value_definitions?.length"></div>

<div class="form-group-content form-group">
  <lib-metric-structure-field-form
    *ngFor="let valueDefinition of valueDefinitionGroup.value_definitions; let i = index"
    cdkDrag
    [cdkDragDisabled]="valueDefinition | isValueDefinitionDragDisabled : metric : (canEditEveryMetrics$ | async)"
    [class]="'formfield ' + getValueDefinitionFormat(valueDefinition).size"
    [ngClass]="{
      newline: valueDefinition.newline,
      required: valueDefinition.required,
      readonly: getValueDefinitionFormat(valueDefinition).readonly
    }"
    [valueDefinition]="valueDefinition"
    [valueDefinitionGroup]="valueDefinitionGroup"
    [vdIndex]="i"
    [isActive]="selectedItemId === valueDefinition.id"
    [attr.data-testid]="'vd-' + valueDefinition.id"
  ></lib-metric-structure-field-form>
</div>
<div
  class="overlay"
  [ngClass]="{
    active: selectedItemId === valueDefinitionGroup.id,
    disabled: overlayDisabled
  }"
  (click)="setSelectedItem(valueDefinitionGroup)"
  [attr.data-testid]="'overlay-' + valueDefinitionGroup.id"
>
  <div class="actions">
    <ng-container *ngIf="!isCoreValueDefinitonGroupOfReferenceV2Metric">
      <button class="btn btn-sm" *ngIf="canMoveDown" (click)="moveGroupDown($event)">
        {{ "Move Down" | translate }}
      </button>
      <button class="btn btn-sm" *ngIf="canMoveUp" (click)="moveGroupUp($event)">{{ "Move Up" | translate }}</button>
    </ng-container>
    <button class="btn btn-sm" *ngIf="!valueDefinitionGroup.value_definitions?.length" (click)="deleteGroup($event)">
      {{ "Delete" | translate }}
    </button>
    <ng-container *libFeatureFlag="{ featureFlags: ['metric_structure_deactivation_enabled'] }">
      <button
        aria-label="group-activation"
        *ngIf="valueDefinitionGroup | isDeactivated; else deactivate"
        class="btn btn-sm"
        (click)="activateValueDefinitionGroup()"
      >
        {{ "Activate" | translate }}
      </button>
      <ng-template #deactivate>
        <button aria-label="group-deactivation" class="btn btn-sm" (click)="deactivateFieldGroup()">
          {{ "Deactivate" | translate }}
        </button>
      </ng-template>
    </ng-container>
  </div>
</div>
