import { Injectable } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import { ApiResponse, SearchOptions, Source } from '../../../models';
import { ClientConfigService } from '../../client/client-config/client-config.service';
import { ClientSourcesService } from '../../client/client-sources/client-sources.service';
import { ClientCompaniesService } from '../../client/client-companies/client-companies.service';
import { SearchOptionsBuilder } from '../../../builders/search-options-bu-builder';

@Injectable({
  providedIn: 'root',
})
export class SourcesService {
  withStaticConsolidation = this.clientConfigService.areAnyFeatureFlagsEnabled(['static_consolidation']);
  constructor(
    private clientConfigService: ClientConfigService,
    private clientSourcesService: ClientSourcesService,
    private clientCompanyService: ClientCompaniesService
  ) {}

  public listSources(): Observable<ApiResponse<Source[]>> {
    if (this.withStaticConsolidation) {
      return this.listSourcesForStaticConsolidation();
    } else {
      return this.listDefaultSources();
    }
  }

  public isConsolidationActiveFromList(company_id: string): Observable<boolean> {
    const searchOptions = this.getCompanySearchOptions(company_id);

    return this.clientSourcesService
      .search(searchOptions)
      .pipe(map((sources: ApiResponse<Source[]>) => this.isSourceConsolidationActive(sources.data)));
  }

  private isSourceConsolidationActive(sources: Source[]): boolean {
    return this.withStaticConsolidation && sources.length > 2;
  }

  private getCompanySearchOptions(company_id: string): SearchOptions {
    const searchOptions = new SearchOptionsBuilder();
    searchOptions.custom_filters({ company_id });
    return searchOptions.build();
  }

  private listDefaultSources(): Observable<ApiResponse<Source[]>> {
    return forkJoin([this.clientSourcesService.listSources(), this.clientCompanyService.getHQCompany()]).pipe(
      map(([sourcesResponse, hqCompanyResponse]) => {
        const buList = sourcesResponse.data;
        const hqCompany = hqCompanyResponse.data;
        const defaultBUIndex = buList.findIndex((bu) => bu.company_id === hqCompany.id && bu.is_default);
        sourcesResponse.data = buList.splice(defaultBUIndex, 1).concat(buList);

        return sourcesResponse;
      })
    );
  }

  private listSourcesForStaticConsolidation(): Observable<ApiResponse<Source[]>> {
    return this.clientSourcesService.listSources().pipe(
      map((sources: ApiResponse<Source[]>) => {
        if (this.isSourceConsolidationActive(sources.data)) {
          const consolidatedSourceIndex = sources.data.findIndex((source) => source.consolidation);
          const consolidatedSource = sources.data.splice(consolidatedSourceIndex, 1)[0];

          const defaultSourceIndex = sources.data.findIndex((source) => source.is_default);
          const defaultSource = sources.data.splice(defaultSourceIndex, 1)[0];

          sources.data = [consolidatedSource, defaultSource].concat(sources.data);
        }

        return sources;
      })
    );
  }
}
