<mat-toolbar class="page-context-bar" [ngClass]="{ 'pt-2': anchorName }">
  <nav *ngIf="anchorName">
    <a (click)="anchorClick.emit()" class="anchor" role="tab">
      <mat-icon svgIcon="arrow-head-left"></mat-icon>
      {{ anchorName }}
    </a>
  </nav>

  <div class="page-context-bar-content" [ngClass]="{ 'pt-0 pb-2': !title }">
    <div *ngIf="title; else settingsContext" class="title-container" [ngClass]="{ separator: withSeparator }">
      <h9 [matTooltip]="title" class="page-name">
        {{ title }}
      </h9>

      <a *ngIf="withSignOut" (click)="authService.logout()" class="anchor">
        <mat-icon class="mat-icon notranslate icon-sm mr-1" [svgIcon]="'arrow-full-right'"></mat-icon>
        {{ "Sign Out" | translate }}
      </a>
    </div>

    <div class="subtitle-container">
      <div *ngIf="subtitle">
        {{ subtitle }}
      </div>

      <div *ngIf="ctaText">
        <a (click)="ctaClick.emit()">
          <mat-icon *ngIf="ctaIcon" class="mat-icon notranslate icon-sm mr-1" [svgIcon]="ctaIcon"></mat-icon>
          {{ ctaText }}
        </a>
      </div>
    </div>
  </div>
</mat-toolbar>

<ng-template #settingsContext>
  <div
    *ngIf="contextSettingsService.settings$ | async as settings"
    class="settings-container"
    [ngClass]="{ separator: withSeparator }"
  >
    <ng-container *ngIf="settings">
      <div class="settings-text source-text">
        <strong>{{ "Source: " | translate }}</strong> {{ settings.source.title }}
      </div>
      <div class="settings-text fiscal-year-text">
        <strong>{{ "Fiscal year: " | translate }}</strong> {{ settings.fiscalYear.title }}
      </div>
    </ng-container>
  </div>
</ng-template>
