import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateUtils } from '../classes/DateUtils/date-utils';

export class DateValidators {
  static compareDates(startDateFieldName: string = 'start', endDateFieldName: string = 'end'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let response = null;
      const start = control.get(startDateFieldName);
      const end = control.get(endDateFieldName);
      if (start?.value && end?.value) {
        const startValue = DateUtils.dateParse(start.value as string);
        const endValue = DateUtils.dateParse(end.value as string);

        response = startValue.getTime() > endValue.getTime() ? { RangeError: true } : null;
      }
      return response;
    };
  }
}
