import { Permission, TwoFactorStatus, User, UserRoles, UserType } from '../models';
import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';

export class UserBuilder {
  private readonly _user: User;

  constructor() {
    this._user = {
      aud: '',
      active: true,
      allow_basic_auth: true,
      client_code: 'novisto',
      client_id: '',
      email: '',
      exp: +faker.date.soon(),
      first_name: 'User',
      iat: 1,
      id: uuidv4(),
      iss: '',
      last_name: '1',
      name: '',
      org_id: '',
      org_name: '',
      roles: [UserRoles.admin],
      title: '',
      type: UserType.STANDARD,
      user_id: 'user_id',
      username: '',
      access_token: '',
      refresh_token: '',
      rexp: +faker.date.soon(),
      default_to_hq_fiscal_year: true,
      require_source_on_documents: false,
      permissions: [],
      password_last_changed: '',
      twofactor_status: { enabled: false },
    };
  }

  id(id: string): UserBuilder {
    this._user.id = id;
    return this;
  }

  allow_basic_auth(allowBasicAuth: boolean): UserBuilder {
    this._user.allow_basic_auth = allowBasicAuth;
    return this;
  }

  user_id(user_id: string): UserBuilder {
    this._user.user_id = user_id;
    return this;
  }

  first_name(name: string): UserBuilder {
    this._user.first_name = name;
    return this;
  }

  last_name(name: string): UserBuilder {
    this._user.last_name = name;
    return this;
  }

  roles(roles: string[]): UserBuilder {
    this._user.roles = roles;
    return this;
  }

  type(type: UserType): UserBuilder {
    this._user.type = type;
    return this;
  }

  email(email: string): UserBuilder {
    this._user.email = email;
    return this;
  }

  active(active: boolean): UserBuilder {
    this._user.active = active;
    return this;
  }

  title(title: string): UserBuilder {
    this._user.title = title;
    return this;
  }

  department(department: string): UserBuilder {
    this._user.department = department;
    return this;
  }

  accessToken(accessToken: string): UserBuilder {
    this._user.access_token = accessToken;
    return this;
  }

  refreshToken(refreshToken: string): UserBuilder {
    this._user.refresh_token = refreshToken;
    return this;
  }

  password_last_changed(date: string): UserBuilder {
    this._user.password_last_changed = date;
    return this;
  }

  created(date: Date): UserBuilder {
    this._user.created = date;
    return this;
  }

  created_by(id: string): UserBuilder {
    this._user.created_by = id;
    return this;
  }

  updated(date: Date): UserBuilder {
    this._user.updated = date;
    return this;
  }

  updated_by(id: string): UserBuilder {
    this._user.updated_by = id;
    return this;
  }

  permissions(permissions: Permission[]): UserBuilder {
    this._user.permissions = permissions;
    return this;
  }

  twofactorStatus(twofactorStatus: TwoFactorStatus): UserBuilder {
    this._user.twofactor_status = twofactorStatus;
    return this;
  }

  orgName(orgName: string): UserBuilder {
    this._user.org_name = orgName;
    return this;
  }

  build(): User {
    return this._user;
  }
}
