import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from '../../common';
import {
  ApiResponse,
  ApplicationApiDefinition,
  Dashboard,
  DashboardCreationRequest,
  DashboardRenameRequest,
  DashboardsFilteringOptions,
  DashboardUpdateVisibilityRequest,
  DashboardCopyRequest,
} from '../../../models';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ClientDashboardsService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.dashboards;
  }

  public createDashboard(dashboardCreationRequest: DashboardCreationRequest): Observable<ApiResponse<Dashboard>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/dashboards`, dashboardCreationRequest);
  }

  public renameDashboard(
    dashboardId: string,
    dashboardRenameRequest: DashboardRenameRequest
  ): Observable<ApiResponse<Dashboard>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/dashboards/${dashboardId}`, dashboardRenameRequest);
  }

  public getDashboard(dashboardId: string): Observable<ApiResponse<Dashboard>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/dashboards/${dashboardId}`);
  }

  public getDefaultDashboard(): Observable<ApiResponse<Dashboard | null>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/dashboards/default`);
  }

  public listDashboards(filters: DashboardsFilteringOptions): Observable<ApiResponse<Dashboard[]>> {
    let params = new HttpParams();

    if (filters.page) {
      params = params.append('page', filters.page);
    }
    if (filters.page_size) {
      params = params.append('page_size', filters.page_size);
    }
    if (filters.filters.search_term) {
      params = params.append('query', filters.filters.search_term);
    }
    if (filters.filters.owner) {
      params = params.append('owner_id', filters.filters.owner);
    }
    if (filters.filters.visibility) {
      params = params.append('visibility', filters.filters.visibility);
    }

    return this.apiService.get(`${this.servicePath}${this.resource}/dashboards`, { params });
  }

  public deleteDashboard(dashboardId: string): Observable<ApiResponse> {
    return this.apiService.delete(`${this.servicePath}${this.resource}/dashboards/${dashboardId}`);
  }

  public updateDashboardVisibility(
    dashboardId: string,
    dashboardUpdateVisibilityRequest: DashboardUpdateVisibilityRequest
  ): Observable<ApiResponse<Dashboard>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/dashboards/${dashboardId}/update_visibility`,
      dashboardUpdateVisibilityRequest
    );
  }

  public copyDashboard(
    dashboardId: string,
    dashboardCopyRequest: DashboardCopyRequest
  ): Observable<ApiResponse<Dashboard>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/dashboards/${dashboardId}/copy`,
      dashboardCopyRequest
    );
  }
}
