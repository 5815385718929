import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  Output,
  ViewChild,
  ElementRef,
  Renderer2,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';

import { EventsService, TranslateService } from '../../../services/common';
import { SearchService } from '../../../search';
import { SearchOptions, Collection, ActionItem, EmptyResults, Presentation } from '../../../models';

@Component({
  selector: 'lib-metric-selector-items',
  templateUrl: './metric-selector-items.component.html',
  styleUrls: ['./metric-selector-items.component.scss'],
})
export class MetricSelectorItemsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() searchOptions: string = '';
  @Output() action: EventEmitter<ActionItem> = new EventEmitter<ActionItem>();

  // Spinner, load more elememnts
  @ViewChild('spinner') spinner!: ElementRef<HTMLElement>;
  scrollObserver: IntersectionObserver = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      this.loadMore();
    }
  });

  cache?: string;
  loaded: boolean = false;
  noData: boolean = false;

  dummies = new Array(12);
  itemCollection?: Collection<any>;

  selectedItem?: ActionItem;
  activeItem?: ActionItem;
  selectedItemID?: string;
  selectedItemIDs: string[] = [];
  emptyResults: EmptyResults = {
    title: this.translateService.instant('No results'),
    subtitle: this.translateService.instant('No search results available for this combination of filters.'),
    image: 'laptop-neutral',
  };
  ePresentation = Presentation;

  constructor(
    private translateService: TranslateService,
    private renderer: Renderer2,
    private searchService: SearchService,
    private eventsService: EventsService
  ) {}

  ngOnInit(): void {
    this.eventsService.getSelectedItems().subscribe((items) => (this.selectedItemIDs = items));
  }

  ngOnChanges(changes: SimpleChanges): void {
    // prevent search for unintended events
    // emit changes is triggerer twice on search bar on keywords control enter, once with an Event object
    if (changes.searchOptions && typeof changes.searchOptions.currentValue === 'string') {
      // show spinner when search options changed and a new search starts
      // on initial search start spinner is not ready for the show method, but it will be rendered
      if (!changes.searchOptions.isFirstChange()) {
        this.showSpinner();
      }
      this.search();
    }
  }

  ngOnDestroy(): void {}

  search(): void {
    const searchOptions = JSON.parse(this.searchOptions);
    this.noData = !Object.keys(searchOptions.filters).length && !searchOptions.query.keywords;
    this.loaded = false;
    this.searchService.search(searchOptions, this.cache).subscribe((result) => {
      this.itemCollection = result;
      if (!this.itemCollection.items.length || this.itemCollection.items.length >= this.itemCollection.count) {
        this.hideSpinner();
      } else {
        // wait 0.5s for the dummies to fade out
        // spinner element is not available for observer before items become visible
        setTimeout(() => {
          this.scrollObserver.observe(this.spinner.nativeElement);
        }, 500);
      }
      this.loaded = true;
    });
  }

  loadMore(): void {
    const searchOptions = <SearchOptions>JSON.parse(this.searchOptions);
    searchOptions.from = this.itemCollection!.items.length;
    this.searchService.search(searchOptions, this.cache).subscribe((result) => {
      this.itemCollection!.items = this.itemCollection!.items.concat(result.items);
      if (this.itemCollection!.items.length >= this.itemCollection!.count) {
        this.scrollObserver.disconnect();
        this.hideSpinner();
      }
    });
  }

  showSpinner(): void {
    setTimeout(() => {
      this.renderer.removeClass(this.spinner.nativeElement, 'invisible');
    }, 500);
  }

  hideSpinner(): void {
    setTimeout(() => {
      this.renderer.addClass(this.spinner.nativeElement, 'invisible');
    }, 500);
  }

  onMouseOver(item: any): void {
    this.activeItem = item;
  }

  onMouseOut(): void {
    this.activeItem = undefined;
  }

  selectItem(action: ActionItem): void {
    this.selectedItem = action.item;
    this.action.emit(action);
  }
}
