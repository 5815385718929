import { DashboardStackChartData } from '../../models';
import { CreatedUpdatedBuilder } from '../created-updated-builder';

export class DashboardStackChartDataBuilder extends CreatedUpdatedBuilder<DashboardStackChartData> {
  constructor() {
    super({
      value: Math.floor(Math.random() * 10),
      x_label: 'test x label',
      y_label: 'test y label',
    });
  }

  value(value: number | undefined): DashboardStackChartDataBuilder {
    this._instance.value = value;
    return this;
  }

  xLabel(xLabel: string): DashboardStackChartDataBuilder {
    this._instance.x_label = xLabel;
    return this;
  }

  yLabel(yLabel: string): DashboardStackChartDataBuilder {
    this._instance.y_label = yLabel;
    return this;
  }
}
