import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Required } from '../../../decorators';
import { ValueGroupFormGroup } from '../../models/valueGroupFormGroup';
import {
  ValueDefinitionType,
  Value,
  DataRequestUserResponsibility,
  DataRequestValueGroupSetStatus,
  PlatformValueGroupSetStatus,
  DataRequestSourceStatus,
} from '../../../models';
import { UpsertValue, UpsertValueGroup } from '../../models/upsertValue';
import { ValueFormControl } from '../../models/valueFormControl';
import { MoveValueGroupEvent } from '../../models/moveValueGroupEvent';
import { ResetValueEventWithoutVgsetId } from '../../models/resetValueEvent';
import { MetricEditorFieldHandlerComponent } from '../metric-editor-field-handler/metric-editor-field-handler.component';
import { DEFAULT_DOCUMENT_CONTEXT, DocumentContext } from '../../models/documentContext';
import { Subject, takeUntil } from 'rxjs';
import { waitForNextUpdate } from '../../utils/operators';

@Component({
  selector: 'lib-metric-editor-group-handler',
  templateUrl: './metric-editor-group-handler.component.html',
  styleUrls: ['./metric-editor-group-handler.component.scss'],
})
export class MetricEditorGroupHandlerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() @Required() valueGroupFormGroup!: ValueGroupFormGroup;
  @Input() documentContext: DocumentContext = DEFAULT_DOCUMENT_CONTEXT;
  @Input() repeatableGroupCount = 0;
  @Input() indicatorId: string = '';
  @Input() vgsetId: string = '';
  @Input() displayFieldActions: boolean = false;
  @Input() collaboratorResponsibility?: DataRequestUserResponsibility;
  @Input() valueGroupSetStatus!: PlatformValueGroupSetStatus | DataRequestValueGroupSetStatus;
  @Input() dataRequestSourceStatus!: DataRequestSourceStatus;

  @Output() update: EventEmitter<UpsertValueGroup> = new EventEmitter<UpsertValueGroup>();
  @Output() resetValue: EventEmitter<ResetValueEventWithoutVgsetId> = new EventEmitter<ResetValueEventWithoutVgsetId>();
  @Output() addGroup: EventEmitter<UpsertValueGroup[]> = new EventEmitter<UpsertValueGroup[]>();
  @Output() moveGroup: EventEmitter<MoveValueGroupEvent> = new EventEmitter<MoveValueGroupEvent>();
  @Output() deleteGroup: EventEmitter<string> = new EventEmitter<string>();
  @Output() metricLinkEdit: EventEmitter<string> = new EventEmitter<string>();

  @ViewChildren(MetricEditorFieldHandlerComponent)
  metricEditorFieldHandlerComponents!: QueryList<MetricEditorFieldHandlerComponent>;

  showOptionMenu: boolean = false;
  moveUpDisabled: boolean = true;
  moveDownDisabled: boolean = true;

  private destroy$ = new Subject<void>();
  private updateValueSubject = new Subject<UpsertValue>();

  ngOnInit(): void {
    this.setupUpdateValueSubscription();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges() {
    this.showOptionMenu = this.valueGroupFormGroup.repeatable && this.repeatableGroupCount > 1;
    this.moveUpDisabled = this.valueGroupFormGroup.subposition <= 1;
    this.moveDownDisabled = this.valueGroupFormGroup.subposition >= this.repeatableGroupCount;
  }

  setupUpdateValueSubscription() {
    this.updateValueSubject
      .pipe(waitForNextUpdate(this.valueGroupFormGroup), takeUntil(this.destroy$))
      .subscribe((upsertValue) => {
        this.handlePendingCreation();
        this.update.emit(this.valueGroupFormGroup.toUpsertValueGroup([upsertValue]));
      });
  }

  onUpdateValue(upsertValue: UpsertValue): void {
    this.updateValueSubject.next(upsertValue);
  }

  handleResetValue(valueId: string): void {
    this.resetValue.emit({ valueId, valueGroupId: this.valueGroupFormGroup.valueGroupRef.id });
  }

  private handlePendingCreation(): void {
    if (this.valueGroupFormGroup.valueGroupRef.id == null) {
      this.valueGroupFormGroup.waitForNextUpdate();
    }
  }

  isShowAddGroupButton(): boolean {
    if (!this.valueGroupFormGroup.repeatable || this.valueGroupFormGroup.disabled) {
      return false;
    }

    const groupMaxRepetition = this.valueGroupFormGroup.groupMaxRepetition;
    const isLast = this.valueGroupFormGroup.subposition === this.repeatableGroupCount;

    const hasValuesToShow = Boolean(this.valueGroupFormGroup.displayedValueFormControls()?.length);
    return (groupMaxRepetition ? this.repeatableGroupCount < groupMaxRepetition && isLast : isLast) && hasValuesToShow;
  }

  moveUp(): void {
    this.moveGroup.emit({
      value_group_id: this.valueGroupFormGroup.valueGroupRef.id,
      position: this.valueGroupFormGroup.subposition - 1,
    });
  }

  moveDown(): void {
    this.moveGroup.emit({
      value_group_id: this.valueGroupFormGroup.valueGroupRef.id,
      position: this.valueGroupFormGroup.subposition + 1,
    });
  }

  delete(): void {
    this.deleteGroup.emit(this.valueGroupFormGroup.valueGroupRef.id);
  }

  repeatGroup(): void {
    const currentGroup = this.valueGroupFormGroup.toUpsertValueGroup([]);
    const repeatedGroup = { ...currentGroup, id: undefined, subposition: null };
    const groupsToEmit = this.repeatableGroupCount === 1 ? [currentGroup, repeatedGroup] : [repeatedGroup];
    this.addGroup.emit(groupsToEmit);
  }

  valueFormControlTrackBy(index: number, valueFormControl: ValueFormControl): string {
    return valueFormControl.valueRef.value_definition_id;
  }

  isACalculatedFieldInARepeatableGroup(valueFormControl: ValueFormControl): boolean {
    return this.valueGroupFormGroup.repeatable && valueFormControl.valueRef.type === ValueDefinitionType.calculated;
  }

  getFocusId(valueRef: Value): string {
    return `${this.valueGroupFormGroup.valueGroupRef?.value_definition_group_id}-${
      this.valueGroupFormGroup.valueGroupRef.position
    }-${this.valueGroupFormGroup.valueGroupRef.subposition ?? 1}-${valueRef.value_definition_id}-${valueRef.position}`;
  }
}
