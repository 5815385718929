import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { DataRequestSourceStatus, DataRequestUserResponsibility, ValueGroupSet } from '../models';
import { Required } from '../decorators';
import { ValueGroupSetForm } from './models/valueGroupSetForm';
import { ResetValueEvent, ResetValueEventWithoutVgsetId } from './models/resetValueEvent';
import { UpsertValueGroup, UpsertValueGroupSet } from './models/upsertValue';
import { MoveValueGroupEvent } from './models/moveValueGroupEvent';
import { MetricEditorGroupHandlerComponent } from './components/metric-editor-group-handler/metric-editor-group-handler.component';
import { DEFAULT_DOCUMENT_CONTEXT, DocumentContext } from './models/documentContext';
import { MetricEditorFormGroup } from './models/metricEditorFormGroup';
import { delay, startWith, Subject, takeUntil } from 'rxjs';
import { waitForNextUpdate } from './utils/operators';
import { BaseMetricEditorFormStateService } from './services/base-metric-editor-form-state/base-metric-editor-form-state.service';

@Component({
  selector: 'lib-metric-editor-form',
  templateUrl: './metric-editor-form.component.html',
  styleUrls: ['./metric-editor-form.component.scss'],
})
export class MetricEditorFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() @Required() valueGroupSet!: ValueGroupSet;
  @Input() disabled = false;
  @Input() documentContext: DocumentContext = DEFAULT_DOCUMENT_CONTEXT;
  @Input() withStaticConsolidationRules = false;
  @Input() disabledReason?: string;
  @Input() displayFieldActions: boolean = false;
  @Input() collaboratorResponsibility?: DataRequestUserResponsibility;
  @Input() dataRequestSourceStatus!: DataRequestSourceStatus;

  @Output() update: EventEmitter<UpsertValueGroupSet> = new EventEmitter<UpsertValueGroupSet>();
  @Output() moveGroup: EventEmitter<MoveValueGroupEvent> = new EventEmitter<MoveValueGroupEvent>();
  @Output() deleteGroup: EventEmitter<string> = new EventEmitter<string>();
  @Output() resetValue: EventEmitter<ResetValueEvent> = new EventEmitter<ResetValueEvent>();
  @Output() metricLinkEdit: EventEmitter<string> = new EventEmitter<string>();
  @Output() isVgsetFormValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChildren(MetricEditorGroupHandlerComponent)
  metricEditorGroupHandlerComponents!: QueryList<MetricEditorGroupHandlerComponent>;

  valueGroupSetForm?: ValueGroupSetForm;

  private unsubscribe$ = new Subject<void>();
  private updateValueGroupSubject = new Subject<UpsertValueGroup>();

  constructor(private baseMetricEditorFormStateService: BaseMetricEditorFormStateService) {}

  ngOnInit(): void {
    this.valueGroupSetForm = new ValueGroupSetForm(this.valueGroupSet);
    this.setAvailability();
    this.baseMetricEditorFormStateService.setDefaultFocusField(this.valueGroupSet);
    this.valueGroupSetForm.statusChanges
      .pipe(startWith(this.valueGroupSetForm.status), delay(0), takeUntil(this.unsubscribe$))
      .subscribe((status) => {
        this.isVgsetFormValid.emit(status !== 'INVALID');
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.valueGroupSet) {
      if (this.valueGroupSetForm == null) {
        this.initializeValueGroupSetForm();
      } else {
        const previousValueGroupSetId = changes.valueGroupSet.previousValue.id as string | undefined;
        const currentValueGroupSetId = changes.valueGroupSet.currentValue.id as string | undefined;

        if (previousValueGroupSetId != null && previousValueGroupSetId !== currentValueGroupSetId) {
          this.initializeValueGroupSetForm();
        } else {
          this.valueGroupSetForm.updateValueGroupSet(this.valueGroupSet);
        }
        // NF-5564 had to be merged to fix a release blocker but this optimization need to come back at some point.
        // if (
        //   previousValueGroupSetId != null &&
        //   currentValueGroupSetId != null &&
        //   previousValueGroupSetId === currentValueGroupSetId
        // ) {
        //   this.valueGroupSetForm.updateValueGroupSet(this.valueGroupSet);
        // } else {
        //   this.initializeValueGroupSetForm();
        // }
      }
    }

    this.setAvailability();
  }

  private initializeValueGroupSetForm(): void {
    this.valueGroupSetForm = new ValueGroupSetForm(this.valueGroupSet);
    this.unsubscribe$.next();
    this.setupUpdateValueGroupSubscription(this.valueGroupSetForm);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setAvailability(): void {
    if (this.disabled) {
      this.valueGroupSetForm?.disable({ emitEvent: false });
    } else {
      this.valueGroupSetForm?.enable({ emitEvent: false });
      this.valueGroupSetForm?.applyConditionalTriggers();

      if (this.withStaticConsolidationRules) {
        this.valueGroupSetForm?.applyStaticConsolidationRules();
      }
    }
  }

  setupUpdateValueGroupSubscription(valueGroupSetForm: ValueGroupSetForm): void {
    this.updateValueGroupSubject
      .pipe(waitForNextUpdate(valueGroupSetForm), takeUntil(this.unsubscribe$))
      .subscribe((upsertValueGroup) => {
        this.handlePendingCreation();
        this.update.emit(this.valueGroupSetForm?.toUpsertValueGroupSet([upsertValueGroup]));
      });
  }

  public onUpdateValueGroup(upsertValueGroup: UpsertValueGroup): void {
    this.updateValueGroupSubject.next(upsertValueGroup);
  }

  public onResetValue(event: ResetValueEventWithoutVgsetId): void {
    this.resetValue.emit({ ...event, valueGroupSetId: this.valueGroupSet.id });
  }

  public handleAddValueGroup(upsertValueGroups: UpsertValueGroup[]): void {
    this.update.emit(this.valueGroupSetForm?.toUpsertValueGroupSet(upsertValueGroups));
  }

  public handleMoveValueGroup(moveValueGroupEvent: MoveValueGroupEvent): void {
    this.moveGroup.emit(moveValueGroupEvent);
  }

  public handleDeleteValueGroup(valueGroupId: string): void {
    this.deleteGroup.emit(valueGroupId);
  }

  public formGroupTrackBy(index: number, formGroup: MetricEditorFormGroup): string {
    return `${formGroup.definitionId}, ${formGroup.position}, ${formGroup.subposition}, ${formGroup.id}`;
  }

  public setFocus(focusId: string): void {
    const metricFieldHandler = this.metricEditorGroupHandlerComponents
      .map((groupHandler) =>
        groupHandler.metricEditorFieldHandlerComponents.find((fieldHandler) => fieldHandler.focusId === focusId)
      )
      .find((fieldHandlers) => fieldHandlers);
    metricFieldHandler?.setFocus();
  }

  public get isValidOrDisabled(): boolean {
    return !!this.valueGroupSetForm?.isValidOrDisabled();
  }

  public triggerValidations(): void {
    this.valueGroupSetForm?.triggerValidations();
  }

  private handlePendingCreation(): void {
    if (this.valueGroupSetForm?.valueGroupSet.id == null) {
      this.valueGroupSetForm?.waitForNextUpdate();
    }
  }
}
