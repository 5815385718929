import { faker } from '@faker-js/faker';

import { CreatedUpdatedBuilder } from './created-updated-builder';
import { Entity } from '../models';

export abstract class EntityBuilder<T extends Entity> extends CreatedUpdatedBuilder<T> {
  protected constructor(instance: T) {
    super({
      code: `${faker.word.noun({ length: 3 })}_${faker.word.noun({ length: 4 })}`,
      active: true,
      ...instance,
    });
  }

  id(id: string): EntityBuilder<T> {
    this._instance.id = id;
    return this;
  }

  name(name: string): EntityBuilder<T> {
    this._instance.name = name;
    return this;
  }

  code(code: string): EntityBuilder<T> {
    this._instance.code = code;
    return this;
  }

  active(active: boolean): EntityBuilder<T> {
    this._instance.active = active;
    return this;
  }
}
