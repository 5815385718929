import { ApiError } from '../models';

export class ApiErrorBuilder<T = string> {
  private readonly _apiErrorResponse: ApiError<T>;

  constructor() {
    this._apiErrorResponse = {
      detail: undefined,
      type: 'error',
    };
  }

  detail(detail: T): ApiErrorBuilder<T> {
    this._apiErrorResponse.detail = detail;
    return this;
  }

  type(type: string): ApiErrorBuilder<T> {
    this._apiErrorResponse.type = type;
    return this;
  }

  build(): ApiError<T> {
    return this._apiErrorResponse;
  }
}
