import {
  DashboardStackBarInputType,
  DashboardStackBarRenderingType,
  DashboardStackChart,
  DashboardStackChartData,
  IntegerTypeDetails,
  MetricTableColumnDefinition,
  ValueDefinition,
} from '../../models';
import { CreatedUpdatedBuilder } from '../created-updated-builder';
import { v4 as uuidv4 } from 'uuid';
import { DashboardStackChartDataBuilder } from './dashboard-stack-chart-data-builder';
import { ValueDefinitionBuilder } from '../value-definition-builder';
import { MetricTableColumnDefinitionBuilder } from '../metric-table-column-definition-builder';

export class DashboardStackChartBuilder extends CreatedUpdatedBuilder<DashboardStackChart> {
  constructor() {
    super({
      id: uuidv4(),
      input_type: DashboardStackBarInputType.calculated,
      rendering_type: DashboardStackBarRenderingType.absolute,
      fiscal_year_start_id: uuidv4(),
      fiscal_year_end_id: uuidv4(),
      value_definition: new ValueDefinitionBuilder().build(),
      metric_table_column_definition: new MetricTableColumnDefinitionBuilder().build(),
      type_details: { family: 'general', units: 'default' },
      chart_data: [new DashboardStackChartDataBuilder().build(), new DashboardStackChartDataBuilder().build()],
    });
  }

  renderingType(renderingType: DashboardStackBarRenderingType): DashboardStackChartBuilder {
    this._instance.rendering_type = renderingType;
    return this;
  }

  inputType(inputType: DashboardStackBarInputType): DashboardStackChartBuilder {
    this._instance.input_type = inputType;
    return this;
  }

  typeDetails(typeDetails: IntegerTypeDetails): DashboardStackChartBuilder {
    this._instance.type_details = typeDetails;
    return this;
  }

  chartData(chartData: DashboardStackChartData[]): DashboardStackChartBuilder {
    this._instance.chart_data = chartData;
    return this;
  }

  fiscalYearStartId(fiscalYearId: string): DashboardStackChartBuilder {
    this._instance.fiscal_year_start_id = fiscalYearId;
    return this;
  }

  fiscalYearEndId(fiscalYearId: string | null): DashboardStackChartBuilder {
    this._instance.fiscal_year_end_id = fiscalYearId;
    return this;
  }

  valueDefinition(valueDefinition: ValueDefinition | undefined): DashboardStackChartBuilder {
    this._instance.value_definition = valueDefinition;
    return this;
  }

  metricTableColumnDefinition(
    metricTableColumnDefinition: MetricTableColumnDefinition | undefined
  ): DashboardStackChartBuilder {
    this._instance.metric_table_column_definition = metricTableColumnDefinition;
    return this;
  }
}
