import {
  DashboardChartWidget,
  DashboardDataPointWidget,
  DashboardValueDefinitionTargetWidget,
  DashboardWidget,
  DashboardWidgetSource,
  DashboardWidgetType,
  Metric,
} from '../../models';
import { CreatedUpdatedBuilder } from '../created-updated-builder';
import { MetricBuilder } from '../metric-builder';
import { DashboardDataPointWidgetBuilder } from './dashboard-data-point-widget-builder';
import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';
import { DashboardChartWidgetBuilder } from './dashboard-chart-widget-builder';
import { DashboardValueDefinitionTargetWidgetBuilder } from './dashboard-value-definition-target-widget-builder';

export class DashboardWidgetBuilder extends CreatedUpdatedBuilder<DashboardWidget> {
  constructor() {
    super({
      id: uuidv4(),
      label: faker.name.jobDescriptor(),
      row_location: 0,
      column_location: 1,
      height: 1,
      width: 1,
      widget_type: DashboardWidgetType.DATA_POINT_WIDGET,
      created: new Date(),
      created_by: uuidv4(),
      updated: new Date(),
      updated_by: uuidv4(),
      dashboard_id: uuidv4(),
      metric: new MetricBuilder().build(),
      data_point_widget: new DashboardDataPointWidgetBuilder().build(),
      stack_chart_widget: new DashboardChartWidgetBuilder().build(),
      value_definition_target_widget: new DashboardValueDefinitionTargetWidgetBuilder().build(),
      widget_sources: [],
    });
  }

  id(id: string): DashboardWidgetBuilder {
    this._instance.id = id;
    return this;
  }

  label(label: string): DashboardWidgetBuilder {
    this._instance.label = label;
    return this;
  }

  widgetType(type: DashboardWidgetType): DashboardWidgetBuilder {
    this._instance.widget_type = type;
    return this;
  }

  metric(metric: Metric): DashboardWidgetBuilder {
    this._instance.metric = metric;
    return this;
  }

  dataPointWidget(dataPointWidget: DashboardDataPointWidget | undefined): DashboardWidgetBuilder {
    this._instance.data_point_widget = dataPointWidget;
    return this;
  }

  stackChartWidget(stackChartWidget: DashboardChartWidget): DashboardWidgetBuilder {
    this._instance.stack_chart_widget = stackChartWidget;
    return this;
  }

  valueDefinitionTargetWidget(
    valueDefinitionTargetWidget: DashboardValueDefinitionTargetWidget | undefined
  ): DashboardWidgetBuilder {
    this._instance.value_definition_target_widget = valueDefinitionTargetWidget;
    return this;
  }

  widgetSources(widgetSources: DashboardWidgetSource[]): DashboardWidgetBuilder {
    this._instance.widget_sources = widgetSources;
    return this;
  }

  height(height: number): DashboardWidgetBuilder {
    this._instance.height = height;
    return this;
  }

  width(width: number): DashboardWidgetBuilder {
    this._instance.width = width;
    return this;
  }

  rowLocation(rowLocation: number): DashboardWidgetBuilder {
    this._instance.row_location = rowLocation;
    return this;
  }

  columnLocation(columnLocation: number): DashboardWidgetBuilder {
    this._instance.column_location = columnLocation;
    return this;
  }
}
