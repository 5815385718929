<div class="fx-row justify-start align-center large" *ngIf="selectionList$ | async as selectionList">
  <lib-select-input
    class="formfield fx-grow required"
    [label]="'Option list' | translate"
    [control]="selectionListFormControl"
    (selectionChangeEvent)="updateAnswers()"
    [options]="selectionList"
    [messages]="{ invalidChoices: 'Please add choices by clicking the edit button' | translate }"
  >
  </lib-select-input>

  <div class="btn-edit-container" *ngIf="canCreateCustomChoices">
    <a
      class="btn btn-stroked btn-edit-choice-option"
      [ngClass]="{ disabled: selectionListFormControl.disabled }"
      (click)="launchCustomSelectionDialog()"
    >
      <span>{{ "Edit" | translate }}</span>
    </a>
  </div>
</div>
