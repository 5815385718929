import { LANGUAGES, LicenseeSettingsRequest } from '../models';
import { faker } from '@faker-js/faker';

export class LicenseeSettingsBuilder {
  private readonly _licenseeSettings: LicenseeSettingsRequest;

  constructor() {
    this._licenseeSettings = {
      onerow_id: faker.datatype.boolean(),
      language: LANGUAGES.FR,
      login_lock_time: faker.datatype.number(),
      logout_inactivity_time: faker.datatype.number(),
      warn_inactivity_time: faker.datatype.number(),
      max_login_attempts: faker.datatype.number(),
      enforce_2fa: faker.datatype.boolean(),
      allow_risky_extensions_public: faker.datatype.boolean(),
      allow_risky_extensions_platform: faker.datatype.boolean(),
      password_reuse_max_number: faker.datatype.number(),
      password_reuse_max_age_months: faker.datatype.number(),
      password_expiration_days: faker.datatype.number(),
    };
  }

  language(language: LANGUAGES) {
    this._licenseeSettings.language = language;
    return this;
  }

  loginLockTime(time: number) {
    this._licenseeSettings.login_lock_time = time;
    return this;
  }

  logoutInactivityTime(time: number) {
    this._licenseeSettings.logout_inactivity_time = time;
    return this;
  }

  warnInactivityTime(time: number) {
    this._licenseeSettings.warn_inactivity_time = time;
    return this;
  }

  maxLoginAttempts(amount: number) {
    this._licenseeSettings.max_login_attempts = amount;
    return this;
  }

  enforce2Fa(enforce2Fa: boolean) {
    this._licenseeSettings.enforce_2fa = enforce2Fa;
    return this;
  }

  allowRiskyExtensionsPublic(allowRiskyExtensionsPublic: boolean) {
    this._licenseeSettings.allow_risky_extensions_public = allowRiskyExtensionsPublic;
    return this;
  }

  allowRiskyExtensionsPlatform(allowRiskyExtensionsPlatform: boolean) {
    this._licenseeSettings.allow_risky_extensions_platform = allowRiskyExtensionsPlatform;
    return this;
  }

  passwordReuseMaxNumber(amount: number) {
    this._licenseeSettings.password_reuse_max_number = amount;
    return this;
  }

  passwordReuseMaxAgeMonths(months: number) {
    this._licenseeSettings.password_reuse_max_age_months = months;
    return this;
  }

  passwordExpiry(amount: number) {
    this._licenseeSettings.password_expiration_days = amount;
    return this;
  }

  build(): LicenseeSettingsRequest {
    return this._licenseeSettings;
  }
}
