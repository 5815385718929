import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PublicApiService as AbstractPublicApiService, AuthService } from '@novisto/common';

import { APIConfigAddon, ApplicationApiDefinitionAddon, SupportedEnvironment } from '../../models';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class PublicApiService extends AbstractPublicApiService {
  public apiConfig: APIConfigAddon;

  private apiName: keyof ApplicationApiDefinitionAddon = 'external';

  constructor(http: HttpClient, httpBackend: HttpBackend, private authService: AuthService) {
    super(http, httpBackend);
    this.apiConfig = environment.apiConfig;
  }

  public getBaseUrl(): string {
    return `${this.getProtocol()}${this.getDomain()}`;
  }

  public getServicePath(apiName: keyof ApplicationApiDefinitionAddon = this.apiName): string {
    return this.apiConfig.apis[apiName].api || '';
  }

  public getUrl(path: string): string {
    return `${this.getBaseUrl()}${path}`;
  }

  private getDomain(): string {
    switch (this.apiConfig.env) {
      case SupportedEnvironment.localhost: {
        return `public.${SupportedEnvironment.dev}.${this.authService.user?.client_code}.novisto.net`;
      }
      case SupportedEnvironment.production: {
        return `public.${this.authService.user?.client_code}.novisto.net`;
      }
      default: {
        return `public.${this.apiConfig.env}.${this.authService.user?.client_code}.novisto.net`;
      }
    }
  }
}
