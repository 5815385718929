import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {
  BooleanTypeDetails,
  ChoiceTypeDetails,
  ConditionalTrigger,
  ConditionalTriggerTarget,
  ConditionalTriggerTargetUpsert,
  ConditionalTriggerUpsertPayload,
  DialogResult,
  Status,
  TipTypeDetails,
  ValueDefinition,
  ValueDefinitionGroup,
  ValueDefinitionType,
} from '../../../models';
import { ConditionalTriggerForm } from './conditional-trigger-form';
import { TranslateService, HtmlToStringService } from '../../../services/common';
import { SearchService } from '../../../search';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConditionalTriggerDialogData, TargetOption, TargetType } from './models';
import { IsDeactivatedPipe } from '../../pipe/is-deactivated/is-deactivated.pipe';

@Component({
  selector: 'lib-conditional-trigger-add-edit-dialog',
  templateUrl: './conditional-trigger-add-edit-dialog.component.html',
  styleUrls: ['./conditional-trigger-add-edit-dialog.component.scss'],
  providers: [IsDeactivatedPipe],
})
export class ConditionalTriggerAddEditDialogComponent implements OnInit {
  triggerValueChoiceList$: Observable<string[] | boolean[]> = of([]);
  targetChoiceList: TargetOption[] = [];
  conditionalTriggerForm: ConditionalTriggerForm;

  sourceValueDefinition: ValueDefinition<ChoiceTypeDetails | BooleanTypeDetails>;
  valueDefinitionGroups: ValueDefinitionGroup[];

  isEditing: boolean = false;

  constructor(
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ConditionalTriggerDialogData,
    private dialogRef: MatDialogRef<
      ConditionalTriggerAddEditDialogComponent,
      DialogResult<ConditionalTriggerUpsertPayload>
    >,
    private searchService: SearchService,
    private htmlToStringService: HtmlToStringService,
    private isDeactivatedPipe: IsDeactivatedPipe
  ) {
    this.isEditing = data.conditionalTrigger != null;
    this.sourceValueDefinition = this.data.sourceValueDefinition;
    this.valueDefinitionGroups = this.data.valueDefinitionGroups;
    const conditionalTrigger: ConditionalTrigger = data.conditionalTrigger
      ? JSON.parse(JSON.stringify(data.conditionalTrigger))
      : undefined;
    if (conditionalTrigger && this.sourceValueDefinition.type === ValueDefinitionType.boolean) {
      data.conditionalTrigger?.values?.forEach((value, index) => {
        conditionalTrigger.values[index] = value
          ? ((this.sourceValueDefinition.type_details as BooleanTypeDetails).label_true as string)
          : ((this.sourceValueDefinition.type_details as BooleanTypeDetails).label_false as string);
      });
    }
    this.conditionalTriggerForm = new ConditionalTriggerForm(conditionalTrigger);
  }

  ngOnInit(): void {
    this.initializeTriggerValueChoiceList();
    this.initializeTargetChoiceList();
  }

  get dialogTitle(): string {
    return this.translateService.instant(this.isEditing ? 'Edit a Conditional Trigger' : 'Add a Conditional Trigger');
  }

  get submitButtonText(): string {
    return this.translateService.instant(this.isEditing ? 'Save' : 'Add trigger');
  }

  private initializeTriggerValueChoiceList(): void {
    if (this.sourceValueDefinition.type === ValueDefinitionType.choice) {
      const choiceTypeDetails = this.sourceValueDefinition.type_details as ChoiceTypeDetails;
      const selectionSetId = choiceTypeDetails.selection_set_id;
      if (selectionSetId != null) {
        this.triggerValueChoiceList$ = this.searchService
          .searchResources('selection_set_item', selectionSetId)
          .pipe(map((items) => items.map((item) => item.title)));
      } else {
        this.triggerValueChoiceList$ = of(choiceTypeDetails.choices ?? []);
      }
    } else if (this.sourceValueDefinition.type === ValueDefinitionType.boolean) {
      const booleanTypeDetails = this.sourceValueDefinition.type_details as BooleanTypeDetails;
      const trueLabel = booleanTypeDetails.label_true;
      const falseLabel = booleanTypeDetails.label_false;
      if (trueLabel && falseLabel) {
        this.triggerValueChoiceList$ = of([trueLabel, falseLabel]);
      } else {
        this.triggerValueChoiceList$ = of([true, false]);
      }
    }
  }

  private initializeTargetChoiceList(): void {
    const sourceValueDefinitionGroup = this.valueDefinitionGroups.find(
      (group) => group.id === this.sourceValueDefinition.value_definition_group_id
    );

    if (sourceValueDefinitionGroup) {
      const availableValueDefinitionTargetOptions =
        this.getAvailableValueDefinitionTargetOptions(sourceValueDefinitionGroup);
      const availableValueDefinitionGroupTargetOptions =
        this.getAvailableValueDefinitionGroupTargetOptions(sourceValueDefinitionGroup);
      this.targetChoiceList = [...availableValueDefinitionTargetOptions, ...availableValueDefinitionGroupTargetOptions];
    } else {
      this.targetChoiceList = [];
    }
  }

  private getTargetOptionLabel(vd: ValueDefinition): string {
    switch (vd.type) {
      case ValueDefinitionType.subtitle:
        return this.translateService.instant('Subtitle: {subtitleLabel}', { subtitleLabel: vd.type_details.value });
      case ValueDefinitionType.tip:
        return this.translateService.instant('Instructions: {instructionLabel}', {
          instructionLabel: this.htmlToStringService.convertTipRichTextToPlain(
            this.isValueDefinitionTypeTip(vd) ? vd.type_details?.value ?? '' : '',
            25
          ),
        });
      case ValueDefinitionType.document:
        return this.translateService.instant('Document Link: {docLabel}', {
          docLabel: vd.document?.name ?? this.translateService.instant('Unknown'),
        });
      default:
        return vd.label ?? this.translateService.instant('Unknown');
    }
  }

  private isValueDefinitionTypeTip(vd: ValueDefinition): vd is ValueDefinition<TipTypeDetails> {
    return vd.type === ValueDefinitionType.tip;
  }

  private getAvailableValueDefinitionTargetOptions(sourceValueDefinitionGroup: ValueDefinitionGroup): TargetOption[] {
    return (
      sourceValueDefinitionGroup.value_definitions
        ?.filter(
          (vd) => vd.position > this.sourceValueDefinition.position && this.isOptionActiveOrAlreadyExistsInTargets(vd)
        )
        .map((vd) => ({
          id: vd.id,
          type: TargetType.ValueDefinition,
          name: this.getTargetOptionLabel(vd),
        })) ?? []
    );
  }

  private isOptionActiveOrAlreadyExistsInTargets(targetItem: ValueDefinition | ValueDefinitionGroup): boolean {
    const alreadyExists = this.data.conditionalTrigger?.targets.some(
      (t) => t.value_definition_id === targetItem.id || t.value_definition_group_id === targetItem.id
    );
    return alreadyExists || !this.isDeactivatedPipe.transform(targetItem);
  }

  private getAvailableValueDefinitionGroupTargetOptions(
    sourceValueDefinitionGroup: ValueDefinitionGroup
  ): TargetOption[] {
    return this.valueDefinitionGroups
      .filter(
        (vdg) =>
          vdg.position > sourceValueDefinitionGroup.position &&
          vdg.table_id == null &&
          this.isOptionActiveOrAlreadyExistsInTargets(vdg)
      )
      .map((vdg) => ({
        id: vdg.id,
        type: TargetType.ValueDefinitionGroup,
        name: vdg.label ?? this.translateService.instant('Group {iteration}', { iteration: vdg.position }),
      }));
  }

  targetOptionToConditionTriggerTarget(targetOption: TargetOption): Partial<ConditionalTriggerTarget> {
    if (targetOption.type == TargetType.ValueDefinition) {
      return { value_definition_id: targetOption.id };
    } else {
      return { value_definition_group_id: targetOption.id };
    }
  }

  compareConditionalTriggerTarget(target1: ConditionalTriggerTarget, target2: ConditionalTriggerTarget): boolean {
    return (
      target1.value_definition_id == target2.value_definition_id &&
      target1.value_definition_group_id == target2.value_definition_group_id
    );
  }

  submit(): void {
    this.dialogRef.close({ status: Status.SUCCESS, data: this.getPayloadFromForm() });
  }

  private getPayloadFromForm(): ConditionalTriggerUpsertPayload {
    const valueList = [];
    if (this.sourceValueDefinition.type === ValueDefinitionType.boolean) {
      for (const triggerValue of this.conditionalTriggerForm.triggerValue.value) {
        valueList.push(triggerValue === (this.sourceValueDefinition.type_details as BooleanTypeDetails).label_true);
      }
    }
    return {
      values:
        this.sourceValueDefinition.type === ValueDefinitionType.boolean
          ? valueList
          : (this.conditionalTriggerForm.triggerValue.value as string[] | null) ?? [],
      targets:
        ((this.conditionalTriggerForm.targets.value as Partial<ConditionalTriggerTarget>[] | null)?.map((t) => ({
          value_definition_id: t.value_definition_id,
          value_definition_group_id: t.value_definition_group_id,
        })) as ConditionalTriggerTargetUpsert[]) ?? [],
    };
  }

  closeDialog(): void {
    this.dialogRef.close({ status: Status.CANCEL });
  }
}
