import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionItem, ResourceType } from '../../../models';
import { ButtonProps, OptionProps } from '../../models';

@Component({
  selector: 'lib-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
  @Input() optionProps?: OptionProps;
  @Input() buttonProps?: ButtonProps;

  @Output() optionSelected = new EventEmitter<ActionItem>();

  sortResourceType = ResourceType.sort;

  getDefaultValueTitle(): string {
    return this.optionProps?.defaultValueTitle ?? this.optionProps?.defaultValue?.title ?? '';
  }

  getButtonTitle(): string {
    return this.buttonProps?.title ?? this.optionProps?.activeOption?.title ?? '';
  }
}
