import { Component, Input, OnInit } from '@angular/core';

import { MetricSearchStateService } from '../metric-search-state.service';
import { MetricSearchValueDefinitionField, Presentation, ValueDefinition, ValueDefinitionType } from '../../models';

@Component({
  selector: 'lib-metric-search-value-definitions',
  templateUrl: './metric-search-value-definitions.component.html',
  styleUrls: ['./metric-search-value-definitions.component.scss'],
  providers: [],
})
export class MetricSearchValueDefinitionsComponent implements OnInit {
  @Input() metricId: string = '';

  public readonly eField = MetricSearchValueDefinitionField;
  public readonly ePresentation = Presentation;
  public readonly eValueDefinitionType = ValueDefinitionType;

  public isLoading: boolean = true;
  public toggledValueDefinitions: Record<string, boolean> = {};
  public valueDefinitions: ValueDefinition[] = [];

  constructor(public readonly metricSearchStateService: MetricSearchStateService) {}

  public ngOnInit(): void {
    this.metricSearchStateService.loadMetricValueDefinitions(this.metricId);
  }

  public indeterminateSelection([key, selection]: [string, unknown], rowKey?: string): boolean {
    return Boolean(selection && rowKey && key.startsWith(rowKey));
  }

  public onCheckChanged(key: string) {
    this.metricSearchStateService.handleSelection(key);
  }

  public toggleValueDefinition(valueDefinitionId: string): void {
    this.toggledValueDefinitions[valueDefinitionId] = !this.toggledValueDefinitions[valueDefinitionId];
  }
}
