<ng-container *ngIf="data$ | async as data">
  <ng-container *ngIf="data.metric; else dummyTable">
    <ng-container *ngIf="!selectedTable">
      <div class="metric-info-container">
        <p class="metric-code">{{ data.metric.code }}</p>
        <p class="metric-description">{{ data.metric.description }}</p>
      </div>
      <div class="search-container">
        <lib-filter-bar-search
          (onSearchChanged)="filterValueDefinitions($event)"
          [searchBarPlaceholder]="'Search field' | translate"
        ></lib-filter-bar-search>
      </div>
      <lib-data-table-remote-data
        [tableColumns]="valueDefinitionTableColumns"
        [tableData]="data.filteredValueDefinitionsWithFieldTypeRows"
        [isPaginable]="false"
        (selectRow)="selectValueDefinition($event)"
        [showRowSelection]="true"
      >
      </lib-data-table-remote-data>
    </ng-container>

    <ng-container *ngIf="selectedTable">
      <lib-select-metric-table-column-definition
        *ngIf="metricTableValueSelection === eMetricTableValueSelectionChoice.tableColumn"
        [metricTableDefinition]="selectedTable"
        (metricTableColumnDefinitionSelected)="selectTableInputColumn($event)"
        (cancel)="selectedTable = undefined"
      ></lib-select-metric-table-column-definition>

      <lib-select-metric-table-cell-value-definition
        *ngIf="metricTableValueSelection === eMetricTableValueSelectionChoice.tableCell"
        [metricTableDefinition]="selectedTable"
        (cancel)="selectedTable = undefined"
        [metric]="data.metric"
        (metricTableValueDefinitionSelected)="selectValueDefinitionFromTableCell($event)"
      ></lib-select-metric-table-cell-value-definition>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #valueDefinitionCell let-row="row">
  <div class="field-type-cell">
    <div>{{ row?.fieldTypeText }}</div>

    <mat-icon
      *ngIf="row?.fieldType === eValueDefinitionFieldType.table"
      svgIcon="arrow-head-right"
      data-testid="table-expand-icon"
    ></mat-icon>
  </div>
</ng-template>

<ng-template #dummyTable>
  <lib-dummy [presentation]="ePresentation.matTable" data-testid="dummy-matTable"></lib-dummy>
</ng-template>
