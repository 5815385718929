import { Injectable } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';

import { BehaviorSubject, finalize, Observable } from 'rxjs';
import keyBy from 'lodash/keyBy';

import {
  ActionItem,
  ApiResponse,
  FilterBarSelection,
  Indicator,
  ItemType,
  Metric,
  MetricCategory,
  MetricSearchFieldSelection,
  MetricSearchInitialSelection,
  MetricSearchSelection,
  MetricSearchValueDefinition,
  MetricSearchValueDefinitionContainer,
  MetricSearchValueDefinitionField,
  SearchBarFilterResourceArgs,
  SearchBarFilterResourceType,
  SearchOptionFilters,
  SearchOptions,
  ValueDefinition,
  ValueDefinitionGroup,
  ValueDefinitionType,
} from '../models';
import { ClientMetricsService } from '../services/client';
import { DataTablePaginatorConfiguration, DEFAULT_PAGE_SIZE } from '../data-table';
import { FilterService, TranslateService } from '../services/common';
import { MetricSearchApiService } from '../services/api-services';

@Injectable()
export class MetricSearchStateService {
  private _metrics: BehaviorSubject<Indicator[]> = new BehaviorSubject<Indicator[]>([]);
  private _metricValueDefinitions = new BehaviorSubject<Record<string, MetricSearchValueDefinitionContainer>>({});
  private _selections = new BehaviorSubject<Record<string, boolean>>({});
  private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isLoadingNextPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _allDataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _dataTablePaginationConfig$: BehaviorSubject<DataTablePaginatorConfiguration> =
    new BehaviorSubject<DataTablePaginatorConfiguration>({
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 0,
      total: 0,
    });

  private readonly fieldTranslations = {
    [MetricSearchValueDefinitionField.all]: this.translateService.instant('Value'),
    [MetricSearchValueDefinitionField.explanation]: this.translateService.instant('Explanation'),
    [MetricSearchValueDefinitionField.pageNumber]: this.translateService.instant('Page Number'),
    [MetricSearchValueDefinitionField.url]: this.translateService.instant('URL'),
  };
  private readonly vdToExclude = [
    ValueDefinitionType.document,
    ValueDefinitionType.label,
    ValueDefinitionType.subtitle,
    ValueDefinitionType.tip,
  ];

  readonly metrics$: Observable<Indicator[]>;
  readonly metricValueDefinitions$: Observable<Record<string, MetricSearchValueDefinitionContainer>>;
  readonly selections$: Observable<Record<string, boolean>>;
  readonly isLoading$: Observable<boolean>;
  readonly isLoadingNextPage$: Observable<boolean>;
  readonly allDataLoaded$: Observable<boolean>;

  readonly dataTablePaginationConfig$: Observable<DataTablePaginatorConfiguration>;

  private metrics: Record<string, Indicator> = {};
  private valueDefinitions: Record<string, ValueDefinition> = {};
  private valueDefinitionGroups: Record<string, ValueDefinitionGroup> = {};
  private withInfiniteScroll: boolean = false;

  readonly filterArgs: SearchBarFilterResourceArgs = {
    [SearchBarFilterResourceType.category]: { excluded: [MetricCategory.THIRD_PARTY] },
  };

  private commonFilters: SearchOptionFilters = {};
  private sort?: ActionItem;
  private searchOptions: SearchOptions = {
    item_type: ItemType.metrics_indicator,
    filters: {},
    filter_args: this.filterArgs,
    from: 0,
    query: { keywords: '' },
    size: DEFAULT_PAGE_SIZE,
  };

  private readonly selectionConcatenator: string = '*';
  private readonly selectionSeparator: string = '+';

  constructor(
    private readonly metricSearchApiService: MetricSearchApiService,
    private readonly clientMetricsService: ClientMetricsService,
    private readonly translateService: TranslateService,
    private readonly filterService: FilterService
  ) {
    this.metrics$ = this._metrics.asObservable();
    this.metricValueDefinitions$ = this._metricValueDefinitions.asObservable();
    this.selections$ = this._selections.asObservable();
    this.isLoading$ = this._isLoading$.asObservable();
    this.isLoadingNextPage$ = this._isLoadingNextPage$.asObservable();
    this.allDataLoaded$ = this._allDataLoaded$.asObservable();
    this.dataTablePaginationConfig$ = this._dataTablePaginationConfig$.asObservable();
  }

  public initialize(
    withInfiniteScroll: boolean,
    sortItem: ActionItem,
    commonFilters: SearchOptionFilters,
    initialSelections?: MetricSearchInitialSelection
  ): void {
    this.withInfiniteScroll = withInfiniteScroll;
    this.sort = { id: sortItem.id, title: this.translateService.instant(sortItem.title) };
    this.searchOptions.sort = this.sort;
    this.commonFilters = commonFilters;
    this.searchOptions.filters = { ...this.searchOptions.filters, ...commonFilters };
    this.setInitialSelections(initialSelections);
  }

  public loadMetrics(): void {
    this._isLoading$.next(true);
    this.metricSearchApiService
      .search(this.searchOptions)
      .pipe(
        finalize(() => {
          this._isLoading$.next(false);
          this._isLoadingNextPage$.next(false);
        })
      )
      .subscribe((indicatorsResponse: ApiResponse<Indicator[]>) => {
        if (this.withInfiniteScroll) {
          let metrics = this._metrics.getValue();
          metrics = metrics.concat(indicatorsResponse.data);
          this._metrics.next(metrics);
          const totalCount = indicatorsResponse.meta.total_count ?? 0;
          this._allDataLoaded$.next(metrics.length >= totalCount);
        } else {
          this._metrics.next(indicatorsResponse.data);
        }
        this.updateDataTablePaginationConfig(undefined, undefined, indicatorsResponse.meta.total_count ?? 0);
      });
  }

  public handleSelection(
    key: string,
    options?: { fetchValueDefinitions?: boolean; force?: boolean; singleSelection?: boolean }
  ): boolean {
    const keyComponents = key.split(this.selectionSeparator);
    const metricId = keyComponents[0];

    if (options?.singleSelection) {
      this._selections.next({ [key]: true });
      return true;
    }

    if (options?.fetchValueDefinitions && !(metricId in this._metricValueDefinitions.value)) {
      this.loadMetricValueDefinitions(metricId, key);
      return !this._selections.value[key];
    }

    const selected = typeof options?.force === 'boolean' ? options.force : !this._selections.value[key];
    const selections = { ...this._selections.value, [key]: selected };
    const container = this._metricValueDefinitions.value[metricId];
    const valueDefinitions = container ? [...container.nonRepeatable, ...container.repeatable] : [];
    const valueDefinition = this.fetchValueDefinition(key, valueDefinitions);

    // Handle children
    if (valueDefinition || keyComponents.length === 1) {
      (valueDefinition?.children || valueDefinitions).forEach((vd) => {
        if (vd.key.startsWith(key)) {
          this.appendChildSelections(selections, vd, selected);
        }
      });
    }

    // Handle parents
    this.handleParentSelection(selections, key, valueDefinitions);

    this._selections.next(selections);

    return selected;
  }

  public loadMetricValueDefinitions(metricId: string, selectionKey?: string): void {
    if (metricId in this._metricValueDefinitions.value) {
      return;
    }

    this.clientMetricsService.getMetric(metricId).subscribe((res) => {
      this.setMetricComponents(res.data);

      if (selectionKey) {
        this.handleSelection(selectionKey);
      }
    });
  }

  public getSelections(): MetricSearchSelection[] {
    const selections = Object.keys(this._selections.value).filter((key) => this._selections.value[key]);
    const metricSearchFields = Object.values<string>(MetricSearchValueDefinitionField);
    const metricValueDefinitions = selections.reduce((acc: Record<string, MetricSearchFieldSelection[]>, key) => {
      const components = key.split(this.selectionSeparator);
      const metricId = components[0];
      const attribute = components[components.length - 1];

      if (metricSearchFields.includes(attribute)) {
        const valueDefinition = this.valueDefinitions[components[components.length - 2]];
        const valueDefinitionGroup = this.valueDefinitionGroups[valueDefinition.value_definition_group_id];
        acc[metricId] = [
          ...(acc[metricId] || []),
          { attribute: attribute as MetricSearchValueDefinitionField, valueDefinition, valueDefinitionGroup },
        ];
      }

      return acc;
    }, {});

    return Array.from(new Set(selections.map((key) => key.split(this.selectionSeparator)[0]))).map((metricId) => ({
      indicator: this.metrics[metricId],
      valueDefinitions: metricValueDefinitions[metricId],
    }));
  }

  public onSearchChange(searchQuery: string): void {
    this.resetDataTableConfigs();
    this.searchOptions.query.keywords = searchQuery;
    this.searchOptions.from = 0;
    this.searchOptions.sort = searchQuery.length ? undefined : this.sort;
    this._metrics.next([]);
    this.loadMetrics();
  }

  public onFilterChange(options: FilterBarSelection[]): void {
    this.resetDataTableConfigs();
    this.searchOptions.filters = {
      ...this.sanitizeFilterOptions(options, true),
      ...this.commonFilters,
    };
    this.searchOptions.from = 0;

    if (this.searchOptions.filters.sort) {
      this.searchOptions.sort = {
        id: String(this.searchOptions.filters.sort.id),
        title: this.translateService.instant(this.searchOptions.filters.sort.title),
      };
    }
    this._metrics.next([]);
    this.loadMetrics();
  }

  public onPageChange(event?: PageEvent): void {
    if (event) {
      this.searchOptions.from = event.pageIndex * event.pageSize;
      this.searchOptions.size = event.pageSize;
      this.updateDataTablePaginationConfig(event.pageIndex, event.pageSize);
      this.loadMetrics();
    } else if (this.withInfiniteScroll && !this._isLoadingNextPage$.getValue() && !this._allDataLoaded$.getValue()) {
      this.searchOptions.from = this._metrics.getValue().length;
      this._isLoadingNextPage$.next(true);
      this.loadMetrics();
    }
  }

  public refresh(): void {
    this.resetDataTableConfigs();
    this._metrics.next([]);
    this.loadMetrics();
  }

  private resetDataTableConfigs() {
    this.searchOptions.size = this._dataTablePaginationConfig$.getValue().pageSize;
    this.updateDataTablePaginationConfig(0);
  }

  private updateDataTablePaginationConfig(currentPage?: number, pageSize?: number, total?: number) {
    const dataTablePaginationConfig = this._dataTablePaginationConfig$.getValue();
    dataTablePaginationConfig.currentPage = currentPage ?? dataTablePaginationConfig.currentPage;
    dataTablePaginationConfig.pageSize = pageSize ?? dataTablePaginationConfig.pageSize;
    dataTablePaginationConfig.total = total ?? dataTablePaginationConfig.total;
    this._dataTablePaginationConfig$.next(dataTablePaginationConfig);
  }

  private sanitizeFilterOptions(filterBarSelections: FilterBarSelection[], singleValue: boolean = false): any {
    return filterBarSelections
      .filter((option) =>
        option.selection.some((selection) => selection.id !== this.filterService.filterListDefaultValue.id)
      )
      .reduce(
        (accOptions, option) => ({
          ...accOptions,
          [option.id]: singleValue ? option.selection[0] : option.selection,
        }),
        {}
      );
  }

  private toMetricSearchValueDefinition(
    metricId: string,
    groups: ValueDefinitionGroup[]
  ): MetricSearchValueDefinition[] {
    return groups.reduce((acc: MetricSearchValueDefinition[], vdg) => {
      if (vdg.table_id) {
        let tableItem: MetricSearchValueDefinition | undefined = acc[acc.length - 1];
        const key = `${metricId}${this.selectionSeparator}${vdg.table_id}`;

        if (tableItem?.key !== key) {
          tableItem = { key, children: [], label: String(vdg.label) };
          acc.push(tableItem);
        }

        tableItem.children?.push(...this.fetchMetricSearchTableChildren(key, vdg));
      } else {
        vdg.value_definitions?.forEach((vd) => {
          if (!this.vdToExclude.includes(vd.type)) {
            let key = `${metricId}${this.selectionSeparator}${vd.id}`;
            const children = this.fetchMetricSearchValueDefinitionChildren(key, vd);
            key += children.length ? '' : `${this.selectionSeparator}${MetricSearchValueDefinitionField.all}`;
            acc.push({ key, children, label: String(vd.label) });
          }
        });
      }
      return acc;
    }, []);
  }

  private fetchMetricSearchValueDefinitionChildren(
    parentKey: string,
    vd: ValueDefinition
  ): MetricSearchValueDefinition[] {
    let fields: MetricSearchValueDefinitionField[] = [];

    switch (vd.type) {
      case ValueDefinitionType.boolean:
      case ValueDefinitionType.choice:
        fields = [MetricSearchValueDefinitionField.all, MetricSearchValueDefinitionField.explanation];
        break;
      case ValueDefinitionType.file_v2:
        fields = [
          MetricSearchValueDefinitionField.all,
          MetricSearchValueDefinitionField.explanation,
          MetricSearchValueDefinitionField.pageNumber,
          MetricSearchValueDefinitionField.url,
        ];
        break;
      default:
        break;
    }

    return fields.map((field) => ({
      key: `${parentKey}${this.selectionSeparator}${field}`,
      label: this.fieldTranslations[field],
      disabled: field === MetricSearchValueDefinitionField.all,
    }));
  }

  private fetchMetricSearchTableChildren(parentKey: string, vdg: ValueDefinitionGroup): MetricSearchValueDefinition[] {
    if (vdg.is_calculation) {
      const key = `${parentKey}${this.selectionSeparator}${vdg.id}`;
      const children = vdg.value_definitions?.map((vd) => ({
        key: `${key}${this.selectionSeparator}${vd.id}${this.selectionSeparator}${MetricSearchValueDefinitionField.all}`,
        label: String(vd.label),
      }));
      return children?.length ? [{ key, label: this.translateService.instant('Totals'), children }] : [];
    }

    const contextColumns = vdg.value_definitions?.filter((vd) => vd.type === ValueDefinitionType.label) || [];
    const inputColumns = vdg.value_definitions?.filter((vd) => vd.type !== ValueDefinitionType.label) || [];
    const contextKey = `${parentKey}${this.selectionSeparator}${contextColumns
      .map((c) => c.id)
      .join(this.selectionConcatenator)}`;
    const children = inputColumns.map((c) => ({
      key: `${contextKey}${this.selectionSeparator}${c.id}${this.selectionSeparator}${MetricSearchValueDefinitionField.all}`,
      label: String(c.label),
    }));

    return [{ key: contextKey, label: contextColumns.map((c) => c.type_details?.value).join(', '), children }];
  }

  private appendChildSelections(
    selections: Record<string, boolean>,
    vd: MetricSearchValueDefinition,
    selected: boolean
  ): void {
    selections[vd.key] = selected;
    vd.children?.forEach((child) => {
      this.appendChildSelections(selections, child, selected);
    });
  }

  private fetchValueDefinition(
    key: string,
    vds: MetricSearchValueDefinition[]
  ): MetricSearchValueDefinition | undefined {
    for (let index = 0; index < vds.length; index++) {
      const vd = vds[index];
      if (vd.key === key) {
        return vd;
      } else if (vd.children) {
        const valueDefinition = this.fetchValueDefinition(key, vd.children);
        if (valueDefinition) {
          return valueDefinition;
        }
      }
    }

    return;
  }

  private handleParentSelection(
    selections: Record<string, boolean>,
    key: string,
    vds: MetricSearchValueDefinition[]
  ): void {
    const keyComponents = key.split(this.selectionSeparator);
    keyComponents.pop();
    keyComponents.forEach((_c, i) => {
      const parentKey = keyComponents.slice(0, keyComponents.length - i).join(this.selectionSeparator);
      const parent = this.fetchValueDefinition(parentKey, vds);
      const children = parent?.children || vds;
      selections[parentKey] = children.every((c) => selections[c.key]);
    });
  }

  private setMetricComponents(metric: Metric): void {
    const nonRepeatableGroups = metric.value_definition_groups?.filter((vdg) => !vdg.repeatable) || [];
    const nonRepeatable = this.toMetricSearchValueDefinition(metric.id, nonRepeatableGroups);
    const repeatableGroups = metric.value_definition_groups?.filter((vdg) => vdg.repeatable) || [];
    const repeatable = this.toMetricSearchValueDefinition(metric.id, repeatableGroups);
    this.metrics[metric.id] = metric;
    this._metricValueDefinitions.next({
      ...this._metricValueDefinitions.value,
      [metric.id]: { nonRepeatable, repeatable },
    });
    this.valueDefinitions = {
      ...this.valueDefinitions,
      ...metric.value_definition_groups?.reduce(
        (acc: Record<string, ValueDefinition>, vdg) => ({ ...acc, ...keyBy(vdg.value_definitions || [], 'id') }),
        {}
      ),
    };
    this.valueDefinitionGroups = {
      ...this.valueDefinitionGroups,
      ...keyBy([...nonRepeatableGroups, ...repeatableGroups], 'id'),
    };
  }

  private setInitialSelections(initialSelections?: MetricSearchInitialSelection): void {
    if (!initialSelections) {
      return;
    }

    const selections: Record<string, boolean> = {};
    const valueDefinitions: MetricSearchValueDefinition[] = [];

    initialSelections.metrics.forEach((metric) => {
      this.setMetricComponents(metric);
      const container = this._metricValueDefinitions.value[metric.id];
      valueDefinitions.push(...container.nonRepeatable, ...container.repeatable);
    });

    initialSelections.selections.forEach((m) => {
      m.valueDefinitions.forEach((field) => {
        let key = m.metricId;
        const valueDefinition = this.valueDefinitions[field.valueDefinitionId];
        const vdg = this.valueDefinitionGroups[valueDefinition.value_definition_group_id];

        // For tables, generate the coresponding keys using the context columns
        if (vdg.table_id) {
          const tableKey = `${key}${this.selectionSeparator}${vdg.table_id}`;
          const contextColumns = vdg.value_definitions?.filter((vd) => vd.type === ValueDefinitionType.label) || [];
          const contextKey = contextColumns.map((c) => c.id).join(this.selectionConcatenator);

          key = `${tableKey}${this.selectionSeparator}` + (vdg.is_calculation ? vdg.id : contextKey);
        }

        // Create the corresponding selection key for this field
        key += `${this.selectionSeparator}${valueDefinition.id}${this.selectionSeparator}${field.attribute}`;

        // Append the corresponding selection for this field
        selections[key] = true;
      });
    });

    // Parent selection
    Object.keys(selections).forEach((key) => this.handleParentSelection(selections, key, valueDefinitions));

    this._selections.next(selections);
  }
}
