import {
  DEFAULT_METRIC_COLUMNS,
  ExportFormat,
  ExportMetricsCurrentViewPayload,
  ExportTableFormat,
  SearchIndicatorPayload,
} from '../models';
import { formatDate } from '@angular/common';
import { Builder } from './builder';
import { SearchIndicatorPayloadBuilder } from './search-indicator-payload-builder';

export class ExportMetricsCurrentViewPayloadBuilder extends Builder<ExportMetricsCurrentViewPayload> {
  constructor() {
    const currentDate: Date = new Date();
    super({
      file_name: `MetricLibrary - ${formatDate(currentDate, 'yyyy-MM-dd', 'en-CA')}`,
      output_format: ExportFormat.EXCEL,
      table_format: null,
      frequencies: [currentDate.getFullYear().toString()],
      columns: DEFAULT_METRIC_COLUMNS,
      skip_reset_values: true,
      skip_metric_no_values: true,
      skip_business_unit_no_values: true,
      skip_frequencies_no_values: true,
      search_indicator_v1_request: new SearchIndicatorPayloadBuilder().build(),
      include_consolidated: true,
    });
  }

  businessUnitId(business_unit_id: string): ExportMetricsCurrentViewPayloadBuilder {
    this._instance.business_unit_ids = [business_unit_id];
    return this;
  }

  fileName(file_name: string): ExportMetricsCurrentViewPayloadBuilder {
    this._instance.file_name = file_name;
    return this;
  }

  outputFormat(format: ExportFormat): ExportMetricsCurrentViewPayloadBuilder {
    this._instance.output_format = format;
    return this;
  }

  tableFormat(table_format: ExportTableFormat): ExportMetricsCurrentViewPayloadBuilder {
    this._instance.table_format = table_format;
    return this;
  }

  searchIndicatorV1Request(search_v1_request: SearchIndicatorPayload): ExportMetricsCurrentViewPayloadBuilder {
    this._instance.search_indicator_v1_request = search_v1_request;
    return this;
  }

  skipResetValues(value: boolean): ExportMetricsCurrentViewPayloadBuilder {
    this._instance.skip_reset_values = value;
    return this;
  }

  skipMetricNoValues(value: boolean): ExportMetricsCurrentViewPayloadBuilder {
    this._instance.skip_metric_no_values = value;
    return this;
  }

  skipBusinessUnitNoValues(value: boolean): ExportMetricsCurrentViewPayloadBuilder {
    this._instance.skip_business_unit_no_values = value;
    return this;
  }

  skipFrequenciesNoValues(value: boolean): ExportMetricsCurrentViewPayloadBuilder {
    this._instance.skip_frequencies_no_values = value;
    return this;
  }

  includeConsolidated(value: boolean): ExportMetricsCurrentViewPayloadBuilder {
    this._instance.include_consolidated = value;
    return this;
  }
}
