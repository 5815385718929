import { UserEmailCheck } from '../models';

export class UserEmailCheckBuilder {
  private readonly _userEmailCheck: UserEmailCheck;

  constructor() {
    this._userEmailCheck = {
      available: true,
    };
  }

  available(available: boolean): UserEmailCheckBuilder {
    this._userEmailCheck.available = available;
    return this;
  }

  build(): UserEmailCheck {
    return this._userEmailCheck;
  }
}
