import {
  DataRequestSourceStatus,
  DataRequestUserResponsibility,
  DataRequestValueGroupSetStatus,
  ValueDefinitionType,
} from '../../../../models';

export class FieldActionsValidator {
  /**
   * Validates if the user can add a rejection comment
   */
  static validateRejectButton(
    valueDefinitionType: ValueDefinitionType,
    unresolvedRejectionCommentsAndReplies: number | undefined,
    dataRequestSourceStatus: DataRequestSourceStatus,
    valueGroupSetStatus: DataRequestValueGroupSetStatus,
    collaboratorResponsibility?: DataRequestUserResponsibility
  ): FieldActionError | undefined {
    if (
      dataRequestSourceStatus === DataRequestSourceStatus.STARTED ||
      this.isSourceStatusAboveContributorRole(dataRequestSourceStatus, collaboratorResponsibility)
    ) {
      return {
        hideButton: true,
      };
    }

    if (valueDefinitionType === ValueDefinitionType.calculated) {
      return {
        errorType: FieldActionsErrorType.INVALID_VD_TYPE,
      };
    }

    if (this.isMetricAlreadyProcessed(dataRequestSourceStatus, valueGroupSetStatus)) {
      return {
        errorType: FieldActionsErrorType.METRIC_ALREADY_PROCESSED,
      };
    }

    if (unresolvedRejectionCommentsAndReplies) {
      return {
        errorType: FieldActionsErrorType.INVALID_REJECTION_COMMENTS_COUNT,
      };
    }

    return;
  }

  private static isMetricAlreadyProcessed(
    dataRequestSourceStatus: DataRequestSourceStatus,
    valueGroupSetStatus: DataRequestValueGroupSetStatus
  ): boolean {
    if (valueGroupSetStatus === DataRequestValueGroupSetStatus.REJECTED) {
      return true;
    }

    switch (dataRequestSourceStatus) {
      case DataRequestSourceStatus.IN_REVIEW:
        return valueGroupSetStatus === DataRequestValueGroupSetStatus.ACCEPTED;
      case DataRequestSourceStatus.IN_APPROVAL:
        return valueGroupSetStatus === DataRequestValueGroupSetStatus.APPROVED;
      default:
        return false;
    }
  }

  private static isSourceStatusAboveContributorRole(
    dataRequestSourceStatus: DataRequestSourceStatus,
    collaboratorResponsibility?: DataRequestUserResponsibility
  ): boolean {
    if (!collaboratorResponsibility) {
      return false;
    }
    switch (dataRequestSourceStatus) {
      case DataRequestSourceStatus.IN_REVIEW:
        return collaboratorResponsibility === DataRequestUserResponsibility.CONTRIBUTOR;
      case DataRequestSourceStatus.IN_APPROVAL:
        return [DataRequestUserResponsibility.CONTRIBUTOR, DataRequestUserResponsibility.REVIEWER].includes(
          collaboratorResponsibility
        );
      default:
        return false;
    }
  }
}

export interface FieldActionError {
  errorType?: FieldActionsErrorType;
  hideButton?: boolean;
}

export enum FieldActionsErrorType {
  INVALID_VD_TYPE = 'invalid_vd_type',
  METRIC_ALREADY_PROCESSED = 'metric_already_processed',
  INVALID_REJECTION_COMMENTS_COUNT = 'invalid_rejection_comments_count',
}
