<gridster [options]="gridsterConfig">
  <gridster-item *ngFor="let dashboardWidget of widgets" [item]="dashboardWidget | dashboardWidgetToGridsterItem">
    <ng-container [ngSwitch]="dashboardWidget.widget_type">
      <lib-chart-widget *ngSwitchCase="eWidgetType.CHART_WIDGET" [widget]="dashboardWidget"></lib-chart-widget>
      <lib-single-data-point-widget
        *ngSwitchCase="eWidgetType.DATA_POINT_WIDGET"
        [widget]="dashboardWidget"
        [fiscalYearsItem]="fiscalYearItems"
        [sourcesItem]="sourceItems"
      ></lib-single-data-point-widget>
    </ng-container>
  </gridster-item>
</gridster>
