import { DashboardChartWidget, DashboardStackChart } from '../../models';
import { CreatedUpdatedBuilder } from '../created-updated-builder';
import { v4 as uuidv4 } from 'uuid';
import { DashboardStackChartBuilder } from './dashboard-stack-chart-builder';

export class DashboardChartWidgetBuilder extends CreatedUpdatedBuilder<DashboardChartWidget> {
  constructor() {
    super({
      id: uuidv4(),
      dashboard_widget_id: uuidv4(),
      stack_chart: new DashboardStackChartBuilder().build(),
    });
  }

  stackChart(stackChart: DashboardStackChart): DashboardChartWidgetBuilder {
    this._instance.stack_chart = stackChart;
    return this;
  }
}
