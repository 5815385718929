import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BUTTON_COMPONENTS } from './buttons';
import { CARD_COMPONENTS } from './cards';
import { INDICATOR_COMPONENTS } from './indicators';
import { TAGS_COMPONENTS } from './tags';
import { TYPOGRAPHY_COMPONENTS } from './typography';
import { BADGE_COMPONENTS } from './badges';
import { FocusStateDirective, FORM_COMPONENTS } from './form';
import { FILES_COMPONENTS } from './files';
import { CHIPS_COMPONENTS } from './chips';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../directives';
import { CdkModule } from '../material/cdk.module';
import { TEXTS_COMPONENTS } from './texts';
import { TranslateModule } from '@ngx-translate/core';
import { NumberMaskModule } from '../number-mask';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { DocumentEditorContainerModule } from '@syncfusion/ej2-angular-documenteditor';
import { MENU_COMPONENTS } from './menus';
import { MatRippleModule } from '@angular/material/core';
import { LOADINGS_COMPONENTS } from './loadings';
import { STEPPER_COMPONENTS } from './steppers';
import { WIDGETS_COMPONENTS } from './widgets';
import { LISTS_COMPONENTS } from './lists';
import { NumericInputTypePipe } from './form/numeric-input/numeric-type-pipe/numeric-input-type.pipe';
import { AccordionComponent } from './accordion/accordion.component';

@NgModule({
  declarations: [
    ...CARD_COMPONENTS,
    ...BUTTON_COMPONENTS,
    ...INDICATOR_COMPONENTS,
    ...TAGS_COMPONENTS,
    ...TYPOGRAPHY_COMPONENTS,
    ...BADGE_COMPONENTS,
    ...FORM_COMPONENTS,
    ...TEXTS_COMPONENTS,
    ...FILES_COMPONENTS,
    ...MENU_COMPONENTS,
    ...CHIPS_COMPONENTS,
    ...LOADINGS_COMPONENTS,
    ...STEPPER_COMPONENTS,
    ...WIDGETS_COMPONENTS,
    ...LISTS_COMPONENTS,
    FocusStateDirective,
    NumericInputTypePipe,
    AccordionComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CdkModule,
    MaterialModule,
    // SyncFusion
    RichTextEditorModule,
    GridModule,
    PipesModule,
    DirectivesModule,
    TranslateModule,
    NumberMaskModule,
    NgxMaskDirective,
    NgxMaskPipe,
    DocumentEditorContainerModule,
    MatRippleModule,
  ],
  providers: [provideNgxMask()],
  exports: [
    ...CARD_COMPONENTS,
    ...BUTTON_COMPONENTS,
    ...INDICATOR_COMPONENTS,
    ...TAGS_COMPONENTS,
    ...TYPOGRAPHY_COMPONENTS,
    ...BADGE_COMPONENTS,
    ...FORM_COMPONENTS,
    ...TEXTS_COMPONENTS,
    ...FILES_COMPONENTS,
    ...MENU_COMPONENTS,
    ...CHIPS_COMPONENTS,
    ...STEPPER_COMPONENTS,
    ...WIDGETS_COMPONENTS,
    ...LOADINGS_COMPONENTS,
    ...LISTS_COMPONENTS,
    FocusStateDirective,
    NumericInputTypePipe,
    AccordionComponent,
  ],
})
export class ComponentsModule {}
