import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FormControl } from '@angular/forms';
import { OptionListItem } from '../../../models';
import { AlertsService, TranslateService } from '../../../services/common';
@Component({
  selector: 'lib-options-list-edit-table',
  templateUrl: './options-list-edit-table.component.html',
  styleUrls: ['./options-list-edit-table.component.scss'],
})
export class OptionsListEditTableComponent {
  @Input() nonDeletableColumnIds: string[] = [];
  @Input() options: OptionListItem[] = [];
  @Input() editable: boolean = false;
  @Input() editingOptionIndex: number = -1;
  @Input() isMetricUpdating: boolean = false;
  @Output() optionsChange = new EventEmitter<OptionListItem[]>();

  activeOption?: string = undefined;

  public editableOptionControl: FormControl = new FormControl<string>('', { nonNullable: true });

  constructor(private alertsService: AlertsService, private translateService: TranslateService) {}

  addOption(event: Event): void {
    const input = event.target as HTMLInputElement;
    const trimmedValue = input.value.trim();
    if (!trimmedValue) {
      return;
    }
    const options = this.options.map((o) => o.label);
    if (!options.includes(trimmedValue)) {
      input.value = '';
      this.optionsChange.emit([...this.options, { label: trimmedValue }]);
    } else {
      this.alertsService.error(
        this.translateService.instant('Option: {option} already exists', { option: trimmedValue })
      );
    }
  }

  enableEditOption(index: number): void {
    this.editableOptionControl.setValue(this.options[index].label);
    this.editingOptionIndex = index;
  }

  editOption(): void {
    const currentOption = this.options[this.editingOptionIndex];
    const updateOption = this.editableOptionControl.value.trim();
    if (updateOption && updateOption !== currentOption.label) {
      const newOptions = this.options.slice();
      newOptions[this.editingOptionIndex] = {
        id: currentOption.id,
        label: updateOption || currentOption.label,
      };
      this.optionsChange.emit(newOptions);
    }
    this.editingOptionIndex = -1;
    this.editableOptionControl.reset();
  }

  removeOption(choice: string): void {
    const newOptions = this.options.filter((o) => o.label !== choice);
    this.optionsChange.emit(newOptions);
  }

  moveOption(choice: string, direction: 'up' | 'down'): void {
    const index: number = this.options.findIndex((o) => o.label === choice);
    const option = this.options.find((o) => o.label === choice);
    const newOptions = [...this.options];
    switch (direction) {
      case 'up':
        newOptions[index] = newOptions[index - 1];
        newOptions[index - 1] = { ...option, label: choice };
        break;
      case 'down':
        newOptions[index] = newOptions[index + 1];
        newOptions[index + 1] = { ...option, label: choice };
        break;
    }
    this.optionsChange.emit(newOptions);
  }

  isFirstOption(choice: string): boolean {
    return this.options.findIndex((o) => o.label === choice) === 0;
  }

  isLastOption(choice: string): boolean {
    return this.options.findIndex((o) => o.label === choice) === this.options.length - 1;
  }
}
