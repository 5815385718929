import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';

import { LimitedUser, UserType } from '../models';

export class LimitedUserBuilder {
  private readonly _user: LimitedUser;

  constructor() {
    this._user = {
      id: uuidv4(),
      active: true,
      department: faker.name.jobArea(),
      email: faker.internet.email(),
      first_name: faker.name.firstName(),
      last_name: faker.name.lastName(),
      type: UserType.STANDARD,
      title: faker.name.jobTitle(),
    };
  }

  public id(id: string): LimitedUserBuilder {
    this._user.id = id;
    return this;
  }

  public active(active: boolean): LimitedUserBuilder {
    this._user.active = active;
    return this;
  }

  public department(department?: string): LimitedUserBuilder {
    this._user.department = department;
    return this;
  }

  public email(email: string): LimitedUserBuilder {
    this._user.email = email;
    return this;
  }

  public first_name(name: string): LimitedUserBuilder {
    this._user.first_name = name;
    return this;
  }

  public last_name(name: string): LimitedUserBuilder {
    this._user.last_name = name;
    return this;
  }

  public title(title?: string): LimitedUserBuilder {
    this._user.title = title;
    return this;
  }

  public type(type: UserType): LimitedUserBuilder {
    this._user.type = type;
    return this;
  }

  public build(): LimitedUser {
    return this._user;
  }
}
