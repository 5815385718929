import { faker } from '@faker-js/faker';
import { v4 as uuidv4 } from 'uuid';

import { BenchmarkV2Attribute, BenchmarkV2ResponseIndicatorValue } from '../models';
import { Builder } from './builder';

export class BenchmarkV2ResponseIndicatorValueBuilder extends Builder<BenchmarkV2ResponseIndicatorValue> {
  constructor() {
    super({
      attribute: BenchmarkV2Attribute.all,
      value_definition_id: uuidv4(),
      values: [faker.company.bsNoun()],
    });
  }

  public attribute(attribute: BenchmarkV2Attribute): BenchmarkV2ResponseIndicatorValueBuilder {
    this._instance.attribute = attribute;
    return this;
  }

  public valueDefinitionId(valueDefinitionId: string): BenchmarkV2ResponseIndicatorValueBuilder {
    this._instance.value_definition_id = valueDefinitionId;
    return this;
  }

  public values(values?: unknown[]): BenchmarkV2ResponseIndicatorValueBuilder {
    this._instance.values = values;
    return this;
  }
}
