import { MetricGroup, MetricGroupMetric } from '../models';

export class MetricGroupBuilder {
  private readonly _metricGroup: MetricGroup;

  constructor() {
    this._metricGroup = {
      id: '123',
      name: 'metric group name',
      description: 'Metric group description',
      active: true,
    };
  }

  id(id: string): MetricGroupBuilder {
    this._metricGroup.id = id;
    return this;
  }

  name(name: string): MetricGroupBuilder {
    this._metricGroup.name = name;
    return this;
  }

  description(description: string): MetricGroupBuilder {
    this._metricGroup.description = description;
    return this;
  }

  metrics_count(count: number): MetricGroupBuilder {
    this._metricGroup.metrics_count = count;
    return this;
  }

  active(value: boolean): MetricGroupBuilder {
    this._metricGroup.active = value;
    return this;
  }

  metrics(metricGroupMetrics: MetricGroupMetric[]): MetricGroupBuilder {
    this._metricGroup.metrics = metricGroupMetrics;
    return this;
  }

  build(): MetricGroup {
    return this._metricGroup;
  }
}
