import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, ApplicationApiDefinition, ReportHistoricalValue, ValueGroupSet } from '../../../models';
import pickBy from 'lodash/pickBy';
import { ApiService } from '../../common';
import { HttpContext } from '@angular/common/http';
import { BYPASS_INTERCEPTOR_ERROR_MANAGING } from '../../../interceptors/error-interceptor/bypass-error-constant';

@Injectable({
  providedIn: 'root',
})
export class ClientFieldHistoricalDataService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  valueDefinitionGroupResource: string;
  reportsResource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.valueDefinitionGroupResource = this.apiService.apiConfig.apis.collect.resources.valueDefinitionGroups;
    this.reportsResource = this.apiService.apiConfig.apis.collect.resources.reports;
  }

  getHistoricalData(
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    businessUnitId?: string,
    minFrequencyCode?: string,
    maxFrequencyCode?: string
  ): Observable<ApiResponse<ValueGroupSet[]>> {
    const params: Record<string, unknown> = {
      ['business_unit_id']: businessUnitId,
      ['min_frequency_code']: minFrequencyCode,
      ['max_frequency_code']: maxFrequencyCode,
    };

    return this.apiService.get(
      `${this.servicePath}${this.valueDefinitionGroupResource}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/historical_data`,
      { params: pickBy(params, (param: unknown) => param != null) }
    );
  }

  getHistoricalGroupData(
    valueDefinitionGroupId: string,
    businessUnitId?: string,
    minFrequencyCode?: string,
    maxFrequencyCode?: string
  ): Observable<ApiResponse<ValueGroupSet[]>> {
    const params: Record<string, unknown> = {
      ['business_unit_id']: businessUnitId,
      ['min_frequency_code']: minFrequencyCode,
      ['max_frequency_code']: maxFrequencyCode,
    };

    return this.apiService.get(
      `${this.servicePath}${this.valueDefinitionGroupResource}/value_definition_groups/${valueDefinitionGroupId}/historical_data`,
      { params: pickBy(params, (param: unknown) => param != null) }
    );
  }

  getReportHistoricalData(
    reportId: string,
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string
  ): Observable<ApiResponse<ReportHistoricalValue[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.reportsResource}/reports/${reportId}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/historical_data`,
      {
        context: new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
      }
    );
  }

  getReportHistoricalGroupData(
    reportId: string,
    metricId: string,
    valueDefinitionGroupId: string
  ): Observable<ApiResponse<ReportHistoricalValue[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.reportsResource}/reports/${reportId}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/historical_data`,
      {
        context: new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
      }
    );
  }
}
