import { FormatNumberBrowserLocalePipe, UnitSymbolPipe } from '@novisto/common';
import isNil from 'lodash/isNil';

import { EmbedderValue, FileValue, MinimalDocumentMetaData, Value, ValueDefinitionType } from '../models';

export class ValueUtils {
  public static displayExplanation(value: Value): boolean {
    switch (value.type) {
      case ValueDefinitionType.boolean:
        return value.type_details[`prompt_on_${value.value.value}`];
      case ValueDefinitionType.choice:
        return value.type_details.display_explanation;
      default:
        return false;
    }
  }

  public static formatExplanation(value: Value): string {
    let explanation = '';

    switch (value.type) {
      case ValueDefinitionType.boolean:
      case ValueDefinitionType.choice:
        explanation = value.value?.additional_text;
        break;
      default:
        break;
    }

    return explanation || '[No value]';
  }

  public static formatValue(
    embedderValue: EmbedderValue,
    field: string,
    formatNumberBrowserLocalePipe: FormatNumberBrowserLocalePipe,
    unitSymbolPipe: UnitSymbolPipe,
    documents: Record<string, MinimalDocumentMetaData> = {}
  ): { value: string | string[]; html: boolean } {
    const table = embedderValue.table;
    const tableTotals = embedderValue.tableTotals;
    const value = embedderValue.value;
    let formattedValue = '';
    let html = false;

    if (table) {
      html = true;
      formattedValue = '<ul>';
      table.forEach((vg) => {
        const contextCols = vg.values?.filter((v) => v.type === ValueDefinitionType.label) || [];
        const inputCols = vg.values?.filter((v) => v.type !== ValueDefinitionType.label) || [];
        formattedValue += `<li><b>${contextCols.map((c) => c.type_details.value).join(', ')}</b><ul>`;
        formattedValue += inputCols
          .map((c) => {
            const units = unitSymbolPipe.transform(String(c.type_details.units || ''), true);
            return `<li>${c.label}${units ? ` ${units}` : ''}: ${this.fetchValue(String(c.value || ''))}</li>`;
          })
          .join('');
        formattedValue += '</ul></li>';
      });

      if (tableTotals?.values?.length) {
        formattedValue += '<li><b>Total(s)</b>';
        tableTotals.values.forEach((v) => {
          const units = unitSymbolPipe.transform(String(v.type_details.units || ''));
          formattedValue += `<ul><li>${v.label}: ${this.fetchValue(String(v.value || ''))} ${units}</li></ul>`;
        });
        formattedValue += '</li>';
      }

      formattedValue += '</ul>';
    } else if (value && !isNil(value?.value)) {
      switch (value.type) {
        case ValueDefinitionType.choice:
          formattedValue = value.value.values.length ? value.value.values : '';
          break;
        case ValueDefinitionType.boolean:
          formattedValue = value.type_details[`label_${value.value.value}`];
          break;
        case ValueDefinitionType.calculated:
        case ValueDefinitionType.decimal:
        case ValueDefinitionType.integer:
          formattedValue = formatNumberBrowserLocalePipe.transform(
            String(value.value),
            Number(value.type_details.max_decimals ?? 0)
          );

          const units = unitSymbolPipe.transform(String(value.type_details.units || ''));
          if (units) {
            formattedValue += ` ${units}`;
          }
          break;
        case ValueDefinitionType.file:
          formattedValue = `${documents[field].name}.${documents[field].extension}`;
          break;
        case ValueDefinitionType.file_v2:
          html = true;
          const document: FileValue = value.value.find((v: { file_id: string }) => v.file_id === field);
          formattedValue = `<p><b>File:</b> ${documents[field].name}.${documents[field].extension}`;

          if (value.type_details.display_url) {
            formattedValue += `<br><b>Document URL:</b> ${this.fetchValue(document.url)}`;
          }

          if (value.type_details.display_page_number) {
            formattedValue += `<br><b>Page number:</b> ${this.fetchValue(String(document.page_number))}`;
          }

          if (value.type_details.display_explanation) {
            formattedValue += `<br><b>Explanation:</b> ${this.fetchValue(document.explanation)}`;
          }

          formattedValue += '</p>';
          break;
        default:
          html = Boolean(value.type_details.rich_text);
          formattedValue = value.value;
          break;
      }
    }

    return { html, value: this.fetchValue(formattedValue) };
  }

  private static fetchValue(value?: string | string[]): string | string[] {
    return value || '[No value]';
  }
}
