import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, map, of } from 'rxjs';

import { ApiResponse, Indicator, MinimalDocumentMetaData, ValueDefinitionType } from '../../models';
import { PublicApiService } from '../api/public-api.service';

@Injectable({ providedIn: 'root' })
export class PublicDocumentsService {
  private readonly resource: string;
  private readonly servicePath: string;

  constructor(private apiService: PublicApiService) {
    this.servicePath = apiService.getServicePath();
    this.resource = apiService.apiConfig.apis.external.resources.documents;
  }

  public getIndicatorDocuments(indicators: Indicator[]): Observable<Record<string, MinimalDocumentMetaData>> {
    const documentIds: string[] = [];
    indicators.forEach((indicator) => {
      indicator.value_group_sets?.[0]?.value_groups?.forEach((vg) => {
        vg.values?.forEach((v) => {
          if (v.type === ValueDefinitionType.file && Array.isArray(v.value)) {
            documentIds.push(...v.value.map(String));
          } else if (v.type === ValueDefinitionType.file_v2 && Array.isArray(v.value)) {
            documentIds.push(...v.value.map((v) => String(v.file_id)));
          }
        });
      });
    });

    if (!documentIds.length) {
      return of({});
    }

    return this.getMetadata(documentIds).pipe(
      map((res) => res.data.reduce((acc, d) => Object.assign(acc, { [d.id]: d }), {}))
    );
  }

  public getMetadata(ids: string[]): Observable<ApiResponse<MinimalDocumentMetaData[]>> {
    const params = new HttpParams({ fromObject: { document_ids: ids } });
    return this.apiService.get(`${this.servicePath}${this.resource}/documents/metadata`, { params });
  }
}
