import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ApplicationApiDefinition,
  FiscalYearAuditTrail,
  FiscalYearAuditTrailFilteringOptions,
} from '../../../models';
import { ApiService } from '../../common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClientFiscalYearsAuditTrailService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.fiscalYearsAuditTrail;
  }

  public listFiscalYearsAuditTrail(
    filterOptions: FiscalYearAuditTrailFilteringOptions
  ): Observable<ApiResponse<FiscalYearAuditTrail[]>> {
    const params = this.filterOptionsToParams(filterOptions);
    return this.apiService
      .get(`${this.servicePath}${this.resource}/fiscal_years_audit_trails`, { params })
      .pipe(map((response: ApiResponse<FiscalYearAuditTrail[]>) => this.mapResponseToModel(response)));
  }

  private filterOptionsToParams(options: FiscalYearAuditTrailFilteringOptions): object {
    const params: Record<string, unknown> = {
      ['offset']: options.pagination.offset,
      ['limit']: options.pagination.limit,
    };

    if (options.filters?.fiscal_year) {
      params['frequency_code'] = options.filters.fiscal_year;
    }

    if (options.filters?.event_type) {
      params['event_type'] = options.filters.event_type;
    }

    return params;
  }

  private mapResponseToModel(response: ApiResponse<FiscalYearAuditTrail[]>): ApiResponse<FiscalYearAuditTrail[]> {
    response.data = response.data.reduce(
      (acc: FiscalYearAuditTrail[], value: any) => [
        ...acc,
        {
          ...value,
          fiscal_year: {
            id: value.fiscal_year.id,
            year: value.fiscal_year.frequency_code,
            start: value.fiscal_year.start,
            end: value.fiscal_year.end,
            unit_id: value.fiscal_year.business_unit_id,
          },
        },
      ],
      []
    );
    return response;
  }
}
