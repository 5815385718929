import { DataRequestIndicator, DataRequestIndicatorLink } from '../models';
import { DataRequestIndicatorBuilder } from './data-request-indicator-builder';

export class DataRequestIndicatorLinkBuilder {
  private readonly _dataRequestIndicatorLink: DataRequestIndicatorLink;

  constructor() {
    this._dataRequestIndicatorLink = {
      data_request_id: '123',
      id: '123',
      important: false,
      indicator: new DataRequestIndicatorBuilder().build(),
      name: 'data request indicator link name',
      position: 1,
    };
  }

  id(id: string): DataRequestIndicatorLinkBuilder {
    this._dataRequestIndicatorLink.id = id;
    return this;
  }

  indicator(dataRequestIndicator: DataRequestIndicator): DataRequestIndicatorLinkBuilder {
    this._dataRequestIndicatorLink.indicator = dataRequestIndicator;
    return this;
  }

  position(position: number): DataRequestIndicatorLinkBuilder {
    this._dataRequestIndicatorLink.position = position;
    return this;
  }

  dataRequestId(dataRequestId: string): DataRequestIndicatorLinkBuilder {
    this._dataRequestIndicatorLink.data_request_id = dataRequestId;
    return this;
  }

  important(important: boolean): DataRequestIndicatorLinkBuilder {
    this._dataRequestIndicatorLink.important = important;
    return this;
  }

  instructions(instructions: string): DataRequestIndicatorLinkBuilder {
    this._dataRequestIndicatorLink.instructions = instructions;
    return this;
  }

  build(): DataRequestIndicatorLink {
    return this._dataRequestIndicatorLink;
  }
}
