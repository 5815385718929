import { ConditionalTrigger, ValueDefinition, ValueDefinitionGroup } from '../models';
import { v4 as uuid4 } from 'uuid';

export class ValueDefinitionGroupBuilder {
  private readonly _valueDefinitionGroup: ValueDefinitionGroup;

  constructor() {
    this._valueDefinitionGroup = {
      id: uuid4(),
      name: 'name',
      position: 1,
      indent: 0,
      metric_id: 'metricId',
      value_definitions: [],
      conditional_triggers: [],
      repeatable: false,
      label: undefined,
      active: true,
      end_fiscal_year_frequency_code: '',
    };
  }

  id(id: string): ValueDefinitionGroupBuilder {
    this._valueDefinitionGroup.id = id;
    return this;
  }

  name(name: string): ValueDefinitionGroupBuilder {
    this._valueDefinitionGroup.name = name;
    return this;
  }

  label(label?: string): ValueDefinitionGroupBuilder {
    this._valueDefinitionGroup.label = label;
    return this;
  }

  indent(indent: number): ValueDefinitionGroupBuilder {
    this._valueDefinitionGroup.indent = indent;
    return this;
  }

  position(position: number): ValueDefinitionGroupBuilder {
    this._valueDefinitionGroup.position = position;
    return this;
  }

  groupMaxRepetition(groupMaxRepetition: number): ValueDefinitionGroupBuilder {
    this._valueDefinitionGroup.group_max_repetition = groupMaxRepetition;
    return this;
  }

  repeatable(repeatable: boolean): ValueDefinitionGroupBuilder {
    this._valueDefinitionGroup.repeatable = repeatable;
    return this;
  }

  isCalculation(isCalculation?: boolean): ValueDefinitionGroupBuilder {
    this._valueDefinitionGroup.is_calculation = isCalculation;
    return this;
  }

  valueDefinitions(valueDefinitions: ValueDefinition[]): ValueDefinitionGroupBuilder {
    this._valueDefinitionGroup.value_definitions = valueDefinitions;
    return this;
  }

  tableId(tableId: string | null): ValueDefinitionGroupBuilder {
    this._valueDefinitionGroup.table_id = tableId;
    return this;
  }

  metricId(metricId: string): ValueDefinitionGroupBuilder {
    this._valueDefinitionGroup.metric_id = metricId;
    return this;
  }

  coreValueDefinitionGroupId(coreValueDefinitionGroupId: string | null): ValueDefinitionGroupBuilder {
    this._valueDefinitionGroup.core_value_definition_group_id = coreValueDefinitionGroupId;
    return this;
  }

  active(active?: boolean): ValueDefinitionGroupBuilder {
    this._valueDefinitionGroup.active = active;
    return this;
  }
  endFiscalYearFrequencyCode(frequencyCode?: string): ValueDefinitionGroupBuilder {
    this._valueDefinitionGroup.end_fiscal_year_frequency_code = frequencyCode;
    return this;
  }

  conditionalTriggers(conditionalTriggers: ConditionalTrigger[]): ValueDefinitionGroupBuilder {
    this._valueDefinitionGroup.conditional_triggers = conditionalTriggers;
    return this;
  }

  build(): ValueDefinitionGroup {
    return this._valueDefinitionGroup;
  }
}
