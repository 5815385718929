import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Required } from '../../../../../decorators';
import {
  DecimalTypeDetails,
  IntegerTypeDetails,
  MetricTableColumn,
  MetricTableColumnDefinition,
  ValueDefinitionType,
} from '../../../../../models';
import { TranslateService } from '../../../../../services/common';

@Component({
  selector: 'lib-table-column-definition-card',
  templateUrl: './table-column-definition-card.component.html',
  styleUrls: ['./table-column-definition-card.component.scss'],
})
export class TableColumnDefinitionCardComponent implements OnInit {
  @Input() @Required() tableColumnDefinition!: MetricTableColumnDefinition | MetricTableColumn;
  @Input() disabled: boolean = false;

  @Output() menuClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  private readonly inputColumnTypes = [ValueDefinitionType.integer, ValueDefinitionType.decimal];
  unitCode: string = '';

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.unitCode = this.inputColumnTypes.includes(this.tableColumnDefinition.type)
      ? (this.tableColumnDefinition.type_details as IntegerTypeDetails | DecimalTypeDetails).units
      : '';
  }

  get subtitleText(): string {
    if (this.tableColumnDefinition.type === ValueDefinitionType.label) {
      return this.translateService.instant(
        '{amount, plural, =0{{amount} Context Option} other{{amount} Context Options} }',
        {
          amount: (this.tableColumnDefinition.context_options || this.tableColumnDefinition.options).length,
        }
      );
    } else {
      return this.translateService.instant(`Input Column`);
    }
  }

  handleClick($event: MouseEvent): void {
    if (!this.disabled) {
      this.menuClick.emit($event);
    }
  }
}
