import { DataRequestSourceStatus, DataRequestUser, DataRequestUserResponsibility } from './sources';
import { DataRequestIndicatorLink, DataRequestType, DataRequestValueGroupSet, DueDateCell } from './requests';
import { Entity, RequestDocMetadata } from '../entities';
import { Value } from '../values';
import { Report } from '../templates';

export {
  CollaboratorDataRequest,
  CollaboratorDataRequestRow,
  CollaboratorDataRequestWithSource,
  DataRequestWithSource,
  ActionableStatus,
  DataRequestPublicDocument,
  DataRequestDocMetaData,
  DataRequestCollaborationRightPanelData,
};

interface CollaboratorDataRequest {
  data_request_id: string;
  data_request_source_id: string;
  data_request_user_id: string;
  name: string;
  source: string;
  last_updated: Date;
  due_date?: Date;
  responsibility: DataRequestUserResponsibility;
  status: DataRequestSourceStatus;
  frequency_code: string;
  prefill_frequency_code?: string;
  data_request_source_users: DataRequestUser[];
  type: DataRequestType;
}

interface ActionableStatus {
  label: string;
  status?: DataRequestSourceStatus;
}

interface CollaboratorDataRequestRow
  extends Omit<
    CollaboratorDataRequest,
    'status' | 'last_updated' | 'prefill_frequency_code' | 'due_date' | 'data_request_source_users' | 'type'
  > {
  status: string;
  type: string;
  last_updated: string;
  due_date: DueDateCell;
  actionable: ActionableStatus;
}

interface DataRequestWithSource {
  name: string;
  source: string;
  frequency_code: string;
  status: DataRequestSourceStatus;
  active: boolean;
  last_updated: Date;
  introduction?: string;
  conclusion?: string;
  started_with_reviewer: boolean;
  indicator_links: DataRequestIndicatorLink[];
  business_unit_id: string;
  data_request_users: DataRequestUser[];
  show_historical_data: boolean;
  show_recommendations: boolean;
  show_audit_trail_panel: boolean;
  show_consolidation_panel: boolean;
  allow_reviewers_to_edit: boolean;
  allow_approvers_to_edit: boolean;
  prefill_frequency?: string;
  hide_metric_codes: boolean;
  type: DataRequestType;
  report_id?: string;
  due_date?: string;
}

interface CollaboratorDataRequestWithSource extends DataRequestWithSource {
  responsibility: DataRequestUserResponsibility;
}

interface DataRequestPublicDocument extends Entity {
  original_filename: string;
  deleted: string;
  extension: string;
  file_hash: string;
  container: string;
  meta: RequestDocMetadata;
}

interface DataRequestDocMetaData {
  data_period_end: string;
  data_period_start: string;
  extension: string;
  fiscal_year: number;
  id: string;
  name: string;
  updated: string;
}

interface DataRequestCollaborationRightPanelData {
  currentDataRequestValueGroupSet: DataRequestValueGroupSet | undefined;
  canEditDataRequestVGSet: boolean;
  focusedField: Value | null;
  dataRequestReport: Report | null;
  focusedFieldButton?: string | undefined;
}
